/* eslint-disable no-multi-spaces */

const buildTableData = (dataValues) => {
    const data = !dataValues.userList ? [] : dataValues.userList.map((user, i) => {
      return {
        Values: [
          user.UserId,
          user.FullName,
          user.Email ? user.Email : '',
          dataValues.zoneSignatureMappings[i]?.subType ? dataValues.zoneSignatureMappings[i].subType : "No assigned Role",
          i === 0 || dataValues.separateCopyForSigners ? '' : 'VasionArrowUpIcon',
          i === dataValues.userList.length - 1 || dataValues.separateCopyForSigners ? '' : 'VasionArrowDownIcon',
          user.UserId === -1 ? 'VasionDeleteIcon' : ''
        ],
        ValueType: 'String',
      }
    })
    const tableData = {
      Columns: dataValues.columns,
      Rows: {
        Values: data,
        ValueType: 'ApiArray`1',
      },
    }
    return tableData
  }

  export {
      buildTableData,
  }
