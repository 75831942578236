<template>
  <div
    v-if="dataArray.length || showIfEmpty"
    :id="divIDComputed"
    v-click-outside="close"
    class="vasion-droplist"
    :style="{ width: inputGroupWidth}"
  >
    <VasionInput
      v-if="!type.includes('check')"
      v-bind="$attrs"
      :id="id ? id : inputIDComputed"
      v-model="selectedDisplay"
      :inputType="inputType"
      :title="title"
      :arrowValue="open"
      :arrowClickCallback="toggleOpen"
      :placeholder="placeholder"
      :isDisabled="isDisabled"
      :required="required"
      readonly
      @mousedown="tabOpen"
      @keydown="inputKeyDown"
      @focusout="inputFocusOut"
      @focus="tabOpen"
    />
    <div v-else>
      <span class="multi-droplist-tab-label">{{ title }}</span>
      <span v-if="required" class="vasion-required-indicator">*</span>
      <div
        class="multi-droplist-container"
        :class="{ 'disabled-droplist-container' : isDisabled }"
        :style="[backgroundColor ? { 'background-color': backgroundColor } : null ]"
        tabindex="0"
        @mousedown="toggleOpen"
      >
        <div class="multi-droplist-tabs">
          <span v-if="!selectedArrayLocal || selectedArrayLocal.length < 1" class="multi-droplist-placeholder">{{ placeholder }}</span>
          <div
            v-for="(item, index) in selectedArrayLocal"
            :id="item.value"
            :key="index"
            class="droplist-tab drag-item"
            :class="{ 'disabled-droplist-tab' : isDisabled, 'droplist-tab-pill': pillOptions, 'draggable': draggableOptions, 'chip-outline': (allowOutline && outline === item[valueName])}"
            :draggable="draggableOptions"
            :data-index="index"
            :title="item.tooltipText"
            @click="chipClick($event, item)"
            @dragend="onItemDragEnd"
            @dragenter="onItemDragEnter"
            @dragleave="onItemDragLeave"
            @dragover="onItemDragOver"
            @dragstart="onItemDragStart"
            @drop="onItemDrop"
          >
            <span v-if="item.dotClass" :class="item.dotClass"></span>
            <span>{{ item[displayName] }}</span><VasionExIcon v-if="!isDisabled" class="droplist-tab-icon" @click="handleSelection(item, true, true)" />
          </div>
        </div>
        <i v-if="open && !isDisabled" class="drop-arrow"><VasionArrowDropUpIcon /></i>
        <i v-if="!open && !isDisabled" class="drop-arrow"><VasionArrowDropDownIcon /></i>
      </div>
    </div>
    <transition name="fade">
      <ul
        v-if="open && !isDisabled && !dragActive"
        :id="title && title.length > 0 && title !== undefined
          ? `${title.toLowerCase()}-ul`
          : `${uniqueId}-ul`"
        ref="dropdownList"
        role="list"
        class="list"
        :class="{ 'lowerHeight': decreaseListHeight, 'normal-list': !overlayList, 'overlay-list': overlayList }"
        :style="{ width: dropListWidth, bottom: offset }"
      >
        <li v-if="showSearchField" :class="{ 'search-item': showSearchField }" tabIndex="0">
          <VasionInput
            id="vasion-droplist-search"
            ref="searchInput"
            v-model="searchValue"
            v-debounce:100ms="searchList"
            name="vasion-droplist-search"
            class="search-field"
            :placeholder="searchPlaceholder"
            :required="required"
            :width="'95'"
            :widthUnit="'%'"
            inputType="search-white"
            @listNavigation="navigateList"
          />
        </li>
        <li
          v-if="type === 'add-and-delete'"
          class="add-item"
          @click="emitAddItemClicked"
        >
          <div class="add-item-text">
            <VasionAddIcon class="plus-icon" /> {{ addItemText }}
          </div>
          <div class="add-item-bottom-border" />
        </li>
        <li
          v-for="(item, index) in dataArrayLocal"
          :key="index"
          :class="{
            'selected-item': type.includes('check') ? selectedArrayLocal.findIndex((o) => { return (o[valueName] === item[valueName] || o === item); }) != -1 : item[displayName] === selection,
          }"
          role="listitem"
          class="list-item multiselect-list-item"
          tabIndex="1"
          :title="item.name"
          @keydown="navigateList"
        >
          <div
            :class="`item-name${item.itemClass ? ' ' + item.itemClass : ''}`"
            @click="handleSelection(item, true, true)"
          >
            <slot :item="item">
              {{ item }}
            </slot>
          </div>

          <VasionDeleteIcon
            v-if="type === 'add-and-delete'"
            class="delete-icon"
            @click="emitDeleteClicked(item)"
          />
          <VasionCheckbox
            v-else
            :id="item[valueName].toString() || '0'"
            class="item-checkbox"
            :class="{ 'selected-item': type.includes('check') ? selectedArrayLocal.findIndex((o) => { return o[valueName] === item[valueName]; }) != -1 : item[displayName] === selection}"
            :checked="type.includes('check') ? selectedArrayLocal.findIndex((o) => { return o[valueName] === item[valueName]; }) != -1 : item[displayName].toString() === selectedDisplay || item[displayName] === selection"
            :noLabel="true"
            @change="handleSelection(item, false, true)"
          />
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'VasionDropList',
  inheritAttrs: false,
  props: {
    addItemText: {
      type: String,
      default: 'Add Item',
      required: false,
    },
    allowOutline: {
      type: Boolean,
      default: false,
      required: false,
    },
    backgroundColor: {
      type: String,
      default: '',
      required: false,
    },
    checkCanUpdate: {
      type: Function,
      required: false,
      default: (item = true) => item,
    },
    closeOnOutsideClick: {
      type: Boolean,
      default: true,
      required: false,
    },
    dataArray: {
      type: Array,
      required: true,
    },
    decreaseListHeight: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayName: {
      type: String,
      required: false,
      default: "",
    },
    draggableOptions: {
      type: Boolean,
      default: false,
      required: false,
    },
    forceChipClickEvent: {
      type: Boolean,
      default: false,
      required: false,
    },
    forceOpen: {
      type: Boolean,
      default: false,
      required: false,
    },
    multiSelectInitialValue: {
      type: Array,
      required: false,
      default: null,
    },
    id: {
      type: String,
      required: false,
      default: undefined,
    },
    initializeOnCreate: {
      type: Boolean,
      default: false,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    openAbove: {
      type: Boolean,
      required: false,
      default: false,
    },
    overlayList: {
      type: Boolean,
      required: false,
      default: false,
    },
    pillOptions: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select...',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    returnValueOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      required: false,
      default: 'Type to search ...',
    },
    showIfEmpty: {
      type: Boolean,
      default: false,
      required: false,
    },
    showSearchField: {
      type: Boolean,
      default: true,
      required: false,
    },
    theID: {
      type: String,
      default: 'drop-list',
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: ""
    },
    type: {
      type: String,
      default: 'plain-list',
      required: false,
      validator: function (value) {
        return ['plain-list', 'check-list', 'plain-list-search', 'check-list-search', 'number', 'add-and-delete'].indexOf(value) !== -1
      },
    },
    // eslint-disable-next-line
    value: {
      required: true,
    },
    valueName: {
      type: String,
      required: true,
      default: 'value',
    },
    width: {
      type: String,
      default: '326px',
      required: false,
    },
  },
  data: function () {
    return {
      dataArrayLocal: this.dataArray,
      dragActive: false,
      forceClosed: false,
      inHandleSelection: false,
      initialized: false,
      open: false,
      searchValue: '',
      selectedArrayLocal: this.multiSelectInitialValue?.length > 0 ? this.multiSelectInitialValue : [],
      selectedIndex: -1,
      selection: null,
      uuid: this.$uuid.v1(),
    }
  },
  computed: {
    divIDComputed() {
      return this.title && this.title.length > 0 && this.title !== undefined ? this.title.toLowerCase() : this.uniqueId
    },
    dropListWidth() {
      try{
        const newWidth = document.getElementById(this.divIDComputed).offsetWidth

        if (newWidth <= 0) {
          return '100%'
        } else {
          return `${newWidth}px`
        }
      }
      catch {
        return '100%'
      }
    },
    inputGroupWidth() {
      return this.$options.propsData.width ? `${this.width}px` : this.width
    },
    inputIDComputed() {
      return `${this.divIDComputed}-input`
    },
    inputType() {
      if (this.type.includes('search')) {
        return 'search-white-top'
      }

      return 'top-white'
    },
    offset() {
      const result = `${26}px`
      if (this.openAbove === true) {
        return result
      }

      try {
        const id = this.divIDComputed
        const viewportOffset = document.getElementById(id).getBoundingClientRect()
        const { top } = viewportOffset;
        const viewport = document.documentElement.clientHeight

        if (viewport !== 0 && ((top / viewport) > 0.75)) {
          return result
        } else {
          return false
        }
      }
      catch {
        return false
      }

    },
    selectedDisplay: {
      get: function () {
        let returnValue = ''
        if (this.selection) {
          switch (typeof this.selection) {
            case 'string':
              returnValue = this.selection
              break;
            case 'object':
              returnValue = this.selection[this.displayName]
              break;
            case 'number':
              returnValue = this.selection
              break;
            default:
              returnValue = ''
              break;
          }
        }
        return returnValue
      },
      set: function (newArray) {
        return newArray
      },
    },
    outline() { return this.$store.state.capture.selectedOption },
    uniqueId() {
      return this.theID === 'drop-list' ? this.$uuid.v1().toString() : this.theID
    },
  },
  watch: {
    dataArray: function (newVal) {
      this.dataArrayLocal = newVal
    },
    forceOpen(){
      if (this.forceOpen) {
        this.open = true
      }
    },
    open: function (newVal) {
      this.$emit("openDropDown", newVal)
    },
    value: async function (newValue, oldValue) {
      if (this.type && this.type.includes('check')) {
        if (this.value) {
          if (!this.initializeOnCreate) {
            this.selectedArrayLocal = newValue
          }
          this.selection = this.value[this.displayName] ? this.value[this.displayName] : this.value
        } else {
          this.selection = ''
        }
        if (this.initializeOnCreate && !this.initialized)
        {
          this.initialized = true;
          this.selectedArrayLocal = newValue
        }
      } else if (newValue !== oldValue && !this.inHandleSelection) {
        if (newValue) {
          this.selection = this.calculateSelection()
        } else {
          this.selection = ''
          this.selectedIndex = -1
        }
      }
    },
  },
  mounted: function() {
    this.selection = this.calculateSelection()
  },
  methods: {
    calculateSelection() {
      if (typeof this.dataArray[0] !== 'object') {
        return this.value
      } else {
        const localSelection = this.dataArray.filter((obj, index) => {
          const dn = this.displayName
          const vn = this.valueName
          const v = this.value
          if (!v) return false
          // Select the matching selection value no matter which part matches
          const tryFindMatch = (value) => (obj[dn] === value || obj[vn] === value || obj[dn] === value[dn] || obj[vn] === value[vn])
          const match = (tryFindMatch(v)) || (Array.isArray(v) && v.findIndex(val => tryFindMatch(val)) >= 0)
          if (match) {
            this.selectedIndex = index
            return true
          } else {
            return false
          }
        })
        return localSelection[0] ? localSelection[0][this.displayName] : ''
      }
    },
    chipClick(event, item) {
      const exitSvgIsClicked = event?.target?.tagName === "svg" && event?.target?.classList.contains('droplist-tab-icon')
      const exitPathIsClicked = event?.target?.tagName === "path" && event?.target?.parentNode?.tagName === "svg" && event?.target?.parentNode?.classList.contains('droplist-tab-icon')
      const undefinedIsClicked = event?.target?.localName === undefined
      const eventIsFalse = event === false

      if ((undefinedIsClicked || eventIsFalse) && event != null) return
      if (exitSvgIsClicked || exitPathIsClicked) {
        this.$emit('chipClick', {...item, delete: true})
        return
      }
      
      this.$emit('chipClick', item)
      this.allowOutline && this.$store.dispatch('capture/setSelectedOption', item[this.valueName])
    },
    close: function () {
      if (this.closeOnOutsideClick) {
        this.open = false
      }
    },
    confirmSelection(item, forceCloseDroplist, isInputFromUser) {
      if (item === {}) {
        return
      }
      this.inHandleSelection = true
      if (forceCloseDroplist) {
        this.open = false
        this.forceClosed = true
      }
      const targetValue = this.valueName ? item[this.valueName] : item
      const name = this.valueName

      this.selectedIndex = this.dataArray.findIndex((o) => { return (name ? o[name] : o) === targetValue; })
      const existsIndex = this.selectedArrayLocal.findIndex((o) => { return (name ? o[name] : o) === targetValue; })
      if (this.type.includes('check')) {
        this.selection = ''
        if (existsIndex === -1) {
          // eslint-disable-next-line
          this.selectedArrayLocal.push(item)[this.valueName]
          // eslint-disable-next-line
        } else {
          this.selectedArrayLocal.splice(existsIndex, 1);
        }
        if (isInputFromUser){
          this.$emit('input', this.selectedArrayLocal)
        }
      } else if (this.selectedIndex >= 0) {
        const selectionValue = this.dataArray[this.selectedIndex]
        const selectionDisplay = this.displayName ? this.dataArray[this.selectedIndex][this.displayName] : this.dataArray[this.selectedIndex]
        let emitValue = this.returnValueOnly ? selectionValue.name : selectionValue
        if (this.selection === selectionDisplay) {
          this.selection = ""
          emitValue = ""
        } else {
          this.selection = selectionDisplay
        }
        if (isInputFromUser) {
          this.$emit('input', emitValue)
        }

        this.open = false
      }

      this.$nextTick(()=>{
        this.inHandleSelection = false
      })
    },
    emitAddItemClicked(){
      this.$emit('addItemClicked')
      this.open = false
    },
    emitDeleteClicked(item){
      this.$emit('deleteClicked', item)
    },
    filteredValues(items, text) {
      if (!text) { return items }
      const input = text.toLowerCase()

      return items.filter(item => {
        let target = typeof item === 'string' ? item : null
        if(target === null) {
          target = typeof item[this.displayName] === 'string' ? item[this.displayName] : item.name
        }
        return target.toLowerCase().includes(input)
      })
    },
    // eslint-disable-next-line
    async handleSelection(item, forceCloseDroplist, isInputFromUser) {
      if (await this.checkCanUpdate(item)) {
        this.confirmSelection(item, forceCloseDroplist, isInputFromUser)
        this.chipClick(false, item)
        if ( this.forceChipClickEvent && !forceCloseDroplist) {
          this.$nextTick(()=>{
            this.$emit('chipClick', item)
         })
       }
      }
    },
    inputFocusOut() {
      this.$emit('inputFocusOut')
    },
    inputKeyDown(key) {
      this.$emit('inputKeyDown', key)
    },
    navigateList(e) {
      const ul = this.$refs.dropdownList // This is the ul (dropdown list)
      const first = ul.firstChild // This is the search input, not the li the search input resides in

      let keyCode = ''
      keyCode = !e.code ? e : e.code

      switch (keyCode) {
        case 'ArrowUp':
          if (document.activeElement.parentNode.parentNode.parentNode === first) {
            break
          } else if (document.activeElement.previousSibling === first) {
            document.activeElement.previousSibling.firstChild.childNodes[3].childNodes[1].focus() // Select the search input, not the li that the search input resides in
          } else {
            document.activeElement.previousSibling.focus()
          }
          e.preventDefault()
          break
        case 'ArrowDown':
          if (document.activeElement.parentNode.parentNode.parentNode === first && this.dataArrayLocal.length > 0) {
            document.activeElement.parentNode.parentNode.parentNode.nextSibling.focus() // Select the search input, not the li that the search input resides in
          } else if (document.activeElement.nextSibling && this.dataArrayLocal.length > 0) {
            document.activeElement.nextSibling.focus()
          }
          this.open = true
          e.preventDefault()
          break
        default:
          break
      }
      this.open = true
    },
    onItemDragEnd() {
      let items = this.$el.querySelectorAll(".drag-item")

      for (let item of items) {
        item.classList.remove("hint")
        item.classList.remove("active")
        item.classList.remove("selected")
      }

      this.dragActive = false
      this.open = false
    },
    onItemDragEnter(event) {
      event.target.classList.add("active")
    },
    onItemDragLeave() {
      this.dragActive = false
      this.open = false
    },
    onItemDragOver(event) {
      event.preventDefault()
    },
    onItemDragStart(event) {
      this.dragActive = true
      this.open = false
      
      let items = this.$el.querySelectorAll(".drag-item")
      for (let item of items) {
        (item !== event.target) ? item.classList.add("hint") : item.classList.add("selected")
      }
    },
    onItemDrop(event) {
      let parent = false
      if (!event.target.classList.contains("drag-item")) {
        parent = true
        if (!event.target.parentElement.classList.contains("drag-item")) return
      }
      event.preventDefault();
      let targetItem = parent ? event.target.parentElement : event.target
      let items = this.$el.querySelectorAll(".drag-item")
      let selectedItem = null, currentPos = 0, droppedPos = 0

      for (let index = 0; index < items.length; index++) {
        const item = items[index]
        if (item.classList.contains("selected")) {
          selectedItem = item
          currentPos = index
        }
        if (item === targetItem) {
          droppedPos = index
        }
      }

      if (selectedItem !== targetItem) {
        const element = this.selectedArrayLocal.splice(currentPos, 1)[0]
        this.selectedArrayLocal.splice(droppedPos, 0, element)
        this.$emit('input', this.selectedArrayLocal)
      }
    },
    searchList(input) {
      this.dataArrayLocal = input ? this.filteredValues(this.dataArray, input) : this.dataArray
    },
    tabOpen() {
      if (!this.open) {
        this.toggleOpen()
      }
    },
    toggleOpen(event = null) {
      const chipIsClicked = (event?.target?.tagName === "SPAN" && !event?.target?.classList.contains('multi-droplist-placeholder')) || event?.target?.classList.contains('droplist-tab')
      const exitSvgIsClicked = event?.target?.tagName === "svg" && event?.target?.classList.contains('droplist-tab-icon')
      const exitPathIsClicked = event?.target?.tagName === "path" && event?.target?.parentNode?.tagName === "svg" && event?.target?.parentNode?.classList.contains('droplist-tab-icon')

      if (chipIsClicked || exitSvgIsClicked || exitPathIsClicked) {
        this.forceClosed = false
        return;
      }

      if (this.forceClosed) {
        this.open = false
        this.forceClosed = false
      } else {
        this.open = !this.open
        if (this.showSearchField && this.open) {
          setTimeout(() => {
            if (this.$refs.searchInput && this.$refs.searchInput.$refs.mainInput) this.$refs.searchInput.$refs.mainInput.focus()
          }, 200)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';

  .multi-droplist-tab-label {
    height: 12px;
    font-family: $font-family-medium, 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: $grey-400;
  }
  .multi-droplist-container {
    min-height: 40px;
    border-radius: 8px;
    border: solid 1px $grey-100;
    padding: 4px;
    z-index: 1;
    display: flex;
    outline: none;

    .multi-droplist-tabs {
      display: flex;
      font-family: $font-family-book;
      font-size: 16px;
      color: $grey-300;
      width: 100%;
      flex-wrap: wrap;

      .multi-droplist-placeholder {
        display: inline-block;
        height: 25px;
        padding: 5px;
        margin: 2px;
        line-height: 18px;
        text-align: left;
        vertical-align: middle;
      }

      .droplist-tab {
        display: flex;
        align-items: center;
        min-height: 25px;
        background-color: $surface-variant;
        color: $on-surface-variant;
        border-radius: 4px;
        padding: 5px;
        margin: 2px;
        line-height: 18px;
        text-align: left;
        vertical-align: middle;

        .droplist-tab-icon {
          fill: $on-surface-variant;
          cursor: pointer;
        }
      }

      .droplist-tab.draggable {
        cursor: move;
      }

      .droplist-tab-pill {
        border-radius: 50rem !important;
        border: 2px solid $surface-variant;
      }

      .disabled-droplist-tab{
        color: #e4e5e6;
        background-color:#95989a;
      }
    }

    .drop-arrow {
      position: relative;
      left: 0;
      top: 3px;
      cursor: pointer;
    }
  }
  .disabled-droplist-container {
    background-color: #e4e5e6;
  }

  .list {
    list-style-type: none;
    border: 1px solid $grey-100;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    background-color: $white;
    padding: 0;
    margin-top: 0px;
    padding: 10px 0px;
    z-index: 1000;
    max-height: 250px;
    overflow: auto;
  }

  .normal-list {
    position: absolute;
    width: 100% !important;
  }

  .overlay-list {
    position: fixed;
    width: unset;
  }

  .lowerHeight {
    max-height: 125px;
  }

  li {
    cursor: pointer;
  }

  .list-item {
    color: $grey-500;
    display: block;
    justify-content: space-between;
    align-items: center;
    font-family: $font-family-book;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .item-name {
      flex: auto;
      height: 40px;
      padding: 10px 0 10px 10px;
    }

    .vasion-checkbox{
      margin: 0 !important;
    }

    .item-checkbox {
      flex: initial;
      height: 40px;
      padding: 10px 10px 10px 0;
    }
  }
  .list-item:hover, .list-item:focus {
    background-color: $surface-variant;
    color: $on-surface-variant;
    outline: none;
  }
  .multiselect-list-item {
    display: flex;
    text-align: left;
  }

  .checkbox {
    fill: $grey-400;
  }

  .selected-item {
    background-color: $surface-variant;
    color: $on-surface-variant;
    fill: $orange-300
  }
  .selected-item:focus {
    outline: solid 1px $primary;
  }

  .search-item {
    background: none !important;
    :hover{
      background: none !important;
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .vasion-droplist {
    position: relative;
  }

  .search-field {
    margin-left: 7px;
  }

  .add-item {
    display: block;
    justify-content: space-between;
    align-items: center;
    font-family: $font-family-book;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .add-item-text {
      background-color: $grey-50;
      display: flex;
      align-items: center;
      height: 40px;
      padding: 10px 0 10px 10px;
      color: $plumb-300;
    }

    .plus-icon {
      fill: $plumb-300;
      margin-right: 1.5rem;
    }

    .add-item-bottom-border {
      background-color: $grey-100;
      height: 2px;
      width:100%;
      margin: 5px 0 2px 0;
    }
  }

  .delete-icon {
    fill: $grey-700;
    margin-right: 1rem;
  }

.drag-item {
  border: 1px solid #dfdfdf;
  background: #f5f5f5;
}
.drag-item.hint {
  border: 1px solid #ffc49a;
  background: #feffb4;
}
.drag-item.active {
  border: 1px solid #ffa5a5;
  background: #ffe7e7;
}
.drag-item.selected {
  border: 1px solid #00bb9c;
  background: #08a34e;
  opacity: 0.5;
}
.chip-outline {
  border: 2px solid #FF8C4D !important;
}
</style>
