<template>
  <div id="vasion-select-workflow">
    <div id="header">
      <span id="header-title">Select Workflow</span>
      <VasionInput
        id="search-workflows"
        v-model="workflowNameFilter"
        class="search-field"
        name="search-workflows"
        placeholder="Search Workflows"
        inputType="search-gray"
        :width="'300'"
      />
    </div>

    <div id="main-section">
      <div
        v-for="(workflow, index) in menuItems"
        :key="index"
        class="row"
        :class="[selectedWorkflowID === workflow.iID ? 'active' : '']"
        @click="selectWorkflow(workflow.iID)"
      >
        <VasionWorkflowIcon class="workflow-icon" />
        <span class="workflow-name">{{ workflow.sName }}</span>
      </div>
    </div>

    <div id="vasion-select-workflow-footer">
      <div v-if="localShowErrorLabel" class="error-label-left">
        <h4>Cannot process this current workflow</h4>
      </div>
      <div class="buttons-right">
        <VasionButton
          id="cancel"
          name="cancel"
          :classProp="'secondary'"
          @vasionButtonClicked="cancelWorkflowSelect"
        >
          Cancel
        </VasionButton>

        <VasionButton
          id="continue"
          name="continue"
          :classProp="'primary'"
          :isDisabled="continueDisabled"
          @vasionButtonClicked="continueToWorkflowNextStep"
        >
          Continue
        </VasionButton>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VasionSelectWorkflow',
  props: {
    showErrorLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    workflowList: {
      type: Array,
      required: true,
      default: null,
    },
  },
  data: function () {
    return {
      localShowErrorLabel: this.showErrorLabel,
      selectedWorkflowID: 0,
      workflowNameFilter: '',
    }
  },
  computed: {
    continueDisabled() { return this.selectedWorkflowID <= 0 },
    menuItems() {
      const lowerFilter = this.workflowNameFilter.toLowerCase()

      return !this.workflowList ? []
        : this.workflowList.filter((element) => {
            return element.sName.toLowerCase().includes(lowerFilter)
          })
    },
  },
  created() {},
  methods: {
    cancelWorkflowSelect() {
      this.$emit('cancelButtonClick')
    },
    continueToWorkflowNextStep() {
      this.$emit('continueButtonClick', this.selectedWorkflowID)
    },
    selectWorkflow(workflowId) {
      this.localShowErrorLabel = false
      this.selectedWorkflowID = workflowId
    },
  },
}
</script>

<style lang="scss">
  //global CSS for several uses of start-workflow-select-dialog
  @import '@/assets/css/variables.scss';
  #start-workflow-select-dialog {
    width: 900px;
    max-height: 100%;

    .md-dialog-container {
       overflow: auto;
    }
  }
</style>

<style lang="scss" scoped>
  @import '@/assets/css/vasion-formio.scss';
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';

  #vasion-select-workflow {
    margin: 25px;
    z-index: 30;
    #header {
      height: 50px;

      #header-title {
        @include Headline;
        line-height: 50px;
        vertical-align: middle;
      }

      .search-field {
        padding-left: 5px;
        float: right;
        color: $grey-400;
        fill: $grey-400;
      }
    }

    @media(max-width: 705px){
    /* this is for the header width plus search workflows input width */
    #header {
      height: auto;
      display: flex;
      flex-direction: column;

      .search-field {
        padding-left: 0px;
        float: left;
        }
      }
      
      #main-section {
        margin-top: 60px;
      }
    }

    #main-section {
      margin-top: 10px;
      height: 301px;
      min-height: 200px;
      border-radius: 4px;
      border: solid 1px $grey-100;
      overflow-x: hidden;
      overflow-y: auto;

      .row {
        height: 40px;
        margin: 5px;
        color: $grey-500;
        fill: $grey-500;
        cursor: pointer;
        width: 99%;

        .workflow-icon {
          width: 20px;
          margin: 7px 25px 0 15px;
          float: left;
        }

        .workflow-name {
          @include SubtitleActive;
          float: left;
          line-height: 40px;
          vertical-align: middle;
        }

        &:hover, &.active {
          background-color: $primary-container !important;
          color: $on-primary-container !important;
          fill: $on-primary-container !important;
        }
      }
    }
  }

  #vasion-select-workflow-footer {
    margin: 10px;
    .error-label-left {
      float: left;
      color: red;
    }
    .buttons-right {
      float: right;
    }

    #back {
      margin-left: 0;
      padding-left: 0;

      button {
        margin-left: 0;
        padding-left: 0;
      }
    }

    #continue {
      margin-right: 0;
      padding-right: 0;

      button {
        margin-right: 0;
        padding-right: 0;
      }
    }
  }

</style>
