var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.sync === true)?_c('div',{key:_vm.modalKey,staticClass:"overlay",attrs:{"id":_vm.modalId}},[_c('div',{class:{
      'modal-container': true,
      'md-dialog': _vm.modalMdContainer
    }},[_c('div',{staticClass:"vasion-general-modal",class:_vm.modalType !== 'slot' ? 'dialog-div' : ''},[((_vm.modalType === 'slot' || _vm.showTitleAndMessage) && (_vm.modalTitle || _vm.showTopRightCloseButton))?_c('div',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.modalTitle)+" "),(_vm.showTopRightCloseButton)?_c('VasionClear',{attrs:{"id":"clear-icon"},on:{"click":function($event){return _vm.noButtonClick(true)}}}):_vm._e()],1):_vm._e(),(_vm.modalType === 'message' || _vm.showTitleAndMessage)?_c('span',{ref:"dialogMessage",staticClass:"message",class:{
          'message-margin-top': _vm.showTitleAndMessage,
          'left-align-text': _vm.leftAlignMessageText
        },attrs:{"id":"dialog-message"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e(),(_vm.modalType === 'slot')?_c('div',{class:[_vm.noYScroll ? 'slot-no-y-scroll' : 'slot'],on:{"slotComponentEmit":function($event){return _vm.yesButtonClick()}}},[_vm._t("default")],2):_vm._e(),(!_vm.hideButtons)?_c('div',{staticClass:"general-modal-footer",class:{
          'buttons-start': _vm.buttonGroup === 'start',
          'buttons-center': _vm.buttonGroup === 'center',
          'buttons-end': _vm.buttonGroup === 'end',
          'buttons-single': _vm.buttonGroup === 'single',
        }},[(!_vm.hideButtons && _vm.showCustomButton)?_c('VasionButton',{attrs:{"id":"custom-button","isDisabled":_vm.customButtonDisabled,"classProp":'secondary'},on:{"vasionButtonClicked":function($event){return _vm.customButtonClick()}}},[_vm._v(" "+_vm._s(_vm.customButtonText)+" ")]):_vm._e(),(!_vm.hideButtons && _vm.showRejectButton)?_c('VasionButton',{attrs:{"id":"reject-button","classProp":_vm.buttonGroup === 'single' ? 'primary' : 'secondary'},on:{"vasionButtonClicked":function($event){return _vm.noButtonClick(false)}}},[_vm._v(" "+_vm._s(_vm.rejectButtonText)+" ")]):_vm._e(),(!_vm.hideButtons && _vm.showConfirmButton)?_c('VasionButton',{attrs:{"id":"confirm-button","isDisabled":_vm.confirmButtonDisabled,"classProp":'primary'},on:{"vasionButtonClicked":function($event){return _vm.yesButtonClick()}}},[_vm._v(" "+_vm._s(_vm.confirmButtonText)+" ")]):_vm._e()],1):_vm._e()])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }