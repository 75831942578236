export const AdminSideNavOptions = {
  Processing: 'bpa',
  Capture: 'capture',
  DigitalSignature: 'digitalSignature',
  Search: 'search',
  Storage: 'storage',
  Integration: 'integration',
  Security: 'security',
  FormBuilder: 'adminFormsList',
  SystemSettings: 'systemSettings',
}
