<template>
  <div class="main-div">
    <VasionCalendar id="vasion-calendar" :propStartDate="localDueDate" @input="calendarChangedDate" />
    <table class="w100">
      <tr>
        <td class="align-left">
          <VasionCheckbox
            id="alert-requestor"
            name="alert-requestor"
            :checked="alertRequestor"
            @change="toggleAlertRequestor()"
          >
            Alert Requestor
          </VasionCheckbox>
        </td>
        <td class="cancel-and-ok-buttons">
          <VasionButton id="cancel-button" :classProp="'primary-light'" @vasionButtonClicked="cancelClick">
            Cancel
          </VasionButton>
          <VasionButton id="ok-button" :classProp="'primary-light'" @vasionButtonClicked="okClick">
            OK
          </VasionButton>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>

export default {
  name: 'SetDocumentDueDate',
  props: {
    propDocumentID: {
      required: true,
      type: Number,
      default: 0,
    },
    propDueDate: {
      required: false,
      type: Date,
      default: null,
    },
    propWorkflowID: {
      required: true,
      type: Number,
      default: 0,
    },
  },
  data: function () {
    return {
      alertRequestor: true,
      localDocumentID: 0,
      localDueDate: null,
      localWorkflowID: 0,
    }
  },
  computed: { },
  watch: {
    propDocumentID: function () {
      this.localDocumentID = this.propDocumentID
    },
    propDueDate: function () {
      this.localDueDate = this.propDueDate
    },
    propWorkflowID: function () {
      this.localWorkflowID = this.propWorkflowID
    },
  },
  created() {
    this.localDocumentID = this.propDocumentID
    this.localWorkflowID = this.propWorkflowID
    this.localDueDate = this.propDueDate
  },
  methods: {
    calendarChangedDate(newDate) {
      this.localDueDate = newDate
    },
    cancelClick() {
      this.$emit('cancelButtonClick')
    },
    okClick() {
      const payload = {
        documentID: this.localDocumentID,
        workflowID: this.localWorkflowID,
        dueDate: this.localDueDate,
      }

      this.$store.dispatch('workflow/saveWorkflowDueDate', payload)
      this.$emit('okButtonClick', this.localDueDate)
    },
    toggleAlertRequestor() {
      this.alertRequestor = !this.alertRequestor
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .main-div {
    background-color: $white;
  }

  .align-left {
    text-align: left;
  }

  .cancel-and-ok-buttons{
    text-align: right;
  }

  @media (max-width: $phone){
    .cancel-and-ok-buttons{
      text-align: right;
      display: flex;
    }
  }

  .w100 {
    width: 100%;
  }
</style>
