<template>
  <div>
    <table id="vasion-table">
      <tr class="headerRow">
        <th
          v-for="(header, index) in headerColumns"
          :key="index"
          :header="header.title"
          :md-label="header.title"
          class="table-header"
          :class="{ widerCheckboxColumn: isHeaderTextLong, widerColumns: hasManyColumns, singleWideColumn: shouldIncreaseColumnWidth(header) && hasManyColumns }"
          :style="{'text-align': header.textAlign, 'width': header.columnWidth}"
          @click="updateOrderByColumn(index)"
        >
          <div>
            <label :class="{'column-header': (columnToOrderBy === index && orderDirection != orderEnum.None)}">{{ header.title }}</label>
            <VasionArrowDropDownIcon v-show="columnToOrderBy === index && orderDirection === orderEnum.Descending" />
            <VasionArrowDropUpIcon v-show="columnToOrderBy === index && orderDirection === orderEnum.Ascending" />
          </div>
        </th>
      </tr>
      <tr
        v-for="(row, rowIndex) in orderedTableRows"
        :id="`row_${rowIndex}`"
        :key="rowIndex"
        class="iconFillColor table-rows"
        :class="{ 'vasion-lightest-gray-background': rowIndex % 2 === 0, 'selected-row': selectedRowIndex === rowIndex, 'table-rows-cursor-pointer': rowHoverPointer }"
      >
        <td
          v-for="(value, columnIndex) in row.Cells"
          :id="`column${columnIndex}`"
          :key="`emptyUser${columnIndex}`"
          class="edit-table-data"
          @click="rowCellClickedHandler(row, rowIndex, columnIndex)"
        >
          <!-- It's possible we may want to use AttributeFieldFilterItem for the different field types -->
          <div
            v-if="headerColumns[columnIndex].type === 1 || (headerColumns[columnIndex].type >= 6 && headerColumns[columnIndex].type <= 8) || headerColumns[columnIndex].type >= 11"
            :class="{ 'fcs-input': fixedColumnsSizes }"
          >
            <VasionInput
              v-model="value.cellData"
              inputType="blank-white"
              class="vasion-table-text"
              :backgroundColor="value.backgroundColor"
              :isDisabled="!value.canEdit"
              :specialType="getSpecialType(headerColumns[columnIndex].type)"
              :showURLLinkDefault="false"
              :hoverText="value.cellData"
              :extraTitleText="value.meanConfidence ? String(value.meanConfidence) : ''"
              @change="textboxChange(row, rowIndex, columnIndex)"
              @notValid="$emit('notValid', `value-${rowIndex}-${columnIndex}`)"
              @isValid="$emit('isValid', `value-${rowIndex}-${columnIndex}`)"
            />
          </div>
          <div
            v-else-if="headerColumns[columnIndex].type === 2"
            :class="{ 'fcs-drop-list': fixedColumnsSizes }"
          >
            <VasionDropList
              :id="`name-droplist-${rowIndex}-${columnIndex}`"
              v-slot="slotItem"
              v-model="value.cellData"
              :multiSelectInitialValue="!value.subType || value.subType.startsWith('check-list') ? value.cellData : []"
              class="td-set-width"
              :backgroundColor="value.backgroundColor"
              :isDisabled="!value.canEdit"
              :dataArray="value.dropdownValues"
              :type="value.subType ? value.subType : 'check-list'"
              displayName="name"
              valueName="value"
              width="100%"
              :initializeOnCreate="false"
              @input="droplistChange(row, rowIndex, columnIndex)"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>
          <div
            v-else-if="headerColumns[columnIndex].type === 3"
            :class="{ 'disable-div' : !value.canEdit, 'fcs-date-picker': fixedColumnsSizes }"
          >
            <VasionDateTimeField
              v-model="value.cellData"
              label=""
              type="date"
              :backgroundColor="value.backgroundColor"
              @input="datepickerChange(row, rowIndex, columnIndex)"
            />
          </div>
          <div
            v-else-if="headerColumns[columnIndex].type === 9"
          >
            <VasionCheckbox
              :id="`checkbox-${rowIndex}-${columnIndex}`"  
              style="display: flex; justify-content: center;"
              :checked="value.cellData"
              :noLabel="!Boolean(value.title)"
              :isDisabled="value.isDisabled ? value.isDisabled : false"
              @change="checkboxChange(row, rowIndex, columnIndex)"
            >
              <span v-if="value.title"> {{ value.title }} </span>
            </VasionCheckbox>
          </div>
          <div
            v-else
            style="font-family: Archivo;"
            :class="{ 'fcs-name': fixedColumnsSizes }"
            :title="value.cellData"
          >
            {{ value.cellData.length < 40 ? value.cellData : `${value.cellData.substring(0, 40)}...` }}
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
// eslint-disable-next-line no-restricted-globals
import { OrderByEnum } from '@/enums/orderByEnum.js'
import { orderBy } from 'lodash'
import { formatDateTime } from '@/store/helperModules/common.module'

export default {
  name: 'VasionEditableTable',
  components: {
  },
  filters: {
    formatDateToDisplay(val) {
      if (!val || val === '') {
        return val
      }

      return formatDateTime(val, 'date')
    },
  },
  props: {
    fixedColumnsSizes: {
      type: Boolean,
      default: false,
      required: true,
    },
    headerColumns: {
      type: Array,
      default: () => [],
      // Example: [
      //   {
      //     title: 'header',
      //     sortable: true,
      //     type: 1,
      //   },
      //   ...
      // ]
      required: false,
    },
    rowHoverPointer: {
      type: Boolean,
      default: true,
      required: false,
    },
    supportSorting: {
      type: Boolean,
      default: true,
      required: false,
    },
    tableRows: {
      type: Array,
      default: () => [],
      required: false,
      // Example: [
      //   {
      //     id: 123, (required if sorting to maintain selection)
      //     Cells: [
      //       {
      //         value: 'some example text',
      //         canEdit: true,
      //       },
      //       ...
      //     ],
      //   },
      //   ...
      // ]
    },
  },
  data: function () {
    return {
      columnToOrderBy: -1,
      hasManyColumns: false,
      isHeaderTextLong: false,
      orderDirection: OrderByEnum.None,
      orderEnum: OrderByEnum,
      selectedRow: null,
      selectedRowIndex: -1,
      tableRowsOrdered: [],
    }
  },
  computed: {
    orderedTableRows() {
      if (this.tableRows.length > 0 && this.tableRows[0]?.Cells?.length != this.headerColumns.length) {
        return
      }
      if (this.orderDirection === OrderByEnum.Ascending || this.orderDirection === OrderByEnum.Descending){
        if (this.columnToOrderBy >= 0) {
          return orderBy(this.tableRows, this.compareCellData(this.columnToOrderBy), this.orderDirection === OrderByEnum.Ascending ? 'asc' : 'desc')
        }
      }
      return this.tableRows
    }
  },
  watch: { 
    orderedTableRows: function(newRows) {
      if (!newRows || newRows.length == 0) {
        this.selectedRow = null
        this.selectedRowIndex = -1
      }
      if (this.selectedRow) {
        this.selectedRowIndex = newRows.findIndex(row => row.id == this.selectedRow.id)
      }
      
      if (this.selectedRowIndex >= 0) {
        return
      }
      if (this.tableRows.length > 0) {
        this.rowCellClickedHandler(this.tableRows[0], 0, 0)
      } else {
        this.selectedRow = null
      }
    }
  },
  created() {
    this.$material.locale.dateFormat = this.$store.state.systemSettings.systemDateFormat
    this.hasManyColumns = this.headerColumns.length > 20
    if (this.tableRows.length > 0) {
      this.rowCellClickedHandler(this.tableRows[0], 0, 0)
    }
  },
  methods: {
    async checkboxChange(rowData, rowIndex, columnIndex) {
      rowData.Cells[columnIndex].cellData = !rowData.Cells[columnIndex].cellData
      this.$emit('cellValueChanged', { rowData: rowData, rowIndex: rowIndex, columnIndex: columnIndex })
    },
    async datepickerChange(rowData, rowIndex, columnIndex) {
      if (!rowData.Cells[columnIndex].cellData) {
        rowData.Cells[columnIndex].cellData = ""
      }
      this.$emit('cellValueChanged', { rowData: rowData, rowIndex: rowIndex, columnIndex: columnIndex })      
    },
    async droplistChange(rowData, rowIndex, columnIndex)
    {
      this.$emit('cellValueChanged', { rowData: rowData, rowIndex: rowIndex, columnIndex: columnIndex })
    },
    compareCellData(columnIndex) {
      return function (rowData) {
        if (!rowData.Cells[columnIndex].cellData) {
          return ""
        }
        if (Array.isArray(rowData.Cells[columnIndex].cellData) && rowData.Cells[columnIndex].cellData.length > 0) {
          return rowData.Cells[columnIndex].cellData[0].name
        }
        if (typeof rowData.Cells[columnIndex].cellData == 'object' && rowData.Cells[columnIndex].cellData.name) {
          return rowData.Cells[columnIndex].cellData.name
        }
        
        return rowData.Cells[columnIndex].cellData
      }
    },
    getSpecialType(type)
    {
      switch(type) {
        case 6:  return 'number'
        case 7:  return 'url'
        case 8:  
        case 12:  
          return 'email'
        case 11: return 'currency'
        case 14: return 'address'
        default: return ''
      }
    },
    markDirty() {
      this.$emit('dirty')
    },
    rowCellClickedHandler(rowData, rowIndex, columnIndex) {
      this.selectedRowIndex = rowIndex
      this.selectedRow = rowData
      this.$emit('rowCellClicked', {rowData: rowData, rowIndex: rowIndex, columnIndex: columnIndex})
    },
    shouldIncreaseColumnWidth(header) {
      return header.title.length >= 10
    },
    async textboxChange(rowData, rowIndex, columnIndex) {
      this.$emit('cellValueChanged', { rowData: rowData, rowIndex: rowIndex, columnIndex: columnIndex })      
    },
    updateOrderByColumn(column) {
      if (!this.supportSorting || this.headerColumns.length < column || !this.headerColumns[column].sortable) {
        //the idea is that we have to explicitly specify whether a column is sortable or not
        return
      }

      if (this.columnToOrderBy === -1 || this.columnToOrderBy !== column) {
        this.columnToOrderBy = column
        this.orderDirection = OrderByEnum.Ascending
      } else {
        switch (this.orderDirection) {
          case OrderByEnum.Ascending:
            this.orderDirection = OrderByEnum.Descending
            break
          case OrderByEnum.Descending:
            this.orderDirection = OrderByEnum.None
            break
          case OrderByEnum.None:
          default:
            this.orderDirection = OrderByEnum.Ascending
            break
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';

  .selected-row {
    background-color: $primary-container;
  }

  .widerCheckboxColumn {
    max-width: 90px;
    width: 90px;
  }
  .widerColumns {
    min-width: 100px;
  }
  .singleWideColumn {
    min-width: 210px;
  }
  .headerRow {
    background-color: $table-header-bg;
    color: $table-header-color;
  }

  .iconFillColor{
    fill: $table-body-color;
  }

  .vasion-table-text {
    cursor: pointer;
    width: 100%;
    min-height: 20px;
    margin-left: -2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .vasion-ellipsis-button {
    width: 32px;
    height: 32px;
    padding: 0px;

    svg {
      margin: 4px 0 0 4px;
    }

    &:hover {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: $orange-100;
    }
  }

  #vasion-table {
    width: 100%;
  }
  .table-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: $table-header-bg;
    color: $table-header-color;
    padding: 15px 8px 15px 8px;
    white-space: nowrap;
  }
  .edit-table-data {
    min-width: 100px;
    height: 76px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .table-rows:hover {
    background-color: $grey-100;
    color: $grey-500;
  }
  .table-rows-cursor-pointer {
    cursor: pointer;
  }
  .disable-div {
    pointer-events: none;
    opacity: 0.4;
  }
  .column-header {
    position: relative;
    bottom: 6px;
  }
  // fcs: fixed column size
  .fcs-input {
    width: 200px;
  }
  .fcs-date-picker {
    width: 170px;
  }
  ::v-deep .fcs-date-picker .md-datepicker {
    margin-bottom: 0;
  }
  .fcs-drop-list {
    min-width: 100px;
    width: 250px;
  }
  .fcs-name {
    max-width: 200px;
  }
</style>
