/* eslint-disable consistent-return */
import { createAxiosInstance } from '@/services/axiosService'

const getAllMaxxForms = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('/v1/Document/LoadAllEForms', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getEncryptedUsername = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('/v1/auth/GetEncryptedUserName', null)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getMaxxFormDetails = async (apiToken, maxxFormID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const payload = {
      IncludeUserSecurity: true,
      EFormID: maxxFormID,
      IncludeFields: true,
    }

    const { data } = await axios.post('v1/Document/LoadEForm', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const loadAllFormsForUser = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Document/LoadAllEFormsForUser', payload)
    if (response?.data && response.data.EFormCollection && response.data.EFormCollection.Values) {
      return response.data.EFormCollection.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const submitMaxxForm = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Document/SubmitEForm', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

export {
  getAllMaxxForms,
  getEncryptedUsername,
  getMaxxFormDetails,
  loadAllFormsForUser,
  submitMaxxForm,
}
