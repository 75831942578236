<template>
  <div class="vertical-scroll">
    <VasionButton
      v-if="showCreateNewButton"
      id="create-storage"
      class="no-margin-top"
      :classProp="isMobile ? 'primary' : 'secondary-transparent'"
      :isFullWidth="true"
      :isDisabled="disableCreateSubfolderDialog"
      @vasionButtonClicked="toggleCreateFolder"
    >
      <span class="vasion-button-plus">+</span>CREATE NEW
    </VasionButton>

    <VasionFolders
      :isInLeftNav="true"
      :disableLoader="true"
      :routeToFolderOnSelection="routeToFolder"
      @itemSelected="handleFolderSelected"
    />

    <md-dialog id="create-new-sub-storage" :md-active.sync="showCreateSubFolder" :md-click-outside-to-close="false">
      <VaultConfig @closeWindow="toggleCreateFolder" @saved="toggleCreateFolder" />
    </md-dialog>

    <md-dialog id="create-new-top-storage" :md-active.sync="showCreateTopFolder" :md-click-outside-to-close="false" class="vasion-parent-folder-div">
      <TheParentFolder
        :isModal="true"
        :defaultDriveLocationId="selectedStorageConfigId"
        @close="toggleCreateFolder"
        @saved="handleCreateTopFolderSave"
      />
    </md-dialog>

    <VasionSnackbar
      id="delete-folder-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import TheParentFolder from '@/views/storage/TheParentFolder.vue'
import VaultConfig from '@/components/vault/VaultConfig.vue';

export default {
  name: 'StorageSideNav',
  components: {
    TheParentFolder,
    VaultConfig,
  },
  props: {
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      folderID: 0,
      routeToFolder: true,
      selectedFolderName: '',
      showBoxFolders: false,
      showCreateSubFolder: false,
      showCreateTopFolder: false,
      showSnackbarBool: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      sortAscending: true,
      sortByColumn: '',
      storageid: '',
      toggleTopByDefault: false,
    }
  },
  computed: {
    disableCreateSubfolderDialog() {
      if (this.$store.state.common.selectedFolder) {
        return false
      } else if (this.$store.getters['common/canConfigureFolders']) {
        return false
      } else {
        return true
      }
    },
    folderAncestorsList() { return this.$store.state.vault.folderAncestorsList },
    pageSize: {
      get: function () {
        return this.$store.state.common.pageSize
      },
      set: function (newSize) {
        this.$store.dispatch('common/setPageSize', newSize)
      },
    },
    selectedFolder() { return this.$store.state.common.selectedFolder },
    selectedStorageConfigId() { return this.$store.state.common.selectedStorageConfigId },
    showCreateNewButton() { return this.$store.getters['common/canCreateSubfolder'] },
    storageSideNavRefresh() { return this.$store.state.common.storageSideNavRefresh },
  },
  created() {
    this.$store.dispatch('vault/setActiveFolderName', 'Vasion Drive')
    if (!this.$store.state.vault.vaultFoldersPayload || !this.$store.state.vault.vaultFoldersPayload.FolderId) {
      this.setFolder({value: 0, storageConfigId: 0})
    } else {
      this.folderID = this.$store.state.vault.vaultFoldersPayload.FolderId
      this.sortAscending = this.$store.state.vault.vaultFoldersPayload.Ascending
      this.sortByColumn = this.$store.state.vault.vaultFoldersPayload.OrderBy
    }
    this.$store.dispatch('common/setSelectedFolder', null)
  },
  methods: {
    async handleCreateTopFolderSave(parentFolderName, driveStorageConfig) {
      this.snackbarTitle = 'Parent Folder Saved Successfully'
      this.snackbarSubTitle = `Saved "${parentFolderName}" to ${driveStorageConfig?.name ?? driveStorageConfig}`
      this.snackbarImage = true
      this.showSnackbarBool = true
      this.showNewTopFolderDialog = false
      this.showCreateTopFolder = !this.showCreateTopFolder
    },
    async handleFolderSelected(folder) {
      if (!folder || !folder.isSelected) {
        return
      }
      this.storageid = folder.storageid
      this.$store.dispatch('vault/setActiveFolderName', folder.name)
      await this.setFolder(folder)
    },
    async setFolder(folder) {
      if (!this.$store.state.vault.searchingSubfolders) {
        this.folderID = folder.value
        await this.$store.dispatch('vault/setActiveFolderId', this.folderID)
        this.$store.dispatch('vault/setActiveStorageId', this.storageid)
        this.sortAscending = true
        this.sortByColumn = ''
        this.updateFolderData(1, folder.storageConfigId)
      }
    },
    toggleCreateFolder() {
      if (!this.showDialog && this.disabledCreateSubfolderDialog) {
        return
      }
      if (this.$store.state.common.selectedFolder && this.$store.state.common.selectedFolderId !== 0) {
        this.showCreateSubFolder = !this.showCreateSubFolder
      } else {
        this.showCreateTopFolder = !this.showCreateTopFolder
      }
    },
    async updateFolderData(displayPageNumber, storageConfigId) {
      if (displayPageNumber <= 0) {
        displayPageNumber = 1
      }
      const payload = {
        FolderId: 0,
        Skip: (displayPageNumber - 1) * this.pageSize,
        Take: this.pageSize,
        OrderBy: this.sortByColumn,
        Ascending: this.sortAscending,
        storageid: '',
        DisplayName: this.selectedFolderName,
      }
      if (!payload.DisplayName && this.$store.state.vault.folderDisplayName) {
        payload.DisplayName = this.$store.state.vault.folderDisplayName
      }

      if (typeof this.folderID !== 'undefined' && this.folderID > 0) {
        payload.FolderId = this.folderID
        payload.storageid = this.storageid
        this.$store.dispatch('vault/getFolderContents', payload)
      } else {
        // else It is folderId=0 but we have a storageConfigId meaning it isn't a folder, but a Drive Location
        await this.$store.dispatch('vault/getRootFolderContents', storageConfigId)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/light-theme-system-palette.scss';

#create-storage {
  padding: 0px 1px 0px 0px;
  top: 0px;
  z-index: 20;
}
</style>
