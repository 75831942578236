import { getToken } from '@/store/helperModules/common.module'

import {
  buildGenericTableData,
  buildWorkflowTableData,
  streamPDF,
} from '@/store/helperModules/reporting.module'

import {
  doDeleteAuditReport,
  doDeleteCustomReport,
  doDeleteCustomReportByName,
  doDeleteWorkflowReport,
  doGenerateAuditReportPDF,
  doGenerateGenericReportPDF,
  doGenerateWorkflowReportPDF,
  doGetAllAuditReports,
  doGetAllCustomReports,
  doGetAllReports,
  doGetAllWorkflowReports,
  doGetAllReportsForAutomation,
  doGetAuditActions,
  doGetAuditReport,
  doGetWorkflows,
  doGetWorkflowsWithIndexForms,
  doGetWorkflowReport,
  doLoadAllCustomReportDetails,
  doRunAuditReport,
  doRunGenericReport,
  doRunWorkflowReport,
  doSaveAuditReport,
  doSaveCustomReport,
  doSaveWorkflowReport,

 } from '@/store/apiModules/reporting.api'

 function initialState() {
  return {
    allReportsList: [],
    adminCustomReports: [],
    auditActions: [],
    auditReport: {},
    auditReports: [],
    auditReportData: null,
    customReports: [],
    documentControlReports: [],
    documentReports: [],
    editingAuditReport: false,
    editingWorkflowReport: false,
    genericReport: [],
    indexFieldFilters: [],
    newFormId: 0,
    reportingSideNav: '',
    savedAuditReports: [],
    savedWorkflowReports: {},
    scheduledReport: '',
    selectedCustomReportConfig: null,
    selectedIndexFieldsForExtract: [],
    selectedRunCustomReport: {},
    selectedTabName: '',
    userReports: [],
    workflowReport: [],
    workflowReportData: null,
    workflowReportSummary: '',
    workflows: [],
  }
}

const reporting = {
  namespaced: true,
  state: initialState,
  mutations: {
    changeReportingSideNav(state, value) { state.reportingSideNav = value },
    removeCustomReportDetail(state, reportID) {
      if (state.adminCustomReports) {
        state.adminCustomReports = state.adminCustomReports
          .filter(report => report.ReportID !== reportID)
      }
    },
    replaceCustomReport(state, report) {
      if (!state.adminCustomReports || !report || !report.ReportID || report.ReportID <= 0) {
        return
      }

      state.adminCustomReports = state.adminCustomReports.map((adminReport) => {
        if (adminReport.ReportID === report.ReportID) {
          return report
        } else {
          return adminReport
        }
      })
    },
    resetReports(state) {
      state.workflowReport = {}
      state.auditReport = {}
      state.genericReport = {}
    },
    resetStoreData(state) {
      const localState = initialState()
      Object.keys(state).forEach((prop) => {
        state[prop] = localState[prop]
      })
    },
    setAllAuditReports(state, reports) { state.savedAuditReports = reports },
    setAllReportsList(state, [allReports, customReports]) { state.allReportsList = [...allReports, ...customReports] },
    setAllWorkflowReports(state, value) { state.savedWorkflowReports = value },
    setAuditActions(state, value) { state.auditActions = value },
    setAuditReport(state, reportData) { state.auditReport = buildWorkflowTableData(reportData) },
    setAuditReportData(state, value) { state.auditReportData = value },
    setCustomReportDetails(state, customReportDetails) { state.adminCustomReports = customReportDetails },
    setEditingAuditReport(state, value) { state.editingAuditReport = value },
    setEditingWorkflowReport(state, value) { state.editingWorkflowReport = value },
    setGenericReport(state, reportData) { state.genericReport = buildGenericTableData(reportData) },
    setGenericReportForm(state, reportData) { state.genericReportForm = reportData },
    setIndexFieldFilters(state, fields) { state.indexFieldFilters = fields },
    setReportsLists(state, [allReports, customReports, auditReports]) {
      state.customReports = customReports
      state.userReports = allReports.filter(report => report.sReportType === 'User')
      state.documentControlReports = allReports.filter(report => report.sReportType === 'Document Control')
      state.documentReports = allReports.filter(report => report.sReportType === 'Document' && customReports.filter(e => e.sName === report.sName).length === 0)
      state.auditReports = auditReports
    },
    setScheduledReport(state, value) { state.scheduledReport = value },
    setSelectedIndexFieldsForExtract(state, fields) { state.selectedIndexFieldsForExtract = fields },
    setSelectedReportConfig(state, value) { state.selectedCustomReportConfig = value },
    setSelectedRunCustomReport(state, value) { state.selectedRunCustomReport = value },
    setSelectedTabName(state, value) { state.selectedTabName = value },
    setWorkflowList(state, workflows) { state.workflows = workflows },
    setWorkflowReport(state, reportData) {
      state.workflowReportSummary = `${reportData.totalRecords} Document(s) with an average span of ${reportData.days} Days, ${reportData.hours} Hours, and ${reportData.minutes} Minutes.`
      state.workflowReport = buildGenericTableData(reportData.dataTable)
    },
    setWorkflowReportData(state, value) { state.workflowReportData = value },
  },
  actions: {
    changeReportingSideNav({ commit }, value) { commit('changeReportingSideNav', value) },
    async deleteAuditReport({ rootState }, reportID) {
      const data = await doDeleteAuditReport(getToken(rootState.common.apiToken), reportID)
      return data
    },
    async deleteCustomReport({ commit, rootState }, reportID) {
      const data = await doDeleteCustomReport(getToken(rootState.common.apiToken), reportID)
      if (data) {
        commit('removeCustomReportDetail', reportID)
      }
      return data
    },
    async deleteCustomReportByName({ rootState }, reportName) {
      const data = await doDeleteCustomReportByName(getToken(rootState.common.apiToken), reportName)
      return data
    },
    async deleteWorkflowReport({ rootState }, reportID) {
      const data = await doDeleteWorkflowReport(getToken(rootState.common.apiToken), reportID)
      return data
    },
    async generateAuditReportPDF({ rootState }, payload) {
      const response = await doGenerateAuditReportPDF(getToken(rootState.common.apiToken), payload)
      if (response) {
        streamPDF(response)
      }
      return response
    },
    async generateGenericReportPDF({ rootState }, payload) {
      payload.iUserId = rootState.common.userID
      payload.sUserName = rootState.common.userName
      const response = await doGenerateGenericReportPDF(getToken(rootState.common.apiToken), payload)
      if (response) {
        streamPDF(response)
      }
      return response
    },
    async generateWorkflowReportPDF({ rootState }, payload) {
      const response = await doGenerateWorkflowReportPDF(getToken(rootState.common.apiToken), payload)
      if (response) {
        streamPDF(response)
      }
      return response
    },
    async getAllReports({ commit, rootState }) {
      const [allReportsRes, allCustomReportsRes] = await Promise.all([
        doGetAllReports(getToken(rootState.common.apiToken)),
        doGetAllCustomReports(getToken(rootState.common.apiToken)),
      ])
      return commit('setAllReportsList', [allReportsRes.Reports, allCustomReportsRes.Reports])
    },
    async getAllReportsForAutomation({ rootState }) {
      const allReportsRes = await doGetAllReportsForAutomation(getToken(rootState.common.apiToken))
      return allReportsRes.Reports
    },
    async getAllSavedAuditReports({ commit, rootState }, enforceSecurity) {
      const reports = await doGetAllAuditReports(rootState.common.apiToken, enforceSecurity)
      commit('setAllAuditReports', reports)
    },
    async getAllSavedWorkflowReports({ rootState, commit }, enforceSecurity) {
      const response = await doGetAllWorkflowReports(rootState.common.apiToken, enforceSecurity)
      commit('setAllWorkflowReports', response)
    },
    async getAuditActions({ commit, rootState }) {
      const data = await doGetAuditActions(getToken(rootState.common.apiToken))
      if (data) {
        commit('setAuditActions', data.Values)
      }
    },
    async getSavedAuditReport({ rootState }, auditReportId) {
      return doGetAuditReport(rootState.common.apiToken, auditReportId)
    },
    async getSavedWorkflowReport({ rootState }, workflowReportId) { return doGetWorkflowReport(rootState.common.apiToken, workflowReportId) },
    async getWorkflows({ commit, rootState }) {
      const workflowList = []
      const data = await doGetWorkflows(getToken(rootState.common.apiToken))

      if (data && data.Workflows) {
        Object.keys(data.Workflows).map((key) => {
          workflowList.push({
            name: data.Workflows[key],
            value: Number(key),
          })
          return true
        })
        workflowList.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      }
      commit('setWorkflowList', workflowList)
    },
    async getWorkflowsWithIndexForms({ commit, rootState }) {
      const workflowList = []
      const data = await doGetWorkflowsWithIndexForms(getToken(rootState.common.apiToken))

      if (data && data.Workflows) {
        data.Workflows.map((key) => {
          workflowList.push({
            name: key.Name,
            value: Number(key.WorkflowId),
            indexForm: key.IndexFormId
          })
          return true
        })
        workflowList.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      }
      commit('setWorkflowList', workflowList)
    },
    async loadAllCustomReportDetails({ commit, rootState }) {
      const data = await doLoadAllCustomReportDetails(getToken(rootState.common.apiToken))
      if (data) {
        commit('setCustomReportDetails', data.reports)
      }
    },
    async loadReports({ commit, rootState }) {
      const [allReportsRes, allCustomReportsRes, auditReports] = await Promise.all([
        doGetAllReports(getToken(rootState.common.apiToken)),
        doGetAllCustomReports(getToken(rootState.common.apiToken)),
        doGetAllAuditReports(rootState.common.apiToken, true),
      ])
      return commit('setReportsLists', [allReportsRes.Reports, allCustomReportsRes.Reports, auditReports])
    },
    async populateWorkflowReportData({ dispatch }) {
      await Promise.all([
        dispatch('attributeForm/getForms', null, { root: true }),
        dispatch('getWorkflowsWithIndexForms'),
        dispatch('common/getUsers'),
      ])
    },
    replaceCustomReport({ commit }, report) { commit('replaceCustomReport', report) },
    resetReports({ commit }) { commit('resetReports') },
    async runAuditReport({ commit, rootState }, payload) {
      const data = await doRunAuditReport(getToken(rootState.common.apiToken), payload)
      if (data) {
        commit('setAuditReport', data)
      }
    },
    async runGenericReport({ commit, rootState }, payload) {
      const data = await doRunGenericReport(getToken(rootState.common.apiToken), payload)
      if (data) {
        commit('setGenericReport', data)
      }
    },
    async runWorkflowReport({ commit, rootState }, payload) {
      const data = await doRunWorkflowReport(getToken(rootState.common.apiToken), payload)
      if (data) {
        commit('setWorkflowReport', data)
      }
    },
    async saveAuditReport({ rootState }, payload) {
      const response = await doSaveAuditReport(getToken(rootState.common.apiToken), payload)
      return response
    },
    async saveCustomReport({ rootState }, payload) {
      const response = await doSaveCustomReport(getToken(rootState.common.apiToken), payload)
      return response
    },
    async saveWorkflowReport({ rootState }, payload) { return doSaveWorkflowReport(getToken(rootState.common.apiToken), payload) },
    setAuditReportData({ commit }, data) { commit('setAuditReportData', data) },
    setEditingWorkflowReport({ commit }, value) { commit('setEditingWorkflowReport', value) },
    setEditingAuditReport({ commit }, value) { commit('setEditingAuditReport', value) },
    setGenericReportForm({ commit }, reportData) { commit('setGenericReportForm', reportData) },
    setIndexFieldFilters({ commit }, fields) { commit('setIndexFieldFilters', fields) },
    setScheduledReportName({ commit }, name) { commit('setScheduledReport', name) },
    setSelectedIndexFieldsForExtract({ commit }, fields) { commit('setSelectedIndexFieldsForExtract', fields) },
    setSelectedReportConfig({ commit, rootState }, value) {
      let data
      if (rootState.reporting.adminCustomReports) {
        data = rootState.reporting.adminCustomReports
          .find(element => element.ReportID === value)
      }

      if (typeof data === 'undefined') {
        data = {
          ReportID: 0,
          FolderID: null,
          FolderName: '',
          SearchSubfolders: false,
          IndexFormID: 0,
          ReportName: '',
          AccessUserIDs: [],
          AccessGroupIDs: [],
          OrderedDisplayFields: [],
          OrderedOrderByFields: [],
          SearchFields: [],
        }
      }
      commit('setSelectedReportConfig', data)
    },
    async setSelectedReportConfigByName({ commit, rootState }, reportName) {
      let data
      if (rootState.reporting.adminCustomReports) {
        data = rootState.reporting.adminCustomReports
          .find(element => element.ReportName === reportName)
      }

      if (!data) {
        const responseData = await doLoadAllCustomReportDetails(getToken(rootState.common.apiToken))
        if (responseData) {
          commit('setCustomReportDetails', responseData.reports)
        }
      }

      if (rootState.reporting.adminCustomReports) {
        data = rootState.reporting.adminCustomReports
          .find(element => element.ReportName === reportName)
      }

      if (!data) {
        data = {
          ReportID: 0,
          FolderID: null,
          FolderName: '',
          SearchSubfolders: false,
          IndexFormID: 0,
          ReportName: '',
          AccessUserIDs: [],
          AccessGroupIDs: [],
          OrderedDisplayFields: [],
          OrderedOrderByFields: [],
          SearchFields: [],
        }
      }
      commit('setSelectedReportConfig', data)
    },
    setSelectedRunCustomReport({ commit, rootState }, reportName) {
      let data
      if (rootState.reporting.customReports) {
        data = rootState.reporting.customReports.find(element => element.sName === reportName)
      }

      if (!data) {
        data = {}
      }

      commit('setSelectedRunCustomReport', data)
    },
    setSelectedRunWorkflowReport({ commit }, report) {
      if (!report) {
        report = {}
      }

      commit('setSelectedRunCustomReport', report)
    },
    setSelectedTabName({ commit }, name) {
      commit('setSelectedTabName', name)
    },
    setWorkflowReportData({ commit }, data) { commit('setWorkflowReportData', data) },
  },
  getters: {
    getScheduledReportingProps: () => {
      const data = {
        hiddenColumns: ['_WorkflowID_', 'Import / Export'],
        buttons: [{ name: 'Scheduled Report', destination: 'EditReportScheduledReporting' }],
        scheduledType: '1223',
      }
      return data
    },
  },
}

export default reporting
