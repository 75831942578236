<template>
  <div>
    <div class="inner-div">
      <div v-if="hasFormFields">
        <div v-for="(field, index) in localFieldArray" :key="index" class="loop-div">
          <span v-if="field.FieldType !== 'SignatureButton' && field.FieldType !== 'Image'">
            <label>{{ getFieldLabel(field) }}</label><label class="error-text">{{ localErrorMessages[index] }}</label>
          </span>
          <div v-if="field.FieldType === 'TextBox'">
            <VasionInput
              :id="`text_field_${field.EFormFieldID}`"
              v-model="localFieldValues[index]"
              class="input-style"
              inputType="top-white"
              width="100%"
              :name="`text_field_${field.EFormFieldID}`"
              :placeholder="getFieldPlaceholderText(field)"
            />
          </div>
          <div v-else-if="field.FieldType === 'RadioButton'">
            <div>
              <md-radio
                v-for="(radioValue, radioIndex) in getFieldMultiValues(field)"
                :key="radioIndex"
                v-model="localFieldValues[index]"
                :value="radioValue"
              >
                {{ radioValue }}
              </md-radio>
            </div>
          </div>
          <div v-else-if="field.FieldType === 'CheckBox'" class="checkbox-div">
            <div>
              <VasionCheckbox
                v-for="(checkBoxValue, checkBoxIndex) in getFieldMultiValues(field)"
                :id="`checkbox_${field.EFormFieldID}`"
                :key="checkBoxIndex"
                :checked="false"
                @change="toggleCheckBoxSelection(checkBoxValue, index)"
              >
                {{ checkBoxValue }}
              </VasionCheckbox>
            </div>
          </div>
          <div v-else-if="field.FieldType === 'Image'">
            <div>
              <img class="max-w200" :src="getImageData(field)">
            </div>
          </div>
          <div v-else-if="field.FieldType === 'ComboBox'">
            <md-field>
              <label for="items">{{ getFieldPlaceholderText(field) }}</label>
              <md-select id="items" v-model="localFieldValues[index]" name="items">
                <md-option v-for="(item, i) in localDropDownArray[index]" :key="i" :value="item.name">
                  {{ item.name }}
                </md-option>
              </md-select>
            </md-field>
          </div>
          <div v-else-if="field.FieldType === 'TextArea'">
            <div>
              <textarea
                v-model="localFieldValues[index]"
                class="vasion-textarea"
                :placeholder="getFieldPlaceholderText(field)"
                rows="4"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        No fields to display.
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MaxxFormFieldsDisplay',
  components: {
  },
  props: {
    propMaxxFormFields: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data: function () {
    return {
      localDropDownArray: [],
      localErrorMessages: [],
      localFieldArray: [],
      localFieldValues: [],
      signatureIndex: 0,
      testInput: '',
    }
  },
  computed: {
    hasFormFields: function () { return this.localFieldArray && this.localFieldArray.length > 0 },
  },
  watch: {
    propMaxxFormFields: function () {
      this.updateLocalFieldsFromProp()
    },
  },
  created: function () {
    this.updateLocalFieldsFromProp()
  },
  methods: {
    getFieldLabel(field) {
      return field.IsRequired === true ? `${field.LabelText} *` : field.LabelText
    },
    getFieldMultiValues(field) {
      return field && field.MiscAttributes && field.MiscAttributes.Options ? field.MiscAttributes.Options.split('|') : []
    },
    getFieldPlaceholderText(field) {
      return field.MiscAttributes && field.MiscAttributes.PlaceHolder ? field.MiscAttributes.PlaceHolder : ''
    },
    getImageData(field) {
      return field && field.MiscAttributes && field.MiscAttributes.ImageData ? field.MiscAttributes.ImageData : ''
    },
    toggleCheckBoxSelection(value, fieldIndex) {
      if (!value || !this.localFieldValues || this.localFieldValues.length <= fieldIndex) {
        return
      }

      const currentValueArray = this.localFieldValues[fieldIndex].split('|')
      const itemIndex = currentValueArray.indexOf(value)

      if (itemIndex >= 0) {
        // the item already exists, so we're removing it
        currentValueArray.splice(itemIndex, 1)
        this.localFieldValues[fieldIndex] = ''

        if (currentValueArray.length > 0) {
          let newValue = ''
          currentValueArray.map((item) => {
            newValue = `${newValue}|${item}`
            return true
          })

          this.localFieldValues[fieldIndex] = newValue.substring(1) // remove the leading '|'
        }
      } else {
        // append the item to the end of the list
        this.localFieldValues[fieldIndex] = this.localFieldValues[fieldIndex] === '' ? value : `${this.localFieldValues[fieldIndex]}|${value}`
      }
    },
    updateLocalFieldsFromProp() {
      this.localFieldValues = []
      this.localFieldArray = []
      this.localDropDownArray = []
      this.localErrorMessages = []
      this.localFieldArray = this.propMaxxFormFields.map((field) => {
        this.localErrorMessages.push('')

        if (field.FieldType === 'ComboBox') {
          const optionsArray = this.getFieldMultiValues(field)

          this.localFieldValues.push('')
          this.localDropDownArray.push(optionsArray.map((option) => {
            return { name: option }
          }))
        } else {
          this.localFieldValues.push('')
          this.localDropDownArray.push([])
        }

        return field
      })
    },
    validateAndGetFieldData() {
      if (!this.localFieldArray || this.localFieldArray.length === 0) {
        return null
      }
      const fieldResultsArray = {}
      let validData = true
      this.localErrorMessages = []

      // using a for loop because the index is important
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.localFieldArray.length; i++) {
        let fieldValue = ''

        if (this.localFieldArray[i].FieldType === 'ComboBox') {
          fieldValue = !this.localFieldValues[i] ? '' : this.localFieldValues[i]
        } else if (this.localFieldArray[i].FieldType !== 'Image') {
          fieldValue = this.localFieldValues[i]
        }
        if (this.localFieldArray[i].IsRequired === true && fieldValue === '') {
          this.localErrorMessages.push(' - Required Field')
          validData = false
        } else {
          this.localErrorMessages.push('')
        }

        fieldResultsArray[this.localFieldArray[i].EFormFieldID] = fieldValue
      }
      return validData === true ? fieldResultsArray : null
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';

  .checkbox-div {
    padding-top: 10px;
  }

  label {
    height: 12px;
    font-family: $font-family-medium, 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: $grey-400;
    margin-bottom: 2px;
    position: relative;
    bottom: 1px;
  }

  .error-text {
    color: $red-500;
  }

  .inner-div {
    margin: 15px
  }

  .loop-div {
    margin: 20px 20px 0 0;
  }

  .max-w200 {
    max-width: 200px
  }

  ::v-deep .md-radio.md-theme-default.md-checked .md-ripple {
    color: $secondary !important;
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-radio-container {
    border-color: $secondary !important;
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-radio-container:after {
    background-color: $secondary !important;
  }
</style>
