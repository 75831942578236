import Vue from 'vue'

import {
    addSignatureDocumentHistory,
    addTaskListsToDocument,
    addTaskToDoc,
    approveDocuments,
    approveDocumentsWithSteps,
    approveSignatureDocument,
    burnAnnotations,
    canRemoveAttributeField,
    checkDocumentFlags,
    checkFileExtension,
    completeTask,
    copyDocuments,
    copyEFormPdf,
    createVersion,
    createVersionAttributeFormPDF,
    deleteAnnotationImage,
    deleteDocCacheFiles,
    deleteDocumentLink,
    deleteDocumentNote,
    deleteDocumentPages,
    deleteMultipleDocuments,
    deleteSignatureDocumentTemplate,
    copySignatureDocumentTemplate,
    downloadAttributeFormDocument,
    downloadVasionDocument,
    emailAttributeForm,
    emailMultiDocument,
    emailSingleDocument,
    exportDocumentHistoryCSV,
    exportDocumentHistoryPDF,
    exportSignatureDocumentHistory,
    exportWorkflowHistoryPDF,
    getAnnotationImageList,
    getDefaultLookupId,
    getDocumentDetails,
    getDocumentDetailsByGuid,
    getDocumentDetailsWithPayload,
    getDocumentDetailsWithVersion,
    getDocumentDownloadCount,
    getDocumentDueDate,
    getDocumentHistory,
    getDocumentImageURL,
    getDocumentLineItems,
    getDocumentLineItems2,
    getDocumentPriority,
    getDocumentVersions,
    getEmailBodyWithLinks,
    getFieldType,
    getFieldValidationData,
    getFileImageURL,
    getFilePageCount,
    getFilterFields,
    getFilterFieldsForEditing,
    getSignatureDocumentHistory,
    getTextractDocumentImageURL,
    getTextStampAnnotationImage,
    getTextStampAnnotationList,
    getThumbnail,
    hasFilterFields,
    incrementDownloadCounter,
    insertReplacePages,
    legalHoldDocuments,
    linkDocuments,
    loadDocLinks,
    logSignatureAdded,
    mergeDocuments,
    mergeDocumentsFilter,
    mergeLinkedDocuments,
    moveDocuments,
    printDocumentAttributeForm,
    processSampleTextractFile,
    recallSignatureDocument,
    rejectDocument,
    rejectSignatureDocument,
    renameDisplayName,
    replaceDocumentTemplateFile,
    replaceSignatureDocumentTemplateFile,
    replaceSignatureDocumentFile,
    resendSignatureDocumentLink,
    rollbackVersion,
    rotateDocument,
    runEFormLookup,
    findLinkedDocuments,
    saveBulkProcessActionAIP,
    saveBulkProcessActionBarcode,
    saveBulkProcessActionOCR,
    saveDocumentAttributes,
    saveDocumentRedaction,
    saveDocumentLineItems2,
    saveFieldValidationData,
    saveFilterFields,
    saveNote,
    savePriority,
    saveThumbnailInfo,
    sendNewSignatureDocumentEmail,
    setAnnotationImage,
    splitDocument,
    submitPackage,
    takeOwnershipDocuments,
    terminateDocuments,
    tryCoCStreamPDF,
    tryStreamPDF,
    updateDocumentGUIDs,
    updateNote,
    useEFormDBLookupResult,
    uploadTempDocForMerge,
  } from '@/store/apiModules/document.api'

  import {
    buildHistoryTableData,
    buildNotesTableData,
    buildLinkedDocumentsTableData,
    buildVersionTableData,
  } from '@/store/helperModules/document.module'

  import {
    downloadCSV,
    downloadPDF,
    streamPDF,
  } from '@/store/helperModules/reporting.module'

  import { 
    formatDateTime 
  } from '@/store/helperModules/common.module'

  function initialState() {
    return {
      activeSignature: '',
      activeStamp: '',
      annotation: {
        type: '',
        color: '',
      },
      annotationImageList: [],
      backRoute: '',
      cardClicked: '',
      checkedDocuments: [],
      coCIncluded: false,
      deleteCurrentAnnotation: false,
      documentVersionData: [],
      filePageCount: '',
      filteredFieldOptions: [],
      history: {},
      lineItems: null,
      lineItemsFields: {},
      lineItemChanges: [],
      linkedDocuments: {},
      mergeAppendToFirstDocument: '',
      mergeDeleteOriginalFiles: '',
      mergeFileAs: '',
      mergeFileName: '',
      mergeFolderLocationId: '',
      mergeFolderLocationName: '',
      mergeKeyForMerge: 0,
      mergeMergeFileNotes: '',
      mergeUseFieldsFromFistDocument: '',
      notes: {},
      packagers: {},
      pendingSignatureAnnotationIDsForCurrentUser: [],
      pendingSignatureDocumentZones: [],
      priority: {
        priorityID: 1,
        priorityName: 'High',
      },
      rectangleForTextStamp: {},
      refreshKey: 1,
      refreshLineItemDataTrigger: 1,
      refreshThumbnailKey: 1,
      selectedLineItemField: {},
      tempCheckedDocuments: [],
      tempSortedRows: [],
      textStampAnnotationList: [],
      textStampToReplace: '',
      uploadedPagesPageNumber: '',
      uploadedPagesInsertOption: '',
      uploadedPagesToAdd: [],
      uploadNewPagesKey: 1,
      zoomValue: 1,
    }
  }

const document = {
  namespaced: true,
  state: initialState,
  mutations: {
    addCheckedDocument(state, value) { state.checkedDocuments.push(value) },
    addNewDocumentSlot(state) { state.uploadedPagesToAdd.push({ fileName: '' }) },
    clearUploadedPagesToAdd(state) { state.uploadedPagesToAdd = [] },
    moveMergeDocument(state, payload) {
        const fromIndex = payload.from
        const toIndex = payload.to
        const element = state.checkedDocuments[fromIndex];
        state.checkedDocuments.splice(fromIndex, 1);
        state.checkedDocuments.splice(toIndex, 0, element);
    },
    removePageFromList(state, index) { state.uploadedPagesToAdd.splice(index, 1) },
    resetStoreData(state) {
      const localState = initialState()
      Object.keys(state).forEach((prop) => {
        state[prop] = localState[prop]
      })
    },
    setActiveSignature(state, value) { state.activeSignature = value },
    setActiveStamp(state, value) { state.activeStamp = value },
    setAnnotationConfig(state, value) { state.annotation = value },
    setAnnotationImageList(state, value) { state.annotationImageList = value },
    setBackRoute(state, value) { state.backRoute = value },
    setCardClicked(state, value) { state.cardClicked = value },
    setCheckedDocuments(state, documentsArray) { state.checkedDocuments = documentsArray },
    setCoCIncluded(state, value) { state.coCIncluded = value },
    setDeleteCurrentAnnotation(state, value) { state.deleteCurrentAnnotation = value },
    setDocumentPriority(state, priority) { state.priority = priority },
    setDocumentVersionData(state, value) {
      if (value && value.Versions) {
        state.documentVersionData = buildVersionTableData(value.Versions.Values)
      } else {
        state.documentVersionData = []
      }
    },
    setFilePageCount(state, count) { state.filePageCount = count },
    setFilteredFieldOptions(state, value) { state.filteredFieldOptions = value },
    setHistory(state, historyData) { state.history = buildHistoryTableData(historyData) },
    setLineItems(state, lineItemData) { state.lineItems = lineItemData },
    setLineItemFields(state, {documentId, lineItemData}) { Vue.set(state.lineItemsFields, documentId, lineItemData) },
    setLineItemChanges(state, lineItemChanges) { state.lineItemChanges = lineItemChanges },
    
    setLinkedDocuments(state, linkedDocumentsData) { state.linkedDocuments = buildLinkedDocumentsTableData(linkedDocumentsData) },
    setMergeAppendToFirstDocument(state, value) { state.mergeAppendToFirstDocument = value },
    setMergeClearValues(state) {
      state.setMergeAppendToFirstDocument = ''
      state.mergeDeleteOriginalFiles = ''
      state.mergeFolderLocationId = ''
      state.mergeFileAs = ''
      state.mergeFileName = ''
      state.mergeFolderLocationName = ''
      state.mergeMergeFileNotes = ''
      state.mergeUseFieldsFromFistDocument = ''
    },
    setMergeDeleteOriginalFiles(state, value) { state.mergeDeleteOriginalFiles = value },
    setMergeFileAs(state, value) { state.mergeFileAs = value },
    setMergeFileName(state, value) { state.mergeFileName = value },
    setMergeFolderLocation(state, mergeFolderLocation) {
      state.mergeFolderLocationName = mergeFolderLocation.name
      state.mergeFolderLocationId = mergeFolderLocation.value
    },
    setMergeKeyToRunMerge(state) { state.mergeKeyForMerge += 1 },
    setMergeMergeFileNotes(state, value) { state.mergeMergeFileNotes = value },
    setmergeUseFieldsFromFistDocument(state, value) { state.mergeUseFieldsFromFistDocument = value },
    setNotes(state, noteData) { state.notes = buildNotesTableData(noteData) },
    setPackagers(state, packagers) {state.packagers = packagers},
    setPageToList(state, newPage) { state.uploadedPagesToAdd.splice(newPage.uploadIndex, 1, newPage) },
    setPendingSignatureAnnotationIDsForCurrentUser(state, value) { state.pendingSignatureAnnotationIDsForCurrentUser = value },
    setPendingSignatureDocumentZones(state, value) { state.pendingSignatureDocumentZones = value },
    setRectangleForTextStamp(state, value) { state.rectangleForTextStamp = value },
    setRefreshKey(state) { state.refreshKey += 1 },
    triggerLineItemDataRefresh(state) { state.refreshLineItemDataTrigger += 1 },
    setSelectedLineItemField(state, value) { state.selectedLineItemField = value },
    setTextStampAnnotationList(state, value) { state.textStampAnnotationList = value },
    setTextStampToReplace(state, value) { state.textStampToReplace = value },
    setZoomValue(state, value) {
      if (typeof(value) !== 'number') {
        if (value && state.zoomValue < 7) {
          state.zoomValue *= 1.25
        } else if (state.zoomValue >= 0.1) {
          state.zoomValue /= 1.25
        }
      } else {
        state.zoomValue = value
      }
    },
    refreshThumbnailKey(state) { state.refreshThumbnailKey += 1 },
    removeMergeDocument(state, index) { state.checkedDocuments.splice(index, 1) },
    removePendingSignatureAnnotationID(state, annotationID) {
      if (!state.pendingSignatureAnnotationIDsForCurrentUser || state.pendingSignatureAnnotationIDsForCurrentUser.length === 0) {
        return
      }

      state.pendingSignatureAnnotationIDsForCurrentUser = state.pendingSignatureAnnotationIDsForCurrentUser.filter(element => {
        return element !== annotationID
      })
    },
    addPendingSignatureAnnotationID(state, annotationID) {
      state.pendingSignatureAnnotationIDsForCurrentUser.push(annotationID)
    },
    setTempCheckedDocuments(state, value) { state.tempCheckedDocuments = value },
    resetZoomValue(state) { state.zoomValue = 1 },
    setTempSortedRows(state, value) { state.tempSortedRows = value },
    setUploadedPagesInsertOption(state, value) { state.uploadedPagesInsertOption = value },
    setUploadedPagesPageNumber(state, value) { state.uploadedPagesPageNumber = value },
    setUploadNewPages(state) { state.uploadNewPagesKey += 1 },
  },
  actions: {
    activeSignature({ commit }, value) { commit('setActiveSignature', value) },
    activeStamp({ commit }, value) { commit('setActiveStamp', value) },
    async addCheckedDocument({ commit }, value) {
        await commit('addCheckedDocument', value)
      return true
    },
    addNewDocumentSlot({ commit }) { commit('addNewDocumentSlot') },
    addPageToList({ commit }, value) { commit('setPageToList', value) },
    addPendingSignatureAnnotationID({ commit }, annotationID) { commit('addPendingSignatureAnnotationID', annotationID) },
    async addSignatureDocumentHistory({ rootState }, payload) { return addSignatureDocumentHistory(rootState.common.apiToken, payload) },
    async addTaskListsToDocument({ rootState }, payload) { return addTaskListsToDocument(rootState.common.apiToken, payload) },
    async addTaskToDoc({ rootState }, payload) { return addTaskToDoc(rootState.common.apiToken, payload) },
    async approveDocuments({ rootState }, payload) {
      const result = await approveDocuments(rootState.common.apiToken, payload)
      return result
    },
    async approveDocumentsWithSteps({ rootState }, payload) {
      const result = await approveDocumentsWithSteps(rootState.common.apiToken, payload)
      return result
    },
    async approveSignatureDocument({ rootState }, payload) {
      const result = await approveSignatureDocument(rootState.common.apiToken, payload)
      return result
    },
    async addOneToRefreshKey({ commit }) { commit('setRefreshKey') },
    async addOneToThumbnailRefreshKey({ commit }) { commit('refreshThumbnailKey') },
    async burnAnnotations({ rootState }, payload) {
      const result = await burnAnnotations(rootState.common.apiToken, payload)
      return result
    },
    async canRemoveAttributeField({ rootState }, payload) {
      const data = await canRemoveAttributeField(rootState.common.apiToken, payload)
      return data
    },
    async checkDocumentFlags({ rootState }, payload) { return checkDocumentFlags(rootState.common.apiToken, payload) },
    async checkFileExtension({ rootState }, payload) { return checkFileExtension(rootState.common.apiToken, payload) },
    clearUploadedPagesToAdd({ commit }) { commit('clearUploadedPagesToAdd') },
    async completeTask({ rootState }, payload) {
      const result = await completeTask(rootState.common.apiToken, payload)
      return result
    },
    async copyDocuments({ rootState }, payload) {
      const result = await copyDocuments(rootState.common.apiToken, payload)
      return result
    },
    async copyEFormPdf({ rootState }, payload) {
      const result = await copyEFormPdf(rootState.common.apiToken, payload)
      return result
    },
  
    async createVersion({ commit, rootState }, payload) {
      const result = await createVersion(rootState.common.apiToken, payload)
      commit('setDocumentVersionData', result)
      return result
    },
    async createVersionAttributeFormPDF({ rootState, dispatch }, payload) {
      await createVersionAttributeFormPDF(rootState.common.apiToken, payload)
      dispatch('getDocumentDetails', payload)
    },
    async deleteAnnotationImage({ rootState }, annotationImageId) {
      const result = await deleteAnnotationImage(rootState.common.apiToken, annotationImageId)
      return result
    },
    async deleteDocCacheFiles({ rootState }, payload) {
      const result = await deleteDocCacheFiles(rootState.common.apiToken, payload)
      return result
    },
    async deleteDocumentLink({ commit, rootState }, payload) {
      const result = await deleteDocumentLink(rootState.common.apiToken, payload)
      if (result.Links) {
        commit('setLinkedDocuments', result.Links.Values)
      } else {
        commit('setLinkedDocuments', null)
      }
    },
    async deleteDocumentNote({ commit, rootState }, noteId) {
      const result = await deleteDocumentNote(rootState.common.apiToken, noteId)
      if (result.data.Error) {
        return result.data.Error
      }
      commit('setNotes', result.data.Notes.Values)
      return ''
    },
    async deleteDocumentPages({ rootState }, payload) { return deleteDocumentPages(rootState.common.apiToken, payload) },
    async deleteMultipleDocuments({ rootState }, payload) {
      const result = await deleteMultipleDocuments(rootState.common.apiToken, payload)
      return result
    },
    async deleteSignatureDocumentTemplate({ rootState }, documentIDString) {
      const result = await deleteSignatureDocumentTemplate(rootState.common.apiToken, documentIDString)
      return result
    },
    async copySignatureDocumentTemplate({ rootState }, documentID) {
      const result = await copySignatureDocumentTemplate(rootState.common.apiToken, documentID)
      return result
    },
    async downloadAttributeFormDocument({ rootState }, documentID) {
      const data = await downloadAttributeFormDocument(rootState.common.apiToken, documentID)
      return data
    },
    async downloadVasionDocument({ rootState }, payload) {
      const data = await downloadVasionDocument(rootState.common.apiToken, payload)
      return data
    },
    async emailAttributeForm({ rootState }, payload) {
      const result = await emailAttributeForm(rootState.common.apiToken, payload)
      return result
    },
    async emailMultiDocument({ rootState }, payload) {
      const result = await emailMultiDocument(rootState.common.apiToken, payload)
      return result
    },
    async emailSingleDocument({ rootState }, payload) {
      const result = await emailSingleDocument(rootState.common.apiToken, payload)
      return result
    },
    async exportDocumentHistoryCSV({ rootState }, documentID) {
      const result = await exportDocumentHistoryCSV(rootState.common.apiToken, documentID)
      downloadCSV(result, 'DocumentHistoryExport')
    },
    async exportDocumentHistoryPDF({ rootState }, documentID) {
      const result = await exportDocumentHistoryPDF(rootState.common.apiToken, documentID)
      downloadPDF(result, 'DocumentHistoryExport')
    },
    async exportSignatureDocumentHistory({ rootState }, documentID) {
      const exportPDF = await exportSignatureDocumentHistory(rootState.common.apiToken, documentID)
      streamPDF(exportPDF)
    },
    async exportWorkflowHistoryPDF({ rootState }, documentID) {
      const payload = {
        documentID: documentID,
        userID: rootState.common.userID,
      }

      const result = await exportWorkflowHistoryPDF(rootState.common.apiToken, payload)
      downloadPDF(result, 'WorkflowHistoryExport')
    },
    filteredFieldOptions({ commit }, value) { commit('setFilteredFieldOptions', value) },
    async getAnnotationImageList({ commit, rootState }) {
      const imageList = await getAnnotationImageList(rootState.common.apiToken)
      commit('setAnnotationImageList', imageList)
    },
    async getDefaultLookupId({ rootState }, attributeFormId) {
      const lookupId = await getDefaultLookupId(rootState.common.apiToken, attributeFormId)
      return lookupId
    },
    async getDocumentDetails({ rootState, dispatch }, payload) {
      let data
      if (payload.documentID) {
        data = await getDocumentDetails(rootState.common.apiToken, payload.documentID, payload.workflowStepID, payload.password, payload.flags)
        if (data) {
          dispatch('setLinksNotesAndPackagers', data)
        }
        return data
      }
    },
    async getDocumentDetailsByGuid({ rootState, dispatch }, documentID) {
      const data = await getDocumentDetailsByGuid(rootState.common.apiToken, documentID)
      if (data) {
        dispatch('setLinksNotesAndPackagers', data)
      }
      return data
    },
    async getDocumentDetailsWithPayload({ rootState }, payload) {
      const data = await getDocumentDetailsWithPayload(rootState.common.apiToken, payload)
      return data
    },
    async getDocumentDetailsWithVersion({ dispatch, rootState }, payload) {
      const data = await getDocumentDetailsWithVersion(rootState.common.apiToken, payload.documentID, payload.version)
      if (data) {
        dispatch('setLinksNotesAndPackagers', data)
      }
      return data
    },
    async getDocumentDownloadCount({ rootState }, documentGuid) {
      const result = await getDocumentDownloadCount(rootState.common.apiToken, documentGuid)
      return result
    },
    async getDocumentDueDate({ rootState }, payload) {
      const result = await getDocumentDueDate(rootState.common.apiToken, payload)
      return result ? formatDateTime(result, 'date') : null
    },
    async getDocumentHistory({ commit, rootState }, documentID) {
      const response = await getDocumentHistory(rootState.common.apiToken, documentID)
      if (response) {
        commit('setHistory', response.History.Values)
      }
    },
    async getDocumentImageURL({ rootState }, payload) {
      const results = await getDocumentImageURL(rootState.common.apiToken, payload)
      return results
    },
    async getDocumentLineItems({ commit, rootState }, payload) {
      const response = await getDocumentLineItems(rootState.common.apiToken, payload)
      if (response) {
        commit('setLineItems', response.lineItemTables)
      }
    },
    async getDocumentLineItems2({ rootState }, payload) {
      const results = await getDocumentLineItems2(rootState.common.apiToken, payload)
      return results
    },
    async getDocumentPriority({ commit, rootState }, payload) {
      const results = await getDocumentPriority(rootState.common.apiToken, payload)
      commit('setDocumentPriority', results)
    },
    async getDocumentVersions({ commit, rootState }, payload) {
      const results = await getDocumentVersions(rootState.common.apiToken, payload)
      commit('setDocumentVersionData', results)
    },
    async getEmailBodyWithLinks({ rootState }, payload) {
      const results = await getEmailBodyWithLinks(rootState.common.apiToken, payload)
      return results
    },
    async getFieldType({ rootState }, fieldName) {
      const fieldType = await getFieldType(rootState.common.apiToken, fieldName)
      return fieldType
    },
    async getFieldValidationData({rootState}, payload){
      const data = await getFieldValidationData(rootState.common.apiToken, payload)
      return data
    },
    async getFileImageURL({ rootState }, payload) {
      const results = await getFileImageURL(rootState.common.apiToken, payload)
      return results
    },
    async getFilePageCount({ commit, rootState }, payload) {
      const count = await getFilePageCount(rootState.common.apiToken, payload)
      commit('setFilePageCount', count)
      return count
    },
    async getFilterFields({ rootState }, payload) {
      const result = await getFilterFields(rootState.common.apiToken, payload)
      return result
    },
    async getFilterFieldsForEditing({ rootState }, payload) {
      const result = await getFilterFieldsForEditing(rootState.common.apiToken, payload)
      return result
    },
    async getSignatureDocumentHistory({ rootState }, documentID) {
      return getSignatureDocumentHistory(rootState.common.apiToken, documentID)
    },
    async getTextractDocumentImageURL({ rootState }, payload) {
      const results = await getTextractDocumentImageURL(rootState.common.apiToken, payload)
      return results
    },
    async getTextStampAnnotationImage({ rootState }, payload) {
      const result = await getTextStampAnnotationImage(rootState.common.apiToken, payload)
      return result
    },
    async getTextStampAnnotationList({ commit, rootState }, payload) {
      const imageList = await getTextStampAnnotationList(rootState.common.apiToken, payload)
      if (imageList?.ResultStatus === 1 && imageList?.Values) commit('setTextStampAnnotationList', imageList.Values)
    },
    async setTextStampAnnotationList({ commit }, newValue) {
      commit('setTextStampAnnotationList', newValue)
    },
    async getThumbnail({ rootState }, payload) {
      const thumbNail = await getThumbnail(rootState.common.apiToken, payload)
      return thumbNail
    },
    async hasFilterFields({ rootState }, formID) {
      const result = await hasFilterFields(rootState.common.apiToken, formID)
      return result
    },
    async incrementDownloadCounter({ rootState }, payload) {
      const result = await incrementDownloadCounter(rootState.common.apiToken, payload)
      return result
    },
    async insertReplacePages({ rootState }, payload) {
      const result = await insertReplacePages(rootState.common.apiToken, payload)
      return result
    },
    async legalHoldDocuments({ rootState }, documentIDString) {
      const result = await legalHoldDocuments(rootState.common.apiToken, documentIDString)
      return result
    },
    async linkDocuments({ rootState }, payload) { await linkDocuments(rootState.common.apiToken, payload) },
    async loadAndSetDocLinks({ rootState, commit }, documentID) {
      const payload = {
        ID: documentID,
      }
      const documentLinkResponse = await loadDocLinks(rootState.common.apiToken, payload)
      if (documentLinkResponse && documentLinkResponse.Links) {
        commit('setLinkedDocuments', documentLinkResponse.Links.Values)
      }
    },
    async logSignatureAdded({ rootState }, documentID) { logSignatureAdded(rootState.common.apiToken, documentID) },
    async mergeDocuments({ rootState }, payload) {
      const result = await mergeDocuments(rootState.common.apiToken, payload)
      return result
    },
    async mergeDocumentsFilter({ rootState }, payload) {
      const result = await mergeDocumentsFilter(rootState.common.apiToken, payload)
      return result
    },
    async mergeLinkedDocuments({ rootState }, payload) {
      const result = await mergeLinkedDocuments(rootState.common.apiToken, payload)
      return result
    },
    async moveDocuments({ rootState }, payload) {
      const result = await moveDocuments(rootState.common.apiToken, payload)
      return result
    },
    async moveMergeDocuments({ commit }, payload) { commit('moveMergeDocument', payload) },
    async printDocumentAttributeForm({ rootState }, documentID) {
      const response = await printDocumentAttributeForm(rootState.common.apiToken, documentID)
      return response
    },
    async processSampleTextractFile({ rootState }, payload) {
      const result = await processSampleTextractFile(rootState.common.apiToken, payload)
      return result
    },
    recallSignatureDocument({ rootState }, payload) { return recallSignatureDocument(rootState.common.apiToken, payload) },
    rejectDocument({ rootState }, payload) { return rejectDocument(rootState.common.apiToken, payload) },
    rejectSignatureDocument({ rootState }, payload) { return rejectSignatureDocument(rootState.common.apiToken, payload) },
    renameDisplayName({ rootState }, payload) { return renameDisplayName(rootState.common.apiToken, payload) },
    removeMergeDocument({ commit }, index) { commit('removeMergeDocument', index) },
    removePageFromList({ commit }, pageIndex) { commit('removePageFromList', pageIndex) },
    removePendingSignatureAnnotationID({ commit }, annotationID) { commit('removePendingSignatureAnnotationID', annotationID) },
    async replaceDocumentTemplateFile({ rootState }, payload) {
      const response = await replaceDocumentTemplateFile(rootState.common.apiToken, payload)
      return response
    },
    async replaceSignatureDocumentTemplateFile({ rootState }, payload) {
      const response = await replaceSignatureDocumentTemplateFile(rootState.common.apiToken, payload)
      return response
    },
    async replaceSignatureDocumentFile({ rootState }, payload) {
      const response = await replaceSignatureDocumentFile(rootState.common.apiToken, payload)
      return response
    },
    resendSignatureDocumentLink({ rootState }, payload) {
      return resendSignatureDocumentLink(rootState.common.apiToken, payload)
    },
    resetStoreData({ commit }) { commit('resetStoreData') },
    resetZoomValue({ commit }) { commit('resetZoomValue') },
    async rollbackVersion({ rootState }, payload) { return rollbackVersion(rootState.common.apiToken, payload) },
    rotateDocument({ rootState }, payload) { return rotateDocument(rootState.common.apiToken, payload) },
    async runEFormLookup({ rootState }, payload) { return runEFormLookup(rootState.common.apiToken, payload) },
    async findLinkedDocuments({ rootState }, documentID) {
      const results = await findLinkedDocuments(rootState.common.apiToken, documentID)
      return results
    },
    async saveBulkProcessActionAIP({ rootState }, payload) { return saveBulkProcessActionAIP(rootState.common.apiToken, payload) },
    async saveDocumentsForBarcodeRecognition({ rootState }, payload) {
      return saveBulkProcessActionBarcode(rootState.common.apiToken, payload)
    },
    async saveDocumentsForOCR({ rootState }, payload) {
      return saveBulkProcessActionOCR(rootState.common.apiToken, payload)
    },
    async saveDocumentAttributes({ rootState }, payload) {
      const result = await saveDocumentAttributes(rootState.common.apiToken, payload)
      return result
    },
    async saveDocumentLineItems2({rootState}, payload){
      const result = await saveDocumentLineItems2(rootState.common.apiToken, payload)
      return result
    },
    async saveFieldValidationData({rootState}, payload){
      const result = await saveFieldValidationData(rootState.common.apiToken, payload)
      return result
    },
    async saveFilterFields({rootState}, payload){
      const result = await saveFilterFields(rootState.common.apiToken, payload)
      return result
    },
    async saveNote({ commit, rootState }, payload) {
      const result = await saveNote(rootState.common.apiToken, payload)
      if (result) {
        commit('setNotes', result.Notes.Values)
      }
    },
    async savePriority({ rootState }, payload) {
      const result = await savePriority(rootState.common.apiToken, payload)
      return result
    },
    async saveRedaction({ rootState }, payload) {
      const result = await saveDocumentRedaction(rootState.common.apiToken, payload)
      return result
    },
    async saveThumbnailInfo({ rootState }, payload) {
      const result = await saveThumbnailInfo(rootState.common.apiToken, payload)
      return result
    },
    sendNewSignatureDocumentEmail({ rootState }, documentID) {
      const payload = {
        Value: documentID,
      }
      sendNewSignatureDocumentEmail(rootState.common.apiToken, payload)
    },
    async setAnnotationImage({ rootState }, payload) {
      const result = await setAnnotationImage(rootState.common.apiToken, payload)
      return result
    },
    setAnnotationConfig({ commit }, value) { commit('setAnnotationConfig', value) },
    setBackRoute({ commit }, value) { commit('setBackRoute', value) },
    setCardClicked({ commit }, value ) { commit('setCardClicked', value) },
    setCheckedDocuments({ commit }, value) { commit('setCheckedDocuments', value) },
    setCoCIncluded({ commit }, value) { commit('setCoCIncluded', value) },
    setDeleteCurrentAnnotation({ commit }, value) { commit('setDeleteCurrentAnnotation', value) },
    setDocumentPriority({ commit }, value) { commit('setDocumentPriority', value) },
    setLineItemChanges({ commit }, value) { commit('setLineItemChanges', value) },
    setLineItemFields({ commit }, {documentId, lineItemData}) { commit('setLineItemFields', {documentId, lineItemData}) },
    setLinksNotesAndPackagers({ commit }, data) {
      if (data.Links) {
        commit('setLinkedDocuments', data.Links.Values)
      } else {
        commit('setLinkedDocuments', null)
      }
      if (data.Notes) {
        commit('setNotes', data.Notes.Values)
      } else {
        commit('setNotes', null)
      }
      if (data.DocPackageViewer) {
        commit('setPackagers', data.DocPackageViewer)
      } else {
        commit('setPackagers', {})
      }
    },
    setMergeAppendToFirstDocument({ commit }, value) { commit('setMergeAppendToFirstDocument', value) },
    setMergeClearValues({ commit }) { commit('setMergeClearValues') },
    setMergeDeleteOriginalFiles({ commit }, value) { commit('setMergeDeleteOriginalFiles', value) },
    setMergeFileAs({ commit }, value) { commit('setMergeFileAs', value) },
    setMergeFileName({ commit }, value) { commit('setMergeFileName', value) },
    setMergeFolderLocation({ commit }, value) { commit('setMergeFolderLocation', value) },
    setMergeKeyToRunMerge({ commit }) { commit('setMergeKeyToRunMerge') },
    setMergeMergeFileNotes({ commit }, value) { commit('setMergeMergeFileNotes', value) },
    setmergeUseFieldsFromFistDocument({ commit }, value) { commit('setmergeUseFieldsFromFistDocument', value) },
    setPendingSignatureAnnotationIDsForCurrentUser({ commit }, value) { commit('setPendingSignatureAnnotationIDsForCurrentUser', value) },
    setPendingSignatureDocumentZones({ commit }, value) { commit('setPendingSignatureDocumentZones', value) },
    setRectangleForTextStamp({ commit }, value) { commit('setRectangleForTextStamp', value) },
    setSelectedLineItemField({ commit }, value) { commit('setSelectedLineItemField', value) },
    setTempCheckedDocuments({ commit }, value) { commit('setTempCheckedDocuments', value) },
    setTempSortedRows({ commit }, value) { commit('setTempSortedRows', value) },
    setTextStampToReplace({ commit }, value) { commit('setTextStampToReplace', value) },
    setZoomValue({ commit }, value) { commit('setZoomValue', value) },
    async splitDocument({ rootState }, payload) {
      const result = await splitDocument(rootState.common.apiToken, payload)
      return result
    },
    submitPackage({ rootState }, payload) { return submitPackage(rootState.common.apiToken, payload) },
    takeOwnershipDocuments({ rootState }, payload) { return takeOwnershipDocuments(rootState.common.apiToken, payload) },
    terminateDocuments({ rootState }, payload) { return terminateDocuments(rootState.common.apiToken, payload) },
    triggerLineItemDataRefresh( { commit } ) { commit('triggerLineItemDataRefresh') },
    async tryCoCStreamPDF({ rootState }, payload) {
      const result = await tryCoCStreamPDF(rootState.common.apiToken, payload)
      return result
    },
    async tryStreamPDF({ rootState }, payload) {
      const result = await tryStreamPDF(rootState.common.apiToken, payload)
      return result
    },
    async updateDocumentGUIDs({ rootState }, payload) {
      const result = await updateDocumentGUIDs(rootState.common.apiToken, payload)
      return result
    },
    uploadNewPages({ commit }) { commit('setUploadNewPages') },
    async updateNote({ commit, rootState }, payload) {
      const result = await updateNote(rootState.common.apiToken, payload)
      if (result) {
        commit('setNotes', result.Notes.Values)
      }
    },
    uploadedPagesInsertOption({ commit }, value) { commit('setUploadedPagesInsertOption', value) },
    uploadedPagesPageNumber({ commit }, value) { commit('setUploadedPagesPageNumber', value) },
    async uploadTempDocForMerge({ rootState }, value) {
      const payload = {
        name: value.name,
        fileData: value.fileData,
      }
      const tempDoc = await uploadTempDocForMerge(rootState.common.apiToken, payload)
      return tempDoc
    },
    async useEFormDBLookupResult({ rootState }, payload) { return useEFormDBLookupResult(rootState.common.apiToken, payload) },
  },
}

export default document
