import {
  getAllMaxxForms,
  getEncryptedUsername,
  getMaxxFormDetails,
  loadAllFormsForUser,
  submitMaxxForm,
 } from '@/store/apiModules/maxxForms.api'

 function initialState() {
  return {
    allMaxxForms: [],
    currentlyLoggedInUser: '',
    selectedForm: {
      id: 0,
      name: '',
    },
    userForms: [],
  }
}

const maxxForms = {
  namespaced: true,
  state: initialState,
  mutations: {
    resetStoreData(state) {
      const localState = initialState()
      Object.keys(state).forEach((prop) => {
        state[prop] = localState[prop]
      })
    },
    setAllMaxxForms(state, value) { state.allMaxxForms = value },
    setCurrentlyLoggedInUser(state, value) { state.currentlyLoggedInUser = value.Value },
    setSelectedForm(state, value) { state.selectedForm = value },
    setUserForms(state, value) { state.userForms = value },
  },
  actions: {
    async getMaxxFormDetails({ rootState }, maxxFormID) {
      const data = await getMaxxFormDetails(rootState.common.apiToken, maxxFormID)
      return data
    },
    async loadAllFormsForUser({ rootState, commit }, payload) {
      const forms = await loadAllFormsForUser(rootState.common.apiToken, payload)
      commit('setUserForms', forms)
    },
    async loadAllMaxxForms({ rootState, commit }) {
      const payload = {
        IncludeUserSecurity: false,
        StartNumber: 0,
        MaxReturnCount: 1000,
        IncludeFields: false,
      }

      const data = await getAllMaxxForms(rootState.common.apiToken, payload)
      if (data && data.EFormCollection && data.EFormCollection.Values) {
        commit('setAllMaxxForms', data.EFormCollection.Values)
        return data.EFormCollection.Values
      } else {
        return undefined
      }
    },
    async selectForm({ commit }, formId) {
      if (formId !== 0) {
          commit('setSelectedForm', { id: formId, name: `Untitled ${$formsLabel}` })
      } else {
        commit('setSelectedForm', { id: 0, name: `Untitled ${$formsLabel}` })
      }
    },
    async setCurrentlyLoggedInUser({ rootState, commit }) {
      const user = await getEncryptedUsername(rootState.common.apiToken)
      if (user != null) {
        commit('setCurrentlyLoggedInUser', user)
      }
    },
    async submitMaxxForm({ rootState }, payload) {
      const data = await submitMaxxForm(rootState.common.apiToken, payload)
      if (!data || data.Value === undefined || data.Value === null || data.Value === '') {
        return "Unknown error"
      }
      else if (isNaN(data.Value)) {
        return data.Value
      }
      return "";
    },
  },
}

export default maxxForms;
