<template>
  <VasionGeneralModal
    id="vasionFileUploadDialog"
    :modalType="'slot'"
    :rejectButtonText="'CLOSE'"
    :showConfirmButton="false"
    :sync="show"
    @noButtonClick="$emit('update:show', false)"
  >
    <div class="upload-container">
      <file-pond
        :acceptedFileTypes="acceptedFileTypes"
        :allowFileTypeValidation="delegateValidationToFilepond"
        :allowMultiple="allowMultiple"
        :label-idle="filePondLabel"
        :server="serverOptions"
        class="new-document"
        @addfilestart="onAddFileStart"
        @processfile="onProcessFile"
        @input="showFileUploading(true)"
      />
    </div>
    <p v-if="showNote" class="note">
      {{ note }}
    </p>
  </VasionGeneralModal>
</template>

<script>
import vueFilePond from 'vue-filepond';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

const FilePond = vueFilePond(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

/**
 * A dialog modal that lets you upload one or more files
 * @group Shared
 */
export default {
  name: 'VasionFileUploadDialog',
  components: {
    FilePond,
  },
  props: {
    // An array of accepted MIME types
    acceptedFileTypes: {
      required: false,
      type: Array,
      default: null,
    },
    // Whether to allow multiple uploads
    allowMultiple: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * Whether to reject invalid filetypes from filepond's side versus
     * manually implementing validation. Relies on acceptedFileTypes
     */
    delegateValidationToFilepond: {
      required: false,
      type: Boolean,
      default: false,
    },
    filePondLabel: {
      required: false,
      type: String,
      default: `<span class="filepond-drag">Drag your file(s) here</span>
              <br/><br/>
              <span class="filepond-label-action">or click to upload your file(s)</span>`,
    },
    // Filepond server options
    serverOptions: {
      required: true,
      type: Object,
    },
    // Whether to show the modal
    show: {
      required: true,
      type: Boolean,
    },
    note: {
      required: false,
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      numFilesToUpload: 0,
      pondInputCount: 0,
    }
  },
  computed: {
    showNote() {
      return this.note !== null || this.note.match(/^ *$/) === null
    },
  },
  watch: {
    show: function() {
      this.showFileUploading(false)
    }
  },
  methods: {
    onAddFileStart(file) {
      this.numFilesToUpload += 1

      /**
       * Fires when starting file load
       * @arg File
       */
      this.$emit('addFileStart', file)
    },
    onProcessFile(error, file) {
      this.numFilesToUpload -= 1

      /**
       * Fires when processing of a file has been completed
       * @arg Error
       * @arg File
       */
      this.$emit('processFile', error, file)

      if (this.numFilesToUpload <= 0) {
        /**
         * Fires when all files have completed uploading
         */
        this.$emit('allFilesUploaded')
      }
    },
    showFileUploading(show) {
      const fileUploads = document.querySelector('.filepond--list-scroller')
      let height = '0'

      if (show) {
        if (this.pondInputCount >= 1) {
          height = '100%'
        }
        this.pondInputCount += 1
      } else {
        height = '0'
        this.pondInputCount = 0
      }

      if (fileUploads) {
        fileUploads.style.height = height
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  #vasionFileUploadDialog::v-deep {
    .upload-container {
      width: 450px;
      height: 320px;
    }

    .filepond--list-scroller {
      transform: translate3d(0px, 0px, 0px) !important;
      max-height: 95%;
    }
    
    .note {
      max-width: 450px;
    }
  }
</style>
