/* eslint-disable no-multi-spaces */
import { formatDateTime } from '@/store/helperModules/common.module'

const buildWorkspaceTableData = function buildHistoryTableData(response, editableColumns) {
  let headers = []
  let customFolderIDInt = typeof response.Rows.customFolderID === "string" ? Number.parseInt(response.Rows.customFolderID) : response.Rows.customFolderID
  if (response.Rows.customFolderID && customFolderIDInt !== 0) {
    headers = response.Columns.Values.filter((item) => {
      if (item.startsWith('CanMass')) {
        return false;
      } else {
        return true;
      }
    }).map((item, index) => {
      if (index < 3) {
        return `_${item[0].toLowerCase() + item.slice(1)}_`
      } else {
        return item
      }
    })
    headers.unshift('_VasionCheckBox_')
  } else {
    headers = editableColumns ? [
      '_VasionCheckBox_',
      '_documentID_',
      '_wFID_',
      '_stepID_',
      '_Priority_',
      'Workflow',
      'Type',
      'Primary ID',
      'Assigned To',
      'Status',
      'Step Name',
      'Due Date',
    ] : [
      '_VasionCheckBox_',
      '_documentID_',
      '_wFID_',
      '_stepID_',
      'Priority',
      'Workflow',
      'Type',
      'Primary ID',
      'Assigned To',
      'Status',
      'Step Name',
      'Due Date',
    ]
  }

  if (response === null || response.length === 0) {
    return { tableHeaders: headers, tableRows: [] }
  }

  const docIx = response.Columns.Values.indexOf('DocumentID')
  const wfIdIx = response.Columns.Values.indexOf('WFID')
  const dueDateIx = response.Columns.Values.indexOf('Due Date')
  const priorityIdIx = response.Columns.Values.indexOf('PriorityID')
  const priorityIx = response.Columns.Values.indexOf('Priority')
  const wfIx = response.Columns.Values.indexOf('Workflow')
  const typeIx = response.Columns.Values.indexOf('Type')
  const primIdIx = response.Columns.Values.indexOf('Primary ID')
  const asgnIx = response.Columns.Values.indexOf('Assigned To')
  const statusIx = response.Columns.Values.indexOf('Status')
  const stepIx = response.Columns.Values.indexOf('Step Name')
  const stepIdIx = response.Columns.Values.indexOf('StepID')
  /*
    0: "DocumentID"
    1: "WFID"
    2: "CanMassApprove"
    3: "CanMassReassign"
    4: "CanMassReject"
    5: "PriorityID"
    6: "PriorityColor"
    7: "Priority"
    8: "Workflow"
    9: "Type"
    10: "Primary ID"
    11: "Assigned To"
    12: "Status"
    13: "Due Date"
    14: "Step Name"
    15: "StepID"*/

  const returnArr = response.Rows.Values.map((item) => {
      let dueDateString = item.Values[dueDateIx]
      if (dueDateString && dueDateString !== '') {
        try {
          dueDateString = formatDateTime(dueDateString, 'date')
        } catch (error) {
          dueDateString = item.dueDate
        }
      }
      const customArrayCopy = [...item.Values]
      if (response.Rows.customFolderID !== 0) {
        customArrayCopy.splice(2, 3)
        customArrayCopy.unshift({
          show: true,
          value: false,
        })
      }
      
      return {
        Values: customFolderIDInt === 0 ? [
          {                        // _VasionCheckBox_
            show: true,
            value: false,
          },                       // _VasionCheckBox_
          item.Values[docIx],          // DocumentID
          item.Values[wfIdIx],          // Workflow ID
          item.Values[stepIdIx],         // Step ID
          item.Values[priorityIx],          // Priority
          item.Values[wfIx],          // Workflow
          item.Values[typeIx],          // Type
          item.Values[primIdIx],          // Primary ID
          item.Values[asgnIx],         // Assigned To
          item.Values[statusIx],         // Status
          item.Values[stepIx],         // Step Name
          dueDateString,           // Due Date
        ] : customArrayCopy,
          ValueType: 'String',
          priority: {
            value: item.Values[priorityIdIx] !== '' ? Number(item.Values[priorityIdIx]) : 2,
            name: item.Values[priorityIx] !== '' ? item.Values[priorityIx] : 'Normal',
          },
      }
  })
  return { tableHeaders: headers, tableRows: returnArr }
}

const buildDraftsTableData = (response) => {
  let headers = ['_ID_','_EFormID_','_WFID_','Draft Name','Workflow Name','Form Name','Created Date','Modified Date','_VasionEllipsisButton_']

  if (response === null || response.length === 0) {
    return { tableHeaders: headers, tableRows: [] }
  }

  const returnArr = response.map((item) => {
      return {
        Values: [
          item.ID,
          item.EFormID,
          item.WFID,
          item.Name,
          item.WorkflowName,
          item.EFormName, 
          formatDateTime(item.CreatedDate, 'datetime'),
          formatDateTime(item.ModifiedDate, 'datetime'),
          'VasionEllipsisIcon'
        ],
          ValueType: 'String',
      }
  })
  return { tableHeaders: headers, tableRows: returnArr }
}

const buildRecentDocumentsTableData = (response) => {
  let headers = []
  headers = response.Columns.Values.map((item) => {
    if (item === 'DocumentID') {
      return `_${item[0].toLowerCase() + item.slice(1)}_`
    } else {
      return item
    }
  })
  headers.unshift('_VasionCheckBox_')

  if (response === null || response.length === 0) {
    return { tableHeaders: headers, tableRows: [] }
  }

  const returnArr = response.Rows.Values.map((item) => {
      return {
        Values: [
          {                        // _VasionCheckBox_
            show: true,
            value: false,
          },                       // _VasionCheckBox_
          item.Values[0],          // DocumentID
          item.Values[1],          // Document Name
          formatDateTime(item.Values[2], 'datetime'),          // Created Date
          formatDateTime(item.Values[3], 'datetime'),          // Modified Date
          formatDateTime(item.Values[4], 'datetime'),          // Date Accessed
        ],
          ValueType: 'String',
      }
  })
  return { tableHeaders: headers, tableRows: returnArr }
}

export {
  buildWorkspaceTableData,
  buildDraftsTableData,
  buildRecentDocumentsTableData,
}
