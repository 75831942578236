<template>
  <div>
    <FieldsTable
      v-for="(table, index) in lineItemTables"
      :key="`D-${documentId}-T${index}`"
      :documentId="documentId"
      :dataTable="table"
      :tableIndex="index"
      @updateField="updateField"
      @markDirty="markDirty"
    />
  </div>
</template>

<script>
import FieldsTable from '@/components/general/FieldsTable.vue';

export default {
  name: 'FieldTablesContainer',
  components: {
    FieldsTable,
  },
  props: {
    documentId: {
      type: Number,
      required: true
    },
    lineItemTables: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localLineItemTables: [...this.lineItemTables],
    };
  },
  computed: {
    lineItemChanges() { return this.$store.state.document.lineItemChanges },
  },
  methods: {
    markDirty() {
      this.$emit('markDirty')
    },
    async updateField(documentId, tableIndex, rowIndex, columnIndex, cellId, value) {
      let buffer = [...this.lineItemChanges]
      
      const bufferIndex = buffer.findIndex(item => item.DocumentId === documentId)
      if (bufferIndex !== -1) {
        buffer[bufferIndex].lineItems[cellId] = value
      } else {
        const newLineItemChanges = {
          DocumentId: documentId,
          lineItems: { [cellId]: value },
        };
        buffer.push(newLineItemChanges)
      }
      
      await this.$store.dispatch('document/setLineItemChanges', buffer)
      this.localLineItemTables[tableIndex].Rows[rowIndex].Columns[columnIndex].Value = value
    },
  }
};
</script>

<style lang="scss" scoped>
.text-01 {
  color: #000000;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
</style>