/* eslint-disable linebreak-style */
/* eslint-disable default-case */
/* eslint-disable consistent-return */

import { createAxiosInstance } from '@/services/axiosService'

const canAccessFolder = async (apiToken, folderId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Folder/CanAccessFolder', folderId)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const decryptVault = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Folder/DecryptVault', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const deleteSubFolder = async (apiToken, vaultID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('v1/Folder/DeleteSubfolderGetAncestors', {
      data: {
        Value: vaultID.toString(),
      },
    })

    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const deleteVault = async (apiToken, vaultID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('v1/Folder/DeleteVault', {
      data: {
        Value: vaultID.toString(),
      },
    })

    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getFolderContents = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Folder/GetFolder', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getFolderDefaultObject = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Folder/GetFolder', payload)
    if (response) {
      return response.data?.defaultObject
    }
  } catch (error) {
    console.warn(error)
  }
}

const getFolderName = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Folder/GetFolderName', payload)
    if (response) {
      return response.data?.sFolderName
    }
  } catch (error) {
    console.warn(error)
  }
}


const getParentFolderID = async (apiToken, folderID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Folder/GetParentFolderID?Value=${folderID}`)
    if (response?.data && response.data.Value) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const getReadOnlyFolderEntities = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Folder/GetReadOnlyFolderEntities?Value=0', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getReadOnlyFolderInfo = async (apiToken, folderID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const payload = {
      FolderId: folderID,
    }

    const response = await axios.post('v1/Folder/GetReadOnlyFolder', payload)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getSingleVault = async (apiToken, folderID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get(`v1/Folder/GetVault?Value=${folderID}`)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getVaultList = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Folder/GetVaultList')
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveSubFolder = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Folder/CreateSubFolderGetAncestors', payload)
    if (response) {
      return response.data.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveVault = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Folder/SaveVault', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const setClientDefaultEncryptionEnabled = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Folder/SetClientDefaultEncryptionEnabled', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const vasionFolderSearch = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Folder/vasionFolderSearch', payload)
    if (response) {
      return response.data.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

export {
  canAccessFolder,
  decryptVault,
  deleteSubFolder,
  deleteVault,
  getFolderContents,
  getFolderDefaultObject,
  getFolderName,
  getParentFolderID,
  getReadOnlyFolderEntities,
  getReadOnlyFolderInfo,
  getSingleVault,
  getVaultList,
  saveSubFolder,
  saveVault,
  setClientDefaultEncryptionEnabled,
  vasionFolderSearch,
}
