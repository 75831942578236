import {
  deleteScheduler,
  getAddToWorkflowScheduler,
  getBarcodeRecognitionScheduler,
  getDataImportDetails,
  getDocumentNameIndexingList,
  getDocumentTemplateScheduler,
  getExportConfigList,
  getExportDetails,
  getFullTextUpdateScheduler,
  getImportDetails,
  getIsValidUncPath,
  getLookupDetails,
  getMoveFieldComparisons,
  getMoveScheduler,
  getOcrScheduler,
  getRetentionScheduler,
  getSchedulerFieldComparisonTypes,
  getSchedulerList,
  getSchedulerListForUser,
  getScheduledReportDetails,
  getXmlImportScheduler,
  runScheduler,
  saveAddToWorkflowScheduler,
  saveAIPScheduler,
  saveBarcodeRecognitionScheduler,
  saveDataImportScheduler,
  saveDocumentTemplateScheduler,
  saveTheExportScheduler,
  saveFullTextUpdateScheduler,
  saveImportScheduler,
  saveLookupScheduler,
  saveMoveScheduler,
  saveRetentionScheduler,
  saveScheduledReport,
  saveXMLImportScheduler,
  getAipScheduler,
 } from '@/store/apiModules/automationEngine.api'

 import {
  buildTableData,
 } from '@/store/helperModules/automationEngine.module';

function initialState() {
  return {
    activeScheduler: {},
    automationVisibility: {},
    comparisonForm: [],
    documentNameIndexingList: [],
    exportConfigList: [],
    schedulerList: [],
    schedulersData: {},
  }
}

const automationEngine = {
  namespaced: true,
  state: initialState,
  mutations: {
    clearActiveScheduler(state) { state.activeScheduler = {} },
    resetStoreData(state) {
      const localState = initialState()
      Object.keys(state).forEach((prop) => {
        state[prop] = localState[prop]
      })
    },
    setAutomationVisibility(state, value) { state.automationVisibility = value },
    setActiveScheduler(state, value) { state.activeScheduler = value },
    setComparisonForm(state, value) { state.comparisonForm = value },
    setDocumentNameIndexingList(state, value) { state.documentNameIndexingList = value },
    setExportConfigList(state, value) { state.exportConfigList = value },
    setSchedulerList(state, value) { state.schedulerList = value },
    setSchedulerProps(state, value) { state.schedulerProps = value },
    setTableData(state, value) { state.tableData = buildTableData(value) },
  },
  actions: {
    async clearActiveScheduler({ commit }) { commit('clearActiveScheduler', {}) },
    async deleteScheduler({ rootState }, schedulerId) {
      const result = await deleteScheduler(rootState.common.apiToken, schedulerId)
      return result
    },
    async getAddToWorkflowScheduler({ rootState, commit }, schedulerId) {
      const addToWorkflowDetails = await getAddToWorkflowScheduler(rootState.common.apiToken, schedulerId)
      commit('setActiveScheduler', addToWorkflowDetails)
    },
    async getAipScheduler({ rootState }, schedulerId) { return getAipScheduler(rootState.common.apiToken, schedulerId) },
    getBarcodeRecognitionScheduler({ rootState }, schedulerId) { return getBarcodeRecognitionScheduler(rootState.common.apiToken, schedulerId) },
    async getDataImportDetails({ rootState, commit }, schedulerId) {
      const dataImportDetails = await getDataImportDetails(rootState.common.apiToken, schedulerId)
      commit('setActiveScheduler', dataImportDetails)
    },
    async getDocumentNameIndexingList({ rootState, commit }) {
      const indexes = await getDocumentNameIndexingList(rootState.common.apiToken)
      commit('setDocumentNameIndexingList', indexes)
    },
    async getDocumentTemplateScheduler({ rootState, commit }, schedulerId) {
      const templateDetails = await getDocumentTemplateScheduler(rootState.common.apiToken, schedulerId)
      commit('setActiveScheduler', templateDetails)
    },
    async getExportConfigList({ rootState, commit }) {
      const configs = await getExportConfigList(rootState.common.apiToken)
      commit('setExportConfigList', configs)
    },
    async getExportDetails({ rootState, commit }, exportId) {
      const exportDetails = await getExportDetails(rootState.common.apiToken, { Value: exportId })
      commit('setActiveScheduler', exportDetails)
    },
    async getFullTextUpdateScheduler({ rootState, commit }, schedulerId) {
      const fullTextDetails = await getFullTextUpdateScheduler(rootState.common.apiToken, schedulerId)
      commit('setActiveScheduler', fullTextDetails)
    },
    async getImportDetails({ rootState, commit }, importId) {
      const importDetails = await getImportDetails(rootState.common.apiToken, importId)
      commit('setActiveScheduler', importDetails)
    },
    async getIsValidUncPath({ rootState }, path) {
      const value = await getIsValidUncPath(rootState.common.apiToken, path)
      return value && value === 'True'
    },
    async getLookupDetails({ rootState, commit }, lookupId) {
      const lookupDetails = await getLookupDetails(rootState.common.apiToken, lookupId)
      commit('setActiveScheduler', lookupDetails)
    },
    async getMoveFieldComparisons({ rootState, commit }) {
      const value = await getMoveFieldComparisons(rootState.common.apiToken)
      commit('setComparisonForm', value)
    },
    async getMoveScheduler({ rootState, commit }, schedulerId) {
      const contentMoveDetails = await getMoveScheduler(rootState.common.apiToken, schedulerId)
      commit('setActiveScheduler', contentMoveDetails)
    },
    async getOcrScheduler({ rootState, commit }, schedulerId) {
      const ocr = await getOcrScheduler(rootState.common.apiToken, schedulerId)
      commit('setActiveScheduler', ocr)
    },
    async getRetentionScheduler({ rootState }, schedulerId) {
      return getRetentionScheduler(rootState.common.apiToken, schedulerId)
    },
    async getSchedulerFieldComparisonTypes({ rootState, commit }) {
      const value = await getSchedulerFieldComparisonTypes(rootState.common.apiToken)
      commit('setComparisonForm', value)
    },
    async getSchedulerList({ rootState, commit }, payload) {
      const schedulerList = await getSchedulerList(rootState.common.apiToken, payload)
      commit('setSchedulerList', schedulerList)
    },
    async getSchedulerListForUser({ rootState, commit }, payload) {
      const schedulerList = await getSchedulerListForUser(rootState.common.apiToken, payload)
      commit('setSchedulerList', schedulerList)
    },
    async getScheduledReportDetails({ rootState, commit }, scheduledReportId) {
      const scheduledReport = await getScheduledReportDetails(rootState.common.apiToken, scheduledReportId)
      commit('setActiveScheduler', scheduledReport)
    },
    async getXmlImportScheduler({ rootState, commit }, scheduledReportId) {
      const scheduledReport = await getXmlImportScheduler(rootState.common.apiToken, scheduledReportId)
      commit('setActiveScheduler', scheduledReport)
    },
    async runScheduler({ rootState }, schedulerId) {
      const result = await runScheduler(rootState.common.apiToken, schedulerId)
      return result
    },
    async saveAddToWorkflowScheduler({ rootState }, payload) {
      const value = await saveAddToWorkflowScheduler(rootState.common.apiToken, payload)
      return value && value !== '' && parseInt(value, 10) > 0
    },
    async saveAIPScheduler({ rootState }, payload) {
      const value = await saveAIPScheduler(rootState.common.apiToken, payload)
      return value && value !== '' && parseInt(value, 10) > 0
    },
    async saveBarcodeRecognitionScheduler({ rootState }, payload) {
      const value = await saveBarcodeRecognitionScheduler(rootState.common.apiToken, payload)
      return value
    },
    async saveDataImportScheduler({ rootState }, payload) {
      return await saveDataImportScheduler(rootState.common.apiToken, payload)
    },
    async saveDocumentTemplateScheduler({ rootState }, payload) {
      const value = await saveDocumentTemplateScheduler(rootState.common.apiToken, payload)
      return value
    },
    async saveExportScheduler({ rootState }, payload) {
      const value = await saveTheExportScheduler(rootState.common.apiToken, payload)
      return value && value !== '' && parseInt(value, 10) > 0
    },
    async saveFullTextUpdateScheduler({ rootState }, payload) {
      const value = await saveFullTextUpdateScheduler(rootState.common.apiToken, payload)
      return value && value !== ''
    },
    async saveImportScheduler({ rootState }, payload) {
      const value = await saveImportScheduler(rootState.common.apiToken, payload)
      return value && value !== '' && parseInt(value, 10) > 0
    },
    async saveLookupScheduler({ rootState }, payload) {
      const value = await saveLookupScheduler(rootState.common.apiToken, payload)
      return value && value !== '' && parseInt(value, 10) > 0
    },
    async saveMoveScheduler({ rootState }, payload) {
      const value = await saveMoveScheduler(rootState.common.apiToken, payload)
      return value
    },
    async saveRetentionScheduler({ rootState }, payload) {
      return saveRetentionScheduler(rootState.common.apiToken, payload)
    },
    async saveScheduledReport({ rootState }, payload) {
      const value = await saveScheduledReport(rootState.common.apiToken, payload)
      return value && value !== '' && parseInt(value, 10) > 0
    },
    async saveXMLImportScheduler({ rootState }, payload) {
      const value = await saveXMLImportScheduler(rootState.common.apiToken, payload)
      return value && value !== '' && parseInt(value, 10) > 0
    },
    setAutomationVisibility({ commit }, data) { commit('setAutomationVisibility', data) },
    setSchedulerProps({ commit }, data) { commit('setSchedulerProps', data) },
    // eslint-disable-next-line no-empty-pattern
    async setTableData({ }, data) {
      const response = await buildTableData(data)
      return response
    },
  },
  getters: {
    getSchedulerDataByType: () => (type) => {
      let data = {}
      switch (type) {
        case 'AddtoWorkflow':
          data = {
            hiddenColumns: ['_WorkflowID_', 'Import / Export'],
            buttons: [{ name: 'Add to Workflow', destination: 'EditAddtoWorkflow' }],
            scheduledType: '1220',
          }
          break;
        case 'Aip':
          data = {
            hiddenColumns: ['_WorkflowID_', 'Import / Export'],
            buttons: [{ name: 'Schedule Aip', destination: 'EditScheduledAip' }],
            scheduledType: '1218',
          }
          break;
        case 'AutomationEngineDbLookups':
          data = {
            hiddenColumns: ['_WorkflowID_', 'Import / Export'],
            buttons: [{ name: 'Scheduled Lookup', destination: 'AutomationEngineEditDatabaseLookup' }],
            scheduledType: '125',
          }
          break;
        case 'BarcodeRecognition':
          data = {
            hiddenColumns: ['_WorkflowID_', 'Import / Export'],
            buttons: [{ name: 'Barcode Recognition', destination: 'EditBarcodeRecognition' }],
            scheduledType: '1210',
          }
          break;
        case 'ContentMove':
          data = {
            hiddenColumns: ['_WorkflowID_', 'Import / Export'],
            buttons: [{ name: 'Content Move', destination: 'EditContentMove' }],
            scheduledType: '128',
          }
          break;
        case 'DataImport':
          data = {
            hiddenColumns: ['_WorkflowID_', 'Import / Export'],
            buttons: [{ name: 'Data Import', destination: 'EditDataImport' }],
            scheduledType: '1224',
          }
          break;
        case 'ImageRecognition':
          data = {
            hiddenColumns: ['_WorkflowID_', 'Import / Export'],
            buttons: [{ name: 'Schedule OCR', destination: 'EditScheduledOcr' }],
            scheduledType: '1210',
          }
          break;
        case 'ImportExport':
          data = {
            hiddenColumns: ['_WorkflowID_'],
            buttons: [{ name: 'Import', destination: 'EditImport' }, { name: 'Export', destination: 'EditExport' }],
            scheduledType: '121,1217',
          }
          break;
        case 'DocumentTemplate':
          data = {
            hiddenColumns: ['_WorkflowID_', 'Import / Export'],
            buttons: [{ name: 'Document Template', destination: 'EditScheduledDocumentTemplate' }],
            scheduledType: '1225',
          }
          break;
        case 'Retention':
          data = {
            hiddenColumns: ['_WorkflowID_', 'Import / Export'],
            buttons: [{ name: 'Retention', destination: 'EditRetention' }],
            scheduledType: '124',
          }
          break;
        case 'ScheduledReports':
          data = {
            hiddenColumns: ['_WorkflowID_', 'Import / Export'],
            buttons: [{ name: 'Scheduled Report', destination: 'EditScheduledReporting' }],
            scheduledType: '1223',
          }
          break;
        case 'XMLImport':
            data = {
              hiddenColumns: ['_WorkflowID_', 'Import / Export'],
              buttons: [{ name: 'XML Import', destination: 'EditXMLImport' }],
              scheduledType: '127',
            }
            break;
        default:
          data = {
            hiddenColumns: [''],
            buttons: [{ name: '', destination: '' }],
            scheduledType: '',
          }
          break;
      }
      return data
    },
  },
}

export default automationEngine;
