/* eslint-disable linebreak-style */
/* eslint-disable default-case */
/* eslint-disable consistent-return */

import { createAxiosInstance } from '@/services/axiosService'


const checkIfFieldIsUsedInAForm = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Admin/FieldIsUsedInAForm', payload)
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const checkIfUserCanAccessEForm = async (apiToken, id) => {
  const axios = createAxiosInstance(apiToken)
  const payload = {
    Value: id,
  }
  try {
    const { data } = await axios.post('v1/Document/UserCanAccessEForm', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const checkIfUserCanAccessEFormAndWorkflow = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Document/UserCanAccessEFormAndWorkflow', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const deleteEFormDraftById = async (apiToken, draftId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('v1/Document/DeleteEFormDraftByID', {
      data: {
        Value: draftId,
      }
    })
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const deletePackage = async (apiToken, packageId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('v1/Document/DeletePackager', {
      data: {
        Value: packageId,
      }
    })
    if (response) {
      return response
    }
  } catch (error) {
    console.warn(error)
  }
}

const getAllFieldHeaders = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get('v1/Admin/GetAllFieldHeaders')
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getCreateTempDocsByEFormID = async (apiToken, eFormId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/GetCreateTempDocsByEFormID', { Value: eFormId })
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getCreateTempDocsByFormID = async (apiToken, formId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/GetCreateTempDocsByFormID', { Value: formId })
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getCreateTempDocsForFormID = async (apiToken, formId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/GetDocTemplatesForEForm', { Value: formId })
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getCurrencyFormatByFieldName = async (apiToken, fieldName) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Admin/GetCurrencyFormatByFieldName', { Value: fieldName })
    if (response?.data?.Value) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const getEform = async (apiToken, formId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Document/LoadEForm', { EFormID: formId, IncludeUserSecurity: true, IncludeFields: true })
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getEFormDraftByDraftId = async (apiToken, draftId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Document/LoadEFormDraftByID', { Value: draftId })
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getEFormDrafts = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('/v1/Document/LoadMyEFormDraftsForms')
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getEFormExternalSettings = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get('v1/Admin/GetEFormExternalSettings')
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getEFormFieldsFromFormIOJson = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Document/GetEFormFieldsFromFormIOJson', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getEFormsByFormID = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Document/LoadAllEFormsByFormID', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getFileExtensions = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Document/GetFileExtensions`)
    if (response?.data && response.data.Values) {
      return response.data.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const getFormBuilderConfigByFormID = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get(`v1/Document/GetFormIOFieldSchema?Value=${payload.formID}&formType=${payload.formType}`)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getPackager = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/LoadPackager', payload)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}


const getPackagesByObjectId = async (apiToken, payload) => {

  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/GetPackagesByObjectId', payload)
    if (response) {      
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const replaceFormIOPdf = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Upload/ReplaceFormIOPdf', payload)
    return data
  }
  catch (error) {
    console.warn(error)
  }
}

const saveEForm = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Document/SaveEForm', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const saveEFormDocTemplateDefaultMapping = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Document/SaveEFormDocTemplateDefaultMapping', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const saveEFormDocTemplateMapping = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Document/SaveEFormDocTemplateMapping', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const saveEFormDraft = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Document/SaveEFormDraft', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const saveEFormExternalSettings = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Admin/SaveEFormExternalSettings', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const saveNewField = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Admin/AddFieldToForm', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const savePackager = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/SavePackager', payload)

    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const setPackageStatus = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/PostPackageSetStatus', payload)

    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const updateFieldInEForms = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Admin/UpdateFieldInEForms', payload)
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const uploadFormIOPdf = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Upload/UploadFormIOPdf', payload)
    return data
  }
  catch (error) {
    console.warn(error)
  }
}

export {
  checkIfFieldIsUsedInAForm,
  checkIfUserCanAccessEForm,
  checkIfUserCanAccessEFormAndWorkflow,
  deleteEFormDraftById,
  deletePackage,
  getAllFieldHeaders,
  getCreateTempDocsByEFormID,
  getCreateTempDocsByFormID,
  getCreateTempDocsForFormID,
  getCurrencyFormatByFieldName,
  getEform,
  getEFormDraftByDraftId,
  getEFormDrafts,
  getEFormFieldsFromFormIOJson,
  getEFormsByFormID,
  getEFormExternalSettings,
  getFileExtensions,
  getFormBuilderConfigByFormID,
  getPackager,
  getPackagesByObjectId,
  replaceFormIOPdf,
  saveEForm,
  saveEFormDocTemplateDefaultMapping,
  saveEFormExternalSettings,
  saveEFormDocTemplateMapping,
  saveEFormDraft,
  saveNewField,
  savePackager,
  setPackageStatus,
  updateFieldInEForms,
  uploadFormIOPdf,
}
