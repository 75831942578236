<template>
  <div />
</template>

<script>
/* eslint-disable  prefer-arrow-callback */
/* eslint-disable  no-floating-decimal */

import { uuid } from 'vue-uuid';
import { fabric } from 'fabric';
import canvasService from './canvasService'
import VasionCheckBoxEmptyIcon from '@/assets/images/VasionCheckBoxEmptyIcon.png'

export default {
  // Gets us the provider property from the parent <my-canvas> component.
  inject: ['provider', 'reference'],
  props: {
    disableSaveAnnotations: {
      required: false,
      type: Boolean,
      default: false,
    },
    image: {
      type: Object,
      required: false,
      default: null,
    },
    largeDefaultLoadSize: {
      required: false,
      type: Boolean,
      default: false,
    },
    pageNumber: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      actionStarted: false,
      annotationsDrawn: false,
      base64DataPrefix: 'data:image/png;base64,',
      debug: false,
      localImage: this.image,
      nullAnnotationConfig: {
        type: '',
        color: '',
        id: '',
        subType: '',
        includeTime: false,
        bottomAlign: true,
        isRequired: true,
      },
      readyToDraw: false,
      zoomFactorX: 0,
      zoomFactorY: 0,
    }
  },
  computed: {
    activeSignature() { return this.$store.state.document.activeSignature },
    activeStamp() { return this.$store.state.document.activeStamp },
    annotationConfig() { return this.$store.state.document.annotation },
    annotations() { return this.$store.state.workflow.signatureZoneAnnotations },
    deletingOnInputs() { return this.$store.state.digitalSignature.deletingOnInputs },
    propertiesAnnotationsArray() { return this.$store.state.digitalSignature.propertiesAnnotationsArray },
    saveAnnotationsToggle() { return this.$store.state.digitalSignature.saveAnnotationsToggle },
    signatureZoneSaveObject() { return this.$store.state.workflow.signatureZoneSaveObject },
    zoomValue() { return this.$store.state.document.zoomValue },
  },
  watch: {    
    annotationConfig: async function (val) {
      const { canvas } = this.provider
      if (canvas) {
        await this.removeActiveEventFunctions()
        this.clearAssociateUserConfig()
        switch (val.type) {
          case 'signatureZone':
            await this.initializeSignatureZoneCreate()
            break
          default:
            await this.initializeAnnotationSelect()
        }
        canvasService.setSubType(val.subType)
      }
    },
    readyToDraw: async function () {
      if (this.readyToDraw && !this.annotationsDrawn) {
        await this.drawAllAnnotations()
        await this.initializeAnnotationSelect()
        this.readyToDraw = false
      }

      const { canvas } = this.provider
      let intervalCounter = 0
      const checkerId = setInterval(() => {
        if (intervalCounter > 10) {
          clearInterval(checkerId)
        }
        const all = canvas.toObject()
        if (all.objects.length === this.annotations.length) {
          this.saveAllAnnotations()
          clearInterval(checkerId)
        }
        intervalCounter++
      }, 200)
    },
    saveAnnotationsToggle() {
      this.saveAllAnnotations()
    },
    selectedAnnotation(annotation) {
      canvasService.setSelectedAnnProps(annotation)
    },
    zoomValue() {
      this.setZoomValue()
    },
  },
  created() {
    this.readyToDraw = true
    this.setBackgroundImage()
      this.$nextTick(() => {
        canvasService.initialize(this.provider.canvas, this.localImage.Width, this.localImage.Height)
      })
  },
  beforeDestroy() {
    const { canvas } = this.provider
    canvas.discardActiveObject().renderAll()
  },
  methods: {
    activeObject() { return this.provider.canvas.getActiveObject() },
    annotationMouseDown() {
      if (this.debug) { console.log('annotation:move-resize mouse:down') }
      if (this.annotationConfig.type !== '') {
        return false;
      }

      this.actionStarted = true
      if (this.activeObject()) {
        const activeObject = this.activeObject()
        activeObject.bringToFront()
        this.setAssociateUserConfig(activeObject.toObject())
      }

      return true
    },
    async annotationMouseUp() {
      if (this.debug) { console.log('annotation:move-resize mouse:up') }
      if (!this.actionStarted || this.annotationConfig.type !== '') {
        return false;
      }
      this.$emit('annotationUpdate')
      await this.saveAllAnnotations()

      return true
    },
    async annotationSelectionCleared() {
      this.clearAssociateUserConfig()
    },
    // This function needs to have a name that is unique to this context file
    // because it will be fired off by global "keyup" event listener.
    async checkDeleteKeyAndDeleteAutoSign(event) {
      if (this.debug) { console.log('annotation:delete') }
      const { canvas } = this.provider
      const key = event.keyCode || event.charCode
      if (this.activeObject() && (key === 8 || key === 46) && !this.deletingOnInputs) { // KeyCodes are for delete and backspace
        this.$emit('annotationDeleted', this.activeObject().toObject().id)
        canvas.remove(this.activeObject());
        this.clearAssociateUserConfig()
        await this.saveAllAnnotations(true)
      }
    },
    clearAssociateUserConfig() {
      this.$store.dispatch('digitalSignature/setAssociateUserConfig', {
        showSetUserPanel: false,
        uniqueId: '',
        subType: '',
        includeTime: false,
        bottomAlign: true,
        isRequired: true,
      })
      canvasService.setSubType('')
    },
    async drawAllAnnotations() {
      await this.$store.dispatch('digitalSignature/setPropertiesAnnotationsArray', [])

      if (!this.annotations || this.annotations.length === 0) return 0
      const { canvas, context } = this.provider

      // Set the zoom factor based on the current size vs. the original localImage size
      // TODO: This still needs some work for smaller screens
      this.zoomFactorX = canvas.width / this.localImage.Width
      this.zoomFactorY = canvas.height / this.localImage.Height

      // Clear the canvas
      context.clearRect(0, 0, canvas.width, canvas.height)
      canvas.clear();

      // Loop through the annotations.Values and draw each one
      this.annotations.forEach((annotation) => {
        switch (annotation.AnnType) {
        case 'Rectangle':
          this.renderRectangle(annotation, canvas)
          break
        case 'checkbox':
          this.renderImage(annotation, canvas)
          break
        default:
          break
        }
      })
      this.annotationsDrawn = true
      return 1
    },
    async drawEmptyCheckBox(canvas) {
      fabric.Image.fromURL(VasionCheckBoxEmptyIcon, async (img) => {
        img.set({
          ...canvasService.getCreatingRect(),
        })

        img.toObject = this.extendFabricObject(img, uuid.v1().toString(), this.annotationConfig.subType)
        img.setControlsVisibility({ mtr: false })
        img.hasControls = false
        canvas.add(img)
        canvas.setActiveObject(img)
        
        canvas.defaultCursor = 'default'
        await this.saveAllAnnotations()
        await this.$store.dispatch('document/setAnnotationConfig', this.nullAnnotationConfig)
        this.setAssociateUserConfig(this.activeObject().toObject())
        this.$emit('annotationCreated')
      })
    },
    extendFabricObject(objectToExtend, uniqueId, subType, includeTime, bottomAlign, isRequired) {
      return (function (toObject) {
        return function () {
          return fabric.util.object.extend(toObject.call(this), {
            id: uniqueId || '',
            subType: subType || '',
            includeTime: includeTime ? true : false,
            bottomAlign: bottomAlign ? true : false,
            isRequired: isRequired ? true : false,
          });
        };
      }(objectToExtend.toObject))
    },
    async initializeAnnotationSelect() {
      await this.removeActiveEventFunctions()

      canvasService.handleEvent('mouse:down', this.annotationMouseDown)
      canvasService.handleEvent('mouse:up', this.annotationMouseUp)
      canvasService.handleEvent('selection:cleared', this.annotationSelectionCleared)
      canvasService.handleBoundedEvents()

      document.addEventListener('keyup', this.checkDeleteKeyAndDeleteAutoSign, false)
    },
    async initializeSignatureZoneCreate() {
      const { canvas } = this.provider
      canvas.defaultCursor = 'crosshair'
      await this.removeActiveEventFunctions()

      canvasService.handleEvent('mouse:down', this.signatureZoneCreateMouseDown)
      canvasService.handleEvent('mouse:move', this.signatureZoneCreateMouseMove)
      canvasService.handleEvent('mouse:up', this.signatureZoneCreateMouseUp)
    },
    async removeActiveEventFunctions() {
      canvasService.removeEventHandlers()

      document.removeEventListener('keyup', this.checkDeleteKeyAndDeleteAutoSign);
    },
    async renderImage(annotation, canvas) {
      fabric.Image.fromURL(VasionCheckBoxEmptyIcon, (img) => {
        img.set({
          left: annotation.left * this.zoomFactorX,
          top: annotation.top * this.zoomFactorY,
        })

        img.toObject = this.extendFabricObject(img, annotation.UniqueID, annotation.subType)
        img.setControlsVisibility({ mtr: false })
        img.hasControls = false
        this.setProperties(annotation)
        canvas.add(img)
      })
    },
    async renderRectangle(annotation, canvas) {
      const rectangle = new fabric.Rect({
        width: annotation.width * this.zoomFactorX,
        height: annotation.height * this.zoomFactorY,
        left: annotation.left * this.zoomFactorX,
        top: annotation.top * this.zoomFactorY,
        fill: 'rgba(254, 172, 0, 0.2)',
        stroke: 'rgba(0, 107, 227, 0.2)',
        strokeWidth: 1,
      })
      rectangle.setControlsVisibility({ mtr: false })
      rectangle.toObject = this.extendFabricObject(rectangle, annotation.UniqueID, annotation.subType, annotation?.includeTime, annotation?.bottomAlign, annotation?.isRequired)
      this.setProperties(annotation)

      canvas.add(rectangle)
    },
    async saveAllAnnotations() {
      if (this.disableSaveAnnotations) return
      const { canvas } = this.provider
      const all = canvas.toObject()
      if (all.objects.length === 0) return

      const saveObject = {
        FilePath: this.localImage.FilePath,
        PageNumber: this.localImage.PageNumber,
        DocumentId: this.localImage.DocumentId,
        ImageSource: this.localImage.ImageSource,
        Width: this.localImage.Width,
        Height: this.localImage.Height,
        OriginalDPI: this.localImage.OriginalDPI,
        OriginalHeight: this.localImage.OriginalHeight,
        OriginalWidth: this.localImage.OriginalWidth,
        PageDPI: this.localImage.PageDPI,
        Annotations: {
          Values: [],
        },
      }
      const localAnnotations = []
      const me = this
      all.objects.forEach(function (o, index) {
        let type = ''
        let properties = {}
        let name = ''

        switch (o.type) {
          case 'rect':
            type = 'Rectangle'
            properties = {
              RECT_COLOR_A: '75',
              RECT_COLOR_R: '254',
              RECT_COLOR_G: '172',
              RECT_COLOR_B: '0',
            }
            name = `${type}`
            break
          case 'image':
            type = 'checkbox'
            properties = {
              PICTURE_BASE64: VasionCheckBoxEmptyIcon,
              ISCHECKED: false,
            }
            name = `${type}`
            break
          default:
            type = 'UNKNOWN'
            break
        }
        properties.subType = o.subType
        const foundElement = me.propertiesAnnotationsArray.find(e => e.UniqueID === o.id)
        if (foundElement) { 
          properties.includeTime = foundElement.includeTime 
          properties.bottomAlign = foundElement.bottomAlign
          properties.isRequired = foundElement.isRequired
        }

        if (!o.left || !o.top || !o.width || !o.height) return

        localAnnotations.push({
          Users: [],
          Groups: [],
          Name: name,
          AnnType: type,
          Rotate: 0,
          Print: true,
          Visible: true,
          Locked: false,
          Rank: index,
          UniqueID: o.id,
          PosX: Number(o.left),
          PosY: Number(o.top),
          Height: Number(o.height) * Number(o.scaleY),
          Width: Number(o.width) * Number(o.scaleX),
          IsRequired: properties.isRequired,
          Properties: properties,
        })
      })

      saveObject.Annotations.Values = localAnnotations
      if (this.debug) { console.log(saveObject) }
      await this.$store.dispatch('workflow/storeSignatureZoneSaveObject', saveObject)
      await this.$store.dispatch('document/saveRedaction', saveObject)
    },
    setAssociateUserConfig(obj) {
      this.$store.dispatch('digitalSignature/setAssociateUserConfig', {
        showSetUserPanel: true,
        uniqueId: obj.id,
        subType: obj.subType,
        includeTime: obj.includeTime,
        bottomAlign: obj.bottomAlign,
        isRequired: obj.isRequired,
      })
    },
    setBackgroundImage() {
      const { canvas } = this.provider
      if (canvas) {
        canvas.setBackgroundImage(this.image.ImageSource, canvas.renderAll.bind(canvas), {
          backgroundImageStretch: false,
          scaleX: canvas.width / this.image.Width,
          scaleY: canvas.height / this.image.Height,
        })
      } else {
        this.$nextTick(() => {
          this.setBackgroundImage()
        })
      }
    },
    async setProperties(annotation)
    {
      let includeTime = false
      if (typeof annotation?.includeTime == 'string') {
        includeTime = annotation?.includeTime.toLowerCase() === 'true'
      } else if (typeof annotation?.includeTime == 'boolean') {
        includeTime = annotation?.includeTime
      }
      let bottomAlign = true
      if (typeof annotation?.bottomAlign == 'string') {
        bottomAlign = annotation?.bottomAlign.toLowerCase() === 'true'
      } else if (typeof annotation?.bottomAlign == 'boolean') {
        bottomAlign = annotation?.bottomAlign
      }
      let isRequired = true
      if (typeof annotation?.isRequired == 'string') {
        isRequired = annotation?.isRequired.toLowerCase() === 'true'
      } else if (typeof annotation?.isRequired == 'boolean') {
        isRequired = annotation?.isRequired
      }
      await this.$store.dispatch('digitalSignature/updateElementInPropertiesAnnotationsArray', {
        UniqueID: annotation.UniqueID,
        includeTime: includeTime,
        bottomAlign: bottomAlign,
        isRequired: isRequired,
      })
    },
    setZoomValue() {
      const { canvas } = this.provider
      if (canvas) {
        canvas.setHeight((canvas.getHeight() / canvas.getZoom()) * this.zoomValue); // Divide by the previous zoom to get the original height
        canvas.setWidth((canvas.getWidth() / canvas.getZoom()) * this.zoomValue); // Divide by the previous zoom to get the original width
        canvas.setZoom(this.zoomValue); // Now set the new zoom value
        canvas.renderAll();
      }
    },
    async signatureZoneCreateMouseDown(options) {
      if (this.debug) { console.log('signatureZone:create mouse:down') }
      if (this.annotationConfig.type === '') {
        return false;
      }

      this.actionStarted = true
      canvasService.creatingRectStart(options)

      return true
    },
    signatureZoneCreateMouseMove(options) {
      if (this.debug) { console.log('signatureZone:create mouse:move') }
      if (!this.actionStarted || this.annotationConfig.type === '') {
        return false;
      }

      canvasService.creatingRectEnd(options)

      return true
    },
    async signatureZoneCreateMouseUp() {
      if (this.debug) { console.log('signatureZone:create mouse:up') }
      if (!this.actionStarted || this.annotationConfig.type === '') {
        return false;
      }

      const { canvas } = this.provider

      if (this.actionStarted) {
        this.actionStarted = false;
      }

      if (this.annotationConfig.subType === "checkbox") {
        this.drawEmptyCheckBox(canvas)
        return true
      }

      const rectangle = new fabric.Rect({
        ...canvasService.getCreatingRect(),
        fill: 'rgba(254, 172, 0, 0.2)',
        stroke: 'rgba(0, 107, 227, 0.2)',
        strokeWidth: 1,
      })

      rectangle.setControlsVisibility({ mtr: false })
      const newID = uuid.v1().toString()
      rectangle.toObject = this.extendFabricObject(rectangle, newID, this.annotationConfig.subType)

      if (this.annotationConfig.subType === "timestamp") {
        await this.$store.dispatch('digitalSignature/updateElementInPropertiesAnnotationsArray', {
          UniqueID: newID,
          includeTime: false,
          bottomAlign: true,
          isRequired: true,
        })
      }

      canvas.add(rectangle)
      canvas.setActiveObject(rectangle)

      canvas.defaultCursor = 'default'
      await this.saveAllAnnotations()
      await this.$store.dispatch('document/setAnnotationConfig', this.nullAnnotationConfig)
      this.setAssociateUserConfig(this.activeObject().toObject())
      this.$emit('annotationCreated')

      return true
    },
  },
}
</script>
