<template>
  <div v-click-outside="clickedOutsideCanvas" />
</template>

<script>
/* eslint-disable  prefer-arrow-callback */

import { uuid } from 'vue-uuid';
import { fabric } from 'fabric';
import canvasService from './canvasService'
import VasionCheckBoxEmptyIcon from '@/assets/images/VasionCheckBoxEmptyIcon.png'
import VasionCheckBoxCheckedIcon from '@/assets/images/VasionCheckBoxCheckedIcon.png'

export default {
    // Gets us the provider property from the parent <my-canvas> component.
    inject: ['provider', 'reference'],
    props: {
      annotations: {
        type: Object,
        required: false,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: () => [],
      },
      canDelete: {
        type: Boolean,
        required: false,
        default: true,
      },
      image: {
        type: Object,
        required: false,
        default: null,
      },
      isSignatureDocument: {
        required: false,
        type: Boolean,
        default: false,
      },
      largeDefaultLoadSize: {
        required: false,
        type: Boolean,
        default: false,
      },
      pageNumber: {
        type: Number,
        required: true,
        default: null,
      },
    },
    data() {
      return {
        actionStarted: false,
        annotationsDrawn: false,
        base64DataPrefix: 'data:image/png;base64,',
        checkedCheckBoxes: [],
        debug: false,
        editingText: false,
        localImage: this.image,
        nullAnnotationConfig: {
          type: '',
          color: '',
        },
        originalAnnLayouts: new Map(),
        originalSignatures: [],
        readyToDraw: false,
        scaleFactor: 1.3,
        zoomFactorX: 0,
        zoomFactorY: 0,
      }
    },
    computed: {
      activeSignature() { return this.$store.state.document.activeSignature },
      activeStamp() { return this.$store.state.document.activeStamp },
      annotationConfig() { return this.$store.state.document.annotation },
      areAnnotationsLocked() { return this.$store.getters['common/areAnnotationsLocked'] },
      canAddAnnotation() { return this.$store.getters['common/canAddAnnotation'] },
      deleteCurrentAnnotation() { return this.$store.state.document.deleteCurrentAnnotation },
      drawSignature() { return this.$store.state.digitalSignature.drawSignature },
      pendingSignatureDocumentZones() { return this.$store.state.document.pendingSignatureDocumentZones },
      selectedAnnotation() { return this.$store.state.digitalSignature.selectedAnnotation },
      textStampToReplace() { return this.$store.state.document.textStampToReplace },
      UserID() { return this.$store.state.common.userID },
      zoomValue() { return this.$store.state.document.zoomValue },
    },
    watch: {
      annotationConfig: async function (val) {
        const { canvas } = this.provider
        if (canvas) {
          await this.removeActiveEventFunctions()
          switch (val.type) {
            case 'highlight':
              await this.initializeHighlightCreate()
              break
            case 'redaction':
              await this.initializeRedactionCreate()
              break
            case 'signature':
              canvas.defaultCursor = 'crosshair'
              if (this.annotationConfig.applyOnZone) {
                await this.signatureCreateMouseUp()
              } else {
                await this.initializeSignatureSelect()
              }
              break
            case 'stamp':
              canvas.defaultCursor = 'crosshair'
              await this.initializeStampSelect()
              break
            case 'text':
              canvas.defaultCursor = 'crosshair'
              if (this.annotationConfig.applyOnZone) {
                await this.textCreateMouseUp()
              } else {
                await this.initializeTextCreate()
              }
              break
            default:
              await this.initializeAnnotationSelect()
          }
        }
      },
      deleteCurrentAnnotation: async function(newVal) {
        const indexOfLastObjectAdded = this.provider.canvas._objects.length - 1
        const lastObjectAdded = this.provider.canvas._objects[indexOfLastObjectAdded]

        const { canvas } = this.provider
        if (newVal && canvas && lastObjectAdded) {
          canvas.remove(lastObjectAdded);
          await this.saveAllAnnotations()
        }

        await this.$store.dispatch('document/setDeleteCurrentAnnotation', false)
      },
      drawSignature() {
        if (this.drawSignature) {
          this.signatureCreateMouseUp()
        }
      },
      pageNumber() {
        this.localImage.PageNumber = this.image.PageNumber
        this.$store.dispatch('document/addOneToRefreshKey')
      },
      readyToDraw: async function () {
        if (this.readyToDraw && !this.annotationsDrawn) {
          await this.drawAllAnnotations()
          await this.initializeAnnotationSelect()
          this.readyToDraw = false
        }
      },
      selectedAnnotation(annotation) {
        canvasService.setSelectedAnnProps(annotation)
      },
      textStampToReplace: async function(newVal) {
        if (newVal) {
          const indexOfLastObjectAdded = this.provider.canvas._objects.length - 1
          const lastObjectAdded = this.provider.canvas._objects[indexOfLastObjectAdded]

          const { canvas } = this.provider
          const rect = lastObjectAdded

          canvas.remove(lastObjectAdded);

          const stamp = document.createElement('img')
          stamp.src = newVal
          stamp.onload = this.loadImage(
            stamp,
            canvas,
            rect.top,
            rect.left,
            rect.width,
            rect.height,
            this.saveAllAnnotations,
            this.extendFabricObject,
            this.setResizeControls,
            this.annotationConfig.subType,
            uuid.v1().toString(),
          )
        }
      },
      zoomValue() {
        this.setZoomValue()
      },
    },
    created() {
      this.readyToDraw = true
      this.setBackgroundImage()
      this.setZoomValue()
      
      setTimeout(() => { // setTimeout is required to actually get the values
        this.provider.canvas._objects.forEach(obj => obj.vasionId = uuid.v1().toString())
        this.originalSignatures = this.provider.canvas._objects.map(obj => obj.vasionId)
        if (this.debug) { console.log('created: originalSignatures', this.originalSignatures) }
      }, 500)
      this.$nextTick(() => {
        canvasService.initialize(this.provider.canvas, this.localImage.Width, this.localImage.Height)
      })
    },
    methods: {
      activeObject() { return this.provider.canvas.getActiveObject() },
      annotationMouseDown() {
        if (this.debug) { console.log('annotation:move-resize mouse:down') }
        if (this.annotationConfig.type !== '') {
          return false;
        }

        this.actionStarted = true
        if (this.activeObject()) {
          const activeObject = this.activeObject()
          activeObject.bringToFront()
          const obj = activeObject.toObject() // Need to do this to get the Fabric.js object extended properties we've added (ie. subType)
          this.setResizeControls(activeObject, obj.subType)
        }

        return true
      },
      async annotationMouseUp() {
        if (this.debug) { console.log('annotation:move-resize mouse:up') }
        if (!this.actionStarted || this.annotationConfig.type !== '') {
          return false;
        }
        if (this.activeObject() && this.activeObject().toObject()) {
          this.$store.dispatch('digitalSignature/setSelectedAnnotation', this.activeObject().toObject())

          if (this.selectedAnnotation && this.selectedAnnotation.subType === 'checkbox') {
            this.changeCheckboxStatus()
          }
        }
        await this.saveAllAnnotations()

        return true
      },
      async changeCheckboxStatus() {
        const { canvas } = this.provider
        const sig = document.createElement('img')

        const isChecked = this.selectedAnnotation?.isChecked?.toLowerCase() === "true"
        if (!isChecked) {
          sig.src = VasionCheckBoxCheckedIcon
          this.selectedAnnotation.isChecked = 'true'
        } else {
          sig.src = VasionCheckBoxEmptyIcon
          this.selectedAnnotation.isChecked = 'false'
        }

        sig.onload = this.loadImage(
          sig,
          canvas,
          this.selectedAnnotation.top,
          this.selectedAnnotation.left,
          this.selectedAnnotation.width,
          this.selectedAnnotation.height,
          this.saveAllAnnotations,
          this.extendFabricObject,
          this.setResizeControls,
          "checkbox",
          this.selectedAnnotation.id,
          this.annotationConfig.applyOnZone,
          this.selectedAnnotation.propertiesSubType,
          this.selectedAnnotation.isChecked,
          this.selectedAnnotation.isRequired,
        )
        if (isChecked) {
          this.$store.dispatch('document/addPendingSignatureAnnotationID', this.selectedAnnotation.id)
        } else {
          this.$store.dispatch('document/removePendingSignatureAnnotationID', this.selectedAnnotation.id)
        }
        canvas.remove(this.activeObject())

        canvas.defaultCursor = 'default'
        await this.$store.dispatch('document/setAnnotationConfig', this.nullAnnotationConfig)
        this.$store.dispatch('document/logSignatureAdded', { Value: this.localImage.DocumentId })
        this.$emit('checkRightSidePanelMobile')
        return true
      },
      async checkDeleteKeyAndDelete(event) {
        if (this.debug) { console.log('annotation:delete') }
        if (this.activeObject() && this.canAddAnnotation) {
          const { canvas } = this.provider
          const key = event.keyCode || event.charCode
          if (this.debug) { console.log('annotation:delete:can', this.activeObject().vasionId, key, this.editingText, this.canDelete, this.originalSignatures.includes(canvas.getActiveObject().vasionId)) }
          if (this.activeObject()
            && (key === 8 || key === 46) // KeyCodes are for delete and backspace
            && !this.editingText
            && this.canDelete
            && !this.originalSignatures.includes(canvas.getActiveObject().vasionId)) {
              if (this.debug) { console.log('annotation:delete:do') }
              canvas.remove(this.activeObject());
              await this.saveAllAnnotations()
          }
        }
      },
      async clickedOutsideCanvas() {
        if (this.editingText) {
          this.saveAllAnnotations()
        }
      },
      async drawAllAnnotations() {
        if (!this.annotations.Values || this.annotations.Values.length === 0) return 0

        const { canvas, context } = this.provider

        // Set the zoom factor based on the current size vs. the original localImage size
        // TODO: This still needs some work for smaller screens
        this.zoomFactorX = canvas.width / this.localImage.Width
        this.zoomFactorY = canvas.height / this.localImage.Height

        // Clear the canvas
        context.clearRect(0, 0, canvas.width, canvas.height)
        canvas.clear();

        // Loop through the annotations.Values and draw each one
        this.annotations.Values.forEach((annotation) => {
          let shouldDrawAnnotation = true
          if (this.pendingSignatureDocumentZones && this.pendingSignatureDocumentZones.length > 0 && annotation.Properties.disableSave === 'True') {
            shouldDrawAnnotation = this.pendingSignatureDocumentZones.some(e => e.annotationID === annotation.UniqueID && String(e.signatureUserID) === String(this.UserID))
          }
          if (shouldDrawAnnotation === true) {
            switch (annotation.AnnType) {
              case 'Rectangle':
                this.renderRectangle(annotation, canvas)
                break
              case 'Picture':
              case 'Signature':
              case 'Initial':
              case 'Text':
                this.renderSignature(annotation, canvas, this.setAnnotationInteraction)
                break
              case 'Checkbox':
                this.renderCheckbox(annotation, canvas)
                break
              case 'Stamp':
                this.renderStamp(annotation, canvas)
                break
              default:
                break
            }
          }
        })
        this.annotationsDrawn = true
        this.checkEmptyAnnotations()
        return 1
      },
      extendFabricObject(objectToExtend, uniqueId, subType, disableSave, propertiesSubType, includeTime, bottomAlign, isRequired, recentlySigned, isChecked) {
        return (function (toObject) {
          return function () {
            return fabric.util.object.extend(toObject.call(this), {
              id: uniqueId || '',
              subType: subType || '',
              disableSave: disableSave || '',
              propertiesSubType: propertiesSubType || '',
              includeTime: includeTime || '',
              bottomAlign: bottomAlign || '',
              isRequired: isRequired || '',
              recentlySigned: recentlySigned || '',
              isChecked: isChecked,
            });
         };
        }(objectToExtend.toObject))
      },
      async getTextImageFromText() {
        return 'image'
      },
      async highlightCreateMouseDown(event) {
        if (this.debug) { console.log('highlight:create mouse:down') }
        if (this.annotationConfig.type === '') {
          return false;
        }

        this.actionStarted = true
        canvasService.creatingRectStart(event)

        return true
      },
      highlightCreateMouseMove(event) {
        if (this.debug) { console.log('highlight:create mouse:move') }
        if (!this.actionStarted || this.annotationConfig.type === '') {
          return false;
        }

        canvasService.creatingRectEnd(event)

        return true
      },
      async highlightCreateMouseUp() {
        if (this.debug) { console.log('highlight:create mouse:up') }
        if (!this.actionStarted || this.annotationConfig.type === '') {
          return false;
        }

        const { canvas } = this.provider

        if (this.actionStarted) {
          this.actionStarted = false;
        }

        const rectangle = new fabric.Rect({
          ...canvasService.getCreatingRect(),
          fill: this.annotationConfig.color,
        })
        rectangle.setControlsVisibility({ mtr: false })
        rectangle.toObject = this.extendFabricObject(rectangle, uuid.v1().toString(), this.annotationConfig.type)

        canvas.add(rectangle)
        canvas.setActiveObject(rectangle)
        this.setResizeControls(this.activeObject(), '')

        canvas.defaultCursor = 'default'
        await this.saveAllAnnotations()
        await this.$store.dispatch('document/setAnnotationConfig', this.nullAnnotationConfig)

        return true
      },
      async initializeAnnotationSelect() {
        if (this.debug) { console.log('initializeAnnotationSelect') }
        await this.removeActiveEventFunctions()

        canvasService.handleEvent('mouse:down', this.annotationMouseDown)
        canvasService.handleEvent('mouse:up', this.annotationMouseUp)
        
        canvasService.handleBoundedEvents()

        document.addEventListener('keyup', this.checkDeleteKeyAndDelete, false)
      },
      async initializeHighlightCreate() {
        const { canvas } = this.provider
        canvas.defaultCursor = 'crosshair'
        await this.removeActiveEventFunctions()

        canvasService.handleEvent('mouse:down', this.highlightCreateMouseDown)
        canvasService.handleEvent('mouse:move', this.highlightCreateMouseMove)
        canvasService.handleEvent('mouse:up', this.highlightCreateMouseUp)
      },
      async initializeRedactionCreate() {
        const { canvas } = this.provider
        canvas.defaultCursor = 'crosshair'
        await this.removeActiveEventFunctions()

        canvasService.handleEvent('mouse:down', this.redactionCreateMouseDown)
        canvasService.handleEvent('mouse:move', this.redactionCreateMouseMove)
        canvasService.handleEvent('mouse:up', this.redactionCreateMouseUp)
      },
      async initializeSignatureSelect() {
        await this.removeActiveEventFunctions()

        canvasService.handleEvent('mouse:down', this.signatureCreateMouseDown)
        canvasService.handleEvent('mouse:move', this.signatureCreateMouseMove)
        canvasService.handleEvent('mouse:up', this.signatureCreateMouseUp)
      },
      async initializeStampSelect() {
        await this.removeActiveEventFunctions()

        canvasService.handleEvent('mouse:down', this.stampCreateMouseDown)
        canvasService.handleEvent('mouse:move', this.stampCreateMouseMove)
        canvasService.handleEvent('mouse:up', this.stampCreateMouseUp)
      },
      async initializeTextCreate() {
        await this.removeActiveEventFunctions()

        canvasService.handleEvent('mouse:down', this.textCreateMouseDown)
        canvasService.handleEvent('mouse:move', this.textCreateMouseMove)
        canvasService.handleEvent('mouse:up', this.textCreateMouseUp)
      },
      loadImage(image, canvas, top, left, width, height, saveFunction, objectExtensionFunction, setResizeControlsFunction, subType, uniqueId, applyOnZone, propertiesSubType, isChecked, isRequired = true) {
        if(!this.originalAnnLayouts.has(uniqueId)){
          this.originalAnnLayouts.set(uniqueId, {
            top,
            left,
            width,
            height
          })
        }
        top = this.originalAnnLayouts.get(uniqueId).top
        left = this.originalAnnLayouts.get(uniqueId).left
        if((this.$store.dispatch('digitalSignature/shouldApplySignatureTimestamp')) && propertiesSubType === 'initial'){
          width = this.originalAnnLayouts.get(uniqueId).width + 200
        }else{
          width = this.originalAnnLayouts.get(uniqueId).width
        }
        height = this.originalAnnLayouts.get(uniqueId).height

        return async function () {
          const newImg = new fabric.Image(image, {
            top: top,
            left: left,
            lockRotation: true,
            lockScalingFlip: true,
            strokeDashArray: [5, 5],
            ...(subType === 'checkbox' && { hoverCursor: "pointer" }),
          });

          newImg.setControlsVisibility({ mtr: false })

          if (applyOnZone) {
            newImg.lockMovementX = true
            newImg.lockMovementY = true
            newImg.lockScalingY = true
            newImg.lockScalingX = true
            newImg.lockScaling = true
          }

          const widthScale = width / image.width
          const heightScale = height / image.height
          if (widthScale < heightScale) {
            newImg.scaleToWidth(width)
          } else {
            newImg.scaleToHeight(height)
          }

          // For signature annotations, bottom align the scaled signature using the original height of the annotation
          if (propertiesSubType === 'initial' || propertiesSubType === 'signature' || propertiesSubType === 'timestamp') {
             newImg.top = newImg.top + (height - newImg.height * newImg.scaleY)
          }

          if (subType === 'checkbox') {
            newImg.toObject = objectExtensionFunction(newImg, uniqueId, subType, '', propertiesSubType, '', true, isRequired, '', isChecked)
          } else {
            newImg.toObject = objectExtensionFunction(newImg, uniqueId, subType, '', propertiesSubType, '', true)
          }

          if (subType === 'checkbox') {
            newImg.setControlsVisibility({ mtr: false })
            newImg.hasControls = false
          }

          canvas.add(newImg)
          canvas.setActiveObject(newImg)
          setResizeControlsFunction(canvas.getActiveObject(), subType)

          await saveFunction()
        }
      },
      async redactionCreateMouseDown(event) {
        if (this.debug) { console.log('redaction:create mouse:down') }
        if (this.annotationConfig.type === '') {
          return false;
        }

        this.actionStarted = true
        canvasService.creatingRectStart(event)

        return true
      },
      redactionCreateMouseMove(event) {
        if (this.debug) { console.log('redaction:create mouse:move') }
        if (!this.actionStarted || this.annotationConfig.type === '') {
          return false;
        }

        canvasService.creatingRectEnd(event)

        return true
      },
      async redactionCreateMouseUp() {
        if (this.debug) { console.log('redaction:create mouse:up') }
        if (!this.actionStarted || this.annotationConfig.type === '') {
          return false;
        }

        const { canvas } = this.provider

        if (this.actionStarted) {
          this.actionStarted = false;
        }

        const rectangle = new fabric.Rect({
          ...canvasService.getCreatingRect(),
          fill: this.annotationConfig.color,
        })
        rectangle.setControlsVisibility({ mtr: false })
        rectangle.setControlsVisibility({ mtr: false })
        rectangle.toObject = this.extendFabricObject(rectangle, uuid.v1().toString(), this.annotationConfig.subType)

        canvas.add(rectangle)
        if (!this.annotationConfig.textStamp) canvas.setActiveObject(rectangle)
        this.setResizeControls(this.activeObject(), '')

        if (this.annotationConfig.textStamp) {
          await this.$store.dispatch('document/setRectangleForTextStamp', rectangle)
        }

        canvas.defaultCursor = 'default'
        await this.saveAllAnnotations()
        await this.$store.dispatch('document/setAnnotationConfig', this.nullAnnotationConfig)

        return true
      },
      async removeActiveEventFunctions() {
        if (this.debug) { console.log('removeActiveEventFunctions') }
        canvasService.removeEventHandlers()
        document.removeEventListener('keyup', this.checkDeleteKeyAndDelete)
      },
      async renderCheckbox(annotation, canvas) {
        const isChecked = annotation?.Properties?.ISCHECKED?.toLowerCase() === "true"
        if (isChecked) this.checkedCheckBoxes.push(annotation.UniqueID)
        const image = isChecked ? `${this.base64DataPrefix}${annotation.Properties.PICTURE_BASE64}` : VasionCheckBoxEmptyIcon
        fabric.Image.fromURL(image, (img) => {
          img.set({
            left: annotation.PosX * this.zoomFactorX,
            top: annotation.PosY * this.zoomFactorY,
            hoverCursor: "pointer",
          })

          img.toObject = this.extendFabricObject(img, annotation.UniqueID, annotation.Properties.subType, '', '', '', '', annotation.IsRequired, '', isChecked.toString())
          img.setControlsVisibility({ mtr: false })
          img.hasControls = false
          img.lockMovementX = true
          img.lockMovementY = true
          canvas.add(img)
        })
      },
      async renderRectangle(annotation, canvas) {
        const r = annotation.Properties.RECT_COLOR_R
        const b = annotation.Properties.RECT_COLOR_B
        const g = annotation.Properties.RECT_COLOR_G
        let a = annotation.Properties.RECT_COLOR_A
        if (a > 1) {
          a = a / 256
        }

        let calcFill
        if (annotation.Name === 'Highlight') {
          calcFill = `rgba(${r}, ${g}, ${b}, ${a}`
        } else if (annotation.Properties.subType === 'signature' ||
          annotation.Properties.subType === 'initial' ||
          annotation.Properties.subType === 'timestamp' ||
          annotation.Properties.subType === 'text') {
          calcFill = `rgba(${r}, ${g}, ${b}, .2)`
        } else {
          calcFill = b === '0' ? 'black' : 'white'
        }
        const rectangle = new fabric.Rect({
          width: annotation.Width,
          height: annotation.Height,
          left: annotation.PosX,
          top: annotation.PosY,
          fill: calcFill,
        })

        rectangle.setControlsVisibility({ mtr: false })

        if (annotation.Properties.subType === 'signature' ||
          annotation.Properties.subType === 'initial' ||
          annotation.Properties.subType === 'timestamp' ||
          annotation.Properties.subType === 'text' ) {
          rectangle.toObject = this.extendFabricObject(rectangle, annotation.UniqueID, annotation.Name.toLowerCase(), annotation.Properties.disableSave, annotation.Properties.subType)
        }
        else if (annotation.Name === 'Highlight') {
          rectangle.toObject = this.extendFabricObject(rectangle, annotation.UniqueID, annotation.Name.toLowerCase())
        } else {
          rectangle.toObject = this.extendFabricObject(rectangle, annotation.UniqueID)
        }

        this.setAnnotationInteraction(rectangle, annotation)
        canvas.add(rectangle)
      },
      async renderSignature(annotation, canvas, sizingFunction) {
        const self = this
        if (this.largeDefaultLoadSize) {
          const isSignatureAnnotation = annotation.Properties.subType === 'signature' ||
              annotation.Properties.subType === 'initial' ||
              annotation.Properties.subType === 'timestamp'
          const sig = document.createElement('img')
          sig.src = `${this.base64DataPrefix}${annotation.Properties.PICTURE_BASE64}`
          sig.onload = function () {
            const newImg = new fabric.Image(sig, {
              top: annotation.PosY,
              left: annotation.PosX,
              lockRotation: true,
            });
            if (isSignatureAnnotation) {
              newImg.backgroundColor = "#ECDCFF"
              newImg.stroke = "#3D2562"
              newImg.strokeWidth = 2
            }
            newImg.setControlsVisibility({ mtr: false })
            newImg.scaleToWidth(annotation.Width);

            if (isSignatureAnnotation || annotation.Properties.subType === 'text' ) {
              newImg.toObject = self.extendFabricObject(newImg, annotation.UniqueID, annotation.Name.toLowerCase(),
                annotation.Properties.disableSave, annotation.Properties.subType, annotation.Properties.includeTime, annotation.Properties.bottomAlign, annotation.Properties.isRequired)
            } else {
              newImg.toObject = self.extendFabricObject(newImg, annotation.UniqueID, annotation.Name.toLowerCase())
            }

            sizingFunction(newImg, annotation)
            canvas.add(newImg);
          };
        }
      },
      async checkEmptyAnnotations()
      {
        var emptyTextAnnotations = 0
        const { canvas } = this.provider
        const all = canvas.toObject()
        all.objects.forEach(async function (o) {
          if (o.type === 'textbox') {
            emptyTextAnnotations += (o.text.trim()) ? 0 : 1
          }
        })
        this.$emit('emptyTextAnnotations', emptyTextAnnotations)
      },
      async saveAllAnnotations() {
        const { canvas } = this.provider
        const all = canvas.toObject()
        const saveObject = {
          DocumentId: this.localImage.DocumentId,
          PageNumber: this.localImage.PageNumber,
          ImageSource: this.localImage.ImageSource,
          Width: this.localImage.Width,
          Height: this.localImage.Height,
          OriginalDPI: this.localImage.OriginalDPI,
          OriginalHeight: this.localImage.OriginalHeight,
          OriginalWidth: this.localImage.OriginalWidth,
          PageDPI: this.localImage.PageDPI,
          Annotations: {
            Values: [],
          },
          IsSignatureDocument: this.isSignatureDocument,
        }
        const annotations = []
        all.objects.forEach(async function (o) {
          let color = ''
          let type = ''
          let properties = {}
          let name = ''

          switch (o.type) {
            case 'rect':
              switch (o.subType) {
                case 'highlight':
                  type = 'Rectangle'
                  properties = {
                    RECT_COLOR_A: '51',
                    RECT_COLOR_R: '254',
                    RECT_COLOR_G: '172',
                    RECT_COLOR_B: '0',
                  }
                  name = 'Highlight'
                  break
                  default:
                    type = 'Rectangle'
                    color = o.fill === 'black' ? 'Black ' : 'White '
                    properties = {
                      RECT_COLOR_A: o.fill === 'black' ? '255' : '255',
                      RECT_COLOR_R: o.fill === 'black' ? '0' : '255',
                      RECT_COLOR_G: o.fill === 'black' ? '0' : '255',
                      RECT_COLOR_B: o.fill === 'black' ? '0' : '255',
                    }
                    name = `${color}${type}`
                    break
              }
            break
            case 'image':
              switch (o.subType) {
                case 'signature':
                  type = 'Signature'
                  color = ''
                  name = type
                  break
                case 'initial':
                  type = 'Signature'
                  color = ''
                  name = 'Initial'
                  break
                case 'stamp':
                  type = 'Picture'
                  color = ''
                  name = 'Stamp'
                  break
                case 'checkbox':
                  type = 'checkbox'
                  color = ''
                  name = 'Checkbox'
                  break
                default:
                  type = 'Signature'
                  color = ''
                  name = type
                  break
              }

              properties = {
                PICTURE_BASE64: o.src.substring(22),
                ...(o.subType === 'checkbox' && { subType: 'checkbox' }),
                ...(o.subType === 'checkbox' && {ISCHECKED: o.isChecked} ),
              }
              break
            case 'textbox':
              type = 'Text'
              color = ''
              name = 'Text'
              properties = {
                ANNOTATION_TEXT: o.text,
                FONT_COLOR_A: '255',
                FONT_COLOR_R: '0',
                FONT_COLOR_G: '0',
                FONT_COLOR_B: '0',
                FONT_FAMILY_NAME: 'Arial',
                WRAP_TEXT: 'True',
                TEXT_BOLD: 'False',
                TEXT_ITALIC: 'False',
                TEXT_STRIKEOUT: 'False',
                TEXT_UNDERLINE: 'False',
              }
              break
            default:
              type = 'UNKNOWN'
              break
          }
          // eslint-disable-next-line
          if (!o.hasOwnProperty('disableSave') || o.disableSave !== 'True') {
            annotations.push({
              Users: [],
              Groups: [],
              Name: name,
              AnnType: type,
              Rotate: 0,
              Print: true,
              Visible: true,
              Locked: false,
              Rank: 3,
              UniqueID: o.id,
              PosX: Number(o.left),
              PosY: Number(o.top),
              Height: Number(o.height) * Number(o.scaleY),
              Width: Number(o.width) * Number(o.scaleX),
              IsRequired: properties.isRequired || o.isRequired || false,
              Properties: properties,
            })
          }
        })

        saveObject.Annotations.Values = annotations

        if (this.debug) { console.log("save data", saveObject) }
        await this.$store.dispatch('document/saveRedaction', saveObject)
        await this.checkEmptyAnnotations()
      },
      setAnnotationInteraction(fabricRect, serverAnnotation) {
        if (!fabricRect || !serverAnnotation) {
          return
        }

        let shouldLock = serverAnnotation.Locked || this.areAnnotationsLocked
        if (!shouldLock && serverAnnotation.Properties.disableSave) {
          shouldLock = serverAnnotation.Properties.disableSave === 'True'
        }
        if (!this.canAddAnnotation) {
          shouldLock = true
        }

        if (!shouldLock) {
          shouldLock = this.pendingSignatureDocumentZones.some(e => e.annotationID === serverAnnotation.UniqueID && String(e.signatureUserID) === String(this.UserID))
        }
        fabricRect.lockMovementX = shouldLock
        fabricRect.lockMovementY = shouldLock
        fabricRect.lockScalingY = shouldLock
        fabricRect.lockScalingX = shouldLock
        fabricRect.lockScaling = shouldLock
      },
      setBackgroundImage() {
        const { canvas } = this.provider
        let count = 0
        const limit = 10
        if (canvas) {
          canvas.setBackgroundImage(this.image.ImageSource, canvas.renderAll.bind(canvas), {
            width: canvas.width,
            height: canvas.height,
          })
          this.$emit('backgroundImageLoaded')
        } else if (count < limit) {
          count += 1
          setTimeout(() => {
            this.setBackgroundImage()
          }, 1000)
        }
      },
      setResizeControls(activeObject, subType) {
        if (subType === 'stamp' || subType === 'signature' || subType === 'initial') {
            // Remove the "middle" size controls, so the image aspect ratio will be retained when resizing
            activeObject.setControlsVisibility({
              tl: true, // top left
              tr: true, // top right
              br: true, // bottom right
              bl: true, // bottom left
              ml: false, // middle left
              mt: false, // middle top
              mr: false, // middle right
              mb: false, // middle bottom
              mtr: false, // middle top rotator
            })
          }
      },
      setTextEdit(newVal) {
        this.editingText = newVal
      },
      setZoomValue() {
        const { canvas } = this.provider
        if (canvas) {
          canvas.setHeight((canvas.getHeight() / canvas.getZoom()) * this.zoomValue); // Divide by the previous zoom to get the original height
          canvas.setWidth((canvas.getWidth() / canvas.getZoom()) * this.zoomValue); // Divide by the previous zoom to get the original width
          canvas.setZoom(this.zoomValue); // Now set the new zoom value
          canvas.renderAll();
        }
      },
      async signatureCreateMouseDown(event) {
        if (this.debug) { console.log('signature:create mouse:down') }
        if (this.annotationConfig.type === '') {
          return false;
        }

        this.actionStarted = true
        canvasService.creatingRectStart(event)

        return true
      },
      signatureCreateMouseMove(event) {
        if (this.debug) { console.log('signature:create mouse:move') }
        if (!this.actionStarted || this.annotationConfig.type === '') {
          return false;
        }

        canvasService.creatingRectEnd(event)

        return true
      },
      async signatureCreateMouseUp() {
        if (this.debug) { console.log('signature:create mouse:up') }
        if (!this.actionStarted || this.annotationConfig.type === '') {
          return false;
        }

        const { canvas } = this.provider

        if (this.annotationConfig.applyOnZone && this.selectedAnnotation.id !== this.activeObject().toObject().id) {
          canvas.defaultCursor = 'default'
          await this.$store.dispatch('document/setAnnotationConfig', this.nullAnnotationConfig)
          return false
        }

        if (this.actionStarted) {
          this.actionStarted = false;
        }
        let localSig = this.activeSignature
        if (this.annotationConfig.subType === 'signature' || this.annotationConfig.subType === 'initial') {
          if (await this.$store.dispatch('digitalSignature/shouldApplySignatureTimestamp')) {
            localSig = await this.$store.dispatch('digitalSignature/burnTimestampToImage', { Value: localSig.substr(22) })
            localSig = `${this.base64DataPrefix}${localSig}`
          }
        }
        const sig = document.createElement('img')
        sig.src = localSig
        if (this.annotationConfig.applyOnZone) {
          sig.onload = this.loadImage(
            sig,
            canvas,
            this.selectedAnnotation.top,
            this.selectedAnnotation.left,
            this.selectedAnnotation.width,
            this.selectedAnnotation.height,
            this.saveAllAnnotations,
            this.extendFabricObject,
            this.setResizeControls,
            this.annotationConfig.subType,
            this.selectedAnnotation.id,
            this.annotationConfig.applyOnZone,
            this.selectedAnnotation.propertiesSubType
          )
          this.$store.dispatch('document/removePendingSignatureAnnotationID', this.selectedAnnotation.id)
          canvas.remove(this.activeObject());
        } else {
          const rect = canvasService.getCreatingRect()
          sig.onload = this.loadImage(
            sig,
            canvas,
            rect.top,
            rect.left,
            rect.width,
            rect.height,
            this.saveAllAnnotations,
            this.extendFabricObject,
            this.setResizeControls,
            this.annotationConfig.subType,
            uuid.v1().toString(),
          )
        }
        canvas.defaultCursor = 'default'
        await this.$store.dispatch('document/setAnnotationConfig', this.nullAnnotationConfig)
        this.$store.dispatch('document/logSignatureAdded', { Value: this.localImage.DocumentId })
        this.$emit('checkRightSidePanelMobile')
        return true
      },
      async stampCreateMouseDown(event) {
        if (this.debug) { console.log('stamp:create mouse:down') }
        if (this.annotationConfig.type === '') {
          return false;
        }

        this.actionStarted = true
        canvasService.creatingRectStart(event)

        return true
      },
      stampCreateMouseMove(event) {
        if (this.debug) { console.log('stamp:create mouse:move') }
        if (!this.actionStarted || this.annotationConfig.type === '') {
          return false;
        }

        canvasService.creatingRectEnd(event)

        return true
      },
      async stampCreateMouseUp() {
        if (this.debug) { console.log('stamp:create mouse:up') }
        if (!this.actionStarted || this.annotationConfig.type === '') {
          return false;
        }

        const { canvas } = this.provider

        if (this.actionStarted) {
          this.actionStarted = false;
        }

        const rect = canvasService.getCreatingRect()
        const stamp = document.createElement('img')
        stamp.src = this.activeStamp
        stamp.onload = this.loadImage(
          stamp,
          canvas,
          rect.top,
          rect.left,
          rect.width,
          rect.height,
          this.saveAllAnnotations,
          this.extendFabricObject,
          this.setResizeControls,
          this.annotationConfig.subType,
          uuid.v1().toString(),
        )
        canvas.defaultCursor = 'default'
        await this.$store.dispatch('document/setAnnotationConfig', this.nullAnnotationConfig)

        return true
      },
      async textCreateMouseDown(event) {
        if (this.debug) { console.log('stamp:create mouse:down') }
        if (this.annotationConfig.type === '') {
          return false;
        }

        this.actionStarted = true
        canvasService.creatingRectStart(event)

        return true
      },
      textCreateMouseMove(event) {
        if (this.debug) { console.log('stamp:create mouse:move') }
        if (!this.actionStarted || this.annotationConfig.type === '') {
          return false;
        }

        canvasService.creatingRectEnd(event)

        return true
      },
      async textCreateMouseUp() {
        const me = this
        if (me.debug) { console.log('stamp:create mouse:up') }
        if (!me.actionStarted || me.annotationConfig.type === '') {
          return false;
        }

        const { canvas } = me.provider

        if (this.annotationConfig.applyOnZone && this.selectedAnnotation.id !== this.activeObject().toObject().id) {
          canvas.defaultCursor = 'default'
          await this.$store.dispatch('document/setAnnotationConfig', this.nullAnnotationConfig)
          return false
        }

        if (me.actionStarted) {
          me.actionStarted = false;
        }

        let t1 = null
        let newUniqueID = uuid.v1().toString()

        if (this.annotationConfig.applyOnZone) {
           t1 = new fabric.Textbox('', {
              width: me.selectedAnnotation.width,
              height: me.selectedAnnotation.height,
              top: me.selectedAnnotation.top,
              left: me.selectedAnnotation.left,
              fontSize: me.selectedAnnotation.height,
              textAlign: 'left',
              fontFamily: 'Arial',
              underline: false,
              fill: 'rgb(0,0,0)',
              fixedWidth: me.selectedAnnotation.width,
          })

          t1.lockMovementX = true
          t1.lockMovementY = true
          t1.lockScalingY = true
          t1.lockScalingX = true
          t1.lockScaling = true
          newUniqueID = this.selectedAnnotation.id

          this.$store.dispatch('document/removePendingSignatureAnnotationID', this.selectedAnnotation.id)
          canvas.remove(this.activeObject());
        }
        else {
          const rect = canvasService.getCreatingRect()
          t1 = new fabric.Textbox('', {
            ...rect,
            fontSize: rect.height,
            fontFamily: 'Arial',
            textAlign: 'left',
            underline: false,
            fill: 'rgb(0,0,0)',
            fixedWidth: rect.width,
          })
        }
        
        t1.setControlsVisibility({ mtr: false })

        t1.setControlsVisibility({
          mt: false, // middle top disable
          mb: false, // middle bottom
          ml: false, // middle left
          mr: false, // I think you get it
        });
        t1.setControlsVisibility({ mtr: false })

        t1.toObject = this.extendFabricObject(t1, newUniqueID)

        canvas.on('text:changed', (opt) => {
          const t2 = opt.target;
          this.checkEmptyAnnotations()
          if (t2.width > t2.fixedWidth) {
            t2.fontSize *= t2.fixedWidth / (t2.width + 1);
            t2.width = t2.fixedWidth;
          }
        })
        t1.on('editing:entered', () => {
          me.setTextEdit(true)
        })
        t1.on('editing:exited', () => {
          me.setTextEdit(false)
          this.$emit('checkRightSidePanelMobile')
        })

        canvas.add(t1)
        canvas.defaultCursor = 'default'
        await me.$store.dispatch('document/setAnnotationConfig', this.nullAnnotationConfig)

        t1.enterEditing()
        canvas.setActiveObject(t1)

        return true
      },
    },
}
</script>
