<template>
  <div />
</template>

<script>
/* eslint-disable  prefer-arrow-callback */

import { uuid } from 'vue-uuid';
import { fabric } from 'fabric';
import canvasService from './canvasService'

export default {
  name: 'AutoSignConfigContext',
    // Gets us the provider property from the parent <my-canvas> component.
    inject: ['provider', 'reference'],
    props: {
      image: {
        type: Object,
        required: false,
        default: null,
      },
      largeDefaultLoadSize: {
        required: false,
        type: Boolean,
        default: false,
      },
      pageNumber: {
        type: Number,
        required: true,
        default: null,
      },
    },
    data() {
      return {
        actionStarted: false,
        annotationsDrawn: false,
        base64DataPrefix: 'data:image/png;base64,',
        debug: false,
        localImage: this.image,
        nullAnnotationConfig: {
          type: '',
          color: '',
        },
        readyToDraw: false,
        zoomFactorX: 0,
        zoomFactorY: 0,
      }
    },
    computed: {
      activeSignature() { return this.$store.state.document.activeSignature },
      activeStamp() { return this.$store.state.document.activeStamp },
      annotationConfig() { return this.$store.state.document.annotation },
      annotations() { return this.$store.state.workflow.signatureZoneAnnotations },
    },
    watch: {
      annotationConfig: async function (val) {
        this.setBackgroundImage()
        const { canvas } = this.provider
        if (canvas) {
          if (this.annotationConfig !== this.nullAnnotationConfig) {
            canvas.clear()
          }
          await this.removeActiveEventFunctions()
          switch (val.type) {
            case 'signatureZone':
              await this.initializeSignatureZoneCreate()
              break
            default:
              await this.initializeAnnotationSelect()
          }
        }
      },
      pageNumber: async function () {
        this.localImage.PageNumber = this.image.PageNumber
        this.$store.dispatch('document/addOneToRefreshKey')
      },
      readyToDraw: async function () {
        if (this.readyToDraw && !this.annotationsDrawn) {
          await this.drawAllAnnotations()
          await this.initializeAnnotationSelect()
          this.readyToDraw = false
        }
      },
      selectedAnnotation(annotation) {
        canvasService.setSelectedAnnProps(annotation)
      },
    },
    created() {
      this.readyToDraw = true
      this.setBackgroundImage()
      this.$nextTick(() => {
        canvasService.initialize(this.provider.canvas, this.localImage.Width, this.localImage.Height)
      })
    },
    methods: {
      activeObject() { return this.provider.canvas.getActiveObject() },
      annotationMouseDown() {
        if (this.debug) { console.log('annotation:move-resize mouse:down') }
        if (this.annotationConfig.type !== '') {
          return false;
        }

        this.actionStarted = true
        if (this.activeObject()) {
          const activeObject = this.activeObject()
          activeObject.bringToFront()
        }

        return true
      },
      async annotationMouseUp() {
        if (this.debug) { console.log('annotation:move-resize mouse:up') }
        if (!this.actionStarted || this.annotationConfig.type !== '') {
          return false;
        }

        await this.saveAllAnnotations()

        return true
      },
      // This function needs to have a name that is unique to this context file
      // because it will be fired off by global "keyup" event listener.
      async checkDeleteKeyAndDeleteAutoSign(event) {
        if (this.debug) { console.log('annotation:delete') }
        const { canvas } = this.provider
        const key = event.keyCode || event.charCode
        if (this.activeObject() && (key === 8 || key === 46)) { // KeyCodes are for delete and backspace
          canvas.remove(this.activeObject());
          await this.saveAllAnnotations(true)
        }
      },
      async drawAllAnnotations() {
        if (!this.annotations || this.annotations.length === 0) return 0

        const { canvas, context } = this.provider

        // Set the zoom factor based on the current size vs. the original localImage size
        // TODO: This still needs some work for smaller screens
        this.zoomFactorX = canvas.width / this.localImage.Width
        this.zoomFactorY = canvas.height / this.localImage.Height

        // Clear the canvas
        context.clearRect(0, 0, canvas.width, canvas.height)
        canvas.clear();

        // Loop through the annotations.Values and draw each one
        this.annotations.forEach((annotation) => {
          switch (annotation.AnnType) {
          case 'Rectangle':
            this.renderRectangle(annotation, canvas)
            break
          default:
            break
          }
        })
        this.annotationsDrawn = true
        return 1
      },
      extendFabricObject(objectToExtend, uniqueId, subType) {
        return (function (toObject) {
          return function () {
            return fabric.util.object.extend(toObject.call(this), {
              id: uniqueId || '',
              subType: subType || '',
            });
          };
        }(objectToExtend.toObject))
      },
      async initializeAnnotationSelect() {
        await this.removeActiveEventFunctions()

        canvasService.handleEvent('mouse:down', this.annotationMouseDown)
        canvasService.handleEvent('mouse:up', this.annotationMouseUp)

        canvasService.handleBoundedEvents()

        document.addEventListener('keyup', this.checkDeleteKeyAndDeleteAutoSign, false)
      },
      async initializeSignatureZoneCreate() {
        const { canvas } = this.provider
        canvas.defaultCursor = 'crosshair'
        await this.removeActiveEventFunctions()

        canvasService.handleEvent('mouse:down', this.signatureZoneCreateMouseDown)
        canvasService.handleEvent('mouse:move', this.signatureZoneCreateMouseMove)
        canvasService.handleEvent('mouse:up', this.signatureZoneCreateMouseUp)
      },
      async removeActiveEventFunctions() {
        canvasService.removeEventHandlers()

        document.removeEventListener('keyup', this.checkDeleteKeyAndDeleteAutoSign);
      },
      async renderRectangle(annotation, canvas) {
        const rectangle = new fabric.Rect({
          width: annotation.width,
          height: annotation.height,
          left: annotation.left,
          top: annotation.top,
          fill: 'rgba(254, 172, 0, 0.2)',
          stroke: 'rgba(0, 107, 227, 0.2)',
          strokeWidth: 1,
        })

        rectangle.setControlsVisibility({ mtr: false })
        rectangle.toObject = this.extendFabricObject(rectangle, annotation.UniqueID)
        canvas.add(rectangle)
      },
      async saveAllAnnotations() {
        const { canvas } = this.provider
        const all = canvas.toObject()

        const saveObject = {
          FilePath: this.localImage.FilePath,
          ForceNormalAnnotation: true,
          DocumentId: this.localImage.DocumentId,
          PageNumber: this.localImage.PageNumber,
          ImageSource: this.localImage.ImageSource,
          Width: this.localImage.Width,
          Height: this.localImage.Height,
          OriginalDPI: this.localImage.OriginalDPI,
          OriginalHeight: this.localImage.OriginalHeight,
          OriginalWidth: this.localImage.OriginalWidth,
          PageDPI: this.localImage.PageDPI,
          Annotations: {
            Values: [],
          },
        }
        const localAnnotations = []

        all.objects.forEach(function (o) {
          let type = ''
          let properties = {}
          let name = ''

          switch (o.type) {
            case 'rect':
              type = 'Rectangle'
              properties = {
                RECT_COLOR_A: o.fill === '0.2',
                RECT_COLOR_R: o.fill === '254',
                RECT_COLOR_G: o.fill === '172',
                RECT_COLOR_B: o.fill === '0',
              }
              name = `${type}`
              break
            default:
              type = 'UNKNOWN'
              break
          }

          localAnnotations.push({
            Users: [],
            Groups: [],
            Name: name,
            AnnType: type,
            Rotate: 0,
            Print: true,
            Visible: true,
            Locked: false,
            Rank: 3,
            UniqueID: o.id,
            PosX: Number(o.left),
            PosY: Number(o.top),
            Height: Number(o.height) * Number(o.scaleY),
            Width: Number(o.width) * Number(o.scaleX),
            IsRequired: properties.isRequired,
            Properties: properties,
          })
        })

        saveObject.Annotations.Values = localAnnotations
        if (this.debug) { console.log(saveObject) }
        await this.$store.dispatch('workflow/storeSignatureZoneSaveObject', saveObject)
        await this.$store.dispatch('document/saveRedaction', saveObject)
      },
      setBackgroundImage() {
        const { canvas } = this.provider
        if (canvas) {
          canvas.setBackgroundImage(this.image.ImageSource, canvas.renderAll.bind(canvas), {
            backgroundImageStretch: false,
            scaleX: canvas.width / this.image.Width,
            scaleY: canvas.height / this.image.Height,
          })
        } else {
          this.$nextTick(() => {
            this.setBackgroundImage()
          })
        }
      },
      async signatureZoneCreateMouseDown(options) {
        if (this.debug) { console.log('signatureZone:create mouse:down') }
        if (this.annotationConfig.type === '') {
          return false;
        }

        this.actionStarted = true
        canvasService.creatingRectStart(options)

        return true
      },
      signatureZoneCreateMouseMove(options) {
        if (this.debug) { console.log('signatureZone:create mouse:move') }
        if (!this.actionStarted || this.annotationConfig.type === '') {
          return false;
        }

        canvasService.creatingRectEnd(options)
        return true
      },
      async signatureZoneCreateMouseUp() {
        if (this.debug) { console.log('signatureZone:create mouse:up') }
        if (!this.actionStarted || this.annotationConfig.type === '') {
          return false;
        }

        const { canvas } = this.provider

        if (this.actionStarted) {
          this.actionStarted = false;
        }

        const rectangle = new fabric.Rect({
        ...canvasService.getCreatingRect(),
          fill: 'rgba(254, 172, 0, 0.2)',
          stroke: 'rgba(0, 107, 227, 0.2)',
          strokeWidth: 1,
        })
        rectangle.setControlsVisibility({ mtr: false })
        rectangle.toObject = this.extendFabricObject(rectangle, uuid.v1().toString(), this.annotationConfig.subType)

        canvas.add(rectangle)
        canvas.setActiveObject(rectangle)

        canvas.defaultCursor = 'default'
        await this.saveAllAnnotations()
        await this.$store.dispatch('document/setAnnotationConfig', this.nullAnnotationConfig)

        return true
      },
    },
}
</script>
