/* eslint-disable linebreak-style */
/* eslint-disable no-console */
import axios from 'axios';
import { createAxiosInstance } from '@/services/axiosService'
import {
  approveMSA,
  buildSendToVasionActionString,
  doGetEmailTemplates,
  doGetGroups,
  doGetUsers,
  deleteBarcodeConfig,
  deleteEForm,
  deleteEmailTemplate,
  getAllDocumentTemplates,
  getConfig,
  getIndexFields,
  getIsTextractLicensed,
  getPasswordSchema,
  getUserHasSchedulers,
  loginUser,
  needPasswordReset,
  requestPasswordReset,
  resetPassword,
  resetPasswordCheckGuid,
  setConfig,
  updatePassword,
  validateSendToVasionActionString,
  doGetUsersWithAutoCompleted,
} from '@/store/apiModules/common.api'

import {
  getMyForms,
 } from '@/store/apiModules/attributeForm.api'

import {
  allEntriesExist,
  atLeastOneExists,
  buildAndNavigateToScanToVasionUrl,
  getToken,
} from '@/store/helperModules/common.module'

import { SecurityRoles } from '@/enums/securityRoles';

import AmplitudeService from '@/services/AmplitudeService'

function initialState() {
  return {
    apiToken: '',
    appAccess: null,
    documentTemplates: [],
    emailTemplates: [],
    firstName: '',
    formsURL: '',
    groups: [],
    hideNavbarAndHeader: false,
    indexFields: [],
    indexFieldsObjectID: 0,
    isMSAApproved: null,
    isSignatureDocLinkView: false,
    isSignedIn: false,
    lastName: '',
    loadedFolders: [],
    loginType: 1,
    mainContainerWithFullWidth: false,
    needsPasswordReset: false,
    pageSize: 100,
    pageSizeOptions: [5, 10, 15, 20, 25, 50, 75, 100],
    roles: [],
    selectedFolder: null,
    selectedFolderId: null,
    selectedStorageConfigId: null,
    storageSideNavRefresh: 1,
    suppressDefaultApp: false,
    users: [],
    usersWithAutoComplete: [],
    userID: 0,
    userIsAdmin: false,
    userName: '',
    userHasForms: false,
    userHasSchedulers: false,
    userCanEditSomeWorkflows: false,
    userSessionExpiry: null,
  }
}

function updateStorage(storageType, key, newValue) {
  const oldValue = storageType.getItem(key);
  storageType.setItem(key, newValue);

  const event = new StorageEvent('storage', {
    key,
    oldValue,
    newValue,
    storageArea: storageType,
    url: window.location.href,
  });
  window.dispatchEvent(event);
}

const common = {
  namespaced: true,
  state: initialState,
  mutations: {
    addOneToStorageSideNavRefresh(state) { state.storageSideNavRefresh += 1 },
    hideNavbarAndHeader(state, value) { state.hideNavbarAndHeader = value },
    resetStoreData(state) {
      const localState = initialState()
      Object.keys(state).forEach((prop) => {
        state[prop] = localState[prop]
      })

      localStorage.clear()
    },
    setDocumentTemplates(state, value) { state.documentTemplates = value },
    setEmailTemplates(state, value) { state.emailTemplates = value },
    setFormsURL(state, value) { state.formsURL = value },
    setGroupList(state, groups) { state.groups = groups },
    setIndexFieldList(state, fields) { state.indexFields = fields },
    setIndexFieldsObjectID(state, objectID) { state.indexFieldsObjectID = objectID },
    setIsMSAApproved(state, value) { state.isMSAApproved = value },
    setIsSignatureDocLinkView(state, value) { state.isSignatureDocLinkView = value },
    setIsSignedIn(state, value) { state.isSignedIn = value },
    setLoadedFolders(state, value) { state.loadedFolders = value; },
    setLoginError(state, value) {state.loginError = value},
    setMainContainerWithFullWidth(state, value) {state.mainContainerWithFullWidth = value},
    setPageSize(state, value) { state.pageSize = value },
    setPasswordResetState(state, value) { state.needsPasswordReset = value === 'true' },
    setSelectedFolder(state, value) { state.selectedFolder = value; },
    setSelectedFolderId(state, value) { state.selectedFolderId = value; },
    setSelectedFolderSelectionValue(state, value) {
      if (state.selectedFolder) {
        state.selectedFolder.isSelected = value;
      }
    },
    setSelectedStorageConfigId(state, value) { state.selectedStorageConfigId = value; },
    setSuppressDefaultApp(state, value) { state.suppressDefaultApp = value },
    setUserSessionExpiry(state, value) { state.userSessionExpiry = value },
    setUserData(state, value) {
      state.apiToken = value.Token
      state.userName = value.Username
      state.isSignatureDocLinkView = value.IsSignatureDocLinkView === 'true' || value.IsSignatureDocLinkView === true
      state.loginType = value.LoginType
      state.userID = value.UserId
      if (value.Roles) {
        // if coming from the endpoint, we can an array.  If coming from localStorage, we get a string
        state.roles = Array.isArray(value.Roles) ? value.Roles : value.Roles.split(',')
      } else {
        state.roles = []
      }

      if (value.AppAccess) {
        state.appAccess =  Array.isArray(value.AppAccess) ? value.AppAccess : value.AppAccess.split(',')
      }
      else {
        state.appAccess = []
      }
      if (state.appAccess.length === 0) state.appAccess = null
 
      state.userIsAdmin = value.IsAdmin
      state.firstName = value.FirstName
      state.lastName = value.LastName
      state.userHasForms = value.UserHasForms
      state.userHasSchedulers = value.UserHasSchedulers
      state.userCanEditSomeWorkflows = value.UserCanEditSomeWorkflows
      
      state.isSignedIn = value.Token !== ''

      if (value.UpdateStorage === true) {
        localStorage.setItem('UserToken', state.apiToken)
        localStorage.setItem('Username', state.userName)
        localStorage.setItem('LoginType', state.loginType)
        localStorage.setItem('UserID', state.userID)
        localStorage.setItem('Roles', state.roles)
        localStorage.setItem('AppAccess', state.appAccess)
        localStorage.setItem('IsAdmin', state.userIsAdmin)
        localStorage.setItem('FirstName', state.firstName)
        localStorage.setItem('LastName', state.lastName)
        localStorage.setItem('UserHasForms', state.userHasForms)
        localStorage.setItem('UserHasSchedulers', state.userHasSchedulers)
        localStorage.setItem('IsSignatureDocLinkView', state.isSignatureDocLinkView)
        localStorage.setItem('UserCanEditSomeWorkflows', state.userCanEditSomeWorkflows)
        
        if (state.userID === 0) {
          sessionStorage.setItem('ActiveSubApp', '')
          sessionStorage.setItem('MainNav', '')
        }
      }
      
      if (state.userID !== 0 && value.systemSettings?.featureFlags?.vasionAutomateBridge) {
        let bridgeActions = []
        let domains = {}

        if (this.getters['common/showAdminApp'])
          bridgeActions.push("ProAdmin - Admin")
        if (this.getters['common/APP_ACCESS_AUTOMATION'] && (state.userHasSchedulers || this.getters['common/canAccessAutomationApp_RolesOnly']))
          bridgeActions.push("ProAutomation - Admin")
        if (this.getters['common/showCaptureApp'])
          bridgeActions.push("ProCapture - Admin")
        if (this.getters['common/APP_ACCESS_FORMS'] && (state.userHasForms || this.getters['common/canAccessFormsApp_RolesOnly']))
          bridgeActions.push("ProForms - Admin")
        if (this.getters['common/showReportsApp'])
          bridgeActions.push("ProReporting - Admin")
        if (this.getters['common/showSignatureApp'])
          bridgeActions.push("ProSignature - Admin")
        if (this.getters['common/showStorageApp'])
          bridgeActions.push("ProStorage - Admin")
        if (this.getters['common/showTemplatesApp'])
          bridgeActions.push("ProTemplates - Admin")
        if (this.getters['common/showWorkflowApp'])
          bridgeActions.push("ProWorkflow - Admin")
        if (this.getters['common/showWorkspaceApp'])
          bridgeActions.push("ProWorkspace - Admin")

        if (this.getters['common/canAccessVaOutputMgmt_RolesOnly'])
          bridgeActions.push("Output - Admin")
        if (this.getters['common/canAccessVaPrint_RolesOnly'])
          bridgeActions.push("PI - Admin")
        if (this.getters['common/canAccessVaRules_RolesOnly'])
          bridgeActions.push("Rules - Admin")

        domains = {
          pi: value.systemSettings?.vasionPrintURL,
          pro: window.location.origin + process.env.VUE_APP_IIS_APPLICATION,
          vac: value.systemSettings?.vasionAutomateURL,
        }

        const userInfoForSideRail = {
          actions: bridgeActions,
          displayName: state.firstName,
          email: state.userName,
          firstName: state.firstName,
          lastName: state.lastName,
        }
        
        updateStorage(sessionStorage, 'userInfo', JSON.stringify(userInfoForSideRail))
        updateStorage(sessionStorage, 'domains', JSON.stringify(domains))
      }
    },
    setUserList(state, users) { state.users = users },
    setUserListWithAutoComplete(state, usersWithAutoComplete) {state.usersWithAutoComplete = usersWithAutoComplete},
  },
  actions: {
    addOneToStorageSideNavRefresh({ commit }) { commit('addOneToStorageSideNavRefresh') },
    async approveMSA({ commit, rootState }) {
      const error = await approveMSA(rootState.common.apiToken)
      await commit('setLoginError', error)
      if (!error) {
        commit('setIsMSAApproved', true)
      }
      return error
    },    
    async authenticateNoPassword({ commit, dispatch, rootState }, credentials) {
      try {
        const res = await axios.post('/v1/Auth/AuthenticateNP', credentials, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        let userTokenSaved;
        if (res && res.data && res.data.Token) {
          const [userHasSchedulers, userHasForms, ] = await Promise.all([
            getUserHasSchedulers(res.data.Token),
            dispatch('getUserHasForms', res.data.Token),
            dispatch('systemSettings/getAllSystemSettings', {apiToken: res.data.Token, forceFetch: true}, { root: true })
          ])

          const userData = {
            AppAccess: res.data.AppAccess,
            Token: res.data.Token,
            Username: res.data.Username,
            LoginType: res.data.LoginType,
            UserId: res.data.UserId,
            UpdateStorage: true,
            Roles: res.data.Roles.Values.map(r => r.Value.SystemName),
            IsAdmin: res.data.IsAdmin,
            FirstName: res.data.FirstName,
            LastName: res.data.LastName,
            UserHasForms: userHasForms,
            UserHasSchedulers: userHasSchedulers,
            UserCanEditSomeWorkflows: res.data.WorkflowAppAccess,
          };
          userData.systemSettings = rootState.systemSettings
          userTokenSaved = await commit('setUserData', userData);
          await commit('setIsMSAApproved', res.data.IsMSAApproved)
        }

        return userTokenSaved;
      } catch (error) {
        console.warn(error);
      }
    },
    async buildSendToVasionActionString({ rootState }, payload) {
      payload.authToken = rootState.common.apiToken
      const actionString = await buildSendToVasionActionString(rootState.common.apiToken, payload)
      if (await validateSendToVasionActionString(rootState.common.apiToken, { Value: actionString })) {
        return actionString
      }
      return null
    },
    async checkIsAuthenticated({ commit, dispatch, rootState }) {
      const userData = {
        Token: '',
        Username: '',
        LoginType: 1,
        UserId: 0,
        UpdateStorage: true,
        FirstName: '',
        LastName: '',
      };

      // this check is important for a site refresh
      // that clears out the baseURL for axios, so we're resetting it here
      // on refresh, the first thing we do is check if we're already authenticated,
      // so it makes sense to add that extra check here
      await dispatch('resetAxiosDefaultBaseURL')

      const userToken = localStorage.getItem('UserToken');
      if (userToken && userToken !== '') {
        try {
          const response = await axios.get('/v1/auth/IsAuthenticated', {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `X-MVApiAuth ${userToken}`,
            },
          });

          if (response) {
            userData.Token = userToken
            const localAppAccess = localStorage.getItem('AppAccess')
            if (localAppAccess) {
              userData.AppAccess = Array.isArray(localAppAccess) ? localAppAccess : localAppAccess.split(',')
              userData.AppAccess = userData.AppAccess.filter(e => e !== 'null')
            }
            else {
              userData.AppAccess = []
            }
            if (userData.AppAccess.length === 0) userData.AppAccess = null

            userData.Username = localStorage.getItem('Username')
            userData.LoginType = localStorage.getItem('LoginType')
            userData.UserId = localStorage.getItem('UserID')
            userData.Roles = localStorage.getItem('Roles')
            userData.IsAdmin = localStorage.getItem('IsAdmin')
            userData.FirstName = localStorage.getItem('FirstName')
            userData.LastName = localStorage.getItem('LastName')
            userData.UserHasForms = (localStorage.getItem('UserHasForms') === 'true')
            userData.UserHasSchedulers = (localStorage.getItem('UserHasSchedulers') === 'true')
            userData.UpdateStorage = false
            userData.IsSignatureDocLinkView = localStorage.getItem('IsSignatureDocLinkView')
            userData.UserCanEditSomeWorkflows = (localStorage.getItem('UserCanEditSomeWorkflows') === 'true')

            if (response.headers['x-mvapiauthexp']) {
              commit('setUserSessionExpiry', new Date().getTime() + response.headers['x-mvapiauthexp'] * 60000)
            }
          }
        } catch (error) {
          console.warn(error);
        }
      }
      await dispatch('systemSettings/getAllSystemSettings', {apiToken: userData.Token, forceFetch: false}, { root: true })
      userData.systemSettings = rootState.systemSettings
      commit('setUserData', userData);
    },
    async deleteBarcodeConfig({ rootState }, id) {
      const data = await deleteBarcodeConfig(rootState.common.apiToken, id)
      if (data.ResultStatus === 1) {
        return true
      } else {
        return false
      }
    },
    async deleteEForm({ rootState }, payload) {
      return await deleteEForm(rootState.common.apiToken, payload)
    },
    async deleteEmailTemplate({ dispatch, rootState }, payload) {
      const data = await deleteEmailTemplate(rootState.common.apiToken, payload)
      if (data?.Value === 'True') {
        dispatch('getEmailTemplates')
        return true
      } else {
        return false
      }
    },
    getChildFolders({ rootState }, payload) {
      const axiosInstance = createAxiosInstance(rootState.common.apiToken)
      let folderResponse;
      const { parentFolderId, enforceUserSecurity, groupByStorageType, storageConfigId } = payload
      let { includeWFQueue } = payload
      if (!includeWFQueue) {
        includeWFQueue = false
      }

      try {
        const internalPayload = {
          FolderId: parentFolderId,
          Filter: '',
          IncludeWFQueue: includeWFQueue,
          Skip: 0,
          Take: 10000,
          EnforceUserSecurity: enforceUserSecurity,
          GroupByThirdPartyStorageType: groupByStorageType,
          StorageConfigId: storageConfigId,
        }
        folderResponse = axiosInstance.post('/v1/Workflow/GetFolderList', internalPayload)
      } catch (error) {
        console.warn('error: ', error)
      }

      return folderResponse;
    },
    async getConfig({ rootState }, config) {
      const configValue = await getConfig(rootState.common.apiToken, config)
      return configValue && configValue.sConfigValue ? configValue.sConfigValue : ''
    },
    async getDocumentTemplates({ commit, rootState }) {
      let templates = []
      const data = await getAllDocumentTemplates(rootState.common.apiToken)

      if (data && data.Values) {
        templates = data.Values
      }

      commit('setDocumentTemplates', templates)
    },
    async getEmailTemplates({ commit, rootState }) {
      let templates = []
      const data = await doGetEmailTemplates(rootState.common.apiToken)

      if (data && data.Values) {
        templates = data.Values
      }

      commit('setEmailTemplates', templates)
    },
    async getEmptyAppGreeting({ rootState }) {
      const today = new Date()
      const hours = today.getHours()
      let timeOfDay = 'morning'

      if (hours >= 12 && hours < 17) {
        timeOfDay = 'afternoon'
      } else if (hours >= 17) {
        timeOfDay = 'evening'
      }

      const displayName = rootState.common.firstName ? rootState.common.firstName : rootState.common.userName
      return `Good ${timeOfDay}, ${displayName}`
    },
    getFolderListWithDocuments({ rootState }, payload) {
      const axiosInstance = createAxiosInstance(rootState.common.apiToken)
      let folderResponse;
      const { parentFolderId, enforceUserSecurity, maxxDocumentCount } = payload

      try {
        const internalPayload = {
          FolderId: parentFolderId,
          Filter: '',
          IncludeWFQueue: false,
          Skip: 0,
          Take: 10000,
          EnforceUserSecurity: enforceUserSecurity,
          MaxDocumentCount: maxxDocumentCount,
        };

        folderResponse = axiosInstance.post('/v1/Workflow/GetFolderListWithDocuments', internalPayload);
      } catch (error) {
        console.warn('error: ', error)
      }

      return folderResponse;
    },
    async getGroups({ commit, rootState }) {
      const groupList = [];
      const data = await doGetGroups(getToken(rootState.common.apiToken));

      if (data?.Values) {
        Object.keys(data.Values).map((key) => {
          let group
          if (data?.Values.length > 0 && data?.Values[key]) {
            group = data.Values[key]

            groupList.push({
              name: group.Name,
              value: Number(group.ID),
            });
          }
          return true;
        });
      }

      commit('setGroupList', groupList);
    },
    async getIndexFieldsForForm({ commit, rootState, dispatch }, formId) {
      await dispatch('resetIndexFields')
      const fieldList = []

      try {
        if (formId > 0) {
          const response = await getIndexFields(rootState.common.apiToken, formId)

          if (response && response.Fields && response.Fields.Values) {
            Object.keys(response.Fields.Values).map((key) => {
              let field
              if (response.Fields.Values[key]) {
                field = response.Fields.Values[key]

                const dropdownValues = []
                if (field.DropDownValues && field.DropDownValues.Values && field.DropDownValues.Values.length > 0) {
                  Object.keys(field.DropDownValues.Values).map((innerkey) => {
                    dropdownValues.push({
                      name: field.DropDownValues.Values[innerkey],
                      value: Number(innerkey),
                    })
                    return true
                  })
                }

                fieldList.push({
                  type: field.Control,
                  name: field.DisplayName,
                  value: field.FieldName,
                  dropdownValues: dropdownValues,
                  fieldID: field.FieldID,
                  readOnly: field.ReadOnly,
                  required: field.Required,
                  hidden: field.Hidden,
                  allowMultiples: field.AllowMultiples,
                  showCommas: field.ShowCommas,
                  decimalPlaces: field.DecimalPlaces,
                  mask: field.Mask,
                  fieldLength: field.FieldLength,
                  currencyFormat: field.CurrencyFormat,
                })
              }
              return true
            })
          }
        }
      } catch (error) {
        console.warn(error)
      }

      commit('setIndexFieldsObjectID', formId)
      commit('setIndexFieldList', fieldList)
      return fieldList
    },
    async getIsTextractLicensed({ rootState }) {
      return await getIsTextractLicensed(rootState.common.apiToken)
    },
    async getPasswordSchema({ rootState }) {
      const { data: Value } = await getPasswordSchema(rootState.common.apiToken)
      return Value
    },
    async getUserHasForms(_, apiToken) {
      if (!apiToken) return false
      const payload = {
        StartNumber: 1,
        MaxReturnCount: 10000,
        IncludeUserSecurity: false,
        IncludeFields: false,
        PopulateOnlyNameAndID: true,
      }
      try {
        const response = await getMyForms(apiToken, payload)
        return response?.data?.EFormCollection?.Values?.length > 0
      } catch (error) {
        console.warn(error)
        return false
      }
    },
    async getUsers({ commit, rootState }, payload) {
      const userList = [];
      const data = await doGetUsers(getToken(rootState.common.apiToken), payload)

      if (data?.Values) {
        Object.keys(data.Values).map((key) => {
          let user
          if (data.Values && data.Values.length > 0 && data.Values[key]) {
            user = data.Values[key]

            userList.push({
              name: `${user.FirstName || ''} ${user.LastName || ''}`,
              value: Number(user.UserId),
              username: user.Username,
            });
          }
          return true;
        });
      }
      commit('setUserList', userList);
    },
    async getUsersWithAutoCompleted({ commit, rootState }) {
      const userListWithAutoCompleted = [];
      const data = await doGetUsersWithAutoCompleted(getToken(rootState.common.apiToken))

      if (data?.Values) {
        Object.keys(data.Values).map((key) => {
          let user
          if (data.Values && data.Values.length > 0 && data.Values[key]) {
            user = data.Values[key]

            userListWithAutoCompleted.push({
              name: `${user.FirstName || ''} ${user.LastName || ''}`,
              value: Number(user.UserId),
              username: user.Username,
            });
          }
          return true;
        });
      }
      commit('setUserListWithAutoComplete', userListWithAutoCompleted);
    },
    hideNavbarAndHeader({ commit }, value) { commit('hideNavbarAndHeader', value) },
    async needsPasswordReset({ rootState, commit }, password) {
      const userId = localStorage.getItem('UserID')
      const { data } = await needPasswordReset(rootState.common.apiToken, { Value: `${password}~${userId}` })

      await commit('setPasswordResetState', data.Value)
      return data.Value === 'true'
    },
    async openScanToVasion({ dispatch }, payload) {
      const actionString = await dispatch('buildSendToVasionActionString', payload)
      buildAndNavigateToScanToVasionUrl(actionString)
    },
    async requestPasswordReset({ rootState, dispatch }, payload) {
      await dispatch('resetAxiosDefaultBaseURL')
      const response = await requestPasswordReset(rootState.common.apiToken, payload)
      return response
    },
    async resetAxiosDefaultBaseURL({ commit }) {
      if (axios.defaults.baseURL === undefined || axios.defaults.baseURL === '') {
        axios.defaults.baseURL = `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_API}`;
        commit('setFormsURL', `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_FORMS}`)
      }
    },
    resetIndexFields({ commit }) {
      commit('setIndexFieldsObjectID', 0)
      commit('setIndexFieldList', [])
    },
    runFindReplaceIndexData({ rootState }, payload) {
      const axiosInstance = createAxiosInstance(rootState.common.apiToken)
      let response;

      try {
        response = axiosInstance.post('/v1/admin/FindAndReplaceIndexData', payload);
      } catch (error) {
        console.warn(error)
      }

      return response;
    },
    async setConfig({ rootState }, value) { setConfig(rootState.common.apiToken, value) },
    setGroupAndUsers({ commit }, value) {
      commit('setUserList', value.userList)
      commit('setGroupList', value.groupList)
      commit('setUserListWithAutoComplete', value.userListWithAutoCompleted)
    },
    setIndexFields({ commit }, fieldsArray) { return commit('setIndexFieldList', fieldsArray) },
    setIsSignatureDocLinkView({ commit }, value) { return commit('setIsSignatureDocLinkView', value) },
    setIsSignedIn({ commit }, value) { return commit('setIsSignedIn', value) },
    setLoginError({ commit }, value) { return commit('setLoginError', value) },
    setMainContainerWithFullWidth({ commit }, mainContainerWithFullWidth) { return commit('setMainContainerWithFullWidth', mainContainerWithFullWidth) },
    setPageSize({ commit }, pageSize) { return commit('setPageSize', pageSize) },
    setPasswordResetState({ commit }, passwordResetStateString) { return commit('setPasswordResetState', passwordResetStateString) },
    setSelectedFolder({ commit }, value) { return commit('setSelectedFolder', value); },
    setSelectedFolderId({ commit }, value) { return commit('setSelectedFolderId', value); },
    setSelectedFolderSelectionValue({ commit }, value) { return commit('setSelectedFolderSelectionValue', value); },
    setSelectedStorageConfigId({ commit }, value) { return commit('setSelectedStorageConfigId', value); },
    setSignatureDocLinkView({ commit }, value) { return commit('setSignatureDocLinkView', value); },
    async setUserDataWithAuthInfo({ commit, dispatch, rootState }, payload) {
      const [userHasSchedulers, userHasForms, ] = await Promise.all([
        getUserHasSchedulers(payload.Token),
        dispatch('getUserHasForms', payload.Token),
        dispatch('systemSettings/getAllSystemSettings', {apiToken: payload.Token, forceFetch: true}, { root: true })
      ])

      const userData = {
        AppAccess: payload.AppAccess,
        Token: payload.Token,
        Username: payload.Username,
        LoginType: payload.LoginType,
        UserId: payload.UserId,
        UpdateStorage: true,
        Roles: payload.Roles.Values.map(r => r.Value.SystemName),
        IsAdmin: payload.IsAdmin,
        FirstName: payload.FirstName,
        LastName: payload.LastName,
        UserHasForms: userHasForms,
        UserHasSchedulers: userHasSchedulers,
        UserCanEditSomeWorkflows: payload.WorkflowAppAccess,
      };

      userData.systemSettings = rootState.systemSettings
      const setDataSuccess = await commit('setUserData', userData);
      return setDataSuccess
    },
    async signIn({ rootState, commit, dispatch }, credentials) {
      await commit('setLoginError', '')
      await dispatch('systemSettings/resetStoreData', '', { root: true })
      if (credentials.Username.toLowerCase() !== localStorage.getItem('Username')?.toLowerCase()) localStorage.clear()
      const userToken = localStorage.getItem('UserToken');
      if (userToken && userToken !== '') {
        const userData = {
          Token: userToken,
          AppAccess: localStorage.getItem('AppAccess'),
          Username: localStorage.getItem('Username'),
          LoginType: localStorage.getItem('LoginType'),
          UserId: localStorage.getItem('UserID'),
          Roles: localStorage.getItem('Roles'),
          UpdateStorage: false,
          IsAdmin: localStorage.getItem('IsAdmin'),
          FirstName: localStorage.getItem('FirstName'),
          LastName: localStorage.getItem('LastName'),
          UserHasForms: localStorage.getItem('UserHasForms'),
          UserHasSchedulers: localStorage.getItem('UserHasSchedulers'),
          UserCanEditSomeWorkflows: localStorage.getItem('UserCanEditSomeWorkflows'),
          IsSignatureDocLinkView: false,
        };

        await dispatch('systemSettings/getAllSystemSettings', {apiToken: userData.Token, forceFetch: true}, { root: true })
        userData.systemSettings = rootState.systemSettings
        return commit('setUserData', userData)
      }
      commit('setIsSignatureDocLinkView', false)

      const res = await loginUser(rootState.common.apiToken, credentials)
      let userTokenSaved;
      if (res && res.data) {
        if (res.data.Token) {
          const [userHasSchedulers, userHasForms, ] = await Promise.all([
            getUserHasSchedulers(res.data.Token),
            dispatch('getUserHasForms', res.data.Token),
            dispatch('systemSettings/getAllSystemSettings', {apiToken: res.data.Token, forceFetch: true}, { root: true })
          ])

          const userData = {
            AppAccess: res.data.AppAccess,
            Token: res.data.Token,
            Username: res.data.Username,
            LoginType: res.data.LoginType,
            UserId: res.data.UserId,
            UpdateStorage: true,
            Roles: res.data.Roles.Values.map(r => r.Value.SystemName),
            IsAdmin: res.data.IsAdmin,
            FirstName: res.data.FirstName,
            LastName: res.data.LastName,
            UserHasForms: userHasForms,
            UserHasSchedulers: userHasSchedulers,
            UserCanEditSomeWorkflows: res.data.WorkflowAppAccess,
          };

          const { data } = await needPasswordReset(res.data.Token, { Value: `${credentials.Password}~${res.data.UserId}` })
          await commit('setPasswordResetState', data.Value)
          userData.systemSettings = rootState.systemSettings
          userTokenSaved = await commit('setUserData', userData);
          await commit('setIsMSAApproved', res.data.IsMSAApproved)

          await AmplitudeService.checkAmplitudeAvailability()
          await AmplitudeService.identifyUser(userData.UserId > 0 ? `${window.location.host}:${userData.UserId}`: null)
          await AmplitudeService.trackEvent('Logged In', {
            'loginType': userData.LoginType,
          })
        } else {
          await commit('setLoginError', res.data.ResultMessage)
        }
        return userTokenSaved;
      }
    },
    async signInSignatureDocLinkView({ commit, dispatch, rootState }, token) {
      const res = await axios.post('/v1/auth/AuthWithToken', { Value: `${token}` }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      let userTokenSaved;
      if (res && res.data && res.data.Token) {
        const [userHasSchedulers, userHasForms, ] = await Promise.all([
          getUserHasSchedulers(res.data.Token),
          dispatch('getUserHasForms', res.data.Token),
          dispatch('systemSettings/getAllSystemSettings', {apiToken: res.data.Token, forceFetch: true}, { root: true })
        ])

        const userData = {
          AppAccess: res.data.AppAccess,
          Token: res.data.Token,
          Username: res.data.Username,
          LoginType: res.data.LoginType,
          UserId: res.data.UserId,
          UpdateStorage: true,
          Roles: res.data.Roles.Values.map(r => r.Value.SystemName),
          IsAdmin: res.data.IsAdmin,
          FirstName: res.data.FirstName,
          LastName: res.data.LastName,
          UserHasForms: userHasForms,
          UserHasSchedulers: userHasSchedulers,
          IsSignatureDocLinkView: true,
          UserCanEditSomeWorkflows: res.data.WorkflowAppAccess,
        };

        userData.systemSettings = rootState.systemSettings
        userTokenSaved = await commit('setUserData', userData);
        await commit('setIsMSAApproved', res.data.IsMSAApproved)
      }
      return userTokenSaved;
    },
    async signInWithToken({ commit, dispatch, rootState }, token) {
      const res = await axios.post('/v1/auth/AuthWithToken', { Value: `${token}` }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      let userTokenSaved;
      if (res && res.data && res.data.Token) {
        const [userHasSchedulers, userHasForms, ] = await Promise.all([
          getUserHasSchedulers(res.data.Token),
          dispatch('getUserHasForms', res.data.Token),
          dispatch('systemSettings/getAllSystemSettings', {apiToken: res.data.Token, forceFetch: true}, { root: true })
        ])

        const userData = {
          AppAccess: res.data.AppAccess,
          Token: res.data.Token,
          Username: res.data.Username,
          LoginType: res.data.LoginType,
          UserId: res.data.UserId,
          UpdateStorage: true,
          Roles: res.data.Roles.Values.map(r => r.Value.SystemName),
          IsAdmin: res.data.IsAdmin,
          FirstName: res.data.FirstName,
          LastName: res.data.LastName,
          UserHasForms: userHasForms,
          UserHasSchedulers: userHasSchedulers,
          IsSignatureDocLinkView: false,
          UserCanEditSomeWorkflows: res.data.WorkflowAppAccess,
        };

        userData.systemSettings = rootState.systemSettings
        userTokenSaved = await commit('setUserData', userData);
        await commit('setIsMSAApproved', res.data.IsMSAApproved)

        await AmplitudeService.checkAmplitudeAvailability()
        await AmplitudeService.identifyUser(userData.UserId > 0 ? `${window.location.host}:${userData.UserId}`: null)
        await AmplitudeService.trackEvent('Logged In', {
          'loginType': userData.LoginType,
        })        
      }
      return userTokenSaved;
    },
    async signOut({ commit, state, dispatch, rootState }, isTokenExpired) {
      await axios.post('/v1/auth/LogoutCurrentUser', null, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `X-MVApiAuth ${state.apiToken}`,
        },
      });

      const userData = {
        AppAccess: null,
        Token: '',
        Username: '',
        LoginType: 1,
        UserId: 0,
        Roles: [],
        IsAdmin: false,
        UpdateStorage: true,
        FirstName: '',
        LastName: '',
        UserHasForms: false,
        UserHasSchedulers: false,
        UserCanEditSomeWorkflows: false,
      };
      commit('setIsMSAApproved', false);

      const localToken = localStorage.getItem('UserToken');
      if (localToken && localToken !== '') {
        userData.UpdateStorage = true;
      }
      localStorage.clear()
      sessionStorage.removeItem('userinfo')
      sessionStorage.removeItem('domains')

      await AmplitudeService.trackEvent('Logged Out', {
        'isTokenExpired': isTokenExpired,
      })

      await dispatch('systemSettings/resetStoreData', '', { root: true })
      userData.systemSettings = rootState.systemSettings
      return commit('setUserData', userData);
    },
    async resetPassword({ rootState }, payload) {
      const result = await resetPassword(rootState.common.apiToken, payload)
      return result && result.data && result.data.Value !== undefined ? result.data.Value : 'Error Resetting Password'
    },
    async resetPasswordCheckGuid({ rootState, dispatch }, guid) {
      await dispatch('resetAxiosDefaultBaseURL')
      const result = await resetPasswordCheckGuid(rootState.common.apiToken, guid)
      return result
    },
    async setNewPassword({ rootState }, newPassword) {
      const result = await updatePassword(rootState.common.apiToken, { Value: `${newPassword}` })
      return result && result.data && result.data.Value !== undefined ? result.data.Value : 'Error Resetting Password'
    },
    async setNewPasswordForReset({ dispatch, commit }, newPassword) {
      const Value = await dispatch('setNewPassword', newPassword)
      if (!Value) {
        await commit('setPasswordResetState', false)
      }
      return Value
    },
    suppressDefaultApp({ commit }, value) { return commit('setSuppressDefaultApp', value); },
    userHasAllRoles({ rootState }, roleNameArray) { return allEntriesExist(rootState.common.roles, roleNameArray) },
    userHasAtLeastOneRole({ rootState }, roleNameArray) { return atLeastOneExists(rootState.common.roles, roleNameArray) },
    userHasRole({ rootState }, roleName) { return rootState.common.roles && rootState.common.roles.length > 0 && rootState.common.roles.includes(roleName) },
    // eslint-disable-next-line no-empty-pattern
    validateEmail({}, email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      return re.test(String(email).toLowerCase()) | email === ""
    },
  },
  getters: {
    APP_ACCESS_ADMIN: (state) => { return !state.appAccess || state.appAccess.includes('APP_ADMIN') },
    APP_ACCESS_AUTOMATION: (state) => { return !state.appAccess || state.appAccess.includes('APP_AUTOMATION') },
    APP_ACCESS_CAPTURE: (state) => { return !state.appAccess || state.appAccess.includes('APP_CAPTURE') },
    APP_ACCESS_FORMS: (state) => { return !state.appAccess || state.appAccess.includes('APP_FORMS') },
    APP_ACCESS_REPORTS: (state) => { return !state.appAccess || state.appAccess.includes('APP_REPORTS') },
    APP_ACCESS_SEARCH: (state) => { return !state.appAccess || state.appAccess.includes('APP_SEARCH') },
    APP_ACCESS_SIGNATURE: (state) => { return !state.appAccess || state.appAccess.includes('APP_SIGNATURE') },
    APP_ACCESS_STORAGE: (state) => { return !state.appAccess || state.appAccess.includes('APP_STORAGE') },
    APP_ACCESS_TEMPLATES: (state) => { return !state.appAccess || state.appAccess.includes('APP_TEMPLATES') },
    APP_ACCESS_WORKFLOW: (state) => { return !state.appAccess || state.appAccess.includes('APP_WORKFLOW') },
    APP_ACCESS_WORKSPACE: (state) => { return !state.appAccess || state.appAccess.includes('APP_WORKSPACE') },
    areAnnotationsLocked: (state) => {
      const checkArray = [
        SecurityRoles.AnnotationLock,
      ]
      return allEntriesExist(state.roles, checkArray)
    },
    canAccessAdmin: (state, getters) => {
      return getters.canAccessAdminCapture
        || getters.canAccessAdminProcessing
        || getters.canAccessAdminSearch
        || getters.canAccessAdminSecurity
        || getters.canConfigureFolders
    },
    canAccessAdminApp_RolesOnly: (state, getters) => { return getters.canAccessAdmin },
    canAccessAdminCapture: (state, getters) => {
      if (getters.isAdminUser === true) {
        return true
      }

      const checkArray = [
        SecurityRoles.AdminConfigureDocumentAttributes,
        SecurityRoles.AdminConfigureIndexForm,
        SecurityRoles.AdminConfigureAIP,
        SecurityRoles.AdminGlobalIndexEdit,
      ]

      return atLeastOneExists(state.roles, checkArray)
    },
    canAccessAdminProcessing: (state, getters) => {
      if (getters.isAdminUser === true) {
        return true
      }

      const checkArray = [
        SecurityRoles.AdminConfigureEmailTemplates,
        SecurityRoles.AdminConfigureSchedulers,
      ]

      return atLeastOneExists(state.roles, checkArray)
    },
    canAccessAdminSearch: (state, getters) => {
      if (getters.isAdminUser === true) {
        return true
      }

      const checkArray = [
        SecurityRoles.AdminConfigureSearch,
        SecurityRoles.RefreshFullTextSearch,
      ]

      return atLeastOneExists(state.roles, checkArray)
    },
    canAccessAdminSecurity: (state, getters) => {
      if (getters.isAdminUser === true) {
        return true
      }

      const checkArray = [
        SecurityRoles.AdminConfigureRoles,
        SecurityRoles.AdminConfigureGroups,
        SecurityRoles.AdminConfigureUsers,
      ]

      return atLeastOneExists(state.roles, checkArray)
    },
    canAccessAutomationApp_RolesOnly: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.AdminConfigureSchedulers) },
    canAccessCaptureApp_RolesOnly: (state, getters) => {
      if (getters.isAdminUser === true) {
        return true
      }

      const checkArray = [
        SecurityRoles.DocumentScan,
        SecurityRoles.ConfigureCaptureProfile,
        SecurityRoles.AdminConfigureAIP,
        SecurityRoles.AdminBarcodeIndexing,
      ]
      return atLeastOneExists(state.roles, checkArray)
    },
    canAccessCustomReports: (state, getters) => {
      if (getters.isAdminUser === true) {
        return true
      }

      const checkArray = [
        SecurityRoles.ReportingDocuments,
        SecurityRoles.AdminConfigureCustomReports,
        SecurityRoles.ReportingRunCustom,
      ]
      return atLeastOneExists(state.roles, checkArray)
    },
    canAccessDigitalSignature_RolesOnly: (state, getters) => {
      if (getters.isAdminUser === true) {
        return true
      }

      const checkArray = [
        SecurityRoles.SignatureAppConfigure,
        SecurityRoles.SignatureAppUser,
      ]

      return atLeastOneExists(state.roles, checkArray)
    },
    canAccessFormsApp_RolesOnly: (state, getters) => {
      // TODO: For now, we're only checking if the User can configure a Form or
      // I'm not sure if this is all that will be included in the forms app but access Admin Integration (which is the current getter for the Admin - Configure Lookups Role) has been added.
      return getters.canConfigureAttributeForms || getters.checkAdminAndRole(SecurityRoles.AdminConfigureLookups)
    },
    canAccessGroupConfiguration: (state, getters) => {
      if (getters.isAdminUser === true) {
        return true
      }

      const checkArray = [
        SecurityRoles.AdminConfigureRoles,
        SecurityRoles.AdminConfigureGroups,
      ]

      return atLeastOneExists(state.roles, checkArray)
    },
    canAccessMaxxSafe: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.MaxxSafeAccess) },
    canAccessReports: (state, getters) => {
      if (getters.isAdminUser === true) {
        return true
      }

      const checkArray = [
        SecurityRoles.AdminAudit,
        SecurityRoles.AdminRunWorkflowReport,
        SecurityRoles.ReportingDocuments,
        SecurityRoles.ReportingUsers,
        SecurityRoles.ReportingWorkflow,
        SecurityRoles.ReportingRunCustom,
      ]

      return atLeastOneExists(state.roles, checkArray)
    },
    canAccessReportsApp_RolesOnly: (state, getters) => {
      if (getters.isAdminUser === true) {
        return true
      }

      return getters.canAccessReports
        || getters.canAccessScheduledReports
    },
    canAccessScheduledReports: (state, getters) => {
      if (getters.isAdminUser === true) {
        return true
      }

      const checkArray = [
        SecurityRoles.AdminConfigureCustomReports,
        SecurityRoles.AdminConfigureSchedulers,
      ]

      return atLeastOneExists(state.roles, checkArray)
    },
    canAccessSearchSetup: (state, getters) => {
      if (getters.isAdminUser === true) {
        return true
      }

      const checkArray = [
        SecurityRoles.AdminConfigureSearch,
        SecurityRoles.RefreshFullTextSearch,
      ]

      return atLeastOneExists(state.roles, checkArray)
    },
    canAccessTemplatesApp_RolesOnly: (state, getters) => {
      if (getters.isAdminUser === true) {
        return true
      }

      const checkArray = [
        SecurityRoles.AdminConfigureEmailTemplates,
      ]
      return atLeastOneExists(state.roles, checkArray)
    },
    canAccessThumbnailManager: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentThumbnailManager) },
    canAccessUserConfiguration: (state, getters) => {
      if (getters.isAdminUser === true) {
        return true
      }

      const checkArray = [
        SecurityRoles.AdminConfigureRoles,
        SecurityRoles.AdminConfigureUsers,
      ]

      return atLeastOneExists(state.roles, checkArray)
    },
    canAccessVaOutputMgmt_RolesOnly: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.VaAdminOutputMgmt) },
    canAccessVaPrint_RolesOnly: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.VaAdminPrint) },
    canAccessVaRules_RolesOnly: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.VaAdminRules) },
    canAddAnnotation: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.AnnotationAdd) },
    canAddEditDocumentNotes: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentNoteAddEdit) },
    canAssignToWorkflow: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentSendToWorkflow) },
    canBurnAnnotations: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.AnnotationBurn) },
    canConfigureAIP: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.AdminConfigureAIP) },
    canConfigureAttributeForms: (state, getters) => {
      if (getters.isAdminUser === true) {
        return true
      }

      const checkArray = [
        SecurityRoles.AdminConfigureDocumentAttributes,
        SecurityRoles.AdminConfigureIndexForm,
      ]

      return atLeastOneExists(state.roles, checkArray)
    },
    canConfigurBarcodeIndexing: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.AdminBarcodeIndexing) },
    canConfigureCaptureProfiles: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.ConfigureCaptureProfile) },
    canConfigureEmailTemplates: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.AdminConfigureEmailTemplates) },
    canConfigureFolders: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.AdminConfigureFolders) },
    canConfigureGroups: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.AdminConfigureGroups) },
    canConfigureLookups: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.AdminConfigureLookups) },
    canConfigureReports: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.AdminConfigureCustomReports) },
    canConfigureScheduler: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.AdminConfigureSchedulers) },
    canConfigureSearches: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.AdminConfigureSearch) },
    canConfigurSecurityRoles: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.AdminConfigureRoles) },
    canConfigureUsers: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.AdminConfigureUsers) },
    canBatesLabelDocument: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentBatesLabel) },
    canCopyDocument: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentCopy) },
    canCopyFolderStructure: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.SubFolderCopyStructure) },
    canCreateSignatureDocument: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.SignatureAppConfigure) },
    canCreateSubfolder: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.SubfolderCreate) },
    canDeleteDocument: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentDelete) },
    canDeleteDocumentNotes: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentDeleteNote) },
    canDeletePages: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentDeletePages) },
    canDownloadDocument: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentDownload) },
    canEditDocumentFieldValues: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentAssignIndexForm) },
    canEditDocumentLineItem: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentEditLineItem) },
    canEmailDocument: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentEmail) },
    canExportDocumentHistory: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentHistoryExport) },
    canGeneratePDF: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.GeneratePDF) },
    canLegalHold: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentLegalHold) },
    canMergeDocuments: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentMerge) },
    canMoveDocument: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentMove) },
    canOCRDocuments: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentOCR) },
    canPerformDigitalSignature: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DigitalSignature) },
    canPrintDocument: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentPrint) },
    canRecycleDocument: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentDeleteToRecycle) },
    canRefreshFullTextSearch: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.RefreshFullTextSearch) },
    canRenameDocument: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentRename) },
    canRenameSubFolders: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.SubfolderRename) },
    canRunAudit: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.AdminAudit) },
    canRunBulkUpdate: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.CanRunBulkUpdate) },
    canRunCustomReport: (state, getters) => {
      if (getters.isAdminUser === true) {
        return true
      }

      const checkArray = [
        SecurityRoles.ReportingDocuments,
        SecurityRoles.ReportingRunCustom,
        SecurityRoles.AdminConfigureCustomReports,
      ]

      return atLeastOneExists(state.roles, checkArray)
    },
    canRunDocumentReport: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.ReportingDocuments) },
    canRunGlobalIndexEdit: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.AdminGlobalIndexEdit) },
    canRunScheduler: () => { return true },
    canRunUserReport: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.ReportingUsers) },
    canRunWorkflowReport: (state, getters) => {
      if (getters.isAdminUser === true) {
        return true
      }

      const checkArray = [
        SecurityRoles.AdminRunWorkflowReport,
        SecurityRoles.ReportingWorkflow,
      ]

      return atLeastOneExists(state.roles, checkArray)
    },
    canSplitDocument: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentSplit) },
    canSubmitDocumentPackages: (state, getters) => {return getters.checkAdminAndRole(SecurityRoles.SubmitDocumentPackages)},
    canUploadDocument: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentUpload) },
    canUploadNewVersionDocument: (state, getters) => {
      if (getters.isAdminUser === true) {
        return true
      }

      const checkArray = [
        SecurityRoles.DocumentUpload,
        SecurityRoles.VersionSaveNewVersion,
      ]

      return allEntriesExist(state.roles, checkArray)
    },
    canViewDocumentFieldValidation: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentIndexValidation) },
    canViewDocumentHistory: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentHistoryView) },
    canViewDocumentNotes: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentViewNotes) },
    canViewDocumentPackages: (state, getters) => {return getters.checkAdminAndRole(SecurityRoles.ViewDocumentPackages)},
    canViewDocumentVersions: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentVersionHistory) },
    canViewHiddenFieldsWithoutValue: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentViewHiddenFieldsWithoutValue) },
    canViewHiddenFieldsWithValue: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentViewHiddenFieldsWithValue) },
    canViewLinkedDocuments: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.DocumentLinkedDocAccess) },
    checkAdminAndRole: (state, getters) => (roleName) => {
      if (getters.isAdminUser === true) {
        return true
      }

      const checkArray = [
        roleName,
      ]

      return allEntriesExist(state.roles, checkArray)
    },
    isAdminUser: (state) => {
      if (state.userIsAdmin === true) {
        return true
      }

      const checkArray = [
        SecurityRoles.SystemAdmin,
      ]

      return allEntriesExist(state.roles, checkArray)
    },
    isFormAdminUser: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.AdminConfigureForm) },
    isWorkflowAdminUser: (state, getters) => { return getters.checkAdminAndRole(SecurityRoles.AdminConfigureWorkflow) },
    showAdminApp: (state, getters) => {
      if (getters.APP_ACCESS_ADMIN === false) {
        return false
      }

      return getters.canAccessAdminApp_RolesOnly
    },
    showCaptureApp: (state, getters) => {
      if (getters.APP_ACCESS_CAPTURE === false) {
        return false
      }

      return getters.canAccessCaptureApp_RolesOnly
    },
    showReportsApp: (state, getters) => {
      if (getters.APP_ACCESS_REPORTS === false) {
        return false
      }

      return getters.canAccessReportsApp_RolesOnly
    },
    showSignatureApp: (state, getters) => {
      if (getters.APP_ACCESS_SIGNATURE === false) {
        return false
      }

      return getters.canAccessDigitalSignature_RolesOnly
    },
    showStorageApp: (state, getters) => { return getters.APP_ACCESS_STORAGE },
    showTemplatesApp: (state, getters) => {
      if (getters.APP_ACCESS_TEMPLATES === false) {
        return false
      }

      return getters.canAccessTemplatesApp_RolesOnly
    },
    showWorkflowApp: (state, getters) => {
      return getters.APP_ACCESS_WORKFLOW && getters.isWorkflowAdminUser || state.userCanEditSomeWorkflows
    },
    showWorkspaceApp: (state, getters) => { return getters.APP_ACCESS_WORKSPACE },
    canDocumentTerminateWorkflow: (state, getters) => {return getters.checkAdminAndRole(SecurityRoles.DocumentTerminateWorkflow) },
  },
};

export default common;
