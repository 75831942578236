const defaultIgnoreItems = [
  {
    key: 'api',
    ignore: false,
    components: [
      { key: 'key', ignore: false },
      { key: 'tags', ignore: true },
      { key: 'properties', ignore: true },
    ],
  },
  { key: 'conditional', ignore: false },
  { key: 'logic', ignore: true },
  { key: 'layout', ignore: true },
  { key: 'hidden', ignore: true },
  {
    key: 'display',
    ignore: false,
    components: [
      { key: "vasionFieldName", type: "textfield", label: "Vasion Field Name", disabled: true, isRequired: false, tooltip: "The Vasion Field this form field is mapped to.", },
      { key: 'prefix', ignore: true },
      { key: 'suffix', ignore: true },
      { key: 'widget.type', ignore: true },
      { key: 'widget', ignore: true },
      { key: 'allowMultipleMasks', ignore: true },
      { key: 'inputMasks', ignore: true },
      { key: 'customClass', ignore: false },
      { key: 'hidden', ignore: true },
      { key: 'hideLabel', ignore: true },
      { key: 'showWordCount', ignore: true },
      { key: 'showCharCount', ignore: true },
      { key: 'mask', ignore: true },
      { key: 'autofocus', ignore: true },
      { key: 'spellcheck', ignore: true },
      { key: 'dataGridLabel', ignore: true },
      { key: 'tableView', ignore: true },
      { key: 'modalEdit', ignore: true },
    ],
  },
  {
    key: 'data',
    ignore: false,
    components: [
      { key: 'persistent', ignore: true },
      { key: 'protected', ignore: true },
      { key: 'dbIndex', ignore: true },
      { key: 'encrypted', ignore: true },
      { key: 'redrawOn', ignore: true },
      { key: 'clearOnHide', ignore: true },
      { key: 'customDefaultValuePanel', ignore: true },
      { key: 'allowCalculateOverride', ignore: true },
      { key: 'currency', ignore: false },
      { key: 'multiple', ignore: false },
    ],
  },
]

const addressIgnoreItems = [
  {
    key: 'api',
    ignore: false,
    components: [
      { key: 'key', ignore: false },
      { key: 'tags', ignore: true },
      { key: 'properties', ignore: true },
    ],
  },
  { key: 'conditional', ignore: false },
  { key: 'logic', ignore: true },
  { key: 'layout', ignore: true },
  {
    key: 'display',
    ignore: false,
    components: [
      { key: "vasionFieldName", type: "textfield", label: "Vasion Field Name", disabled: true, isRequired: false, tooltip: "The Vasion Field this form field is mapped to.", },
      { key: 'prefix', ignore: true },
      { key: 'suffix', ignore: true },
      { key: 'widget.type', ignore: true },
      { key: 'widget', ignore: true },
      { key: 'inputMask', ignore: false },
      { key: 'allowMultipleMasks', ignore: true },
      { key: 'inputMasks', ignore: true },
      { key: 'customClass', ignore: false },
      { key: 'hidden', ignore: true },
      { key: 'hideLabel', ignore: true },
      { key: 'showWordCount', ignore: true },
      { key: 'showCharCount', ignore: true },
      { key: 'mask', ignore: true },
      { key: 'autofocus', ignore: true },
      { key: 'spellcheck', ignore: true },
      { key: 'dataGridLabel', ignore: true },
      { key: 'tableView', ignore: true },
      { key: 'modalEdit', ignore: true },
    ],
  },
  {
    key: 'data',
    ignore: false,
    components: [
      { key: 'persistent', ignore: false },
      { key: 'protected', ignore: true },
      { key: 'dbIndex', ignore: true },
      { key: 'encrypted', ignore: true },
      { key: 'redrawOn', ignore: true },
      { key: 'clearOnHide', ignore: true },
      { key: 'customDefaultValuePanel', ignore: true },
      { key: 'allowCalculateOverride', ignore: true },
    ],
  },
]

const fileIgnoreItems = [
  {
    key: 'file',
    components: [
      {
        key: 'storage',
        disabled: true,
        defaultValue: 'base64',
      },
      {
        key: 'dir',
        ignore: true,
      },
    ],
  },
  {
    key: 'data',
    ignore: true,
  }
]

const builderConfig = {
  noNewEdit: true,
  noDefaultSubmitButton: true,
  builder:
  {
  basic: false,
  advanced: false,
  premium: false,
  data: false,
  pdf: {
    title: 'PDF FIELDS',
    weight: 20,
    default: false,
    components: {
      file: true,
    },
  },
  customBasic: {
      title: 'BASIC',
      default: false,
      weight: 30,
      components: {
          textfield: true,
          textarea: true,
          number: true,
          checkbox: true,
          selectboxes: true,
          radio: true,
          select: true,
          password: true,
      },
  },
  customAdvanced: {
      title: 'ADVANCED',
      default: false,
      weight: 40,
      components: {
          htmlelement: true,
          email: true,
          url: true,
          datetime: true,
          day: true,
          time: true,
          currency: true,
          file: true,
          datagrid: true,
          columns: true,
          content: true,
          address: true
      },
  },
  custom: {
    title: 'OBJECT FIELDS',
    weight: 60,
    default: true,
    components: {},
  },
  layout: false,
  },
  editForm: {
    textfield: [...defaultIgnoreItems],
    textarea: [...defaultIgnoreItems],
    number: [...defaultIgnoreItems],
    checkbox: [...defaultIgnoreItems],
    selectboxes: [...defaultIgnoreItems],
    select: [...defaultIgnoreItems],
    radio: [...defaultIgnoreItems],
    email: [...defaultIgnoreItems],
    url: [...defaultIgnoreItems],
    datetime: [...defaultIgnoreItems],
    day: [...defaultIgnoreItems],
    time: [...defaultIgnoreItems],
    currency: [...defaultIgnoreItems],
    signature: [...defaultIgnoreItems],
    address: [...addressIgnoreItems],
    file: [...fileIgnoreItems],
  },
}

export {
  builderConfig,
  defaultIgnoreItems,
}
