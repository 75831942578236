<template>
  <div class="vasion-white-background main-div">
    <Loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vasion-page-title top-div">
      {{ title }}
    </div>
    <div id="main-section">
      <div id="storage-options">
        <div class="row">
          <span class="section-title">
            BROWSE
          </span>
        </div>
        <ul class="browse-drive-ul vasion-ul">
          <li
            v-for="driveType in driveTypesFiltered"
            :id="driveType.storageType + '-' + driveType.storageConfigId"
            :key="driveType.storageConfigId"
            :name="driveType.storageType + '-' + driveType.storageConfigId"
            class="vasion-drive-button"
            :class="[selectedDriveType.storageConfigId === driveType.storageConfigId ? 'active' : '']"
            @click="switchSelectedDrive(driveType)"
          >
            <VasionGoogleIcon v-if="driveType.storageType === 'GoogleDrive'" />
            <VasionOneDriveIcon v-else-if="driveType.storageType === 'OneDrive'" />
            <VasionBoxIcon v-else-if="driveType.storageType === 'Box'" />
            <VasionWorkDocsIcon v-else-if="driveType.storageType === 'WorkDocs'" />
            <VasionS3Icon v-else-if="driveType.storageType === 'S3'" />
            <VasionWasabiIcon v-else-if="driveType.storageType === 'Wasabi'" />
            <VasionSharePointIcon v-else-if="driveType.storageType === 'SharePoint'" />
            <VasionFolderSparkIcon v-else />
            {{ driveType.storageDisplayName }}
          </li>
        </ul>
      </div>

      <div id="select-folder-section">
        <table>
          <tr>
            <td class="search-folders-header">
              <VasionInput
                id="txt-folder-search"
                v-model="folderSearchText"
                v-debounce:300ms="runFolderSearch"
                name="txt-folder-search"
                placeholder="Search Folders..."
                inputType="search-gray"
                :isSearch="true"
              />
            </td>
          </tr>
          <tr v-show="!displaySearchResults">
            <td class="folder-container">
              <div class="folder-selection-parent-div">
                <VasionButton
                  v-show="!parentFolderIDIsProvider"
                  id="up-folder-button"
                  class="header-image-button"
                  name="up-folder-button"
                  title="Back"
                  :icon="'VasionChevronLeft'"
                  @vasionButtonClicked="upFolder"
                />
                <label class="vasion-dark-label">
                  {{ displayName }}
                </label>
              </div>
              <ul class="browse-folder-ul vasion-ul">
                <li
                  v-for="(folder, index) in folderList"
                  :id="`folderItem:${folder.FolderId}`"
                  :key="index"
                  class="vasion-folder-browse-button"
                  :class="[selectedFolderID === folder.FolderId ? 'active' : '']"
                  :item="folder"
                >
                  <div class="md-layout">
                    <div class="folder-display-div" @click="selectFolder(folder.FolderId, folder.Name)">
                      <VasionFolderIcon />{{ folder.Name }}
                    </div>
                    <div v-if="folder.HasChildren" @click="navigateDown(folder.FolderId, folder.Name)">
                      <VasionKeyboardArrowRight class="go-forward-arrow" />
                    </div>
                  </div>
                </li>
              </ul>
            </td>
          </tr>
          <tr v-show="displaySearchResults">
            <td class="folder-container">
              <div class="folder-selection-parent-div">
                <label class="vasion-dark-label">
                  Search Results
                </label>
              </div>
              <ul class="browse-folder-ul vasion-ul">
                <li
                  v-for="(folder, index) in folderSearchResults"
                  :id="`searchFolderItem:${folder.iID}`"
                  :key="index"
                  class="vasion-folder-browse-button"
                  :class="[selectedFolderID === folder.iID ? 'active' : '']"
                  :item="folder"
                >
                  <div class="md-layout">
                    <div class="folder-display-div" @click="selectFolder(folder.iID, folder.sName)">
                      <VasionFolderIcon />{{ folder.sName }}
                    </div>
                  </div>
                </li>
              </ul>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="bottom-div">
      <VasionCheckbox
        v-if="customCheckBoxText !== ''"
        id="chk-custom-data"
        name="chk-custom-data"
        :checked="customCheckBoxChecked"
        @change="toggleCustomCheckbox"
      >
        {{ customCheckBoxText }}
      </VasionCheckbox>
      {{ errorText }}
      <VasionButton id="button-cancel" :classProp="'secondary'" @vasionButtonClicked="cancelClick()">
        {{ cancelButtonText }}
      </VasionButton>
      <VasionButton id="button-move" :classProp="'primary'" @vasionButtonClicked="okClick()">
        {{ okButtonText }}
      </VasionButton>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'VasionFolderSelect',
  components: {
    Loading,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    okButtonText: {
      type: String,
      required: true,
    },
    cancelButtonText: {
      type: String,
      required: false,
      default: 'Cancel',
    },
    showOnlyDataAutomation: {
      type: Boolean,
      required: false,
      default: false,
    },
    customCheckBoxChecked: {
      type: Boolean,
      required: false,
      default: false,
    },
    customCheckBoxText: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: function () {
    return {
      driveTypes: [],
      foldersByDriveType: {},
      allFolders: [],
      errorText: '',
      folderList: [],
      folderSearchResults: [],
      folderSearchText: '',
      fullPage: true,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      parentFolderID: 0,
      parentFolderIDHistory: [],
      parentFolderName: '',
      parentFolderNameHistory: [],
      selectedDriveType: {storageConfigId: 0, storageDisplayName: 'Vasion Drive'},
      selectedFolderID: 0,
      selectedFolderName: '',
      vasionFolderList: [],
    }
  },
  computed: {
    displayName() { return !this.parentFolderIDIsProvider ? this.parentFolderName : this.selectedDriveType.storageDisplayName },
    displaySearchResults() { return this.folderSearchText !== '' },
    displayVasionFolder() { return !this.showOnlyDataAutomation },
    driveTypesFiltered() {
      if (!this.displayVasionFolder) {
        return this.driveTypes.filter(driveType => {
          return driveType.storageType != 'Vasion Drive'
        })
      }
      return this.driveTypes
    },
    parentFolderIDIsProvider() { return !(this.parentFolderID > 0 || typeof(this.parentFolderID) === 'string') },
  },
  watch: {
    displaySearchResults: function () {
      this.selectedFolderID = 0
    },
  },
  async created() {
    await this.loadDriveTypes()
    if (this.showOnlyDataAutomation) {
      this.selectedDriveType = this.driveTypes[1] // Don't default to Vasion Drive if it isn't an option
    }
    this.loadFoldersFromParentID()
  },
  methods: {
    cancelClick() {
      this.$emit('cancelButtonClick')
    },
    async toggleCustomCheckbox() {
      this.$emit('toggleCustomCheckbox')
    },
    displayFolderByType() {
      this.folderList = this.foldersByDriveType[this.selectedDriveType.storageConfigId];
    },
    async loadFoldersFromParentID() {
      this.isLoading = true
      if (this.showOnlyDataAutomation){
        this.loadThirdPartyFolders()
      } else {
        this.loadVasionPartyFolders()
      }
      this.isLoading = false
    },
    async loadThirdPartyFolders() {
      this.selectedFolderID = this.parentFolderID
      let payload = {
        StorageConfigId: this.selectedDriveType.storageConfigId === 0 ? null : this.selectedDriveType.storageConfigId,
        ThirdPartyFolderId: this.selectedFolderID === 0 ? null : this.selectedFolderID,
      }
      const response = await this.$store.dispatch('workflow/getThirdPartyFolderList', payload)
      if (response) {
        this.allFolders = response
        this.folderList = this.allFolders
      } else {
        this.folderList = []
      }
    },
    async loadVasionPartyFolders() {
      const payload = {
        parentFolderId: this.parentFolderID,
        enforceUserSecurity: true,
        groupByStorageType: false,
      }
      const response = await this.$store.dispatch('common/getChildFolders', payload)
      if (response?.data && response.data.FolderList) {
        this.allFolders = response.data.FolderList.Values
        this.separateFoldersByType()
        this.displayFolderByType()
      } else {
        this.folderList = []
      }
    },
    async okClick() {
      this.errorText = ''
      if (this.selectedFolderID <= 0) {
        this.errorText = 'Please select a Folder'
        return
      }

      this.isLoading = true
      const payload = {
        SelectedFolderID: this.selectedFolderID,
        SelectedFolderName: this.selectedFolderName,
        SelectedDriveType: this.selectedDriveType,
      }
      this.$emit('okButtonClick', payload)
      this.isLoading = false
    },
    async navigateDown(newParentFolderID, newParentFolderName) {
      if (!this.parentFolderIDIsProvider) {
        this.parentFolderIDHistory.push(this.parentFolderID)
        this.parentFolderNameHistory.push(this.parentFolderName)
      }

      this.parentFolderID = newParentFolderID
      await this.loadFoldersFromParentID()
      this.parentFolderName = newParentFolderName
    },
    async runFolderSearch() {
      if (this.folderSearchText === '') {
        this.folderSearchResults = []
        return
      }
      this.isLoading = true
      const searchPayload = {
        Value: this.folderSearchText,
        FilterByStorageConfig: true,
        StorageConfigId: this.selectedDriveType.storageConfigId,
      }
      this.folderSearchResults = await this.$store.dispatch('vault/vasionFolderSearch', searchPayload)
      this.isLoading = false
    },
    selectFolder(newFolderID, newFolderName) {
      this.selectedFolderID = newFolderID
      this.selectedFolderName = newFolderName
    },
    separateFoldersByType() {
      this.foldersByDriveType = {}
      const storageConfigs = new Set(this.allFolders.map(folder => folder.ThirdPartyStorageConfigId))
      storageConfigs.forEach(storageConfigId => {
        this.foldersByDriveType[storageConfigId] = this.allFolders.filter(folder => folder.ThirdPartyStorageConfigId === storageConfigId)
      });
    },
    switchSelectedDrive(newDriveType) {
      this.selectedDriveType = newDriveType
      this.folderSearchText = ''
      this.selectedFolderID = 0
      this.parentFolderID = 0
      this.parentFolderName = ''
      this.parentFolderIDHistory = []
      this.parentFolderNameHistory = []
      this.displayFolderByType()
      this.loadFoldersFromParentID()
    },
    async upFolder() {
      let newName = ''
      if (this.parentFolderIDHistory.length > 0) {
        this.parentFolderID = this.parentFolderIDHistory.pop()
        newName = this.parentFolderNameHistory.pop()
      } else {
        this.parentFolderID = 0
        newName = ''
      }

      await this.loadFoldersFromParentID()
      this.parentFolderName = newName
    },
    async loadDriveTypes() {
      this.driveTypes = await this.$store.dispatch('storage/getConfiguredDriveTypes', { showOnlyDataAutomation: this.showOnlyDataAutomation })
    },
  },
}
</script>

<style lang="scss" scoped>

  @import '@/assets/css/variables.scss';

  .folder-display-div {
    width: 500px;
  }

  .arrow-display-div {
    border: solid;
  }

  .go-forward-arrow {
    fill: black;
  }

  .main-div {
    width: 890px;
    height: 500px;
    margin: 24px 24px 24px 24px;
    border: none;
  }

  .top-div {
    height: 50px;
  }

 .bottom-div {
    width: 100%;
    vertical-align: middle;
    line-height: 50px;
    text-align: right;
  }

  #main-section {
    width: 100%;
    height: 420px;

    #storage-options {
      width: 300px;
      float: left;

      .row {
        width: 100%;

        .section-title {
          @include SECTION-HEADER;
          color: $grey-400;
          display: block;
          margin: 5px 0;
        }
      }
    }

    #select-folder-section {
      width: 580px;
      height: 410px;
      border-radius: 4px;
      border: solid 1px $grey-100;
      display: flex;
    }
  }

  li.vasion-drive-button {
    padding-left: 10px;
    margin-top: 13px;
    margin-bottom: 13px;
  }

  li.vasion-folder-browse-button {
    width: 100%;
  }

  .search-folders-header {
    width: 100%;
    padding: 10px 10px 10px 10px;
  }

  .folder-container {
    width: 100%;
    height: 345px;
  }

  .folder-selection-parent-div {
    padding-left: 10px;
    height: 40px;
    line-height: 35px;
  }

  table {
    width: 100%;
  }

  .browse-folder-ul {
    margin-top: 0px;
    height: 295px;
    width: 100%;
    overflow: auto;
  }

  .vasion-checkbox {
    float: left;
  }

  .browse-drive-ul {
    height: 388px;
    overflow: auto;
  }

</style>
