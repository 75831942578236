<template>
  <div
    v-if="showSnackbar"
    id="snackbar"
    class="snackbar"
    :style="determinePadding"
    :class="{ fadeInAndOut: !closeOnClick, fadeInOnly: closeOnClick}"
  >
    <table>
      <tr>
        <td rowspan="2" class="snackbar-img">
          <VasionCircleCheck v-if="snackbarImage" />
          <VasionCircleError v-else />
        </td>
        <td>
          <span class="title"> {{ snackbarTitle }} </span>
        </td>
      </tr>
      <tr>
        <div class="sub-title">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-if="showRawHtml" class="sub-title-with-html" v-html="snackbarSubTitle" />
          <span v-else>{{ snackbarSubTitle }}</span>
        </div>
      </tr>
    </table>
    <VasionButton
      v-if="closeOnClick"
      id="button-close"
      :icon="'VasionCloseIcon'"
      title="Close"
      @vasionButtonClicked="closeSnackbar()"
    />
  </div>
</template>

<script>
export default {
  name: 'VasionSnackbar',
  props: {
    showRawHtml: {
      type: Boolean,
      default: false,
      required: false,
    },
    showSnackbarBool: {
      type: Boolean,
      default: false,
      required: false,
    },
    snackbarImage: {
      type: Boolean,
      default: false,
      required: false,
    },
    snackbarSubTitle: {
      type: String,
      default: '',
      required: false,
    },
    snackbarTitle: {
      type: String,
      default: '',
      required: false,
    },
  },
  data: function () {
    return {
      showSnackbar: false,
    }
  },
  computed: {
    determinePadding() {
      return {
        padding: `10px ${(this.closeOnClick) ? '50px' : '25px'} 10px 25px`,
      }
    },
    closeOnClick() {
      return !this.snackbarImage
    }
  },
  watch: {
    showSnackbarBool: function () {
      if (this.showSnackbarBool) {
        this.showSnackbar = true
        if (!this.closeOnClick) {
          setTimeout(() => {
            this.showSnackbar = false
            this.$emit('update:showSnackbarBool', this.showSnackbar)
          }, this.closeOnClick ? Infinity : 5000);
        }
      }
    },
  },
  methods: {
    closeSnackbar() {
      this.showSnackbar = false
      this.$emit('update:showSnackbarBool', this.showSnackbar)
    },
  },
}

</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';

  .snackbar {
    max-width: 95vw;
    max-height: 150px;
    overflow-y: auto;
    visibility: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 $box-shadow;
    background-color: $inverse-surface;
    text-align: left;
    z-index: 1100;
    position: fixed;
    left: 50%;
    bottom: 30px;
    transform: translate(-50%);
    color: $inverse-on-surface;
    fill: $inverse-on-surface;
  }

  @media(max-width: $phone){
    .snackbar{
      width: 95vw;
    }
  }

  #button-close {
    position: absolute;
    top: 0;
    right: 0px;
  }
  .snackbar-img {
    padding: 0px 20px 0px 0px;
  }
  .fadeInAndOut {
    visibility: visible;
     -webkit-animation: fadein 1.5s,  fadeout 1.5s 4s;
    animation: fadein 1.5s, fadeout 1.5s 4s;
  }
  .fadeInOnly {
    visibility: visible;
    -webkit-animation: fadein 1.5s;
    animation: fadein 1.5s;
  }
  .title {
    vertical-align: middle;
    font-family: $font-family-book;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $inverse-on-surface;
  }
  .sub-title {
    padding-top: 4px;
    vertical-align: middle;
    font-size: 14px;
    color: $inverse-on-surface;
    white-space: pre-wrap;
  }

  .sub-title-with-html {
    white-space: nowrap;
  }

  @-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0; visibility: visible;}
    to {bottom: 30px; opacity: 1;}
  }

  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }
  @-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }

  @keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }

</style>
