// eslint-disable-next-line consistent-return
const buildBarcodeGridData = (response) => {
  if (response && response.BIList) {
    const headers = ['Config Name', 'Object Name'] // $formsLabel doesn't work here, returns undefined

    const rows = response.BIList.map((a) => {
      return {
        ValueType: 'String',
        Id: a.ID,
        Values: [a.ConfigName, a.IndexFormName],
      }
    })

    return {
      tableHeaders: headers,
      tableRows: rows,
    }
  }
}

// eslint-disable-next-line consistent-return
const buildEmptyConfigFromBarcode = (response) => {
  if (response.Fields) {
    const returnArr = []
    const newValues = response.Fields.Values
    // eslint-disable-next-line array-callback-return
    newValues.map((a) => {
      const item = {
        IndexFieldID: a.FieldID,
        IndexFieldTitle: a.DisplayName,
        PageNumber: null,
        BarcodeNumber: null,
        From: null,
        To: null,
      }
      returnArr.push(item)
    })
    return returnArr
  }
}

export {
  buildBarcodeGridData,
  buildEmptyConfigFromBarcode,
}
