<template>
  <div
    class="start-workflow-manager-container"
    :class="{'tall-modal': uiType === 'formLayout' || uiType === 'attributeForm'}"
  >
    <VasionSelectWorkflow
      v-show="uiType === 'wfSelection'"
      :workflowList="workflowList"
      @cancelButtonClick="closeStartWorkflow"
      @continueButtonClick="workflowSelected"
    />

    <StartWorkflowForm
      v-if="uiType === 'formLayout'"
      :formLayout="selectedFormLayout"
      :workflowID="selectedWorkflowID"
      :fromFormLayout="true"
      :loadDraftId="loadDraftId"
      :loadFormId="loadFormId"
      @backButtonClick="backToWorkflowSelect"
      @cancelButtonClick="closeStartWorkflow"
      @workflowStarted="showWorkflowStarted"
      @eFormSubmitted="workflowStarted"
      @eFormSubmitFailed="showWorkflowNotStarted"
      @workflowUnsuccessful="showWorkflowNotStarted"
    />

    <StartWorkflowForm
      v-if="uiType === 'eForm' && eForm"
      :formLayout="eForm"
      :workflowID="selectedWorkflowID"
      :fromMyForms="true"
      :loadDraftId="loadDraftId"
      :loadFormId="loadFormId"
      @backButtonClick="backToWorkflowSelect"
      @cancelButtonClick="closeStartWorkflow"
      @workflowStarted="showWorkflowStarted"
      @eFormSubmitted="workflowStarted"
      @eFormSubmitFailed="showWorkflowNotStarted"
      @workflowUnsuccessful="showWorkflowNotStarted"
    />

    <StartWorkflowFileUpload
      v-if="uiType === 'standardWF'"
      @backButtonClick="backToWorkflowSelect"
      @cancelButtonClick="closeStartWorkflow"
      @continueToAttributeForm="continueToAttributeForm"
      @scanToVasionStarted="scanToVasionStarted"
      @workflowUnsuccessful="showWorkflowNotStarted"
      @kickOffWorkflow="kickOffWorkflow"
    />

    <StartWorkflowAttributeForm
      v-if="uiType === 'attributeForm'"
      :workflowID="selectedWorkflowID"
      @backButtonClick="backToWorkflowFileUpload"
      @cancelButtonClick="closeStartWorkflow"
      @kickOffWorkflow="kickOffWorkflow"
      @workflowUnsuccessful="showWorkflowNotStarted"
    />

    <VasionUserGroupSelect
      v-if="uiType === 'selectUser'"
      modalTitle="Select User"
      :userOnly="true"
      :groupFilterId="assignUserGroupFilterId"
      @noButtonClick="closeStartWorkflow"
      @yesButtonClick="assignWorkflowWithSelectUser"
    />
  </div>
</template>
<script>

import StartWorkflowAttributeForm from '@/components/workflow/StartWorkflowAttributeForm.vue';
import StartWorkflowFileUpload from '@/components/workflow/StartWorkflowFileUpload.vue'
import StartWorkflowForm from '@/components/workflow/StartWorkflowForm.vue'

export default {
  name: 'StartWorkflowManager',
  components: {
    StartWorkflowAttributeForm,
    StartWorkflowFileUpload,
    StartWorkflowForm,
  },
  props: {
    viewMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    loadDraftId: {
      type: Number,
      default: 0,
      required: false,
    },
    loadFormId: {
      type: Number,
      default: 0,
      required: false,
    },
    loadWorkflowId: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  data: function () {
    return {
      assignUserGroupFilterId: 0,
      selectedWorkflowID: 0,
      uiType: this.viewMode ? 'formLayout' : 'wfSelection',
      userAssignPayload: {},
      wfInitType: 'Form',
      workflowList: [],
    }
  },
  computed: {
    eForm() { return this.$store.state.forms.eForm },
    isFromMobile() { return this.$route.query.isFromMobile ? this.$route.query.isFromMobile : false },
    selectedDocumentId() { return this.$store.state.workflow.selectedDocumentId },
    selectedFormLayout() { return this.startWorkflowPathData && this.startWorkflowPathData?.maxxForm ? this.startWorkflowPathData.maxxForm : {} },
    startWorkflowPathData() { return this.$store.state.workflow.startWorkflowPathData },
    userId() { return this.$store.state.common.userID },
    workflowDueDate() { return this.$store.state.workflow.workflowDueDate },
  },
  async created() {
    if (this.viewMode && this.loadDraftId > 0)
    {
      this.workflowSelected(this.loadWorkflowId)
      this.selectedWorkflowID = this.loadWorkflowId
    }
    else if (this.viewMode && this.$route.params.workflowID) {
      this.workflowSelected(parseInt(this.$route.params.workflowID, 10))
      this.selectedWorkflowID = parseInt(this.$route.params.workflowID, 10)
    } else {
      this.workflowSelected(0)
    }
    this.resetStoreValues()
    this.$store.dispatch('workflow/resetStartWorkflowSuccess')
    this.workflowList = await this.$store.dispatch('workflow/getWorkflowMenuItems')
    this.$store.dispatch('forms/getEForm', (this.loadFormId > 0) ? this.loadFormId : this.$route.params.formID)

    if (this.uiType === 'wfSelection') this.selectWorkflow()
  },
  methods: {
    async assignWorkflowWithSelectUser(payload) {
      if (!payload || !payload.username || !payload.value) {
        this.closeStartWorkflow()
      }

      this.userAssignPayload.promptData.prmt_ChooseFromGroup.cfg_UserID = payload.value
      const { Result: result } = await this.$store.dispatch('workflow/assignToWorkflowWithData', this.userAssignPayload)
      if (!result) {
        this.showWorkflowNotStarted()
      } else {
        this.setDueDate()
        this.showWorkflowStarted()
      }
    },
    backToWorkflowFileUpload() { this.uiType = 'standardWF' },
    backToWorkflowSelect() { this.workflowSelected(0) },
    closeStartWorkflow() {
      this.resetStoreValues()
      this.$emit('closeStartWorkflow')
    },
    continueToAttributeForm() { this.uiType = 'attributeForm' },
    async kickOffWorkflow() {
      const payload = {
        documentId: this.selectedDocumentId,
        workflowId: this.selectedWorkflowID,
        userId: parseInt(this.userId, 10),
      }

      const responsePayload = await this.$store.dispatch('workflow/attemptAssignToWorkflowWithData', payload)

      if (!responsePayload) {
        this.setDueDate()
        this.wfInitType = 'Browse'
        this.showWorkflowStarted()
      } else {
        this.assignUserGroupFilterId = responsePayload.assignUserGroupFilterId
        this.userAssignPayload = responsePayload.userAssignPayload
        this.uiType = 'selectUser'
      }
    },
    resetStoreValues() {
      this.$store.dispatch('workflow/setWorkflowDueDate', '')
      this.$store.dispatch('workflow/setSelectedDocumentId', null)
    },
    async scanToVasionStarted() {
      const payload = {
        folderId: 0,
        workflowId: this.selectedWorkflowID,
      }
      await this.$store.dispatch('common/openScanToVasion', payload)
      this.closeStartWorkflow()
    },
    async selectWorkflow() {
      await this.$amplitude.trackEvent('Workspace Page Viewed', {
        'page': 'Select Workflow',
        'numItems': this.workflowList.length,
      })
    },
    setDueDate() {
      if (!this.workflowDueDate) {
        return
      }

      const payload = {
          documentID: this.selectedDocumentId,
          workflowID: this.selectedWorkflowID,
          dueDate: this.workflowDueDate,
      }
      this.$store.dispatch('workflow/saveWorkflowDueDate', payload)
    },
    showWorkflowNotStarted() {
      if (this.selectedWorkflowID > 0) {
        this.$emit('workflowFailed')
        this.$store.dispatch('workflow/setStartWorkflowSuccess', 0)
      }
      this.closeStartWorkflow()
    },
    showWorkflowStarted() {
      this.$emit('workflowStarted')
      this.$store.dispatch('workflow/setStartWorkflowSuccess', 1)
      this.workflowStarted()
    },
    async workflowStarted(){
      if (this.$route.fullPath === '/workspace/inbox' && this.loadDraftId === 0) {
        this.$store.dispatch('mainViews/changeMainNav', 'my-tasks-pending')
        this.$store.dispatch('workflow/getWorkflowPendingTasks', 0)
      }
      this.closeStartWorkflow()
      if (this.selectedWorkflowID > 0 && !this.isFromMobile) {
        await this.$amplitude.trackEvent('Workflow Started', {
          'initiationType': this.wfInitType,
        })
      }
    },
    async workflowSelected(workflowID) {
      this.selectedWorkflowID = workflowID
      const payload = {
        Value: workflowID,
      }
      await this.$store.dispatch('workflow/loadStartWorkflowPathData', payload)
      this.wfInitType = 'Form'
      if (this.viewMode) {
        this.uiType = 'eForm'
      } else if (workflowID <= 0) {
        this.uiType = 'wfSelection'
      } else if (!this.startWorkflowPathData?.maxxForm && !this.viewMode) {
        this.uiType = 'standardWF'
      } else {
        this.uiType = 'formLayout'
      }
    },
  },
}
</script>
<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .start-workflow-manager-container {
    z-index: 1;
    height: 100%;
    max-height: 100%;
  }
  
  .tall-modal {
    height: 90vh;
  }
  
  @media(max-width: $phone){
    .tall-modal {
      height: auto;
    }
  }
</style>
