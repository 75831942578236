<template>
  <!-- eslint-disable vue/no-template-shadow -->
  <md-table-row
    slot="md-table-row"
    slot-scope="{ field }"
    class="selectable"
    @click.prevent="toggleSelected(field.name)"
  >
    <md-table-cell v-model="selected">
      <md-checkbox v-model="selected" :disabled="isDisabled">
        <span class="field-name">{{ field.name }}</span>
      </md-checkbox>
    </md-table-cell>
  </md-table-row>
  <!-- eslint-enable vue/no-template-shadow -->
</template>

<script>
export default {
  name: 'AttributeFieldSelectItem',
  props: {
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    field: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: true,
    }
  },
  methods: {
    toggleSelected: function (name) {
      this.selected = !this.selected
      this.$emit('fieldclicked', name)
    },
  },
}
</script>

<style lang="scss" scoped>
.selectable {
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #657786;
  background-color: #fff;
  margin: 2px;

  td {
    height: 18px;
    font-size: 17px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    margin: 2px;
  }
}

</style>
