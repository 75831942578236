<template>
  <div id="toolbar-zoom" class="toolbar-height">
    <!-- <div class="toolbar-divider" /> -->
    <VasionButton
      id="btnZoomIn"
      class="toolbar-icon"
      name="btnZoomIn"
      title="Zoom In"
      :icon="'VasionZoomInIcon'"
      :isDisabled="disableZoomIn"
      @vasionButtonClicked="zoomIn()"
    />
    <VasionButton
      id="btnZoomOut"
      class="toolbar-icon"
      name="btnZoomOut"
      title="Zoom Out"
      :icon="'VasionZoomOutIcon'"
      :isDisabled="disableZoomOut"
      @vasionButtonClicked="zoomOut()"
    />
  </div>
</template>

<script>
export default {
  name: 'VasionZoomTool',
  data: function() {
    return {

    }
  },
  computed: {
    disableZoomIn() {
      return this.zoomValue > 3
    },
    disableZoomOut() {
      return this.zoomValue <= 0.1
    },
    zoomValue() { return this.$store.state.document.zoomValue },
  },
  async created() {
    await this.$store.dispatch('document/setZoomValue', .5)
  },
  methods: {
    zoomIn() {
      if (!this.disableZoomIn) {
        this.$store.dispatch('document/setZoomValue', true)
      }
    },
    zoomOut() {
      if (!this.disableZoomOut) {
        this.$store.dispatch('document/setZoomValue', false)
      }
    },
  },
}
</script>
