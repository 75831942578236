<template>
  <form v-if="searchFields" action="">
    <Loading
      id="loader"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div v-for="(field, index) in searchFields" :key="index" class="space">
      <div v-for="header in objectHeaders" :key="header.name">
        <h2 v-if="header.index === index" class="object-name-header">
          Object Name: {{ header.name }}
        </h2>
      </div>
      
      <AttributeFieldFilterItem
        :key="index"
        :field="searchFieldsForUI[index]"
        :isInDocumentView="true"
        :isInSearch="true"
        :useMultiSelectLists="true"
        @filterupdated="fieldValueUpdated"
      />
    </div>
  </form>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import AttributeFieldFilterItem from '@/components/AttributeFieldFilterItem.vue';

export default {
  name: 'IndexSearch',
  components: {
    Loading,
    AttributeFieldFilterItem,
  },
  props: {
    closeSearchDisplay: {
      type: Function,
      required: true,
    },
    searchID: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      objectHeaders: [],
      searchFields: [],
      searchFieldsForUI: [],
      searchIDLocal: 0,
    };
  },
  async created() {
    this.isLoading = true

    if (!this.$store.state.search.refineSearch) {
      await this.$store.dispatch('search/loadIndexSearchFields', this.searchID)
    }
    this.searchIDLocal = this.$store.state.search.indexSearchFields.SearchId
    // If there are no search fields, this is an automatic search using only static fields, so call the search
    if (this.$store.state.search.indexSearchFields.Fields === null)
    {
      await this.$store.dispatch('search/setSearchCriteria', { SearchId: this.searchIDLocal, Fields: [] })
      this.$store.dispatch('mainViews/toggleSavedSearchDropDown', false)
      // eslint-disable-next-line
      this.$router.push({ name: 'SearchResults' }).catch(err => {})

      return
    }
    
    this.prepareFieldData()

    const objHeaders = []
    let headerCount = 0
    this.searchFields.forEach(field => {
      if (!objHeaders.includes(field.FormName)) {
        objHeaders.push(field.FormName)
        this.objectHeaders.push({ name: field.FormName, index: headerCount })
      }
      headerCount++
    })
    this.isLoading = false
  },
  methods: {
    async clear() {
      this.isLoading = true

      if (!this.searchFields) {
        this.isLoading = false
        return
      }
      await this.$store.dispatch('search/loadIndexSearchFields', this.searchID)
      this.prepareFieldData()

      this.isLoading = false
    },
    prepareFieldData() {
      this.searchFields = this.$store.state.search.indexSearchFields.Fields.Values.map((element) => {
        if (element.DropDownValues && element.DropDownValues.length > 0) {
          element.DropDownValueObjects = element.DropDownValues.map((value, ix) => {
            const retObject = {
              name: value,
              value: ix,
            }

            return retObject
          })
          element.TextValue = element.DropDownSelectedValues ?? ''
        }
        return element
      })
      this.searchFieldsForUI = this.searchFields.map(field => {
        return {
          name: field.DisplayName,
          type: field.Control,
          isDisabled: false,
          readOnly: false,
          mask: field.Mask,
          allowMultiples: true,
          //showCommas: field.ShowCommas,
          //decimalPlaces: field.decimalPlaces,
          currencyFormat: field.CurrencyFormat,
          filterValue: field.TextValue ?? '',
          required: false,
          value: field.FieldName,
          dropdownValues: field.DropDownValueObjects,
          fieldLength: field.FieldLength,
          fieldFormID: field.FormId,
        }
      })
    },
    async saveSearchCriteria() {
      const updatedSearchFields = {
        SearchId: this.searchIDLocal,
        Fields: this.searchFields,
      }
      await this.$store.dispatch('search/setSearchCriteria', updatedSearchFields)
      this.$store.dispatch('mainViews/toggleSavedSearchDropDown', false)
      // eslint-disable-next-line
      this.$router.push({ name: 'SearchResults' }).catch(err => {})
    },
    async fieldValueUpdated(attributeFieldData) {
      if (this.isLoading) return
      //There could be multiple objects with the same field. We currently only list the field once.
      //This would be a problem for Drop down, checkbox, radio fields as only one set of values will be displayed
      //If we modify search to show the fields for each object, then this search logic will need to include
      // some sort of FormId, to ensure we find the correct field
      let fieldData = this.searchFields.find(field => field.FieldName === attributeFieldData.name)
      if (fieldData.DropDownValues && fieldData.DropDownValues.length > 0 && Array.isArray(attributeFieldData.value)) {
        fieldData.SelectedDropDownObject = attributeFieldData.value
        fieldData.DropDownSelectedValues = attributeFieldData.value.map(val => val.name)
        fieldData.TextValue = fieldData.DropDownSelectedValues.join(';')
      } else if (fieldData.Control === 3) {
        fieldData.DateValueUTC = attributeFieldData.value
        fieldData.TextValue = attributeFieldData.value
      } else {
        fieldData.TextValue = attributeFieldData.value
      }
    },
  },
}
</script>

<style lang="scss">
  .object-name-header {
    font-size: 18px;
    font-weight: bold;
  }

  .space {
    margin-top: 15px;
  }

  #loader {
    z-index: 1001;
  }
  .text-area {
    height: 100px;
  }
</style>
