let activeEventFunctions = []
let canvas = null
const defaultSizes = {
  initial: { width: 72, height: 60 },
  signature: { width: 375, height: 60 },
  text: { width: 408, height: 60 },
  timestamp: { width: 248, height: 60 },
  checkbox: { width: 48, height: 48 },
}
let debug = false
let maxHeight = 0
let maxWidth = 0
let selectedAnnProps = {
  left: 0,
  top: 0,
  scaleX: 0,
  scaleY: 0,
}
let subType = ''
let creatingRect = {
  start: { x: 0, y: 0 },
  end: { x: 0, y: 0 }
}
let lastSizes = JSON.parse(JSON.stringify(defaultSizes))

const creatingRectEnd = (event) => {
  const coords = getCursorPosition(event)
  let x = coords.x
  if (x < 0) x = 0
  if (x > maxWidth) x = maxWidth
  let y = coords.y
  if (y < 0) y = 0
  if (y > maxHeight) y = maxHeight
  creatingRect.end = { x, y }
}
const creatingRectStart = (event) => {
  const coords = getCursorPosition(event)
  creatingRect = {
    start: { x: coords.x, y: coords.y },
    end: { x: coords.x, y: coords.y }
  }
}
const fixRectangleSize = (width, height) => {
  if(!Object.keys(defaultSizes).includes(subType))  return { width, height }
  if (width < 10) width = lastSizes[subType].width
  if (height < 10) height = lastSizes[subType].height
  if (width < defaultSizes[subType].width) width = defaultSizes[subType].width
  if (height < defaultSizes[subType].height) height = defaultSizes[subType].height
  lastSizes[subType].width = width
  lastSizes[subType].height = height
  if (subType === 'checkbox') {
    return { width: defaultSizes[subType].width, height: defaultSizes[subType].height }
  }
  return { width, height }
}
const getCreatingRect = () => {
  const left = Math.min(creatingRect.start.x, creatingRect.end.x)
  const right = Math.max(creatingRect.start.x, creatingRect.end.x)
  const top = Math.min(creatingRect.start.y, creatingRect.end.y)
  const bottom = Math.max(creatingRect.start.y, creatingRect.end.y)

  let width = right-left
  let height = bottom-top

  const fiexdSizes = fixRectangleSize(width, height)
  width = fiexdSizes.width
  height = fiexdSizes.height

  if (width < 10) width = 60
  if (height < 10) height = 60

  return {
    left: left,
    top: top,
    width: width,
    height: height,
  }
}
const getCursorPosition = (event) => {
  let pointer = canvas.getPointer(event.e)
  return {x: pointer.x, y: pointer.y}
}
const handleBoundedEvents = () => {
  if (debug) { console.log('cs:handleBoundedEvents', canvas, maxWidth, maxHeight) }
  handleEvent( 'object:moving', onAnnotationMoving );
  handleEvent( 'object:scaling', onAnnotationScaling );
  handleEvent( 'selection:created', onAnnotationSelection );
}
const handleEvent = (eventName, handler) => {
  if (debug) { console.log('cs:handleEvent', eventName, canvas, maxWidth, maxHeight) }
  canvas.on(eventName, handler);
  activeEventFunctions.push(eventName)
}
const initialize = (c, maxW, maxH) => {
  if (debug) { console.log('cs:initialize', c, maxW, maxH) }
  canvas = c
  maxWidth = maxW
  maxHeight = maxH
  lastSizes = JSON.parse(JSON.stringify(defaultSizes))
}
const onAnnotationMoving = (e) => {
  if (debug) { console.log('cs:onAnnotationMoving', canvas, maxWidth, maxHeight) }
  const height = e.target.height * e.target.scaleY
  const width = e.target.width * e.target.scaleX

  // top
  if(e.target.top < 0){
    e.target.top = 0
  }
  // bottom
  if(e.target.top + height > maxHeight){
    e.target.top = maxHeight - height
  }
  // left
  if(e.target.left < 0){
    e.target.left = 0
  }
  // right
  if(e.target.left + width > maxWidth){
    e.target.left = maxWidth - width
  }
}
const onAnnotationScaling = (e) => {
  if (debug) { console.log('cs:onAnnotationScaling', canvas, maxWidth, maxHeight) }
  //top
  if(e.target.top < 0) {
      e.target.top = selectedAnnProps.top = 0
      e.target.scaleY = selectedAnnProps.scaleY
  } else {
    selectedAnnProps.top = e.target.top
    selectedAnnProps.scaleY = e.target.scaleY
  }
  //bottom
  if((e.target.scaleY * e.target.height) + e.target.top > maxHeight) {
    e.target.scaleY = (maxHeight - e.target.top) / e.target.height
  } else {
    selectedAnnProps.scaleY = e.target.scaleY
  }
  //left
  if(e.target.left < 0) {
    e.target.left = selectedAnnProps.left = 0;
    e.target.scaleX = selectedAnnProps.scaleX;
  } else {
    selectedAnnProps.left = e.target.left;
    selectedAnnProps.scaleX = e.target.scaleX;
  }
  //right
  if((e.target.scaleX * e.target.width) + e.target.left > maxWidth) {
    e.target.scaleX = (maxWidth - e.target.left) / e.target.width;
  } else {
    selectedAnnProps.scaleX = e.target.scaleX;
  }
}
const onAnnotationSelection = (e) => {
  e.target.lockMovementX = e.selected.some(x => x.lockMovementX);
  e.target.lockMovementY = e.selected.some(x => x.lockMovementY);
  e.target.lockRotation = e.selected.some(x => x.lockRotation);
  e.target.lockScalingX = e.selected.some(x => x.lockScalingX);
  e.target.lockScalingY = e.selected.some(x => x.lockScalingY);
  e.target.lockScaling = e.selected.some(x => x.lockScaling);
  e.target.lockScalingFlip = e.selected.some(x => x.lockScalingFlip);
  e.target.setControlsVisibility({ mtr: false })
}
const removeEventHandlers = () => {
  activeEventFunctions.forEach(item => canvas.off(item))
  activeEventFunctions = []
}
const setSelectedAnnProps = (annotation) => {
  selectedAnnProps.left = annotation.left
  selectedAnnProps.top = annotation.top
  selectedAnnProps.scaleX = annotation.scaleX
  selectedAnnProps.scaleY = annotation.scaleY
}
const setSubType = (value) => {
  subType = value
}

const canvasService = {
  creatingRectEnd,
  creatingRectStart,
  getCreatingRect,
  handleBoundedEvents,
  handleEvent,
  initialize,
  removeEventHandlers,
  setSelectedAnnProps,
  setSubType,
}

export default canvasService
