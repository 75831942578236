import moment from 'moment'
import lists from '@/views/admin/systemSettings/globalSettingsFieldsList.json'
import store from '@/store/store'

const allEntriesExist = function (searchInArray, searchForArray) {
  if (!searchForArray || searchForArray.length === 0) {
    return true
  }

  if (!searchInArray || searchInArray.length === 0) {
    return false
  }

  let retValue = true
  searchForArray.forEach((role) => {
    if (!searchInArray.includes(role)) {
      retValue = false
    }
  })

  return retValue
}

const atLeastOneExists = function (searchInArray, searchForArray) {
  if (!searchForArray || searchForArray.length === 0) {
    return true
  }

  if (!searchInArray || searchInArray === 0) {
    return false
  }

  let retValue = false
  searchForArray.forEach((role) => {
    if (searchInArray.includes(role)) {
      retValue = true
    }
  })

  return retValue
}

const buildAndNavigateToScanToVasionUrl = async function (actionString) {
  const scanToVasionBaseUrl = process.env.VUE_APP_SCANTOVASION_BASE
  const apiUrl = `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_API}`
  window.open(
    `${scanToVasionBaseUrl}${encodeURIComponent(apiUrl)}~${actionString}`,
    '_blank',
  )
}

const formatCurrency = (value, format) => {
  if (typeof value === 'number') value = value.toString()
  if (isNaN(Number(value)) || !value) return ''

  let isNegative = value[0] === '-'
  if (isNegative) value = value.substring(1)
  let numericFormat = format.match('#.*0')[0]

  // Determine the decimal and thousands separators
  const firstHashIndex = numericFormat.indexOf('#')
  const firstZeroIndex = numericFormat.indexOf('0')

  if (firstZeroIndex == -1) return ''

  let thousandSeparator = '\0' // Default to no separator
  let decimalSeparator = '\0'  // Default to no separator

  if (firstHashIndex != -1 && firstZeroIndex > firstHashIndex + 1) {
      let possibleThousandSeparator = numericFormat.charAt(firstHashIndex + 1)
      if (possibleThousandSeparator != '#' && possibleThousandSeparator != '0') {
          thousandSeparator = possibleThousandSeparator
      }
  }

  if (firstZeroIndex < numericFormat.length - 1)
  {
      let possibleDecimalSeparator = numericFormat.charAt(firstZeroIndex + 1)
      if (possibleDecimalSeparator != '#' && possibleDecimalSeparator != '0') {
          decimalSeparator = possibleDecimalSeparator
      }
  }

  // Ensure proper number of decimal places
  let decimalPlaces = 0;
  if (decimalSeparator != '\0')
  {
      decimalPlaces = numericFormat.split(decimalSeparator).length > 1 ? numericFormat.split(decimalSeparator)[1].length : 0
  }

  // Format the amount, it initially has , for thousands and . for cents
  var valueAsFixed = Number(value).toFixed(decimalPlaces)
  let formattedAmount = (valueAsFixed + '').split('').reverse().join('').replace(/(\d{3})(?=\d)/g, '$1,').split('').reverse().join('')

  if (decimalSeparator != '\0')
  {
      formattedAmount = formattedAmount.replace('.', decimalSeparator)
  }

  //the thousands separator is a , right now, do not replace last one cause comma might be decimal separator
  if (thousandSeparator === '\0')
  {
      formattedAmount = formattedAmount.replace(/(?<=\d),(?=\d{3})/g, '')
  }
  else if (thousandSeparator !== ',')
  {
      formattedAmount = formattedAmount.replace(/(?<=\d),(?=\d{3})/g, thousandSeparator)
  }

  // Replace the numeric part in the format string with the formatted amount
  let result = format.replace(/#.*0/g, formattedAmount)
  if (isNegative) result = "-" + result

  return result;  
}

const formatDateTime = (value, type, format = null) => {
  if (!value) return value
  
  const systemDateFormat = store.state.systemSettings.systemDateFormat
  const systemTimeFormat = store.state.systemSettings.systemTimeFormat
  const systemDateTimeFormat = `${systemDateFormat} ${systemTimeFormat}`

  let defaultFormat = ''
  if (type === 'date') {
    defaultFormat = systemDateFormat || 'MM/DD/YYYY';
  } else if (type === 'time') {
    defaultFormat = systemTimeFormat || 'hh:mm:ss a';
  } else if (type === 'datetime') {
    defaultFormat = systemDateTimeFormat || 'MM/DD/YYYY hh:mm:ss a';
  }

  let parsedDate = null
  let formatToSet = null

  if (type === 'date' && lists.systemDateFormats && lists.systemDateFormats.length > 0) {
    const formatObj = lists.systemDateFormats.find(format => format.value === defaultFormat)
    if (formatObj) {
      defaultFormat = formatObj.momentFormat
    }
  }
  if (type === 'datetime' && lists.systemDateFormats && lists.systemDateFormats.length > 0) {
    const splitedFormat = defaultFormat.split(' ')
    const formatObj = lists.systemDateFormats.find(format => format.value === splitedFormat[0])
    if (formatObj) {
      defaultFormat = `${formatObj.momentFormat} ${splitedFormat[1]}`
    }
  }

  if (typeof value === 'string') {
    switch (type) {
      case 'date': {
        // parsedDate = new Date(value)
        parsedDate = moment(value).toDate()
        formatToSet = format || defaultFormat || 'MM/DD/YYYY'
        break;
      }
      case 'time': {
        const today = new Date()
        const timeParts = value.split(' ')
        const time = timeParts[0]
        const period = timeParts[1]
        const [hours, minutes, seconds] = time.split(':').map(num => parseInt(num, 10))
        let hours24 = hours
        if (period === 'AM' && hours === 12) {
          hours24 = 0
        } else if (period === 'PM' && hours !== 12) {
          hours24 += 12
        }
        today.setHours(hours24, minutes, seconds || 0, 0)
        parsedDate = today
        formatToSet = format || defaultFormat || 'hh:mm:ss a'
        break;
      }
      case 'datetime': {
        parsedDate = new Date(value)
        formatToSet = format || defaultFormat || 'MM/DD/YYYY hh:mm:ss a'
        break;
      }
      default:
        throw new Error('Invalid type specified. Use "date", "time", or "datetime".')
    }

    if (type === 'date' && !parsedDate) {
      console.warn('Invalid date value.')
      return value
    }

    if (type !== 'date' && isNaN(parsedDate.getTime())) {
      console.warn('Invalid date/time value.')
      return value
    }

    const formattedDate = moment(parsedDate).format(formatToSet)
    return formattedDate
  } else if (value instanceof Date) {
    parsedDate = moment(value)
    if (!parsedDate.isValid()) {
      console.warn('Invalid date/time value.')
      return value
    }

    switch (type) {
      case 'date':
        formatToSet = format || defaultFormat || 'MM/DD/YYYY'
        break;
      case 'time':
        formatToSet = format || defaultFormat || 'hh:mm:ss a'
        break;
      case 'datetime':
        formatToSet = format || defaultFormat || 'MM/DD/YYYY hh:mm:ss a'
        break;
      default:
        formatToSet = 'MM/DD/YYYY hh:mm:ss a'
    }

    return parsedDate.format(formatToSet)
  }
}

const getDateTimeDisplayValue = (dateString) => {
  if (!isNaN(Date.parse(dateString))) {
    const date = new Date(dateString)
    const locale = navigator.language
    const options = { dateStyle: "short", timeStyle: "medium" }
    return date.toLocaleString(locale, options)
  }
  return ''
}

const getToken = function (token) {
  return token || localStorage.getItem('UserToken')
}

const isIPad = () => {
  if (/iPad/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad') {
    return true
  }
  return false
}

const isSafari = () => {
  // Chrome in iPad
  if (navigator.userAgent.match('CriOS')) return false
  // Safari
  if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0) return true
  // Others
  return false
}

const isMobileSize = () => {
  if (screen.width <= 768) {
    return true;
  }
  else {
    return false;
  }
}

const isValidURL = (str) => {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]+(-[a-z\\d]+)*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[{};&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
  return !!pattern.test(str)
}

const openUrlInNewBrowserWindow = (url) => {
  const screenWidth = screen.width;
  const screenHeight = screen.height;
  const newWidth = Math.min(screenWidth * 0.8, 1200);
  const newHeight = Math.min(screenHeight * 0.8, 800);

  var newWindow = window.open(url, "_blank", `width=${newWidth},height=${newHeight}`);

  if (newWindow) {
    newWindow.focus();
  } else {
    alert('Please allow popups for this site and try again.');
  }
}

const removeIllegalChars = async function (value) {
  return value.replace(/[<>:"/\\|?*]/g, '')
}

/**
 * Sorts table data and allows you to transform elements to sort on,
 * so you can sort using keys as deep as you want in each iterated object.
 */
const tableSorterWithTransform = function (elementTransform, sortTableAscending) {
  return function (a, b) {
    const item1 = elementTransform(a)
    const item2 = elementTransform(b)

    if (sortTableAscending) {
      return typeof item1 === 'string'
        ? `${(item1).localeCompare(item2)}`
        : item1 - item2
    } else {
      return typeof item1 === 'string'
        ? `${(item2).localeCompare(item1)}`
        : item2 - item1
    }
  }
}

/**
 * Sorts table data.
 *
 * @deprecated by tableSorterWithTransform
 */
const tableSorter = function (sortTableBy, sortTableAscending) {
  return function (a, b) {
    const item1 = a[sortTableBy]
    const item2 = b[sortTableBy]

    if (sortTableAscending) {
      return typeof item1 === 'string'
        ? `${(item1).localeCompare(item2)}`
        : item1 - item2
    } else {
      return typeof item1 === 'string'
        ? `${(item2).localeCompare(item1)}`
        : item2 - item1
    }
  }
}

const testDownloadHasFileExtension = async function (downloadResult, fileName, testFileExtension) {
  // eslint-disable-next-line
  const fileDownload = require('js-file-download')

  if (fileName.endsWith(testFileExtension)) {
    fileDownload(downloadResult, `${fileName}`)
  } else {
    fileDownload(downloadResult, `${fileName}${testFileExtension}`)
  }
}

const testIsGuid = async function (value) {
  if (value === null) {
    return false
  }

  const test = value.toString().match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/)
  return test !== null
}

const unformatCurrency = function (value, format) {
  let negativeMultiplier = value[0] === '-' ? -1 : 1
  let currencyValue = value.replace(/[^0-9.,]/g, '')
  let decimalSeparator = ''
  for (var i = format.length - 1; i >=0; i--) {
    let ch = format[i]
    if (ch == '.' || ch == ",") {
       decimalSeparator = ch
       break
    }
  }
  if (decimalSeparator == '.') {
    currencyValue = currencyValue.replaceAll(',','')
  } else {
    currencyValue = currencyValue.replaceAll('.','')
    currencyValue = currencyValue.replaceAll(',','.')
  }
  return currencyValue * negativeMultiplier
}

export {
  allEntriesExist,
  atLeastOneExists,
  buildAndNavigateToScanToVasionUrl,
  formatCurrency,
  formatDateTime,
  getDateTimeDisplayValue,
  getToken,
  isIPad,
  isSafari,
  isMobileSize,
  isValidURL,
  openUrlInNewBrowserWindow,
  removeIllegalChars,
  tableSorter,
  tableSorterWithTransform,
  testDownloadHasFileExtension,
  testIsGuid,
  unformatCurrency,
}
