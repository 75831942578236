<template>
  <div id="vasion-upload">
    <VasionFolders
      :extraTextLength="true"
      :showFiles="showFiles"
      :onlyShowStorageConfigId="onlyShowStorageConfigId"
      :toggleStorageConfigIdByDefault="toggleStorageConfigIdByDefault"
      :fillColor="'#838587'"
      :enableContextMenu="false"
      @itemSelected="updateButtonActive"
    />
  </div>
</template>
<script>
export default {
  name: 'VasionUpload',
  props: {
    showFiles: {
      type: Boolean,
      default: false,
      required: false,
    },
    onlyShowStorageConfigId: {
      type: Number,
      default: null,
      required: false,
    },
    toggleStorageConfigIdByDefault: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  methods: {
    async loadSubfolders(parentFolderId) {
      const payload = {
        parentFolderId: parentFolderId,
        enforceUserSecurity: false,
        groupByStorageType: false,
      }
      const response = await this.$store.dispatch('common/getChildFolders', payload)
      return response
    },
    updateButtonActive(selectedData) {
      this.$emit('itemSelected', selectedData)
    },
  },
}
</script>
<style lang="scss" scoped>
  #vasion-upload {
    height: 355px;
    width: 100%;
    overflow: auto;

    .folder-dialog-browse {
      width: 400px;
      height: 300px;
      overflow: auto;
    }
  }
</style>
