import axios from 'axios'
// import router from '../router'
import store from '../store/store'

export const createAxiosInstance = (token) => {
  const axInstance = axios.create({
    baseURL: axios.defaults.baseURL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `X-MVApiAuth ${token}`,
      timeout: 1,
    },
  })

  axInstance.interceptors.response.use(response => {
    if (response?.headers['x-mvapiauthexp']) {
      store.commit('common/setUserSessionExpiry', new Date().getTime() + response.headers['x-mvapiauthexp'] * 60000)
    }
    return response;
  }, error => {
    // if (error.response.status === 401 && !store.common.isSignatureDocLinkView) {
    //   router.push({ name: 'TheLogin' })
    // }
    return error;
  });

  return axInstance
}

export default { createAxiosInstance }
