/* eslint-disable linebreak-style */
/* eslint-disable default-case */
/* eslint-disable consistent-return */

import { createAxiosInstance } from '@/services/axiosService'

const deleteDocumentTemplate = async (apiToken, documentTemplateId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('v1/Admin/DeleteDocumentTemplate', {
      data: {
        Value: documentTemplateId,
      },
    })
    return response
  } catch (error) {
    console.warn(error)
  }
}

const documentTemplateReplace = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/DocumentTemplateReplace', payload)
    return response
  } catch( error ) {
    console.warn(error)
  }
}

const signatureDocumentTemplateReplace = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/SignatureDocumentTemplateReplace', payload)
    return response
  } catch( error ) {
    console.warn(error)
  }
}

const signatureDocumentFileReplace = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/SignatureDocumentFileReplace', payload)
    return response
  } catch( error ) {
    console.warn(error)
  }
}

const copyDocumentTemplate = async (apiToken, documentTemplateId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/CopyDocumentTemplate', {
      Value: documentTemplateId,
    })
    return response
  } catch (error) {
    console.warn(error)
  }
}

const findAndReplaceIndexData = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    return axios.post('v1/Admin/FindAndReplaceIndexData', payload)
  } catch (error) {
    console.warn(error)
  }
}

const generateSamlSSOLogin = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Auth/GenerateSamlSSOLogin')
    if (response?.data && response.data.Value) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const getAppVersions = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get('v1/Admin/GetAppVersions')
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getCaptureProfile = async (apiToken, profileID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Admin/GetCaptureProfile?Value=${profileID}`)
    if (response && response.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getAllUserSettings = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Auth/GetAllUserSettings`)
    if (response && response.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getUserSetting = async (apiToken, name) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Auth/GetUserSetting?Value=${name}`)
    if (response && response.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getDocumentTemplateTypeList = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Admin/GetDocumentTemplateTypeList')
    if (response && response.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getEmailSettings = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Admin/GetEmailSettings`)
    if (response && response.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getEmailTemplateDetails = async (apiToken, templateID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Admin/GetEmailTemplateDetails?Value=${templateID}`)
    if (response && response.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getComprehendTypes = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get('/v1/Admin/GetComprehendTypes')
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getCustomFolderViewDetailsForFolder = async (apiToken, folderId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Admin/GetCustomFolderViewDetailsForFolder?Value=${folderId}`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getCustomFolderViewDetailsForID = async (apiToken, custViewId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Admin/GetCustomFolderViewDetailsForID?Value=${custViewId}`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getFieldHasMultipleObjects = async (apiToken, fieldName) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Admin/GetFieldHasMultipleObjects?Value=${fieldName}`)
    return response?.data
  } catch (error) {
    console.warn(error)
  }
}

const getLicenseAvailabilityForUserUpsert = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Admin/LicenseAvailabilityForUserUpsert')
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
    return {
      UserLicenseIsAvailable: false,
      Message: "User licenses not available. Availability Call Failed."
    }
  }
}

const getLookupBindings = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Values } } = await axios.get('v1/Admin/GetLookupBindings?Value=15')
    return Values
  } catch (error) {
    console.warn(error)
  }
}

const getSamlConfig = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Admin/GetSamlConfig')
    if (response && response.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getSamlSSOUrl = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Auth/GetSamlSSOUrl')
    if (response?.data && response.data.Value) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const getTextractAIP = async (apiToken, textractAIPId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Admin/GetTextractAIP?Value=${textractAIPId}`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getTransactionLogAggregateData = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Admin/GetTransactionLogAggregateData', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getUiVersion = async () => {
  //read VERSION file from FE distribution
  let baseUrl = process.env.VUE_APP_IIS_APPLICATION ?? ''
  if (baseUrl == '/') {
    baseUrl = ''
  }
  try {
    const response = await fetch(baseUrl + '/VERSION.txt')
    if(response.status == 200) {
      let responseText = await response.text();
      if (/^\d/.test(responseText)) {
        return responseText
      }
    }
  } catch (error) {
    console.warn(error)
  }
  return "unknown";
}

const getXmlConfigurationList = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get('v1/Admin/GetXmlConfigurationList')
    return data
  } catch (error) {
    console.warn(error)
  }
}

const deleteTextractAIP = async (apiToken, textractAIPId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.delete(`v1/Admin/DeleteTextractAIP?Value=${textractAIPId}`)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const deleteXmlConfiguration = async (apiToken, xmlConfigId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('v1/Admin/DeleteXmlImportConfiguration', {
      data: {
        Value: xmlConfigId,
      },
    })
    return response
  } catch (error) {
    console.warn(error)
  }
}

const getXmlSchemaList = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get('v1/Admin/GetXmlSchemaList')
    return data.schemas
  } catch (error) {
    console.warn(error)
  }
}

const listTextractS3Buckets = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('/v1/Admin/ListTextractS3Buckets', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const loadDashboardStatistics = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get('v1/Report/LoadDashboardStatistics')
    return data
  } catch (error) {
    console.warn(error)
  }
}

const loadImportXmlSchemaFields = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/admin/LoadImportXmlSchemaFields', payload)
    if (data.Values)
      return data.Values
    if (data.Value)
      return data.Value
  } catch (error) {
    console.warn(error)
  }
}

const reloadSAMLConfiguration = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Admin/ReloadSAMLConfiguration')
    if (response?.data && response.data.Value) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveBarcodeIndex = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/SaveBarcodeIndex', payload)
      return response
  } catch (error) {
    console.warn(error)
  }
}

const saveCaptureProfile = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/SaveCaptureProfile', payload)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveEmailSettings = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/SaveEmailSettings', payload)
      return response
  } catch (error) {
    console.warn(error)
  }
}

const setUserSetting = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Auth/SetUserSetting', payload)
      return response
  } catch (error) {
    console.warn(error)
  }
}

const saveEmailTemplate = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Admin/SaveEmailTemplate', payload)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveOcrTemplate = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Admin/SaveOCRScheduler', payload)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const savePasswordSettings = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Auth/SavePasswordSettings', payload)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveSamlConfig = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Admin/SaveSamlConfig', payload)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const sendTestEmail = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get('/v1/Admin/SendTestEmail')
    return data
  } catch (error) {
    console.warn(error)
  }
}

const setThirdPartyCode = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('/v1/Admin/SetThirdPartyCode', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const saveTextractAIP = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Admin/SaveTextractAIP', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const saveXmlConfiguration = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Admin/SaveXmlConfiguration', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getSalesforceConnectionInfo = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get('/v1/Admin/GetSalesforceConnectionInfo')
    return data
  } catch (error) {
    console.warn(error)
  }
}

const saveSalesforceConnectionInfo = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('/v1/Admin/SaveSalesforceConnectionInfo', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const testComprehendConnection = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('/v1/Admin/TestComprehendConnection', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const testSalesforceConnectionInfo = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('/v1/Admin/TestSalesforceConnectionInfo', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const testTextractConnection = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('/v1/Admin/TestTextractConnection', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const validateTextractConnection = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('/v1/Admin/ValidateTextractConnection', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const deleteSalesforceConnectionInfo = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.delete('/v1/Admin/DeleteSalesforceConnectionInfo')
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getAwsRegions = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('/V1/Admin/GetAwsRegions', null)
    if (response?.data) {
      return response
    }
  } catch (error) {
    console.warn(error)
  }
}

export {
  deleteDocumentTemplate,
  copyDocumentTemplate,
  deleteSalesforceConnectionInfo,
  deleteTextractAIP,
  deleteXmlConfiguration,
  documentTemplateReplace,
  findAndReplaceIndexData,
  generateSamlSSOLogin,
  getAppVersions,
  getCaptureProfile,
  getSalesforceConnectionInfo,
  getAllUserSettings,
  getUserSetting,
  getComprehendTypes,
  getCustomFolderViewDetailsForFolder,
  getCustomFolderViewDetailsForID,
  getDocumentTemplateTypeList,
  getEmailSettings,
  getEmailTemplateDetails,
  getFieldHasMultipleObjects,
  getLicenseAvailabilityForUserUpsert,
  getLookupBindings,
  getSamlConfig,
  getSamlSSOUrl,
  getTextractAIP,
  getTransactionLogAggregateData,
  getUiVersion,
  getXmlConfigurationList,
  getXmlSchemaList,
  listTextractS3Buckets,
  loadDashboardStatistics,
  loadImportXmlSchemaFields,
  reloadSAMLConfiguration,
  saveBarcodeIndex,
  saveEmailSettings,
  saveCaptureProfile,
  saveSalesforceConnectionInfo,
  setUserSetting,
  saveEmailTemplate,
  saveOcrTemplate,
  savePasswordSettings,
  saveSamlConfig,
  sendTestEmail,
  setThirdPartyCode,
  saveTextractAIP,
  saveXmlConfiguration,
  signatureDocumentFileReplace,
  signatureDocumentTemplateReplace,
  testComprehendConnection,
  testSalesforceConnectionInfo,
  testTextractConnection,
  validateTextractConnection,
  getAwsRegions,
}
