import {
  deleteAip,
  deleteCaptureProfile,
  getAIP,
  getAIPCompareFieldTypeList,
  getAllAipProcs,
  getAllBarcodeIndex,
  getCaptureProfileList,
  getInstaller,
  saveAIP,
  uploadTempFile,

 } from '@/store/apiModules/capture.api'

 import {
  buildTableDataForAIP,
  buildTableDataForBarcodeConfig,
  buildTableDataForProfiles,
 } from '@/store/helperModules/capture.module';

function initialState() {
  return {
    aipCheckZoneArray: [],
    aipOmrZoneArray: [],
    aipTextZoneArray: [],
    aipData: {},
    barcodeData: {},
    captureSideNav: '',
    currentAIP: null,
    selectedAIPZone: {
      uniqueId: '',
      subType: '',
    },
    selectedOption: null,
    selectedFormId: 0,
    tempFile: '',
  }
}

const capture = {
  namespaced: true,
  state: initialState,
  mutations: {
    changeCaptureSideNav(state, value) { state.captureSideNav = value },
    resetStoreData(state) {
      const localState = initialState()
      Object.keys(state).forEach((prop) => {
        state[prop] = localState[prop]
      })
    },
    setAipCheckZoneArray(state, value) { state.aipCheckZoneArray = value },
    setAipData(state, value) { state.aipData = value },
    setAipOmrZoneArray(state, value) { state.aipOmrZoneArray = value },
    setAipTextZoneArray(state, value) { state.aipTextZoneArray = value },
    setBarcodeData(state, value) { state.barcodeData = value },
    setCurrentAIP(state, value) { state.currentAIP = value },
    setSelectedAIPZone(state, value) { state.selectedAIPZone = value },
    setSelectedOption(state, value) { state.selectedOption = value },
    setSelectedFormId(state, value) { state.selectedFormId = value },
    setTempFile(state, value) { state.tempFile = value },
  },
  actions: {
    changeCaptureSideNav({ commit }, value) { commit('changeCaptureSideNav', value) },
    async deleteAip({ rootState }, configurationId) {
      const result = await deleteAip(rootState.common.apiToken, configurationId)
      return result
    },
    async deleteCaptureProfile({ rootState }, profileId) { return deleteCaptureProfile(rootState.common.apiToken, profileId) },
    async getAIP({ rootState }, aipID) {
      const response = await getAIP(rootState.common.apiToken, aipID)
      if (response !== undefined && response.data !== undefined) {
        return response.data
      } else {
        return {}
      }
    },
    async getAIPCompareFieldTypeList({ rootState }) {
      const response = await getAIPCompareFieldTypeList(rootState.common.apiToken)
      if (response !== undefined) {
        return response
      } else {
        return []
      }
    },
    async getAllAipProcs({ rootState, commit }) {
      const result = await getAllAipProcs(rootState.common.apiToken)
      commit('setAipData', result)
    },
    async getAllBarcodeIndex({ rootState }) { return getAllBarcodeIndex(rootState.common.apiToken) },
    async getCaptureProfiles({ rootState }) { return getCaptureProfileList(rootState.common.apiToken) },
    async getInstaller() {
      const installer = await getInstaller()
      window.open(installer)
    },
    async saveAIP({ rootState }, payload) {
      const response = await saveAIP(rootState.common.apiToken, payload)
      if (response !== undefined && response.data !== undefined && response.data.Value !== undefined) {
        return response.data.Value
      } else {
        return 0
      }
    },
    setAipCheckZoneArray({ commit }, value) { commit('setAipCheckZoneArray', value) },
    setAipOmrZoneArray({ commit }, value) { commit('setAipOmrZoneArray', value) },
    setAipTextZoneArray({ commit }, value) { commit('setAipTextZoneArray', value) },
    setBarcodeData({ commit }, value) { commit('setBarcodeData', value) },
    setCurrentAIP({ commit }, value) { commit('setCurrentAIP', value) },
    async setSelectedAIPZone({ commit }, value) { commit('setSelectedAIPZone', value) },
    setSelectedOption({ commit }, value) { commit('setSelectedOption', value) },
    setSelectedFormId({ commit }, value) { commit('setSelectedFormId', value) },
    // eslint-disable-next-line
    async setTableData({}, data) {
      let response = ''
      if (data.type === 'Aip') {
        response = await buildTableDataForAIP(data)
      } else if (data.type === 'BarcodeConfig') {
        response = await buildTableDataForBarcodeConfig(data)
      } else if (data.type === 'CaptureProfiles') {
        response = await buildTableDataForProfiles(data)
      }
      return response
    },
    setTempFile({ commit }, value) { commit('setTempFile', value) },
    async uploadTempFile({ rootState, commit }, payload) {
      const temp = await uploadTempFile(rootState.common.apiToken, payload)
      commit('setTempFile', temp)
    },
  },
}

export default capture;
