import {
    getDocumentTemplate,
    saveDocumentTemplate,
    uploadTempFile,

   } from '@/store/apiModules/common.api'

  function initialState() {
    return {
      captureSideNav: '',
      currentDocTemplate: null,
      docTemplateFormFieldArray: [],
      selectedDocTemplateZone: {
        subType: '',
        uniqueId: '',
      },
      tempFile: '',
    }
  }

  const template = {
    namespaced: true,
    state: initialState,
    mutations: {
      changeCaptureSideNav(state, value) { state.captureSideNav = value },
      resetStoreData(state) {
        const localState = initialState()
        Object.keys(state).forEach((prop) => {
          state[prop] = localState[prop]
        })
      },
      setCurrentDocTemplate(state, value) { state.currentDocTemplate = value },
      setDocTemplateFormFieldArray(state, value) { state.docTemplateFormFieldArray = value },
      setSelectedDocTemplateZone(state, value) { state.selectedDocTemplateZone = value },
      setTempFile(state, value) { state.tempFile = value },
    },
    actions: {
      changeCaptureSideNav({ commit }, value) { commit('changeCaptureSideNav', value) },
      async getDocumentTemplate({ rootState }, templateID) {
        const response = await getDocumentTemplate(rootState.common.apiToken, templateID)
        if (response !== undefined && response.data !== undefined) {
          return response.data
        } else {
          return {}
        }
      },
      async saveDocumentTemplate({ rootState }, payload) {
        const response = await saveDocumentTemplate(rootState.common.apiToken, payload)
        if (response !== undefined && response.data !== undefined && response.data.Value !== undefined) {
          return response.data.Value
        } else {
          return 0
        }
      },
      setCurrentDocTemplate({ commit }, value) { commit('setCurrentDocTemplate', value) },
      setDocTemplateFormFieldArray({ commit }, value) { commit('setDocTemplateFormFieldArray', value) },
      async setSelectedDocTemplateZone({ commit }, value) { commit('setSelectedDocTemplateZone', value) },
      setTempFile({ commit }, value) { commit('setTempFile', value) },
      async uploadTempFile({ rootState, commit }, payload) {
        const temp = await uploadTempFile(rootState.common.apiToken, payload)
        commit('setTempFile', temp)
      },
    },
  }

 export default template;
