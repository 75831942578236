<template>
  <div>
    <DocumentObjectModal
      :show="showThisDialog"
      :allowObjectSelection="true"
      :confirmButtonText="confirmText"
      :rejectButtonText="'Delete'"
      :documentId="currentDocumentId"
      :formId="formId"
      :imageEndpoint="'document/getDocumentImageURL'"
      :title="documentTitle"
      :titleShowDocumentName="true"
      @closeDialogClicked="toggleCancelAll"
      @confirmButtonClicked="saveFields"
      @rejectButtonClicked="toggleCheckDeleteDialog"
    />

    <VasionGeneralModal
      :hideButtons="true"
      buttonGroup="single"
      :modalType="'slot'"
      :sync="showDeleteDialog"
      @noButtonClick="showDeleteDialog = false"
    >
      <VasionConfirmationDialog
        message="Are you sure you want to delete this file?"
        @noButtonClick="showDeleteDialog = false"
        @yesButtonClick="deleteDocument"
      />
    </VasionGeneralModal>

    <VasionGeneralModal
      :hideButtons="true"
      buttonGroup="single"
      :modalType="'slot'"
      :sync="showCancelAllDialog"
      @noButtonClick="showCancelAllDialog = false"
    >
      <VasionConfirmationDialog
        message="All remaining files not yet saved will be deleted. Would you like to continue?"
        @noButtonClick="showCancelAllDialog = false"
        @yesButtonClick="cancelAllRemaining(); showCancelAllDialog = false"
      />
    </VasionGeneralModal>

    <VasionSnackbar
      id="result-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>
  
<script>
  import DocumentObjectModal from '@/components/document/DocumentObjectModal.vue';
  
  export default {
    name: 'DocumentObjectPreview',
    components: {
      DocumentObjectModal,
    },
    props: {
      show: {
        type: Boolean,
        required: true,
      },
      documentIdList: {
        type: Array,
        required: false,
        default: () => [],
      },
      formId: {
        type: Number,
        required: true,
      },
    },
    data: function () {
      return {
        currentDocumentId: 0,
        currentDocumentNumber: 1,
        documentName: '',
        filesDeleted: 0,
        filesSaved: 0,
        showCancelAllDialog: false,
        showDeleteDialog: false,
        showThisDialog: false,
        showSnackbar: false,
        snackbarImage: false,
        snackbarSubTitle: '',
        snackbarTitle: '',
      }
    },
    computed: {
      confirmText() { return (this.currentDocumentNumber == this.totalDocuments) ? "Save" : "Save And Continue" },
      documentTitle() { return this.documentName + '   ' + this.currentDocumentNumber + '/' + this.totalDocuments },
      totalDocuments() { return this.documentIdList.length },
    },
    watch: {
      async show() {
        this.showThisDialog = this.show
        if (this.showThisDialog) {
          this.currentDocumentNumber = 1
          this.filesDeleted = 0
          this.filesSaved = 0
          this.currentFormId = this.formId
          this.currentDocumentId = this.documentIdList[this.currentDocumentNumber - 1]
        }
      },
    },
    methods: {
      async cancelAllRemaining() {
        let deletingDocuments = ''
        this.documentIdList.forEach((documentID, i) => {
          if (i >= this.currentDocumentNumber - 1) {
            deletingDocuments = deletingDocuments + documentID.toString() + ','
            this.filesDeleted++
          }
        })
        deletingDocuments = deletingDocuments.slice(0, deletingDocuments.length - 1)
        const errorMessage = await this.$store.dispatch('document/deleteMultipleDocuments', { 
          'DocumentIdString': deletingDocuments, 
          'ForceRecycle': false 
        })
        const deleteSuccess = errorMessage === ''
        if (deleteSuccess) {
          this.toggleSnackbar(true, 'Documents Deleted Successfully.')
        } else {
          this.toggleSnackbar(false, 'Error deleting documents. Please contact your administrator.')
        }
        this.closeAndDisplaySnackbar()
      },
      closeAndDisplaySnackbar() {
        this.toggleSnackbar(true, `Documents deleted: ${this.filesDeleted}, Documents saved: ${this.filesSaved}`)
        this.$emit('allDocumentsProcessed')
      },
      async deleteDocument() {
        const errorMessage = await this.$store.dispatch('document/deleteMultipleDocuments', { 
          'DocumentIdString': this.currentDocumentId.toString(), 
          'ForceRecycle': false 
        })
        const deleteSuccess = errorMessage === ''
        if (deleteSuccess) {
          this.toggleSnackbar(true, 'Document Deleted Successfully.')
          this.filesDeleted++
          this.goToNextDocument()
        } else {
          this.toggleSnackbar(false, 'Error deleting document. Please contact your administrator.')
        }
        this.toggleCheckDeleteDialog()
      },
      async goToNextDocument() {
        if (this.currentDocumentNumber >= this.totalDocuments) {
          this.closeAndDisplaySnackbar()
          return
        }
        this.currentDocumentNumber++
        this.currentDocumentId = this.documentIdList[this.currentDocumentNumber - 1]
        this.noImageLoaded = false
        this.currentPageNumber = 1
      },
      goToPage(pageNumber) {
        this.noImageLoaded = false
        if (typeof pageNumber !== 'undefined' && pageNumber > 0 && pageNumber <= this.totalPages) {
          this.currentPageNumber = pageNumber
        }
      },
      async saveFields(params) {
        const payload = {
          DocumentId: this.currentDocumentId,
          FormId: params.formId,
          FieldValues: { Values: params.fieldValues }
        }

        const result = await this.$store.dispatch('document/saveDocumentAttributes', payload)
        
        if (result) {
          this.toggleSnackbar(true, 'Document saved successfully')        
          this.filesSaved++
          this.goToNextDocument()
        }
        else {
          this.toggleSnackbar(false, 'Error saving document')        
        }
      },
      toggleCancelAll() {
        this.showCancelAllDialog = !this.showCancelAllDialog
      },
      toggleCheckDeleteDialog() {
        this.showDeleteDialog = !this.showDeleteDialog
      },
      toggleSnackbar(result, message) {
        this.snackbarImage = result
        if (result) {
          this.snackbarTitle = 'SUCCESS'
          this.snackbarSubTitle = message
          this.showSnackbar = true
        } else {
          this.snackbarTitle = 'FAILURE'
          this.snackbarSubTitle = message
          this.showSnackbar = true
        }
      },
    },
  }
</script>
