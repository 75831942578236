<template>
  <div
    v-if="showModal === true"
    class="overlay"
    @click.self="outsideClick()"
  >
    <div class="modal-container">
      <div class="vasion-general-modal dialog-div">
        <VasionWordmark class="vasion-wordmark" />        
        <div class="title">Usage Agreement</div>
        <div class="agreement">
          Please read and review the <a href="https://www.vasion.com/vasion-master-software-agreement" target="_blank" class="vasion-msa" @click="agreementClicked=true">Vasion Master Software Agreement.</a> By clicking the button below, you agree to use Vasion in accordance with the terms and conditions set forth in the agreement.
        </div>
        <VasionButton
          id="confirm-button"
          :isFullWidth="true"
          :isDisabled="!agreementClicked"
          :classProp="'primary'"
          @vasionButtonClicked="yesButtonClick()"
        >
          Agree &amp; Continue
        </VasionButton>
        <VasionButton
          id="reject-button"
          :isFullWidth="true"
          :classProp="'secondary'"
          @vasionButtonClicked="noButtonClick()"
        >
          Cancel
        </VasionButton>
      </div>
    </div>
  </div>  
</template>
  
<script>
  export default {
    name: 'VasionMSAModal',
    components: {
    },
    props: {
      showModal: {
        type: Boolean,
        required: true,
      }
    },
    data: function () {
      return {
        agreementClicked: false,
      }
    },
    computed: {
    },
    async created() {
    },
    methods: {
      async yesButtonClick() {
        const error = await this.$store.dispatch('common/approveMSA')
        if (error) {
          this.$emit('noButtonClick')
          return
        }
        this.$emit('yesButtonClick')
      },
      noButtonClick() { 
        this.$store.dispatch('common/setLoginError', 'MSA Approval cancelled by user.')  
        this.$emit('noButtonClick') 
      },
    },
  }
  </script>
  
  <style lang="scss" scoped>
    @import '@/assets/css/variables.scss';
    @import '@/assets/css/light-theme-system-palette.scss';

    .overlay {
    visibility: initial;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 20;
    cursor: auto;
    .modal-container {
      z-index: 1000;
      border-radius: 8px;
      background: #FFFFFF;
      padding: 25px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      animation: grow .30s;
      max-width: 100%;
      max-height: 100%;
      overflow-y: auto;
      .vasion-general-modal {
        height: 100%;
        display: flex;
        flex-direction: column;
        margin: auto;
        .message {
          @include Text
        }
      }
      .dialog-div {
        padding: 50px;
      }
      .general-modal-footer {
        position: relative;
        bottom: 0;
        right: 0;
      }
    }
  }

  .vasion-wordmark {
    width: 100%;
    position: relative;
    right: 8px;
    fill: $primary;
  }

  .title {
    font-size: 26px;
    font-weight: 500;
    margin-top: 50px;
  }

  .agreement {
    font-size: 13px;
    margin: 16px 0px;
  }

  .vasion-msa {
    font-size: 13px;
    color: $blue-300 !important;
    text-decoration: underline;
  }
</style>
  