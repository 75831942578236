
import {
  getConfigs,
  // setConfig,
} from '@/store/apiModules/common.api'

async function getConfigValues(apiToken) {
  const data = await getConfigs(apiToken)
  return data?.ConfigValues
}

// async function setConfigApi({ rootState }, payload) {
//   /*payload = {sConfigName, sConfigValue}*/
//   setConfig(rootState.common.apiToken, payload)
// }

function initialState() {
  return {
    featureFlags: {
      aipTextractEnable: false,
      amplitudeDisabled: false,
      barcodeBatchScanning: false,
      basicFieldValidation: false,
      enableFolderSearch: false,
      enableWFParallelRoute: false,
      globalFolderEncryption: false,
      pdfFormsEnabled: false,
      textractLineItemDataEnabled: false,
      vasionAutomateBridge: false,
    },
    homePageDeployDateVersion1: '',
    stateFetched: false,
    vasionAutomateURL: '',
    vasionPrintURL: '',
    systemSettingsSideNav: '',
    systemCurrencyFormat: '$#,##0.00',
    systemDateFormat: 'MM/DD/YYYY',
    systemTimeFormat: 'hh:mm:ss a',
  }
}

const systemSettings = {
  namespaced: true,
  state: initialState,
  mutations: {
    changeSystemSettingsSideNav(state, value) { state.systemSettingsSideNav = value },
    resetStoreData(state) {
      const localState = initialState()
      Object.keys(state).forEach((prop) => {
        state[prop] = localState[prop]
      })
    },
    setStateFetched(state, value) {
      state.stateFetched = value
    },
    storeFeatureFlag(state, payload) {
      state.featureFlags[payload.key] = payload.value?.toLowerCase() === 'true'
    },
    storeSetting(state, payload) {
      state[payload.key] = payload.value
    },
  },
  actions: {
    changeSystemSettingsSideNav({ commit }, value) { commit('changeSystemSettingsSideNav', value) },
    
    async getAllSystemSettings({ commit, state }, {apiToken, forceFetch}) {
      if (!apiToken) {
        commit('resetStoreData')
        return
      }
      if (state.stateFetched && !forceFetch) return

      const configValues = await getConfigValues(apiToken)
      if (configValues) {
        commit('storeFeatureFlag', {key: 'aipTextractEnable', value: configValues['AIPTextractEnable']})
        commit('storeFeatureFlag', {key: 'amplitudeDisabled', value: configValues['AmplitudeDisabled']})
        commit('storeFeatureFlag', {key: 'barcodeBatchScanning', value: configValues['BarcodeBatchScanning']})
        commit('storeFeatureFlag', {key: 'basicFieldValidation', value: configValues['BasicFieldValidation']})
        commit('storeFeatureFlag', {key: 'cohereEnabled', value: configValues['CohereEnabled']})
        commit('storeFeatureFlag', {key: 'enableFolderSearch', value: configValues['EnableFolderSearch']})
        commit('storeFeatureFlag', {key: 'enableGeneratePdf', value: configValues['EnableGeneratePdf']})
        commit('storeFeatureFlag', {key: 'enableWFParallelRoute', value: configValues['EnableWFParallelRoute']})
        commit('storeFeatureFlag', {key: 'globalFolderEncryption', value: configValues['GlobalFolderEncryption']})
        commit('storeFeatureFlag', {key: 'pdfFormsEnabled', value: configValues['PdfFormsEnabled']})
        commit('storeFeatureFlag', {key: 'textractLineItemDataEnabled', value: configValues['TextractLineItemDataEnabled']})
        commit('storeFeatureFlag', {key: 'vasionAutomateBridge', value: configValues['VasionAutomateBridge']})

        commit('storeSetting', {key: 'homePageDeployDateVersion1', value: configValues['HomePageDeployDateVersion1']})

        commit('storeSetting', {key: 'vasionAutomateURL', value: configValues['VasionAutomateURL']})
        commit('storeSetting', {key: 'vasionPrintURL', value: configValues['VasionPrintURL']})

        commit('storeSetting', {key: 'systemDateFormat', value: configValues['SystemDateFormat'] || state.systemDateFormat})
        commit('storeSetting', {key: 'systemTimeFormat', value: configValues['SystemTimeFormat'] || state.systemTimeFormat})
        commit('storeSetting', {key: 'systemCurrencyFormat', value: configValues['SystemCurrencyFormat'] || state.systemCurrencyFormat})

        commit('setStateFetched', true)
      }
    },
    reloadSystemSettings({ commit }, apiToken) {
      commit('setStateFetched', false)
      return this.dispatch('systemSettings/getAllSystemSettings', apiToken)
    },
    resetStoreData({ commit }) {
      commit('resetStoreData')
    },
    // async setSystemSetting({ commit, rootState }, payload) {
    //   setConfigApi(rootState, payload)
    //   commit('storeSetting', {key: payload.sConfigName, value: payload.sConfigValue})
    // },
    // async setFeatureFlag({ commit, rootState }, payload) {
    //   setConfigApi(rootState, payload)
    //   commit('storeFeatureFlag', {key: payload.sConfigName, value: payload.sConfigValue})
    // },
  },
}

export default systemSettings;
