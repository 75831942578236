<template>
  <div v-if="(filter==='none' || filter===cardConfidenceLevel) && (selectedObjectID===0 || selectedObjectID===cardData.FormId)">
    <div class="card-container" :class="{ 'selected': clicked }" @click="cardClicked">
      <div class="card-header">
        <div>
          <p class="title">
            {{ title }}
          </p>
        </div>
        <VasionConfidenceChip v-if="meanConfidence" :confidence="meanConfidence" @confidenceLevel="setConfidenceLevel" />
      </div>
      <div class="card-body">
        <IndexFieldList
          :key="cardKey"
          :ref="indexFieldListRef"
          :enforceFieldSecurity="true"
          :fields="fields"
          :selectorOnly="false"
          :showSubmit="false"
          :useMultiSelectLists="true"
          :disableAllFields="false"
          :isInDocumentView="false"
          :fixedSize="true"
          :hiddenColumnIDs="hiddenColumnIDs"
          @fieldValueUpdated="updateFieldValues"
          @isValid="markFieldAsValid"
          @notValid="markFieldAsNotValid"
          @clean="markClean"
          @dirty="markDirty"
          @fieldClicked="fieldClicked"
        />
        <CollapsibleSection v-if="hasLineItemData" title="Line Item Data">
          <FieldTablesContainer :key="`D${this.cardData.DocumentId}-LI${refreshLineItemDataTrigger}`" :lineItemTables="lineItemTables" :documentId="cardData.DocumentId" @markDirty="markDirty" />
        </CollapsibleSection>
      </div>
    </div>
  </div>
</template>

<script>
import CollapsibleSection from '@/components/general/CollapsibleSection.vue';
import FieldTablesContainer from '@/components/general/FieldTablesContainer.vue';
import IndexFieldList from '@/components/IndexFieldList.vue';
import VasionConfidenceChip from '@/components/shared/VasionConfidenceChip.vue';

export default {
  name: 'VasionFieldValidationCard',
  components: {
    CollapsibleSection,
    FieldTablesContainer,
    IndexFieldList,
    VasionConfidenceChip,
  },
  props: {
    cardData: {
      type: Object,
      required: true,
      default: () => {},
    },
    filter: {
      type: String,
      required: false,
      default: 'none'
    },
    hiddenColumnIDs: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectedObjectID: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      cardKey: 0,
      fields: [],
      indexFieldListRef: '',
      invalidFields: [],
      lineItemTables: [],
      meanConfidence: null,
      title: '',
      cardConfidenceLevel: 'none',
    };
  },
  computed: {
    clicked() { return this.$store.state.document.cardClicked?.DocumentId === this.cardData.DocumentId },
    hasLineItemData() { return this.lineItemTables.length > 0 },
    refreshLineItemDataTrigger() { return this.$store.state.document.refreshLineItemDataTrigger },
  },
  async created() {
    if (this.isCardDataValid()) await this.setCardData()
    this.lineItemTables = await this.getLineItemsTables(this.cardData.DocumentId)
    await this.$store.dispatch('document/setLineItemFields', { documentId: this.cardData.DocumentId, lineItemData: this.lineItemTables })
    // Force refresh of card in case any field formatting was applied
    this.$nextTick(() => {
      this.cardKey++
    })
 },
  methods: {
    async cardClicked(event) {
      if (event.target.nodeName !== "DIV") return
      if (event.target.closest('.collapsible-section')) return
      if (event.target.closest('.overlay')) return
      const dataToSend = this.clicked ? null : this.cardData
      await this.$store.dispatch('document/setCardClicked', dataToSend)
      this.$emit('cardClicked', dataToSend)
    },
    async constructFields(baseData) {
      const fieldList = []
      Object.keys(baseData).map((key) => {
        let field
        if (baseData[key]) {
          field = baseData[key]
          const dropdownValues = []

          if (field.DropDownValues && field.DropDownValues.Values && field.DropDownValues.Values.length > 0) {
            Object.keys(field.DropDownValues.Values).map((innerkey) => {
              dropdownValues.push({
                name: field.DropDownValues.Values[innerkey],
                value: Number(innerkey),
              })
              return true
            })
          }

          let ret = ''
          if (field.Control === 2) {
            ret = field.DropDownSelectedValues?.Values ? field.DropDownSelectedValues.Values : ''
          } else if (field.Control === 3) {
            ret = field.DateValueUTC
          } else {
            ret = field.TextValue
          }

          fieldList.push({
            type: field.Control,
            name: field.DisplayName,
            value: field.FieldName,
            filterValue: ret,
            dropdownValues: dropdownValues,
            fieldID: field.FieldID,
            pageNumber: field.PageNumber,
            readOnly: field.ReadOnly,
            required: field.Required,
            hidden: field.Hidden,
            allowMultiples: field.AllowMultiples,
            showCommas: field.ShowCommas,
            decimalPlaces: field.DecimalPlaces,
            currencyFormat: field.CurrencyFormat,
            mask: field.Mask,
            meanConfidence: field.MeanConfidence,
            fieldLength: field.FieldLength,
          })
        }
        return true
      })
     return fieldList 
    },
    async fieldClicked(field) {
      this.$emit('fieldClicked', { card: this.cardData, clickedField: field })
    },
    async getLineItemsTables(documentId) {
      const payload = { DocumentId: documentId, IncludeConfidence: true }
      const result = await this.$store.dispatch('document/getDocumentLineItems2', payload)
      const { lineItemTables = [] } = result || {}
      return lineItemTables
    },
    isCardDataValid() {
      if ( this.cardData === null ) return false
      if ( typeof this.cardData !== 'object' ) return false
      if ( !this.cardData?.DisplayName ) return false
      if ( typeof this.cardData.DisplayName !== 'string' ) return false
      if ( !this.cardData?.IndexFormFields?.Values ) return false
      
      return true
    },
    markClean() {
      this.$emit('markClean')
    },
    markDirty() {
      this.$emit('markDirty')
    },
    markFieldAsNotValid(field) {
      if (this.invalidFields.includes(field.type)) return
      this.invalidFields.push(field.type)
      this.$emit('markFieldAsNotValid', { field: field, ...this.cardData })
    },
    markFieldAsValid(field) {
      const indexToBeDeleted = this.invalidFields.indexOf(field.type)
      if (indexToBeDeleted !== -1)
        this.invalidFields.splice(indexToBeDeleted, 1)
      this.$emit('markFieldAsValid', { field: field, ...this.cardData })
    },
    async setCardData() {
      this.title = this.cardData.DisplayName
      this.meanConfidence = this.cardData.meanConfidence ? this.cardData.meanConfidence : null
      this.indexFieldListRef = `ref-${this.cardData.DocumentId}`
      this.fields = await this.constructFields(this.cardData.IndexFormFields.Values)
    },
    setConfidenceLevel(level) {
      this.cardConfidenceLevel = level
    },
    updateFieldValues(newFieldValues) {
      this.$emit('updateFieldValues', { newFields: newFieldValues, ...this.cardData })
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container.selected {
  border: 1px solid #8b8b8b;
}
.card-container {
  width: 100%;
  margin-bottom: 8px;
  background-color: #FFFFFF;
  border: 1px solid #dee2e6;
  border-radius: 16px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  position: relative;
  display: flex;
  flex-direction: column;
}
.card-header {
  padding: 16px;
  margin-bottom: 0;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}
.card-header div {
  max-width: 80%;
}
.card-header .title {
  font-family: Archivo;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #615C66;
  margin-bottom: 0px;
}
.card-body {
  flex: 1 1 auto;
  padding: 16px;
  margin-bottom: 12px;
}
</style>
