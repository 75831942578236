<!-- /* Available icons for icon buttons can be found at https://material.io/resources/icons/?style=baseline */ -->
<template>
  <div v-if="isValid()" class="vasion-button" :class="{ divFullWidth: isFullWidth, hasLabel: label.length > 0 }">
    <label v-if="label.length > 0" id="vasion-button-label" class="vasion-input-label-top">{{ updatedLabel.toUpperCase() }}</label>

    <!-- Icon Button -->
    <md-button
      v-if="isIcon()"
      :class="calculatedClasses()"
      :disabled="isDisabled"
      :md-ripple="ripple"
      @click.prevent="doClickAction"
    >
      <component
        :is="icon"
        v-if="isVasionIcon()"
        :style="{ fill: iconFill }"
        :class="{ 'vasion-icon-disabled': isDisabled }"
      />
      <md-icon v-else>
        {{ icon }}
      </md-icon>
    </md-button>

    <!-- Router Link Button -->
    <md-button
      v-else-if="isRouterLink()"
      :to="to"
      :class="calculatedClasses()"
      :disabled="isDisabled"
      :md-ripple="ripple"
      @click.prevent="doClickAction"
    >
      <slot>Route Link</slot>
    </md-button>

    <!-- Link Button -->
    <md-button
      v-else-if="isLink()"
      :href="href"
      :class="calculatedClasses()"
      :disabled="isDisabled"
      :md-ripple="ripple"
      @click="doClickAction"
    >
      <slot>Link</slot>
    </md-button>

    <!-- Standard Button -->
    <md-button
      v-else-if="isSubmit && !isIcon() && !isRouterLink() && !isLink()"
      type="submit"
      :class="calculatedClasses()"
      :disabled="isDisabled"
      :md-ripple="ripple"
      @click="doClickAction"
    >
      <slot>Submit</slot>
    </md-button>

    <md-button
      v-else
      :class="calculatedClasses()"
      :disabled="isDisabled"
      :md-ripple="ripple"
      @click.prevent="doClickAction"
    >
      <slot>Standard</slot>
    </md-button>
    <!-- TODO: Add support for Secondary Icon buttons -->
  </div>
</template>

<script>

const classPropOptions = [
  '',
  'primary',
  'primary-light',
  'primary-warning',
  'secondary',
  'secondary-grey',
  'secondary-transparent',
  'secondary-light',
  'secondary-toggled',
  'secondary-warning',
  'secondary-warning-text',
  'text',
  'text-selected',
  'text-light',
  'text-black',
  'text-transparent',
  'text-transparent-selected',
  'tab',
  'tab-selected',
]

export default {
  name: 'VasionButton',
  props: {
    classProp: {
      type: String,
      default: '',
      required: false,
      validator: function (value) {
        return classPropOptions.indexOf(value) !== -1
      },
    },
    clickActionCallback: {
      type: Function,
      default: null,
      required: false,
    },
    href: {
      type: String,
      default: '',
      required: false,
    },
    icon: {
      type: String,
      default: '',
      required: false,
    },
    iconFill: {
      type: String,
      default: '',
      required: false,
    },
    isDense: {
      type: Boolean,
      default: false,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
      required: false,
    },
    isInErrorState: {
      type: Boolean,
      default: false,
      required: false,
    },
    isRaised: {
      type: Boolean,
      default: false,
      required: false,
    },
    isSubmit: {
      type: Boolean,
      default: false,
      required: false,
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    makeHoverEffectSquare: {
      type: Boolean,
      default: false,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    ripple: {
      type: Boolean,
      default: true,
      required: false,
    },
    to: {
      type: String,
      default: '',
      required: false,
    },
  },
  computed: {
    updatedLabel() { return this.required ? `${this.label} *` : this.label },
  },
  watch: {
    classProp: function () {
      this.calculatedClasses()
    },
  },
  methods: {
    calculatedClasses: function () {
      const classes = []

      if (this.isIcon()) {
        classes.push('md-icon-button')
        if (this.makeHoverEffectSquare) {
          classes.push('md-icon-button-no-circle')
        }
      }

      switch (this.classProp) {
        case 'primary':
        case 'primary-light':
          classes.push('md-primary')
          break;
        case 'secondary':
        case 'secondary-grey':
        case 'secondary-transparent':
        case 'secondary-light':
        case 'secondary-toggled':
        case 'text':
        case 'text-light':
        case 'text-black':
        case 'tab':
        case 'tab-selected':
          classes.push('md-default')
          break;
        case 'primary-warning':
        case 'secondary-warning':
          classes.push('md-accent')
          break;
        default:
          classes.push('md-default')
          break;
      }

      if (this.isDense) {
        classes.push('md-dense')
      }

      if (this.isRaised) {
        classes.push('md-raised')
      }
      if (this.isInErrorState) {
        if (this.classProp === 'text-selected' ){
          classes.push('vasion-tab-error-state-selected')
        }
        else {
          classes.push('vasion-tab-error-state')
        }
      }
      else {
        switch (this.classProp) {
          case 'primary':
            classes.push('vasion-primary')
            break;
          case 'primary-light':
            classes.push('vasion-primary-light')
            break;
          case 'primary-warning':
            classes.push('vasion-primary-warning')
            break;
          case 'secondary':
            classes.push('vasion-secondary')
            break;
          case 'secondary-grey':
            classes.push('vasion-secondary-grey')
            break;
          case 'secondary-transparent':
            classes.push('vasion-secondary-transparent')
            break;
          case 'secondary-light':
            classes.push('vasion-secondary-light')
            break;
          case 'secondary-toggled':
            classes.push('vasion-secondary-toggled')
            break;
          case 'secondary-warning':
            classes.push('vasion-secondary-warning')
            break;
          case 'secondary-warning-text':
            classes.push('vasion-secondary-warning-text')
            break;
          case 'text':
            classes.push('vasion-text')
            break;
          case 'text-selected':
            classes.push('vasion-text-selected')
            break;
          case 'text-light':
            classes.push('vasion-text-light')
            break;
          case 'text-black':
            classes.push('vasion-text-black')
            break;
          case 'text-transparent':
            classes.push('vasion-text-transparent')
            break;
          case 'text-transparent-selected':
            classes.push('vasion-text-transparent-selected')
            break;
          case 'tab':
              classes.push('vasion-tab')
              break;
          case 'tab-selected':
            classes.push('vasion-tab-selected')
            break;
          default:
            break;
        }
      }

      if (this.isFullWidth) {
        classes.push('buttonFullWidth')
      }

      return classes
    },
    doClickAction: function (e) {
      if (typeof this.clickActionCallback === 'function') {
        this.clickActionCallback()
      }

      this.$emit('vasionButtonClicked', e)
    },
    isIcon: function () {
      return typeof this.icon !== 'undefined' && this.icon && this.icon !== ''
    },
    isLink: function () {
      return typeof this.href !== 'undefined' && this.href && this.href !== ''
    },
    isRouterLink: function () {
      return typeof this.to !== 'undefined' && this.to && this.to !== ''
    },
    isValid: function () {
      let error = ''

      if (classPropOptions.indexOf(this.classProp) === -1 && typeof this.classProp !== 'undefined' && this.classProp !== '') {
        const optionsString = classPropOptions.filter(option => option !== '').join("', '");
        error = `${error}The value of the class property, '${this.classProp},' is invalid for a vasion-button. Acceptable values are '${optionsString}', or an empty string. `
      }

      if (this.isRouterLink() && this.isLink()) {
        error = `${error}A vasion-button cannot be both a router-link and a link. Please provide either the 'to' property or the 'href' property, but not both. `
      }

      if (this.isIcon() && (this.isRouterLink() || this.isLink())) {
        error = `${error}A vasion-button cannot be an icon button as well as a router-link button or a link button. Please remove the 'to' and 'href' properties. `
      }

      if (this.isSubmit && (this.isIcon() || this.isRouterLink() || this.isLink())) {
        error = `${error}A vasion-button with isSubmit set to true cannot be an icon button or a router-link button or a link button. Please remove the 'icon', to', and 'href' properties. `
      }

      if (error && error !== '') {
        console.warn(`ERROR: ${error}`)
        return false
      }

      return true
    },
    isVasionIcon: function () {
      return this.isIcon() && this.icon.startsWith('Vasion')
    },
  },
}
</script>

<style lang="scss">
  //global CSS for md-button styling
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';
  .md-button.vasion-secondary-button {
    background-color: $btn-secondary-bg !important;
    color: $btn-secondary-color !important;
    border: $btn-secondary-border;
    border-radius: 4px;

    &:hover, &:focus {
      background-color: $btn-secondary-hover-bg !important;
    }

    &:active {
      background-color: $btn-secondary-active-bg !important;
    }

    &:disabled, &[disabled] {
      background-color: $white !important;
      color: $grey-75 !important;
      border-color: $grey-75 !important;
    }
  }
</style>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
@import '@/assets/css/light-theme-system-palette.scss';

.divFullWidth {
  width: 100%;
}

.md-icon-button-no-circle:before {
  border-radius: 0 !important;
}

.buttonFullWidth {
  width: calc(100% - 15px);
}

#vasion-button-label {
  display: flex;
  margin-bottom: 2px;
  margin-left: 8px;
}
.hasLabel {
  margin-top: 3px !important;
}

.vasion-button {
  display: inline-block;
  padding: 0;

  a, button {
      height: 36px;
      border-radius: 4px;
      font-family: $font-family-book !important;
      color: $white !important;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.71;
      letter-spacing: normal;
      text-align: center;
      z-index: 4;

      &.md-default {
          color: $grey-500 !important;
      }

      &.md-icon-button {
          margin: 0;
      }

      // If we ever need an icon that is any color other than black, we'll need to remove this and handle some other way
      i.md-icon-font {
          color: $grey-500;
      }

      &.vasion-primary {
          background-color: $btn-primary-bg !important;
          color: $btn-primary-color !important;

          &:hover:before {
            opacity: .08;
            background-color: $white !important;
          }

          &:focus:before {
            opacity: .12;
            background-color: $white !important;
          }

          &:active {
            opacity: .12;
            background-color: $white !important;
          }

          &:disabled:before, &[disabled]:before {
            opacity: .08;
            background-color: #000000 !important;
          }

          &:disabled, &[disabled] {
            background-color: $btn-primary-disable-bg !important;
            color: #1d1b1ea1 !important;
          }
      }

      &.vasion-primary-light {
          background-color: $btn-primary-light-bg !important;
          color: $btn-primary-light-color !important;

          &:hover:before {
            opacity: .08;
            background-color: $primary !important;
          }
          
          &:focus:before {
            opacity: .12;
            background-color: $primary !important;
          }

          &:active {
            opacity: .12;
            background-color: $primary !important;
          }

          &:disabled, &[disabled] {
              background-color: transparent !important;
              color: $on-surface-variant !important;
          }
      }

      &.vasion-primary-warning {
          background-color: $red-300 !important;

          &:hover, &:focus {
              background-color: $red-400 !important;
          }

          &:active {
              background-color: $red-500 !important;
          }

          &:disabled, &[disabled] {
              background-color: $grey-75 !important;
              color: $grey-400 !important;
          }
      }

      &.vasion-secondary {
          background-color: $btn-secondary-bg !important;
          color: $btn-secondary-color !important;
          border: $btn-secondary-border;

          &:hover, &:focus {
              background-color: $btn-secondary-hover-bg !important;
          }

          &:active {
              background-color: $btn-secondary-active-bg !important;
          }

          &:disabled, &[disabled] {
              background-color: $white !important;
              color: $grey-75 !important;
              border-color: $grey-75 !important;
          }
      }

      &.vasion-secondary-grey {
          background-color: $grey-50 !important;
          color: $grey-700 !important;
          border-radius: 4px;

          &:hover, &:focus {
              background-color: $grey-75 !important;
          }

          &:active {
              background-color: $grey-75 !important;
          }

          &:disabled, &[disabled] {
              background-color: $grey-25 !important;
              color: $grey-75 !important;
          }
      }

      &.vasion-secondary-transparent {
          background-color: transparent !important;
          color: $primary !important;
          border-radius: 4px;
          border: solid 1px $primary;

          &:hover:before {
            opacity: .08;
            background-color: $primary !important;
          }

          &:focus:before {
            opacity: .12;
            background-color: $primary !important;
          }

          &:disabled, &[disabled] {
              background-color: transparent !important;
              color: $on-surface !important;
              fill: $on-surface !important;
              border-color: $bg-black-8 !important;
          }
      }

      &.vasion-secondary-toggled {
        background-color: $secondary-container !important;
        color: $primary !important;
        border: thin solid currentColor;

        &:hover, &:focus {
          background-color: $secondary-container !important;
        }

        &:active {
          background-color: $secondary-container !important;
        }

          &:disabled, &[disabled] {
              background-color: $white !important;
              color: $grey-75 !important;
              border-color: $grey-75 !important;
          }
      }

      &.vasion-secondary-light {
          background-color: $white-24 !important;
          color: $white !important;
          border-radius: 4px;
          border: solid 1px $white;

          &:hover, &:focus {
              background-color: $white-12 !important;
          }

          &:active {
              background-color: $white-24 !important;
          }

          &:disabled, &[disabled] {
              background-color: $white-24 !important;
              color: $white-48 !important;
              border-color: $white;
          }
      }

      &.vasion-secondary-warning {
          background-color: $white !important;
          color: $red-300 !important;
          border-radius: 4px;
          border: solid 1px $red-300;

          &:hover, &:focus {
              background-color: $red-50 !important;
          }

          &:active {
              background-color: $red-75 !important;
          }

          &:disabled, &[disabled] {
              background-color: $white !important;
              color: $grey-75 !important;
              border-color: $grey-75;
          }
      }
      
      &.vasion-secondary-warning-text {
          background-color: $white !important;
          color: $red-300 !important;
          border-radius: 4px;

          &:hover, &:focus {
              background-color: $red-50 !important;
          }

          &:active {
              background-color: $red-75 !important;
          }

          &:disabled, &[disabled] {
              background-color: $white !important;
              color: $grey-75 !important;
              border-color: $grey-75;
          }
      }

      &.vasion-text {
          color: $on-surface-variant !important;
          border-bottom: solid 2px transparent !important;
          border-radius: unset;

          &:hover, &:focus {
            background-color: transparent !important;
          }

          &:active {
            background-color: transparent !important;;
          }

          &:disabled, &[disabled] {
            background-color: transparent !important;
              color: $grey-400 !important;
          }
      }

      &.vasion-text-selected {
          color: $primary !important;
          border-bottom: solid 2px $primary !important;
          border-radius: unset;

          &:hover, &:focus {
              background-color: transparent !important;
            }
            
            &:active {
            background-color: transparent !important;
          }

          &:disabled, &[disabled] {
              background-color: transparent !important;
              color: $on-surface-variant !important;
          }
      }

      &.vasion-text-light {
          background-color: $white-24 !important;
          color: $white !important;

          &:hover, &:focus {
              background-color: $white-12 !important;
          }

          &:active {
              background-color: $white-24 !important;
          }

          &:disabled, &[disabled] {
              background-color: $white-24 !important;
              color: $white-48 !important;
          }
      }

      &.vasion-text-black {
        font-family: $font-family-book;
        font-weight: 500;
        background-color: $white !important;
        color: $primary !important;

        &:hover, &:focus {
          background-color: $surface !important;
          color: $on-surface !important;
        }
      }

      &.vasion-text-transparent {
        background-color: transparent !important;
        color: $grey-400 !important;
        margin-bottom: 0;
        margin-left: 2px;
        margin-right: 2px;
        font-size: 12px !important;

        &:hover, &:focus {
          background-color: $primary-container !important;
          color: $on-primary-container !important;
          ::v-deep .md-button-content {
            color: $on-primary-container !important;
          }
        }

        &:disabled, &[disabled] {
            background-color: $white !important;
            color: $grey-400 !important;
        }
      }

      &.vasion-text-transparent-selected {
        background-color: $primary-container !important;
        color: $on-primary-container !important;
        margin-bottom: 0;
        margin-left: 2px;
        margin-right: 2px;
        font-size: 12px !important;

        &:hover, &:focus {
          background-color: $primary-container !important;
        }

        &:disabled, &[disabled] {
            background-color: $white !important;
            color: $grey-400 !important;
        }
      }

      &.vasion-tab {
        margin-bottom: 0;
        margin-left: 2px;
        margin-right: 2px;
        color: $primary !important;
        background-color: transparent !important;

          &:hover {
            color: $primary !important;
            background-color: $bg-primary-8 !important;
          }

          &:active, &:visited, &:focus {
            color: $primary !important;
            background-color: $bg-primary-12 !important;
          }

          &:disabled, &[disabled] {
            background-color: transparent !important;
            color: $outline !important;
          }
      }

      &.vasion-tab-selected {
          margin-bottom: 0;
          margin-left: 2px;
          margin-right: 2px;
          color: $primary !important;
          background-color: $bg-primary-8 !important;

          &:active, &:visited, &:focus {
            background-color: $bg-primary-12 !important;
            color: $primary !important;
          }

          &:disabled, &[disabled] {
            background-color: transparent !important;
            color: $outline !important;
          }
      }
      &.vasion-tab-error-state {
          margin-bottom: 0;
          margin-left: 2px;
          margin-right: 2px;
          background-color: $error-red !important;
          color: $white !important;

          &:active, &:visited, &:focus {
              background-color: $error-red !important;
              color: $white !important;
          }

          &:disabled, &[disabled] {
              background-color: $white !important;
              color: $grey-400 !important;
          }
      }

      &.vasion-tab-error-state-selected {
          margin-bottom: 0;
          margin-left: 2px;
          margin-right: 2px;
          background-color: $error-red !important;
          color: $white !important;
          border-bottom: solid 2px $primary !important;

          &:active, &:visited, &:focus {
              background-color: $error-red !important;
              color: $white !important;
          }

          &:disabled, &[disabled] {
              background-color: $white !important;
              color: $grey-400 !important;
          }
      }
    }
}
</style>
