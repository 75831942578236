/* eslint-disable linebreak-style */
/* eslint-disable default-case */
/* eslint-disable consistent-return */

import { createAxiosInstance } from '@/services/axiosService'

const addAllTemplateTasksToDoc = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    return await axios.post('v1/Document/AddTemplateTasksToDoc', payload)
  } catch (error) {
    console.warn(error)
  }
}

const addWorkflowComment = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    return await axios.post('v1/Workflow/AddWorkflowCommentReturnAll', payload)
  } catch (error) {
    console.warn(error)
  }
}

const assignToWorkflow = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    return await axios.post('v1/Workflow/AssignToWF ', payload)
  } catch (error) {
    console.warn(error)
  }
}

const assignToWorkflowWithData = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    return await axios.post('v1/Workflow/AssignToWFWithData ', payload)
  } catch (error) {
    console.warn(error)
    return null
  }
}

const calculateDueDateAndSettings = async (apiToken, workflowId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Workflow/CalculateDueDateAndSettings?Value=${workflowId}`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const canPerformWFAction = async (apiToken, documentGUID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.get(`v1/Workflow/CanPerformWFAction?Value=${documentGUID}`)
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const copyWorkflow = async (apiToken, workflowId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: Value } = await axios.post('v1/Workflow/CopyWorkflow', {Value: workflowId})
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const deleteAutoStepSetting = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('v1/Workflow/DeleteWorkflowAutoStepSetting', {
      data: {
        workflowID: payload.workflowID,
        stepID: payload.stepID,
        settingNumbers: payload.settingNumbers,
      },
    })

    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const deletePrintedFile = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('v1/Download/DeletePrintFile', {
      data: payload,
    })

    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const deleteTaskList = async (apiToken, taskId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.delete('v1/Workflow/DeleteTaskList', {
      data: {
        Value: taskId,
      },
    })
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const deleteTemplateTask = async (apiToken, taskId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.delete('v1/Workflow/DeleteTemplateTask', {
      data: {
        Value: taskId,
      },
    })
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const deleteWorkflow = async (apiToken, workflowId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: Value } = await axios.delete('v1/Workflow/DeleteWorkflow', {
      data: {
        Value: workflowId,
      },
    })
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const deleteWorkflowStep = async (apiToken, stepId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('v1/Workflow/DeleteWorkflowStep ', {
      data: {
        Value: stepId,
      },
    })

    if (response?.data) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const downloadVasionDocumentsForPrint = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const getStatement = `v1/Download/WebDownloadForPrint?T=${payload.T}&BurnAnnotations=${payload.BurnAnnotations}&\
UserID=${payload.UserID}&IncludeWatermark=${payload.IncludeWatermark}&ExtractPages=${payload.ExtractPages}&\
ConvertToPDF=${payload.ConvertToPDF}&StreamPDF=${payload.StreamPDF}&IncludeHistory=${payload.IncludeHistory}`

    const response = await axios.get(getStatement)
    if (response?.data) {
      return response.data
    }
    return false
  } catch (error) {
    console.warn(error)
  }
}

const doWorkflowAction = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Workflow/TryAction', payload)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getAllTaskLists = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Workflow/GetAllTaskLists')
    if (response?.data?.Values) {
      return response.data.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const getAllTemplateTasks = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Values } } = await axios.get('v1/Workflow/GetAllTemplateTasks')
    return Values
  } catch (error) {
    console.warn(error)
  }
}

const getAllWorkflowsWithoutEForm = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Workflow/GetAllWorkflowsWithoutEForm')
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getCurrentApproverName = async (apiToken, documentId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    return await axios.get(`v1/Workflow/GetCurrentApproverName?Value=${documentId}`)
  } catch (error) {
    console.warn(error)
  }
}

const getMaxxFormListForWorkflow = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Document/LoadAllEFormsForWorkflowConfiguration?Value=${payload.workflowId}&FormID=${payload.formId}`)
    if (response?.data?.EFormCollection?.Values) {
      return response.data.EFormCollection.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const getTaskListWithData = async (apiToken, taskId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Workflow/GetTaskListWithData?Value=${taskId}`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getTemplateTaskText = async (apiToken, taskId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.get(`v1/Workflow/GetTemplateTaskText?Value=${taskId}`)
    return Value
  } catch (error) {
    console.warn(error)
  }
}


const getThirdPartyFolderList = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Workflow/GetThirdPartyFolderList', payload)
    if (response?.data && response.data?.FolderList && response.data.FolderList?.Values) {
      return response.data.FolderList.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const getWorkflowAdminList = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Workflow/GetFullWorkflowList')
    if (response?.data && response.data.Values) {
      return response.data.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const getWorkflowAutoStepDisplayData = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Workflow/GetAutoStepDisplay?workflowID=${payload.workflowID}&stepID=${payload.stepID}`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getWorkflowBusinessHours = async (apiToken, workflowId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Workflow/GetWorkflowBusinessHours?Value=${workflowId}`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getWorkflowEmailSettings = async (apiToken, workflowId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Workflow/GetWorkflowEmailSettings?Value=${workflowId}`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getWorkflowInactivitySettings = async (apiToken, workflowId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Workflow/GetWorkflowInactivitySettings?Value=${workflowId}`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getWorkflowMenuItems = async (apiToken) => {
  if (!apiToken) {
    return
  }
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Workflow/UserWorkflowList', {})
    if (response?.data && response.data.Values) {
      return response.data.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const getWorkflowMenuItemsFiltered = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Workflow/WFListFilter', payload)
    if (response?.data && response.data.Values) {
      return response.data.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const getWorkflowPriorities = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get('v1/Document/GetDocumentPriorityList')
    if (data?.documentPriorities) {
      return data.documentPriorities
    }
  } catch (error) {
    console.warn(error)
  }
}

const getWorkflowRecentDocuments = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get(`v1/Document/GetMyRecentDocuments?pastDaysCount=${payload.pastDaysCount}&skip=${payload.skip}&returnCount=${payload.returnCount}&sortBy=${payload.sortBy}&descSortOrder=${payload.descSortOrder}`)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getWorkflowSecurity = async (apiToken, workflowId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Workflow/GetWorkflowSecurity?Value=${workflowId}`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getWorkflowSettings = async (apiToken, workflowId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Workflow/GetWorkflowSettings?Value=${workflowId}`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getWorkflowStepsTasksOptions = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Workflow/GetWorkflowStepsTasksOptions')
    if (response?.data?.Values) {
      return response.data.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const getWorkflowUserAssignedSummary = async (apiToken, workflowId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Workflow/GetWorkflowUserAssignedSummary?Value=${workflowId}`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getWorkStepHeaders = async (apiToken, workflowId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Workflow/GetWorkflowStepSummary?Value=${workflowId}`)
    if (response?.data?.Values) {
      return response.data.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const loadStartWorkflowPathData = async (apiToken, payload) => {
  if (!apiToken) {
    return
  }
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/GetVasionWorkflowAction', payload)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const loadMyEFormDraftsWorkflow = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get(`v1/Document/LoadMyEFormDraftsWorkflow`)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const loadWorkspaceDataTable = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Workflow/LoadWorkspaceDataTable', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const postWorkflowBusinessHours = async (apiToken, request) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Workflow/SaveWorkflowBusinessHours', request)
    if (response.data === 'Value') {
      getWorkflowBusinessHours(apiToken, request.workflowID)
    }
  } catch (error) {
    console.warn(error)
  }
}

const postWorkflowInactivitySettings = async (apiToken, request) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Workflow/SaveWorkflowInactivitySettings', request)
    if (data?.Value === 'True') {
      getWorkflowInactivitySettings(apiToken, request.workflowId)
      return true
    }
  } catch (error) {
    console.warn(error)
  }

  return false
}

const postWorkflowSecurity = async (apiToken, request) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Workflow/SaveWorkflowSecurity', request)
    return data?.Value === 'True'
  } catch (error) {
    console.warn(error)
  }

  return false
}

const postWorkflowUserAssignedSummary = async (apiToken, request) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Workflow/SaveWorkflowUserAssignedSummary', request)
    if (data?.Value === 'True') {
      getWorkflowUserAssignedSummary(apiToken, request.workflowId)
      return true
    }
  } catch (error) {
    console.warn(error)
  }

  return false
}

const reassignWorkflow = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: response } = await axios.post('v1/Workflow/MassReassign', payload)
    return response
  } catch (error) {
    console.warn(error)
  }
}

const runWorkflowBulkUpdate = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Workflow/WorkflowBulkUpdate', payload)
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const saveAllWorkflowAutoStepSettings = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Workflow/SaveAllWorkflowAutoStepSettings', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const saveAllWorkflowSteps = async (apiToken, request) => {
  const axios = createAxiosInstance(apiToken)
  try {
    return await axios.post('v1/Workflow/SaveAllWorkflowStepSummary', request)
  } catch (error) {
    console.warn(error)
  }
}

const saveTaskList = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Workflow/SaveTaskList', payload)
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const saveTemplateTask = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Workflow/SaveTemplateTask', payload)
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const saveWorkflowAutoStepSetting = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Workflow/SaveWorkflowAutoStepSetting', payload)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveWorkflowDueDate = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Workflow/SaveDocumentDueDate', payload)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveWorkflowEmailSettings = async (apiToken, request) => {
  const axios = createAxiosInstance(apiToken)
  try {
    return await axios.post('v1/Workflow/SaveWorkflowEmailSettings', request)
  } catch (error) {
    console.warn(error)
  }
}

const saveWorkflowSettings = async (apiToken, request) => {
  const axios = createAxiosInstance(apiToken)
  try {
    return await axios.post('v1/Workflow/SaveWorkflowSettings', request)
  } catch (error) {
    console.warn(error)
  }
}

const saveWorkflowSteps = async (apiToken, request) => {
  const axios = createAxiosInstance(apiToken)
  try {
    return await axios.post('v1/Workflow/SaveWorkflowStepSummary', request)
  } catch (error) {
    console.warn(error)
  }
}

const shouldOpenInDataView = async (apiToken, docIDorGUID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Workflow/ShouldOpenInDataView?Value=${docIDorGUID}`)
    if (response?.data) {
      return response.data.Value === 'True'
    } else {
      return false
    }
  } catch (error) {
    console.warn(error)
  }
}

const uploadWorkflowAutoSignatureFile = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Upload/UploadWFAutoSigTemplateFile', payload)
    return Value
  } catch (error) {
    console.warn(error)
  }

  return false
}

export {
  addAllTemplateTasksToDoc,
  addWorkflowComment,
  assignToWorkflow,
  assignToWorkflowWithData,
  canPerformWFAction,
  calculateDueDateAndSettings,
  copyWorkflow,
  deleteAutoStepSetting,
  deletePrintedFile,
  deleteTaskList,
  deleteTemplateTask,
  deleteWorkflow,
  deleteWorkflowStep,
  downloadVasionDocumentsForPrint,
  doWorkflowAction,
  getAllTaskLists,
  getTemplateTaskText,
  getThirdPartyFolderList,
  getAllWorkflowsWithoutEForm,
  getAllTemplateTasks,
  getCurrentApproverName,
  getMaxxFormListForWorkflow,
  getTaskListWithData,
  getWorkflowAdminList,
  getWorkflowAutoStepDisplayData,
  getWorkflowBusinessHours,
  getWorkflowInactivitySettings,
  getWorkflowEmailSettings,
  getWorkflowMenuItems,
  getWorkflowMenuItemsFiltered,
  getWorkflowPriorities,
  getWorkflowRecentDocuments,
  getWorkflowUserAssignedSummary,
  getWorkflowSettings,
  getWorkflowSecurity,
  getWorkStepHeaders,
  getWorkflowStepsTasksOptions,
  loadMyEFormDraftsWorkflow,
  loadStartWorkflowPathData,
  loadWorkspaceDataTable,
  postWorkflowBusinessHours,
  postWorkflowInactivitySettings,
  postWorkflowSecurity,
  postWorkflowUserAssignedSummary,
  reassignWorkflow,
  runWorkflowBulkUpdate,
  saveAllWorkflowAutoStepSettings,
  saveTaskList,
  saveTemplateTask,
  saveWorkflowAutoStepSetting,
  saveWorkflowDueDate,
  saveWorkflowEmailSettings,
  saveWorkflowSettings,
  saveWorkflowSteps,
  saveAllWorkflowSteps,
  shouldOpenInDataView,
  uploadWorkflowAutoSignatureFile,
}
