<template>
  <!-- eslint-disable-next-line vue/no-template-shadow -->
  <div slot-scope="{ field }" :class="{'thing': !isInDocumentView && !fixedSize, 'top-space': isInDocumentView && !fixedSize, 'field-for-card': fixedSize}">
    <div v-if="field.type === 1 || field.type === 14" class="text">
      <VasionInput
        v-model="filterValue"
        :required="required"
        class="input-style"
        :class="{'data-view-input-style': !isInDocumentView}"
        inputType="top-white"
        :title="field.name"
        :isDisabled="isDisabled || field.isDisabled || isInSignatureMode"
        :readonly="field.readOnly"
        :specialType="field.mask==1 ? 'SSN':''"
        :maxLengthProp="field.fieldLength"
        width="100%"
        :cusutomClass="customInputClass"
        :hoverText="hoverInputText"
        @click="fieldClicked"
        @input="emitDirty(); checkIfRequiredAndEmpty(filterValue);"
        @notValid="emitNotValid"
        @isValid="emitIsValid"
      />
    </div>
    <div v-if="isDropDownUI" class="dropdown">
      <VasionDropList
        v-slot="slotItem"
        ref="fieldDropList"
        v-model="selectedItem"
        :forceChipClickEvent="true"
        :returnValueOnly="true"
        :required="required"
        :dataArray="field.dropdownValues"
        :isDisabled="isDisabled || field.isDisabled || isInSignatureMode || field.readOnly"
        :title="field.name"
        :type="useMultiSelectLists && field.allowMultiples ? 'check-list' : 'plain-list'"
        :valueName="useMultiSelectLists && field.allowMultiples ? 'value' : 'name'"
        :class="{'data-view-input-style': !isInDocumentView}"
        :showIfEmpty="true"
        displayName="name"
        width="100%"
        @input="emitDirty(); checkIfRequiredAndEmpty(selectedItem);"
        @chipClick="onDropdownClick"
        v-on="$listeners"
      >
        {{ slotItem.item.name }}
      </VasionDropList>
    </div>
    <div
      v-if="field.type === 3 && !isInSignatureMode"
      :class="{
        'text' : dateFieldIsDisabled,
        'date' : !dateFieldIsDisabled
      }"
    >
      <div v-if="dateFieldIsDisabled">
        <label class="vasion-input-label-top">{{ field.name }}</label>
        <span v-if="required" class="vasion-required-indicator">*</span>
        <VasionInput
          v-model="filterValue"
          :required="required"
          class="input-style"
          :class="{'data-view-input-style': !isInDocumentView}"
          inputType="top-white"
          width="100%"
          :readonly="field.readOnly"
          :isDisabled="true"
          :cusutomClass="customInputClass"
          :hoverText="hoverInputText"
          @click="fieldClicked"
        />
      </div>
      <!-- Make this readonly too: -->
      <VasionDateTimeField
        v-else
        v-model="filterValue"
        :label="field.name"
        :cusutomClass="customInputClass"
        :hoverText="hoverInputText"
        type="date"
        @close="emitDirty(); checkIfRequiredAndEmpty(filterValue);"
        @input="emitDirty(); checkIfRequiredAndEmpty(filterValue);"
        @open="fieldClicked"
      />
    </div>
    <div v-if="field.type === 3 && isInSignatureMode" class="text">
      <VasionInput
        v-model="filterValue"
        :required="required"
        class="input-style"
        :class="{'data-view-input-style': !isInDocumentView}"
        inputType="top-white"
        width="100%"
        :isDisabled="true"
        :readonly="field.readOnly"
        :title="field.name"
        :cusutomClass="customInputClass"
        :hoverText="hoverInputText"
        @click="fieldClicked"
      />
    </div>
    <div v-if="field.type === 6" class="text">
      <VasionInput
        :initialValue="filterValue"
        :required="required"
        class="input-style"
        :class="{'data-view-input-style': !isInDocumentView}"
        inputType="top-white"
        width="100%"
        :readonly="field.readOnly"
        :isDisabled="isDisabled || field.isDisabled || isInSignatureMode"
        :title="field.name"
        specialType="number"
        :showCommas="field.showCommas"
        :decimalPlaces="field.decimalPlaces"
        :cusutomClass="customInputClass"
        :hoverText="hoverInputText"
        :maxLengthProp="field.fieldLength"
        @click="fieldClicked"
        @input="[updateValue($event), emitDirty(), checkIfRequiredAndEmpty($event)]"
        @notValid="emitNotValid"
        @isValid="emitIsValid"
      />
    </div>
    <div v-if="field.type === 7" class="text">
      <VasionInput
        v-model="filterValue"
        :required="required"
        class="input-style"
        :class="{'data-view-input-style': !isInDocumentView}"
        inputType="top-white"
        width="100%"
        specialType="url"
        :readonly="field.readOnly"
        :isDisabled="isDisabled || field.isDisabled || isInSignatureMode"
        :title="field.name"
        :cusutomClass="customInputClass"
        :hoverText="hoverInputText"
        :maxLengthProp="field.fieldLength"
        @click="fieldClicked"
        @input="emitDirty(); checkIfRequiredAndEmpty(filterValue);"
        @notValid="emitNotValid"
        @isValid="emitIsValid"
      />
    </div>
    <div v-if="field.type === 8" class="text">
      <VasionInput
        v-model="filterValue"
        :required="required"
        class="input-style"
        :class="{'data-view-input-style': !isInDocumentView}"
        inputType="top-white"
        width="100%"
        :readonly="field.readOnly"
        :isDisabled="isDisabled || field.isDisabled || isInSignatureMode"
        :title="field.name"
        specialType="email"
        :cusutomClass="customInputClass"
        :hoverText="hoverInputText"
        :maxLengthProp="field.fieldLength"
        @click="fieldClicked"
        @input="emitDirty(); checkIfRequiredAndEmpty(filterValue);"
        @notValid="emitNotValid"
        @isValid="emitIsValid"
      />
    </div>
    <div v-if="field.type === 11" class="text">
      <VasionInput
        v-model="filterValue"
        :required="required"
        class="input-style"
        :class="{'data-view-input-style': !isInDocumentView}"
        inputType="top-white"
        width="100%"
        :readonly="field.readOnly"
        :isDisabled="isDisabled || field.isDisabled || isInSignatureMode"
        :title="field.name"
        specialType="currency"
        :currencyFormat="field.currencyFormat"
        :cusutomClass="customInputClass"
        :hoverText="hoverInputText"
        :maxLengthProp="field.fieldLength"
        @click="fieldClicked"
        @input="emitDirty(); checkIfRequiredAndEmpty(filterValue);"
        @notValid="emitNotValid"
        @isValid="emitIsValid"
      />
    </div>
    <div v-if="field.type === 12" class="text">
      <VasionInput
        v-model="filterValue"
        :required="required"
        class="input-style"
        :class="{'data-view-input-style': !isInDocumentView}"
        inputType="top-white"
        width="100%"
        :readonly="field.readOnly"
        :isDisabled="isDisabled || field.isDisabled || isInSignatureMode || (!isInSearch && field.filterValue.length > 0)"
        :title="field.name"
        specialType="email"
        :cusutomClass="customInputClass"
        :hoverText="hoverInputText"
        :maxLengthProp="field.fieldLength"
        @click="fieldClicked"
        @input="emitDirty(); checkIfRequiredAndEmpty(filterValue);"
        @notValid="emitNotValid(field)"
        @isValid="emitIsValid(field)"
      />
    </div>
    <div v-if="field.type === 13">
      <VasionInput
        v-model="filterValue"
        :required="required"
        class="input-tall"
        :class="{'data-view-input-style': !isInDocumentView}"
        inputType="top-white"
        :readonly="field.readOnly"
        :isDisabled="isDisabled || field.isDisabled || isInSignatureMode"
        :title="field.name"
        specialType="textarea"
        :cusutomClass="customInputClass"
        :hoverText="hoverInputText"
        :maxLengthProp="field.fieldLength"
        @click="fieldClicked"
        @input="emitDirty(); checkIfRequiredAndEmpty(filterValue);"
        @notValid="emitNotValid"
        @isValid="emitIsValid"
      />
    </div>
  </div>
</template>

<script>
import { getMeanConfidenceColor } from '@/enums/objectFieldMeanConfidenceLevels.js'
import { formatDateTime } from '@/store/helperModules/common.module'

export default {
    name: 'AttributeFieldFilterItem',
    props: {
      isDisabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      field: {
        type: Object,
        required: true,
      },
      fixedSize: {
        type: Boolean,
        required: false,
        default: false,
      },
      forPrompt: {
        type: Boolean,
        required: false,
        default: false,
      },
      isInDocumentView: {
        type: Boolean,
        required: false,
        default: false,
      },
      isInSearch: {
        type: Boolean,
        required: false,
        default: false,
      },
      showErrorState: {
        type: Boolean,
        required: false,
        default: false,
      },
      useMultiSelectLists: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        customInputClass: '',
        hoverInputText: '',
        filterValue: '',
        isDirty: false,
        selectedItem: [],
      }
    },
    computed: {
      dateFieldIsDisabled() { return this.isDisabled || (this.field.isDisabled && this.isInSignatureMode) },
      isDropDownUI() { return this.field.type === 2 || this.field.type === 9 || this.field.type === 10 },
      isInSignatureMode() { return this.$route.name !== 'SignatureEditDocument' && this.$store.state.mainViews.activeSubApp === 'signatures' },
      required() { return this.field.required === true },
    },
    watch: {
      field: {
        handler: function () {
          this.updateCurrentValue()
        },
        deep: true,
      },
      filterValue: function () {
        let newValue = this.filterValue

        if (this.field.type === 3) {
          newValue = formatDateTime(newValue, 'date', 'yyyy/MM/DD')
          if (newValue === 'Invalid date') newValue = this.filterValue
          if (this.filterValue !== this.field.filterValue) {
            this.$emit('filterupdated', { name: this.field.value, type: this.field.type, value: newValue })
          }
        } else {
          this.$emit('filterupdated', { name: this.field.value, type: this.field.type, value: newValue })
        }
      },
      selectedItem: function () {
        let selectedValue
        if (this.useMultiSelectLists) {
          selectedValue = this.selectedItem
        } else {
          selectedValue = this.selectedItem && this.selectedItem.name ? [this.selectedItem.name] : ['']
        }
        this.$emit('filterupdated', {
          name: this.field.value,
          type: this.field.type,
          value: selectedValue,
        })
      },
    },
    async created() {
      this.$material.locale.dateFormat = this.$store.state.systemSettings.systemDateFormat
      await this.updateCurrentValue()
      this.populateMultiSelectDropList()
      this.checkIfRequiredAndEmpty(this.filterValue)
      this.$emit('clean')
    },
    methods: {
      checkIfRequiredAndEmpty(value) {
        if (this.forPrompt && this.field.required) {
          if (!value || (typeof value === 'object' && Object.keys(value).length === 0)) {
            this.$emit('isRequiredAndEmpty', { name: this.field.name, empty: true })
          } else {
            this.$emit('isRequiredAndEmpty', { name: this.field.name, empty: false })
          }
        }
      },
      clearDate() {
        this.filterValue = ''
        this.emitDirty()
      },
      emitDirty() {
        this.isDirty = true
        this.$emit('dirty')
      },
      emitIsValid(field) {
        if (field) {
          this.$emit('isValid', field)
          return
        }
        this.$emit('isValid')
      },
      emitNotValid(field) {
        if (field) {
          this.$emit('notValid', field)
          return
        }
        this.$emit('notValid')
      },
      async fieldClicked() {
        this.$emit('fieldClicked', this.field)
      },
      async onDropdownClick() {
        if (this.filterValue !== "") {
          this.$emit('filterupdated', { name: this.field.value, type: this.field.type, value: this.filterValue })
        }
      },
      populateMultiSelectDropList() {
        if (this.useMultiSelectLists) {
          const control = this.$refs.fieldDropList
          if (control) {
            control.selectedArrayLocal = this.selectedItem ? this.selectedItem : []
          }
        }
      },
      async updateCurrentValue() {
        if (this.field && this.field.filterValue) {
          if (this.filterValue !== this.field.filterValue) {
            if (this.field.type === 3 && Array.isArray(this.field.filterValue)) {
              this.filterValue = this.field.filterValue.length === 0 ? '' : this.field.filterValue[0]
            } else {
              this.filterValue = this.field.filterValue
            }
          }

          if (this.isDropDownUI && this.field.dropdownValues && Array.isArray(this.filterValue) && this.filterValue.length > 0) {
            if (this.useMultiSelectLists) {
              this.selectedItem = this.filterValue.map((value) => {
                const foundItem = this.field.dropdownValues.find((element) => {
                  return element.name === value
                })
                return {
                  name: foundItem?.name,
                  value: foundItem?.value,
                }
              }).filter(el => el.name !== undefined)
            } else {
              const foundItem = this.field.dropdownValues.find((element) => {
                return element.name === this.filterValue[0]
              })

              if (foundItem && this.selectedItem !== foundItem) {
                this.selectedItem = foundItem
              }
            }
          }
          else if (this.isDropDownUI && this.field.dropdownValues) {
            const foundItem = this.field.dropdownValues.find((element) => {
                return element.name === this.filterValue
            })

            if (foundItem && this.selectedItem !== foundItem) {
              this.selectedItem = foundItem.name
            }
            else {
              this.selectedItem = ''
            }
          }

          if (this.field?.meanConfidence) {
            const color = getMeanConfidenceColor(this.field.meanConfidence)
  
            if (color === 'LawnGreen') {
              this.customInputClass = 'success'
            } else if (color === 'Yellow') {
              this.customInputClass = 'warning'
            } else if (color === 'Red') {
              this.customInputClass = 'error'
            } else {
              this.customInputClass = ''
            }

            this.hoverInputText = `${this.field.meanConfidence}% Confidence`
          }
        }
      },
      updateValue(newValue) {
        this.$emit('filterupdated', { name: this.field.value, type: this.field.type, value: newValue })
      },
    },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .input-tall {
    height: 120px;
  }

  .thing {
    width: 100%;
  }

  @media(max-width: $tablet){
    .thing{
      width: 100%;
    }
  }

  .top-space {
    margin: 15px 0 0 0;
    width: 100%;
  }

  .data-view-input-style {
    padding-bottom: 15px;
  }

  .field-for-card {
    width: 300px;
  }
</style>
