<template>
  <div class="download-options-div">
    <div class="download-options-title">
      <h2>Print Documents</h2>
    </div>
    <div class="download-options-checkboxes">
      <div class="checkbox-burn-annotations">
        <VasionCheckbox
        v-show="supportBurnAnnotations"
          id="burn-annotations-checkbox"
          name="burn-annotations-checkbox"
          class="download-checkbox"
          :checked="burnAnnotations"
          @change="toggleBurnAnnotations"
        >
          Burn Annotations
        </VasionCheckbox>
      </div>
      <div class="checkbox-include-sig-doc-history">
        <VasionCheckbox
          v-show="supportIncludeHistory"
          id="chk-include-sig-doc-history"
          name="chk-include-sig-doc-history"
          :checked="includeSigDocHistory"
          @change="toggleIncludeSigDocHistory"
        >
          Include Signature Doc History
        </VasionCheckbox>
      </div>
    </div>
    <div class="dialog-footer-div">
      <VasionButton id="download-cancel-button" :classProp="'secondary'" @vasionButtonClicked="cancelClick()">
        Cancel
      </VasionButton>
      <VasionButton id="download-ok-button" :classProp="'primary'" @vasionButtonClicked="okClick()">
        PRINT
      </VasionButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VasionPrintOptions',
  props: {
    multipleDocuments: {
      type: Boolean,
      default: false,
      required: false,
    },
    supportBurnAnnotations: {
      type: Boolean,
      required: false,
      default: false,
    },
    supportIncludeHistory: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      burnAnnotations: false,
      extractPages: '',
      includeSigDocHistory: false,
    }
  },
  computed: {
  },
  methods: {
    cancelClick() {
      this.multipleDocsLocal = false
      this.$emit('cancelClick')
    },
    okClick() {
      const payload = {
        burnAnnotations: this.burnAnnotations,
        includeSigDocHistory: this.includeSigDocHistory,
      }
      this.$emit('okClick', payload)
    },
    toggleBurnAnnotations() {
      this.burnAnnotations = !this.burnAnnotations
    },
    toggleIncludeSigDocHistory() {
      this.includeSigDocHistory = !this.includeSigDocHistory
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  .checkbox-burn-annotations, .checkbox-include-sig-doc-history {
    width: 459px;
  }
  .download-options-div {
    padding: 24px;
    width: 928px;
    height: 286px;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 $box-shadow;
    background: $white;
  }
  .download-options-checkboxes {
    display: flex;
    flex-direction: row;
    margin: 24px 0px;
  }
  .download-checkbox {
    margin-bottom: 10px;
  }
  .dialog-footer-div {
    position: absolute;
    bottom: 12px;
    width: calc(100% - 44px);
    text-align: right;
    vertical-align: middle;
    line-height: 50px;
  }
</style>
