<template>
  <div>
    <VasionGeneralModal
      modalType="slot"
      :sync="showThisDialog"
      :confirmButtonDisabled="(!areRequiredFieldsFilled || !isModalFormValid)"
      :confirmButtonText="confirmButtonText"
      :rejectButtonText="rejectButtonText"
      :showTopRightCloseButton="true"
      :modalTitle="fullTitle"
      @confirmButtonClick="confirmButtonClicked"
      @noButtonClick="rejectButtonClicked"
    >
      <div id="doc-object-modal-container">
        <div id="modalBody">
          <div id="doc-object-modal-left">
            <VasionDropList
              v-if="allowObjectSelection"
              id="default-object"
              v-slot="slotItem"
              v-model="currentForm"
              title="Default Object"
              placeholder="Select the Default Object..."
              name="default-object"
              type="plain-list"
              :dataArray="objectsList"
              valueName="value"
              displayName="name"
              :width="'100%'"
              :required="false"
              :isDisabled="false"
              @input="defaultObjectChanged"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
            <IndexFieldList
              ref="attributeFieldsComponent"
              :disableAllFields="false"
              :fields="indexFieldListWithoutWorkflowFields"
              :enforceFieldSecurity="true"
              :selectorOnly="false"
              :showSubmit="false"
              :fieldWidth="225"
              :isInDocumentView="true"
              :useMultiSelectLists="true"
              @fieldValueUpdated="updateLocalFieldValues"
              @notValid="markNotValid"
              @isValid="markIsValid"
            />
          </div>
          <div id="doc-object-modal-right">
            <div class="no-pointer-events document-loaded " :class="{ 'no-image': noImageLoaded }">
              <DocumentImage
                v-if="isDocumentLoaded"
                :key="currentPageNumber"
                ref="mainImage"
                :burnAnnotations="true"
                :disableMoveAndSaveAnnotations="disableSaveAnnotations"
                :documentID="documentId"
                :pageNumber="currentPageNumber"
                :width="378"
                :height="492"
                :context="'SignatureApp'"
                :imageEndpoint="imageEndpoint"
                :smallImage="false"
                :fileDetail="fileDetails" 
                :filePath="documentFilePath"
                @noImage="noImage"               
              />
            </div>
            <div id="toolbar-div" class="input-t-center">
              <!-- PAGINATION -->
              <VasionPager
                :currentPageNumber="currentPageNumber"
                :showPageSize="false"
                :totalPages="totalPages"
                @pageChanged="goToPage"
              />
            </div>
          </div>
        </div>
      </div>
    </VasionGeneralModal>
  </div>
</template>
  
<script>
  import IndexFieldList from '@/components/IndexFieldList.vue';
  import DocumentImage from '@/components/document/DocumentImage.vue';
  
  export default {
    name: 'DocumentObjectModal',
    components: {
      IndexFieldList,
      DocumentImage,
    },
    props: {
      allowObjectSelection: {
        type: Boolean,
        required: false,
        default: false,
      },
      confirmButtonText: {
        type: String,
        required: false,
        default: 'Save',
      },
      disableSaveAnnotations: {
        required: false,
        type: Boolean,
        default: false,
      },
      documentFilePath: {
        type: String,
        required: false,
        default: '',
      },
      documentId: {
        type: Number,
        required: false,
        default: 0,
      },
      formId: {
        type: Number,
        required: true,
      },
      imageEndpoint: {
        type: String,
        required: false,
        default: 'document/getFileImageURL',
      },
      rejectButtonText: {
        type: String,
        required: false,
        default: 'Cancel',
      },
      show: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        required: false,
        default: '',
      },
      titleShowDocumentName: {
        type: Boolean,
        required: false,
        default: false
      },
    },
    data: function () {
      return {
        currentForm: {},
        currentFormId: 0,
        currentPageNumber: 1,
        documentName: '',
        fieldValues: [],
        fileDetails: {},
        isDocumentLoaded: false,
        isFormValue: false,
        isModalFormValid: true,
        localFieldValues: [],
        noImageLoaded: false,
        requiredValues: [],
        showThisDialog: false,
        totalPages: 0,
      }
    },
    computed: {
      areRequiredFieldsFilled() {
        let result = true;
        if (this.indexFieldListWithoutWorkflowFields)
        {
          this.indexFieldListWithoutWorkflowFields.forEach(field => {
            if (field.required)
            {
              let found = false;
              if (this.localFieldValues)
              {
                for (let i = 0; i< this.localFieldValues.length; i++) {
                  if (this.localFieldValues[i].name === field.value) {
                    found = true
                    if (this.localFieldValues[i].value.length === 0) {
                      result = false
                    }
                  }
                }
              }
              if (!found) {
                result = false
              }
            }
          })
        }
        return result
      },
      fullTitle() { return (this.titleShowDocumentName) ? (this.documentName + '   ' + this.title) : this.title },
      indexFieldList() { return this.$store.state.common.indexFields },
      indexFieldListWithoutWorkflowFields() {
        return this.indexFieldList.filter((field, index, arr) => {
          if (this.$route.name === 'SignatureEditDocument' || this.$route.name === 'VaultFolders') arr[index].readOnly = false
          return field?.value !== 'Workflow_Approver' && field?.value !== 'Workflow_Initiator' && field?.value !== 'Workflow_Due_Date' && field?.value !== 'Workflow_Status' && field?.value !== 'CreatedBy'
        })
      },
      isObjectSelected() { return this.attributeForm && this.attributeForm.value },
      objectsList() {
        return this.$store.state.attributeForm.forms.map((f) => {
          return {
            name: f.name,
            value: f.value,
          }
        })
      },
   },
    watch: {
      async documentId() {
        if (this.documentId === 0) return

        this.loadCurrentDocument(this.documentId)
        this.currentPageNumber = 1
        await this.getFormInputs()
        this.$refs.attributeFieldsComponent.clearIndexFields()
      },
      async show() {
        this.showThisDialog = this.show

        if (this.showThisDialog) {
          this.currentFormId = this.formId
          await this.$store.dispatch('attributeForm/getForms')
          this.currentForm = this.objectsList.find(val => val.value == this.currentFormId)
          this.getFormInputs()
        }
      }
    },
    async created() {
      if (this.documentFilePath !== '') {
        const pageCountpayload = {
          Value: this.documentFilePath,
        }
        this.totalPages = Number(await this.$store.dispatch('document/getFilePageCount', pageCountpayload))
        this.isDocumentLoaded = true
      }
    },
    methods: {
      closeDialogClicked() {
        this.$emit('closeDialogClicked')
      },
      confirmButtonClicked() {
        this.$emit('confirmButtonClicked', {
            formId: this.currentFormId, 
            fieldValues: this.getLocalIndexFieldValuePairs(),
          })
      },
      defaultObjectChanged() {
        this.currentFormId = this.currentForm.value
        this.getFormInputs()
      },
      getLocalIndexFieldValuePairs() {
        if (!this.localFieldValues) {
          return []
        }

        return this.localFieldValues.map(e => {
          if (Array.isArray(e.value)) {
            const dropdownValues = []
            e.value.forEach(v => {
              dropdownValues.push(v.name)
            })
            const dropDownSingleString = dropdownValues.join(';')
            return {
              Key: e.name,
              Value: dropDownSingleString,
            }
          } else {
            return {
              Key: e.name,
              Value: e.value,
            }
          }
        })
      },
      async getFormInputs() {
        await this.$store.dispatch('common/getIndexFieldsForForm', this.currentFormId)
      },
      goToPage(pageNumber) {
        this.noImageLoaded = false
        if (typeof pageNumber !== 'undefined' && pageNumber > 0 && pageNumber <= this.totalPages) {
          this.currentPageNumber = pageNumber
        }
      },
      async loadCurrentDocument(documentId) {
        const documentDetails = await this.$store.dispatch('document/getDocumentDetails', { documentID: documentId })
        this.fileDetails = documentDetails?.FileDetail
        this.documentName = documentDetails?.DisplayName
        this.totalPages = documentDetails?.FileDetail?.PageCount
        this.isDocumentLoaded = true
      },
      markIsValid() { this.isModalFormValid = true },
      markNotValid() { this.isModalFormValid = false },
      rejectButtonClicked(clickX) {
        if (clickX === true)
          this.$emit('closeDialogClicked')
        else
          this.$emit('rejectButtonClicked')
      },
      noImage() {
        this.noImageLoaded = true
      },
      updateLocalFieldValues(childComponentValues) {
        this.localFieldValues = childComponentValues
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .document-loaded {
    height: 492px;
    width: 378px;
  }
  
  .no-pointer-events {
    pointer-events: none;
  }
  
  .no-image {
    background-color: $grey-300 !important;
  }

  ::v-deep .modal-container {
    height: 75%;
    max-height: 745px;
    min-height: 600px;
    width: calc(100% - 500px);
    min-width: 600px;
    max-width: 1300px;
  }

  #doc-object-modal-container {
    display: flex;
    flex-direction: column;
    #modalBody {
      display: flex;
      height: 580px;
      width:100%;
      margin-top: 20px;
      flex-direction: row;
      #doc-object-modal-left {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 580px;
        min-width: 400px;
        overflow-y: auto;
        padding-right: 24px;
        margin-right: 5px;
        height: 100%;
      }
      #doc-object-modal-right {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 580px;
        width: 100%;
        min-width: 400px;
        max-width: 580px;
        border: 1px solid $grey-100;
        border-radius: 8px;
        #toolbar-div {
          margin-top: 16px;
        }
      }
    }
  }

  .input-t-center::v-deep input.text-input {
    text-align: center;
    padding-left: 0px;
  }
</style>
