<template>
  <div class="temp-space">
    <div class="signature-box">
      <VueSignaturePad
        ref="signaturePad"
        width="100%"
        height="200px"
        :options="signOptions"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Draw',
  props: {
    image: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: function () {
    return {
      signOptions: {
        onEnd: this.onEnd,
        dotSize: 2.5,
        minWidth: 3,
        maxWidth: 6,
        onBegin: this.onBegin,
      },
    }
  },
  mounted() {
    if (this.image) {
      this.resizeCanvas()
      this.$refs.signaturePad.fromDataURL(this.image);
    }
  },
  methods: {
    clear() { 
      //we "undo" our scaling and clear the signature
      this.$refs.signaturePad.resizeCanvas()
      this.$refs.signaturePad.clearSignature()
    },
    closeDialog() { this.$emit('closeDialog') },
    onBegin() {
        this.$refs.signaturePad.resizeCanvas()
    },
    onEnd() { this.$emit('hasSignature') },
    ref_clear() { 
      this.clear()
    },
    resizeCanvas() {
      //the built in resizeCanvas will scale up, but not down, from what I can tell, so this code will scale regardless when loading an existing image
      let ratio =  window.devicePixelRatio
      this.$refs.signaturePad.$refs.signaturePadCanvas.width = this.$refs.signaturePad.$refs.signaturePadCanvas.offsetWidth * ratio
      this.$refs.signaturePad.$refs.signaturePadCanvas.height = this.$refs.signaturePad.$refs.signaturePadCanvas.offsetHeight * ratio
      this.$refs.signaturePad.$refs.signaturePadCanvas.getContext("2d").scale(ratio, ratio)
      this.$refs.signaturePad.clearSignature()
    },
    trimCanvas() {
      //This  method came from https://github.com/szimek/signature_pad/issues/49
      //an alternative is a trim-canvas npm package
      const canvas = this.$refs.signaturePad.$refs.signaturePadCanvas
      
        let imgWidth = canvas.width;
        let imgHeight = canvas.height;
        const originalCtx = canvas.getContext('2d');
        let imageData = originalCtx.getImageData(0, 0, imgWidth, imgHeight),
        data = imageData.data,
        getAlpha = function(x, y) {
            return data[(imgWidth*y + x) * 4 + 3]
        },
        scanY = function (fromTop) {
            let offset = fromTop ? 1 : -1;

            // loop through each row
            for(let y = fromTop ? 0 : imgHeight - 1; fromTop ? (y < imgHeight) : (y > -1); y += offset) {

                // loop through each column
                for(let x = 0; x < imgWidth; x++) {
                    if (getAlpha(x, y)) {
                        return y;                        
                    }      
                }
            }
            return null; // all image is white
        },
        scanX = function (fromLeft) {
            let offset = fromLeft? 1 : -1;

            // loop through each column
            for(let x = fromLeft ? 0 : imgWidth - 1; fromLeft ? (x < imgWidth) : (x > -1); x += offset) {

                // loop through each row
                for(let y = 0; y < imgHeight; y++) {
                    if (getAlpha(x, y)) {
                        return x;                        
                    }      
                }
            }
            return null; // all image is white
        };

        let cropTop = scanY(true),
        cropBottom = scanY(false),
        cropLeft = scanX(true),
        cropRight = scanX(false);

        let relevantData = originalCtx.getImageData(cropLeft, cropTop, cropRight-cropLeft, cropBottom-cropTop);
        canvas.width = cropRight-cropLeft;
        canvas.height = cropBottom-cropTop;
        originalCtx.clearRect(0, 0, cropRight-cropLeft, cropBottom-cropTop);
        originalCtx.putImageData(relevantData, 0, 0);
    
    },
    saveSignature() {
      this.trimCanvas()
      const { data } = this.$refs.signaturePad.saveSignature();
      return data
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .signature-box {
    display: inline-block;
    border: 1px solid $grey-100;
    border-radius: 3px;
    height: 200px;
  }

  .temp-space {
    display: flex;
    flex-direction: column;
  }
</style>
