<template>
  <div>
    <table class="vasion-table">
      <tr class="headerRow">
        <th
          v-for="(header, index) in headerColumns"
          :key="index"
          :header="header"
          :md-label="header"
          class="table-header"
          :class="{ hideTableCell: !shouldShowColumn(header), setColumnMinWidth: !shouldSetColumnWidth(header), setColumnWidth: shouldSetColumnWidth(header), columnIconWidth: iconColumnHeaderWidth(header), workSpaceColumn: shouldSetWorkSpaceColumn(header), widerCheckboxColumn: isHeaderTextLong, widerColumns: hasManyColumns, singleWideColumn: shouldIncreaseColumnWidth(header) && hasManyColumns }"
          :style="tableColStyles[index]"
        >
          <div v-if="header === checkboxColumnHeader && !keepCheckedState" id="check-all" class="vasion-icon">
            <VasionCheckbox :id="'chkHeader'" :checked="displayChecked" :noLabel="true" @change="headerCheckChange()" />
          </div>
          <div v-else-if="header === checkboxColumnHeader" id="check-all" />
          <div v-else-if="displayHeaderText(header)" @click="sort(index)">
            <label :class="{'sort-by-label': orderByParams.indexValue === header}">{{ displayHeaderText(header) }}</label>
            <span v-if="supportSorting && orderByParams.indexValue === header">
              <VasionArrowDropDownIcon v-show="orderByParams.order === 'desc'" />
              <VasionArrowDropUpIcon v-show="orderByParams.order === 'asc'" />
            </span>
          </div>
        </th>
      </tr>
      <tr
        v-for="(item, rowIndex) in filteredRows"
        :id="`row_${rowIndex}`"
        :key="rowIndex"
        class="iconFillColor table-rows"
        :class="{ 'table-rows-even': rowIndex % 2 !== 0, 'clickable' : clickableRows }"
        @click="clickableRows && onSelect(item.Values, rowIndex)"
      >
        <td
          v-for="(value, columnIndex) in item.Values"
          :id="`column${columnIndex}`"
          :key="`emptyUser${columnIndex}`"
          class="table-data"
          :style="displayEmptyUser(rowIndex) + ' ' + tableColStyles[columnIndex]"
          :md-sort-by="item.name"
          :md-label="item.name"
          :class="{ hideTableCell: !shouldShowColumn(headerColumns[columnIndex]), setColumnWidth: shouldSetColumnWidth(headerColumns[columnIndex]), columnIconWidth: iconColumnHeaderWidth(headerColumns[columnIndex]), workSpaceColumn: shouldSetWorkSpaceColumn(headerColumns[columnIndex]) }"
        >
          <div
            v-if="dropDownIndeces.includes(columnIndex)"
            :key="`${value}`"
          >
            <VasionDropList
              :id="`name-droplist-${rowIndex}`"
              v-slot="slotItem"
              v-model="filteredRows[rowIndex].Values[columnIndex]"
              class="td-set-width"
              :dataArray="dropDownValues"
              type="plain-list"
              :displayName="'name'"
              :valueName="'name'"
              :value="value"
              @input="$emit('cell-change', {row: rowIndex, column: columnIndex, value: item.Values[columnIndex].name})"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>
          <div
            v-else-if="headerColumns[columnIndex] === iconColumnHeader"
            class="vasion-icon grid-container"
            @mouseover="mouseOnFile($event, rowIndex)"
            @mouseleave="mouseOffFile()"
          >
            <component :is="value" />
            <ThumbnailImage
              v-if="displayFile(rowIndex, item)"
              :width="150"
              :pageNumber="1"
              :documentID="item.Values[documentIDIndex]"
              :index="0"
              :style="thumbnailStyle"
            />
          </div>
          <div v-else-if="headerColumns[columnIndex] === iconButtonColumnHeader" class="vasion-icon-button" @click.stop="iconClick(item, columnIndex, rowIndex)">
            <component :is="value" class="icon" />
          </div>
          <div v-else-if="headerColumns[columnIndex] === iconDeleteColumnHeader" class="vasion-icon-button" @click.stop="deleteIconClick(rowIndex)">
            <component :is="value" class="icon" />
          </div>
          <div
            v-else
          >
            <VasionInput
              v-model="item.Values[columnIndex]"
              inputType="blank-white"
              class="vasion-table-text"
              :class="{ 'clickable' : clickableRows }"
              @change="$emit('cell-change', {row: rowIndex, column: columnIndex, value: item.Values[columnIndex]})"
            />
          </div>
        </td>
        <td
          v-for="(value, index) in item.Values"
          :id="`column${index}`"
          :key="index"
          class="table-data textarea-full-text"
          :style="displayNonEmptyUser(rowIndex) + ' ' + tableColStyles[index]"
          :md-sort-by="item.name"
          :md-label="item.name"
          :class="{ hideTableCell: !shouldShowColumn(headerColumns[index]), setColumnWidth: shouldSetColumnWidth(headerColumns[index]), workSpaceColumn: shouldSetWorkSpaceColumn(headerColumns[index]), columnIconWidth: iconColumnHeaderWidth(headerColumns[index]) }"
        >
          <div
            v-if="headerColumns[index] === iconColumnHeader"
            class="vasion-icon grid-container"
            @mouseover="mouseOnFile($event, rowIndex)"
            @mouseleave="mouseOffFile()"
          >
            <component :is="value" />
            <ThumbnailImage
              v-if="displayFile(rowIndex, item)"
              :width="150"
              :pageNumber="1"
              :documentID="item.Values[documentIDIndex]"
              :index="0"
              :style="thumbnailStyle"
            />
          </div>
          <div v-else-if="headerColumns[index] === iconButtonColumnHeader" class="vasion-icon-button" @click.stop="iconClick(item, index, rowIndex)">
            <component :is="value" class="icon" />
          </div>
          <div v-else-if="headerColumns[index] === ellipsisButtonColumnHeader && ellipsisButtonConfigLocal" class="vasion-icon-button vasion-ellipsis-button pl-flex-vertical-center" @click.stop="ellipsisOpen($event, item)">
            <component :is="value" />
          </div>
          <div v-else-if="headerColumns[index].startsWith(checkboxColumnHeader)" class="vasion-icon">
            <VasionCheckbox
              v-if="value.show && (!keepCheckedState || !value.value)"
              :id="`chkRow_${index}`"
              :checked="value.value"
              :noLabel="true"
              @change="lineItemCheckChange($event, value, item, index, rowIndex)"
              @checkbox-checked-oncreate="setCheckedOnCreate(value, item)"
            />
            <img
              v-else-if="value.show && keepCheckedState && value.value"
              class="checkbox-checked"
              src="@/assets/images/Checkbox-checked.png"
              alt="checked"
            >
          </div>
          <div v-else-if="headerColumns[index].startsWith(priorityColumnHeader)" class="vasion-icon">
            <VasionDropList
              v-slot="slotItem"
              v-model="item.priority"
              :class=" { isHigh : item.priority.value === 1, isNormal : item.priority.value === 2, isLow : item.priority.value === 3 }"
              width="110"
              :dataArray="priorityListOptions"
              :showSearchField="false"
              type="plain-list"
              valueName="value"
              displayName="name"
              @input="setPriority($event, item.Values[documentIDIndex])"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>
          <div v-else-if="headerColumns[index] === dueDateHeader" class="due-date-column" @click="dueDateClick($event, item.Values, value)">
            <div class="due-date-text-div">
              {{ value | formatDateToDisplay }}
            </div>
            <div class="due-date-icon-div">
              <VasionCalendarTodayIcon />
            </div>
          </div>
          <div v-else-if="headerColumns[index] === thumbnailColumHeader" class="image-preview-container">
            <img :src="value" class="image-preview">
          </div>
          <div v-else-if="typeof value === 'object' && value !== null && value.documentList">
            <span>{{ value.fieldValue }}</span>
            <br>
            <p
              v-for="(document, documentIndex) in value.documents" :key="documentIndex"
              class="docs-text"
              @mouseover="mouseOnFile($event, `${document.id}${documentIndex}`)"
              @mouseleave="mouseOffFile()"
            >
              <span v-if="document.id === value.currentDocId">{{ document.name }}</span>
              <a
                v-else
                :href="`${document.id}?fromPackage=true&packageId=${value.packageId}&documentId=${value.currentDocId}`"
              >{{ document.name }}</a>{{ `${documentIndex + 1 === value.documents.length ? "" : ", "}` }}
              <ThumbnailImage
                v-if="displayFile(`${document.id}${documentIndex}`, 'DisplayThumbnail')"
                :width="100"
                :pageNumber="1"
                :documentID="document.id"
                :index="0"
                :style="thumbnailStyle"
              />
            </p>
          </div>
          <div
            v-else
            :id="item.Values[4]"
            class="vasion-table-text"
            :style="{ 'max-width': maxTextLength }"
            :class="{ 'no-wrap' : !wrapCells, 'clickable' : clickableRows }"
            @mouseover="toolTipDisplay($event)"
            @mouseleave="toolTipClose($event)"
          >
            <span v-html="value" />
            <!-- We may need to reconsider how we use this next line. v-html allows for XSS attacks. Are we sanitizing the HTML? -->
            <span class="full-text" v-html="value" /> 
          </div>
        </td>
      </tr>
    </table>
    <div
      v-if="filteredRows.length < 1"
      class="no-records-message"
    >
      <p>No records found.</p>
    </div>
    <div v-if="showDueDateComponent" class="set-due-date-component-div" :style="dueDateDisplayTopStyle">
      <SetDocumentDueDate
        :propDocumentID="dueDateDocumentID"
        :propWorkflowID="dueDateWorkflowID"
        :propDueDate="dueDate"
        @okButtonClick="dueDateOKClick"
        @cancelButtonClick="dueDateCancelClick"
      />
    </div>

    <VasionEllipsisMenu
      v-if="showEllipsisMenu"
      v-click-outside="ellipsisClose"
      :buttonConfig="ellipsisButtonConfigLocal"
      :rowItem="ellipsisRowItem"
      :style="ellipsisMenuTopStyle"
      @ellipsis-button-clicked="ellipsisButtonClicked"
      @close="ellipsisClose"
    />

    <VasionSnackbar
      :showRawHtml="snackbar.showRawHtml" 
      :showSnackbarBool.sync="snackbar.show"
      :snackbarImage="snackbar.image"
      :snackbarSubTitle="snackbar.subtitle"
      :snackbarTitle="snackbar.title"
    />
  </div>
</template>

<script>
// eslint-disable-next-line no-restricted-globals
import SetDocumentDueDate from '@/components/workflow/SetDocumentDueDate.vue'
import ThumbnailImage from '@/components/document/ThumbnailImage.vue';
import { formatDateTime } from '@/store/helperModules/common.module'

export default {
  name: 'VasionTable',
  components: {
    SetDocumentDueDate,
    ThumbnailImage,
  },
  filters: {
    formatDateToDisplay(val) {
      if (!val || val === '') {
        return val
      }

      return formatDateTime(val, 'date')
    },
  },
  props: {
    blankUsers: {
      type: Array,
      default: () => [],
      required: false,
    },
    clickableRows: {
      type: Boolean,
      default: true,
      required: false,
    },
    disableShiftSelect: {
      type: Boolean,
      default: false,
      required: false,
    },
    // This is needed to let the Thumbnail Preview work
    documentIDIndex: {
      type: Number,
      default: 1,
      required: false,
    },
    dropDownIndeces: {
      type: Array,
      default: () => [],
      required: false,
    },
    dropDownValues: {
      type: Array,
      default: () => [],
      required: false,
    },
    ellipsisButtonConfig: {
      type: Object,
      default: null,
      required: false,
    },
    ellipsisButtonConfigFiltered: {
      type: Object,
      default: () => {},
      required: false,
    },
    ellipsisFilterCondition: {
      type: Function,
      default: () => false,
      required: false,
    },
    ellipsisOffset: {
      type: Number,
      default: 0,
      required: false,
    },
    filterByColumnDefault: {
      type: Number,
      default: 0,
      required: false,
    },
    floatingThumbnail: {
      type: Boolean,
      default: false,
      required: false,
    },
    headerColumns: {
      type: Array,
      default: () => [],
      required: false,
    },
    hideColumns: {
      type: Array,
      default: () => [],
      required: false,
    },
    keepCheckedState: {
      type: Boolean,
      default: false,
      required: false,
    },
    maxTextLength: {
      type: String,
      default: '100%',
      required: false,
    },
    supportSorting: {
      type: Boolean,
      default: false,
      required: false,
    },
    tableColStyles: {
      type: Array,
      default: () => [],
      required: false,
    },
    tableRows: {
      type: Array,
      default: () => [],
      required: false,
    },
    wrapCells: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: function () {
    return {
      checkboxColumnHeader: '_VasionCheckBox_',
      checkedBoxes: [],
      dueDate: null,
      dueDateDisplayTopStyle: '',
      dueDateDocumentID: 0,
      dueDateHeader: '_DueDate_',
      dueDateWorkflowID: 0,
      ellipsisButtonColumnHeader: '_VasionEllipsisButton_',
      ellipsisButtonConfigLocal: {},
      ellipsisMenuTopStyle: '',
      ellipsisRowItem: 0,
      fileDisplay: -1,
      hasManyColumns: false,
      headerChecked: false,
      iconButtonColumnHeader: '_VasionIconButton_',
      iconDeleteColumnHeader: '_VasionDeleteIconButton_',
      iconColumnHeader: '_VasionIcon_',
      isHeaderTextLong: false,
      lastCheckedRow: 0,
      lastCheckedBoolean: false,
      localFilteredRows: this.tableRows,
      mouseX: 0,
      mouseY: 0,
      orderByParams: {
        indexValue: this.supportSorting ? this.headerColumns[this.filterByColumnDefault] : '',
        order: 'asc',
      },
      priorityColumnHeader: '_Priority_',
      selected: [],
      showDueDateComponent: false,
      showEllipsisMenu: false,
      snackbar: {
        image: false,
        show: false,
        showRawHtml: true,
        subtitle: '',
        title: '',
      },
      thumbnailColumHeader: 'Thumbnail',
    }
  },
  computed: {
    displayChecked() { return this.headerChecked },
    filteredRows() {
      if (this.orderByParams?.indexValue)
      // eslint-disable-next-line no-undef
        return _.orderBy(this.localFilteredRows, [this.orderByParams.indexValue.replace(/\s/g, '')], [this.orderByParams.order])
      else
        return this.localFilteredRows
    },
    isPriorityColumnPresent() {
      if (this.headerColumns.length <= 0) return false
      if (this.headerColumns.includes(this.priorityColumnHeader)) return true
      return false
     },
     priorityListOptions() {
      if (Array.isArray(this.$store.state.workflow.priorities) && this.$store.state.workflow.priorities.length > 0) {
        return this.$store.state.workflow.priorities.map((p) => {
          return {
            name: p.priorityName,
            value: p.priorityID,
          }
        })
      }
      return []
    },
    thumbnailStyle() { return `position: ${this.floatingThumbnail ? 'fixed' : 'absolute'}; z-index: 10; top: ` + this.mouseY.toString()  + 'px; left: ' + this.mouseX.toString() + 'px; filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.2)) drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 14px rgba(0, 0, 0, 0.12));' }
  },
  watch: {
    tableRows: async function () {
      this.localFilteredRows = this.tableRows
      this.headerChecked = false
      this.checkedBoxes = []
      if (this.isPriorityColumnPresent && (!this.$store.state.workflow.priorities || !Array.isArray(this.$store.state.workflow.priorities) || this.$store.state.workflow.priorities.length == 0)) {
        await this.$store.dispatch('workflow/getWorkflowPriorities')
      }
    },
  },
  async created() {
    this.localFilteredRows = this.tableRows
    this.checkedBoxes = []
    this.selected = []
    this.headerChecked = false
    this.hasManyColumns = this.headerColumns.length > 20
    this.ellipsisButtonConfigLocal = this.ellipsisButtonConfig

    if (this.isPriorityColumnPresent) await this.$store.dispatch('workflow/getWorkflowPriorities')
  },
  methods: {
    displayEmptyUser(rowIndex){
      if (this.blankUsers.includes(rowIndex)){
        return ""
      }
      return "display: none; "
    },
    displayNonEmptyUser(rowIndex){
      if (this.blankUsers.includes(rowIndex)){
        return "display: none; "
      }
      return ""
    },
    displayFile(rowIndex, item) {
      if (typeof item === 'string') {
        return this.fileDisplay == rowIndex && item === 'DisplayThumbnail'
      }
      return this.fileDisplay == rowIndex && (item.Values[this.documentIDIndex + 2] == 'VasionInsertDriveFileIcon' || item.Values[this.documentIDIndex + 1] == 'VasionInsertDriveFileIcon')
    },
    displayHeaderText(headerText) {
      if (!headerText
        || headerText === this.iconColumnHeader
        || headerText === this.iconButtonColumnHeader
        || headerText === this.iconDeleteColumnHeader
        || headerText === this.checkboxColumnHeader
        || headerText === this.ellipsisButtonColumnHeader) {
        return ''
      }
      // If we have a header like ex _VasionCheckBox_{{Header Text Name}}, we need to apply header text, even though it is a checkbox
      if (headerText.startsWith('_VasionCheckBox_')) {
        headerText = headerText.replace('_VasionCheckBox_{{', '').replace('}}', '')
        this.isHeaderTextLong = headerText.length > 15
        return headerText
      } else if (headerText === this.dueDateHeader) {
        return 'Due Date'
      } else if (headerText === this.priorityColumnHeader) {
        return 'Priority'
      }
      return headerText
    },
    dueDateCancelClick() {
      this.showDueDateComponent = false
    },
    dueDateClick(event, items, selectedDueDate) {
      this.showDueDateComponent = false
      this.dueDateDocumentID = Number(items[1])
      this.dueDateWorkflowID = Number(items[2])

      if (!this.dueDateDocumentID || !this.dueDateWorkflowID) {
        return
      }

      this.dueDate = null
      if (selectedDueDate && selectedDueDate !== '') {
        this.dueDate = new Date(selectedDueDate)
      }

      let displayY = event.pageY - 40
      if (displayY + 350 > document.documentElement.scrollHeight) {
        displayY -= 350
      }

      this.dueDateDisplayTopStyle = `top:${displayY}px;`
      this.showDueDateComponent = true
    },
    dueDateOKClick(newDueDate) {
      const rowElement = this.localFilteredRows.find((element) => {
        // disabling lint because I want the "==" comparison - one is a Number, the other is a string
        // eslint-disable-next-line
        return element.Values[1] == this.dueDateDocumentID
      })

      if (rowElement) {
        rowElement.Values[8] = newDueDate
      }

      this.showDueDateComponent = false
    },
    ellipsisButtonClicked(payload) {
      this.$emit('ellipsis-button-clicked', payload)
      this.ellipsisClose()
    },
    ellipsisClose() {
      this.showEllipsisMenu = false
    },
    ellipsisOpen(event, item) {
      this.ellipsisClose()
      this.ellipsisRowItem = item
      let displayY = event.pageY
      let baseSize = 0

      if (this.ellipsisFilterCondition(item)) {
        this.ellipsisButtonConfigLocal = this.ellipsisButtonConfigFiltered
      } else {
        this.ellipsisButtonConfigLocal = this.ellipsisButtonConfig
      }

      if (this.ellipsisButtonConfigLocal && this.ellipsisButtonConfigLocal.buttons) {
        baseSize = this.ellipsisButtonConfigLocal.buttons.length * 40
      }

      if (displayY + baseSize > document.documentElement.scrollHeight) {
        displayY -= baseSize
      }

      displayY += this.ellipsisOffset

      this.ellipsisMenuTopStyle = `top:${displayY}px;`
      this.showEllipsisMenu = true
    },
    headerCheckChange() {
      this.headerChecked = !this.headerChecked
      this.checkedBoxes = []

      this.filteredRows.forEach((item) => {
        item.Values.forEach((element) => {
          if (element && typeof element.show !== 'undefined' && element.show) {
            // kind of a lot going on here...
            // so, the header check was changed, meaning that we want to apply that value to all checkboxes below
            // so, loop through the items, and if it's a check box that's shown, set the value
            // then, add it to the list of checked rows if needed
            element.value = this.headerChecked
            if (this.headerChecked) {
              this.checkedBoxes.push(item)
            }
          }
        })
      })

      this.$emit('newList', this.checkedBoxes)
      this.$emit('vasion-row-checked', this.checkedBoxes)
    },
    iconClick(lineItem, clickedColumnIndex, clickedRowIndex) {
      this.$emit('vasion-icon-click', {
        item: lineItem,
        index: clickedColumnIndex,
        rowIndex: clickedRowIndex,
      })
    },
    deleteIconClick(rowIndex) {
      this.$emit('vasion-delete-icon-click', rowIndex);
    },
    lineItemCheckChange(shiftKey, checkValue, lineItem, clickedColumnIndex, clickedRowIndex) {
      if (shiftKey && !this.disableShiftSelect) {
        const startIndex = Math.min(clickedRowIndex, this.lastCheckedRow)
        const endIndex = Math.max(clickedRowIndex, this.lastCheckedRow)

        this.filteredRows.forEach((item, index) => {
          if (index >= startIndex && index <= endIndex) {
            item.Values.forEach((element) => {
              if (element && typeof element.show !== 'undefined' && element.show) {
                element.value = this.lastCheckedBoolean
                if (this.lastCheckedBoolean) {
                  this.checkedBoxes.push(item)
                } else {
                  const index = this.checkedBoxes.indexOf(lineItem)
                  if (index > -1) {
                     this.checkedBoxes.splice(index, 1);
                  }
                }
              }
            })
          }
        })

        // For shift-click, we want to make sure that the browser does not do a text selection
        document.getSelection().removeAllRanges()

        this.$emit('newList', this.checkedBoxes)
        this.$emit('vasion-row-checked', this.checkedBoxes)
      } else {
        checkValue.value = !checkValue.value
        if (checkValue.value) {
          this.checkedBoxes.push(lineItem)
          this.$emit('newList', this.checkedBoxes, lineItem)
        } else {
          // Find and remove just the first instance
          const index = this.checkedBoxes.indexOf(lineItem)
         if (index > -1) {
            this.checkedBoxes.splice(index, 1);
            this.$emit('newList', this.checkedBoxes, lineItem)
          }
        }

        const payload = this.checkedBoxes.map((row) => {
          return {
            ValueType: row.ValueType,
            Values: row.Values,
            clickedColumnIndex: clickedColumnIndex,
            clickedRowIndex: clickedRowIndex,
          }
        })

        this.$emit('vasion-row-checked', payload, clickedRowIndex, clickedColumnIndex, checkValue.value)
      }
      this.lastCheckedRow = clickedRowIndex
      this.lastCheckedBoolean = checkValue.value
    },
    mouseOffFile: function () {
      this.fileDisplay = -1
    },
    mouseOnFile: function (event, rowIndex) {
      this.mouseX = 50
      this.mouseY = 40
      if (this.floatingThumbnail) {
        this.mouseX = event.clientX
        this.mouseY = event.clientY
      }

      this.fileDisplay = rowIndex
      
      if (this.floatingThumbnail && event.pageY >= document.body.clientHeight - 226) {
        this.mouseY = event.clientY - 226
      }
        
      // Image doesn't fall off bottom of screen:
      if (!this.floatingThumbnail && event.pageY >= document.body.clientHeight - 250){
        this.mouseY = -(event.pageY - (document.body.clientHeight - 250))
      }
    },
    onSelect(items, index) {
      this.selected = items
      // eslint-disable-next-line no-restricted-globals
      if (!event.target.parentNode.classList.contains('input-group') && !event.target.parentNode.parentNode.classList.contains('input-group') && !event.target.parentNode.parentNode.classList.contains('vasion-droplist') && !event.target.parentNode.parentNode.classList.contains('drop-arrow') /* DROPLIST */
      // eslint-disable-next-line no-restricted-globals
      && !event.target.classList.contains('checkbox') && !event.target.parentNode.classList.contains('checkbox') && event.srcElement.className !== 'vasion-checkbox' && !event.target?.childNodes[0]?.childNodes[0]?.classList?.contains('vasion-checkbox') /* CHECKBOX */
      // eslint-disable-next-line no-restricted-globals
      && !event.target.parentNode.classList.contains('vasion-ellipsis-button') /* ELLIPSIS */
      // eslint-disable-next-line no-restricted-globals
      && !event.target.classList.contains('item-name') && !event.target.classList.contains('item-checkbox') /* DROPLIST */) {
        this.$emit('vasion-selection', {
          Values: items,
          Index: index,
        })
      }
    },
    setCheckedOnCreate(checkValue, lineItem) {
      if (checkValue.value) {
        this.checkedBoxes.push(lineItem)
      }
    },
    async setPriority(newValue, documentID) {
      if (typeof newValue !== 'object') return
      if (!Object.prototype.hasOwnProperty.call(newValue, "value")) return
      const payload = {
        documentID,
        priorityID: newValue.value
      }
      const result = await this.$store.dispatch('document/savePriority', payload)
      if (result.Value === 'True') {
        this.snackbar.image = true
        this.snackbar.show = true
        this.snackbar.subtitle = 'Priority updated successfully'
        this.snackbar.title = 'Success!'
      } else {
        this.snackbar.image = false
        this.snackbar.show = true
        this.snackbar.subtitle = result?.ResultMessage ? result.ResultMessage : 'Error when trying to update priority'
        this.snackbar.title = 'ERROR'
      }
    },
    shouldIncreaseColumnWidth(columnName) {
      return columnName.length >= 10
    },
    iconColumnHeaderWidth(columnName) {
      return columnName.indexOf(this.iconColumnHeader) > -1 || columnName.indexOf(this.iconButtonColumnHeader) > -1
    },
    shouldSetColumnWidth(columnName) {
      return columnName.indexOf(this.checkboxColumnHeader) > -1 || columnName.indexOf(this.iconButtonColumnHeader) > -1 || columnName.indexOf(this.ellipsisButtonColumnHeader) > -1 || columnName.indexOf(this.iconDeleteColumnHeader) > -1
    },
    shouldSetWorkSpaceColumn(columnName) {
      return columnName === this.priorityColumnHeader || columnName === this.dueDateHeader
    },
    shouldShowColumn(columnName) {
      if (columnName && (!this.hideColumns || this.hideColumns.length === 0 || this.hideColumns.indexOf(columnName) < 0)) {
        return true
      }

      return false
    },
    sort(indexValue) {
      if (!this.supportSorting) {
        return
      }

      if (this.orderByParams.indexValue === this.headerColumns[indexValue]) {
       this.orderByParams.order = this.orderByParams.order === 'asc' ? 'desc' : 'asc'
      }
      this.orderByParams.indexValue = this.headerColumns[indexValue]

      this.$emit('vasion-column-sort', {
        columnName: this.orderByParams.indexValue,
        ascending: this.orderByParams.order === 'asc',
      })
    },
    toolTipClose(event) {
      event.target.classList.remove('show-tooltip')
    },
    toolTipDisplay(event) {
      if (event.target.scrollWidth != event.target.clientWidth)
        event.target.classList.add('show-tooltip')
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
@import '@/assets/css/light-theme-system-palette.scss';

  .no-wrap{
    white-space: pre;
  }

  .set-due-date-component-div {
    position: absolute;
    right: 30px;
    background: $white;
    border: 1px solid $grey-300;
    margin: 0 auto;
    text-align: left;
    z-index: 1000;
    width: 430px;
  }

  .setColumnMinWidth{
    min-width: 45px;
  }

  .due-date-text-div {
    width: 70px;
    padding-top: 5px;
    margin-right: 15px;
  }

  .due-date-column {
    display: flex;
    flex-wrap: wrap;
    width: 110px;
  }

  .due-date-column:hover {
    color: $orange-300;
    fill: $orange-300;
    background: $orange-50;
    cursor: pointer;
  }

  .hideTableCell {
    display: none;
  }

  .setColumnWidth {
    max-width: 48px;
    width: 48px;
  }

  .workSpaceColumn {
    max-width: 160px;
    width: 160px;
  }
  .widerCheckboxColumn {
    max-width: 90px;
    width: 90px;
  }
  .widerColumns {
    min-width: 100px;
  }
  .singleWideColumn {
    min-width: 210px;
  }

  .iconFillColor {
    fill: $table-body-color;
  }

  .sort-by-label {
    position: relative;
    bottom: 6px;
  }
  
  .vasion-icon-button:hover {
    color: $on-primary-container;
    fill: $on-primary-container;
    cursor: pointer;
  }

  .vasion-ellipsis-button {
    width: 32px;
    height: 32px;
    padding: 0px;

    svg {
      margin: 4px 0 0 4px;
    }

    &:hover {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: $grey-lighten-1;
    }
  }

  .checkbox-checked {
    padding: 3px;
  }

  @media (max-width: $phone){
    .set-due-date-component-div {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 97%;
    }
  }

  .clickable:hover {
    cursor: pointer;
  }

  .textarea-full-text {
    position:relative;
  }

  .full-text {
    white-space: normal;
    border-radius: 6px;
    display:none;
    position:absolute; 
    z-index:100;
    border:1px;
    background-color:black;
    border-style:solid;
    padding: 6px 16px;
    color:white; 
    top:20px; 
    left:20px;
  }

  .show-tooltip span.full-text {
    display: block;
  }
  
  .image-preview {
    width: auto;
    max-height: 85px;
    max-width: 175px;
  }
  .no-records-message p {
    padding: 8px;
    font-family: $font-family-bold;
    font-size: 18px;
    font-weight: bold;
    color: $grey-500;
    margin-bottom: 0;
    text-align: center;
  }
  .docs-text {
    font-size: 11px;
    display: unset;
  }
  .vasion-icon {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .columnIconWidth {
    width: 48px;
  }

  .table-rows {
  }
</style>
