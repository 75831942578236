export default function (date) {
  const localDate = new Date(date);
  const toReturn = (new Date(localDate.getTime() - (localDate.getTimezoneOffset() * 60000)))
    .toLocaleDateString()
    .split('T')[0];

  return toReturn;
}
// export default function (date) {
//   const localDate = new Date(date);
//
//   const year = localDate.getFullYear();
//
//   let month = (1 + localDate.getMonth()).toString();
//   month = month.length > 1 ? month : `0${month}`;
//
//   let day = localDate.getDate().toString();
//   day = day.length > 1 ? day : `0${day}`;
//
//   // return `${month}/${day}/${year}`;
//   return `${year}/${month}/${day}`;
// }
