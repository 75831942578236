/* eslint-disable linebreak-style */
/* eslint-disable no-console */
/* eslint-disable array-callback-return */
// import { defaultComponent } from '@/defaults/default.JSON';
import {
  checkIfFieldIsUsedInAForm,
  checkIfUserCanAccessEForm,
  checkIfUserCanAccessEFormAndWorkflow,
  deleteEFormDraftById,
  deletePackage,
  getAllFieldHeaders,
  getCreateTempDocsByEFormID,
  getCreateTempDocsByFormID,
  getCreateTempDocsForFormID,
  getCurrencyFormatByFieldName,
  getEform,
  getEFormDraftByDraftId,
  getEFormDrafts,
  getEFormFieldsFromFormIOJson,
  getEFormsByFormID,
  getEFormExternalSettings,
  getFileExtensions,
  getFormBuilderConfigByFormID,
  getPackager,
  getPackagesByObjectId,
  replaceFormIOPdf,
  saveEForm,
  saveEFormDocTemplateDefaultMapping,
  saveEFormExternalSettings,
  saveEFormDocTemplateMapping,
  saveEFormDraft,
  saveNewField,
  savePackager,
  setPackageStatus,
  updateFieldInEForms,
  uploadFormIOPdf,
 } from '@/store/apiModules/forms.api'

function initialState() {
  return {
    activeId: 0,
    allFieldHeaders: null,
    areFormsDirty: false,
    builderConfigFromFormID: {},
    createTempDocsByFormID: [],
    defaultFormTab: '',
    desiredPendingRoute: '',
    dirtyFormModalShowing: false,
    docExternalSettings: {
      headerImageBase64: '',
      headerImageFileName: '',
      hexColor: '#ffffff',
    },
    eForm: null,
    eFormDocTemplateFields: null,
    eFormDocTemplateMapping: null,
    formDrafName: '',
    formIsDirty: false,
    fileExtensions: [],
    filePath: '',
    filterValue: '',
    formID: null,
    newSelectedTemplates: [],
    pkgDetails: {},
    pkgFields: {},
    pkgFieldsErrors: {},
    pkgRules: {},
    pkgToEdit: {},
    tempDocsForEform: [],
  }
}

const forms = {
  namespaced: true,
  state: initialState,
  mutations: {
    clearEform(state) {
      state.eForm = null
      state.eFormDocTemplateFields = null
      state.eFormDocTemplateMapping = null
      state.createTempDocsByFormID = []
      state.tempDocsForEform = []
    },
    filterFormBuilderconfigByFormID(state, value) { state.filterValue = value },
    saveEFormDocTemplateMapping(state, value) { state.eFormDocTemplateMapping = value },
    saveEFormExternalSettings(state, value) { state.eFormDocTemplateMapping = value },
    setAllFieldHeaders(state, value) { state.allFieldHeaders = value },
    setAreFormsDirty(state, value) { state.areFormsDirty = value },
    setCreateTempDocsByEFormID(state, value) { state.tempDocsForEform = value },
    setCreateTempDocsByFormID(state, value) {
      const returnArray = []
      Object.keys(value).map(key => {
        returnArray.push({ id: key, name: value[key], value: key })
      })
      state.createTempDocsByFormID = returnArray
    },
    setDefaultFormTab(state, value) { state.defaultFormTab = value },
    setDesiredPendingRoute(state, value) { state.desiredPendingRoute = value },
    setDirtyFormModalShowing(state, value) { state.dirtyFormModalShowing = value },
    setEform(state, value) { state.eForm = value },
    setEformDocTemplateFields(state, value) { state.eForm = value },
    setEformDocument(state, value) { state.eForm = value },
    setEFormExternalSettings(state, value) { state.docExternalSettings = value },
    setFileExtensions(state, value) { state.fileExtensions = value },
    setFormBuilderconfigByFormID(state, value) {
      if (value.Value !== '') {
        let cleanedValue = value.Value
        try { JSON.parse(cleanedValue) } catch { cleanedValue = value.Value.replaceAll("\\", "\\\\") }
        state.builderConfigFromFormID = JSON.parse(cleanedValue)
      }
    },
    setFormDrafName(state, value) { state.formDrafName = value },
    setFormIsDirty(state, value) { state.formIsDirty = value },
    setFormID(state, value) { state.formID = value },
    setPkgDetails(state, value) { state.pkgDetails = value },
    setPkgFields(state, value) { state.pkgFields = value },
    setPkgFieldsErrors(state, value) { state.pkgFieldsErrors = value },
    setPkgRules(state, value) { state.pkgRules = value },
    setPkgToEdit(state, value) { state.pkgToEdit = value },
  },
  actions: {
    async checkIfFieldIsUsedInAForm({ rootState }, payload) { return checkIfFieldIsUsedInAForm(rootState.common.apiToken, payload) },
    // eslint-disable-next-line
    async checkIfUserCanAccessEForm({ rootState }, value) {
      const data = await checkIfUserCanAccessEForm(rootState.common.apiToken, value)
      return data?.Value === 'True'
    },
    async checkIfUserCanAccessEFormAndWorkflow({ rootState }, payload) {
      const data = await checkIfUserCanAccessEFormAndWorkflow(rootState.common.apiToken, payload)
      return data?.Value === 'True'
    },
    clearEform({ commit }) { commit('clearEform') },
    async deleteEFormDraftById({ rootState }, draftId) {
      const result = await deleteEFormDraftById(rootState.common.apiToken, draftId)
      return result
    },
    async deletePackage({ rootState }, packageId) {
      const result = await deletePackage(rootState.common.apiToken, packageId)
      return result
    },
    filterFormBuilderconfigByFormID({ commit }, value) { commit('filterFormBuilderconfigByFormID', value) },
    async getAllFieldHeaders({ commit, rootState }) {
      const data = await getAllFieldHeaders(rootState.common.apiToken)
      if (data) {
        commit('setAllFieldHeaders', data.fields)
      }
      return data
    },
    async getCreateTempDocsByEFormID({ commit, rootState }, eFormId) {
      if (eFormId > 0) {
        const data = await getCreateTempDocsByEFormID(rootState.common.apiToken, eFormId)
        if (data && data.DocTemplates) {
          commit('setCreateTempDocsByEFormID', data.DocTemplates)
        }
        return data
      }
      commit('setCreateTempDocsByEFormID', [])
    },
    async getCreateTempDocsByFormID({ commit, rootState }, formId) {
      if (formId > 0) {
        const data = await getCreateTempDocsByFormID(rootState.common.apiToken, formId)
        if (data) {
          commit('setCreateTempDocsByFormID', data.Values)
        }
        return data
      }
      commit('setCreateTempDocsByFormID', [])
    },
    async getEForm({ commit, rootState }, formId) {
      try {
        const response = await getEform(rootState.common.apiToken, formId)
        commit('setEform', response)
      } catch (error) {
        console.warn(error)
      }
    },
    async getEFormAccessibility({ commit, dispatch, rootState }, formId) {
      await dispatch('common/resetAxiosDefaultBaseURL', null, { root: true })
      try {
        const response = await getEform(rootState.common.apiToken, formId)
        commit('setEform', response)
        if (response.PublicAccessible) {
          return response.PublicAccessible
        } else {
          return false
        }
      } catch (error) {
        console.warn(error)
        return false
      }
    },
    async getEFormDraftByDraftId({ rootState }, draftId) {
      try {
        const response = await getEFormDraftByDraftId(rootState.common.apiToken, draftId)
        if (response) {
          return response
        }
        return []
      } catch (error) {
        console.warn(error)
      }
    },

    async getEFormDrafts({ rootState }) {
      try {
        const response = await getEFormDrafts(rootState.common.apiToken)
        if (response?.Drafts) {
          return response.Drafts
        }
        return []
      } catch (error) {
        console.warn(error)
      }
    },
    async getCreateTempDocsForFormID({ rootState }, payload) { return getCreateTempDocsForFormID(rootState.common.apiToken, payload) },
    async getCurrencyFormatByFieldName({ rootState }, fieldName) { return getCurrencyFormatByFieldName(rootState.common.apiToken, fieldName) },
    async getEFormExternalSettings({ commit, rootState }, formID) {
      const response = await getEFormExternalSettings(rootState.common.apiToken, formID)
      commit('setEFormExternalSettings', response)
      return response
    },
    async getEFormFieldsFromFormIOJson({ rootState }, payloadIn) {
      const payload = {
        eformID: payloadIn.id,
        formIOJson: JSON.stringify(payloadIn.activeFormIoJSON),
        why: '',
      }
      return getEFormFieldsFromFormIOJson(rootState.common.apiToken, payload)
    },
    async getEFormsByFormID({ rootState }, formID) {
      const payload = {
       Value: formID,
      }
      return getEFormsByFormID(rootState.common.apiToken, payload)
    },
    async getFileExtensions({ commit, rootState }) {
      const fileExtensions = await getFileExtensions(rootState.common.apiToken)
      commit('setFileExtensions', fileExtensions)
    },
    async getFormBuilderConfigByFormID({ commit, rootState }, payload) {
      const response = await getFormBuilderConfigByFormID(rootState.common.apiToken, payload)
      commit('setFormBuilderconfigByFormID', response)
    },
    async getPackager({ rootState }, payload) {
      const response = await getPackager(rootState.common.apiToken, payload)
      return response
    },
    async getPackagesByObjectId({ rootState }, payload) {
      const response = await getPackagesByObjectId(rootState.common.apiToken, payload)
      return response
    },
    async replaceFormIOPdf({ rootState }, payload) {      
      return replaceFormIOPdf(rootState.common.apiToken, payload)
    },
    async saveEForm({ commit, rootState }, payload) {
      const response = await saveEForm(rootState.common.apiToken, payload)
      commit('setEform', response)
      return response
    },
    async saveEFormDocTemplateDefaultMapping({ commit, rootState }, payload) {
      const response = await saveEFormDocTemplateDefaultMapping(rootState.common.apiToken, payload)
      commit('saveEFormDocTemplateMapping', response)
      return response
    },
    async saveEFormDocTemplateMapping({ commit, rootState }, payload) {
      const response = await saveEFormDocTemplateMapping(rootState.common.apiToken, payload)
      commit('saveEFormDocTemplateMapping', response)
      return response
    },
    async saveEFormDraft({ rootState }, payload) {
      const response = await saveEFormDraft(rootState.common.apiToken, payload)
      return response
    },
    async saveEFormExternalSettings({ dispatch, rootState }, payload) {
      const response = await saveEFormExternalSettings(rootState.common.apiToken, payload)
      dispatch('getEFormExternalSettings', response)
      return response
    },
    async saveNewField({ rootState }, payload) {
      const response = await saveNewField(rootState.common.apiToken, payload)
      return response
    },
    async savePackager({ rootState }, payload) {
      const response = await savePackager(rootState.common.apiToken, payload)
      return response
    },
    async setPackageStatus({ rootState }, payload) {
      const response = await setPackageStatus(rootState.common.apiToken, payload)
      return response
    },
    setDefaultFormTab({ commit }, defaultTabName) { commit('setDefaultFormTab', defaultTabName) },
    setDesiredPendingRoute({ commit }, value) { commit('setDesiredPendingRoute', value) },
    setDirtyFormModalShowing({ commit }, value) { commit('setDirtyFormModalShowing', value) },
    setFormDrafName({ commit }, value) { return commit('setFormDrafName', value) },
    setFormIsDirty({ commit }, value) { return commit('setFormIsDirty', value) },
    setFormID({ commit }, value) { return commit('setFormID', value) },
    setPkgDetails({ commit }, value) { return commit('setPkgDetails', value) },
    setPkgFields({ commit }, value) { return commit('setPkgFields', value) },
    setPkgFieldsErrors({ commit }, value) { return commit('setPkgFieldsErrors', value) },
    setPkgRules({ commit }, value) { return commit('setPkgRules', value) },
    setPkgToEdit({ commit }, value) { return commit('setPkgToEdit', value) },
    updateAreFormsDirty({ commit }, value) { return commit('setAreFormsDirty', value) },
    async updateFieldInEForms({ rootState }, payload) { return updateFieldInEForms(rootState.common.apiToken, payload) },
    async uploadFormIOPdf({ rootState }, payload) {      
      return uploadFormIOPdf(rootState.common.apiToken, payload)
    },
  },
  getters: {
    availableTemplates(state, getters) { return [...state.createTempDocsByFormID].filter(t => !getters.selectedTemplates.find(({ id }) => id === t.id)) },
    selectedTemplates(state) {
      return state.createTempDocsByFormID.filter(item => {
        let returnBool
        if (state.tempDocsForEform.some(d => parseInt(d.CreateDocTemplateID, 10) === parseInt(item.id, 10))) {
          returnBool = true
        } else {
          returnBool = false
        }
        return returnBool
      })
    },
  },
}

export default forms
