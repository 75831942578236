<template>
  <span>{{ formattedValue }}</span>
</template>

<script>
import { formatDateTime } from '@/store/helperModules/common.module.js'

export default {
  name: 'VasionDateTimeDisplay',
  props: {
    value: {
      type: [String, Date, Number],
      required: true,
    },
    format: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'datetime',
      validator: function (value) {
        return ['date', 'time', 'datetime'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    formattedValue() {
      return formatDateTime(
        this.value,
        this.type,
        this.format
      )
    },
  },
};
</script>