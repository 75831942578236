<template>
  <div class="h-100">
    <div v-show="(!showUploadDialog && isFormDraft) || !isFormDraft" class="h-100">
      <Loading
        :active.sync="isLoading"
        :is-full-page="fullPage"
        :color="loaderColor"
        loader="dots"
        :background-color="loaderBackgroundColor"
      />
      <div id="user-form" :class="{'h-100': true, 'autonomous-view': $route.name === 'plainForm', 'preview-mode': inPreviewMode}">
        <div class="sect-content">
          <div class="sect-header">
            <div v-show="!showMultipleLookups" id="header">
              <div v-show="showLogo" id="header-logo-area">
                <img id="header-logo" :src="checkLogoImgNeedsData">
              </div>
              <div id="text-and-date">
                <span id="header-text">{{ formName }}</span>
                <div v-if="showDatePicker" id="due-date-wrapper">
                  <VasionInput
                    v-if="!canModifyDueDate"
                    id="due-date"
                    v-model="dueDate"
                    name="due-date"
                    :title="dueDateLabel"
                    inputType="top-white"
                    :readonly="true"
                  />
                  <VasionDateTimeField
                    v-if="canModifyDueDate"
                    v-model="dueDate"
                    :label="dueDateLabel"
                    type="date"
                    :disabledDates="disabledDates"
                  />
                </div>
              </div>
            </div>
            <p v-if="isAnonymous" class="label-anonymous">*Your Form submission will be anonymous.</p>
          </div>
          <div class="sect-body">
            <div id="main-section" ref="mainSection">
              <div>
                <div
                  v-if="containsFormioJson"
                  :class="{
                    'pdf-form-container': pdfMode,
                    'pdf-form-container-wh-height': pdfMode && showLogo,
                    'pdf-form-container-height': pdfMode && !showLogo && !isFromMobile,
                    'pdf-form-container-mobile-height': pdfMode && !showLogo && isFromMobile,
                    'formio-container': true
                  }"
                >
                  <formio
                    v-if="(formioFields.components && formioFields.components.length) || pdfMode"
                    ref="formObject"
                    :key="refreshKey"
                    :form="formioFields"
                    :options="pdfFormioConfig"
                    @render="formioRendered"
                    @change="updateFormioJSON"
                    @submit="submitEForm"
                    @nextPage="wizardChangePage"
                    @prevPage="wizardChangePage"
                    @wizardPageSelected="wizardChangePage"
                  />
                </div>
                <MaxxFormDisplay
                  v-else
                  ref="maxxFormComponent"
                  :key="maxxFormComponent"
                  :maxxFormID="formLayout.EFormID"
                  @invalidForm="maxxFormValid = false;"
                />
              </div>
              <div v-if="uploadedFilesList.length > 0" class="uploaded-files-list">
                <div v-for="(file, index) in uploadedFilesList" :key="index" class="uploaded-file-item">
                  <div>
                    <span class="file-name"> {{ file.name }} </span><span class="bytes">({{ file.size ? parseInt(file.size / 1000) : parseInt(uploadedFileSize[index] / 1000) }}K)</span>
                  </div>
                  <VasionButton
                    :icon="'VasionExIcon'"
                    title="Remove Uploaded File"
                    class="uploaded-file-delete"
                    @vasionButtonClicked="removeUploadedFile(index)"
                  />
                </div>
              </div>
              <div v-show="formLayout.RequiresSignature && (!wizardMode || (wizardMode && showSignatureForWizard))" id="apply-signature-dialog">
                <VasionApplySignature
                  ref="signatureField"
                  annotationType="Signature"
                  :customSignatureLabel="formLayout.SignatureLabel"
                  :documentID="0"
                  :isEmbedded="true"
                  :savedSignatureOrInitials="userSignature"
                  :showCancelButton="false"
                  :showSignButton="false"
                  @ok-click="okApplySignatureClick"
                  @signatureChanged="signatureChanged"
                />
              </div>
            </div>
          </div>
          <div class="sect-footer">
            <div
              v-if="(doneCreating && formLayout && formLayout.RequireUploadFiles && uploadedFilesList.length == 0)"
              id="require-attachments-hint"
              class="attachment-required-hint"
            >
              <span class="warnLabel">*Attachment Required</span>
            </div>
            <div v-if="doneCreating" v-show="!showMultipleLookups && isSignedIn" id="footer">
              <VasionButton
                v-if="(formID !== 0 || formID !== null) && !isWorkflowDraft && !isFromMobile && !isMyForm && !isFormDraft"
                id="back"
                name="back"
                :classProp="'secondary'"
                @vasionButtonClicked="backToSelectWorkflow"
              >
                Back
              </VasionButton>

              <VasionButton
                v-if="formID !== 0 && !isFromMobile && !isMyForm && !isFormDraft"
                id="cancel"
                name="cancel"
                :classProp="'secondary'"
                @vasionButtonClicked="cancelForm"
              >
                Cancel
              </VasionButton>

              <VasionButton
                v-show="showLookupBtn"
                :classProp="'secondary'"
                @vasionButtonClicked="runDBLookup"
              >
                Run Lookup
              </VasionButton>
              <VasionButton
                v-if="supportsFileUpload"
                id="upload-file-button"
                name="upload-file-button"
                :classProp="'secondary'"
                @vasionButtonClicked="attachFileClicked"
              >
                Attach Files
              </VasionButton>

              <VasionButton
                v-if="showSaveDraftBtn"
                id="save-draft-button"
                name="save-draft-button"
                class="button-right"
                :classProp="'secondary'"
                :isDisabled="!formIsDirty"
                @vasionButtonClicked="prepareFormDraftSaving"
                >
                  {{ (!formDraftData.EFormDraftId || formIsDirty) ? 'Save Draft' : 'Draft Saved' }}
              </VasionButton>

              <VasionButton
                v-if="formID !== 0 && showSubmitBtn && (!isFromMobile || !workflowID)"
                id="start-workflow"
                :isDisabled="!formIsValid"
                name="start-workflow"
                :classProp="'primary'"
                @vasionButtonClicked="startWorkflow"
              >
                {{ formLayout.RequiresSignature ? "Sign and " : "" }}Submit
              </VasionButton>
              <VasionButton
                v-else-if="showSubmitBtn"
                id="start-workflow"
                name="start-workflow"
                :classProp="'primary'"
                :isDisabled="!formIsValid"
                @vasionButtonClicked="startWorkflow"
              >
                {{ formLayout.RequiresSignature ? "Sign and " : "" }}Start Workflow
              </VasionButton>
            </div>
            <div v-if="!isSignedIn" id="footer-not-signed-in">
              <div id="secured-by-group">
                <span>SECURED BY</span>
                <VasionWordmark id="word-mark" />
              </div>
              <div class="buttons">
                <VasionButton
                  v-show="showLookupBtn"
                  :classProp="'secondary'"
                  @vasionButtonClicked="runDBLookup"
                >
                  Run Lookup
                </VasionButton>

                <VasionButton
                  v-if="supportsFileUpload"
                  id="upload-file-button"
                  name="upload-file-button"
                  class="button-right"
                  :classProp="'secondary'"
                  @vasionButtonClicked="showUploadDialog = true"
                >
                  Attach Files
                </VasionButton>
                <VasionButton
                  v-if="showSubmitBtn"
                  id="start-workflow-not-signed-in"
                  name="start-workflow-not-signed-in"
                  :classProp="'primary'"
                  :isDisabled="!formIsValid"
                  class="button-right"
                  @vasionButtonClicked="startWorkflow"
                >
                  {{ formLayout.RequiresSignature ? "Sign and " : "" }}Submit
                </VasionButton>
              </div>
            </div>
            <span v-show="showErrorText" class="vasion-error-text error-text">Please fill in all required fields.</span>
          </div>
        </div>
      </div>
    </div>

    <VasionFileUploadDialog
      allowMultiple
      :serverOptions="serverOptions"
      :show.sync="showUploadDialog"
      :note="allowedFileExtensions"
      @allFilesUploaded="allFilesUploaded"
    />

    <VasionSnackbar
      id="snack-bar"
      :showRawHtml="false"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />

    <VasionGeneralModal
      id="lookup-modal"
      modalType="slot"
      :modalTitle="'Lookup Results'"
      :sync="showLookupModal"
      :showTopRightCloseButton="false"
      :showConfirmButton="false"
      :showRejectButton="false"
      :modalMdContainer="true"
    >
      <div v-if="currentLookupId">
        <p>{{ `Lookup in progress: ${currentLookupIndex + 1} / ${formLayout.DBLookupIDs.length}` }}</p>
        <div v-show="showWarnLabel" class="warnLabel">
          <h5>No results were found</h5>
        </div>
        <MultipleLookups
          :tableValues="localFieldValues"
          :notRemoveRow="true"
          :showTitle="false"
          @yesButtonClick="updateLookupValues"
          @noButtonClick="cancelLookup"
        />
      </div>
    </VasionGeneralModal>

    <VasionGeneralModal
      id="save-draft-modal"
      modalType="slot"
      :modalTitle="'Save Draft'"
      confirmButtonText="Save"
      rejectButtonText="Cancel"
      :sync="showDraftDialog"
      :showTopRightCloseButton="false"
      :showConfirmButton="true"
      :showRejectButton="true"
      @confirmButtonClick="saveFormDraft"
      @noButtonClick="cancelFormDraftSaving"
    >
      <div class="draft-modal-container">
        <VasionInput
          id="form-draft-name"
          v-model="formDraftName"
          name="form-draft-name"
          title="Draft Name"
          placeholder="Draft Name..."
          inputType="top-white"
          width="100%"
          :autoFocus="true"
        />
      </div>
    </VasionGeneralModal>
    
    <VasionAsynchronousConfirmDialog ref="confirm" confirmText="Yes" cancelText="No" />
  </div>
</template>
<script>
import { Form } from 'vue-formio';
import Loading from 'vue-loading-overlay';
import { toBase64 } from '@/store/helperModules/storage.module'
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import MaxxFormDisplay from '@/components/forms/MaxxFormDisplay.vue'
import { findVasionComponents } from '@/store/helperModules/forms.module'
import moment from 'moment'
import { isMobileSize, isIPad, isSafari } from '@/store/helperModules/common.module'
import { formatDateTime } from '@/store/helperModules/common.module'

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import MultipleLookups from '@/components/vault/MultipleLookups.vue'
import VasionAsynchronousConfirmDialog from '@/components/shared/VasionAsynchronousConfirmDialog.vue'

export default {
  name: 'StartWorkflowForm',
  components: {
    formio: Form,
    Loading,
    MaxxFormDisplay,
    MultipleLookups,
    VasionAsynchronousConfirmDialog,
  },
  props: {
    formLayout: {
      type: Object,
      default: () => {},
      required: true,
    },
    workflowID: {
      type: Number,
      default: 0,
      required: false,
    },
    inPreviewMode: {
      type: Boolean,
      default: false,
      required: false
    },
    fromMyForms: {
      type: Boolean,
      default: false,
      required: false
    },
    fromFormLayout: {
      type: Boolean,
      default: false,
      required: false
    },
    loadDraftId: {
      type: Number,
      default: 0,
      required: false,
    },
    loadFormId: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  data: function () {
    return {
      activeSignature: '',
      canModifyDueDate: false,
      containsFormioJson: false,
      currentDate: new Date(),
      currentLookupId: null,
      currentLookupIndex: null,
      disabledDates: date => {
        return date < this.currentDate
      },
      doneCreating: false,
      dropdownsIds: [],
      dueDate: '',
      dueDateLabel: '',
      filterFieldsList: [],
      filterUpdate: false,
      formDraftData: {},
      formDraftName: '',
      formID: 0,
      formioDataJSON: {},
      formioFields: {
        display: `${$formsLabel}`,
        components: [],
      },
      formIOLoadedOnce: false,
      formIsDirty: false,
      fullPage: true,
      hasSignature: false,
      intervalAutoSaveDuration: 60000,
      intervalAutoSaveId: null,
      isAnonymous: false,
      isAutoSaved: false,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      localFieldValues: [],
      maxxFormComponent: 0,
      maxxFormValid: true,
      pdfLayout: 'Portrait',
      refreshKey: 1,
      savingDraft: false,
      selectedFileUploadFieldValue: {
        name: '',
      },
      serverOptions: {
        process: this.processHandler,
      },
      showDatePicker: false,
      showDraftDialog: false,
      showErrorText: false,
      showLookupModal: false,
      showMultipleLookups: false,
      showUploadDialog: false,
      showWarnLabel: false,
      showSnackbar: false,
      showSignatureForWizard: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      submitProceeded: true,
      updateFormioJSONAlreadyRun: false,
      uploadedFileFieldValue: '',
      uploadedFileName: '',
      uploadedFileNameList: [],
      uploadedFilesList: [],
      uploadedFileString: '',
      uploadedFileStringList: [],
      uploadedFileId: [],
      uploadedFileSize: [],
      zoomDepth: 0,
    }
  },
  computed: {
    allowedFileExtensions() {
      let fileExtensions = this.formLayout.FileExtensions
      if (!fileExtensions)
        return ""
      fileExtensions = fileExtensions.toLowerCase().replaceAll(",", ", .")
      fileExtensions = fileExtensions.replace(/, .([^, .]*)$/, ', or .$1')
      return `Allowed File Types: .${fileExtensions}`
    },
    base64DataPrefix() { return this.$store.state.digitalSignature.base64DataPrefix },
    checkLogoImgNeedsData() {
      let returnVal
      if (this.formLayout.HeaderImageBase64) {
        if (this.formLayout.HeaderImageBase64.startsWith('data:image')) {
          returnVal = this.formLayout.HeaderImageBase64
        } else {
          returnVal = `data:image/png;base64, ${this.formLayout.HeaderImageBase64}`
          // I know this isn't going to work for all cases but I think moving forward we should just return the entire string including the prefix.
        }
      }
      return returnVal
    },
    currentRouteName() { return this.$route.name },
    fileUploadedFieldOptions() {
      const options = []
      if (this.supportsFileUploadField === false || !this.formLayout.UploadIndexFieldOptions || this.formLayout.UploadIndexFieldOptions.length === 0) {
        return options
      }
      options.push({
        name: '',
      })
      this.formLayout.UploadIndexFieldOptions.map((element) => {
        options.push({
          name: element,
        })
        return true
      })
      return options
    },
    formIsValid() {
      let isValid = true
      if (this.formLayout?.RequiresSignature) {
        isValid = isValid && this.hasSignature
      }
      if (this.formLayout?.RequireUploadFiles) {
        isValid = isValid && this.uploadedFilesList.length > 0
      }
      return isValid && this.maxxFormValid
    },
    formName() { return this.formLayout && this.formLayout.Name !== '' ? this.formLayout.Name : `Unknown ${$layoutsLabel}` },
    formType() {
      let formType = null
      if (this.formioFields.display === 'form') formType = 'Form'
      else if (this.formioFields.display === 'wizard') formType = 'Wizard'
      else if (this.formioFields.display === 'pdf') formType = 'PDF'

      return formType
    },
    isMyForm() { return this.currentRouteName === 'MyForm' },
    isFormDraft() { return this.currentRouteName === 'MyDraftForm' },
    isSignedIn() { return this.$store.state.common.isSignedIn },
    isWorkflowDraft() { return this.loadDraftId > 0 },
    isFromMobile() { return this.$route.query.isFromMobile ? this.$route.query.isFromMobile : false },
    pdfMode() { return this.formioFields?.display === 'pdf' },
    pdfFormioConfig() { return this.pdfMode ? { zoom: this.zoom, sanitizeConfig: {addTags: ['iframe'], ALLOWED_TAGS: ['iframe']}} : {sanitizeConfig: {addTags: ['iframe'], ALLOWED_TAGS: ['iframe']}}},
    showLogo() { return this.formLayout?.HeaderImageBase64?.length > 0},
    showLookupBtn() { return this.formLayout.DBLookupIDs !== null && this.formLayout.ShowLookupButton },
    showSaveDraftBtn() { return this.fromMyForms || this.fromFormLayout },
    showSubmitBtn() { return !this.wizardMode || (this.wizardMode && this.showSignatureForWizard) },
    supportsFileUpload() { return this.formLayout?.AllowUploadFiles || this.formLayout?.RequireUploadFiles },
    supportsFileUploadField() { return this.formLayout && this.formLayout.UploadIndexFieldName && this.formLayout.UploadIndexFieldName !== '' },
    userSignature() { return this.$store.state.digitalSignature.userSignature ? `${this.base64DataPrefix}${this.$store.state.digitalSignature.userSignature}` : '' },
    wizardMode() { return this.formioFields?.display === 'wizard' },
    zoom() {
      if (isSafari() && isIPad()) return 0
      const landscapeSize = this.$refs.mainSection.offsetWidth * 0.064 - 101
      const portraitSize = this.$refs.mainSection.offsetWidth * 0.09 - 106
      if (isMobileSize())
        return this.pdfLayout === 'Portrait' ? -50 : -68
      return this.pdfLayout === 'Portrait' ? portraitSize : landscapeSize
    },
    zoomInLimit() {
      if (isSafari() && isIPad()) return 0
      if (isMobileSize()) return 50
      return 80
    },
    zoomOutLimit() {
      if (isSafari() && isIPad()) return 0
      if (isIPad()) return -4
      if (isMobileSize()) return -1
      return -7
    }
  },
  watch: {
    refreshKey() { this.wizardChangePage() }
  },
  async created() {
    this.formID = this.loadFormId > 0 ? this.loadFormId : this.$route.params.formID
    this.currentDate.setHours(0, 0, 0, 0)
    const workflowSettings = await this.$store.dispatch('workflow/getWorkflowSettingsNoCommit', this.workflowID)
    this.canModifyDueDate = workflowSettings?.canModifyDueDate

    this.$material.locale.dateFormat = this.$store.state.systemSettings.systemDateFormat
    if (this.workflowID) {
      const dueDateCalculation = await this.$store.dispatch('workflow/calculateDueDateAndSettings', this.workflowID)
      this.dueDate = dueDateCalculation.dueDate
      this.dueDateLabel = dueDateCalculation.dueDateLabel
      if (this.dueDate !== null) this.showDatePicker = true
    }

    if(!this.inPreviewMode){
      await this.$store.dispatch('forms/getEForm', this.formID)
    }

    await this.checkIfSignatureIsRequired()
    this.doneCreating = true
    await this.wizardChangePage()
    if (this.pdfMode) this.getPDFLayout()

    this.filterFieldsList = await this.$store.dispatch('document/hasFilterFields', this.formLayout.IndexFormID)

    if (this.pdfMode) {
      document.querySelector('span[ref="zoomIn"]').addEventListener("click", this.zoomInController)
      document.querySelector('span[ref="zoomOut"]').addEventListener("click", this.zoomOutController)
    }

    this.checkHeaderImage()
    
    // This Event Listener is added for use by the mobile team. They can attach a file/image natively and then dispatch this event
    if (this.isFromMobile) {
      document.addEventListener("mobileApp:fileAttached", (ev) => {
        if (ev?.detail?.files) {
          Array.prototype.forEach.call(ev.detail.files, fileInfo => {
            this.uploadFile(fileInfo.base64, {name: fileInfo.fileName, size: fileInfo.fileSize}, fileInfo.fileName)
          })
        }
      })
      document.addEventListener("mobileApp:leaveForm", async () => {
        if(this.formIsDirty){
          let res = await this.$root.confirm.open({
            message: 'Are you sure you want to leave? Changes have not been saved.',
          })
          if (res) {            
            window.location = "vasion://forms/close"            
          } else {
            window.location = "vasion://forms/stay"
          }
        }
      })
    }

    if (this.isFormDraft || this.isWorkflowDraft) await this.getFormDraftData()

    this.isAnonymous = this.formLayout.IsAnonymous
    this.isAutoSaved = this.formLayout.IsAutoSaved
    
    if (this.fromMyForms && !this.inPreviewMode && this.formioFields?.display && !this.isFormDraft && !this.isWorkflowDraft) this.logFormOpen()
  },
  beforeDestroy() {
    this.$root.$off('update-zoom', this.updateZoom)
    
    this.removeInjectedStyles()

    if (this.intervalAutoSaveId) this.stopAutoSaveInterval()
    if (this.dropdownsIds.length > 0) {
      this.dropdownsIds.forEach(id => {
        clearInterval(id)
      })
    }
  },
  mounted(){
    if(this.inPreviewMode){
      this.setFormioJson()
    }
    this.$root.confirm = this.$refs.confirm;
    this.$root.$on('update-zoom', this.updateZoom)
  },
  updated: function () {
    if (!this.isFormDraft) this.$store.dispatch('mainViews/changeMainNav', `my-form-${this.formID}`)
    if (this.formLayout.FormIOJSON && this.containsFormioJson === false) {
      this.setFormioJson()
    }
  },
  methods: {
    addClassNameListener(elem, callback) {
      let lastClassName = elem.className
      const setIntervalId = setInterval( () => {
        let className = elem.className
        if (className !== lastClassName) {
          callback();
          lastClassName = className
        }
      },10)
      this.dropdownsIds.push(setIntervalId)
    },
    attachFileClicked() {
      if (this.isFromMobile) {
        // With the Mobile view of the form, we needed another way to attach a file. This attempts to change the URL, which the 
        // mobile app will prevent, but this specific url will signal that they should attach a file instead    
        window.location = "app://attach"
      } else {
        this.showUploadDialog = true
      }
    },
    allFilesUploaded() {
      this.showUploadDialog = false
    },
    async backToSelectWorkflow() {
      if(this.inPreviewMode){
        this.showPreviewModeSnackbar()
        return
      }
      if (!this.formIsDirty) {
        if (this.formDraftData.EFormDraftId && !this.isWorkflowDraft) {
          let res = await this.$root.confirm.open({
            message: 'Are you sure you want to leave and select another workflow? Your data has been saved as a draft.',
          })
          if (res) {
            this.$emit('backButtonClick')
          }
          return
        }
        this.$emit('backButtonClick')
        return
      }
      let res = await this.$root.confirm.open({
        message: 'Are you sure you want to leave and select another workflow? Any unsaved data will be lost.',
      })
      if (res) {
        this.$emit('backButtonClick')
        return
      }
    },
    cancelFormDraftSaving() {
      this.formDraftName = ''
      if (this.formDraftData.DraftName) this.formDraftName = this.formDraftData.DraftName
      this.showDraftDialog = false
    },
    cancelLookup() {
      this.showMultipleLookups = false
      this.runDBLookup()
    },
    async cancelForm() {
      if(this.inPreviewMode){
        this.showPreviewModeSnackbar()
        return
      }
      if (!this.formIsDirty) {
        if (this.formDraftData.EFormDraftId && !this.isWorkflowDraft) {
          let res = await this.$root.confirm.open({
            message: 'Are you sure you want to cancel? Your data has been saved as a draft.',
          })
          if (res) {
            this.$emit('cancelButtonClick')
          }
          return
        }
        this.$emit('cancelButtonClick')
        return
      }
      let res = await this.$root.confirm.open({
        message: 'Are you sure you want to cancel? Any unsaved data will be lost.',
      })
      if (res) {
        this.$emit('cancelButtonClick')
        return
      }
    },
    checkAutoSaveCondition() {
      return this.isAutoSaved && this.formIsDirty && !this.showDraftDialog && !this.$store.state.forms.dirtyFormModalShowing
    },
    async checkIfSignatureIsRequired() {
      if (this.formLayout.RequiresSignature) {
        if(this.$store.state.common.apiToken) {
          await this.$store.dispatch('digitalSignature/getUserSignatureForStore')
        }
      }
    },
    checkSubmitProceeded() {
      // If the submit didn't proceed, there was an error--assume it was required fields not being filled
      if (!this.submitProceeded) {
        this.showErrorText = true
      }
    },
    checkHeaderImage() {
      const headerLogo = document.querySelector('#header-logo')
      if (this.showLogo) {
        if (headerLogo.offsetHeight > 0) {
          this.defineDeepDynamicHeightClass(headerLogo)
          return
        }
        headerLogo.onload = function () { this.defineDeepDynamicHeightClass(headerLogo) }
      }
    },
    clearUploadedFileLists() {
        this.uploadedFileStringList = []
        this.uploadedFileNameList = []
    },
    async customizeComponents(components) {
      let fomrioData = JSON.parse(components)
      fomrioData?.components.forEach( (component, index) => {
        if (this.isFromMobile) {
          if (component?.type === 'datetime') {
            fomrioData.components[index].allowInput = false
          }
          if (component?.type === 'select') {
            fomrioData.components[index].searchEnabled = false
          }
        }
      });
      return fomrioData
    },
    async customizeRenderedComponents() {
      this.$refs.formObject?.formio.components.forEach(component => {
        if (component.type === 'select') {
          if (component.choices._isSelectMultipleElement === true && component.focusableElement) {
            component.focusableElement.setAttribute("readonly", "readonly")
            component.focusableElement.style.cursor = 'pointer'
          }
        }
      });
    },
    defineDeepDynamicHeightClass(headerLogo) {
      let extraOffset = this.$route.name === 'plainForm' && this.showLogo ? 266 : 378
      if (this.isFromMobile) {
         extraOffset -= 146
      }
      let htmlDiv = document.createElement('div')
      htmlDiv.innerHTML = `<p>not relevant</p><style data-injectedstyle="true">.pdf-form-container-wh-height .formio-form-pdf iframe{ height: calc(100vh - ${headerLogo.offsetHeight + extraOffset}px) !important; }</style>`
      document.getElementsByTagName('head')[0].appendChild( htmlDiv.childNodes[1] )
    },
    formioRendered(element) {
      if (element instanceof HTMLElement) {
        const dropdowns = element.getElementsByClassName('choices__list')
        if (dropdowns.length > 0) {
          for (let index = 0; index < dropdowns.length; index++) {
            const dropdown = dropdowns[index]
            this.addClassNameListener(dropdown, () => {
              const hideTextarea = dropdown.classList.contains('is-active') ? true : false
              const textareas = element.getElementsByClassName('formio-component-textarea')
              for (let index = 0; index < textareas.length; index++) {
                const textarea = textareas[index]
                hideTextarea ? textarea.classList.add('hide-textarea') : textarea.classList.remove('hide-textarea')
              }
            })
          }
          
        }
      }
    },
    async getFormDraftData() {
      this.isLoading = true

      const draftId = this.loadDraftId > 0 ? this.loadDraftId : this.$route.params?.draftId
       
      this.formDraftData = await this.$store.dispatch('forms/getEFormDraftByDraftId', draftId)
      this.formDraftName = this.formDraftData.DraftName

      this.setFormioValues(await JSON.parse(this.formDraftData.FormIOJSON), this.$refs.formObject?.formio)

      this.formDraftData.DraftAttachments.forEach(attachment => {
        this.uploadedFileNameList.push(attachment.OriginalFileName)
        this.uploadedFilesList.push(new File([], attachment.OriginalFileName, { type: 'file' }))
        this.uploadedFileStringList.push('')
        this.uploadedFileId.push(attachment.ID)
        this.uploadedFileSize.push(attachment.FileSize)
      })

      if (this.isWorkflowDraft) await this.$store.dispatch('mainViews/changeMainNav', 'form-drafts-view')

      this.isLoading = false
    },
    getPDFLayout() {
      const pdfData = JSON.parse(this.formLayout.FormIOJSON)
      if (pdfData?.settings?.layout) this.pdfLayout = pdfData.settings.layout
      if (this.pdfLayout === 'Landscape') this.refreshKey += 1
    },
    async logFormOpen() {
      let formType = this.formType, formViewType = null
      if (this.containsFormioJson) formViewType = 'WebView'
      else formViewType = 'Legacy'
      
      if (!formType || !formViewType) return

      const properties = {
        formType,
        formViewType,
      }
      if (!this.isFromMobile) await this.$amplitude.trackEvent('Form Opened', properties)
    },
    async logFormSubmission() {
      let formType = this.formType, isFormDraft = false

      if (this.formDraftData.EFormDraftId) isFormDraft = true
      
      if (!formType) return

      const properties = {
        formType,
        isFormDraft,
      }
      if (!this.isFromMobile) await this.$amplitude.trackEvent('Form Submitted', properties)
    },
    async okApplySignatureClick(signatureString) {
      this.activeSignature = signatureString
      await this.startWorkflow()
    },
    async prepareFormDraftSaving() {
      if (!this.formDraftName) {
        this.formDraftName = `${this.formName}_${formatDateTime(new Date(), 'datetime')}`
      }
      this.showDraftDialog = true
    },
    processHandler: async function (fieldName, file, metadata, load, error, progress, abort) {
      try {
        if (this.formLayout?.FileExtensions.length > 0) {
          let allowedFileExtensions = this.formLayout.FileExtensions.toLowerCase().replaceAll(',', '|')
          const matcher = new RegExp(`.(${allowedFileExtensions})$`, "i")
          const isFileExtensionValid = file.name.match(matcher)
          
          if (!isFileExtensionValid) {
            error(true)
            allowedFileExtensions = allowedFileExtensions.replace(/\|([^|]*)$/, ', or .$1')
            allowedFileExtensions = allowedFileExtensions.replaceAll("|", ", .")

            this.snackbarTitle = 'Upload error'
            this.snackbarSubTitle = `Only .${allowedFileExtensions} file types are supported`
            this.snackbarImage = false
            this.showSnackbar = true
            return
          }
        }
        if (this.formLayout?.MaxFileSize !== null) {
          const fileSizeLimit = this.formLayout.MaxFileSize
          const fileSize = file.size / 1024 / 1024
          if (fileSizeLimit < fileSize) {
            error(true)
            this.snackbarTitle = 'Upload error'
            this.snackbarSubTitle = `Only files smaller than ${fileSizeLimit} MB are supported.`
            this.snackbarSubTitle += ` File size received ${fileSize.toFixed(2)} MB`
            this.snackbarImage = false
            this.showSnackbar = true
            return
          }
        }
        this.uploadedFileString = await toBase64(file)
        this.uploadedFileName = file.name
        await this.uploadFile(this.uploadedFileString, file, this.uploadedFileName)

        progress(true, 0, 1024)
        load(file.name)

        return {
          abort: () => {
            abort();
          },
        };
      } catch (error) {
        console.warn(error)
      }
    },    
    removeInjectedStyles() {
      const injectedStyles = document.querySelectorAll('style[data-injectedstyle="true"]')
      injectedStyles.forEach(element => {
        element.remove()
      })
    },
    async removeUploadedFile(index) {
      this.uploadedFileStringList.splice(index, 1)
      this.uploadedFileNameList.splice(index, 1)
      this.uploadedFilesList.splice(index, 1)
      this.uploadedFileId.splice(index, 1)
      this.uploadedFileSize.splice(index, 1)
      this.formIsDirty = true
      await this.$store.dispatch('forms/setFormIsDirty', true)
    },
    async resetFormValues() {
      this.uploadedFilesList = []
      this.activeSignature = ''
      this.$refs.signatureField.ref_clear()
      await this.checkIfSignatureIsRequired()
      this.clearUploadedFileLists()
      this.maxxFormComponent = 1 // Used to clear any remaining form inputs
    },
    async runDBLookup() {
      if(this.inPreviewMode){
        this.showPreviewModeSnackbar()
        return null
      }
      if (this.formLayout.DBLookupIDs === undefined || this.formLayout.DBLookupIDs.length < 1)  return

      this.currentLookupIndex = (!this.currentLookupIndex && this.currentLookupIndex != 0) ? 0 : this.currentLookupIndex+1
      this.currentLookupIndex = (this.currentLookupIndex > this.formLayout.DBLookupIDs.length - 1) ? null : this.currentLookupIndex
      if (this.currentLookupIndex === null) {
        this.showLookupModal = false
        this.showMultipleLookups = false
        return
      }

      this.currentLookupId = this.formLayout.DBLookupIDs[this.currentLookupIndex]

      await this.runSingleDBLookup(this.currentLookupId);
    },
    triggerAutoSaveAction() {
      if (!this.formDraftName) this.formDraftName = `${this.formName}_${moment().format("M/D/YYYY_h:mm A")}`
      this.saveFormDraft(true)
    },
    async updateFormioDataJSON(newData) {
      if (!this.formioDataJSON.data)  this.formioDataJSON.data = {}

      this.formioFields.components.forEach(formField => {
        newData.Columns.Values.forEach((lookupField, lookupFieldIndex) => {
          if (lookupField.startsWith('hidden_')) {
            const lookupKey = lookupField.replace("hidden_", "")
            if (lookupKey === formField.vasionUniqueFieldName) {
              const fieldValue = newData.Rows.Values[0].Values[lookupFieldIndex]
              this.formioDataJSON.data[formField.key] = fieldValue
            }
          }
        });
      })
    },
    async runSingleDBLookup(DBLookupID) {
      this.isLoading = true
      const obj = {
        data:  this.formioDataJSON.data || {}
      }
      const payload = {
        eformID: this.formLayout.EFormID,
        submittedFormIOJSON: JSON.stringify(obj),
        lookupId: DBLookupID,
        clientTimeZone: moment().format('Z'),
        workflowID: this.workflowID,
      }

      const response = await this.$store.dispatch('document/runEFormLookup', payload)

      if (response && response.lookupResults.Rows.Values.length === 1) {
        this.formioFields = await JSON.parse(response.newFormIOFormJSON)
        this.refreshKey += 1
        await this.updateFormioDataJSON(response.lookupResults)
        this.runDBLookup()
      } else if (response && response.lookupResults.Rows.Values.length > 1) {
        this.showMultipleLookups = true
        this.showLookupModal = true
        const rowsForTable = response.lookupResults.Rows.Values.map(v => {
          return v.Values
        })
        this.localFieldValues = {
          Columns: response.lookupResults.Columns.Values,
          Rows: rowsForTable,
        }
      } else {
        this.showMultipleLookups = true
        this.showLookupModal = true
        this.showWarnLabel = true
        this.localFieldValues = {
          Columns: [],
          Rows: [],
        }
      }
      this.isLoading = false
    },
    addAttachmentsToPayload(payload) {
      let draftAttachments = []
      let uploadFileList = []
      let uploadFileNameList = []
      if (this.uploadedFileNameList) {
        this.uploadedFileNameList.forEach((filename, index) => {
          if (this.uploadedFileId[index] > 0) {
            draftAttachments.push({ID: this.uploadedFileId[index], OriginalFilename: filename})
          }
          else {
            uploadFileList.push(this.uploadedFileStringList[index])
            uploadFileNameList.push(filename)
          }
        })
      }
      payload.UploadFileList= uploadFileList
      payload.UploadFileNameList= uploadFileNameList
      payload.DraftAttachments= draftAttachments

      return payload;
    },
    recurseContainers(component, newValues) {
      if (component.columns) {
        component.columns.forEach(column => {
          if (column && Array.isArray(column)) {
            column.forEach(subComponent => {
              if (subComponent.path in newValues.data) {
                subComponent.setValue(newValues.data[subComponent.path])
              }
              this.recurseContainers(subComponent, newValues)
            })
          }
        })
      }
      if (component.components) {
        component.components.forEach(subComponent => {
          if (subComponent.path in newValues.data) {
            subComponent.setValue(newValues.data[subComponent.path])
          }
          this.recurseContainers(subComponent, newValues)
        })
      }
    },
    async saveFormDraft(fromAutoSave = false) {
      this.savingDraft = true
      this.showDraftDialog = false
      const data = this.$refs.formObject.formio._data

      this.stopAutoSaveInterval()

      let payload = {
        EFormID: this.formLayout.EFormID,
        UploadFieldValue: this.uploadedFileFieldValue,
        FormIOJSON: JSON.stringify({ data: data }),
        ReturnDocumentID: true,
        workflowDueDate: this.dueDate,
        DefaultSignature: this.activeSignature,
        WorkflowId: this.workflowID,
        DraftName: this.formDraftName,
      }
      if (this.formDraftData.EFormDraftId) {
        payload.EFormDraftID = this.formDraftData.EFormDraftId
      }
      payload = this.addAttachmentsToPayload(payload)
      if (!fromAutoSave) {
        if (this.isFromMobile) {
          window.location = "vasion://forms/saved"
        }
        else {
          await this.$amplitude.trackEvent('Form Draft Saved', {} )
        }
        this.isLoading = true
      }
      const response = await this.$store.dispatch('forms/saveEFormDraft', payload)

      if (response && response?.EFormDraftId) {
        if (!fromAutoSave) {
          this.snackbarTitle = "Form Draft"
          this.snackbarSubTitle = "The form draft was successfully saved"
          this.snackbarImage = true
          this.showSnackbar = true
        }
        this.formDraftData = response
        await this.$store.dispatch('forms/setFormDrafName', this.formDraftName)
        this.formDraftData.DraftAttachments?.forEach((attachment, index) => {
          this.uploadedFileId[index] = attachment.ID
        })
      } else if (!fromAutoSave) {
        this.snackbarTitle = "Form Draft"
        this.snackbarSubTitle = response?.ResultMessage ? response?.ResultMessage : `An error occurred during the form draft saving`
        this.snackbarImage = false
        this.showSnackbar = true
      }

      this.savingDraft = false
      if (!fromAutoSave) this.isLoading = false

      this.formIsDirty = false
      await this.$store.dispatch('forms/setFormIsDirty', false)
    },
    setDueDate() {
      if(this.inPreviewMode){
        return
      }
      if (this.dueDate && this.dueDate !== '') {
        this.$store.dispatch('workflow/setWorkflowDueDate', this.dueDate)
      }
    },
    async setFormioJson() {
      if (this.formLayout.FormIOJSON && !this.filterUpdate) {
        this.containsFormioJson = true
        const newformIOData = await this.customizeComponents(this.formLayout.FormIOJSON)
        this.formioFields = newformIOData
      }
    },
    async setFormioValues(newValues, formio) {
      if (!newValues?.data) return
      if (!formio?.components) return

      formio.components.forEach(component => {
        if (component.path in newValues.data) {
          component.setValue(newValues.data[component.path])
        }
        this.recurseContainers(component, newValues)
      })
    },
    signatureChanged(hasSig) {
      this.hasSignature = hasSig
    },
    startAutoSaveInterval() {
      if (!this.intervalAutoSaveId) {
        this.intervalAutoSaveId = setInterval(() => {
          if (this.checkAutoSaveCondition()) {
            this.triggerAutoSaveAction()
          }
        }, this.intervalAutoSaveDuration)
      }
    },
    async startWorkflow() {
      if(this.inPreviewMode){
        this.showPreviewModeSnackbar()
        return null
      }
      if (this.formLayout.RequiresSignature && !this.activeSignature) {
        await this.$refs.signatureField.ref_attemptToSign()
        return
      }
      this.setDueDate()
      if (this.showDatePicker && this.canModifyDueDate && this.dueDate && (new Date(this.dueDate) < this.currentDate)) {
        this.snackbarTitle = 'Workflow Due Date is invalid'
        this.snackbarSubTitle = 'The Workflow Due Date has already passed.'
        this.snackbarImage = false
        this.showSnackbar = true
        return
      }

      if (this.containsFormioJson) {
        this.submitProceeded = false
        
        this.$refs.formObject.formio.submit()
        setTimeout(() => {
          this.checkSubmitProceeded()
        }, 750)

        return
      }

      if (this.fileUploadedFieldOptions.length > 0) {
        this.uploadedFileFieldValue = this.selectedFileUploadFieldValue ? this.selectedFileUploadFieldValue.name : ''
      }
      const success = await this.$refs.maxxFormComponent.submitForm(this.uploadedFileStringList, this.uploadedFileNameList, this.uploadedFileFieldValue)
      if (success) {
        this.$emit('workflowStarted')
      } else {
        this.$emit('eFormSubmitFailed')
      }

      this.resetFormValues()
    },
    async stopAutoSaveInterval() {
      if (!this.intervalAutoSaveId) return
      clearInterval(this.intervalAutoSaveId)
      this.intervalAutoSaveId = null
      this.updateFormioJSONAlreadyRun = false
      this.formIsDirty = false
      await this.$store.dispatch('forms/setFormIsDirty', false)
    },
    showPreviewModeSnackbar() {
      this.showRawHtml = false
      this.snackbarTitle = "Preview Mode"
      this.snackbarSubTitle = "Cannot perform this action in the Preview Mode"
      this.snackbarImage = false
      this.showSnackbar = true
    },
    async submitEForm(submission) {
      if(this.inPreviewMode){
        this.showPreviewModeSnackbar()
        return
      }
      this.submitProceeded = true

      if (!submission?.data) {
        return
      }

      this.formIsDirty = false
      await this.$store.dispatch('forms/setFormIsDirty', false)

      this.stopAutoSaveInterval()

      if (this.savingDraft) {
        let payload = {
          EFormID: this.formLayout.EFormID,
          UploadFieldValue: this.uploadedFileFieldValue,
          FormIOJSON: JSON.stringify({ data: submission.data }),
          ReturnDocumentID: true,
          workflowDueDate: this.dueDate,
          DefaultSignature: this.activeSignature,
          WorkflowId: this.workflowID,
          DraftName: this.formDraftName,
        }
        if (this.formDraftData.EFormDraftId) {
          payload.EFormDraftID = this.formDraftData.EFormDraftId
        }
        payload = this.addAttachmentsToPayload(payload)

        this.isLoading = true
        const response = await this.$store.dispatch('forms/saveEFormDraft', payload)

        if (response && response?.EFormDraftId) {
          this.snackbarTitle = "Form Draft"
          this.snackbarSubTitle = "The form draft was successfully saved"
          this.snackbarImage = true
          this.showSnackbar = true
          
          this.formDraftData = response
          await this.$store.dispatch('forms/setFormDrafName', this.formDraftName)
        } else {
          this.snackbarTitle = "Form Draft"
          this.snackbarSubTitle = response?.ResultMessage ? response?.ResultMessage : `An error occurred during the form draft saving`
          this.snackbarImage = false
          this.showSnackbar = true
        }

        this.savingDraft = false
        this.isLoading = false
        return
      }

      let submitFormPayload = {
        EFormID: this.formLayout.EFormID,
        UploadFieldValue: this.uploadedFileFieldValue,
        FormIOJSON: JSON.stringify({ data: submission.data }),
        ReturnDocumentID: true,
        workflowDueDate: this.dueDate,
        DefaultSignature: this.activeSignature,
        WorkflowId: this.workflowID,
      }
      if (this.formDraftData.EFormDraftId) {
        submitFormPayload.EFormDraftID = this.formDraftData.EFormDraftId
      }
      submitFormPayload = this.addAttachmentsToPayload(submitFormPayload)
      this.isLoading = true
      const error = await this.$store.dispatch('maxxForms/submitMaxxForm', submitFormPayload)

      this.logFormSubmission()

      if (!error) {
        if(this.formID){
          this.$emit('eFormSubmitted')
          this.resetFormValues()
          this.containsFormioJson = false
          if (this.isWorkflowDraft) {
            this.$emit('cancelButtonClick')
          }
          else
          {
            this.$router.push({ name: 'SubmissionComplete' })
          }
        }
        else {
          this.$emit('workflowStarted')
          this.resetFormValues()
          this.containsFormioJson = false
        }
      } else {
        this.$emit('eFormSubmitFailed')
        this.showRawHtml = false
        this.snackbarTitle = "Error"
        this.snackbarSubTitle = error
        this.snackbarImage = false
        this.showSnackbar = true
      }
      this.isLoading = false
      // eslint-disable-next-line
      return !error
    },
    async uploadFile(base64File, file, fileName) {
      this.uploadedFileStringList.push(base64File)
      this.uploadedFileNameList.push(fileName)
      this.uploadedFilesList.push(file)
      this.uploadedFileId.push(0)
      this.uploadedFileSize.push(0)
      this.formIsDirty = true
      await this.$store.dispatch('forms/setFormIsDirty', true)
    },
    async updateFormioJSON(submission) {
      if (!this.formIOLoadedOnce && this.isWorkflowDraft) {
        this.formIOLoadedOnce = true
        return
      }

      if (this.isFromMobile && submission.changed === undefined) { await this.customizeRenderedComponents() }

      if(this.inPreviewMode){
        return
      }

      setTimeout(() => { 
        this.wizardChangePage()
      }, 200);

      this.showWarnLabel = false

      if (!submission || !submission.data) return

      if (!this.$route.params?.draftId && !this.updateFormioJSONAlreadyRun && submission.changed) {
        this.updateFormioJSONAlreadyRun = true
      }
  
      if (this.updateFormioJSONAlreadyRun && submission.changed) {
        this.formIsDirty = true
        await this.$store.dispatch('forms/setFormIsDirty', true)
      }

      if (this.$route.params?.draftId && !this.updateFormioJSONAlreadyRun) {
        this.updateFormioJSONAlreadyRun = true
      }

      if (this.isAutoSaved && this.updateFormioJSONAlreadyRun && !this.savingDraft) {
        this.startAutoSaveInterval()
      }
      
      if (!submission.changed || submission.changed.component.type !== 'select' || this.pdfMode || !submission.changed.component.vasionUniqueFieldName ) {
        this.formioDataJSON = submission
        return
      }
      
      // START OF: Filter Logic
      // Get all dropdown/select fields prepped for payload
      let fieldValues = []
      let rawFieldValues = []
      let updatedFields = false
      const vasionComponents = findVasionComponents(this.formioFields)
      vasionComponents.forEach(field => {
        if (field.type === 'select' && this.filterFieldsList.includes(field.vasionUniqueFieldName)) {
          Object.entries(submission.data).forEach(item => {
            if (item[0] === field.key) {
              // Final values to be used for payload
              fieldValues.push({
                Key: field.vasionUniqueFieldName,
                Value: Array.isArray(item[1]) ? item[1].join(';') : item[1]
              })
              rawFieldValues.push({
                Key: field.vasionUniqueFieldName,
                Value: item[1]
              })
            }
          })
        }
      })
      // Make updates to all dropdowns/selects that need them
      let count = 0
      while (count < fieldValues.length) {
        let tempValues = [] // These values will be used to loop through the dropdowns with the correct payload
        fieldValues.forEach(field => {
          if (field !== fieldValues[count]) tempValues.push(field)
        })
        let payload = {
          IndexFormID: this.formLayout.IndexFormID,
          FilterFieldName: fieldValues[count].Key,
          BaseFieldValues: {
            Values: tempValues,
          },
        }
        let filterResult = await this.$store.dispatch('document/getFilterFields', payload)

        // Update the values
        if (filterResult.FieldValues.length > 0) {
          let resultValues = []
          filterResult.FieldValues.forEach(val => {
            resultValues.push({
              label: val,
              value: val,
            })
          })
          vasionComponents.forEach(field => {
            if (field.vasionUniqueFieldName === fieldValues[count].Key) {
              updatedFields = true

              submission.data[field.key] = ''
              if (this.pdfMode && submission.metadata.selectData) {
                delete submission.metadata.selectData[field.key]
              }
              field.data.values = resultValues
              // Auto select value in dropdowns that only have one value
              if (filterResult.FieldValues.length === 1 && submission.changed.component.key != field.key) {
                submission.data[field.key] = field.multiple ? filterResult.FieldValues : filterResult.FieldValues[0]
              }
            }
          })
        }
        count++
      }
      if (!updatedFields) return

      // Now that all dropdowns are updated, remove any items that are no longer in the elements' filtered lists
      vasionComponents.forEach(field => {
        if (field.type === 'select' && this.filterFieldsList.includes(field.vasionUniqueFieldName)) {
          let fValues = rawFieldValues.find(cfv => cfv.Key == field.vasionUniqueFieldName)
          if (!fValues) {
            return
          }
          if (!submission.data[field.key]) {
            if (Array.isArray(fValues.Value)) {
              submission.data[field.key] = fValues.Value.filter(el => field.data.values.some(val => val.value?.toString() === el?.toString()))
            } else {
              if (field.data.values.some(val => val.value == fValues.Value)) {
                submission.data[field.key] = fValues.Value
              }
            }
          }
          if (this.pdfMode && submission.data[field.key] && submission.metadata.selectData) {
            submission.metadata.selectData[field.key] = submission.data[field.key]
          }
          field.defaultValue = []
        }
      })
      
      this.formioDataJSON = submission
      if (!this.filterUpdate) {
        this.filterUpdate = true
        const updatedFormIOJSON = JSON.stringify(this.formioFields)
        this.formioFields = JSON.parse(updatedFormIOJSON)
      }
      setTimeout(() => { // This prevents infinite update loops
        this.filterUpdate = false
      }, 1000);
      // END OF: Filter Logic
    },
    async updateLookupValues(submission) {
      if(this.inPreviewMode){
        return
      }
      this.isLoading = true
      this.showWarnLabel = false
      const obj = {
        data: this.formioDataJSON.data || {},
      }

      const payload = {
        eformID: this.formLayout.EFormID,
        submittedFormIOJSON: JSON.stringify(obj),
        selectedTable: {
          Columns: {
            Values: submission.Columns,
          },
          Rows: {
            Values: [
              {
                Values: submission.Rows[0],
              },
            ],
          },
        },
      }
      const response = await this.$store.dispatch('document/useEFormDBLookupResult', payload)

      if (response && response.lookupResults.Rows.Values) {
        this.formioFields = await JSON.parse(response.newFormIOFormJSON)
        this.showMultipleLookups = false
        this.refreshKey += 1
        await this.updateFormioDataJSON(response.lookupResults)
      }
      this.isLoading = false
      this.runDBLookup()
    },
    updateZoom(fullWidth) {
      if (this.pdfMode) {
        const zoomType = fullWidth ? 'zoomIn' : 'zoomOut'
        let zoomLevels = this.pdfLayout === 'Portrait' ? 5 : 4
        if (this.$route.name === 'plainForm') this.pdfLayout === 'Portrait' ? zoomLevels += 2 : zoomLevels += 1

        for (let index = 0; index < zoomLevels; index++) {
          this.$refs.formObject?.formio?.iframeElement?.contentWindow?.postMessage(JSON.stringify({ name: zoomType }), '*')
        }
      }
    },
    wizardChangePage() {
      if (this.wizardMode) {
        try {
          this.showSignatureForWizard = this.$refs.formObject?.formio?.isLastPage()
        } catch {
          this.showSignatureForWizard = true
        }
      }
    },
    zoomInController() {
      this.zoomDepth++
      if (this.zoomDepth >= this.zoomInLimit) document.querySelector('span[ref="zoomIn"]').style.pointerEvents = 'none'
      if (this.zoomDepth > this.zoomOutLimit) {
        document.querySelector('div[ref="webform"]').classList.remove("prevent-select")
        document.querySelector('span[ref="zoomOut"]').style.pointerEvents = 'auto'
        if (isSafari() && isIPad()) { 
          this.zoomDepth--
          setTimeout(() => {
            this.$refs.formObject?.formio?.iframeElement?.contentWindow?.postMessage(JSON.stringify({ name: 'zoomOut' }), '*')
          }, 200);
        }
      }
    },
    zoomOutController() {
      this.zoomDepth--
      if (this.zoomDepth < this.zoomInLimit) document.querySelector('span[ref="zoomIn"]').style.pointerEvents = 'auto'
      if (this.zoomDepth <= this.zoomOutLimit) {
        document.querySelector('div[ref="webform"]').classList.add("prevent-select")
        document.querySelector('span[ref="zoomOut"]').style.pointerEvents = 'none'
        this.zoomDepth++
        setTimeout(() => {
          this.$refs.formObject?.formio?.iframeElement?.contentWindow?.postMessage(JSON.stringify({ name: 'zoomIn' }), '*')
        }, 200);
      }
    },
  },
}
</script>

<style lang="scss">
  // Global CSS for the Wizard
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';

  .btn-wizard-nav-previous, .btn-wizard-nav-next {
    height: 36px !important;
    border-radius: 6px !important;
    font-family: "Archivo" !important;
    color: $black !important;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.71;
    letter-spacing: normal;
    text-align: center;
    margin: 5px !important;
    background-color: $grey-100 !important;
  }

  .btn-wizard-nav-previous:hover, .btn-wizard-nav-next:hover {
    background-color: $btn-primary-hover-bg !important;
    color: $btn-primary-color !important;
  }

  .btn-wizard-nav-cancel, .btn-wizard-nav-submit {
    margin: 0px !important;
    display: none !important;
  }

  .formio-wizard-nav-container li {
    margin: 0px !important;
    padding: 0px !important;
  }

  nav {
    display: flex !important;
    justify-content: left;
  }

  .pagination {
    margin: 5px 0px 20px 0px !important;
    padding: 0px !important;
    display: inline-block;
    text-align: left;
  }

  .pagination .page-link {
    margin: 4px 10px 4px 0px;
    padding: 2px 20px;
    background-color: $grey-100 !important;
    color: black !important;
    border-radius: 4px;
    border: none;
    height: 36px;
  }

  .pagination .page-item.active .page-link {
    background-color: $btn-primary-bg !important;
    color: $btn-primary-color !important;
    border-radius: 4px;
    border: none;
  }

  .page-link:hover {
    background-color: $btn-primary-hover-bg !important;
    color: $btn-primary-color !important;
  }
</style>

<style lang="scss" scoped>
  @import '@/assets/css/vasion-formio.scss';
  @import '@/assets/css/variables.scss';

  .calc-height {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  #main-section {
    min-height: 100%;
    overflow-y: auto;
    width: auto;
  }
  #main-section::v-deep .formio-component-submit {
    display: none;
  }

  #footer-not-signed-in {
    margin-left: 25px;
    margin-right: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .buttons {
      flex-wrap: wrap;
      justify-content: flex-end;
    }
    #secured-by-group{
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      z-index: 2;
      span {
        font-family: Archivo;
        font-size: 12px;
        font-weight: 500;
        color: $grey-400;
      }
      #word-mark {
        width: 79.5px;
        height: 10.8px;
        fill: $primary;
      }
    }
  }

  #user-form {

    #header {
      display: flex;
      flex-direction: column;
      max-height: 325px;
      border-bottom: 1px solid $grey-300;
      justify-content: space-between;
      margin: 20px 20px 0px 20px;
      #header-logo-area {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      #header-logo {
        height: 100%;
        box-sizing: border-box;
        max-width: 100%;
        max-height: 212px;
        width: auto;
      }
      #text-and-date {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: space-between;
      }
      #header-text {
        @include Headline;
        margin-top: 10px;
        float: left;
      }
      #due-date-wrapper {
        overflow: hidden;
        float: right;
        justify-items: end;
        display: inline-grid;
        width: unset;
      }
    }
  }

  #user-form #header #due-date-wrapper ::v-deep .md-field {
    width: 200px;
  }

  #footer {
    margin: 8px 20px 8px 25px;
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: $tablet){
    #footer {
      flex-wrap: wrap;
    }
  }

  @media (max-width: $phone){
    #main-section {
      max-width: 100%;
    }
    #user-form {
      #header{
        flex-direction: column;
        justify-content: center;
      }
    }

    #footer {
      margin: 5px 15px 0px 25px;
    }
  }

  .formio-container {
    padding: 10px 20px;
  }

  #main-section .uploaded-files-list {
    margin-top: 0;
    max-height: 200px;
    overflow-y: auto;
  }
  .uploaded-files-list {
    width: 100%;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
  }
  .uploaded-file-item {
    width: 412px;
    height: 32px;
    background-color: $grey-100;
    margin: 5px 10px;
    padding: 2px 5px 2px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .file-name {
    display: inline-block;
    margin-right: 15px;
    text-align: left;
  }
  .bytes {
    color: $grey-400;
    text-indent: 10px;
  }
  .warnLabel {
    color: red;
  }

  .attachment-required-hint {
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    font-size: smaller;
  }

  .error-text {
    margin: 0px 10px 10px 0px;
    text-align: right;
  }
  ::v-deep #lookup-modal .md-dialog {
    animation: unset;
    -webkit-animation: unset;
  }
  ::v-deep #lookup-modal .root-div {
    padding: unset;
  }
  ::v-deep .pdf-form-container-height .formio-form-pdf iframe {
    height: calc(100vh - 378px) !important;
  }

  ::v-deep .pdf-form-container-mobile-height .formio-form-pdf iframe {
    height: calc(100vh - 234px) !important;
  }

  ::v-deep div:has(input:disabled) + div.formio-errors {
    display: none !important;
  }
  ::v-deep .prevent-select {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .h-100 {
    height: 100%;
  }

  .sect-content {
    max-height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    height: 100%;
  }
  .sect-header {
    padding: 2px;
  }
  .sect-body {
    flex: 1 1 auto;
    overflow-y: auto;
  }
  .sect-footer {
    padding: 2px;
  }
  ::v-deep .choices__list--dropdown {
    display: none;
  }
  ::v-deep .choices__list--dropdown.is-active {
    display: block;
  }
  #save-draft-modal ::v-deep .modal-container {
    width: calc(100% - 16px);
    padding: 16px;
  }
  .draft-modal-container {
    margin-inline: 8px;
    width: unset;
  }
  // md: 768px
  @media (min-width: $breakpoint-md) {
    #user-form {
      #header {
        #text-and-date {
          grid-template-columns: calc(100% - 220px) 200px;
        }
      }
    }
    #save-draft-modal ::v-deep .modal-container {
      width: unset;
    }
    .draft-modal-container {
      width: 420px;
    }
  }
  .label-anonymous {
    margin-left: 20px;
    margin-top: 6px;
    margin-bottom: 0px;
    font-weight: bold;
  }

  #apply-signature-dialog {
    padding: 10px 20px;
  }
  ::v-deep .main-div {
    border: unset;
    border-radius: 0px;
  }
</style>