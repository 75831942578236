<template>
  <div class="collapsible-section">
    <div class="collapsible-section-header" @click="toggleSection">
      <span class="collapsible-section-icon" :class="{ 'is-open': isOpen }">
        <i class="fa fa-chevron-right"></i>
      </span>
      {{ title }}
    </div>
    <div class="collapsible-section-content" v-show="isOpen">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CollapsibleSection',
  props: {
    title: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleSection() {
      this.isOpen = !this.isOpen;
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/light-theme-system-palette.scss';

.collapsible-section {
  margin-top: 8px;
  margin-bottom: 8px;
}

.collapsible-section-header {
  cursor: pointer;
  font-weight: bold;
  color: $primary;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.collapsible-section-icon {
  float: left;
  transition: transform 0.3s;
  margin-right: 8px;
}

.collapsible-section-icon.is-open {
  transform: rotate(90deg);
}

.collapsible-section-content {
  margin-top: 10px;
}
</style>