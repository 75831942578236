/* eslint-disable linebreak-style */
/* eslint-disable default-case */
import Vue from 'vue';
import Router from 'vue-router';

import store from '@/store/store';

import { SearchTypes } from '@/enums/searchTypes'
import { testIsGuid } from '@/store/helperModules/common.module';

Vue.use(Router);

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (err.name === 'NavigationDuplicated') {
      return this.currentRoute
    }
    // rethrow error
    return Promise.reject(err)
  })
}

const router = new Router({
  base: process.env.VUE_APP_IIS_APPLICATION ?? '',
  mode: 'history',
  routes: [

    // **************************************************************************************************************************************************
    // ************************************************************    Un-Parented Routes   *************************************************************
    // **************************************************************************************************************************************************
    {
      path: '/webSearchUserLogin/:webSearchUserName(.*)',
      name: 'WebSearchUserLogin',
      props: true,
      component: () => import('@/views/search/TheWebSearchUserLogin.vue'),
    },
    {
      path: '/signatureDocLink/:signatureDocumentLinkGUID',
      name: 'TheSignatureDocLink',
      props: true,
      component: () => import('@/views/Document/TheSignatureDocLink.vue'),
    },
    {
      path: '/signatureConfirmation',
      name: 'TheSignatureConfirmation',
      props: true,
      component: () => import('@/views/digitalSignature/TheSignatureConfirmation.vue'),
    },
    {
      path: '/login',
      name: 'TheLogin',
      component: () => import('@/views/TheLogin.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch('resetStoreData')
        next()
      },
    },
    {
      path: '/logout',
      name: 'Logout',
      beforeEnter: async (to, from, next) => {
        try {
          await store.dispatch('common/checkIsAuthenticated')
          await store.dispatch('common/signOut', to.query.isTokenExpired ? to.query.isTokenExpired : false)
        } catch {
          console.warn('You have been signed out.')
        }
        if (to.query.redirect) {
          window.location.href = decodeURI(to.query.redirect)
        }
        else {
          next({ name: 'TheLogin' })
        }
      },
    },
    {
      path: '/admin/ResetPassword/:resetPasswordGuid',
      name: 'ResetPassword',
      component: () => import('@/views/TheLogin.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch('resetStoreData')
        next()
      },
    },
    {
      path: '/',
      name: 'TheHome',
      meta: { requiresAuth: true },
      component: () => import('@/views/home/TheHome.vue'),
    },
    {
      path: '/vasionHome',
      name: 'TheVasionHome',
      meta: { requiresAuth: true },
      component: () => import('@/views/home/LandingPageInfo.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch('mainViews/toggleVasionHome', true)
        next()
      },
    },
    {
      path: '/SAML',
      component: () => import('@/views/admin/SAML.vue'),
    },
    {
      path: '/search',
      meta: { requiresAuth: true },
      component: () => import('@/views/search/TheSearch.vue'),
      beforeEnter: async (to, from, next) => {
        if (!store.getters['common/APP_ACCESS_SEARCH'] || !store.getters['common/canConfigureSearches']) {
          store.dispatch('mainViews/toggleVasionHome', true)
          next({ name: 'TheVasionHome' })
        } else {
          store.dispatch('mainViews/toggleVasionHome', false)
          next()
        }
      },
      children: [
        {
          path: 'textSearchSetup',
          name: 'TextSearchSetup',
          component: () => import('@/views/search/TextSearchSetup.vue'),
          props: true,
        },
        {
          path: 'indexSearchSetup',
          name: 'IndexSearchSetup',
          component: () => import('@/views/search/IndexSearchSetup.vue'),
          props: true,
        },
        {
          path: 'piiSummary',
          name: 'PIIDataSummary',
          component: () => import('@/views/search/PIIDataSummary.vue'),
          beforeEnter: async (to, from, next) => {
            if (!store.getters['common/isAdminUser'] === true) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
      ],
    },

    {
      path: '/document/TheCompareDocuments',
      name: 'TheCompareDocuments',
      meta: { requiresAuth: true },
      component: () => import('@/views/Document/TheCompareDocuments.vue'),
      beforeEnter: async (to, from, next) => {
        await store.dispatch('common/hideNavbarAndHeader', true)
        next();
      },
    },

    {
      path: '/gateway',
      name: 'TheAppDocumentGateway',
      component: () => import('@/views/Document/TheAppDocumentGateway.vue'),
      beforeEnter: async (to, from, next) => {
        await store.dispatch('common/checkIsAuthenticated')
        if (!(
          store.getters['common/APP_ACCESS_SIGNATURE']
          || store.getters['common/APP_ACCESS_STORAGE']
          || store.getters['common/APP_ACCESS_WORKSPACE']
          || store.getters['common/APP_ACCESS_SEARCH']
        )) {
          store.dispatch('mainViews/toggleVasionHome', true)
          next({ name: 'TheVasionHome' })
        } else {
          next();
        }
      },
      children: [
        {
          path: '/docLink/:documentID/:encryptedUserName(.*)?',
          name: 'DocumentFromGateway',
          component: () => import('@/views/Document/ThePublicDocumentGateway.vue'),
          beforeEnter: (to, from, next) => {
            next(to.params.documentID !== undefined && to.params.documentID !== null && to.params.documentID !== '')
          },
        },
        {
          path: '/document/:documentID/:version',
          name: 'DocumentWithVersion',
          component: () => import('@/views/Document/TheDocument.vue'),
          beforeEnter: (to, from, next) => {
            store.dispatch('mainViews/changeActiveSubApp', 'storage')
            next(to.params.documentID && to.params.documentID > 0)
          },
          meta: { requiresAuth: true },
        },
        {
          path: '/document/:documentID',
          name: 'Document',
          props(route) {
            const previousPage = route.params.previousPage || 1
            return {
              documentID: route.params.documentID,
              previousPage: previousPage,
            }
          },
          component: () => import('@/views/Document/TheDocument.vue'),
          beforeEnter: (to, from, next) => {
            if (store.state.mainViews.activeSubApp !== "workspace" && store.state.mainViews.activeSubApp !== "signature")
              store.dispatch('mainViews/changeActiveSubApp', 'storage')
            next(to.params.documentID && (to.params.documentID > 0 || testIsGuid(to.params.documentID)))
          },
          meta: { requiresAuth: true },
        },
        {
          path: '/document/:documentID/multipleDocuments?:additionalDocumentIds',
          name: 'DocumentMultiple',
          component: () => import('@/views/Document/TheDocument.vue'),
          beforeEnter: (to, from, next) => {
            store.dispatch('mainViews/changeActiveSubApp', 'storage')
            next(to.params.documentID && (to.params.documentID > 0 || testIsGuid(to.params.documentID)))
          },
          meta: { requiresAuth: true },
          props(route) {
            const additionalDocumentIds = route.params.additionalDocumentIds || '';
            return {
              additionalDocumentIds : additionalDocumentIds === '' ? [] : additionalDocumentIds.split(',')
            }
          }
        },
        {
          path: '/document/fieldValidation/:documentID',
          name: 'TheFieldValidationView',
          component: () => {
            if (!store.state.systemSettings.featureFlags.basicFieldValidation) {
              return import('@/views/Document/TheFieldValidationAdvancedView.vue')
            } else {
              return import('@/views/Document/TheFieldValidationView.vue')
            }
          },
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            if (!store.getters['common/canViewDocumentFieldValidation']) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
          props(route) {
            return {
              custViewID: route.params.custViewID,
              documentID: route.params.documentID,
              documentIDs: route.params.documentIDs,
              folderID: route.params.folderID,
              fromDocViewer: route.params.fromDocViewer,
            }
          }
        },
        {
          path: '/document/:documentID/page/:startingPage',
          name: 'DocumentWithStartingPage',
          props: true,
          component: () => import('@/views/Document/TheDocument.vue'),
          beforeEnter: (to, from, next) => {
            next(to.params.documentID && (to.params.documentID > 0 || testIsGuid(to.params.documentID)))
          },
          meta: { requiresAuth: true },
        },
        {
          path: '/documentdata/:documentID/:previousPage',
          name: 'DocumentData',
          props: true,
          component: () => import('@/views/Document/TheDocument.vue'),
          beforeEnter: (to, from, next) => {
            next(to.params.documentID && (to.params.documentID > 0 || testIsGuid(to.params.documentID)))
          },
          meta: { requiresAuth: true },
        },
        {
          path: '/documentdata/:documentID/page/:startingPage',
          name: 'DocumentDataWithStartingPage',
          props: true,
          component: () => import('@/views/Document/TheDocument.vue'),
          beforeEnter: (to, from, next) => {
            next(to.params.documentID && (to.params.documentID > 0 || testIsGuid(to.params.documentID)))
          },
          meta: { requiresAuth: true },
        },
      ],
    },
    // ** Search Results **
    {
      path: '/searchResults',
      name: 'SearchResults',
      component: () => import('@/views/search/SearchResults.vue'),
      meta: { requiresAuth: true },
      beforeEnter: async (to, from, next) => {
        if (!store.getters['common/APP_ACCESS_SEARCH']) {
          store.dispatch('mainViews/toggleVasionHome', true)
          next({ name: 'TheVasionHome' })
        } else {
          next()
        }
      },
    },
    {
      path: '/searchResults/:loadResultsFromStore',
      name: 'loadResultsFromStore',
      component: () => import('@/views/search/SearchResults.vue'),
      meta: { requiresAuth: true },
      beforeEnter: async (to, from, next) => {
        if (!store.getters['common/APP_ACCESS_SEARCH']) {
          store.dispatch('mainViews/toggleVasionHome', true)
          next({ name: 'TheVasionHome' })
        } else {
          next()
        }
      },
    },
    // ** Text Search Setup **
    {
      path: '/textSearchSetup',
      name: 'textSearchSetup',
      component: () => import('@/views/search/TextSearchSetup.vue'),
      meta: { requiresAuth: true },
      beforeEnter: async (to, from, next) => {
        if (!store.getters['common/APP_ACCESS_SEARCH']) {
          store.dispatch('mainViews/toggleVasionHome', true);
          next({ name: 'TheVasionHome' })
        } else {
          next(store.getters['common/canConfigureSearches'] === true);
        }
      },
    },

    // *************************************************************************************************************************************************
    // ****************************************************************    Admin App    ****************************************************************
    // *************************************************************************************************************************************************

    {
      path: '/admin',
      name: 'TheAdminApp',
      component: () => import('@/views/admin/TheAdmin.vue'),
      meta: { requiresAuth: true },
      beforeEnter: async (to, from, next) => {
        if (!store.getters['common/APP_ACCESS_ADMIN']) {
          store.dispatch('mainViews/toggleVasionHome', true);
          next({ name: 'TheVasionHome' })
        } else {
          store.dispatch('mainViews/changeActiveSubApp', 'admin')
          store.dispatch('mainViews/toggleVasionHome', false)
          store.dispatch('mainViews/changeMainNav', '')
          next();
        }
      },
      children: [
        {
          path: 'usageDashboard',
          name: 'TheUsageDashboard',
          component: () => import('@/views/admin/UsageDashboard.vue'),
          beforeEnter: (to, from, next) => {
            next(store.getters['common/isAdminUser'] === true);
          },
        },
        // *** Capture Setup ***
        /* {
          path: 'captureSetup',
          component: () => import('@/views/admin/captureSetup/TheCaptureAdmin.vue'),
          meta: { requiresAuth: true },
          children: [
            {
              path: 'imageLibrary',
              name: 'ImageLibrary',
              component: () => import('@/views/admin/captureSetup/ImageLibrary.vue'),
            },
            {
              path: 'scanner',
              name: 'Scanner',
              component: () => import('@/views/admin/captureSetup/SetupScanner.vue'),
            },
            {
              path: 'watermarkSettings',
              name: 'WatermarkSettings',
              component: () => import('@/views/admin/captureSetup/WatermarkSettings.vue'),
            },
          ],
        }, */
        // *** System Settings ***
        {
          path: 'systemSettings',
          component: () => import('@/views/admin/systemSettings/TheSystemSettings.vue'),
          meta: { requiresAuth: true },
          children: [
            {
              path: 'emailSettings',
              name: 'EmailSettings',
              component: () => import('@/views/admin/systemSettings/EmailSettings.vue'),
              beforeEnter: async (to, from, next) => {
                if (store.getters['common/isAdminUser'] === false) {
                  store.dispatch('mainViews/toggleVasionHome', true)
                  next({ name: 'TheVasionHome' })
                } else {
                  next()
                }
              },
            },
            {
              path: 'aboutVasion',
              name: 'AboutVasion',
              component: () => import('@/views/admin/systemSettings/AboutVasion.vue'),
              beforeEnter: async (to, from, next) => {
                if (store.getters['common/isAdminUser'] === false) {
                  store.dispatch('mainViews/toggleVasionHome', true)
                  next({ name: 'TheVasionHome' })
                } else {
                  next()
                }
              },
            },
            {
              path: 'imageLibrary',
              name: 'ImageLibrary',
              component: () => import('@/views/admin/systemSettings/ImageLibrary.vue'),
              beforeEnter: async (to, from, next) => {
                if (store.getters['common/isAdminUser'] === false) {
                  store.dispatch('mainViews/toggleVasionHome', true)
                  next({ name: 'TheVasionHome' })
                } else {
                  next()
                }
              },
            },
            {
              path: 'globalSettings',
              name: 'GlobalSettings',
              component: () => import('@/views/admin/systemSettings/GlobalSettings.vue'),
              beforeEnter: async (to, from, next) => {
                if (store.getters['common/isAdminUser'] === false) {
                  store.dispatch('mainViews/toggleVasionHome', true)
                  next({ name: 'TheVasionHome' })
                } else {
                  next()
                }
              },
            },
          ],
        },

        // *** Integration ***
        {
          path: 'integration',
          component: () => import('@/views/admin/integrations/TheIntegration.vue'),
          meta: { requiresAuth: true },
          children: [
            {
              path: 'authentication',
              name: 'Authentication',
              component: () => import('@/views/admin/integrations/Authentication.vue'),
              beforeEnter: async (to, from, next) => {
                if (store.getters['common/isAdminUser'] === false) {
                  store.dispatch('mainViews/toggleVasionHome', true)
                  next({ name: 'TheVasionHome' })
                } else {
                  next()
                }
              },
            },
            {
              path: 'cloudStorage',
              name: 'CloudStorage',
              component: () => import('@/views/admin/integrations/CloudStorage.vue'),
            },
            {
              path: 'contentConnector',
              name: 'ContentConnector',
              component: () => import('@/views/admin/integrations/ContentConnector.vue'),
            },
            {
              path: 'hardware',
              name: 'Hardware',
              component: () => import('@/views/admin/integrations/Hardware.vue'),
            },
            {
              path: 'software',
              name: 'Software',
              component: () => import('@/views/admin/integrations/Software.vue'),
            },
          ],
        },
            // ***** Old Integration App (These are all stubs) *****
        {
          path: 'integration',
          meta: { requiresAuth: true },
          children: [
            {
              path: 'docusign',
              name: 'DocuSignConfig',
              component: () => import('@/views/integration/DocuSignConfig.vue'),
              beforeEnter: (to, from, next) => {
                next(store.getters['common/isAdminUser'] === true);
              },
            },
            {
              path: 'lookup',
              name: 'LookupConfig',
              component: () => import('@/views/integration/LookupConfig.vue'),
              beforeEnter: (to, from, next) => {
                next(store.getters['common/canConfigureLookups'] === true);
              },
            },
            {
              path: 'osa',
              name: 'OSAConfig',
              component: () => import('@/views/integration/OSA.vue'),
              beforeEnter: (to, from, next) => {
                next(store.getters['common/isAdminUser'] === true);
              },
            },
            {
              path: 'salesforce',
              name: 'SalesforceConfig',
              component: () => import('@/views/integration/SalesforceConfig.vue'),
              beforeEnter: (to, from, next) => {
                next(store.getters['common/isAdminUser'] === true);
              },
            },
          ],
        },
        // *** SearchSetup ***
        {
          path: 'searchSetup',
          meta: { requiresAuth: true },
          component: () => import('@/views/admin/searchSetup/TheSearchSetup.vue'),
          children: [
            {
              path: 'linkSearch',
              name: 'AdminLinkedSearchList',
              component: () => import('@/views/admin/searchSetup/LinkSearch.vue'),
              beforeEnter: async (to, from, next) => {
                if (store.getters['common/canConfigureSearches'] === false) {
                  store.dispatch('mainViews/toggleVasionHome', true)
                  next({ name: 'TheVasionHome' })
                } else {
                  next();
                }
              },
            },
            {
              path: 'ObjectEditAndReplace',
              name: 'AdminObjectEditAndReplace',
              component: () => import('@/views/admin/searchSetup/ObjectEditAndReplace.vue'),
              beforeEnter: async (to, from, next) => {
                if (store.getters['common/isAdminUser'] === false) {
                  store.dispatch('mainViews/toggleVasionHome', true)
                  next({ name: 'TheVasionHome' })
                } else {
                  next();
                }
              },
            },
            {
              path: 'linkSearch/:linkedSearchId',
              name: 'NewEditLinkedSearch',
              component: () => import('@/views/admin/searchSetup/NewEditLinkedSearch.vue'),
              beforeEnter: async (to, from, next) => {
                if (!store.getters['common/canAccessSearchSetup'] === true) {
                  store.dispatch('mainViews/toggleVasionHome', true)
                  next({ name: 'TheVasionHome' })
                } else {
                  next();
                }
              },
            },
            {
              path: 'searchSetup',
              name: 'SearchSetup',
              component: () => import('@/views/admin/searchSetup/SearchSetup.vue'),
              beforeEnter: async (to, from, next) => {
                if (!store.getters['common/canAccessSearchSetup'] === true) {
                  store.dispatch('mainViews/toggleVasionHome', true)
                  next({ name: 'TheVasionHome' })
                } else {
                  store.dispatch('search/setAdminSearchListTypeID', SearchTypes.Index)
                  next()
                }
              },
            },
            {
              path: 'fullTextSearchSetup',
              name: 'AdminFullTextSearchList',
              component: () => import('@/views/admin/searchSetup/SearchSetup.vue'),
              beforeEnter: async (to, from, next) => {
                if (store.getters['common/canAccessSearchSetup'] === false) {
                  store.dispatch('mainViews/toggleVasionHome', true)
                  next({ name: 'TheVasionHome' })
                } else {
                  store.dispatch('search/setAdminSearchListTypeID', SearchTypes.FullText)
                  next()
                }
              },
            },
            {
              path: 'globalSearchSetup',
              name: 'AdminGlobalSearchList',
              component: () => import('@/views/admin/searchSetup/GlobalSearchSetup.vue'),
              beforeEnter: async (to, from, next) => {
                if (store.getters['common/canConfigureSearches'] === false) {
                  store.dispatch('mainViews/toggleVasionHome', true)
                  next({ name: 'TheVasionHome' })
                } else {
                  store.dispatch('search/setAdminSearchListTypeID', SearchTypes.Global)
                  next()
                }
              },
            },
            {
              path: 'attributeSearchSetup',
              name: 'AdminAttributeSearchList',
              component: () => import('@/views/admin/searchSetup/SearchSetup.vue'),
              beforeEnter: async (to, from, next) => {
                if (store.getters['common/canAccessSearchSetup'] === false) {
                  store.dispatch('mainViews/toggleVasionHome', true)
                  next({ name: 'TheVasionHome' })
                } else {
                  store.dispatch('search/setAdminSearchListTypeID', SearchTypes.Index)
                  next()
                }
              },
            },
          ],
        },
        // *** Security Permissions ***
        {
          path: 'securityPermissions',
          meta: { requiresAuth: true },
          component: () => import('@/views/admin/securityPermissions/TheSecurityPermissions.vue'),
          children: [
            {
              path: 'activeUsers',
              name: 'ActiveUsers',
              component: () => import('@/views/admin/securityPermissions/ActiveUsers.vue'),
            },
            {
              path: 'attributeEditReplace',
              name: 'SecurityAttributeEditReplace',
              component: () => import('@/views/admin/securityPermissions/AttributeEditReplace.vue'),
            },
            {
              path: 'failedLogins',
              name: 'FailedLogins',
              component: () => import('@/views/admin/securityPermissions/FailedLogins.vue'),
            },
            {
              path: 'featureFlags',
              name: 'FeatureFlags',
              component: () => import('@/views/admin/securityPermissions/FeatureFlags.vue'),
              beforeEnter: async (to, from, next) => {
                if (store.getters['common/isAdminUser'] === false) {
                  store.dispatch('mainViews/toggleVasionHome', true)
                  next({ name: 'TheVasionHome' })
                } else {
                  next()
                }
              },
            },
            {
              path: 'groups',
              name: 'Groups',
              component: () => import('@/views/admin/securityPermissions/Groups.vue'),
              beforeEnter: async (to, from, next) => {
                if (store.getters['common/canAccessGroupConfiguration'] === false) {
                  store.dispatch('mainViews/toggleVasionHome', true)
                  next({ name: 'TheVasionHome' })
                } else {
                  next();
                }
              },
            },
            {
              path: 'passwordSettings',
              name: 'PasswordSettings',
              component: () => import('@/views/admin/securityPermissions/PasswordSettings.vue'),
              beforeEnter: async (to, from, next) => {
                if (store.getters['common/isAdminUser'] === false) {
                  store.dispatch('mainViews/toggleVasionHome', true)
                  next({ name: 'TheVasionHome' })
                } else {
                  next()
                }
              },
            },
            {
              path: 'twoFactorAuth',
              name: 'TwoFactorAuth',
              component: () => import('@/views/admin/securityPermissions/TwoFactorAuth.vue'),
              beforeEnter: async (to, from, next) => {
                if (store.getters['common/isAdminUser'] === false) {
                  store.dispatch('mainViews/toggleVasionHome', true)
                  next({ name: 'TheVasionHome' })
                } else {
                  next()
                }
              },
            },
            {
              path: 'users',
              name: 'Users',
              component: () => import('@/views/admin/securityPermissions/Users.vue'),
              beforeEnter: async (to, from, next) => {
                if (store.getters['common/canAccessUserConfiguration'] === false) {
                  store.dispatch('mainViews/toggleVasionHome', true)
                  next({ name: 'TheVasionHome' })
                } else {
                  next();
                }
              },
            },
          ],
        },
        // *** Storage ***
        {
          path: 'storageSetup',
          component: () => import('@/views/admin/storageSetup/TheStorageSetup.vue'),
          meta: { requiresAuth: true },
          children: [
            {
              path: 'cloudStorage',
              name: 'CloudStorageSetup',
              component: () => import('@/views/admin/storageSetup/CloudStorageSetup.vue'),
              beforeEnter: async (to, from, next) => {
                if (store.getters['common/canConfigureFolders'] === false) {
                  store.dispatch('mainViews/toggleVasionHome', true)
                  next({ name: 'TheVasionHome' })
                } else {
                  next();
                }
              },
            },
            {
              path: 'cloudStorage/:clientId',
              name: 'CloudStorageSetupWithClientId',
              component: () => import('@/views/admin/storageSetup/CloudStorageSetup.vue'),
            },
            {
              path: 'customFolderViewConfig',
              name: 'CustomFolderViewConfig',
              component: () => import('@/views/admin/storageSetup/CustomFolderViewConfig.vue'),
              beforeEnter: async (to, from, next) => {
                if (store.getters['common/canConfigureFolders'] === false) {
                  store.dispatch('mainViews/toggleVasionHome', true)
                  next({ name: 'TheVasionHome' })
                } else {
                  next();
                }
              },
            },
            {
              path: 'customFolderViewDetails',
              name: 'CustomFolderViewDetails',
              component: () => import('@/views/admin/storageSetup/CustomFolderViewDetails.vue'),
            },
            {
              path: 'parentFolders',
              name: 'ParentFolders',
              component: () => import('@/views/admin/storageSetup/ParentFolders.vue'),
              props: true,
              beforeEnter: async (to, from, next) => {
                if (store.getters['common/canConfigureFolders'] === false) {
                  store.dispatch('mainViews/toggleVasionHome', true)
                  next({ name: 'TheVasionHome' })
                } else {
                  next();
                }
              },
            },
            {
              path: 'theParentFolder/:folderId',
              name: 'TheParentFolder',
              component: () => import('@/views/storage/TheParentFolder.vue'),
            },
                // ** Security **
            {
              path: 'security',
              component: () => import('@/views/security/TheSecurity.vue'),
              meta: { requiresAuth: true },
              children: [
                {
                  path: 'maxxEditorUsers',
                  name: 'MaxxEditorUsers',
                  component: () => import('@/views/security/MaxxEditorUsers.vue'),
                },
              ],
            },
          ],
        },
        // *** Empty Admin View ***
        {
          path: 'emptyAdmin',
          name: 'TheEmptyAdmin',
          component: () => import('@/views/admin/EmptyAdminView.vue'),
        },
      ],
    },

    // **************************************************************************************************************************************************
    // **************************************************************    Automation App    **************************************************************
    // **************************************************************************************************************************************************

    {
      path: '/automation',
      name: 'TheAutomationApp',
      component: () => import('@/views/admin/automationEngine/TheAutomationEngine.vue'),
      meta: { requiresAuth: true },
      children: [
        {
          path: 'home',
          name: 'TheAutomationHome',
          component: () => import('@/views/admin/automationEngine/TheAutomationEngine.vue'),
          meta: { requiresAuth: true },
          beforeEnter: async (to, from, next) => {
            if (!store.getters['common/canConfigureScheduler']) {
              if (store.state.common.userHasSchedulers) {
                const payload = {
                  schedulerTypes: '121,124,1217,125,128,1220,1223,129,1210,1218,1224',
                  orderBy: 0,
                  ascendingOrder: true,
                }
                await store.dispatch('automationEngine/getSchedulerListForUser', payload)
                const automationVisibility = {
                  addToWorkflow: false,
                  contentMove: false,
                  databaseLookups: false,
                  dataImport: false,
                  fullTextUpdate: false,
                  importExport: false,
                  documentTemplate: false,
                  scheduledReports: false,
                  scheduledOcr: false,
                  scheduledAip: false,
                  barcodeRecognition: false,
                  retention: false,
                  xmlImport: false,
                }
                store.state.automationEngine.schedulerList.forEach(scheduler => {
                  switch (scheduler.schedulerTypeName) {
                    case 'Add to Workflow': automationVisibility.addToWorkflow = true; break;
                    case 'Move': automationVisibility.contentMove = true; break;
                    case 'Lookup': automationVisibility.databaseLookups = true; break;
                    case 'Full Text Search Update': automationVisibility.fullTextUpdate = true; break;
                    case 'Import':
                    case 'Export': automationVisibility.importExport = true; break;
                    case 'Reporting': automationVisibility.scheduledReports = true; break;
                    case 'Image Recognition': automationVisibility.scheduledOcr = true; automationVisibility.barcodeRecognition = true; break;
                    case 'Advanced Image Processing': automationVisibility.scheduledAip = true; break;
                    case 'Retention': automationVisibility.retention = true; break;
                    case 'Data Import': automationVisibility.dataImport = true; break;
                    case 'XML Import': automationVisibility.xmlImport = true; break;
                    case 'Document Template': automationVisibility.documentTemplate = true; break;
                  }
                })
                store.dispatch('automationEngine/setAutomationVisibility', automationVisibility)
                store.dispatch('mainViews/toggleVasionHome', false)
                if (automationVisibility.addToWorkflow) {
                  store.dispatch('mainViews/changeMainNav', 'automation-engine-add-to-workflow')
                  next({ name: 'AddToWorkflow' })
                } else if (automationVisibility.barcodeRecognition) {
                  store.dispatch('mainViews/changeMainNav', 'automation-engine-barcode-recognition')
                  next({ name: 'BarcodeRecognition' })
                } else if (automationVisibility.contentMove) {
                  store.dispatch('mainViews/changeMainNav', 'automation-engine-content-move')
                  next({ name: 'AutomationEngineContentMove' })
                } else if (automationVisibility.databaseLookups) {
                  store.dispatch('mainViews/changeMainNav', 'automation-engine-database-lookups')
                  next({ name: 'AutomationEngineDatabaseLookups' })
                } else if (automationVisibility.fullTextUpdate) {
                  store.dispatch('mainViews/changeMainNav', 'automation-engine-full-text-search')
                  next({ name: 'AutomationEngineFullTextUpdate' })
                } else if (automationVisibility.importExport) {
                  store.dispatch('mainViews/changeMainNav', 'automation-engine-import-export')
                  next({ name: 'ImportExport' })
                } else if (automationVisibility.dataImport) {
                  store.dispatch('mainViews/changeMainNav', 'automation-engine-data-import')
                  next({ name: 'DataImport' })
                } else if (automationVisibility.documentTemplate) {
                  store.dispatch('mainViews/changeMainNav', 'automation-engine-scheduled-doc-template')
                  next({ name: 'ScheduledDocumentTemplate' })
                } else if (automationVisibility.retention) {
                  store.dispatch('mainViews/changeMainNav', 'automation-engine-retention')
                } else if (automationVisibility.scheduledReports) {
                  store.dispatch('mainViews/changeMainNav', 'automation-engine-scheduled-reports')
                  next({ name: 'ScheduledReports' })
                } else if (automationVisibility.scheduledOcr) {
                  store.dispatch('mainViews/changeMainNav', 'automation-engine-scheduled-ocr')
                  next({ name: 'ScheduledOcr' })
                } else if (automationVisibility.scheduledAip) {
                  store.dispatch('mainViews/changeMainNav', 'automation-engine-scheduled-aip')
                  next({ name: 'ScheduledAip' })
                } else if (automationVisibility.xmlImport) {
                  store.dispatch('mainViews/changeMainNav', 'automation-engine-xml-import')
                  next({ name: 'XMLImport' })
                }
              } else {
                store.dispatch('mainViews/toggleVasionHome', true)
                next({ name: 'TheVasionHome' })
              }
            } else {
              store.dispatch('mainViews/toggleVasionHome', false)
              store.dispatch('mainViews/changeMainNav', 'automation-engine-add-to-workflow')
              next({ name: 'AddToWorkflow' })
            }
            store.dispatch('mainViews/changeActiveSubApp', 'automation')
          },
        },
        {
          path: 'imageRecognition',
          name: 'ImageRecognition',
          component: () => import('@/views/admin/automationEngine/ImageRecognition.vue'),
        },
        {
          path: 'addToWorkflow',
          name: 'AddToWorkflow',
          component: () => import('@/views/admin/automationEngine/AddToWorkflow.vue'),
          meta: { requiresAuth: true },
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false
              && (!store.state.automationEngine.automationVisibility || !store.state.automationEngine.automationVisibility.addToWorkflow)) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              const data = store.getters['automationEngine/getSchedulerDataByType']('AddtoWorkflow')
              store.dispatch('automationEngine/setSchedulerProps', data)
              next();
            }
          },
        },
        {
          path: 'editAddToWorkflow/:schedulerId',
          name: 'EditAddtoWorkflow',
          meta: { requiresAuth: true },
          component: () => import('@/views/admin/automationEngine/EditAddToWorkflow.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
        {
          path: 'contentMove',
          name: 'AutomationEngineContentMove',
          component: () => import('@/views/admin/automationEngine/AutomationEngineContentMove.vue'),
          beforeEnter: (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false
              && (!store.state.automationEngine.automationVisibility || !store.state.automationEngine.automationVisibility.contentMove)) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              const data = store.getters['automationEngine/getSchedulerDataByType']('ContentMove')
              store.dispatch('automationEngine/setSchedulerProps', data)
              next();
            }
          },
        },
        {
          path: 'EditContentMove/:schedulerId',
          name: 'EditContentMove',
          component: () => import('@/views/admin/automationEngine/EditContentMove.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
        {
          path: 'databaseLookups',
          name: 'AutomationEngineDatabaseLookups',
          component: () => import('@/views/admin/automationEngine/AutomationEngineDatabaseLookups.vue'),
          beforeEnter: (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false
              && (!store.state.automationEngine.automationVisibility || !store.state.automationEngine.automationVisibility.databaseLookups)) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              const data = store.getters['automationEngine/getSchedulerDataByType']('AutomationEngineDbLookups')
              store.dispatch('automationEngine/setSchedulerProps', data)
              next();
            }
          },
        },
        {
          path: 'AutomationEngineEditDatabaseLookup/:schedulerId',
          name: 'AutomationEngineEditDatabaseLookup',
          component: () => import('@/views/admin/automationEngine/AutomationEngineEditDatabaseLookup.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
        {
          path: 'fullTextUpdate',
          name: 'AutomationEngineFullTextUpdate',
          component: () => import('@/views/admin/automationEngine/AutomationEngineFullTextUpdate.vue'),
          beforeEnter: (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false
              && (!store.state.automationEngine.automationVisibility || !store.state.automationEngine.automationVisibility.fullTextUpdate)) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              const data = store.getters['automationEngine/getSchedulerDataByType']('FullTextSearch')
              store.dispatch('automationEngine/setSchedulerProps', data)
              next();
            }
          },
        },
        {
          path: 'importExport',
          name: 'ImportExport',
          component: () => import('@/views/admin/automationEngine/ImportExport.vue'),
          beforeEnter: (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false
              && (!store.state.automationEngine.automationVisibility || !store.state.automationEngine.automationVisibility.importExport)) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              const data = store.getters['automationEngine/getSchedulerDataByType']('ImportExport')
              store.dispatch('automationEngine/setSchedulerProps', data)
              next();
            }
          },
        },
        {
          path: 'editImport/:schedulerId',
          name: 'EditImport',
          component: () => import('@/views/admin/automationEngine/EditImport.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
        {
          path: 'editExport/:schedulerId',
          name: 'EditExport',
          component: () => import('@/views/admin/automationEngine/EditExport.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
        {
          path: 'dataImport',
          name: 'DataImport',
          component: () => import('@/views/admin/automationEngine/DataImport.vue'),
          beforeEnter: (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false
              && (!store.state.automationEngine.automationVisibility || !store.state.automationEngine.automationVisibility.dataImport)) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              const data = store.getters['automationEngine/getSchedulerDataByType']('DataImport')
              store.dispatch('automationEngine/setSchedulerProps', data)
              next();
            }
          },
        },
        {
          path: 'editDataImport/:schedulerId',
          name: 'EditDataImport',
          component: () => import('@/views/admin/automationEngine/EditDataImport.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
        {
          path: 'editScheduledOcr/:schedulerId',
          name: 'EditScheduledOcr',
          component: () => import('@/views/admin/automationEngine/EditScheduledOcr.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
        {
          path: 'editBarcodeRecognition/:schedulerId',
          name: 'EditBarcodeRecognition',
          component: () => import('@/views/admin/automationEngine/EditBarcodeRecognition.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
        {
          path: 'editRetention/:schedulerId',
          name: 'EditRetention',
          component: () => import('@/views/admin/automationEngine/EditRetention.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
        {
          path: 'scheduledOcr',
          name: 'ScheduledOcr',
          component: () => import('@/views/admin/automationEngine/ScheduledOcr.vue'),
          beforeEnter: (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false
              && (!store.state.automationEngine.automationVisibility || !store.state.automationEngine.automationVisibility.scheduledOcr)) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              const data = store.getters['automationEngine/getSchedulerDataByType']('ImageRecognition')
              store.dispatch('automationEngine/setSchedulerProps', data)
              next();
            }
          },
        },
        {
          path: 'scheduledAip',
          name: 'ScheduledAip',
          component: () => import('@/views/admin/automationEngine/ScheduledAip.vue'),
          beforeEnter: (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false
              && (!store.state.automationEngine.automationVisibility || !store.state.automationEngine.automationVisibility.scheduledAip)) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              const data = store.getters['automationEngine/getSchedulerDataByType']('Aip')
              store.dispatch('automationEngine/setSchedulerProps', data)
              next();
            }
          },
        },
        {
          path: 'barcodeRecognition',
          name: 'BarcodeRecognition',
          component: () => import('@/views/admin/automationEngine/BarcodeRecognition.vue'),
          beforeEnter: (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false
              && (!store.state.automationEngine.automationVisibility || !store.state.automationEngine.automationVisibility.barcodeRecognition)) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              const data = store.getters['automationEngine/getSchedulerDataByType']('BarcodeRecognition')
              store.dispatch('automationEngine/setSchedulerProps', data)
              next();
            }
          },
        },
        {
          path: 'retention',
          name: 'Retention',
          component: () => import('@/views/admin/automationEngine/Retention.vue'),
          beforeEnter: (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false
              && (!store.state.automationEngine.automationVisibility || !store.state.automationEngine.automationVisibility.retention)) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              const data = store.getters['automationEngine/getSchedulerDataByType']('Retention')
              store.dispatch('automationEngine/setSchedulerProps', data)
              next();
            }
          },
        },
        {
          path: 'scheduledReports',
          name: 'ScheduledReports',
          component: () => import('@/views/admin/automationEngine/ScheduledReports.vue'),
          beforeEnter: (to, from, next) => {
            const data = store.getters['automationEngine/getSchedulerDataByType']('ScheduledReports')
            store.dispatch('automationEngine/setSchedulerProps', data)
            next();
          },
        },
        {
          path: 'editScheduledReport/:schedulerId',
          name: 'EditScheduledReporting',
          component: () => import('@/views/admin/automationEngine/EditScheduledReport.vue'),
        },
        {
          path: 'editScheduledAIP/:schedulerId',
          name: 'EditScheduledAip',
          component: () => import('@/views/admin/automationEngine/EditScheduledAIP.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
        {
          path: 'xmlImport',
          name: 'XMLImport',
          component: () => import('@/views/admin/automationEngine/XMLImport.vue'),
          beforeEnter: (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false
              && (!store.state.automationEngine.automationVisibility || !store.state.automationEngine.automationVisibility.xmlImport)) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              const data = store.getters['automationEngine/getSchedulerDataByType']('XMLImport')
              store.dispatch('automationEngine/setSchedulerProps', data)
              next();
            }
          },
        },
        {
          path: 'editXMLImport/:schedulerId',
          name: 'EditXMLImport',
          component: () => import('@/views/admin/automationEngine/EditXMLImport.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
        {
          path: 'documentTemplate',
          name: 'ScheduledDocumentTemplate',
          component: () => import('@/views/admin/automationEngine/DocumentTemplate.vue'),
          beforeEnter: (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false
              && (!store.state.automationEngine.automationVisibility || !store.state.automationEngine.automationVisibility.documentTemplate)) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              const data = store.getters['automationEngine/getSchedulerDataByType']('DocumentTemplate')
              store.dispatch('automationEngine/setSchedulerProps', data)
              next();
            }
          },
        },
        {
          path: 'editScheduledDocumentTemplate/:schedulerId',
          name: 'EditScheduledDocumentTemplate',
          component: () => import('@/views/admin/automationEngine/EditScheduledDocumentTemplate.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canConfigureScheduler'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },

        // ** Field Mapping (we aren't sure where these will end up)**
      ],
    },

    // **************************************************************************************************************************************************
    // ***************************************************************    Capture APP   *****************************************************************
    // **************************************************************************************************************************************************

    {
      path: '/capture',
      name: 'TheCaptureApp',
      component: () => import('@/views/capture/TheCapture.vue'),
      meta: { requiresAuth: true },
      beforeEnter: async (to, from, next) => {
        if (!store.getters['common/APP_ACCESS_CAPTURE']) {
          next({ name: 'TheVasionHome' })
        } else {
          store.dispatch('mainViews/changeActiveSubApp', 'capture')
          store.dispatch('mainViews/toggleVasionHome', false)
          if (store.getters['common/showCaptureApp'] !== true) {
            store.dispatch('mainViews/toggleVasionHome', true)
            next({ name: 'TheVasionHome' })
          } else {
            next()
          }
        }
      },
      children: [
        {
          path: '/capture/startScan',
          name: 'StartScan',
          component: () => import('@/views/capture/StartScan.vue'),
        },
        {
          path: '/capture/startScan/:haveScanner',
          name: 'StartScanManual',
          component: () => import('@/views/capture/StartScan.vue'),
        },
        {
          path: '/capture/aip',
          name: 'AdvancedImageProcessing',
          component: () => import('@/views/capture/AdvancedImageProcessing.vue'),
          beforeEnter: async (to, from, next) => {
            if (!store.getters['common/canConfigureAIP'] === true) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
        {
          path: '/capture/zoneConfiguration',
          name: 'AipZoneConfiguration',
          component: () => import('@/views/capture/AipZoneConfiguration.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/isAdminUser'] === false && store.getters['common/canConfigureAIP'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
        {
          path: '/capture/editAIP/:AIPID',
          name: 'EditAIP',
          component: () => import('@/views/capture/EditAdvancedImageProcessing.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canConfigureAIP'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
        {
          path: '/capture/barcodeConfiguration',
          name: 'BarcodeConfiguration',
          component: () => import('@/views/capture/BarcodeConfiguration.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/isAdminUser'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
        {
          path: '/capture/captureProfiles',
          name: 'CaptureProfiles',
          component: () => import('@/views/capture/CaptureProfiles.vue'),
          beforeEnter: async (to, from, next) => {
              next()
          },
        },
        {
          path: '/capture/csvImport',
          name: 'CSVImport',
          component: () => import('@/views/capture/CSVImport.vue'),
          beforeEnter: async (to, from, next) => {
            if (!store.getters['common/isAdminUser'] === true) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
        {
          path: '/capture/documentNameIndexing',
          name: 'DocumentNameIndexing',
          component: () => import('@/views/capture/DocumentNameIndexing.vue'),
          beforeEnter: async (to, from, next) => {
            if (!store.getters['common/isAdminUser'] === true) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
        {
          path: '/capture/documentProperties',
          name: 'DocumentProperties',
          component: () => import('@/views/capture/DocumentProperties.vue'),
          beforeEnter: async (to, from, next) => {
            if (!store.getters['common/isAdminUser'] === true) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
        {
          path: '/capture/editBarcode',
          name: 'EditBarcode',
          component: () => import('@/views/capture/EditBarcode.vue'),
          beforeEnter: async (to, from, next) => {
            if (!store.getters['common/isAdminUser'] === true) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
        {
          path: '/capture/editCaptureProfile',
          name: 'EditCaptureProfile',
          component: () => import('@/views/capture/EditCaptureProfile.vue'),
          beforeEnter: async (to, from, next) => {
            if (!store.getters['common/canConfigureCaptureProfiles'] === true) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
        {
          path: '/capture/textract',
          name: 'NewTextract',
          component: () => import('@/views/capture/NewTextract.vue'),
          beforeEnter: async (to, from, next) => {
            if (!store.getters['common/canConfigureCaptureProfiles'] === true) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
        {
          path: '/capture/textract/:AIPID',
          name: 'EditTextract',
          component: () => import('@/views/capture/NewTextract.vue'),
          beforeEnter: async (to, from, next) => {
            if (!store.getters['common/canConfigureCaptureProfiles'] === true) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
        {
          path: '/capture/folderStructureIndexing',
          name: 'FolderStructureIndexing',
          component: () => import('@/views/capture/FolderStructureIndexing.vue'),
          beforeEnter: async (to, from, next) => {
            if (!store.getters['common/isAdminUser'] === true) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
        {
          path: '/capture/ocr',
          name: 'OCR',
          component: () => import('@/views/capture/OCR.vue'),
          beforeEnter: async (to, from, next) => {
            if (!store.getters['common/isAdminUser'] === true) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
        {
          path: '/capture/barcodeIndexing',
          name: 'BarcodeIndexingAdmin',
          component: () => import('@/views/capture/TheBarcodeIndexingConfig.vue'),
          beforeEnter: async (to, from, next) => {
            if (!store.getters['common/canConfigurBarcodeIndexing'] === true) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
        {
          path: '/capture/globalAttributeReplace',
          name: 'GlobalFindReplace',
          component: () => import('@/views/capture/IndexFindReplace.vue'),
          beforeEnter: async (to, from, next) => {
            if (!store.getters['common/canRunGlobalIndexEdit'] === true) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
        {
          path: '/capture/xmlAttributes',
          name: 'XMLAttributes',
          component: () => import('@/views/capture/XMLAttributes.vue'),
          beforeEnter: async (to, from, next) => {
            if (!store.getters['common/isAdminUser'] === true) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
      ],
    },

    // **************************************************************************************************************************************************
    // *****************************************************************    Forms App   *****************************************************************
    // **************************************************************************************************************************************************

    {
      path: '/forms',
      name: 'TheFormsApp',
      component: () => import('@/views/forms/TheFormsApp.vue'),
      meta: { requiresAuth: true },
      beforeEnter: async (to, from, next) => {
        if (to.path.startsWith('/forms/myForm/') && to.query.isFromMobile) {
          store.dispatch('common/suppressDefaultApp', true)
          next()
        } else if (!store.getters['common/APP_ACCESS_FORMS']) {
          store.dispatch('mainViews/toggleVasionHome', true)
          next({ name: 'TheVasionHome' })
        } else {
          store.dispatch('mainViews/toggleVasionHome', false)
          if (store.getters['common/canAccessFormsApp_RolesOnly']) {
            store.dispatch('mainViews/changeActiveSubApp', 'forms')
            store.dispatch('mainViews/changeMainNav', 'all-forms')
            next()
          } else if (to.path.startsWith('/forms/myForm/')) {
            store.dispatch('common/suppressDefaultApp', true)
            next()
          } else {
            store.dispatch('mainViews/changeActiveSubApp', 'forms')
            store.dispatch('mainViews/changeMainNav', 'my-forms')
            next()
          }
        }
      },
      children: [
        {
          path: 'all',
          name: 'AllForms',
          component: () => import('@/views/forms/AllAttributeForms.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canConfigureAttributeForms'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
        {
          path: 'drafts',
          name: 'FormDrafts',
          component: () => import('@/views/forms/Drafts.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/APP_ACCESS_FORMS']) {
              store.dispatch('mainViews/changeMainNav', 'form-drafts-view')
              next();
            } else {
              next({ name: 'TheVasionHome' })
            }
          },
          meta: { requiresAuth: true },
        },
        {
          path: 'myForms',
          name: 'MyForms',
          component: () => import('@/views/forms/AllMyForms.vue'),
          meta: { requiresAuth: true },
        },
        {
          path: 'myForm/draft/:formName/:formID/:draftId/:workflowID?',
          name: 'MyDraftForm',
          component: () => import('@/views/forms/MyForm.vue'),
          meta: { requiresAuth: true },
          beforeEnter: async(to, from, next) =>{
            if(to.query.isFromMobile){
              store.dispatch('common/hideNavbarAndHeader', true)
              store.dispatch('common/setMainContainerWithFullWidth', true)  
            }  
            next()
          },
        },
        {
          path: 'myForm/:formName/:formID/:workflowID?',
          name: 'MyForm',
          component: () => import('@/views/forms/MyForm.vue'),
          meta: { requiresAuth: true },
          beforeEnter: async(to, from, next) =>{
            if(to.query.isFromMobile){
              store.dispatch('common/hideNavbarAndHeader', true)
              store.dispatch('common/setMainContainerWithFullWidth', true)  
            }  
            next()
          },
        },
        {
          path: 'externalView',
          name: 'ExternalView',
          component: () => import('@/views/forms/ExternalView.vue'),
          meta: { requiresAuth: true },
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/isAdminUser'] === true) {
              store.dispatch('mainViews/changeActiveSubApp', 'forms')
              store.dispatch('mainViews/changeMainNav', 'external-view')
              next();
            } else {
              store.dispatch('mainViews/toggleVasionHome', true);
              next({ name: 'TheVasionHome' })
            }
          },
        },
        {
          path: 'newForm',
          name: 'NewForm',
          meta: { requiresAuth: true },
          component: () => import('@/views/forms/TheEditAttributeForm.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canConfigureAttributeForms'] === true) {
              store.dispatch('mainViews/changeActiveSubApp', 'forms')
              await store.dispatch('attributeForm/resetActiveForm')
              next()
            } else {
              store.dispatch('mainViews/toggleVasionHome', true);
              next({ name: 'TheVasionHome' })
            }
          },
        },
        {
          path: 'editForm/:formID',
          name: 'EditForm',
          meta: { requiresAuth: true },
          component: () => import('@/views/forms/TheEditAttributeForm.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canConfigureAttributeForms'] === true) {
              store.dispatch('mainViews/changeActiveSubApp', 'forms')
              await store.dispatch('attributeForm/resetActiveForm')
              next()
            } else {
              store.dispatch('mainViews/toggleVasionHome', true);
              next({ name: 'TheVasionHome' })
            }
          },
        },
        {
          path: 'formioFormView/:formID/:formLayoutID?',
          name: 'TheNewFormView',
          meta: { requiresAuth: true },
          props(route) {
            return {
              isCopy : route.params.isCopy || false,
            }
          },
          component: () => import('@/views/forms/TheNewFormView.vue'),
          beforeEnter: (to, from, next) => {
            const canAccess = store.getters['common/canConfigureAttributeForms'] === true
              && to.params.formID > 0
            if (canAccess) {
              store.dispatch('mainViews/changeActiveSubApp', 'forms')
              next()
            } else {
              store.dispatch('mainViews/toggleVasionHome', true);
              next({ name: 'TheVasionHome' })
            }
          },
        },
        {
          path: 'fieldMapping',
          component: () => import('@/views/fieldMapping/TheFieldMapping.vue'),
          meta: { requiresAuth: true },
          children: [
            {
              path: 'viewFields',
              name: 'ViewFields',
              component: () => import('@/views/fieldMapping/ViewFields.vue'),
              beforeEnter: (to, from, next) => {
                next(store.getters['common/isAdminUser'] === true);
              },
            },
            {
              path: 'docNameAttributes',
              name: 'DocNameAttributes',
              component: () => import('@/views/fieldMapping/DocNameAttributes.vue'),
              beforeEnter: (to, from, next) => {
                next(store.getters['common/isAdminUser'] === true);
              },
            },
            {
              path: 'folderStructureAttributes',
              name: 'FolderStructureAttributes',
              component: () => import('@/views/fieldMapping/FolderStructureAttributes.vue'),
              beforeEnter: (to, from, next) => {
                next(store.getters['common/isAdminUser'] === true);
              },
            },
            {
              path: 'EditXmlConfiguration/',
              name: 'EditXmlConfiguration',
              component: () => import('@/views/fieldMapping/EditXmlConfiguration.vue'),
              beforeEnter: (to, from, next) => {
                next(store.getters['common/isAdminUser'] === true);
              },
            },
            {
              path: 'databaseLookups',
              name: 'DatabaseLookups',
              component: () => import('@/views/fieldMapping/DatabaseLookups.vue'),
              beforeEnter: (to, from, next) => {
                next(store.getters['common/isAdminUser'] === true);
              },
            },
            {
              path: 'editDatabaseLookups/:lookupId',
              name: 'EditDatabaseLookup',
              component: () => import('@/views/fieldMapping/EditDatabaseLookup.vue'),
              beforeEnter: (to, from, next) => {
                next(store.getters['common/isAdminUser'] === true);
              },
            },
            {
              path: 'editSalesForceLookup/:lookupId',
              name: 'EditSalesForceLookup',
              component: () => import('@/views/fieldMapping/EditSalesForceLookup.vue'),
              beforeEnter: (to, from, next) => {
                next(store.getters['common/isAdminUser'] === true);
              },
            },
            {
              path: 'databaseLookups',
              name: 'FormsDatabaseLookups',
              component: () => import('@/views/fieldMapping/DatabaseLookups.vue'),
              beforeEnter: (to, from, next) => {
                if (store.getters['common/canConfigureLookups'] === false) {
                  store.dispatch('mainViews/toggleVasionHome', true)
                  next({ name: 'TheVasionHome' })
                } else {
                  const data = store.getters['automationEngine/getSchedulerDataByType']('AutomationEngineDbLookups')
                  store.dispatch('automationEngine/setSchedulerProps', data)
                  store.dispatch('mainViews/changeActiveSubApp', 'forms')
                  next()
                }
              },
            },
            {
              path: 'xmlFields',
              name: 'FormsXMLFields',
              component: () => import('@/views/fieldMapping/XMLFields.vue'),
              beforeEnter: (to, from, next) => {
                if (store.getters['common/canConfigureLookups'] === false) {
                  store.dispatch('mainViews/toggleVasionHome', true)
                  next({ name: 'TheVasionHome' })
                } else {
                  next()
                }
              },
            },
          ],
        },
        {
          path: 'package/:formID',
          name: 'NewFormPackage',
          meta: { requiresAuth: true },
          component: () => import('@/views/forms/FormPackage.vue'),
          beforeEnter: async (to, from, next) => {
            await store.dispatch('common/hideNavbarAndHeader', true)
            await store.dispatch('common/setMainContainerWithFullWidth', true)
            const canAccess = store.getters['common/canConfigureAttributeForms'] === true
              && to.params.formID > 0
            if (canAccess) {
              store.dispatch('mainViews/changeActiveSubApp', 'forms')
              next()
            } else {
              store.dispatch('mainViews/toggleVasionHome', true);
              next({ name: 'TheVasionHome' })
            }
          },
        },
        {
          path: 'package/:formID/:packageId',
          name: 'EditFormPackage',
          meta: { requiresAuth: true },
          component: () => import('@/views/forms/FormPackage.vue'),
          beforeEnter: async (to, from, next) => {
            await store.dispatch('common/hideNavbarAndHeader', true)
            await store.dispatch('common/setMainContainerWithFullWidth', true)
            const canAccess = store.getters['common/canConfigureAttributeForms'] === true
              && to.params.formID > 0
            if (canAccess) {
              store.dispatch('mainViews/changeActiveSubApp', 'forms')
              next()
            } else {
              store.dispatch('mainViews/toggleVasionHome', true);
              next({ name: 'TheVasionHome' })
            }
          },
        },
      ],
    },
    {
      path: '/formLink/:formName/:EFormID',
      beforeEnter: async (to, from, next) => {
        if (store.state.common.isSignedIn) {
          next({ name: 'MyForm', params: { formName: to.params.formName, formID: to.params.EFormID } })
          return
        }

        const isAccessible = await store.dispatch('forms/getEFormAccessibility', to.params.EFormID)
        if (isAccessible) {
          next({ name: 'plainForm', params: { formID: to.params.EFormID, formName: to.params.formName } })
        } else {
          next({ name: 'TheLogin', query: { redirect: `/formLink/${to.params.formName}/${to.params.EFormID}` } })
        }
      },
    },
    {
      path: '/formName/:formID',
      name: 'plainForm',
      component: () => import('@/views/forms/PlainForm.vue'),
    },
    {
      path: '/submissionComplete',
      name: 'SubmissionComplete',
      component: () => import('@/views/forms/SubmissionComplete.vue'),
    },

    // **************************************************************************************************************************************************
    // ****************************************************************    Profile App   ****************************************************************
    // **************************************************************************************************************************************************

    {
      path: '/profile',
      component: () => import('@/views/userSettings/TheProfile.vue'),
      meta: { requiresAuth: true },
      beforeEnter: async (to, from, next) => {
        store.dispatch('mainViews/changeActiveSubApp', 'profile')
        store.dispatch('mainViews/toggleVasionHome', false)
        next()
      },
      children: [
        {
          path: '/profile/usersettings',
          name: 'ProfileSettings',
          component: () => import('@/views/userSettings/ProfileSettings.vue'),
        },
        {
          path: '/profile/defaultApp',
          name: 'ProfileDefaultApp',
          component: () => import('@/views/userSettings/ProfileDefaultApp.vue'),
        },
        {
          path: '/profile/digitalSignature',
          name: 'ProfileDigitalSignature',
          component: () => import('@/views/userSettings/ProfileDigitalSignature.vue'),
        },
        {
          path: '/profile/privacy',
          name: 'ProfilePrivacy',
          component: () => import('@/views/userSettings/ProfilePrivacy.vue'),
        },
      ],
    },
 

    // **************************************************************************************************************************************************
    // ****************************************************************    Reports App   ****************************************************************
    // **************************************************************************************************************************************************

    {
      path: '/reporting',
      meta: { requiresAuth: true },
      component: () => import('@/views/reporting/TheReport.vue'),
      beforeEnter: async (to, from, next) => {
        if (!store.getters['common/APP_ACCESS_REPORTS']) {
          store.dispatch('mainViews/toggleVasionHome', true)
          next({ name: 'TheVasionHome' })
        } else {
          store.dispatch('mainViews/changeActiveSubApp', 'reports')
          store.dispatch('mainViews/toggleVasionHome', false)
          next()
        }
      },
      children: [
        {
          path: 'empty',
          name: 'TheEmptyReport',
          component: () => import('@/views/reporting/EmptyReport.vue'),
          beforeEnter: async (to, from, next) => {
            if (!store.getters['common/APP_ACCESS_REPORTS']) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              let allowAccess = false
              store.dispatch('mainViews/changeActiveSubApp', 'reporting')
              if (store.getters['common/showReportsApp'] === true) {
                allowAccess = true
                store.dispatch('mainViews/changeActiveSubApp', 'reports')
              }
              next(allowAccess)
            }
          },
        },
        {
          path: 'customReports',
          name: 'TheAdminCustomReportConfig',
          component: () => import('@/views/reporting/TheAdminCustomReportConfig.vue'),
          beforeEnter: async (to, from, next) => {
            if (!store.getters['common/canConfigureReports'] === true) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
        {
          path: 'audit',
          name: 'TheAuditReport',
          component: () => import('@/views/reporting/TheAuditReport.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canRunAudit'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
        {
          path: 'customReport',
          name: 'TheCustomReport',
          component: () => import('@/views/reporting/TheGenericReport.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canRunCustomReport'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
        {
          path: 'savedWorkflowReport/:reportId',
          name: 'TheSavedWorkflowReport',
          component: () => import('@/views/reporting/TheSavedWorkflowReport.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canRunWorkflowReport'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
        {
          path: 'savedAuditReport/:reportId',
          name: 'TheSavedAuditReport',
          component: () => import('@/views/reporting/TheSavedAuditReport.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canRunAudit'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
        {
          path: 'generic/:reportType',
          name: 'TheGenericReport',
          component: () => import('@/views/reporting/TheGenericReport.vue'),
          beforeEnter: async (to, from, next) => {
            let allowAccess = false
            switch (to.params.reportType) {
              case 'user':
                allowAccess = store.getters['common/canRunUserReport'] === true
                break
              case 'document':
              case 'documentcontrol':
                allowAccess = store.getters['common/canRunDocumentReport'] === true
                break
              default:
                break
            }
            if (!allowAccess === true) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
        {
          path: 'reportConfig',
          name: 'TheReportConfig',
          component: () => import('@/views/reporting/TheReportConfig.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canConfigureReports'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
        {
          path: 'workflow',
          name: 'TheWorkflowReport',
          meta: { requiresAuth: true },
          component: () => import('@/views/reporting/TheWorkflowReport.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canRunWorkflowReport'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
        {
          path: 'workflow/workflowReportConfig',
          name: 'WorkflowReportConfig',
          meta: { requiresAuth: true },
          component: () => import('@/views/reporting/WorkflowReportConfig.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canRunWorkflowReport'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
        {
          path: 'audit/auditReportConfig',
          name: 'AuditReportConfig',
          meta: { requiresAuth: true },
          component: () => import('@/views/reporting/AuditReportConfig.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canRunAuditReport'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
        {
          path: 'scheduledReports',
          name: 'ReportingScheduledReports',
          component: () => import('@/views/reporting/scheduledReports/ScheduledReports.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canAccessScheduledReports'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              const data = store.getters['reporting/getScheduledReportingProps']
              store.dispatch('automationEngine/setSchedulerProps', data)
              next();
            }
          },
        },
        {
          path: 'scheduledReports/editScheduledReport/:schedulerId',
          name: 'EditReportScheduledReporting',
          component: () => import('@/views/admin/automationEngine/EditScheduledReport.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canAccessScheduledReports'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
      ],
    },

    // **************************************************************************************************************************************************
    // ***************************************************************    Signature App   ***************************************************************
    // **************************************************************************************************************************************************

    {
      path: '/digitalSignature',
      meta: { requiresAuth: true },
      component: () => import('@/views/digitalSignature/TheDigitalSignature.vue'),
      beforeEnter: async (to, from, next) => {
        if (store.getters['common/showSignatureApp'] === false) {
          store.dispatch('mainViews/toggleVasionHome', true)
          next({ name: 'TheVasionHome' })
        } else {
          store.dispatch('mainViews/changeActiveSubApp', 'signature')
          store.dispatch('mainViews/toggleVasionHome', false)
          next()
        }
      },
      children: [
        {
          path: 'newDocument',
          name: 'NewDocument',
          component: () => import('@/views/digitalSignature/NewDocument.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canCreateSignatureDocument'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
        {
          path: 'editDocument/:documentId',
          name: 'SignatureEditDocument',
          component: () => import('@/views/digitalSignature/SignatureEditDocument.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canCreateSignatureDocument'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
        {
          path: 'inbox',
          name: 'TheSignatureInbox',
          component: () => import('@/views/digitalSignature/TheGeneralSignatureInbox'),
          beforeEnter: (to, from, next) => {
            store.dispatch('digitalSignature/setInboxType', 'Inbox')
            next()
          },
        },
        {
          path: 'sent',
          name: 'TheSignatureSent',
          component: () => import('@/views/digitalSignature/TheGeneralSignatureInbox'),
          beforeEnter: (to, from, next) => {
            if (store.getters['common/canCreateSignatureDocument'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              store.dispatch('digitalSignature/setInboxType', 'Sent')
              next()
            }
          },
        },
        {
          path: 'completed',
          name: 'TheSignatureCompleted',
          component: () => import('@/views/digitalSignature/TheSignatureCompleted.vue'),
        },
        {
          path: 'recalled',
          name: 'TheSignatureRecalled',
          component: () => import('@/views/digitalSignature/TheSignatureRecalled.vue'),
        },
        {
          path: 'templates',
          name: 'TheSignatureTemplates',
          component: () => import('@/views/digitalSignature/TheSignatureTemplates.vue'),
          beforeEnter: async (to, from, next) => {
            if (store.getters['common/canCreateSignatureDocument'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
      ],
    },
    // **************************************************************************************************************************************************
    // ****************************************************************    Storage App   ****************************************************************
    // **************************************************************************************************************************************************

    {
      path: '/storage',
      meta: { requiresAuth: true },
      component: () => import('@/views/storage/TheStorage.vue'),
      beforeEnter: async (to, from, next) => {
        if (!store.getters['common/APP_ACCESS_STORAGE']) {
          store.dispatch('mainViews/toggleVasionHome', true);
          next({ name: 'TheVasionHome' })
        } else {
          store.dispatch('mainViews/changeActiveSubApp', 'storage')
          store.dispatch('mainViews/toggleVasionHome', false)
          next()
        }
      },
      children: [
        {
          path: '/storage/empty',
          name: 'TheEmptyFolders',
          component: () => import('@/views/vault/EmptyFolders.vue'),
          beforeEnter: (to, from, next) => {
            store.dispatch('mainViews/changeMainNav', '')
            next()
          },
        },
        {
          path: '/storage/folders/:folderId',
          name: 'Folders',
          component: () => import('@/views/vault/VaultFolders.vue'),
          beforeEnter: async (to, from, next) => {
            if (!store.getters['common/canConfigureFolders'] === true) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
        {
          path: '/storage/maxxSafe',
          name: 'MaxxSafeConfig',
          component: () => import('@/views/storage/MaxxSafeConfig.vue'),
          beforeEnter: async (to, from, next) => {
            if (!store.getters['common/isAdminUser'] === true) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next();
            }
          },
        },
        {
          path: 'vaults',
          name: 'VaultFolders',
          component: () => import('@/views/vault/VaultFolders.vue'),
          meta: { requiresAuth: true },
          beforeEnter: async (to, from, next) => {
            if (!store.getters['common/APP_ACCESS_STORAGE']) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
      ],

    },

    // **************************************************************************************************************************************************
    // ***************************************************************    Templates App   ***************************************************************
    // **************************************************************************************************************************************************
    {
      path: '/templates',
      meta: { requiresAuth: true },
      component: () => import('@/views/templates/TheTemplatesApp.vue'),
      beforeEnter: async (to, from, next) => {
        if (!store.getters['common/APP_ACCESS_TEMPLATES']) {
          store.dispatch('mainViews/toggleVasionHome', true)
          next({ name: 'TheVasionHome' })
        } else {
          store.dispatch('mainViews/changeActiveSubApp', 'templates')
          store.dispatch('mainViews/toggleVasionHome', false)
          if (!store.getters['common/canConfigureEmailTemplates'] === true) {
            store.dispatch('mainViews/toggleVasionHome', true)
            next({ name: 'TheVasionHome' })
          } else {
            next()
          }
        }
      },
      children: [
        {
          path: 'allEmailTemplates',
          component: () => import('@/views/admin/AllEmailTemplates.vue'),
          name: 'AllEmailTemplates',
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            if (store.getters['common/canConfigureEmailTemplates'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              store.dispatch('mainViews/changeMainNav', 'all-emails')
              next()
            }
          },
        },
        {
          path: 'configureEmailTemplate/:templateId/:copy',
          name: 'ConfigureEmailTemplate',
          meta: { requiresAuth: true },
          component: () => import('@/views/admin/EmailTemplateContainer.vue'),
          beforeEnter: (to, from, next) => {
            if (store.getters['common/canConfigureEmailTemplates'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
        {
          path: 'editDocumentTemplate/:templateId',
          name: 'EditDocumentTemplate',
          meta: { requiresAuth: true },
          component: () => import('@/views/templates/EditDocumentTemplate.vue'),
          beforeEnter: (to, from, next) => {
            if (store.getters['common/isAdminUser'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              next()
            }
          },
        },
        {
          path: 'documentTemplates',
          component: () => import('@/views/templates/DocumentTemplates.vue'),
          name: 'DocumentTemplates',
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            if (store.getters['common/isAdminUser'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              store.dispatch('mainViews/changeMainNav', 'document-templates')
              next()
            }
          },
        },
      ],
    },

    // **************************************************************************************************************************************************
    // ***************************************************************    Workflow App   ****************************************************************
    // **************************************************************************************************************************************************

    {
      path: '/workflow',
      meta: { requiresAuth: true },
      component: () => import('@/views/workflow/TheWorkflowApp.vue'),
      beforeEnter: async (to, from, next) => {
        if (store.getters['common/showWorkflowApp'] === false) {
          store.dispatch('mainViews/toggleVasionHome', true)
          next({ name: 'TheVasionHome' })
        } else {
          store.dispatch('mainViews/toggleVasionHome', false)
          store.dispatch('mainViews/changeActiveSubApp', 'workflow')
          next()
        }
      },
      children: [
        {
          path: 'all',
          name: 'AllWorkflows',
          component: () => import('@/views/admin/TheAllWorkflows.vue'),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            if (store.getters['common/showWorkflowApp'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              store.dispatch('mainViews/changeMainNav', 'workflow-designer')
              next()
            }
          },
        },
        {
          path: 'workflowAdmin',
          name: 'WorkflowAdmin',
          component: () => import('@/views/workflow/TheWorkflowAdmin.vue'),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            if (store.getters['common/showWorkflowApp'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              if (!store.state.workflow.selectedWorkflow || (store.state.workflow.selectedWorkflow .id === 0 && !store.state.workflow.selectedWorkflow.name)) {
                // handles the user refreshing the workflow designer
                // deep linking is not built in there, so it gets to a weird state
                // in that case, just bring them back to the list of workflows
                next({ name: 'AllWorkflows' })
              }
              else {
                store.dispatch('mainViews/changeActiveSubApp', 'workflow')
                next()
              }
            }
          },
        },
        {
          path: 'workflowTasks',
          name: 'WorkflowTasks',
          component: () => import('@/views/admin/WorkflowTasks.vue'),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            if (store.getters['common/APP_ACCESS_WORKFLOW'] === false || store.getters['common/isWorkflowAdminUser'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              store.dispatch('workflow/setWorkflowTask', 'Tasks')
              next()
            }
          },
        },
        {
          path: 'taskLists',
          name: 'WorkflowTasklists',
          component: () => import('@/views/admin/WorkflowTasks.vue'),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            if (store.getters['common/APP_ACCESS_WORKFLOW'] === false || store.getters['common/isWorkflowAdminUser'] === false) {
              store.dispatch('mainViews/toggleVasionHome', true)
              next({ name: 'TheVasionHome' })
            } else {
              store.dispatch('workflow/setWorkflowTask', 'Tasklist')
              next()
            }
          },
        },
      ],
    },

    // **************************************************************************************************************************************************
    // ***************************************************************    Workspace App   ***************************************************************
    // **************************************************************************************************************************************************

    {
      path: '/workspace',
      meta: { requiresAuth: true },
      component: () => import('@/views/workspace/TheWorkspaceApp.vue'),
      beforeEnter: async (to, from, next) => {
        if (!store.getters['common/APP_ACCESS_WORKSPACE']) {
          store.dispatch('mainViews/toggleVasionHome', true);
          next({ name: 'TheVasionHome' })
        } else {
          store.dispatch('mainViews/toggleVasionHome', false)
          store.dispatch('mainViews/changeActiveSubApp', 'workspace')
          next()
        }
      },
      children: [

        {
          path: 'inbox',
          name: 'TheWorkspaceHome',
          meta: { requiresAuth: true },
          component: () => import('@/views/home/TheHomeInbox.vue'),
        },
      ],
    },
    
    // **************************************************************************************************************************************************
    // ***************************************************************    Redirect Shared Rail URLs    **************************************************
    // **************************************************************************************************************************************************
    {
      path: '/redirect',
      name: 'RedirectURL',
      meta: { requiresAuth: true },
      component: () => import('@/views/home/LandingPageInfo.vue'),
      beforeEnter: (to) => {
        window.location.href = JSON.parse(sessionStorage.getItem(`domains`))[to.query.key] + to.query.pathname
      },
    },

    // End of routes
  ],
});

const originalRouterPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalRouterPush.call(this, location, onResolve, onReject)
  return originalRouterPush.call(this, location).catch(err => err)
}

document.addEventListener("ProRailNavigation", async (event) => {
  const domain = event.detail.domain
  const pathname = event.detail.toPath
  
  if (domain == 'pi' || domain == 'vac') {
    //we need to handle a change in domain; that is, browsing to Automate or Print
    //we want to allow our Dirty State check to do its thing
    router.push({name: "RedirectURL", query: { key: domain, pathname: pathname }})
    return
  }
  
  //domain == 'pro'
  if (pathname.endsWith("/admin/emptyAdmin")) {
    await router.push({ name: 'TheEmptyAdmin' })
  } else if (pathname.endsWith("/capture/startScan")) {
    await router.push({ name: 'StartScan' })
  } else if (pathname.endsWith("/digitalSignature/inbox")) {
    await router.push({ name: 'TheSignatureInbox' })
    store.dispatch('mainViews/changeMainNav', 'signatureInbox')
  } else if (pathname.endsWith("/reporting/empty")) {
    await router.push({ name: 'TheEmptyReport' })
  } else if (pathname.endsWith("/storage/vaults")) {
    await router.push({ name: 'VaultFolders' })
  } else if (pathname.endsWith("/forms/all")) {
    await router.push({ name: 'AllForms' })
  } else if (pathname.endsWith("/automation/home")) {
    await router.push({ name: 'TheAutomationHome' })
  } else if (pathname.endsWith("/templates/allEmailTemplates")) {
    store.dispatch('common/getEmailTemplates')
    await router.push({ name: 'AllEmailTemplates' })
  } else if (pathname.endsWith("/workflow/all")) {
    store.dispatch('workflow/selectWorkflow', 0)
    await router.push({ name: 'AllWorkflows' })
  } else if (pathname.endsWith("/workspace/inbox")) {
    await router.push({ name: 'TheWorkspaceHome' })
    store.dispatch('mainViews/changeMainNav', 'my-tasks-pending')
  } else if (pathname.endsWith("/profile/usersettings")) {
    await router.push({ name: 'ProfileSettings' })
    store.dispatch('mainViews/changeMainNav', 'user-settings')
  } else {
    await router.push({ name: 'TheVasionHome' })
    //logging that we received a route we didn't expect
    console.log("Redirected to Home", pathname)
  }
  store.dispatch('mainViews/activateSideNav', true)
});

router.afterEach(async () => {
  if (store.state.systemSettings.featureFlags.vasionAutomateBridge)
  {
    const updateEvent = new CustomEvent('UpdateActiveItem');
    document.dispatchEvent(updateEvent);
  }
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('mainViews/checkNavFromLocalStorage')

  if (to.name === 'TheLogin') {
    await store.dispatch('common/checkIsAuthenticated')
    if (store.state.common.isSignedIn && !store.state.common.needsPasswordReset) {
      next({
        path: '/vasionHome',
      });
      return
    }
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    await store.dispatch('common/checkIsAuthenticated')
    if (store.state.common.isSignatureDocLinkView && to.name !== 'Document' && to.name !== 'TheSignatureDocLink') {
      store.dispatch('common/setIsSignatureDocLinkView', false)
      localStorage.clear()
    }
    if (!store.state.common.isSignedIn || store.state.common.needsPasswordReset) {
      let path = '/'
      if (to
          && (
            to.path.indexOf('docLink') > -1 || to.path.indexOf('document/') > -1
            || to.path.indexOf('workspace/inbox') > -1 || to.path.indexOf('reporting/empty') > -1 
            || to.path.indexOf('storage/vaults') > -1 || to.path.indexOf('capture/startScan') > -1
            || to.path.indexOf('digitalSignature/inbox') > -1 || to.path.indexOf('forms/all') > -1 
            || to.path.indexOf('templates/allEmailTemplates') > -1 || to.path.indexOf('automation/home') > -1
            || to.path.indexOf('workflow/all') > -1 || to.path.indexOf('admin/emptyAdmin') > -1
            || to.path.indexOf('forms/myForm/') > -1 || to.path.indexOf('profile/usersettings') > -1
          )
      ) {
        path = to.path
      }
      next({
        path: '/login',
        query: { redirect: path },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
