const MeanConfidenceLevels = {
    NoError: 85,
    SomeError: 60,
};

const getMeanConfidenceColor = function (meanConfidence) {
    if (!meanConfidence && meanConfidence !== 0) {
        return '';
    }
    else if (meanConfidence > MeanConfidenceLevels.NoError) {
        return 'LawnGreen';
    }
    else if (meanConfidence > MeanConfidenceLevels.SomeError) {
        return 'Yellow';
    }
    else {
        return 'Red';
    }
}

export { 
    MeanConfidenceLevels,
    getMeanConfidenceColor,
};
