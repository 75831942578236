/* eslint-disable linebreak-style */
/* eslint-disable default-case */
/* eslint-disable consistent-return */

import { createAxiosInstance } from '@/services/axiosService'

const deleteBarcodeIndex = (apiToken, id) => {
  // do something
  const axios = createAxiosInstance(apiToken)
  try {
    axios.delete('v1/admin/DeleteBarcodeIndex', { data: id })
  } catch (error) {
    console.warn(error)
  }
}

const getAllBarcodeIndex = async (apiToken) => {
  // do something
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/admin/getAllBarcodeIndex')
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getSmsConfig = async (apiToken) => {
  // do something
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/admin/GetTwilioSettings')
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveBarcodeIndex = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/admin/SaveBarcodeIndex', payload)
    if (response) {
      return response.data
    }
    } catch (error) {
      console.warn(error)
    }
  }

  const saveSmsConfig = async (apiToken, payload) => {
    const axios = createAxiosInstance(apiToken)
    try {
      const response = await axios.post('v1/admin/SaveTwilioSettings', payload)
      if (response) {
        return response.data
      }
    } catch (error) {
      console.warn(error)
    }
  }

  const sendTestTextMessage = async (apiToken, payload) => {
    const axios = createAxiosInstance(apiToken)
    try {
      const response = await axios.post('v1/admin/SendTestTextMessage', payload)
      return response
    } catch (error) {
      console.warn(error)
    }
  }

export {
  deleteBarcodeIndex,
  getAllBarcodeIndex,
  getSmsConfig,
  saveBarcodeIndex,
  saveSmsConfig,
  sendTestTextMessage,
}
