/* eslint-disable linebreak-style */
/* eslint-disable default-case */
/* eslint-disable consistent-return */
/* eslint-disable arrow-parens */

import {
  deleteBarcodeIndex,
  getAllBarcodeIndex,
  getSmsConfig,
  saveBarcodeIndex,
  saveSmsConfig,
  sendTestTextMessage,
} from '@/store/apiModules/mainViews.api'

import { getIndexFields } from '@/store/apiModules/common.api'

import {
  buildBarcodeGridData,
  buildEmptyConfigFromBarcode,
} from '@/store/helperModules/mainViews.module'

function initialState() {
  return {
    activeSubApp: '',
    activateSideNav: false,
    allBarcodeIndex: {},
    barcodeGridData: {},
    emptyIndexFields: [],
    isSavedSearchOpen: false,
    mainNav: 'home',
    mainNavExpanded: true,
    showNetworkTimeoutModal: false,
    smsConfig: {
      accountSID: '',
      authToken: '',
      fromPhoneNumber: '',
    },
    vasionHomeToggle: false,
  }
}

const mainViews = {
  namespaced: true,
  state: initialState,
  mutations: {
    activateSideNav(state, value) {
      state.activateSideNav = value
    },
    changeActiveSubApp(state, value) {
      state.activeSubApp = value
      sessionStorage.setItem('ActiveSubApp', value)
    },
    changeMainNav(state, value) {
      state.mainNav = value
      sessionStorage.setItem('MainNav', value)
    },
    removeBarcodeIndexFromState(state, value) { state.barcodeGridData.tableRows = state.barcodeGridData.tableRows.filter(e => e.Id !== value.Value) },
    resetStoreData(state) {
      const localState = initialState()
      Object.keys(state).forEach((prop) => {
        state[prop] = localState[prop]
      })
    },
    setAllBarcodeIndex(state, value) { state.allBarcodeIndex = value },
    setBarcodeGridData(state, value) { state.barcodeGridData = buildBarcodeGridData(value) },
    setEmptyIndexFields(state, value) { state.emptyIndexFields = buildEmptyConfigFromBarcode(value) },
    setNetworkTimeoutModal(state, value) { state.showNetworkTimeoutModal = value },
    setSmsConfig(state, value) { state.smsConfig = value },
    setVasionHomeToggle(state, value) { state.vasionHomeToggle = value },
    toggleSavedSearchDropDown(state, value) { state.isSavedSearchOpen = value },
  },
  actions: {
    activateSideNav({ commit }, value) { return commit('activateSideNav', value)},
    changeActiveSubApp({ commit }, value) { return commit('changeActiveSubApp', value) },
    changeMainNav({ commit }, value) { return commit('changeMainNav', value) },
    checkNavFromLocalStorage({ commit }) {
      // check the main nav items against what's in local storage
      // if they're different, it's from a page refresh, so grab what's in storage and use that
      const localActiveSubApp = sessionStorage.getItem('ActiveSubApp')
      if (localActiveSubApp && localActiveSubApp !== this.state.activeSubApp) {
        commit('changeActiveSubApp', localActiveSubApp)
      }

      const localMainNav = sessionStorage.getItem('MainNav')
      if (localMainNav && localMainNav !== this.state.mainNav) {
        commit('changeMainNav', localMainNav)
      }
    },
    async deleteBarcodeIndex({ dispatch, commit, rootState }, id) {
      commit('removeBarcodeIndexFromState', id)
      await deleteBarcodeIndex(rootState.common.apiToken, id)
      return dispatch('getAllBarcodeIndex')
    },
    async getAllBarcodeIndex({ commit, rootState }) {
      const data = await getAllBarcodeIndex(rootState.common.apiToken)
      commit('setAllBarcodeIndex', data)
      return commit('setBarcodeGridData', data)
    },
    async getIndexFields({ commit, rootState }, formId) {
      const response = await getIndexFields(rootState.common.apiToken, formId)
      commit('setEmptyIndexFields', response)
    },
    async getSmsConfig({ commit, rootState }, formId) {
      const response = await getSmsConfig(rootState.common.apiToken, formId)
      commit('setSmsConfig', response)
    },
    async saveBarcodeIndex({ dispatch, rootState }, payload) {
      await saveBarcodeIndex(rootState.common.apiToken, payload)
      return dispatch('getAllBarcodeIndex')
    },
    async saveSmsConfig({ dispatch, rootState }, payload) {
      const data = await saveSmsConfig(rootState.common.apiToken, payload)
      if (!data || !data.ResultStatus || data.ResultStatus !== 1) {
        return false
      }

      dispatch('getSmsConfig')
      return true
    },
    async sendTestTextMessage({ rootState }, payload) {
      let response = null
      try {
        response = await sendTestTextMessage(rootState.common.apiToken, payload)
      } catch (error) {
        console.warn(error)
      }

      return response
    },
    setNetworkTimeoutModal({ commit }, value) { commit('setNetworkTimeoutModal', value) },
    toggleSavedSearchDropDown({ commit }, value) { return commit('toggleSavedSearchDropDown', value) },
    toggleVasionHome({ commit }, value) { return commit('setVasionHomeToggle', value) },
  },
  getters: {
    getBarcodeIndexingById: (state) => (id) => { return state.allBarcodeIndex.BIList.find(form => form.ID === id) },
    workflowListType: (state) => {
      let listType = 1
      if (!state.mainNav || state.mainNav === '') {
        return listType
      }

      switch (state.mainNav) {
        case 'favoriteWorkflows':
          listType = 2
          break;
        case 'sharedWithMeWorkflows':
          listType = 3
          break;
        case 'archivedWorkflows':
          listType = 4
          break
        default:
          listType = 1
          break
      }

      return listType
    },
  },
}

export default mainViews
