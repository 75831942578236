<template>
  <div class="container">
    <img
      v-if="savedSignatureOrInitials && savedSignatureOrInitials !== ''"
      class="signature-image-bounds"
      :src="savedSignatureOrInitials"
      alt="Signature"
    >
  </div>
</template>

<script>
export default {
  name: 'AddSaved',
  props: {
    savedSignatureOrInitials: {
      type: String,
      required: true,
    }
  },
  methods: { },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .container {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $grey-100;
  }

  .signature-image-bounds {
    max-height: 200px;
  }

</style>
