<template>
  <div id="upload-file">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <span id="header">{{ headerText }}</span>
    <div id="main-section">
      <div id="storage-options">
        <div class="row">
          <span class="section-title">
            BROWSE / SCAN
          </span>
        </div>
        <ul class="vasion-ul">
          <li
            v-if="!folderSelectionMode"
            id="local-drive"
            name="local-drive"
            class="vasion-drive-button"
            :class="[selectedUploader === 'LocalDriveUpload' ? 'active' : '']"
            :disabled="!allowUpload"
            @click="switchUploader('LocalDriveUpload'); setButtonInactive();"
          >
            <VasionMonitorIcon /><span class="drive-text"> Local Drive</span>
          </li>

          <li
            v-if="driveTypes[0]"
            :id="driveTypes[0].storageType + '-' + driveTypes[0].storageConfigId"
            :key="driveTypes[0].storageConfigId"
            :name="driveTypes[0].storageDisplayName"
            class="vasion-drive-button"
            :class="[selectedStorageConfigId == driveTypes[0].storageConfigId ? 'active' : '']"
            :disabled="!allowBrowse"
            @click="switchUploader(driveTypes[0].storageConfigId); setButtonInactive();"
          >
            <VasionFolderSparkIcon /> Vasion Drive
          </li>

          <li
            v-if="!fileSelectionMode && showDirectScan"
            id="direct-scan"
            name="direct-scan"
            class="vasion-drive-button"
            :class="[selectedUploader === 'DirectScanUpload' ? 'active' : '']"
            :disabled="!allowScan"
            @click="switchUploader('DirectScanUpload'); setButtonInactive();"
          >
            <VasionScanIcon /> Direct Scan
          </li>
        </ul>

        <div v-if="!fileSelectionMode" class="row">
          <span class="section-title">
            3RD PARTY STORAGE
          </span>
        </div>

        <ul v-if="!fileSelectionMode" class="vasion-ul">
          <li
            v-for="driveType in driveTypes.filter(driveType => driveType.storageConfigId != 0)"
            :id="driveType.storageType + '-' + driveType.storageConfigId"
            :key="driveType.storageConfigId"
            :name="driveType.storageDisplayName"
            class="vasion-drive-button"
            :class="[selectedStorageConfigId == driveType.storageConfigId ? 'active' : '']"
            :disabled="!allowBrowse"
            @click="switchUploader(driveType.storageConfigId); setButtonInactive();"
          >
            <VasionGoogleIcon v-if="driveType.storageType === 'GoogleDrive'" />
            <VasionOneDriveIcon v-else-if="driveType.storageType === 'OneDrive'" />
            <VasionBoxIcon v-else-if="driveType.storageType === 'Box'" />
            <VasionWorkDocsIcon v-else-if="driveType.storageType === 'WorkDocs'" />
            <VasionS3Icon v-else-if="driveType.storageType === 'S3'" />
            <VasionWasabiIcon v-else-if="driveType.storageType === 'Wasabi'" />
            <VasionSharePointIcon v-else-if="driveType.storageType === 'SharePoint'" />

            {{ driveType.storageDisplayName }}
          </li>
        </ul>
      </div>

      <div id="file-section">
        <component
          :is="selectedUploader"
          :showFiles="!folderSelectionMode"
          :toggleStorageConfigIdByDefault="selectedStorageConfigId"
          :onlyShowStorageConfigId="selectedStorageConfigId"
          @file="setFileInMemory"
          @scanToVasionStarted="scanToVasionStarted"
          @itemSelected="setSelectedDocument"
        />
      </div>
    </div>
    <div id="footer">
      <VasionButton
        v-if="showBackButton"
        id="back"
        name="back"
        :classProp="'secondary'"
        @vasionButtonClicked="backToSelectWorkflow"
      >
        Back
      </VasionButton>

      <VasionButton
        id="cancel"
        name="cancel"
        :classProp="'secondary'"
        @vasionButtonClicked="cancelUpload"
      >
        Cancel
      </VasionButton>

      <VasionButton
        v-if="needToFillOutAttributeForm || !isWorkflow"
        id="continue-to-form"
        name="continue-to-form"
        :classProp="'primary'"
        :isDisabled="!canContinue"
        @vasionButtonClicked="continueToAttributeForm"
      >
        Continue
      </VasionButton>
      <VasionButton
        v-else
        id="start-workflow"
        name="start-workflow"
        :classProp="'primary'"
        :isDisabled="!canContinue"
        @vasionButtonClicked="startWorkflow"
      >
        Start Workflow
      </VasionButton>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import DirectScanUpload from '@/components/workflow/DirectScanUpload.vue';
import LocalDriveUpload from '@/components/workflow/LocalDriveUpload.vue';
import VasionUpload from '@/components/workflow/VasionUpload.vue';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import { toBase64 } from '@/store/helperModules/storage.module'

export default {
  name: 'StartWorkflowFileUpload',
  components: {
    DirectScanUpload,
    Loading,
    LocalDriveUpload,
    VasionUpload,
  },
  props: {
    fileSelectionMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    folderSelectionMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    showBackButton:{
      type: Boolean,
      required: false,
      default: true
    },
    showDirectScan: {
      type: Boolean,
      required: false,
      default: true,
    },
    handleFileInTheParent: {
      type: Boolean,
      required: false,
      default: false,
    },
    headerText: {
      type: String,
      required: false,
      default: 'Add Document',
    },
    isWorkflow: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: function () {
    return {
      driveTypes: [],
      folderList: [],
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      newUploadedFile: {},
      selectedItem: {},
      selectedUploader: 'LocalDriveUpload',
      selectedStorageConfigId: null,
    }
  },
  computed: {
    allowBrowse() { return this.startWorkflowPathData.allowBrowse || this.fileSelectionMode || this.handleFileInTheParent },
    allowScan() { return this.startWorkflowPathData.allowScan },
    allowUpload() { return (this.startWorkflowPathData.allowUpload || this.fileSelectionMode || this.handleFileInTheParent) && !this.folderSelectionMode },
    canContinue() {
      switch (this.selectedUploader) {
        case 'LocalDriveUpload':
          return this.uploadedFile && this.uploadedFile.file
        case 'VasionUpload':
          if (!this.folderSelectionMode) 
            return this.selectedDocumentId !== null && this.selectedDocumentId > 0
          else
            return Boolean(this.selectedItem?.value)
        default:
          return false;
      }
    },
    needToFillOutAttributeForm() { return this.startWorkflowPathData && this.startWorkflowPathData.defaultAttributeFormID !== null },
    selectedDocumentId() { return this.$store.state.workflow.selectedDocumentId },
    startWorkflowPathData() { return this.$store.state.workflow.startWorkflowPathData },
    uploadedFile() { return this.$store.state.storage.uploadedFile },
  },
  created: async function () {
    this.setButtonInactive()
    this.loadDriveTypes()
    if (this.allowUpload) {
      this.switchUploader('LocalDriveUpload')
    } else if (this.allowScan) {
      this.switchUploader('DirectScanUpload')
    } else {
      this.switchUploader(0)
    }
  },
  methods: {
    backToSelectWorkflow() {
      this.$emit('backButtonClick')
    },
    cancelUpload() {
      this.$emit('cancelButtonClick')
    },
    async continueToAttributeForm() {
      if (this.folderSelectionMode) {
        this.$emit('continueToAttributeForm', this.selectedItem)
        return
      }
      if (this.handleFileInTheParent) {
        if (this.selectedDocumentId !== null && this.selectedDocumentId > 0) {
          this.$emit('continueToAttributeForm', {file: { documentId: this.selectedDocumentId }, name: this.selectedItem.name})
        } else if (this.uploadedFile) {
          this.$emit('continueToAttributeForm', {file: { newFile: await toBase64(this.uploadedFile.file) }, name: this.uploadedFile.file.name})
        }
        return
      }

      if (this.fileSelectionMode) {
        this.isLoading = true
        const payload = {
          name: '',
          id: '',
          tempFile: null,
        }
        if (this.selectedDocumentId !== null && this.selectedDocumentId > 0) {
          payload.id = this.selectedDocumentId
          payload.name = this.selectedItem.name
        } else {
          const tempPayload = {
            name: this.uploadedFile.file.name,
            fileData: await toBase64(this.uploadedFile.file),
          }
          const tempFile = await this.$store.dispatch('document/uploadTempDocForMerge', tempPayload)
          payload.name = this.uploadedFile.file.name
          payload.id = tempFile.fullServerPath
          payload.tempFile = tempFile.thumbnailBase64
        }
        await this.$store.dispatch('document/addCheckedDocument', payload)
        this.isLoading = false
        this.$emit('fileAdded')
      }
      if (!this.canContinue) {
        return
      }

      this.$emit('continueToAttributeForm')
    },
    async loadDriveTypes() {
      this.driveTypes = await this.$store.dispatch('storage/getConfiguredDriveTypes', { showOnlyDataAutomation: false })
    },
    scanToVasionStarted() {
      this.$emit('scanToVasionStarted')
      this.cancelUpload()
    },
    setButtonInactive() {
      this.$store.dispatch('storage/saveUploadedFilePayloadInMemory', {})
      this.$store.dispatch('workflow/setSelectedDocumentId', null)
    },
    setFileInMemory(file) {
      this.newUploadedFile = file
    },
    setSelectedDocument(item) {
      this.selectedItem = item
    },
    async startWorkflow() {
      if (!this.canContinue) {
        return
      }

      if (this.needToFillOutAttributeForm) {
        this.continueToAttributeForm()
        return
      }

      const documentId = this.selectedDocumentId !== null && this.selectedDocumentId > 0 ? this.selectedDocumentId : await this.$store.dispatch('storage/uploadFileToVasion', this.uploadedFile)
      if (this.fileSelectionMode) {
        const payload = { name: this.uploadedFile.name, id: documentId }
        this.$store.dispatch('document/addCheckedDocument', payload)
      }

      if (documentId !== null && documentId > 0) {
        await this.$store.dispatch('workflow/setSelectedDocumentId',documentId)
        this.$emit('kickOffWorkflow')
      } else {
        this.$emit('workflowUnsuccessful')
      }
    },
    switchUploader(uploader) {
      this.selectedItem = {}
      if (uploader !== 'LocalDriveUpload' && uploader !== 'DirectScanUpload') {
        this.selectedUploader = 'VasionUpload'
        this.selectedStorageConfigId = uploader
      } else {
        this.selectedUploader = uploader
        this.selectedStorageConfigId = null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  #upload-file {
    width: 880px;
    margin: 15px;

    #header {
      @include Headline;
    }

    #main-section {
      margin-top: 10px;

      #storage-options {
        width: 300px;
        float: left;
        max-height: 400px;
        overflow-y: auto;
        margin-right: 5px;
        margin-bottom: 5px;

        .row {
          width: 100%;
          margin: 13px 0;

          .section-title {
            @include SECTION-HEADER;
            color: $grey-400;
            display: block;
            margin: 5px 0;
          }
        }
      }

      #file-section {
        width: 572px;
        height: 356px;
        border-radius: 4px;
        border: solid 1px $grey-100;
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
    #footer {
      margin: 10px;
      float: right;
    }
  }

  @media(max-width: $tablet){
    #upload-file{
      width: auto;
      margin: 15px;
    }
  }

  @media(max-width: $phone){
    #upload-file{
      #main-section{
        height: 100%;
        display: flex;
        flex-direction: column;
        #file-section{
         width: auto;
        }
      }
      #footer {
        margin: 0px;
      }
    }
  }

  li.vasion-drive-button {
    padding-left: 0px;
    margin-top: 13px;
    margin-bottom: 13px;
  }

  li.vasion-drive-button:disabled, li.vasion-drive-button[disabled] {
    pointer-events: none;
  }
</style>
