import Vue from 'vue'
import Vuex from 'vuex'

import admin from '@/store/admin.store'
import attributeForm from '@/store/attributeForm.store'
import automationEngine from '@/store/automationEngine.store'
import bpa from '@/store/bpa.store'
import capture from '@/store/capture.store'
import common from '@/store/common.store'
import digitalSignature from '@/store/digitalSignature.store'
import document from '@/store/document.store'
import fieldMapping from '@/store/fieldMapping.store'
import forms from '@/store/forms.store'
import integration from '@/store/integration.store'
import mainViews from '@/store/mainViews.store'
import maxxForms from '@/store/maxxForms.store'
import reporting from '@/store/reporting.store'
import search from '@/store/search.store'
import security from '@/store/security.store'
import storage from '@/store/storage.store'
import systemSettings from '@/store/systemSettings.store'
import template from '@/store/template.store'
import vault from '@/store/vault.store'
import workflow from '@/store/workflow.store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin,
    attributeForm,
    automationEngine,
    bpa,
    capture,
    common,
    digitalSignature,
    document,
    fieldMapping,
    forms,
    integration,
    mainViews,
    maxxForms,
    reporting,
    search,
    security,
    storage,
    systemSettings,
    template,
    vault,
    workflow,
  },
  actions: {
    resetStoreData({ commit }) {
      commit('admin/resetStoreData')
      commit('attributeForm/resetStoreData')
      commit('automationEngine/resetStoreData')
      commit('bpa/resetStoreData')
      commit('capture/resetStoreData')
      commit('common/resetStoreData')
      commit('digitalSignature/resetStoreData')
      commit('document/resetStoreData')
      commit('fieldMapping/resetStoreData')
      commit('integration/resetStoreData')
      commit('mainViews/resetStoreData')
      commit('maxxForms/resetStoreData')
      commit('reporting/resetStoreData')
      commit('search/resetStoreData')
      commit('security/resetStoreData')
      commit('storage/resetStoreData')
      commit('systemSettings/resetStoreData')
      commit('template/resetStoreData')
      commit('vault/resetStoreData')
      commit('workflow/resetStoreData')
    },
  },
})
