<template>
  <div />
</template>

<script>
/* eslint-disable  prefer-arrow-callback */

import { uuid } from 'vue-uuid';
import { fabric } from 'fabric';

export default {
    // Gets us the provider property from the parent <my-canvas> component.
    inject: ['provider', 'reference'],
    props: {
      aipZones: {
        type: Object,
        required: false,
        default: () => {},
      },
      annotations: {
        type: Object,
        required: false,
        // eslint-disable-next-line vue/require-valid-default-prop
        default: () => [],
      },
      canDelete: {
        type: Boolean,
        required: false,
        default: true,
      },
      disableMoveAndSaveAnnotations: {
        type: Boolean,
        required: false,
        default: false,
      },
      image: {
        type: Object,
        required: false,
        default: null,
      },
      isTextractAIP: {
        type: Boolean,
        required: false,
        default: false,
      },
      largeDefaultLoadSize: {
        required: false,
        type: Boolean,
        default: false,
      },
      pageNumber: {
        type: Number,
        required: true,
        default: null,
      },
      selectedAIP: {
        type: String,
        required: false,
        default: '',
      },
    },
    data() {
      return {
        actionStarted: false,
        activeEventFunctions: [],
        annotationsDrawn: false,
        base64DataPrefix: 'data:image/png;base64,',
        debug: false,
        editingText: false,
        localImage: this.image,
        nullAnnotationConfig: {
          type: '',
          color: '',
        },
        originalSignatures: [],
        rect: {
          start: { x: 0, y: 0 },
          end: { x: 0, y: 0 }
        },
        readyToDraw: false,
        scaleFactor: 1.3,
        zoomFactorX: 0,
        zoomFactorY: 0,
      }
    },
    computed: {
      annotationConfig() { return this.$store.state.document.annotation },
      activeSignature() { return this.$store.state.document.activeSignature },
      activeStamp() { return this.$store.state.document.activeStamp },
      canAddAnnotation() { return this.$store.getters['common/canAddAnnotation'] },
      drawSignature() { return this.$store.state.digitalSignature.drawSignature },
      pendingSignatureDocumentZones() { return this.$store.state.document.pendingSignatureDocumentZones },
      selectedAnnotation() { return this.$store.state.digitalSignature.selectedAnnotation },
      UserID() { return this.$store.state.common.userID },
      zoomValue() { return this.$store.state.document.zoomValue },
    },
    watch: {
      async aipZones() {
        await this.drawAllAnnotations(true)
      },
      annotationConfig: async function (val) {
        const { canvas } = this.provider
        if (canvas) {
          await this.removeActiveEventFunctions()
          switch (val.type) {
            case 'highlight':
              await this.initializeHighlightCreate()
              break
            case 'redaction':
              await this.initializeRedactionCreate()
              break
            case 'signature':
              canvas.defaultCursor = 'crosshair'
              if (this.annotationConfig.applyOnZone) {
                await this.signatureCreateMouseUp()
              } else {
                await this.initializeSignatureSelect()
              }
              break
            case 'stamp':
              canvas.defaultCursor = 'crosshair'
              await this.initializeStampSelect()
              break
            case 'text':
              canvas.defaultCursor = 'crosshair'
              if (this.annotationConfig.applyOnZone) {
                await this.textCreateMouseUp()
              } else {
                await this.initializeTextCreate()
              }
              break
            default:
              await this.initializeAnnotationSelect()
          }
        }
      },
      drawSignature() {
        if (this.drawSignature) {
          this.signatureCreateMouseUp()
        }
      },
      pageNumber() {
        this.localImage.PageNumber = this.image.PageNumber
        this.$store.dispatch('document/addOneToRefreshKey')
      },
      readyToDraw: async function () {
        if (this.readyToDraw && !this.annotationsDrawn) {
          await this.drawAllAnnotations()
          await this.initializeAnnotationSelect()
          this.readyToDraw = false
        }
      },
      zoomValue() {
        this.setZoomValue()
      },
    },
    created() {
      this.readyToDraw = true
      this.setBackgroundImage()
      this.setZoomValue()
      setTimeout(() => { // setTimeout is required to actually get the values
        this.provider.canvas._objects.forEach(obj => obj.vasionId = uuid.v1().toString())
        this.originalSignatures = this.provider.canvas._objects.map(obj => obj.vasionId)
      }, 500)
    },
    methods: {
      activeObject() { return this.provider.canvas.getActiveObject() },
      annotationMouseDown() {
        if (this.debug) { console.log('annotation:move-resize mouse:down') }
        if (this.annotationConfig.type !== '') {
          return false;
        }

        this.actionStarted = true
        if (this.activeObject()) {
          const activeObject = this.activeObject()
          activeObject.bringToFront()
          const obj = activeObject.toObject() // Need to do this to get the Fabric.js object extended properties we've added (ie. subType)
          this.setResizeControls(activeObject, obj.subType)
        }

        return true
      },
      async annotationMouseUp() {
        if (this.debug) { console.log('annotation:move-resize mouse:up') }
        if (!this.actionStarted || this.annotationConfig.type !== '') {
          return false;
        }
        if (this.activeObject() && this.activeObject().toObject()) {
          this.$store.dispatch('digitalSignature/setSelectedAnnotation', this.activeObject().toObject())
        }

        return true
      },
      onAnnotationSelection(e) {
        if (this.debug) { console.log('onAnnotationSelection', e.target, e.selected) }
        e.target.lockMovementX = e.selected.some(x => x.lockMovementX);
        e.target.lockMovementY = e.selected.some(x => x.lockMovementY);
        e.target.lockRotation = e.selected.some(x => x.lockRotation);
        e.target.lockScalingX = e.selected.some(x => x.lockScalingX);
        e.target.lockScalingY = e.selected.some(x => x.lockScalingY);
        e.target.lockScaling = e.selected.some(x => x.lockScaling);
        e.target.lockScalingFlip = e.selected.some(x => x.lockScalingFlip);
        e.target.setControlsVisibility({ mtr: false })
      },
      async checkDeleteKeyAndDelete(event) {
        if (this.debug) { console.log('annotation:delete') }
        if (this.canAddAnnotation) {
          const { canvas } = this.provider
          const key = event.keyCode || event.charCode
          if (this.activeObject()
            && (key === 8 || key === 46) // KeyCodes are for delete and backspace
            && !this.editingText
            && this.canDelete
            && !this.originalSignatures.includes(canvas.getActiveObject().vasionId)) {
              canvas.remove(this.activeObject());
          }
        }
      },
      async drawAllAnnotations(drawNewSingleZone = false) {
        const { canvas, context } = this.provider

        // Set the zoom factor based on the current size vs. the original localImage size
        // TODO: This still needs some work for smaller screens
        this.zoomFactorX = canvas.width / this.localImage.Width
        this.zoomFactorY = canvas.height / this.localImage.Height

        // Clear the canvas
        if (drawNewSingleZone) {
          canvas._objects.map(obj => canvas.remove(obj))
        } else {
          context.clearRect(0, 0, canvas.width, canvas.height)
          canvas.clear();
        }

        // Loop through the annotations.Values and draw each one
        this.annotations.Values.forEach((annotation) => {
          annotation.Locked = this.disableMoveAndSaveAnnotations ? true : annotation.Locked
          let shouldDrawAnnotation = true
          if (this.pendingSignatureDocumentZones && this.pendingSignatureDocumentZones.length > 0 && annotation.Properties.disableSave === 'True') {
            shouldDrawAnnotation = this.pendingSignatureDocumentZones.some(e => e.annotationID === annotation.UniqueID && String(e.signatureUserID) === String(this.UserID))
          }
          if (shouldDrawAnnotation === true) {
            switch (annotation.AnnType) {
              case 'Rectangle':
                this.renderRectangle(annotation, canvas)
                break
              case 'Picture':
              case 'Signature':
              case 'Initial':
              case 'Text':
                this.renderSignature(annotation, canvas, this.setAnnotationInteraction)
                break
              case 'Stamp':
                this.renderStamp(annotation, canvas)
                break
              default:
                break
            }
          }
        })

        if (!this.aipZones?.values) {
          this.annotationsDrawn = true
          return 1
        }

        this.aipZones?.values.forEach((zone, zoneIndex) => {
          const customCondition = this.isTextractAIP ? true : this.aipZones.aipZoneDocumentRanges[this.aipZones.selectedDocumentIndex].find(aip => aip === zoneIndex) !== undefined
          if (
            zone.ZoneHeight > 0.0
            && zone.ZoneWidth > 0.0
            && zone.ZoneX > 0.0
            && zone.ZoneY > 0.0
            && zone.Page === this.pageNumber
            && (zone.UniqueID === this.selectedAIP || this.selectedAIP === '')
            && customCondition
          ){
            let rectangle = {
              AnnType: "Rectangle",
              Groups: [],
              Height: zone.ZoneHeight,
              Locked: this.disableMoveAndSaveAnnotations ? true : zone.Locked,
              Name: "Highlight",
              PosX: zone.ZoneX,
              PosY: zone.ZoneY,
              Print: true,
              Properties: zone.fill,
              Rank: 3,
              ResultMessage: null,
              ResultStatus: 1,
              Rotate: 0,
              UniqueID: zone.UniqueID,
              Users: [],
              Visible: true,
              Width: zone.ZoneWidth,
            }
            this.renderRectangle(rectangle, canvas)
          }
        })

        this.annotationsDrawn = true
        return 1
      },
      extendFabricObject(objectToExtend, uniqueId, subType, disableSave, propertiesSubType, includeTime, bottomAlign, isRequired) {
        return (function (toObject) {
          return function () {
            return fabric.util.object.extend(toObject.call(this), {
              id: uniqueId || '',
              subType: subType || '',
              disableSave: disableSave || '',
              propertiesSubType: propertiesSubType || '',
              includeTime: includeTime || '',
              bottomAlign: bottomAlign || '',
              isRequired: isRequired || '',
            });
         };
        }(objectToExtend.toObject))
      },
      getCursorPosition(event) {
        const { canvas } = this.provider
        let pointer = canvas.getPointer(event.e)
        return [pointer.x, pointer.y]
      },
      getRect() {
        const left = Math.min(this.rect.start.x, this.rect.end.x)
        const right = Math.max(this.rect.start.x, this.rect.end.x)
        const top = Math.min(this.rect.start.y, this.rect.end.y)
        const bottom = Math.max(this.rect.start.y, this.rect.end.y)

        return {
          left: left,
          top: top,
          width: right-left,
          height: bottom-top,
        }
      },
      async getTextImageFromText() {
        return 'image'
      },
      async highlightCreateMouseDown(options) {
        if (this.debug) { console.log('highlight:create mouse:down') }
        if (this.annotationConfig.type === '') {
          return false;
        }

        this.actionStarted = true
        const coords = this.getCursorPosition(options)
        this.rect.start = { x: coords[0], y: coords[1] }

        return true
      },
      highlightCreateMouseMove(options) {
        if (this.debug) { console.log('highlight:create mouse:move') }
        if (!this.actionStarted || this.annotationConfig.type === '') {
          return false;
        }

        const coords = this.getCursorPosition(options)
        this.rect.end = { x: coords[0], y: coords[1] }

        return true
      },
      async highlightCreateMouseUp() {
        if (this.debug) { console.log('highlight:create mouse:up') }
        if (!this.actionStarted || this.annotationConfig.type === '') {
          return false;
        }

        const { canvas } = this.provider

        if (this.actionStarted) {
          this.actionStarted = false;
        }

        const rectangle = new fabric.Rect({
          ...this.getRect(),
          fill: this.annotationConfig.color,
        })
        rectangle.setControlsVisibility({ mtr: false })
        rectangle.toObject = this.extendFabricObject(rectangle, uuid.v1().toString(), this.annotationConfig.type)

        canvas.add(rectangle)
        canvas.setActiveObject(rectangle)
        this.setResizeControls(this.activeObject(), '')

        canvas.defaultCursor = 'default'
        await this.$store.dispatch('document/setAnnotationConfig', this.nullAnnotationConfig)

        return true
      },
      async initializeAnnotationSelect() {
        const { canvas } = this.provider
        await this.removeActiveEventFunctions()

        canvas.on('mouse:down', this.annotationMouseDown, false)
        this.activeEventFunctions.push('mouse:down')
        canvas.on('mouse:up', this.annotationMouseUp, false)
        this.activeEventFunctions.push('mouse:up')
        canvas.on({ 'selection:created': this.onAnnotationSelection });
        this.activeEventFunctions.push('selection:created')

        document.addEventListener('keyup', this.checkDeleteKeyAndDelete, false)
      },
      async initializeHighlightCreate() {
        const { canvas } = this.provider
        canvas.defaultCursor = 'crosshair'
        await this.removeActiveEventFunctions()

        canvas.on('mouse:down', this.highlightCreateMouseDown, false)
        this.activeEventFunctions.push('mouse:down')
        canvas.on('mouse:move', this.highlightCreateMouseMove, false)
        this.activeEventFunctions.push('mouse:move')
        canvas.on('mouse:up', this.highlightCreateMouseUp, false)
        this.activeEventFunctions.push('mouse:up')
      },
      async initializeRedactionCreate() {
        const { canvas } = this.provider
        canvas.defaultCursor = 'crosshair'
        await this.removeActiveEventFunctions()

        canvas.on('mouse:down', this.redactionCreateMouseDown, false)
        this.activeEventFunctions.push('mouse:down')
        canvas.on('mouse:move', this.redactionCreateMouseMove, false)
        this.activeEventFunctions.push('mouse:move')
        canvas.on('mouse:up', this.redactionCreateMouseUp, false)
        this.activeEventFunctions.push('mouse:up')
      },
      async initializeSignatureSelect() {
        const { canvas } = this.provider
        await this.removeActiveEventFunctions()

        canvas.on('mouse:down', this.signatureCreateMouseDown, false)
        this.activeEventFunctions.push('mouse:down')
        canvas.on('mouse:move', this.signatureCreateMouseMove, false)
        this.activeEventFunctions.push('mouse:move')
        canvas.on('mouse:up', this.signatureCreateMouseUp, false)
        this.activeEventFunctions.push('mouse:up')
      },
      async initializeStampSelect() {
        const { canvas } = this.provider
        await this.removeActiveEventFunctions()

        canvas.on('mouse:down', this.stampCreateMouseDown, false)
        this.activeEventFunctions.push('mouse:down')
        canvas.on('mouse:move', this.stampCreateMouseMove, false)
        this.activeEventFunctions.push('mouse:move')
        canvas.on('mouse:up', this.stampCreateMouseUp, false)
        this.activeEventFunctions.push('mouse:up')
      },
      async initializeTextCreate() {
        const { canvas } = this.provider
        await this.removeActiveEventFunctions()

        canvas.on('mouse:down', this.textCreateMouseDown, false)
        this.activeEventFunctions.push('mouse:down')
        canvas.on('mouse:move', this.textCreateMouseMove, false)
        this.activeEventFunctions.push('mouse:move')
        canvas.on('mouse:up', this.textCreateMouseUp, false)
        this.activeEventFunctions.push('mouse:up')
      },
      loadImage(image, canvas, top, left, width, height, saveFunction, objectExtensionFunction, setResizeControlsFunction, subType, uniqueId, applyOnZone) {
        return async function () {
          const newImg = new fabric.Image(image, {
            top: top,
            left: left,
            lockRotation: true,
            lockScalingFlip: true,
            strokeDashArray: [5, 5],
          });

          newImg.setControlsVisibility({ mtr: false })

          if (applyOnZone) {
            newImg.lockMovementX = true
            newImg.lockMovementY = true
            newImg.lockScalingY = true
            newImg.lockScalingX = true
            newImg.lockScaling = true
          }

          const widthScale = width / image.width
          const heightScale = height / image.height
          if (widthScale < heightScale) {
            newImg.scaleToWidth(width)
          } else {
            newImg.scaleToHeight(height)
          }

          newImg.toObject = objectExtensionFunction(newImg, uniqueId, subType)

          canvas.add(newImg)
          canvas.setActiveObject(newImg)
          setResizeControlsFunction(canvas.getActiveObject(), subType)

          await saveFunction()
        }
      },
      async redactionCreateMouseDown(options) {
        if (this.debug) { console.log('redaction:create mouse:down') }
        if (this.annotationConfig.type === '') {
          return false;
        }

        this.actionStarted = true
        const coords = this.getCursorPosition(options)
        this.rect.start = { x: coords[0], y: coords[1] }

        return true
      },
      redactionCreateMouseMove(options) {
        if (this.debug) { console.log('redaction:create mouse:move') }
        if (!this.actionStarted || this.annotationConfig.type === '') {
          return false;
        }

        const coords = this.getCursorPosition(options)
        this.rect.end = { x: coords[0], y: coords[1] }

        return true
      },
      async redactionCreateMouseUp() {
        if (this.debug) { console.log('redaction:create mouse:up') }
        if (!this.actionStarted || this.annotationConfig.type === '') {
          return false;
        }

        const { canvas } = this.provider

        if (this.actionStarted) {
          this.actionStarted = false;
        }

        const rectangle = new fabric.Rect({
          ...this.getRect(),
          fill: this.annotationConfig.color,
        })
        rectangle.setControlsVisibility({ mtr: false })
        rectangle.toObject = this.extendFabricObject(rectangle, uuid.v1().toString(), this.annotationConfig.subType)

        canvas.add(rectangle)
        canvas.setActiveObject(rectangle)
        this.setResizeControls(this.activeObject(), '')

        canvas.defaultCursor = 'default'
        await this.$store.dispatch('document/setAnnotationConfig', this.nullAnnotationConfig)

        return true
      },
      async removeActiveEventFunctions() {
        const { canvas } = this.provider
        this.activeEventFunctions.forEach(item => canvas.off(item))
        this.activeEventFunctions = []

        document.removeEventListener('keyup', this.checkDeleteKeyAndDelete);
      },
      async renderRectangle(annotation, canvas) {
        const r = annotation.Properties.RECT_COLOR_R
        const b = annotation.Properties.RECT_COLOR_B
        const g = annotation.Properties.RECT_COLOR_G
        let a = annotation.Properties.RECT_COLOR_A
        if (a > 1) {
          a = a / 256
        }

        let calcFill
        if (annotation.Name === 'Highlight') {
          calcFill = `rgba(${r}, ${g}, ${b}, ${a}`
        } else if (annotation.Properties.subType === 'signature' ||
          annotation.Properties.subType === 'initial' ||
          annotation.Properties.subType === 'timestamp' ||
          annotation.Properties.subType === 'text') {
          calcFill = `rgba(${r}, ${g}, ${b}, .2)`
        } else {
          calcFill = b === '0' ? 'black' : 'white'
        }
        const rectangle = new fabric.Rect({
          width: annotation.Width,
          height: annotation.Height,
          left: annotation.PosX,
          top: annotation.PosY,
          fill: calcFill,
        })

        rectangle.setControlsVisibility({ mtr: false })
        if (annotation.Properties.subType === 'signature' ||
          annotation.Properties.subType === 'initial' ||
          annotation.Properties.subType === 'timestamp' ||
          annotation.Properties.subType === 'text' ) {
          rectangle.toObject = this.extendFabricObject(rectangle, annotation.UniqueID, annotation.Name.toLowerCase(), annotation.Properties.disableSave, annotation.Properties.subType)
        }
        else if (annotation.Name === 'Highlight') {
          rectangle.toObject = this.extendFabricObject(rectangle, annotation.UniqueID, annotation.Name.toLowerCase())
        } else {
          rectangle.toObject = this.extendFabricObject(rectangle, annotation.UniqueID)
        }

        this.setAnnotationInteraction(rectangle, annotation)
        canvas.add(rectangle)
      },
      async renderSignature(annotation, canvas, sizingFunction) {
        const self = this
        if (this.largeDefaultLoadSize) {
          const sig = document.createElement('img')
          sig.src = `${this.base64DataPrefix}${annotation.Properties.PICTURE_BASE64}`
          sig.onload = function () {
            const newImg = new fabric.Image(sig, {
              top: annotation.PosY,
              left: annotation.PosX,
              lockRotation: true,
              strokeDashArray: [5, 5],
            });
            newImg.setControlsVisibility({ mtr: false })
            newImg.scaleToHeight(annotation.Height);
            newImg.scaleToWidth(annotation.Width);

            if (annotation.Properties.subType === 'signature' ||
              annotation.Properties.subType === 'initial' ||
              annotation.Properties.subType === 'timestamp' ||
              annotation.Properties.subType === 'text' ) {
              newImg.toObject = self.extendFabricObject(newImg, annotation.UniqueID, annotation.Name.toLowerCase(),
                annotation.Properties.disableSave, annotation.Properties.subType, annotation.Properties.includeTime, 
                annotation.Properties.bottomAlign, annotation.Properties.isRequired)
            } else {
              newImg.toObject = self.extendFabricObject(newImg, annotation.UniqueID, annotation.Name.toLowerCase())
            }

            sizingFunction(newImg, annotation)
            canvas.add(newImg);
          };
        }
      },
      setAnnotationInteraction(fabricRect, serverAnnotation) {
        if (!fabricRect || !serverAnnotation) {
          return
        }

        let shouldLock = serverAnnotation.Locked
        if (!shouldLock && serverAnnotation.Properties.disableSave) {
          shouldLock = serverAnnotation.Properties.disableSave === 'True'
        }
        if (!this.canAddAnnotation) {
          shouldLock = true
        }

        if (!shouldLock) {
          shouldLock = this.pendingSignatureDocumentZones.some(e => e.annotationID === serverAnnotation.UniqueID && String(e.signatureUserID) === String(this.UserID))
        }

        fabricRect.lockMovementX = shouldLock
        fabricRect.lockMovementY = shouldLock
        fabricRect.lockScalingY = shouldLock
        fabricRect.lockScalingX = shouldLock
        fabricRect.lockScaling = shouldLock
      },
      setBackgroundImage() {
        const { canvas } = this.provider
        let count = 0
        const limit = 10
        if (canvas) {
          canvas.setBackgroundImage(this.image.ImageSource, canvas.renderAll.bind(canvas), {
            width: canvas.width,
            height: canvas.height,
          })
          this.$emit('backgroundImageLoaded')
        } else if (count < limit) {
          count += 1
          setTimeout(() => {
            this.setBackgroundImage()
          }, 1000)
        }
      },
      setResizeControls(activeObject, subType) {
        if (subType === 'stamp' || subType === 'signature' || subType === 'initial') {
            // Remove the "middle" size controls, so the image aspect ratio will be retained when resizing
            activeObject.setControlsVisibility({
              tl: true, // top left
              tr: true, // top right
              br: true, // bottom right
              bl: true, // bottom left
              ml: false, // middle left
              mt: false, // middle top
              mr: false, // middle right
              mb: false, // middle bottom
              mtr: false, // middle top rotator
            })
          }
      },
      setTextEdit(newVal) {
        this.editingText = newVal
      },
      setZoomValue() {
        const { canvas } = this.provider
        if (canvas) {
          canvas.setHeight((canvas.getHeight() / canvas.getZoom()) * this.zoomValue); // Divide by the previous zoom to get the original height
          canvas.setWidth((canvas.getWidth() / canvas.getZoom()) * this.zoomValue); // Divide by the previous zoom to get the original width
          canvas.setZoom(this.zoomValue); // Now set the new zoom value
          canvas.renderAll();
        }
      },
      async signatureCreateMouseDown(options) {
        if (this.debug) { console.log('signature:create mouse:down') }
        if (this.annotationConfig.type === '') {
          return false;
        }

        this.actionStarted = true
        const coords = this.getCursorPosition(options)
        this.rect.start = { x: coords[0], y: coords[1] }

        return true
      },
      signatureCreateMouseMove(options) {
        if (this.debug) { console.log('signature:create mouse:move') }
        if (!this.actionStarted || this.annotationConfig.type === '') {
          return false;
        }

        const coords = this.getCursorPosition(options)
        this.rect.end = { x: coords[0], y: coords[1] }

        return true
      },
      async signatureCreateMouseUp() {
        if (this.debug) { console.log('signature:create mouse:up') }
        if (!this.actionStarted || this.annotationConfig.type === '') {
          return false;
        }

        const { canvas } = this.provider

        if (this.annotationConfig.applyOnZone && this.selectedAnnotation.id !== this.activeObject().toObject().id) {
          canvas.defaultCursor = 'default'
          await this.$store.dispatch('document/setAnnotationConfig', this.nullAnnotationConfig)
          return false
        }

        if (this.actionStarted) {
          this.actionStarted = false;
        }
        let localSig = this.activeSignature
        if (this.annotationConfig.subType === 'signature' || this.annotationConfig.subType === 'initial') {
          if (await this.$store.dispatch('digitalSignature/shouldApplySignatureTimestamp')) {
            localSig = await this.$store.dispatch('digitalSignature/burnTimestampToImage', { Value: localSig.substr(22) })
            localSig = `${this.base64DataPrefix}${localSig}`
          }
        }

        const sig = document.createElement('img')
        sig.src = localSig
        if (this.annotationConfig.applyOnZone) {
          sig.onload = this.loadImage(
            sig,
            canvas,
            this.selectedAnnotation.top,
            this.selectedAnnotation.left,
            this.selectedAnnotation.width,
            this.selectedAnnotation.height,
            this.extendFabricObject,
            this.setResizeControls,
            this.annotationConfig.subType,
            this.selectedAnnotation.id,
            this.annotationConfig.applyOnZone,
          )
          this.$store.dispatch('document/removePendingSignatureAnnotationID', this.selectedAnnotation.id)
          canvas.remove(this.activeObject());
        } else {
          const rect = this.getRect()
          sig.onload = this.loadImage(
            sig,
            canvas,
            rect.top,
            rect.left,
            rect.width,
            rect.height,
            this.extendFabricObject,
            this.setResizeControls,
            this.annotationConfig.subType,
            uuid.v1().toString(),
          )
        }
        canvas.defaultCursor = 'default'
        await this.$store.dispatch('document/setAnnotationConfig', this.nullAnnotationConfig)
        this.$store.dispatch('document/logSignatureAdded', { Value: this.localImage.DocumentId })
        return true
      },
      async stampCreateMouseDown(options) {
        if (this.debug) { console.log('stamp:create mouse:down') }
        if (this.annotationConfig.type === '') {
          return false;
        }

        this.actionStarted = true
        const coords = this.getCursorPosition(options)
        this.rect.start = { x: coords[0], y: coords[1] }

        return true
      },
      stampCreateMouseMove(options) {
        if (this.debug) { console.log('stamp:create mouse:move') }
        if (!this.actionStarted || this.annotationConfig.type === '') {
          return false;
        }

        const coords = this.getCursorPosition(options)
        this.rect.end = { x: coords[0], y: coords[1] }

        return true
      },
      async stampCreateMouseUp() {
        if (this.debug) { console.log('stamp:create mouse:up') }
        if (!this.actionStarted || this.annotationConfig.type === '') {
          return false;
        }

        const { canvas } = this.provider

        if (this.actionStarted) {
          this.actionStarted = false;
        }

        const rect = this.getRect()
        const stamp = document.createElement('img')
        stamp.src = this.activeStamp
        stamp.onload = this.loadImage(
          stamp,
          canvas,
          rect.top,
          rect.left,
          rect.width,
          rect.height,
          this.extendFabricObject,
          this.setResizeControls,
          this.annotationConfig.subType,
          uuid.v1().toString(),
        )
        canvas.defaultCursor = 'default'
        await this.$store.dispatch('document/setAnnotationConfig', this.nullAnnotationConfig)

        return true
      },
      async textCreateMouseDown(options) {
        if (this.debug) { console.log('stamp:create mouse:down') }
        if (this.annotationConfig.type === '') {
          return false;
        }

        this.actionStarted = true
        const coords = this.getCursorPosition(options)
        this.rect.start = { x: coords[0], y: coords[1] }

        return true
      },
      textCreateMouseMove(options) {
        if (this.debug) { console.log('stamp:create mouse:move') }
        if (!this.actionStarted || this.annotationConfig.type === '') {
          return false;
        }

        const coords = this.getCursorPosition(options)
        this.rect.end = { x: coords[0], y: coords[1] }

        return true
      },
      async textCreateMouseUp() {
        const me = this
        if (me.debug) { console.log('stamp:create mouse:up') }
        if (!me.actionStarted || me.annotationConfig.type === '') {
          return false;
        }

        const { canvas } = me.provider

        if (this.annotationConfig.applyOnZone && this.selectedAnnotation.id !== this.activeObject().toObject().id) {
          canvas.defaultCursor = 'default'
          await this.$store.dispatch('document/setAnnotationConfig', this.nullAnnotationConfig)
          return false
        }

        if (me.actionStarted) {
          me.actionStarted = false;
        }

        let t1 = null
        let newUniqueID = uuid.v1().toString()

        if (this.annotationConfig.applyOnZone) {
           t1 = new fabric.Textbox('', {
              width: me.selectedAnnotation.width,
              height: me.selectedAnnotation.height,
              top: me.selectedAnnotation.top,
              left: me.selectedAnnotation.left,
              fontSize: me.selectedAnnotation.height * (2/3),
              fontFamily: 'Arial',
              textAlign: 'left',
              underline: false,
              fill: 'rgb(0,0,0)',
              fixedWidth: me.selectedAnnotation.width,
          })

          t1.lockMovementX = true
          t1.lockMovementY = true
          t1.lockScalingY = true
          t1.lockScalingX = true
          newUniqueID = this.selectedAnnotation.id

          this.$store.dispatch('document/removePendingSignatureAnnotationID', this.selectedAnnotation.id)
          canvas.remove(this.activeObject());
        }
        else {
          const rect = this.getRect()
          t1 = new fabric.Textbox('', {
            ...rect,
            fontSize: rect.height,
            textAlign: 'left',
            fontFamily: 'Arial',
            underline: false,
            fill: 'rgb(0,0,0)',
            fixedWidth: rect.width,
          })
        }

        t1.setControlsVisibility({ mtr: false })

        t1.setControlsVisibility({
          mt: false, // middle top disable
          mb: false, // middle bottom
          ml: false, // middle left
          mr: false, // I think you get it
        });

        t1.toObject = this.extendFabricObject(t1, newUniqueID)

        canvas.on('text:changed', function (opt) {
          const t2 = opt.target;
          if (t2.width > t2.fixedWidth) {
            t2.fontSize *= t2.fixedWidth / (t2.width + 1);
            t2.width = t2.fixedWidth;
          }
        })
        t1.on('editing:entered', function () {
          me.setTextEdit(true)
        })
        t1.on('editing:exited', function () {
          me.setTextEdit(false)
        })

        canvas.add(t1)
        canvas.defaultCursor = 'default'
        await me.$store.dispatch('document/setAnnotationConfig', this.nullAnnotationConfig)

        t1.enterEditing()
        canvas.setActiveObject(t1)

        return true
      },
    },
}
</script>
