<template>
  <div>
    <span :class="`chip ${confidenceClass}`" :title="confidenceTitle">{{ confidenceText }}</span>
  </div>
</template>

<script>
import { getMeanConfidenceColor } from '@/enums/objectFieldMeanConfidenceLevels.js'

export default {
  name: 'VasionConfidenceChip',
  props: {
    confidence: {
      type: Number,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      confidenceClass: 'bg-invalid',
      confidenceText: '',
      confidenceTitle: '',
    };
  },
  computed: {
  },
  async created() {
    if (this.confidence) this.setConfidenceClassAndTexts()
  },
  methods: {
    setConfidenceClassAndTexts() {
      const color = getMeanConfidenceColor(this.confidence)
      var chipConfidenceLevel = 'none'
  
      if (color === 'LawnGreen') {
        this.confidenceClass = 'bg-high'
        this.confidenceText = 'High Confidence'
        chipConfidenceLevel = 'high'
      } else if (color === 'Yellow') {
        this.confidenceClass = 'bg-medium'
        this.confidenceText = 'Medium Confidence'
        chipConfidenceLevel = 'medium'
      } else if (color === 'Red') {
        this.confidenceClass = 'bg-low'
        this.confidenceText = 'Low Confidence'
        chipConfidenceLevel = 'low'
      } else {
        this.confidenceClass = 'bg-invalid'
        this.confidenceText = ''
      }
      this.confidenceTitle = `${this.confidence.toFixed(2)}% Confidence`
      this.$emit('confidenceLevel', chipConfidenceLevel)
    },
  },
};
</script>

<style lang="scss" scoped>
.chip {
  border-radius: 12px;
  padding: 6px 8px;
  font-family: Archivo;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #000000;
  display: inline-block;
}
.bg-high {
  background-color: #4DCC82;
}
.bg-medium {
  background-color: #FFC44D;
}
.bg-low {
  background-color: #CB0C0C;
  color: #FFFFFF!important;
}
.bg-invalid {
  background-color: rgb(108,117,125);
  color: #FFFFFF!important;
}
</style>
