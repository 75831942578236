<template>
  <VasionGeneralModal
    id="static-fields-modal"
    :rejectButtonText="'Cancel'"
    :confirmButtonText="'OK'"
    :modalTitle="'Static Fields'"
    :modalType="'slot'"
    :sync="sync"
    @noButtonClick="cancelClick()"
    @confirmButtonClick="okClick()"
  >
    <div id="static-fields-div" class="vasion-html-table-default">
      <table id="static-fields-table">
        <thead>
          <tr>
            <th class="field-name">
              <label class="vasion-html-table-header">Field</label>
            </th>
            <th class="field-type-column">
              <label class="vasion-html-table-header">Value</label>
            </th>
          </tr>
        </thead>
        <tbody class="scrollTBody">
          <tr v-for="(field, index) in staticFieldsDisplay" :key="index" :class="{'lightestGreyBackground': index % 2 === 0 }">
            <td class="field-name padding">
              <label class="vasion-html-table-field">{{ field.displayName }}</label>
            </td>
            <td class="field-type-column vasion-html-table-field padding">
              <VasionInput
                v-model="field.value"
                inputType="top-white"
                placeholder="Enter Field Value..."
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </VasionGeneralModal>
</template>

<script>

export default {
  name: 'VasionStaticFields',
  props: {
    initialValues: {
      type: Array,
      default: () => [],
      required: false,
    },
    objectID: {
      type: Number,
      required: true,
    },
    sync: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: function () {
    return {
      localShowModal: false,
      staticFieldsDisplay: [],
    }
  },
  computed: {
  },
  watch: {
    sync: async function (value) {
      if (!value) {
        return
      }

      if (this.$store.state.common.indexFieldsObjectID !== this.objectID) {
        // The currently loaded list of fields in the store is different that what we need.  So reload!
        await this.$store.dispatch('common/getIndexFieldsForForm', this.objectID)
      }

      if (!this.$store.state.common.indexFields) {
        this.staticFieldsDisplay = []
        return
      }

      this.staticFieldsDisplay = this.$store.state.common.indexFields.map(storeField => {
        const displayField = {
          displayName: storeField.name,
          fieldName: storeField.value,
          value: '',
        }

        const foundDefaultField = this.initialValues.find(f => f.fieldName === displayField.fieldName)
        if (foundDefaultField) {
          displayField.value = foundDefaultField.value
        }

        return displayField
      })

      this.staticFieldsDisplay = this.staticFieldsDisplay.filter(field => {
        return field.fieldName !== ''
      })
    },
  },
  methods: {
    cancelClick() {
      this.$emit('closeModal', null)
    },
    okClick() {
      this.$emit('closeModal', this.staticFieldsDisplay)
    },
  },
}

</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .padding {
    padding: 5px 20px 5px 0px;
  }

  #static-fields-table {
    display: block;
    height: 55vh;
  }

  #static-fields-table td {
    width: 440px;
  }
</style>
