import {
  copyFolderStructure,
  deleteCustomFolderView,
  deleteThirdPartyStorageConfiguration,
  getConfiguredDriveTypes,
  getUniqueFieldNames,
  getAvailableThirdPartyStorageConfigurationTypes,
  getThirdPartyStorageConfigurations,
  getVasionDriveFolderId,
  renameSubfolder,
  setThirdPartyStorageConfiguration,
  testAmazonStorageConnection,
  uploadFileToVasion,
  uploadNewVersion,
 } from '@/store/apiModules/storage.api'

 function initialState() {
  return {
    storageSideNav: '',
    thirdPartyStorageConfigurationTypes: [],
    thirdPartyStorageConfigurations: [],
    uniqueFieldNames: [],
    uploadedFile: {},
    vasionDriveFolderId: '0',
    cloudStorageProviders: {
      box: 'Box',
      google: 'GoogleDrive',
      oneDrive: 'OneDrive',
      workDocs: 'WorkDocs',
      s3: 'S3',
      wasabi: 'Wasabi',
      sharePoint: 'SharePoint',
    },
  }
}

const storage = {
  namespaced: true,
  state: initialState,
  mutations: {
    changeStorageSideNav(state, value) { state.storageSideNav = value },
    resetStoreData(state) {
      const localState = initialState()
      Object.keys(state).forEach((prop) => {
        state[prop] = localState[prop]
      })
    },
    setThirdPartyStorageConfigurationTypes(state, value) { state.thirdPartyStorageConfigurationTypes = value },
    setThirdPartyStorageConfigurations(state, value) { state.thirdPartyStorageConfigurations = value },
    setuniqueFieldNames(state, value) { state.uniqueFieldNames = value },
    setUploadedFile(state, value) { state.uploadedFile = value },
    setVasionDriveFolderId(state, value) { state.vasionDriveFolderId = value },
  },
  actions: {
    changeStorageSideNav({ commit }, value) { commit('changeStorageSideNav', value) },
    async copyFolderStructure({ rootState }, payload) {
      const result = await copyFolderStructure(rootState.common.apiToken, payload)
      return result
    },
    async deleteCustomFolderView({ rootState }, customFolderViewID) {
      const data = await deleteCustomFolderView(rootState.common.apiToken, customFolderViewID)
      return data
    },
    async deleteThirdPartyStorageConfiguration({ rootState }, configID) {
      const data = await deleteThirdPartyStorageConfiguration(rootState.common.apiToken, configID)
      return data
    },
    async getConfiguredDriveTypes({ rootState }, payload) {
      const driveTypes = await getConfiguredDriveTypes(rootState.common.apiToken, payload)
      return driveTypes
    },
    async getAvailableThirdPartyStorageConfigurationTypes({ rootState, commit }) {
      const response = await getAvailableThirdPartyStorageConfigurationTypes(rootState.common.apiToken)
      commit('setThirdPartyStorageConfigurationTypes', response.Values)
      return response.Values
    },
    async getThirdPartyStorageConfigurations({ rootState, commit }) {
      const response = await getThirdPartyStorageConfigurations(rootState.common.apiToken)
      commit('setThirdPartyStorageConfigurations', response.Values)
      return response.Values
    },
    async getVasionDriveFolderId({ rootState, commit }) {
      const response = await getVasionDriveFolderId(rootState.common.apiToken)

      if (!response || !response.data || response.data.sGenRes === '') {
        console.warn('Unable to get Vasion drive folder id')
      }

      commit('setVasionDriveFolderId', response.data.sGenRes)
    },
    async loadUniqueFieldNames({ rootState, commit }) {
      const fieldNames = []
      const data = await getUniqueFieldNames(rootState.common.apiToken)
      if (data && data.Values) {
        Object.keys(data.Values).map((key) => {
          if (data.Values && data.Values.length > 0 && data.Values[key]) {
            fieldNames.push({
              name: data.Values[key],
              value: Number(key),
            });
          }
          return true;
        });
      }
      commit('setuniqueFieldNames', fieldNames)
    },
    async renameSubfolder({ rootState }, payload) {
      const result = await renameSubfolder(rootState.common.apiToken, payload)
      return result
    },
    async saveUploadedFilePayloadInMemory({ commit }, payload) {
      commit('setUploadedFile', payload)
    },
    async setThirdPartyStorageConfiguration({ rootState, dispatch }, payload) {
      const data = await setThirdPartyStorageConfiguration(rootState.common.apiToken, payload)
      dispatch('getThirdPartyStorageConfigurations')
      return data
    },
    async testAmazonStorageConnection({ rootState }, payload) {
      const res = await testAmazonStorageConnection(rootState.common.apiToken, payload)
      return res
    },
    async uploadFileToVasion({ rootState }, payload) {
      // payload = {file, folderId}
      const formData = new FormData()
      formData.append('file', payload.file, payload.file.name)
      formData.append("folderId", payload.folderId)
      formData.append("name", payload.file.name)

      const response = await uploadFileToVasion(rootState.common.apiToken, formData)
      if (!response || !response.data || response.data.Value === '') {
        console.warn('Unable to upload file to Vasion')
        return response
      }

      return response.data.Value
    },
    async uploadNewVersion({ rootState }, payload) {
      const response = await uploadNewVersion(rootState.common.apiToken, payload)
      if (!response || !response.data || response.data.Value !== 'True') {
        console.warn('Unable to upload file to Vasion')
        return false
      }

      return true
    },
  },
}

export default storage;
