<template>
  <div>
    <VasionGeneralModal
      id="vasion-setup-instructions"
      :modalType="'slot'"
      :hideButtons="true"
      :sync="dialog"
    >
      <div v-if="needsPassword !== ''" class="md-layout">
        <div class="top-password-div">
          <span>{{needsPassword}}</span>
          <VasionInput
            v-model="password"
            inputType="top-white"
            class="input-style"
            specialType="password"
            :temporaryReadonly="true"
            :readonly="true"
            :width="'400'"
            :isInErrorState="passwordError"
          />
        </div>
        <div class="bottom-password-div">
          <VasionButton id="btnNewIndexSearch" :classProp="'primary'" @vasionButtonClicked="confirm()">
            {{ confirmText }}
          </VasionButton>
          <VasionButton id="btnNewFTSearch" :classProp="'secondary'" @vasionButtonClicked="cancel()">
            {{ cancelText }}
          </VasionButton>
        </div>
      </div>
      <div v-else>
        <VasionConfirmationDialog
          :message="message"
          @noButtonClick="cancel()"
          @yesButtonClick="confirm()"
        />
      </div>
    </VasionGeneralModal>
  </div>
</template>

<script>
/*
Example Usage:

in this file App.vue, import and register like this:
import { ConfirmDialog } from '@printerlogic/shared-components';
<confirm-dialog ref="confirm" confirmText="Yes" cancelText="No"/>

also add it to root in the mounted() function like this:
  mounted() {
    this.$root.confirm = this.$refs.confirm;
  },

And then in any component you can invoke it like so:
  let res = await this.$root.confirm.open({
    title: 'example title',
    message: 'example message',
  });
  if (res) {
    confirm was positive
  } else {
    confirm was negative
  }
*/
export default {
  name: 'VasionAsynchronousConfirmDialog',
  props: {
    // text for confirm button
    confirmText: {
      type: String,
      required: false,
      default: 'Confirm',
    },
    // text for cancel button
    // if not provided, cancel button will not be shown
    cancelText: {
      type: String,
      required: false,
      default: 'Cancel',
    },
    needsPassword: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: function() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        showCancelButton: true,
        color: 'primary',
        width: 400,
        zIndex: 200,
      },
      password: '',
      passwordError: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.dialog = value;
        if (value == false) {
          this.cancel();
        }
      },
    },
    showCancel: function() {
      return !!this.cancelText && this.options.showCancelButton;
    },
  },
  methods: {
    open(data) {
      this.dialog = true;
      this.title = data.title;
      this.message = data.message;
      this.options = { ...this.options, ...data.options };
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirm() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.dialog = false;
      this.resolve(false);
    },
    ref_setErrorClass() {
      this.passwordError = true
    },
  },
};
</script>

<style lang="scss" scoped>
  .md-layout{
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
  }
  .top-password-div{
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .bottom-password-div {
    display: flex;
    flex-direction: row-reverse;
  }
</style>
