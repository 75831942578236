<template>
  <div class="annotations-canvas-wrapper">
    <canvas
      :id="annotationsID"
      :ref="canvasReference"
      :width="canvasWidth"
      :height="canvasHeight"
      :style="{ width: canvasWidth+'px', height: canvasHeight+'px'}"
    />
    <slot />
  </div>
</template>

<script>
import { fabric } from 'fabric'

export default {
  // Allows any child component to `inject: ['provider']` and have access to it.
  provide() {
    return {
      provider: this.provider,
      reference: this.canvasReference,
    }
  },
  props: {
    annotationsID: {
      type: Number,
      required: false,
      default: 0,
    },
    annotationsStyle: {
      type: String,
      required: false,
      default: '',
    },
    canDelete: {
      type: Boolean,
      required: false,
      default: true,
    },
    canvasHeight: {
      type: Number,
      required: false,
      default: 0,
    },
    canvasReference: {
      type: String,
      required: false,
      default: null,
    },
    canvasWidth: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      // By creating the provider in the data property, it becomes reactive,
      // so child components will update when `context` changes.
      provider: {
        // This is the CanvasRenderingContext that children will draw to.
        canvas: null,
        context: null,
      },
      reference: null,
    }
  },
  mounted() {
    // canDelete will ensure that the canvas behaves normally for anything other than signature documents in the process of being signed
    if (!this.canDelete) {
      let defaultOnTouchStartHandler = fabric.Canvas.prototype._onTouchStart;
      fabric.util.object.extend(fabric.Canvas.prototype, { 
        _onTouchStart: function(e) { 
          let target = this.findTarget(e); 
          // if allowTouchScrolling is enabled, no object was at the
          // the touch position and we're not in drawing mode, then 
          // let the event skip the fabricjs canvas and do default
          // behavior
          if (this.allowTouchScrolling && !target && !this.isDrawingMode) { 
            // returning here should allow the event to propagate and be handled
            // normally by the browser
            return; 
          } 

          // otherwise call the default behavior
          defaultOnTouchStartHandler.call(this, e); 
        } 
      })
    }

    // We can't access the rendering context until the canvas is mounted to the DOM.
    // Once we have it, provide it to all child components.
    this.provider.canvas = new fabric.Canvas(
      this.$refs[this.canvasReference], {
      preserveObjectStacking: true,
      allowTouchScrolling: true,
    })
    this.provider.context = this.provider.canvas.getContext('2d')

    // Resize the canvas to fit its parent's width.
    // Normally you'd use a more flexible resize system.
    this.provider.canvas.width = this.canvasWidth
    this.provider.canvas.height = this.canvasHeight
  },
  methods: {
  },
}
</script>

<style>
  div.annotations-canvas-wrapper {
    width: 100%;
    height: 100%;
  }
  div.annotations-canvas-wrapper canvas {
    width: 100%;
    height: 100%;
  }
  .canvas-container{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 7px 0px;
    margin-right: 40px;
  }
</style>
