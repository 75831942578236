import {
  deleteFTSearchDataCache,
  deleteLinkedSearch,
  deleteSearch,
  getAdminSearchList,
  getAllLinkedSearch,
  getFolderIndexSchedule,
  getFTSearchDocDetails,
  getFullTextSearchDetails,
  getGlobalSearchEnabled,
  getGlobalSearchCustomFolderId,
  getGlobalSearchFolderIndexSchedule,
  getGlobalSearchID,
  getIndexSearchDetails,
  getIndexSearchFields,
  getLinkedSearch,
  getSearches,
  getSearchSecurity,
  runFTSearchWithDataCacheDetails,
  runGetFolderSearchConfigurations,
  runIndexSearch,
  runSaveCustomFolderView,
  runSaveFullTextSearch,
  runSaveIndexSearch,
  saveLinkedSearch,
  saveSearchSecurity,
  setFolderIndexSchedule,
  setGlobalSearchEnabled,
  updateFolderSearchIndex,
  setGlobalSearchCustomFolderId,
  setGlobalFolderIndexSchedule,
  updateFullTextSearchIndex,
 } from '@/store/apiModules/search.api'

import { getIndexFields } from '@/store/apiModules/common.api'
import { formatDateTime } from '@/store/helperModules/common.module'
import { SearchTypes } from '@/enums/searchTypes'

function initialState() {
  return {
    activeFolderView: {},
    adminSearchList: [],
    adminSearchListTypeID: 0,
    combinedFieldSet: [],
    editSearch: {},
    folderSearchConfigurations: {},
    fullFolderSearchConfigurations: {},
    indexSearchFields: {},
    isGlobalSearchEnabled: null,
    refineSearch: false,
    searchCriteria: {},
    searches: {},
    searchGUID: '',
    searchResults: {},
    searchSideNav: '',
    selectedSearch: {},
  }
}

const search = {
  namespaced: true,
  state: initialState,
  mutations: {
    changeSearchSideNav(state, value) { state.searchSideNav = value },
    resetCombinedList(state, value) { state.combinedFieldSet = value },
    resetStoreData(state) {
      const localState = initialState()
      Object.keys(state).forEach((prop) => {
        state[prop] = localState[prop]
      })
    },
    setActiveSearch(state, value) { state.activeSearch = value },
    setAdminSearchList(state, value) { state.adminSearchList = value },
    setAdminSearchListTypeID(state, value) { state.adminSearchListTypeID = value },
    setCombinedList(state, value) { state.combinedFieldSet = value },
    setEditSearch(state, value) { state.editSearch = value },
    setFolderSearchConfigurations(state, value) { state.folderSearchConfigurations = value },
    setFullFolderSearchConfigurations(state, value) { state.fullFolderSearchConfigurations = value },
    setGlobalSearchEnabled(state, value) { state.isGlobalSearchEnabled = value },
    setIndexSearchFields(state, value) { state.indexSearchFields = value },
    setRefineSearch(state, value) { state.refineSearch = value },
    setSearchCriteria(state, value) { state.searchCriteria = value },
    setSearches(state, value) { state.searches = value },
    setSearchGUID(state, value) { state.searchGUID = value },
    setSearchResults(state, value) { state.searchResults = value },
    setSelectedSearch(state, value) { state.selectedSearch = value },
    updateActiveFolderView(state, value) { state.activeFolderView = value },
  },
  actions: {
    changeSearchSideNav({ commit }, value) { commit('changeSearchSideNav', value) },
    async deleteFTSearchDataCache({ rootState }, searchGUID) {
      const response = await deleteFTSearchDataCache(rootState.common.apiToken, searchGUID)
      return response
    },
    async deleteLinkedSearch({ rootState }, linkedSearchID) {
      const response = await deleteLinkedSearch(rootState.common.apiToken, linkedSearchID)
      return response
    },
    async deleteSearch({ rootState, dispatch }, searchID) {
      const data = await deleteSearch(rootState.common.apiToken, searchID)
      if (data && data.Value === 'True') {
        dispatch('loadAdminSearchList')
      }
    },
    // eslint-disable-next-line no-unused-vars
    async getCombinedFieldSet({ rootState, dispatch, commit }, payload) {
      const { formIds, forms } = payload
      const combinedList = []

      await dispatch('resetCombinedFieldList')

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < formIds.length; i++) {
        const formId = formIds[i]
        const formName = forms.find(f => f.value === formId).name
        const fieldList = []

        try {
          // eslint-disable-next-line no-await-in-loop
          const response = await getIndexFields(rootState.common.apiToken, formId)

          if (response && response.Fields && response.Fields.Values) {
            Object.keys(response.Fields.Values).map((key) => {
              let field
              if (response.Fields.Values[key]) {
                field = response.Fields.Values[key]

                const dropdownValues = []
                if (field.DropDownValues && field.DropDownValues.Values && field.DropDownValues.Values.length > 0) {
                  Object.keys(field.DropDownValues.Values).map((innerkey) => {
                    dropdownValues.push({
                      name: field.DropDownValues.Values[innerkey],
                      value: Number(innerkey),
                    })
                    return true
                  })
                }

                fieldList.push({
                  type: field.Control,
                  name: field.DisplayName,
                  value: field.FieldID,
                  uniqueName: field.FieldName,
                  formName: formName,
                })
              }
              return true
            })
          }
        } catch (error) {
          console.warn(error)
        }

        if (fieldList && fieldList.length > 0) {
          combinedList.push(fieldList)
        }
      } // end for loop

      commit('setCombinedList', combinedList)
    },
    async getFolderSearchConfigurations({ rootState, commit }) {
      const data = await runGetFolderSearchConfigurations(rootState.common.apiToken)
      let configurations = {}

      if (data && data.folderViews && data.folderViews.length > 0) {
        configurations = data.folderViews.map((config) => {
          return {
            id: config.id,
            name: config.folderName,
          }
        })
      }

      commit('setFolderSearchConfigurations', configurations)
    },
    async getFTSearchDocDetails({ rootState }, payload) { return getFTSearchDocDetails(rootState.common.apiToken, payload) },
    async getFullFolderSearchConfigurations({ rootState, commit }) {
      const data = await runGetFolderSearchConfigurations(rootState.common.apiToken)
      let configurations = {}

      if (data && data.folderViews && data.folderViews.length > 0) {
        configurations = data.folderViews
      }

      commit('setFullFolderSearchConfigurations', configurations)
    },
    async getGlobalSearchEnabled({ rootState, commit }) {
      const result = await getGlobalSearchEnabled(rootState.common.apiToken)
      commit('setGlobalSearchEnabled', (/true/i).test(result?.Value))
    },
    async getGlobalSearchID({ rootState }) { return getGlobalSearchID(rootState.common.apiToken) },
    async getSearchSecurity({ rootState }, secureId) {
      const result = await getSearchSecurity(rootState.common.apiToken, secureId)
      return result
    },
    async loadAdminSearchList({ rootState, commit }) {
      let searchList = await getAdminSearchList(rootState.common.apiToken)
      if (!searchList) {
        searchList = []
      }
      commit('setAdminSearchList', searchList)
    },
    async loadFolderIndexSchedule({ rootState }, folderId) {
      const response = await getFolderIndexSchedule(rootState.common.apiToken, folderId)
      if (!response) {
        return false
      }
      return response
    },
    async loadFullTextSearchDetails({ rootState, commit }, searchID) {
      commit('setEditSearch', {})
      if (searchID) {
        const searchDetails = await getFullTextSearchDetails(rootState.common.apiToken, searchID)
        commit('setEditSearch', searchDetails)
      }
    },
    async loadGlobalSearchDetails({ rootState }, folderId) {
      let [globalSearchEnabled, globalSearchCustomFolderID, globalSearchFolderIndexSchedule] = await Promise.all([
        getGlobalSearchEnabled(rootState.common.apiToken),
        getGlobalSearchCustomFolderId(rootState.common.apiToken),
        getGlobalSearchFolderIndexSchedule(rootState.common.apiToken, folderId),
      ])
      const response = {
        isGlobalSearchEnabled: globalSearchEnabled.Value,
        globalSearchCustomFolderId: globalSearchCustomFolderID.Value,
        globalSearchFolderIndexSchedule: globalSearchFolderIndexSchedule,
      }
      return response
    },
    async loadIndexSearchDetails({ rootState, commit }, searchID) {
      commit('setEditSearch', {})
      const searchDetails = await getIndexSearchDetails(rootState.common.apiToken, searchID)
      commit('setEditSearch', searchDetails)
    },
    async loadIndexSearchFields({ rootState, commit }, searchID) {
      commit('setEditSearch', {})
      const searchDetails = await getIndexSearchFields(rootState.common.apiToken, searchID)
      commit('setIndexSearchFields', searchDetails)
    },
    async loadLinkedSearch({ rootState }, searchID) { return getLinkedSearch(rootState.common.apiToken, searchID) },
    async loadLinkedSearchList({ rootState }) {
      let searchList = await getAllLinkedSearch(rootState.common.apiToken)
      if (!searchList) {
        searchList = []
      }
      return searchList
    },
    async loadSearches({ rootState, commit }) {
      commit('setSearches', {})
      const searchDetails = await getSearches(rootState.common.apiToken)
      if (searchDetails.Items) commit('setSearches', searchDetails)
      else commit('setSearches', {Items: {Values: []}})
      return searchDetails
    },
    async resetCombinedFieldList({ commit }) { commit('resetCombinedList', []) },
    async runCurrentSearch({ rootState }) {
      if (!rootState.search.selectedSearch || !rootState.search.selectedSearch.SearchId || !rootState.search.searchCriteria) {
        return null
      }

      let data
      if (rootState.search.selectedSearch.Type === SearchTypes.Index) {
        data = await runIndexSearch(rootState.common.apiToken, rootState.search.searchCriteria)
      } else if (rootState.search.selectedSearch.Type === SearchTypes.FullText) {
        data = await runFTSearchWithDataCacheDetails(rootState.common.apiToken, rootState.search.searchCriteria)
      }

      const hasDateTimeFields = (data?.searchResult || data)?.Columns?.Values?.findIndex(col => col.includes('Date/Time')) >= 0
      if (hasDateTimeFields) {
        let rows = data.Rows || data.searchResult?.Rows
        rows.Values.forEach(row => {
          row.Columns.Values.forEach(cell => {
            if(cell.Key.includes('Date/Time')){
              cell.Value = formatDateTime(cell.Value, 'datetime')
            }
          })
        })
      }

      return data
    },
    async saveCustomFolderView({ rootState }, payload) {
      const data = await runSaveCustomFolderView(rootState.common.apiToken, payload)

      if (data) {
       return true
      }

      return false
    },
    async saveFolderIndexSchedule({ rootState }, payload) {
      try {
        const response = await setFolderIndexSchedule(rootState.common.apiToken, payload)
        if (!response) {
          return false
        }
        return response
      } catch (error) {
        console.warn(error)
        return false
      }
    },
    async saveFullTextSearch({ rootState }, payload) {
      const data = await runSaveFullTextSearch(rootState.common.apiToken, payload)
      return data
    },
    async saveGlobalSearchDetails({ rootState }, payload) {
      try {
        const [globalSearchEnabled, globalSearchCustomFolderID, globalFolderIndexSchedule] = await Promise.all([
          setGlobalSearchEnabled(rootState.common.apiToken, payload.isGlobalSearchEnabled),
          setGlobalSearchCustomFolderId(rootState.common.apiToken, payload.globalSearchCustomFolderId),
          setGlobalFolderIndexSchedule(rootState.common.apiToken, payload.globalSearchFolderIndexSchedule),
        ])
        if (!globalSearchEnabled || !globalSearchCustomFolderID || !globalFolderIndexSchedule) {
          throw "Error Saving Global Search Details"
        } else {
          return true
        }
      } catch (error) {
        console.warn(error)
        return false
      }
    },
    async saveIndexSearch({ rootState }, payload) {
      const data = await runSaveIndexSearch(rootState.common.apiToken, payload)
      return data
    },
    async saveLinkedSearch({ rootState }, payload) { return saveLinkedSearch(rootState.common.apiToken, payload) },
    async saveSearchSecurity({ rootState }, payload) {
      const response = await saveSearchSecurity(rootState.common.apiToken, payload)
      return response
    },
    setActiveSearch({ commit }, value) { commit('setActiveSearch', value) },
    setAdminSearchListTypeID({ commit }, value) { commit('setAdminSearchListTypeID', value) },
    setGlobalSearchEnabled({ commit }, value) { commit('setGlobalSearchEnabled', value) },
    setRefineSearch({ commit }, value) { commit('setRefineSearch', value) },
    setSearchCriteria({ commit }, value) { commit('setSearchCriteria', value) },
    setSearchGUID({ commit }, value) { commit('setSearchGUID', value) },
    setSearchResults({ commit }, value) { commit('setSearchResults', value) },
    setSelectedSearch({ commit }, value) { commit('setSelectedSearch', value) },
    updateActiveFolderView({ commit }, value) { commit('updateActiveFolderView', value) },
    async updateFolderSearchIndex({ rootState }, folderID) {
      const result = await updateFolderSearchIndex(rootState.common.apiToken, folderID)
      return result
    },
    async updateFullTextSearchIndex({ rootState }, searchID) {
      const result = await updateFullTextSearchIndex(rootState.common.apiToken, searchID)
      return result
    },
  },
}

export default search;
