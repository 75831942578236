import { render, staticRenderFns } from "./TheParentFolder.vue?vue&type=template&id=37abc008&scoped=true&"
import script from "./TheParentFolder.vue?vue&type=script&lang=js&"
export * from "./TheParentFolder.vue?vue&type=script&lang=js&"
import style0 from "./TheParentFolder.vue?vue&type=style&index=0&id=37abc008&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37abc008",
  null
  
)

export default component.exports