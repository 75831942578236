<template>
  <div id="profile-menu" v-click-outside="loseFocus">
    <ul id="profile-ul">
      <li id="user-settings" @click.prevent="$emit('openUserSettings')">
        <VasionPersonIcon /><span>Account Settings</span>
      </li>
      <li id="profile-logout" @click.prevent="signOut">
        <VasionPowerSettingsNewIcon /><span>Sign Out</span>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'TheProfileMenu',
  components: {
  },
  data: function () {
    return {
    }
  },
  computed: {
  },
  methods: {
    loseFocus() {
      this.$emit('loseFocus')
    },
    routeToUserSettings() {
      this.$store.dispatch('mainViews/changeActiveSubApp', 'profile')
    },
    async signOut() {
      this.$router.push({ name: 'Logout', query: { isTokenExpired: false } })
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';

  #profile-menu {
    @include Text;
    background-color: $white;
    width: 274px;
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    transform-origin: left bottom;

    & > ul {
      list-style: none;
      padding: 8px 8px;
      margin: 0;

      & > li {
        padding: 8px 8px;
        cursor: pointer;
        height: 40px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0;

        & > span {
          display: inline-block;
          vertical-align: middle;
          margin-left: 16px;
        }
      }

      & li:hover {
        background-color: rgba(44, 55, 66, 0.12);
      }
    }
  }

  svg {
    fill: black;
  }
</style>
