/* eslint-disable linebreak-style */
/* eslint-disable default-case */
/* eslint-disable consistent-return */

import { createAxiosInstance } from '@/services/axiosService'

const burnTimestampToImage = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Imaging/BurnTimestampToImage', payload)
    if (Value) {
      return Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const convertTextToSignatureImage = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Imaging/ConvertTextToSignatureImage', payload)
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const getDateAsImage = async (apiToken, includeTime) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.get(`v1/Imaging/GetDateAsImage?includeTime=${includeTime}`)
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const getInboxSignatureDocuments = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Document/GetInboxSignatureDocuments')
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getSentSignatureDocuments = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Document/GetSentSignatureDocuments')
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getSignatureDocumentsCompleted = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Document/GetCompletedSignatureDocuments')
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getSignatureDocumentsRecalled = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Document/GetRecalledSignatureDocuments')
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getSignatureDocumentTemplate = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Document/GetSignatureDocumentTemplate?Value=${payload}`)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getSignatureDocumentTemplates = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Document/GetSignatureDocumentTemplates')
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getUserInfo = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Auth/GetUserSigInfo')
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getUserInitials = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Auth/GetUserInitialsImage')
    if (response) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const getUserSignature = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Auth/GetUserSignatureImage')
    if (response?.data.Value) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveInitials = async (apiToken, signature) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Auth/SetUserInitialsImage', {
        Value: signature,
    })
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveShouldApplySignatureTimestamp = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Auth/SaveShouldApplySignatureTimestamp', payload)
    if (Value === 'True') {
      return true
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveSignature = async (apiToken, signature, password) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Auth/SetUserSignature', {
        Value: signature,
        updateSignaturePassword: password,
    })
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveSignatureDocumentTemplate = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/SaveSignatureDocumentTemplate', payload)
    return response.data.Value === 'True'
  } catch (error) {
    console.warn(error)
  }
}

const shouldApplySignatureTimestamp = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Auth/ShouldApplySignatureTimestamp', payload)
    return Value === 'True'
  } catch (error) {
    console.warn(error)
  }
}

const startSignatureDocument = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Document/StartSignatureDocument', payload)
    return Value === 'True'
  } catch (error) {
    console.warn(error)
  }
}

const UploadSignatureTemplateFile = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.post('v1/Upload/UploadSignatureTemplateFile', payload)
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const verifyAndSendBulkSigTemplate = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/VerifyAndSendBulkSigTemplate', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

export {
  burnTimestampToImage,
  convertTextToSignatureImage,
  getDateAsImage,
  getInboxSignatureDocuments,
  getSentSignatureDocuments,
  getSignatureDocumentsCompleted,
  getSignatureDocumentsRecalled,
  getSignatureDocumentTemplate,
  getSignatureDocumentTemplates,
  getUserInfo,
  getUserInitials,
  getUserSignature,
  saveInitials,
  saveShouldApplySignatureTimestamp,
  saveSignature,
  saveSignatureDocumentTemplate,
  shouldApplySignatureTimestamp,
  startSignatureDocument,
  UploadSignatureTemplateFile,
  verifyAndSendBulkSigTemplate,
}
