<template>
  <div>
    <div v-if="isSignatureDocLinkView && $route.name === 'Document'" id="signature-doc-link-navbar" class="layout vasion-top-nav md-alignment-center-space-between home-vasion-logo-container">
      <div class="vasion-logo-container">
        <VasionLogo id="logo" />
        <VasionButton
          id="login-button"
          :classProp="'primary'"
          @vasionButtonClicked="navigateToLogin"
        >
          Sign In
        </VasionButton>
      </div>
    </div>

    <div v-if="(activeSubAppSet || isVasionHome) && !isSignatureDocLinkView">
      <div v-if="isSignedIn" class="layout vasion-top-nav md-alignment-center-space-between" :class="{'top-nav-mobile': isMobile }">
        <div v-if="isSavedSearchOpen" class="nav-bar-overlay" @click="toggleSavedSearchDisplay" />
        <h1 class="product-name">{{ !isVasionHome ? activeSubApp : "Welcome to Automate Pro" }}</h1>
        <div class="vasion-flex-end vasion-flex-vertical-center">
          <VasionInput
            v-if="canAccessSearch && (!isMobile || mobileSearchOpened)"
            id="search-bar"
            ref="searchBar"
            v-model="inputValue"
            class="search-bar"
            :name="searchId"
            placeholder="Search Vasion"
            inputType="search-gray"
            :readonly="!isGlobalSearchEnabled"
            :isSearch="true"
            :arrowValue="isSavedSearchOpen"
            :arrowClickCallback="toggleSavedSearchDisplay"
            @keyup.enter="runGlobalSearch"
            @click="handleSearchClick"
          />
          <VasionSearchIcon v-if="canAccessSearch && isMobile && !mobileSearchOpened" class="mobile-search-icon" @click="mobileOpenSearch "/>

          <SavedSearchDropDown v-if="isSavedSearchOpen" width="525" />
        </div>
        <VasionSnackbar
          id="results-snack"
          :showSnackbarBool.sync="showSnackbar"
          :snackbarImage="snackbarImage"
          :snackbarSubTitle="snackbarText"
          :snackbarTitle="snackbarTitle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SavedSearchDropDown from '@/components/search/SavedSearchDropDown.vue'

export default {
    name: 'NavBar',
    props: {
      isMobile: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    components: {
      SavedSearchDropDown,
    },
    data: function () {
      return {
        globalSearchID: 0,
        inputValue: '',
        mobileSearchOpened : false,
        showAppPicker: false,
        showProfileMenu: false,
        showSaveSignatureDialog: false,
        showSnackbar: false,
        showUserSettings: false,
        snackbarImage: false,
        snackbarText: '',
        snackbarTitle: '',
      }
    },
    computed: {
        activeSubApp() { return this.$store.state.mainViews.activeSubApp.toUpperCase() },
        activeSubAppSet() { return this.$store.state.mainViews.activeSubApp !== '' },
        canAccessApps() {
          return !this.$store.state.common.appAccess
            || this.$store.state.common.appAccess.length === 0
            || this.$store.state.common.appAccess.length > 1
            || (this.$store.state.common.appAccess.length === 1 && !this.canAccessSearch)
        },
        canAccessSearch() { return this.$store.getters['common/APP_ACCESS_SEARCH'] },
        defaultAppLocalSelection() { return this.$store.state.admin.defaultAppLocalSelection },
        displayName() { return this.$store.state.common.firstName ? this.$store.state.common.firstName : this.$store.state.common.userName },
        fullProfilePic() {
          return ''
        },
        isAdminUser() { return this.$store.getters['common/isAdminUser'] },
        isGlobalSearchEnabled() { return this.$store.state.search.isGlobalSearchEnabled },
        isSavedSearchOpen() { return this.$store.state.mainViews.isSavedSearchOpen },
        isSignatureDocLinkView() { return this.$store.state.common.isSignatureDocLinkView },
        isSignedIn() { return this.$store.state.common.isSignedIn },
        isVasionHome() { return this.$store.state.mainViews.vasionHomeToggle },
        searchId() { return `main-search-${Date.now().toString()}` },
    },
    created() {
      if (this.isSignedIn) {
        this.$store.dispatch('search/getGlobalSearchEnabled')
      }
    },
    methods: {
      closeAppPicker() {
        if (this.showAppPicker === true) {
          this.showAppPicker = false
        }
      },
      mobileOpenSearch() {
        this.mobileSearchOpened = true
      },
      handleSearchClick() {
        if (this.isGlobalSearchEnabled) return
        this.toggleSavedSearchDisplay()
      },
      navigateToLogin() {
        this.$router.push({ name: 'TheLogin' })
      },
      async runGlobalSearch() {
        if (this.inputValue === '') {
          return
        }
        this.mobileSearchOpened = false
        this.$amplitude.trackEvent('Search Run', { searchType: 'Global'} )
        this.globalSearchID = await this.$store.dispatch('search/getGlobalSearchID')
        const payload = {
          SearchId: this.globalSearchID,
          SearchType: 161,
          FullText: this.inputValue,
        }
        await Promise.all([
          this.$store.dispatch('search/setSelectedSearch', { SearchId: this.globalSearchID, Type: 161, isGlobalSearch: true }),
          this.$store.dispatch('search/setSearchCriteria', payload),
        ])
        this.$router.push({ name: 'SearchResults' }).catch(() => {})
      },
      toggleAdmin() {
        this.$store.dispatch('mainViews/toggleAdminNav')
      },
      toggleAppPicker() {
          this.showAppPicker = !this.showAppPicker

          if (this.showAppPicker === true) {
            this.showProfileMenu = false
          }
      },
      toggleProfileMenu() {
          this.showProfileMenu = !this.showProfileMenu

          if (this.showProfileMenu === true) {
            this.showAppPicker = false
          }
      },
      toggleSavedSearchDisplay() {
        this.$store.dispatch('mainViews/toggleSavedSearchDropDown', !this.isSavedSearchOpen)
      },
    },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';
  .apps {
    margin-right: 21px;
  }
  .button-background {
    width: 121px;
    height: 40px;
    margin: 0 15px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .divider {
    width: 1px;
    height: 24px;
    opacity: 0.5;
    margin: 0 16px 0 16px;
    // background-color: $grey-300;
  }

  #login-button {
    position: absolute;
    right: 15px;
  }

  #signature-doc-link-navbar {
    width: 100vw;
    background-color: $primary;

    #logo, .vasion-logo-container {
      background-color: $primary;
    }
  }

  .home-vasion-logo-container {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: left;

    #logo {
      margin-left: 25px;
      width: 95%;
      overflow: visible;
    }

    .sign-out {
      @include Text;
      color: $primary;
      cursor: pointer;
      margin-right: 15px;
    }

  }

  .vasion-logo-container {
    padding-left: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .name {
    width: 57px;
    height: 18px;
    margin-right: 10px;
    font-family: $font-family-medium;
    font-size: 14px;
    font-weight: 500;
    line-height: 0.71;
    color: $orange-300;
  }

  .round-background-hover {
    margin-left: 11px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  .round-background-hover:hover {
    background-color: $plumb-50;
  }

  .round-background-toggled {
    background-color: $plumb-50;
  }

  .roundImage {
    height: 24px;
    width: 24px;
    border-radius: 16px;
  }
  .product-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
    align-self: stretch;

    overflow: hidden;
    color: $primary;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: $font-family-accent;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-transform: uppercase;
    margin: unset;
    margin-bottom: 10px;
  }

  .search-bar{
    width: 525px;
  }

  .layout{
    display: flex;
    justify-content: space-between;
  }

  #profile-button {
    position: relative;
    cursor: pointer;

    & #profile-menu {
      width: 250px;
      position: absolute;
      right: 0;
      top: 40px;
    }
  }
  #vasion-apps {
    position: relative;
    top: 4px;
    left: 4px;
    z-index: 40;
    cursor: pointer;
    & #app-picker {
      position: absolute;
      top: 15px;
      left: -35px;
      z-index: 1000;
    }
  }

  #logo {
    fill: $on-primary;
  }

  .nav-bar-overlay{
    display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,0.4);
      position: fixed;
      z-index: 3;
      cursor: pointer;
  }

  @media (max-width: $tablet){
    .nav-bar-overlay{
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,0.4);
      position: fixed;
      z-index: 1;
      cursor: pointer;
    }
    .search-bar{
      width: 400px;
    }
    .search-icon {
      display: none;
    }

    .button-background {
      width: 60px;
      height: 40px;
      margin: 0 15px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .name {
      width: 57px;
      height: 18px;
      margin-right: 0px;
      font-family: $font-family-medium;
      font-size: 14px;
      font-weight: 500;
      line-height: 0.71;
      color: $orange-300;
    }

    .home-vasion-logo-container {
      .sign-out {
        @include Text;
        color: $white;
        text-align: right;
        margin-right: 15px;
      }
    }
  }

  @media (max-width: $breakpoint-md){
    .search-bar{
      width: 180px;
    }
    .search-icon {
      display: none;
    }

    #vasion-apps {
      position: relative;
      top: 4px;
      left: 4px;
      cursor: pointer;
      & #app-picker {
        width: 295px;
        position: absolute;
        top: 15px;
        left: -50px;
        z-index: 1000;
      }
    }

    .home-vasion-logo-container {
      .sign-out {
        @include Text;
        color: $white;
        text-align: right;
      }
    }
  }

  .logo-resp-behavior {
    display: none;
  }

  .isotype {
    display: block;
    fill: $on-primary;
  }
  @media (min-width: 768px) {
    .vasion-logo-container {
      width: 256px;
      padding-left: none;
    }
    .divider {
      margin: 0 28px 0 16px;
    }
    .isotype {
      display: none!important;;
    }
    .logo-resp-behavior {
      display: block!important;;
    }
  }

  /* Mobile Styles */
  .top-nav-mobile {
    background-color: $primary !important;
    border: none;
  }

  .top-nav-mobile {
    .product-name {
      align-items: flex-start;
      display: flex;
      margin-bottom: unset;
    }
  }

  .mobile-search-icon {
    fill: $white;
  }

  @media (max-width: $breakpoint-md) {
    .product-name {
      color: $on-primary;
      font-family: $font-family-book;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      text-transform: unset;
      margin-bottom: unset;
    }
  }
</style>
