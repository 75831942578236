/* eslint-disable */
const dataURLtoFile = function(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

const enrichCloudData = function enrichCloudData(data) {
  if(data){
    let folders
    if(data.folders){
      folders = data.folders.map((vault) => {
        // eslint-disable-next-line
        vault['piiData'] = false
        vault['encrypted'] = false
        vault['errorCode'] = false
        vault['userAccess'] = []
        vault['groupAccess'] = []
        vault['allAccess'] = false
        vault['vaultName'] = vault.name
        vault['Name'] = vault.name
        vault['storageid'] = vault.storageid
        vault['FolderId'] = vault.id
        vault['vaultID'] = vault.id
        vault['HasChildren'] = vault.hasChildren
        vault['CanModify'] = true
        vault['Type'] = 0
        return vault
      })
    }
    let files
    if(data.files){
      files = data.files.map((vault) => {
        // eslint-disable-next-line
        vault['piiData'] = false
        vault['encrypted'] = false
        vault['errorCode'] = false
        vault['userAccess'] = []
        vault['groupAccess'] = []
        vault['allAccess'] = false
        vault['vaultName'] = vault.name
        vault['Name'] = vault.name
        vault['storageid'] = vault.storageid
        vault['FolderId'] = vault.id
        vault['vaultID'] = vault.id
        vault['HasChildren'] = true
        vault['CanModify'] = true
        vault['Type'] = 1
        return vault
      })
    }
    if(folders){
      folders.sort( function( a, b ) {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();

        return a < b ? -1 : a > b ? 1 : 0;
      });
    }
    if(files){
      files.sort( function( a, b ) {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();

        return a < b ? -1 : a > b ? 1 : 0;
      });
    }
    // const foldersAndFiles = [...folders, ...files]
    return {folders: folders, files: files}

  }else {return {folders: [], files: []}}
}

const enrichCloudDataFoldersOnly = function enrichCloudData(data) {
  if(data && data.folders){
    const folders = data.folders.map((vault) => {
      // eslint-disable-next-line
      vault['piiData'] = false
      vault['encrypted'] = false
      vault['errorCode'] = false
      vault['userAccess'] = []
      vault['groupAccess'] = []
      vault['allAccess'] = false
      vault['vaultName'] = vault.name
      vault['Name'] = vault.name
      vault['storageid'] = vault.storageid
      vault['FolderId'] = vault.id
      vault['vaultID'] = vault.id
      vault['HasChildren'] = true
      vault['CanModify'] = true
      vault['Type'] = 0
      return vault
    })

    return folders.sort( function( a, b ) {
      a = a.name.toLowerCase();
      b = b.name.toLowerCase();

      return a < b ? -1 : a > b ? 1 : 0;
  });
  }
}

const toBase64 = function (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  })
}

const readFileContent = function (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    // the CSV content as string
    reader.onload = (event) => resolve(event.target.result);
    reader.onerror = (error) => reject(error);      
    reader.readAsText(file);
  })
}

export {
  dataURLtoFile,
  enrichCloudData,
  enrichCloudDataFoldersOnly,
  toBase64,
  readFileContent,
}
