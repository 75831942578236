<template>
  <div id="container">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <VasionInput
      v-model="searchValue"
      inputType="top-white"
      title="Subfolder"
      @keydown.enter="search"
      @submit.prevent="search"
    />
    <div v-if="tableData.Rows.Values.length > 0" id="table-container" class="main-table-container vasion-html-table-default">
      <VasionTable
        :headerColumns="columns"
        :tableRows="tableData.Rows.Values"
        :hideColumns="hiddenColumns"
        :supportSorting="false"
        @vasion-selection="goToSubfolder($event)"
      />
    </div>
    <div v-else-if="showNoFolders" id="no-subfolders" class="instructional-message-div">
      No subfolders found.
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'SubfolderSearch',
  components: {
    Loading,
  },
  data: function () {
    return {
      columns: [
        '_iID_',
        'Subfolder',
      ],
      hiddenColumns: ['_iID_'],
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      searchValue: '',
      showNoFolders: false,
      tableData: {
        Rows: {
          Values: [],
        },
      },
    }
  },
  methods: {
    async goToSubfolder(event) {
      this.isLoading = true

      const pathString = event.Values[1]
      const folderNames = pathString.split('\\')
      const selectedFolder = folderNames[folderNames.length - 2]

      const folderId = event.Values[0]
      if (this.$store.state.vault.activeFolderId !== folderId) {
        await this.$router.push({ name: 'Folders', params: { folderId: folderId } })
      }

      this.$store.dispatch('mainViews/toggleSavedSearchDropDown', false)
      this.$store.dispatch('vault/setActiveFolderName', selectedFolder)
      this.isLoading = false
    },
    async search(e) {
      this.isLoading = true

      if (e) {
        e.preventDefault()
      }

      if (this.searchValue.length > 0) {
        const searchPayload = {
          Value: this.searchValue,
          FilterByStorageConfig: false,
          StorageConfigId: 0,
        }
        this.subfolderSearchResults = await this.$store.dispatch('vault/vasionFolderSearch', searchPayload)

        if (this.subfolderSearchResults.length > 0) {
          this.tableData.Rows.Values = this.subfolderSearchResults.map(item => {
            const dataRow = [
              item.iID,
              item.sName,
            ]

            const retElement = {
              Values: dataRow,
              ValueType: 'String',
            }

            return retElement
          })
        } else {
          this.tableData.Rows.Values = []
          this.showNoFolders = true
        }
      }

      this.isLoading = false
    },
  },
}

</script>

<style lang="scss" scoped>
.vasion-input {
  padding: 25px 0;
}

#table-container {
  max-height: 375px;
  overflow: auto;
}
</style>
