<template>
  <div class="fillWidth" :style="{ width: width+'px', height: height+'px' }">
    <div v-show="!failedLoading">
      <span v-show="!dataLoaded" class="center-placeholder">Loading Image...</span>
      <img
        v-show="dataLoaded"
        :id="imageId"
        class="documentImage"
        :style="{ width: width+'px', height: height+'px' }"
        :src="imageData"
      >
    </div>
  </div>
</template>

<script>

export default {
  name: 'ThumbnailImage',
  components: {
  },
  props: {
    documentID: {
      type: Number,
      required: true,
    },
    filePath: {
      type: String,
      required: false,
      default: '',
    },
    height: {
      type: Number,
      required: false,
      default: null,
    },
    imageString: {
      type: String,
      required: false,
      default: "",
    },
    index: {
      type: Number,
      required: true,
    },
    pageNumber: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      archiveData: [],
      dataLoaded: false,
      failedLoading: false,
      image: {},
      imageData: '',
      isVisible: false,
      refreshOnVisible: false,
    };
  },
  computed: {
    imageId() { return `doc:${this.documentID}page:${this.pageNumber}` },
    isInSignatureMode() { return this.$route.name !== 'SignatureEditDocument' && this.$store.state.mainViews.activeSubApp === 'signatures' },
    zoomValue() { return this.$store.state.document.zoomValue },
  },
  watch: {
    index: function () {
      this.dataLoaded = false
      this.getImageString()
    },
    pageNumber: function () {
      this.updateImageSource()
    },
  },
  created() {
    this.getImageString()
  },
  methods: {
    async getImageString() {
      if (this.imageString) {
        this.imageData = this.imageString
        this.dataLoaded = true
      } else {
        const payload = {
          DocumentId: this.documentID,
          PageNumber: this.pageNumber,
          ReloadImage: false,
          ReturnImageData: true,
        }
        const image = await this.$store.dispatch('document/getThumbnail', payload)
        if (image == 0){
          this.failedLoading = true
        }
        this.dataLoaded = true
        this.imageData = image
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  .fillWidth {
    width: 100%;
    overflow: hidden;
  }

  .center-placeholder {
    width: 100%;
  }

</style>
