<template>
  <div class="vasion-white-background vld-parent">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="main-div" :style="{width: isInMyForms ? 'inherit': ''}" :class="{'main-div-dialog': !isEmbedded}">
      <div v-show="!showCompleteSignatureModal || isEmbedded">
        <div v-if="customSignatureLabel !== ''" class="vasion-page-title top-div">
          {{ customSignatureLabel }}
        </div>
        <div v-else class="vasion-page-title top-div">
          Add Your {{ annotationType }}
        </div>
        <div class="md-layout w100 signature-tabs">
          <VasionButton id="button-draw" :classProp="drawTabClass" @vasionButtonClicked="updateSelectedTab('draw')">
            Draw
          </VasionButton>
          <VasionButton id="button-type" :classProp="typeTabClass" @vasionButtonClicked="updateSelectedTab('type')">
            Type
          </VasionButton>
          <VasionButton
            v-if="savedSignatureOrInitials && savedSignatureOrInitials !== ''"
            id="button-add-saved"
            :classProp="addSavedTabClass"
            @vasionButtonClicked="updateSelectedTab('addSaved')"
          >
            Add Saved
          </VasionButton>
        </div>
        <div v-if="selectedTabName === 'draw'" :class="{'draw-initials': annotationType === 'Initials'}">
          <Draw
            ref="draw"
            :image="currentSignatureImage"
            @hasSignature="enableSignButton"
          />
        </div>
        <div v-if="selectedTabName === 'type'">
          <Type
            ref="typeSignature"
            :annotationType="annotationType"
            @hasSignature="enableSignButton"
            @noSignature="disableSignButton"
          />
        </div>
        <div v-if="selectedTabName === 'addSaved'">
          <AddSaved
            :savedSignatureOrInitials="savedSignatureOrInitials"
          />
        </div>
        <div class="bottom-div">
          {{ errorText }}
          <div class="auto-left">
            <VasionButton
              id="button-clear"
              :classProp="'secondary'"
              :isDisabled="signButtonDisabled || selectedTabName !== 'draw'"
              @vasionButtonClicked="clearClick()"
            >
              CLEAR {{ annotationType === "Signature" ? "" : annotationType }}
            </VasionButton>
            <VasionButton
              v-if="showCancelButton"
              id="button-cancel"
              :classProp="'secondary'"
              @vasionButtonClicked="cancelClick()"
            >
              CANCEL
            </VasionButton>
            <VasionButton
              v-if="showSignButton"
              id="button-sign"
              :classProp="'primary'"
              :isDisabled="signButtonDisabled"
              @vasionButtonClicked="attemptToSign"
            >
              SIGN
            </VasionButton>
          </div>
        </div>
      </div>
    </div>

    <VasionLegallyBindingConsentModal
      id="consent-modal"
      :documentID="documentID"
      :geolocation="geolocation"
      :ipAddress="ipAddress"
      :sync="showCompleteSignatureModal"
      @confirmButtonClick="agreeClick"
      @noButtonClick="toggleShowCompleteSignatureModal"
    />
    <VasionGeneralModal
      id="adopt-modal"
      :rejectButtonText="'NO'"
      :confirmButtonText="'YES'"
      :modalTitle="`Adopt ${annotationType}`"
      :modalType="'slot'"
      :sync="showAdoptSignatureModal"
      @confirmButtonClick="adoptSignatureResponse(true)"
      @noButtonClick="adoptSignatureResponse(false)"
    >
      Do you want to adopt {{ annotationType === "Signature" ? 'this' : 'these' }} {{ annotationType }}?
    </VasionGeneralModal>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

import AddSaved from '@/components/digitalSignature/AddSaved.vue'
import Draw from '@/components/digitalSignature/Draw.vue'
import Type from '@/components/digitalSignature/Type.vue'

export default {
  name: 'VasionApplySignature',
  components: {
    AddSaved,
    Draw,
    Loading,
    Type,
  },
  props: {
    annotationType: {
      type: String,
      required: true,
    },
    applyTimestamp: {
      type: Boolean,
      required: false,
      default: true,
    },
    currentSignatureImage: {
      type: String,
      required: false,
      default: '',
    },
    customSignatureLabel: {
      type: String,
      required: false,
      default: '',
    },
    documentID: {
      type: Number,
      required: true,
    },
    geolocation: {
      type: String,
      required: false,
      default: '',
    },
    allowAdoption: {
      type: Boolean,
      required: false,
      default: false,
    },
    ipAddress: {
      type: String,
      required: false,
      default: '',
    },
    isEmbedded: {
      type: Boolean,
      required: false,
      default: false,
    },
    savedSignatureOrInitials: {
      type: String,
      required: false,
      default: '',
    },
    showCancelButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    showLegalBound: { //Hide the legally bound consent if in Signature App
      type: Boolean,
      required: false,
      default: true,
    },
    showSignButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: function () {
    return {
      adoptSignature: false,
      errorText: '',
      isInMyForms: false,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      signButtonDisabled: true,
      selectedTabName: 'draw',
      showAdoptSignatureModal: false,
      showCompleteSignatureModal: false,
    }
  },
  computed: {
    addSavedTabClass() { return this.selectedTabName === 'addSaved' ? 'tab-selected' : 'tab' },
    drawTabClass() { return this.selectedTabName === 'draw' ? 'tab-selected' : 'tab' },
    typeTabClass() { return this.selectedTabName === 'type' ? 'tab-selected' : 'tab' },
  },
  async created() {
    this.isInMyForms = this.$route.name === 'MyForm' || this.$route.name === 'plainForm'
    this.signButtonDisabled = !this.currentSignatureImage
  },
  methods: {
    async adoptSignatureResponse(yesToAdopt) {
      this.adoptSignature = yesToAdopt
      this.showAdoptSignatureModal = false
      await this.signTheDoc()
    },
    async agreeClick() {
      if (this.allowAdoption) {
        this.showAdoptSignatureModal = true
      } else {
        await this.signTheDoc()
      }
    },
    async signTheDoc() {
      let signature;
      switch (this.selectedTabName) {
        case 'draw':
          signature = this.$refs.draw.saveSignature()
          break;
        case 'type':
          signature = await this.$refs.typeSignature.getSignatureImage()
          break;
        case 'addSaved':
          signature = this.savedSignatureOrInitials
          break;
        default:
          signature = ''
          break;
      }

      let stampedSignature = signature
      // Embedded forms now have the timestamps placed on the signature on the backend
      if (this.applyTimestamp && !this.isEmbedded) {
        this.isLoading = true
        stampedSignature = await this.$store.dispatch('digitalSignature/burnTimestampToImageBase64', signature)
        this.isLoading = false
      }

      this.closeShowCompleteSignatureModal()
      this.$emit('ok-click', stampedSignature, this.adoptSignature, signature, this.applyTimestamp)
    },
    async attemptToSign() {
      if (this.showLegalBound) {
        this.toggleShowCompleteSignatureModal()
      } else {
        await this.agreeClick()
      }
    },
    cancelClick() { this.$emit('cancel-click') },
    clearClick() {
      if (this.$refs.draw) {
        this.$refs.draw.ref_clear()
      }
      this.disableSignButton()
    },
    closeShowCompleteSignatureModal() { this.showCompleteSignatureModal = false },
    disableSignButton() {
      this.signButtonDisabled = true
      this.$emit('signatureChanged', false)
      },
    enableSignButton() {
      this.signButtonDisabled = false
      this.$emit('signatureChanged', true)
    },
    async ref_attemptToSign() {
      await this.attemptToSign()
    },
    ref_clear() {
      this.clearClick()
      this.updateSelectedTab('draw')
    },
    toggleShowCompleteSignatureModal() { this.showCompleteSignatureModal = !this.showCompleteSignatureModal },
    updateSelectedTab(tabName) {
      this.disableSignButton()
      this.selectedTabName = tabName
      if (tabName === 'addSaved') {
        this.enableSignButton()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .main-div-dialog {
    padding: 24px;
  }

 .main-div {
    overflow: hidden;
  }

  .vasion-loading-indicator {
    height: 113%;
    margin-top: -30px;
  }

  @media(max-width: 600px){
    .main-div{
      width: 90vw;
    }
  }

  @media(max-width: $phone){
    .main-div{
      width: auto;
    }
  }

  .top-div {
    height: 30px;
  }

  .bottom-div {
    margin-top: 6px;
    margin-right: -8px;
    text-align: right;
    vertical-align: middle;
    line-height: 50px;
    display: flex
  }

  @media(max-width: $tablet){
    .bottom-div{
      flex-wrap: wrap;
    }
  }

  .auto-left {
    margin-left: auto;
  }

  .signature-tabs {
    margin-bottom: 10px;
  }

  #consent-modal {
    padding: 5px;
  }
  
  .vasion-terms-of-service {
    color: $orange-300 !important;
    text-decoration: underline;
  }
</style>
