/* eslint-disable linebreak-style */
/* eslint-disable default-case */
/* eslint-disable consistent-return */

import { createAxiosInstance } from '@/services/axiosService'

const deleteFTSearchDataCache = async (apiToken, searchGUID) => {
  const axios = createAxiosInstance(apiToken)
    try {
      const response = await axios.delete('v1/Search/DeleteFTSearchDataCache', {
        data: {
          Value: searchGUID,
        },
      })
      if (response) {
        return response.data
      }
    } catch (error) {
      console.warn(error)
    }
}

const deleteLinkedSearch = async (apiToken, linkedSearchID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete(`v1/Search/DeleteLinkedSearch?Value=${linkedSearchID}`)

    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const deleteSearch = async (apiToken, searchID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('v1/admin/DeleteSearch', {
      data: {
        Value: searchID.toString(),
      },
    })

    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getAdminSearchList = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Admin/GetAllSearches')
    if (response?.data && response.data.Items && response.data.Items.Values) {
      return response.data.Items.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const getAllLinkedSearch = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Search/GetAllLinkedSearch')
    if (response?.data && response.data.Values) {
      return response.data.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const getFolderIndexSchedule = async (apiToken, folderId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Search/GetFolderIndexSchedule?Value=${folderId}`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getFTSearchDocDetails = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get(`v1/Search/GetFTSearchDocDetails?searchGUID=${payload.searchGUID}&documentID=${payload.docID}&versionDocumentID=${payload.versionID}`)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const getFullTextSearchDetails = async (apiToken, searchID) => {
  const value = searchID <= 0 ? 99999 : searchID
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Admin/GetFTSearchDetails?Value=${value}`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getGlobalSearchCustomFolderId = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Search/GetGlobalSearchCustomFolderViewID')
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getGlobalSearchEnabled = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Search/IsGlobalSearchEnabled')
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getGlobalSearchFolderIndexSchedule = async (apiToken, folderId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Search/GetFolderIndexSchedule?Value=${folderId}`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getGlobalSearchID = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axios.get('v1/Search/GetGlobalSearchID')
    return Value
  } catch (error) {
    console.warn(error)
  }
}

const getIndexSearchDetails = async (apiToken, searchID) => {
  const value = searchID <= 0 ? 99999 : searchID
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Admin/GetIndexSearchDetails?Value=${value}`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getIndexSearchFields = async (apiToken, searchID) => {
  const value = searchID <= 0 ? 99999 : searchID
  const axios = createAxiosInstance(apiToken)
  const payload = {
    SearchId: value,
  }
  try {
    const response = await axios.post('v1/Search/GetIXSearchFields', payload)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getLinkedSearch = async (apiToken, searchID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Search/GetLinkedSearch?Value=${searchID}`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getSearches = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Search/GetSearches', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}
const getSearchSecurity = async (apiToken, searchId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get(`v1/Search/GetSearchSecurity?Value=${searchId}`)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const runFTSearchWithDataCacheDetails = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Search/FTSearchWithDataCacheDetails', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const runGetFolderSearchConfigurations = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Admin/GetCustomFolderViewDetails')
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const runIndexSearch = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  payload.Fields = payload.Fields.filter(function(obj){
    return obj.FieldId !== 0 || (obj.TextValue !== null && obj.TextValue !== '')
  })
  try {
    const fullTextField = payload.Fields.find((element) => {
      return element.FieldId === 0
    })

    const endpoint = fullTextField ? 'v1/Search/IXSearchWithFTField' : 'v1/Search/IXSearch'

    const response = await axios.post(endpoint, payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const runSaveCustomFolderView = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/SaveCustomFolderView', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const runSaveFullTextSearch = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/SaveFTSearchDetails', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const runSaveIndexSearch = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/SaveIndexSearchDetails', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveLinkedSearch = async (apiToken, payload) => {
 const axios = createAxiosInstance(apiToken)
 try {
   const { data } = await axios.post('v1/Search/SaveLinkedSearch', payload)
   return data
 } catch (error) {
   console.warn(error)
 }
}

const saveSearchSecurity = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.post('v1/Search/SaveSearchSecurity', payload)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const setFolderIndexSchedule = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Search/SaveFolderIndexSchedule', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const setGlobalFolderIndexSchedule = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Search/SaveFolderIndexSchedule', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const setGlobalSearchCustomFolderId = async (apiToken, value) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Search/SetGlobalSearchCustomFolderViewID', { Value: value })
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const setGlobalSearchEnabled = async (apiToken, value) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Search/SetGlobalSearchEnabled', { Value: value })
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const updateFolderSearchIndex = async (apiToken, folderID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const payload = {
      Value: folderID,
    }

    const response = await axios.post('v1/Search/UpdateFolderSearchIndex', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const updateFullTextSearchIndex = async (apiToken, searchID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const payload = {
      Value: searchID,
    }

    const response = await axios.post('v1/Search/UpdateFullTextSearchIndex', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

export {
  deleteFTSearchDataCache,
  deleteLinkedSearch,
  deleteSearch,
  getAdminSearchList,
  getAllLinkedSearch,
  getFolderIndexSchedule,
  getFTSearchDocDetails,
  getFullTextSearchDetails,
  getGlobalSearchEnabled,
  getGlobalSearchCustomFolderId,
  getGlobalSearchFolderIndexSchedule,
  getGlobalSearchID,
  getIndexSearchDetails,
  getIndexSearchFields,
  getLinkedSearch,
  getSearches,
  getSearchSecurity,
  runFTSearchWithDataCacheDetails,
  runGetFolderSearchConfigurations,
  runIndexSearch,
  runSaveCustomFolderView,
  runSaveFullTextSearch,
  runSaveIndexSearch,
  saveLinkedSearch,
  saveSearchSecurity,
  setFolderIndexSchedule,
  setGlobalFolderIndexSchedule,
  setGlobalSearchCustomFolderId,
  setGlobalSearchEnabled,
  updateFolderSearchIndex,
  updateFullTextSearchIndex,
}
