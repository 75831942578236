/* eslint-disable linebreak-style */
/* eslint-disable default-case */
/* eslint-disable consistent-return */

import { createAxiosInstance } from '@/services/axiosService'

const doDeleteAuditReport = async (apiToken, reportID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('v1/Report/DeleteAuditReport', {
      data: {
        Value: reportID.toString(),
      },
    })
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const doDeleteCustomReport = async (apiToken, reportID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('/v1/Report/DeleteCustomReport', {
      data: {
        Value: reportID.toString(),
      },
    })
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const doDeleteCustomReportByName = async (apiToken, reportName) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('v1/Report/DeleteCustomReportByName', {
      data: {
        Value: reportName,
      },
    })
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const doDeleteWorkflowReport = async (apiToken, reportID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('v1/Report/DeleteWorkflowReport', {
      data: {
        Value: reportID.toString(),
      },
    })
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const doGenerateAuditReportPDF = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Download/ExportAuditReport', payload, {
      responseType: 'blob',
    })
    if (response) {
      return response
    }
  } catch (error) {
    console.warn(error)
  }
}

const doGenerateGenericReportPDF = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Download/ExportReport', {
      params: payload,
      responseType: 'blob',
    })
    if (response) {
      return response
    }
  } catch (error) {
    console.warn(error)
  }
}

const doGenerateWorkflowReportPDF = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Download/ExportWorkflowReport', payload, {
      responseType: 'blob',
    })
    if (response) {
      return response
    }
  } catch (error) {
    console.warn(error)
  }
}

const doGetAllAuditReports = async (apiToken, enforceSecurity) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Values } } = await axios.get(`v1/Report/LoadAllAuditReports?enforceUserSecurity=${enforceSecurity}`)
    return Values
  } catch (error) {
    console.warn(error)
  }
}

const doGetAllCustomReports = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Report/LoadAllCustomReports', null)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const doGetAllReports = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Report/LoadAll', null)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const doGetAllReportsForAutomation = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Report/LoadAllForAutomation', null)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const doGetAllWorkflowReports = async (apiToken, enforceSecurity) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: { Values } } = await axios.get(`v1/Report/LoadAllWorkflowReports?enforceUserSecurity=${enforceSecurity}`)
    return Values
  } catch (error) {
    console.warn(error)
  }
}

const doGetAuditActions = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('/v1/Auth/GetAuditActions')
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const doGetAuditReport = async (apiToken, auditReportId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data } = await axios.get(`v1/Report/LoadAuditReport?Value=${auditReportId}`)
    return data
  } catch (error) {
    console.warn(error)
  }
}

const doGetWorkflowReport = async (apiToken, workflowReportId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Report/LoadWorkflowReport?Value=${workflowReportId}`)
    return response.data
  } catch (error) {
    console.warn(error)
  }
}

const doGetWorkflows = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Workflow/WFList', null)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const doGetWorkflowsWithIndexForms = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Workflow/WFListWithIndexForms', null)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}


const doLoadAllCustomReportDetails = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Report/LoadAllCustomReportDetails')
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const doRunAuditReport = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/report/RunAuditReport', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const doRunGenericReport = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/report/RunReport', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const doRunWorkflowReport = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/report/RunWorkflowReport', payload)
    if (response) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const doSaveAuditReport = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Report/SaveAuditReport', payload)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}
const doSaveCustomReport = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/report/SaveCustomReport', payload)
    if (response) {
      return response
    }
  } catch (error) {
    console.warn(error)
  }
}

const doSaveWorkflowReport = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Report/SaveWorkflowReport', payload)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

export {
  doDeleteAuditReport,
  doDeleteCustomReport,
  doDeleteCustomReportByName,
  doDeleteWorkflowReport,
  doGenerateAuditReportPDF,
  doGenerateGenericReportPDF,
  doGenerateWorkflowReportPDF,
  doGetAllAuditReports,
  doGetAllCustomReports,
  doGetAllReports,
  doGetAllReportsForAutomation,
  doGetAllWorkflowReports,
  doGetAuditActions,
  doGetAuditReport,
  doGetWorkflows,
  doGetWorkflowsWithIndexForms,
  doGetWorkflowReport,
  doLoadAllCustomReportDetails,
  doRunAuditReport,
  doRunGenericReport,
  doRunWorkflowReport,
  doSaveAuditReport,
  doSaveCustomReport,
  doSaveWorkflowReport,
}
