import { formatDateTime } from './common.module'

const buildFolderGridData = (data) => {
  const result = {
    tableHeaders: ['_Type_', '_TypeID_', '_VasionCheckBox_', '_VasionIcon_'],
    tableRows: [],
  }

  if (!data || !data.Columns || !data.Columns.Values) {
    return result
  }

  data.Columns.Values.forEach((column) => {
    if (column === 'DisplayName') {
      result.tableHeaders.push('Display Name')
    } else {
      result.tableHeaders.push(column)
    }
  })

  if (!data.Rows || !data.Rows.Values) {
    return result
  }

  data.Rows.Values.forEach((rowElement) => {
    const dataRow = [rowElement.Type, rowElement.TypeId,
      {
        show: (rowElement.Type !== 0),
        value: false,
      },
      (rowElement.Type === 0 ? 'VasionFolderIcon' : 'VasionInsertDriveFileIcon')]

    rowElement.Values.Values.forEach((subElement) => {
      if (subElement.Key.includes('Date/Time')) {
        const dateTimeDisplayValue = formatDateTime(subElement?.Value ?? '', 'datetime')
        dataRow.push(dateTimeDisplayValue)
      }
      else {
        dataRow.push(subElement.Value)
      }
    })
    result.tableRows.push({
      Values: dataRow,
      ValueType: 'String',
    })
  })

  return result
}
const buildFolderGridDataForRoot = (data) => {
  const result = {
    tableHeaders: ['_Type_', '_TypeID_', '_VasionCheckBox_', '_VasionIcon_', "StepID", "Display Name", "Created Date", "Modified Date"],
    tableRows: [],
  }

  data.forEach(folder => {
    const folderData = {
      ValueType: 'String',
      Values: [],
    }
    result.tableHeaders.forEach(header => {
      switch (header) {
        case '_Type_':
          folderData.Values.push(0)
          break
        case '_TypeID_':
          folderData.Values.push(folder.FolderId)
          break
        case '_VasionCheckBox_':
          folderData.Values.push({
            show: false,
            value: false,
          })
          break
        case '_VasionIcon_':
          folderData.Values.push('VasionFolderIcon')
          break
        case 'Display Name':
          folderData.Values.push(folder.Name)
          break
        default:
          folderData.Values.push('')
          break
      }
    })
    result.tableRows.push(folderData)
  })

  return result
}

export {
  buildFolderGridData,
  buildFolderGridDataForRoot,
}
