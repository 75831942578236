<template>
  <div id="index-field-list">
    <form id="index-field-form" class="md-layout" @submit.prevent="submitIndexFields">
      <div class="wrap">
        <md-table v-if="selectorOnly" md-card>
          <md-table-row class="table-row comments-header">
            <md-table-head :class="'vasion-table-head-label'">
              Include All Fields
            </md-table-head>
          </md-table-row>
          <AttributeFieldSelectItem
            v-for="(field) in localFields"
            :key="field.value"
            :field="field"
            :isDisabled="disableAllFields"
            @notValid="markNotValid"
            @fieldclicked="updateSelectedFieldList"
          />
        </md-table>
        <div v-else :class="{'index-field-list-100': isInDocumentView, 'thing-wrap': !isInDocumentView}">
          <AttributeFieldFilterItem
            v-for="(field) in localFields"
            :ref="field.value"
            :key="field.value"
            :isInDocumentView="isInDocumentView"
            :useMultiSelectLists="useMultiSelectLists"
            :field="field"
            :forPrompt="forPrompt"
            :isDisabled="disableAllFields"
            :fixedSize="fixedSize"
            @filterupdated="updateFilterList"
            @clean="markClean"
            @dirty="markDirty"
            @notValid="markNotValid(field)"
            @isValid="markIsValid(field)"
            @isRequiredAndEmpty="handleRequiredValues"
            v-on="$listeners"
          />
        </div>
        <div v-if="showSubmit" id="submit-btns">
          <VasionButton
            v-if="forPrompt || showCancelButton"
            id="index-cancel"
            :classProp="'secondary'"
            @vasionButtonClicked="submitCancelled"
          >
            Cancel
          </VasionButton>
          <VasionButton
            id="index-submit"
            :isDisabled="disableSubmit"
            :isSubmit="true"
            :classProp="'primary'"
            @vasionButtonClicked="submitIndexFields"
          >
            <span>Submit</span>
          </VasionButton>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import AttributeFieldFilterItem from '@/components/AttributeFieldFilterItem.vue';
import AttributeFieldSelectItem from '@/components/AttributeFieldSelectItem.vue';
import { loaderColor } from '@/assets/js/styleConfig'
import { cloneDeep, isEqual } from 'lodash'
import { unformatCurrency } from '../store/helperModules/common.module';

export default {
  name: 'IndexFieldList',
  components: {
    AttributeFieldFilterItem,
    AttributeFieldSelectItem,
  },
  props: {
    disableAllFields: {
      type: Boolean,
      required: false,
      default: false,
    },
    enforceFieldSecurity: {
      type: Boolean,
      required: false,
      default: false,
    },
    fieldWidth: {
      type: Number,
      required: false,
      default: null,
    },
    fields: {
      type: Array,
      required: true,
    },
    fixedSize: {
      type: Boolean,
      required: false,
      default: false,
    },
    forPrompt: {
      type: Boolean,
      required: false,
      default: false,
    },
    hiddenColumnIDs: {
      type: Array,
      required: false,
      default: () => [],
    },
    isInDocumentView: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectorOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSubmit: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCancelButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    useMultiSelectLists: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      disableSubmit: false,
      filterFieldsList: [],
      indexFilters: [],
      localFields: [],
      requiredValues: [],
      retrievedFilterFields: 0,
      runningFilters: [],
      selectedFieldsForPdf: [],
      tempIndex: [],
    }
  },
  computed: {
    calculatedLoaderColor() { return this.disableSubmit ? loaderColor : 'white' },
    indexObjectID() { return this.$store.state.common.indexFieldsObjectID },
    initialSelectedFieldsForPdf() { return this.localFields.map((field) => field.name) },
  },
  watch: {
    fields: function () {
      this.filterFields()
    },
    hiddenColumnIDs: function() {
      this.filterFields()
    },
    isInDocumentView: function () {
      this.filterFields()
    },
    localFields: function (newLocalFields) {
      this.$store.dispatch('document/filteredFieldOptions', newLocalFields)
    }
  },
  async created() {
    this.filterFields()

    //IF there are no required fields, don't disable Submit button
    //ELSE, handleRequiredValues will take care of when to enable Submit button
    this.fields.forEach(value => {
      if (value.required)
        this.disableSubmit = true
    })
  },
  methods: {
    clearIndexFields(){
      //This method is only called by the documentObjectModal component
      this.indexFilters =[]
    },
    filterFields() {
      // copy the fields prop to the local variable so we can manipulate it as needed
      this.localFields = JSON.parse(JSON.stringify(this.fields)).filter(f => {
        if (this.hiddenColumnIDs.includes(f.value)) {
           return false;
        }

        if (this.enforceFieldSecurity && f.hidden) {
          const canShow = f.filterValue ? this.$store.getters['common/canViewHiddenFieldsWithValue'] : this.$store.getters['common/canViewHiddenFieldsWithoutValue']
          if (!canShow) {
            return false
          }
        }

        if (f.value === 'Workflow_Status' || f.value === 'Workflow_Approver' || f.value === 'Workflow_Due_Date' || f.value === 'Workflow_Initiator' || f.value === 'CreatedBy') {
          return false
        }

        if (f.filterValue === undefined) {
          // make sure the filterValue property is present for binding purposes
          f.filterValue = ''
        }

        return true
      })
    },
    handleRequiredValues(value) {
      this.disableSubmit = false
      const index = this.requiredValues.findIndex(v => v.name === value.name)
      if (index === -1) {
        this.requiredValues.push(value)
      } else {
        this.requiredValues[index] = value
      }
      this.requiredValues.forEach(e => {
        if (e.empty) {
          this.disableSubmit = true
        }
      })
    },
    markClean() {
      this.$emit('clean')
    },
    markDirty() {
      this.runningFilters = []
      this.$emit('dirty')
    },
    markIsValid(field) {
      if (field) {
        this.$emit('isValid', field)
        return
      }
      this.$emit('isValid')
    },
    markNotValid(field) {
      if (field) {
        this.$emit('notValid', field)
        return
      }
      this.$emit('notValid')
    },
    submitCancelled() {
      this.$emit('submitCancelled')
    },
    // eslint-disable-next-line no-unused-vars
    submitIndexFields: function (e) {
      if (!this.forPrompt) {
        if (this.selectorOnly) {
          this.$store.dispatch('reporting/setSelectedIndexFieldsForExtract', this.selectedFieldsForPdf)
        } else {
          this.$store.dispatch('reporting/setIndexFieldFilters', this.indexFilters)
        }
      }
      this.$emit('submitted')
    },
    updateFieldsFromLookup(lookupResults) {
      if (!lookupResults || !lookupResults.Rows || !lookupResults.Columns || lookupResults.Rows.length === 0) {
        return
      }
      lookupResults.Columns.map((columnName, index) => {
        if (!columnName.startsWith('hidden_')) {
          return
        }

        const fieldName = columnName.substring('hidden_'.length)
        const fieldIndex = this.localFields.findIndex(element => element.value === fieldName)
        if (fieldIndex < 0) {
          return
        }

        let clonedLocalFields = cloneDeep(this.localFields)
        if (clonedLocalFields[fieldIndex].type == 2 || clonedLocalFields[fieldIndex].type == 9 || clonedLocalFields[fieldIndex].type == 10) {
          clonedLocalFields[fieldIndex].filterValue = lookupResults.Rows[0][index].split(";")
        }
        else {
          clonedLocalFields[fieldIndex].filterValue = lookupResults.Rows[0][index]
        }
        this.localFields = clonedLocalFields
        this.markDirty();

        return
      })
    },
    async updateFilterList(attributefilter) {
      if (this.runningFilters.includes(attributefilter.name)) return
      this.runningFilters.push(attributefilter.name)
      const index = this.indexFilters.findIndex((f) => f.name === attributefilter.name)

      if (this.retrievedFilterFields != this.indexObjectID) {   
        this.filterFieldsList = await this.$store.dispatch('document/hasFilterFields', this.indexObjectID)    
        this.retrievedFilterFields = this.indexObjectID
      }

      // If we found the filter, remove it
      if (index > -1) {
        this.indexFilters.splice(index, 1)
      }

      switch (attributefilter.type) {
        case 2: { // Dropdown
          if (attributefilter.value || attributefilter.value === '') {
            if (Array.isArray(attributefilter.value)) {
              attributefilter.value = attributefilter.value.filter((a) => a !== '')
            }

            if (attributefilter.value || attributefilter.value === '') {
              this.indexFilters.push({ name: attributefilter.name, value: attributefilter.value })
            }
          }
          this.localFields.forEach(field => {
            if (field.type === 2 && field.value === attributefilter.name) {
              if(Array.isArray(attributefilter.value))
                field.filterValue = attributefilter.value.map(val => val.name ? val.name : val)
              else
                field.filterValue = attributefilter.value
              return
            }
          })

          // Grab current dropdowns that have values selected and prep them for the document/getFilterFields endpoint
          let fieldNames = []
          let fieldValues = [] // This is the main value used for the endpoint
          this.indexFilters?.forEach(item => {
            if (Array.isArray(item.value)) {
              let values = item.value.map(val => (val.name) ? val.name : val)
              fieldValues.push({
                Key: item.name,
                Value: values.join(';'),
              })
            }
            else
            {
              fieldValues.push({
                Key: item.name,
                Value: item.value,
              })
            }
          })
          let currentFieldValueKeys = fieldValues.map(item => item.Key)
          this.localFields.forEach(field => {
            if (!this.filterFieldsList.includes(field.value)) return
            if (!currentFieldValueKeys.includes(field.value) && field.type === 2) {
              fieldValues.push({
                Key: field.value,
                Value: '',
              })
            }
            if (field.value === attributefilter.name || field.type !== 2) return
            fieldNames.push(field.value)
          })

          while (fieldNames.length > 0) {
            let newFieldValues = []
            fieldValues.forEach(value => {
              if (value.Key !== fieldNames[0]) newFieldValues.push(value)
            })
            let filterPayload = {
              IndexFormID: this.indexObjectID,
              FilterFieldName: fieldNames[0],
              BaseFieldValues: {
                Values: newFieldValues,
              },
            }

            let filterResult = await this.$store.dispatch('document/getFilterFields', filterPayload)
            if (filterResult.FieldValues.length > 0) {
              let newVals = []
              let filterValCount = 0
              filterResult.FieldValues.forEach(val => { // Prep values to be able to update dropdown values
                newVals.push({
                  name: val,
                  value: filterValCount,
                })
                filterValCount++
              })
              this.localFields.forEach(field => {
                if (field.type === 2 && field.value === fieldNames[0]) { // Check if field is the correct dropdown field
                  if (!isEqual(newVals, field.dropdownValues)) {
                    field.dropdownValues = newVals
                  }
                  if (newVals.length === 1) {
                    this.$refs[field.value][0].selectedItem = newVals
                    this.$refs[field.value][0].populateMultiSelectDropList()
                  }
                }
                return
              })
            }
            fieldNames.shift()
          }

          break;
        }
        case 11: // Currency
        {
          const field = this.localFields.find(element => element.value === attributefilter.name)

          // Special logic to handle negative sign
          const noNegatives = attributefilter.value.replaceAll('-', '')
          const lengthDiff = attributefilter.value.length - noNegatives.length
          if (lengthDiff === 1) {
            attributefilter.value = "-" + noNegatives
          } else {
            attributefilter.value = noNegatives
          }

          const currencyValue = unformatCurrency(attributefilter.value, field.currencyFormat)
          this.indexFilters.push({ name: attributefilter.name, value: currencyValue })
          break;
        }
        case 1: // Text
        case 3: // Date
        case 6: // Number
        case 7: // URL
        case 8: // Email
        case 9: // Checkboxes
        case 10: // RadioButtons
        case 12: // Sig Email
        case 13: // Text Area
        case 14: // Address
          this.indexFilters.push({ name: attributefilter.name, value: attributefilter.value })
          break;
        default:
          break;
      }

      this.$emit('fieldValueUpdated', this.indexFilters)
    },
    updateSelectedFieldList: function (value) {
      if (!this.selectedFieldsForPdf || this.selectedFieldsForPdf.length === 0) {
        this.selectedFieldsForPdf = this.initialSelectedFieldsForPdf
      }

      const index = this.selectedFieldsForPdf.findIndex((field) => field === value)
      if (index > -1) {
        this.selectedFieldsForPdf.splice(index, 1)
      } else {
        this.selectedFieldsForPdf.push(value)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.thing-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-between;
}

.vasion-table-head-label {
  font-size: large;
}

@media(max-width: $tablet){
  .thing-wrap{
    flex-direction: column;
  }
}

.index-field-list-100 {
  width: 100%;
  padding: 0 0 0 0 ;
}
.wrap {
  height: 100%;
  width: 100%;
}

#submit-btns {
  margin: 20px auto;
  float: right;
}
</style>
