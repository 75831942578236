import { formatDateTime } from '@/store/helperModules/common.module'


const buildTableData = (dataValues) => {
  const data = !dataValues.jobList ? [] : dataValues.jobList.map((job) => {
    return {
      Values: [
        job.schedulerID,
        job.name,
        job.schedulerTypeName,
        job.performType,
        formatDateTime(job.createdDate, 'date'),
        formatDateTime(job.runTimeString, 'time'),
        formatDateTime(job.lastRunTime, 'datetime'),
        'VasionEllipsisIcon',
      ],
      ValueType: 'String',
    }
  })
  const tableData = {
    Columns: dataValues.columns,
    Rows: {
      Values: data,
      ValueType: 'ApiArray`1',
    },
  }
  return tableData
}

export {
  buildTableData,
}
