<template>
  <div id="help-menu" v-click-outside="loseFocus">
    <ul id="help-ul">
      <li id="help-documentation" @click.prevent="openHelpPageInNewTab">
        <VasionMenuHelpIcon /><span>Help and Documentation</span>
      </li>
      <li id="contact-support" @click.prevent="openSupportRequestInNewTab">
        <VasionMenuSupportIcon /><span>Contact Support</span>
      </li>
      <hr/>
      <li id="privacy-policy" @click.prevent="openPrivacyPolicyInNewTab">
        <VasionMenuPrivacyIcon /><span>Privacy Policy</span>
      </li>
      <li id="terms" @click.prevent="openTermsInNewTab">
        <VasionMenuTermsIcon /><div><span>Terms</span><span class="subheading">Master Software Agreement</span></div>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'TheHelpMenu',
  components: {
  },
  data: function () {
    return {
    }
  },
  computed: {
  },
  methods: {
    loseFocus() {
      this.$emit('loseFocus')
    },
    openHelpPageInNewTab() {
      window.open('https://docs.vasion.com/ap/Default.htm', '_blank', 'noopener noreferrer')
    },
    openSupportRequestInNewTab() {
      window.open('https://www.printerlogic.com/support-request/', '_blank', 'noopener noreferrer')
    },
    openPrivacyPolicyInNewTab() {
      window.open('https://vasion.com/privacy-policy/', '_blank', 'noopener noreferrer')
    },
    openTermsInNewTab() {
      window.open('https://info.printerlogic.com/rs/338-HTA-134/images/Master_Software_Agreement_MSA_EN.pdf?version=0', '_blank', 'noopener noreferrer')
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';

  #help-menu {
    @include Text;
    background-color: $white;
    width: 274px;
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    transform-origin: left bottom;

    & > ul {
      list-style: none;
      padding: 8px 8px;
      margin: 0;

      & > li {
        padding: 8px 8px;
        cursor: pointer;
        height: 48px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 0;
        text-align: left;

        span {
          display: inline-block;
          vertical-align: middle;
          margin-left: 16px;
        }

        svg {
           width: 24px;
        }

        .subheading {
          display: block;
          clear: both;
          font-size: 12px;
          margin-left: 16px;
        }
      }

      & li:hover {
        background-color: rgba(44, 55, 66, 0.12);
      }

      & hr {
        margin: 8px -8px;
        border-color: $outline-variant;
      }
    }
  }

  svg {
    fill: black;
  }
</style>
