import { formatDateTime } from './common.module'

function downloadFile(response, filePrefix, mimeType, extension) {
  if (response?.data) {
    // Stream the file by downloading it through the browser
    // This process creates a new link on the document which contains the URL of the blob;
    // it then executes a click on the link
    const blob = new Blob([response.data], { type: mimeType })
    const anchor = document.createElement('a')
    anchor.download = `${filePrefix}.${extension}`
    anchor.href = window.URL.createObjectURL(blob)
    anchor.click()
  }
}

// I know this is a primitive function that could be improved to accept any number of elements
// eslint-disable-next-line consistent-return
const buildGenericTableData = function buildGenericTableData(response) {
  if (response) {
    if (response.Columns.Values.includes("UserName")){
      const index = response.Columns.Values.indexOf("UserName")
      response.Columns.Values[index] = "Username"
    }
    const dateIndex = response.Columns.Values.indexOf("Date")
    const startDateIndex = response.Columns.Values.indexOf("Start Date")
    const endDateIndex = response.Columns.Values.indexOf("End Date")
    const headers = response.Columns.Values
    const values = response.Rows.Values
    values.forEach(value => {
      if (dateIndex > -1) {
         value.Values[dateIndex] = formatDateTime(value.Values[dateIndex], 'date')
      }
      if (startDateIndex > -1) {
        value.Values[startDateIndex] = formatDateTime(value.Values[startDateIndex], 'datetime')
      }
      if (endDateIndex > -1) { 
        value.Values[endDateIndex] = formatDateTime(value.Values[endDateIndex], 'datetime')
      }
    });
    return { tableHeaders: headers, tableRows: values }
  }
}

// eslint-disable-next-line consistent-return
const buildTableData = function buildTableData(response) {
  if (response) {
    const headers = response.Columns.Values
    const values = response.Rows.Values
    const returnArr = []
    // eslint-disable-next-line array-callback-return
    values.map((a) => {
      const one = a.Values[0]
      const two = a.Values[1]
      const three = a.Values[2]
      const four = a.Values[3]
      const b = {
        [headers[0].replace(/\s/g, '')]: one,
        [headers[1].replace(/\s/g, '')]: two,
        [headers[2].replace(/\s/g, '')]: three,
        [headers[3].replace(/\s/g, '')]: four,
      }
      returnArr.push(b)
    })
    return returnArr
  }
}

// eslint-disable-next-line consistent-return
const buildWorkflowTableData = function buildWorkflowTableData(response) {
  if (response) {
    const headers = response.Columns.Values
    const values = response.Rows.Values
    const returnArr = []
    // eslint-disable-next-line array-callback-return
    values.map((a) => {
      const one = a.Values[0]
      const two = formatDateTime(a.Values[1], 'datetime')
      a.Values[1] = two
      const three = a.Values[2]
      const four = a.Values[3]
      const five = a.Values[3]
      const b = {
        [headers[0].replace(/\s/g, '')]: one,
        [headers[1].replace(/\s/g, '')]: two,
        [headers[2].replace(/\s/g, '')]: three,
        [headers[3].replace(/\s/g, '')]: four,
        [headers[4].replace(/\s/g, '')]: five,
        Values: a.Values,
        ValueType: a.ValueType,
      }
      returnArr.push(b)
    })
    return { tableHeaders: headers, tableRows: returnArr }
  }
}

const downloadCSV = function downloadCSV(response, filePrefix) {
  downloadFile(response, filePrefix, 'text/csv', 'csv')
}

const downloadPDF = function downloadPDF(response, filePrefix) {
  downloadFile(response, filePrefix, 'application/pdf', 'pdf')
}

const getToken = function (token) {
  return token || localStorage.getItem('UserToken')
}

// eslint-disable-next-line consistent-return
const streamPDF = function streamPDF(response) {
  if (response?.data) {
    // stream the pdf in a new browser window\tab
    const file = new Blob([response.data], { type: 'application/pdf' })
    const fileURL = URL.createObjectURL(file)
    window.open(fileURL)
  }
}

export {
  buildGenericTableData,
  buildTableData,
  buildWorkflowTableData,
  downloadCSV,
  downloadPDF,
  getToken,
  streamPDF,
}
