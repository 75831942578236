<template>
  <div class="dialog-div" :style="dialogMessageStyles">
    <div id="dialog-message" ref="dialogMessage">
      {{ message }}
    </div>
    <div class="button-div">
      <VasionButton v-if="showNoButton" id="btnNewFTSearch" :classProp="'secondary'" @vasionButtonClicked="noButtonClick()">
        {{ noButtonText }}
      </VasionButton>
      <VasionButton id="btnNewIndexSearch" :classProp="'primary'" @vasionButtonClicked="yesButtonClick()">
        {{ yesButtonText }}
      </VasionButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VasionConfirmationDialog',
  props: {
    message: {
      type: String,
      default: '',
      required: true,
    },
    noButtonText: {
      type: String,
      default: 'No',
      required: false,
    },
    showNoButton:{
      type: Boolean,
      default: true,
      required: false,
    },
    yesButtonText: {
      type: String,
      default: 'Yes',
      required: false,
    },
  },
  data: function () {
    return {
      dialogMessageStyles: { },
    }
  },
  mounted() {
    this.updateDialogHeight()
  },
  methods: {
    noButtonClick() {
      this.$emit('noButtonClick')
    },
    updateDialogHeight() {
      const height = this.$refs.dialogMessage.clientHeight + 100
      this.dialogMessageStyles = { height: `${height}px` }
    },
    yesButtonClick() {
      this.$emit('yesButtonClick')
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .dialog-div {
    text-align: center;
    padding: 15px 10px 0px 10px;
    height: 120px;
    width: 450px;
  }

  .button-div {
    position: relative;
    left: 50%;
    top: 42%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
  }

</style>
