<template>
  <div class="vasion-side-rail-and-menu" :class="isMobile ? ['fullwidth','fullheight'] : ''">
    <div class="vasion-side-rail" :class="{'fullwidth': isMobile}">
      <div class="vasion-logo-container" @click="changeActiveSubApp('home')">
        <VasionSideRailLogo class="logo" v-if="!isMobile"/>
      </div>
      <div v-if="showWorkspaceApp" class="icon-container" :class="{'active': activeSubApp=='workspace', 'icon-container-mobile': isMobile}" @click="changeActiveSubApp('workspace', true)">
        <VasionSideRailWorkspaceIcon class="icon" :class="{'icon-margin': isMobile}" />
        <span :class="isMobile ? 'icon-text-mobile' : ['icon-text', 'small-icon-text']">Workspace</span>
      </div>
      <div v-if="showReportsApp" class="icon-container" :class="{'active': activeSubApp=='reports', 'icon-container-mobile': isMobile}" @click="changeActiveSubApp('reports', true)">
        <VasionSideRailReportingIcon class="icon" :class="{'icon-margin': isMobile}" />
        <span :class="isMobile ? 'icon-text-mobile' : 'icon-text'">Reports</span>
      </div>
      <div v-if="showStorageApp" class="icon-container" :class="{'active': activeSubApp=='storage', 'icon-container-mobile': isMobile}" @click="changeActiveSubApp('storage', true)">
        <VasionSideRailStorageIcon class="icon" :class="{'icon-margin': isMobile}" />
        <span :class="isMobile ? 'icon-text-mobile' : 'icon-text'">Storage</span>
      </div>
      <div v-if="showCaptureApp" class="icon-container" :class="{'active': activeSubApp=='capture', 'icon-container-mobile': isMobile}" @click="changeActiveSubApp('capture', true)">
        <VasionSideRailCaptureIcon class="icon" :class="{'icon-margin': isMobile}" />
        <span :class="isMobile ? 'icon-text-mobile' : 'icon-text'">Capture</span>
      </div>
      <div v-if="showFormsApp" class="icon-container" :class="{'active': activeSubApp=='forms', 'icon-container-mobile': isMobile}" @click="changeActiveSubApp('forms', true)">
        <VasionSideRailFormsIcon class="icon" :class="{'icon-margin': isMobile}" />
        <span :class="isMobile ? 'icon-text-mobile' : 'icon-text'">Forms</span>
      </div>
      <div v-if="showSignatureApp" class="icon-container" :class="{'active': activeSubApp=='signature', 'icon-container-mobile': isMobile}" @click="changeActiveSubApp('signature', true)">
        <VasionSideRailSignatureIcon class="icon" :class="{'icon-margin': isMobile}" />
        <span :class="isMobile ? 'icon-text-mobile' : 'icon-text'">Signature</span>
      </div>
      <div v-if="showWorkflowApp" class="icon-container" :class="{'active': activeSubApp=='workflow', 'icon-container-mobile': isMobile}" @click="changeActiveSubApp('workflow', true)">
        <VasionSideRailWorkflowIcon class="icon" :class="{'icon-margin': isMobile}" />
        <span :class="isMobile ? 'icon-text-mobile' : 'icon-text'">Workflow</span>
      </div>
      <!--div v-if="showPrintApp" class="icon-container" :class="{'active': activeSubApp=='print', 'icon-container-mobile': isMobile}" @click="changeActiveSubApp('print', true)">
        <VasionSideRailPrintIcon class="icon" :class="{'icon-margin': isMobile}" />
        <span :class="isMobile ? 'icon-text-mobile' : 'icon-text'">Print</span>
      </div>
      <div v-if="showOutputManagementApp" class="icon-container icon-container-large-size" :class="{'active': activeSubApp=='output-management', 'icon-container-mobile': isMobile}" @click="changeActiveSubApp('output-management', true)">
        <VasionSideRailOutputManagementIcon class="icon" :class="{'icon-margin': isMobile}" />
        <span :class="isMobile ? 'icon-text-mobile' : 'icon-text'">Output<br/>Mgmt</span>
      </div-->
      <div v-if="showTemplatesApp" class="icon-container" :class="{'active': activeSubApp=='templates', 'icon-container-mobile': isMobile}" @click="changeActiveSubApp('templates', true)">
        <VasionSideRailTemplatesIcon class="icon" :class="{'icon-margin': isMobile}" />
        <span :class="isMobile ? 'icon-text-mobile' : 'icon-text'">Templates</span>
      </div>
      <div v-if="showAutomationApp" class="icon-container" :class="{'active': activeSubApp=='automation', 'icon-container-mobile': isMobile}" @click="changeActiveSubApp('automation', true)">
        <VasionSideRailAutomationIcon class="icon" :class="{'icon-margin': isMobile}" />
        <span :class="isMobile ? 'icon-text-mobile' : ['icon-text', 'small-icon-text']">Automation</span>
      </div>
      <div v-if="showAdminApp" class="icon-container" :class="{'active': activeSubApp=='admin', 'icon-container-mobile': isMobile}" @click="changeActiveSubApp('admin', true)">
        <VasionSideRailAdminIcon class="icon" :class="{'icon-margin': isMobile}" />
        <span :class="isMobile ? 'icon-text-mobile' : 'icon-text'">Admin</span>
      </div>
      <div v-if="showMoreIcon" class="icon-container" :class="{'active': activeSubApp=='more', 'icon-container-mobile': isMobile}" @click="toggleMenu()">
        <VasionSideRailEllipsisIcon class="icon icon-more" :class="{'icon-margin': isMobile}" />
        <span :class="isMobile ? 'icon-text-mobile' : 'icon-text'">More</span>
      </div>
      <div class="side-rail-bottom">
        <div class="icon-container" :class="{'help': activeSubApp=='help', 'icon-container-mobile': isMobile}" @click="toggleHelpMenu()">
          <VasionSideRailHelpIcon class="icon" :class="{'icon-margin': isMobile}" />
          <span :class="isMobile ? 'icon-text-mobile' : 'icon-text'">Help</span>
          <TheHelpMenu 
            class="side-rail-menu contentsize-menu"
            v-if="menuHelpVisible"
            @loseFocus="toggleHelpMenu"
          />
        </div>
        <div class="avatar-container" :class="{'avatar': activeSubApp=='avatar', 'avatar-container-mobile': isMobile}" @click="toggleProfileMenu()">
          <VasionSideRailAvatarIcon class="avatar" />
          <span class="avatar-text" :class="isMobile ? 'avatar-text-mobile' : []">{{ avatarText}}</span>
          <TheProfileMenu
            class="side-rail-menu contentsize-menu"
            v-if="menuProfileVisible"
            @loseFocus="toggleProfileMenu"
            @openUserSettings="toggleProfileMenu(); changeActiveSubApp('profile', true)"
          />
        </div>
      </div>
    </div>
    <div id="menu" v-if="menuVisible" class="side-rail-menu fullsize-menu" v-click-outside="toggleMenu">
      <span class="menu-text">More</span>
      <VasionClear id="clear-icon" @click="toggleMenu()" />
      <ul class="menu-list">
        <li
          v-for="(subapp, index) in iconOrder.slice(this.iconsVisible)"
          :key="index"
          @click="changeActiveSubApp(subapp, true); toggleMenu();"
        >
          <span>{{ subapp.charAt(0).toUpperCase() + subapp.slice(1) }}</span>
        </li>  
      </ul>
    </div>
  </div>
</template>

<script>

import TheHelpMenu from '@/components/mainViews/TheHelpMenu.vue'
import TheProfileMenu from '@/components/mainViews/TheProfileMenu.vue'

export default {
  name: 'SideRail',
  props: {
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    TheHelpMenu,
    TheProfileMenu,
  },
  data: function () {
    return {
      switchToSubApp: '',
      menuHelpVisible: false,
      menuProfileVisible: false,
      menuVisible: false,
      iconOrder: ['workspace', 'reports', 'storage', 'capture', 'forms', 'signature', 'workflow', 'templates', 'automation', 'admin'],
      iconsVisible: 12,
    }
  },
  computed: {
    activeSubApp() { return this.$store.state.mainViews.activeSubApp.toLowerCase() },
    activeSubAppSet() { return this.$store.state.mainViews.activeSubApp !== '' },
    avatarText() { 
      if (this.$store.state.common.firstName && this.$store.state.common.lastName)
      {
        return (Array.from(this.$store.state.common.firstName)[0] + Array.from(this.$store.state.common.lastName)[0]).toUpperCase()
      }
      if (this.$store.state.common.firstName) {
        return this.$store.state.common.firstName.substring(0, 2).toUpperCase()
      }
      return this.$store.state.common.userName.substring(0, 2).toUpperCase()
    },
    canAccessApps() {
      return !this.$store.state.common.appAccess
        || this.$store.state.common.appAccess.length === 0
        || this.$store.state.common.appAccess.length > 1
        || (this.$store.state.common.appAccess.length === 1 && !this.canAccessSearch)
    },
    defaultAppLocalSelection() { return this.$store.state.admin.defaultAppLocalSelection },
    fullProfilePic() {
      return ''
    },
    hasAccessToAllForms() { return this.$store.getters['common/canAccessFormsApp_RolesOnly'] },
    hasAccessToMyForms() { return this.$store.getters['common/APP_ACCESS_FORMS'] },
    isAdminUser() { return this.$store.getters['common/isAdminUser'] },
    isSignedIn() { return this.$store.state.common.isSignedIn },
    showAdminApp() { return this.showIcon(this.$store.getters['common/showAdminApp'], 'admin') },
    showAutomationApp() { return this.showIcon(this.$store.getters['common/APP_ACCESS_AUTOMATION'] && 
       (this.$store.state.common.userHasSchedulers || this.$store.getters['common/canAccessAutomationApp_RolesOnly']), 'automation')
    },
    showCaptureApp() { return this.showIcon(this.$store.getters['common/showCaptureApp'], 'capture') },
    showFormsApp() { return this.showIcon(this.$store.getters['common/APP_ACCESS_FORMS'] && (this.$store.state.common.userHasForms || this.$store.getters['common/canAccessFormsApp_RolesOnly']), 'forms') },
    showMoreIcon() { return this.iconOrder.length > (this.iconsVisible + 1) },
    showOutputManagementApp() { return this.showIcon(false, 'output-management') },
    showPrintApp() { return this.showIcon(false, 'print') },
    showReportsApp() { return this.showIcon(this.$store.getters['common/showReportsApp'], 'reports') },
    showSignatureApp() { return this.showIcon(this.$store.getters['common/showSignatureApp'], 'signature') },
    showStorageApp() { return this.showIcon(this.$store.getters['common/showStorageApp'], 'storage') },
    showTemplatesApp() { return this.showIcon(this.$store.getters['common/showTemplatesApp'], 'templates') },
    showWorkflowApp() { return this.showIcon(this.$store.getters['common/showWorkflowApp'], 'workflow') },
    showWorkspaceApp() { return this.showIcon(this.$store.getters['common/showWorkspaceApp'], 'workspace') },
    userHasForms() { return this.$store.state.attributeForm.myForms.length > 0 },
  },
  watch:{
    $route () {
      if (this.switchToSubApp !== '') this.$store.dispatch('mainViews/changeActiveSubApp', this.switchToSubApp)
      this.switchToSubApp = ''
    }
  }, 
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
      this.onResize()
    })
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  methods: {
    changeActiveSubApp(appName, userSelected) {
      let formsRouteName = ''
      if (this.hasAccessToAllForms) {
        formsRouteName = 'AllForms'
      } else if (this.hasAccessToMyForms && !this.hasAccessToAllForms) {
        formsRouteName = 'MyForms'
      }
      if (userSelected) this.$emit('appSelected')
      this.switchToSubApp = appName
      switch (appName) {
        case 'home':
          this.$router.push({ name: 'TheVasionHome' })
          break
        case 'admin':
          this.$router.push({ name: 'TheEmptyAdmin' })
          break
        case 'capture':
          this.$router.push({ name: 'StartScan' })
          break
        case 'signature':
          this.$router.push({ name: 'TheSignatureInbox' })
          this.$store.dispatch('mainViews/changeMainNav', 'signatureInbox')
          break
        case 'reports':
          this.$router.push({ name: 'TheEmptyReport' })
          break
        case 'storage':
          this.$router.push({ name: 'VaultFolders' })
          break
        case 'workspace':
          this.$router.push({ name: 'TheWorkspaceHome' })
          this.$store.dispatch('mainViews/changeMainNav', 'my-tasks-pending')
          break;
        case 'forms':
          this.$router.push({ name: formsRouteName })
          break;
        case 'templates':
          this.$store.dispatch('common/getEmailTemplates')
          this.$router.push({ name: 'AllEmailTemplates' })
          this.$store.dispatch('mainViews/changeMainNav', 'all-emails')
          break;
        case 'automation':
          this.$router.push({ name: 'TheAutomationHome' })
          break;
        case 'workflow':
          this.$store.dispatch('workflow/selectWorkflow', 0)
          this.$router.push({ name: 'AllWorkflows' })
          this.$store.dispatch('mainViews/changeMainNav', 'workflow-designer')
          break;
        case 'print':
        case 'output-management':
          /* To be implemented */
          break;
        case 'profile':
          this.$router.push({ name: 'ProfileSettings' })
          // this.$store.dispatch('mainViews/changeMainNav', 'user-settings')
          break
        default:
          this.$router.push({ name: 'TheWorkspaceHome' })
          this.$store.dispatch('mainViews/changeMainNav', 'my-tasks-pending')
          break
      }
    },
    toggleMenu() {
      if (!this.menuVisible) {
        this.menuVisible = true
        return
      }
      document.querySelector('#menu').classList.remove("side-rail-menu")
      document.querySelector('#menu').classList.remove("fullsize-menu")
      document.querySelector('#menu').classList.add("side-rail-menu-close")
      document.querySelector('#menu').classList.add("fullsize-menu-close")
      setTimeout(() => {
        this.menuVisible = false
      }, 200)
    },
    toggleHelpMenu() {
      if (!this.menuHelpVisible) {
        this.menuHelpVisible = true
        return
      }
      document.querySelector('#help-menu').classList.remove("side-rail-menu")
      document.querySelector('#help-menu').classList.add("side-rail-menu-close")
      setTimeout(() => {
        this.menuHelpVisible = false
      }, 200)
    },
    toggleProfileMenu() {
      if (!this.menuProfileVisible) {
        this.menuProfileVisible = true
        return
      }
      
      if (document.querySelector('#profile-menu')?.classList.contains("side-rail-menu")) {
        document.querySelector('#profile-menu').classList.remove("side-rail-menu")
        document.querySelector('#profile-menu').classList.add("side-rail-menu-close")
      }

      setTimeout(() => {
        this.menuProfileVisible = false
      }, 200)
    },
    onResize() {
      const windowHeight = window.innerHeight - 260
      this.iconsVisible = Math.floor(windowHeight / 64)
      if (this.iconsVisible == this.iconOrder.length - 1) {
        this.iconsVisible--;
      }
    },
    openHelpPageInNewTab() {
      window.open('https://docs.vasion.com/ap/Default.htm', '_blank', 'noopener noreferrer')
    },
    showIcon(show, iconName) { 
      const ix = this.iconOrder.indexOf(iconName)
      if (ix < 0) return
      if (!show) { 
        this.iconOrder.splice(ix, 1)
      } else if (ix > this.iconsVisible - 1) {
        show = false
      }
      return show
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';

  .vasion-side-rail {
    width: 72px;
    height: 100%;
    background-color: $btn-primary-bg;
    position: block;
    z-index: 5;
    text-align: center;
    display: flex;
    flex-direction: column;

    .active {
      background-color: $btn-primary-active-bg;
      border: solid 1px $btn-primary-active-border;
      border-radius: 4px;
    }
    
    .logo {
      margin-top: 38px;
      margin-bottom: 18px;
      cursor: pointer;
    }

    .icon-container {
      margin: 4px 0px 4px 8px;
      padding-top: 8px;
      border-radius: 4px;
      font-family: Archivo; 
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      color: $white;
      height: 56px;
      min-height: 56px;
      width: 56px;
      text-align: center;
      cursor: pointer;

      &:hover {
        background-color: $btn-primary-hover-bg;
      }

      .icon {
        display: block;
        margin: auto;
      }

      .icon-more {
        margin-top: 10px;
      }

      .icon-text {
        padding-top: 5px;
        clear: both;
        display: inline-block;
        width: 100%;
      }

      .small-icon-text {
        font-size: 10px;
      }
    }

    .icon-container-large-size
    {
      height: 72px !important;
    }

    .side-rail-bottom {
      position: relative;
      margin-bottom: 10px;
      margin-top: auto;

      .icon-container {
        position: relative;
      }

      .avatar-container {
        position: relative;
        margin-left: 8px;
        padding-top: 8px;
        border-radius: 4px;
        cursor: pointer;
        height: 56px;
        width: 56px;

        &:hover {
          background-color: $btn-primary-hover-bg;
        }

        .avatar {
          width: 40px;
          height: 40px;
        }

        .avatar-text {
          position: absolute;
          top: 18px;
          left: 0px;
          right: 0px;
          margin-left: auto;
          margin-right: auto;
          font-size: 16px;
          font-weight: bold;
          color: $btn-primary-active-bg;
          background: transparent;
        }      
      }
    }
  }

  .fullsize-menu {
    position: absolute;
    top: 0px;
    left: 72px;
    width: 256px;
    height: 100%;
    padding-top: 30px;
    z-index: 9 !important;
    animation: 0.25s 1 normal slidein;
    box-shadow: 0px 0px 12px 6px #0000003D;
  }

  .fullsize-menu-close {
    position: absolute;
    top: 0px;
    left: 72px;
    width: 256px;
    height: 100%;
    padding-top: 30px;
    animation: 0.25s 1 normal slideout;
  }

  .contentsize-menu {
    position: absolute;
    bottom: 0;
    left: 72px;
    width: 256px;
  }

  .side-rail-menu {
    animation: 0.25s 1 normal slidein;
    background-color: $surface-variant;   
    z-index: 10; 
    overflow-y: auto;
  }

  .side-rail-menu-close {
    animation: 0.25s 1 normal slideout;
    background-color: $surface;   
    z-index: 10; 
  }

  .menu-text {
    font-family: Archivo; 
    font-size: 22px;
    font-weight: 400;
    padding-left: 30px;
  }

  ul.menu-list {
    list-style-type: none;
    padding: 15px 30px;

    li {
      font-family: Archivo; 
      font-size: 14px;
      font-weight: 500;
      padding: 10px 0px;
      margin: 5px 0px;
      cursor: pointer;
    }
  }

  #clear-icon {
    background: $surface-variant;
    border-radius: 16px;
    width: 32px;
    height: 32px;
    padding: 4px;
    float: right;
    margin-top: -8px;
    margin-right: 16px;
  }

  #clear-icon:hover {
    background: $outline;
    cursor: pointer;
  }

  @keyframes slidein {
    0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }

    to {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }    
  }

  @keyframes slideout {
    0% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }

    to {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }    
  }
    
  /* Mobile Styles */
  .fullwidth {
    width: 100vw !important;
    overflow-y: auto;
  }

  .fullheight {
    height: calc(100vh - 56px);
  }

  .icon-margin {
    margin-left: 14px !important;
    margin-top: 10px !important;
    float: left;
  }

  .icon-container-mobile {
    width: calc(100vw - 32px) !important;
    margin: 4px 16px !important;
    text-align: left !important;
  }

  .icon-text-mobile {
    margin-left: 16px !important;
    line-height: 40px;
    font-size: 13px;
  }

  .avatar-container-mobile {
     margin-left: 12px !important;
  }
</style>
