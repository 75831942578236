<template>
  <div>
    <label v-if="title" class="vasion-input-label-top"> {{ title }} </label>
    <md-datepicker
      v-model="dateValue"
      :value="value"
      :md-model-type="String"
      :checkIfRequiredAndEmpty="checkIfRequiredAndEmpty"
      :style="[backgroundColor ? { 'background-color': backgroundColor } : null ]"
      :class="[cusutomClass]"
      :title="hoverText"
      @md-closed="close"
      @md-opened="fieldClicked"
    />
  </div>
</template>

<script>
export default {
  name: 'VasionDatePicker',
  props: {
    backgroundColor: {
      type: String,
      default: '',
      required: false,
    },
    checkIfRequiredAndEmpty: {
      type: Boolean,
      default: false,
      required: false,
    },
    cusutomClass: {
      type: String,
      default: '',
      required: false,
    },
    hoverText: {
      type: String,
      default: '',
      required: false,
    },
    title: {
      type: String,
      default: '',
      required: false,
    },
    value: {
      type: String,
      required: false,
      default: '',
    }
  },
  data () {
    return {
      dateValue: this.value,
    }
  },
  watch: {
    dateValue: function () {
      this.$emit('input', this.dateValue)
      if (!this.dateValue) {
        this.$emit('clearValue')
      }
    },
    value: function () {
      this.dateValue = this.value
    },
  },
  methods: {
    close() {
      this.$emit('closed')
    },
    fieldClicked() {
      this.$emit('fieldClicked', this.field)
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .md-field {
    width: 100%;
    margin: 21px 0;
    min-height: 0 !important;
    display: flex;
    position: relative;
    font-family: inherit;
    padding: 4px 12px;
    border: solid 1px $grey-200;
    border-radius: 6px;
    margin-top: 0;
    margin-bottom: 10px;

  }
  .md-field::v-deep .md-input-action {
    top: 4px;
  }
  .md-field::v-deep .md-icon {
    z-index: 1;
  }
  .md-field:after {
    display: none;
  }
  .success {
    background-color: $success-bg !important;
    border: 1px solid $success-border !important;
  }
  .warning {
    background-color: $warning-bg !important;
    border: 1px solid $warning-border !important;
  }
  .error {
    background-color: $error-bg !important;
    border: 1px solid $error-border !important;
  }
</style>
