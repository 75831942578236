<template>
  <div class="download-options-checkboxes">
    <div class="checkbox-burn-annotations">
      <VasionCheckbox
        id="burn-annotations-checkbox"
        name="burn-annotations-checkbox"
        class="download-checkbox"
        :checked="burnAnnotations"
        @change="toggleBurnAnnotations"
      >
        Burn Annotations
      </VasionCheckbox>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VasionPrintOptionsForGenericModal',
  props: {
    multipleDocuments: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: function () {
    return {
      burnAnnotations: false,
      extractPages: '',
    }
  },
  computed: {
  },
  methods: {
    toggleBurnAnnotations() {
      this.burnAnnotations = !this.burnAnnotations
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .checkbox-burn-annotations {
    width: 459px;
  }
  .download-options-checkboxes {
    margin: 14px 0px;
  }
  .download-checkbox {
    margin-bottom: 10px;
  }
</style>
