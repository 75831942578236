/* eslint-disable linebreak-style */
/* eslint-disable default-case */
/* eslint-disable consistent-return */

import axios from 'axios'
import { createAxiosInstance } from '@/services/axiosService'

const deleteAip = async (apiToken, configurationId) => {
  const axiosC = createAxiosInstance(apiToken)
  try {
    await axiosC.delete(`/v1/Admin/DeleteAIP?Value=${configurationId}`)
    return true
  } catch (error) {
    console.warn(error)
    return false
  }
}

const deleteCaptureProfile = async (apiToken, profileId) => {
  const axiosC = createAxiosInstance(apiToken)
  try {
    await axiosC.delete(`v1/Admin/DeleteCaptureProfile?Value=${profileId}`)
    return true
  } catch (error) {
    console.warn(error)
    return false
  }
}

const getAIP = async (apiToken, aipID) => {
  const axiosC = createAxiosInstance(apiToken)
  try {
    const response = await axiosC.get(`/v1/Admin/GetAIP?Value=${aipID}`)
    return response
  } catch (error) {
    console.warn(error)
    return {}
  }
}

const getAIPCompareFieldTypeList = async (apiToken) => {
  const axiosC = createAxiosInstance(apiToken)
  try {
    const { data: { Values } } = await axiosC.get('v1/Admin/GetAIPCompareFieldTypeList')
    return Values
  } catch (error) {
    console.warn(error)
  }
}

const getAllAipProcs = async (apiToken) => {
  const axiosC = createAxiosInstance(apiToken)
  try {
    const { data: { Values } } = await axiosC.post('v1/Document/GetAllAIPProcs')
    return Values
  } catch (error) {
    console.warn(error)
  }
}

const getAllBarcodeIndex = async (apiToken) => {
  const axiosC = createAxiosInstance(apiToken)
  try {
    const { data: { BIList } } = await axiosC.get('v1/Admin/GetAllBarcodeIndex')
    return BIList
  } catch (error) {
    console.warn(error)
  }
}

const getCaptureProfileList = async (apiToken) => {
  const axiosC = createAxiosInstance(apiToken)
  try {
    const { data: { Values } } = await axiosC.get('v1/Admin/GetCaptureProfileList')
    return Values
  } catch (error) {
    console.warn(error)
  }
}

const getInstaller = async () => {
  try {
    const { data } = await axios.get('https://plapi.pl-ms.com/vasion/installer')
    return data
  } catch (error) {
    console.warn(error)
  }
}

const saveAIP = async (apiToken, payload) => {
  const axiosC = createAxiosInstance(apiToken)
  try {
    const response = await axiosC.post('v1/Admin/saveAIP', payload)
    return response
  } catch (error) {
    console.warn(error)
  }
}

const uploadTempFile = async (apiToken, payload) => {
  const axiosC = createAxiosInstance(apiToken)
  try {
    const { data: { Value } } = await axiosC.post('v1/Upload/UploadTempFile', payload)
    return Value
  } catch (error) {
    console.warn(error)
  }
}

export {
  deleteAip,
  deleteCaptureProfile,
  getAIP,
  getAIPCompareFieldTypeList,
  getAllAipProcs,
  getAllBarcodeIndex,
  getCaptureProfileList,
  getInstaller,
  saveAIP,
  uploadTempFile,
}
