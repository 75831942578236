<template>
  <div class="vld-parent">
    <ul id="folderBrowser" class="vasion-ul">
      <VasionTreeItem
        ref="topLevelFolder"
        class="vasion-cursor"
        :topLevelFolder="true"
        :treeData="treeData"
        :routeToFolderOnSelection="routeToFolderOnSelection"
        :fillColor="fillColor"
        :enableContextMenu="enableContextMenu"
        :showFiles="showFiles"
        :toggleTopByDefault="toggleTopByDefault"
        :enforceUserSecurity="enforceUserSecurity"
        :storageConfigId="storageConfigId"
        :storageType="storageType"
        :extraTextLength="extraTextLength"
        :includeWFQueue="includeWFQueue"
        :disableLoader="disableLoader"
        :disableRootSelect="disableRootSelect"
        :disableUploadDocuments="disableUploadDocuments"
        @itemSelected="handleFolderSelected"
      />
    </ul>
  </div>
</template>

<script>
export default {
  name: 'VasionBrowseFolders',
  props: {
    disableLoader: {
      type: Boolean,
      default: false,
      required: false,
    },
    disableRootSelect: {
      type: Boolean,
      default: false,
      required: false,
    },
    disableUploadDocuments: {
      type: Boolean,
      default: false,
      required: false,
    },
    enableContextMenu: {
      type: Boolean,
      default: true,
      required: false,
    },
    enforceUserSecurity: {
      type: Boolean,
      default: false,
      required: false,
    },
    extraTextLength: {
      type: Boolean,
      default: false,
      required: false,
    },
    fillColor: {
      type: String,
      default: '#000000',
      required: false,
    },
    storageConfigId: {
      type: Number,
      required: true,
    },
    includeWFQueue: {
      type: Boolean,
      default: false,
      required: false,
    },
    routeToFolderOnSelection: {
      type: Boolean,
      default: false,
      required: false,
    },
    showFiles: {
      type: Boolean,
      default: false,
      required: false,
    },
    sourceName: {
      type: String,
      required: true,
    },
    storageType: {
      type: String,
      required: true,
    },
    toggleTopByDefault: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data: function () {
    return {
      treeData: {
        Children: [],
        FolderId: 0,
        HasChildren: true,
        Name: this.sourceName,
        ThirdPartyStorageType: this.storageType,
      },
    }
  },
  methods: {
    handleFolderSelected(selectedData) {
      if (this.disableRootSelect && selectedData.value === 0) return
      this.$emit('itemSelected', selectedData)
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
