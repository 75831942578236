import {
  deleteDatabaseConnection,
  deleteDatabaseLookup,
  getDatabaseConnections,
  getDatabaseLookupDetails,
  getDatabaseLookups,
  getDefaultLookupId,
  getSalesForceFields,
  getSalesForceLookupDetails,
  saveDatabaseConnection,
  saveDatabaseLookup,
  saveSalesForceLookup,
  testDatabaseConnection,
 } from '@/store/apiModules/fieldMapping.api'

function initialState() {
  return {
    activeDatabaseLookup: {},
    connectionList: [],
    databaseConnectionList: [],
    lookupList: [],
  }
}

const fieldMapping = {
  namespaced: true,
  state: initialState,
  mutations: {
    resetStoreData(state) {
      const localState = initialState()
      Object.keys(state).forEach((prop) => {
        state[prop] = localState[prop]
      })
    },
    setActiveDatabaseLookup(state, value) { state.activeDatabaseLookup = value },
    setConnectionList(state, value) { state.databaseConnectionList = value },
    setLookupList(state, value) { state.lookupList = value },
  },
  actions: {
    async deleteDatabaseConnection({ rootState }, connectionId) { return deleteDatabaseConnection(rootState.common.apiToken, connectionId) },
    async deleteDatabaseLookup({ rootState }, lookupId) { return deleteDatabaseLookup(rootState.common.apiToken, lookupId) },
    async getDatabaseConnections({ rootState, commit }) {
      const connections = await getDatabaseConnections(rootState.common.apiToken)
      commit('setConnectionList', connections)
    },
    async getDatabaseLookupDetails({ rootState, commit }, lookupId) {
      const lookup = await getDatabaseLookupDetails(rootState.common.apiToken, lookupId)
      commit('setActiveDatabaseLookup', lookup)
    },
    async getDefaultLookupId({ rootState }, attributeFormId) {
      return getDefaultLookupId(rootState.common.apiToken, attributeFormId)
    },
    async getDatabaseLookups({ rootState, commit }, includeSFLookups) {
      const lookups = await getDatabaseLookups(rootState.common.apiToken, includeSFLookups)
      commit('setLookupList', lookups)
    },
    async getSalesForceFields({rootState}, salesForceObject) {
      return await getSalesForceFields(rootState.common.apiToken, salesForceObject)
    },
    async getSalesForceLookupDetails({ rootState }, lookupId) {
      return await getSalesForceLookupDetails(rootState.common.apiToken, lookupId)
    },
    async saveDatabaseConnection({ rootState }, payload) {
      const response = await saveDatabaseConnection(rootState.common.apiToken, payload)

      if (!response) {
        console.warn('Unable to save database connection')
        return false
      }

      return true
    },
    async saveDatabaseLookup({ rootState }, payload) {
      const response = await saveDatabaseLookup(rootState.common.apiToken, payload)

      if (!response) {
        console.warn('Unable to save database lookup')
        return false
      }

      return true
    },
    async saveSalesForceLookup({ rootState }, payload) {
      const response = await saveSalesForceLookup(rootState.common.apiToken, payload)

      if (!response) {
        console.warn('Unable to save SalesForce lookup')
        return false
      }

      return true
    },
    async testDatabaseConnection({ rootState }, payload) {
      const response = await testDatabaseConnection(rootState.common.apiToken, payload)

      if (!response) {
        console.warn('Unable to save database lookup')
        return false
      }

      return true
    },
  },
}

export default fieldMapping;
