<template>
  <div v-if="isValid" :class="combinedClasses" :style="{width: width }">
    <label :for="generatedId">{{ (required ? "*" : "") + label }}</label>
    <div v-if="type === 'date'">
      <input
        v-if="isDisabled"
        :id="generatedId"
        v-model="displayValue"
        class="disabled"
        :type="type"
        :disabled="true"
        :title="hoverText"
        @input="updateValue"
      />
      <md-datepicker
        v-else
        :id="generatedId"
        v-model="displayValue"
        :required="required"
        :md-model-type="String"
        :md-disabled-dates="disabledDates"
        :style="[backgroundColor ? { 'background-color': backgroundColor } : null ]"
        :title="hoverText"
        @md-opened="onOpen"
        @md-closed="onClose"
        @input="updateValue"
      ></md-datepicker>
    </div>
    <div v-else-if="type === 'time' || type === 'datetime'" class="input-time-container">
      <input
        v-model="inputValue"
        :id="generatedId"
        :class="isDisabled ? 'disabled' : ''"
        :type="type === 'time' ? 'time' : 'datetime-local'"
        :disabled="isDisabled"
        :title="hoverText"
        @input="updateValue"
      />
    </div>
    <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
  </div>
</template>

<script>
import moment from 'moment'
import lists from '@/views/admin/systemSettings/globalSettingsFieldsList.json'

export default {
  name: 'VasionDateTimeField',
  components: {
    
  },
  props: {
    backgroundColor: {
      type: String,
      default: '',
      required: false,
    },
    customClass: {
      type: String,
      default: ''
    },
    disabledDates: {
      type: [Array, Function],
      default: () => []
    },
    format: {
      type: String,
      default: ''
    },
    hoverText: {
      type: String,
      default: '',
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Select Date/Time'
    },
    required: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'date',
      validator: (val) => ['date', 'time', 'datetime'].includes(val)
    },
    value: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '100%'
    }
  },
  data() {
    return {
      displayValue: null,
      errorMessage: '',
      expectedFormat: 'YYYY-MM-DD',
      generatedId: '',
      inputValue: this.value,
      isValid: true,
      possibleDateFormats: [],
    }
  },
  computed: {
    combinedClasses() {
      return ['vasion-date-time-field', this.customClass];
    },
    computedMomentFormat() {
      if (this.format) return this.format

      const systemDateFormat = this.$store.state.systemSettings.systemDateFormat
      let dateFormat = lists.systemDateFormats.find(format => format.value === systemDateFormat)
      if (dateFormat) {
        dateFormat = dateFormat.momentFormat
      }

      return dateFormat || 'YYYY-MM-DD'
    },
  },
  watch: {
    value(newValue) {
      if (this.type === 'time' || this.type === 'datetime') {
        this.inputValue = newValue
      }else if (this.type === 'date') {
        this.setDateDisplayValue()
      }
      return
    },
  },
  created() {
    this.generatedId = `field-${Math.random().toString(36).substr(2, 9)}`

    if (this.type === 'date') this.setDateDisplayValue()
  },
  methods: {
    detectDateFormat(dateString, possibleFormats) {
      for (const format of possibleFormats) {
        let dateFormat = lists.systemDateFormats.find(formatFromList => formatFromList.value === format)
        dateFormat = dateFormat ? dateFormat.momentFormat : format
        if (moment(dateString, dateFormat, true).isValid()) {
          return format
        }
      }
      return null
    },
    onClose() {
      this.$emit('close')
    },
    onOpen() {
      this.$emit('open')
    },
    setDateDisplayValue() {
      this.possibleDateFormats = lists.systemDateFormats.map(format => format.value)
      this.possibleDateFormats.push(this.expectedFormat)

      const value = this.value === null ? '' : this.value
      let expectedFormat = this.expectedFormat
      this.isValid = this.validateDateFormat(value, this.possibleDateFormats, expectedFormat)

      if (!this.isValid) {
        this.isValid = this.validateDateFormat(value, this.possibleDateFormats, 'MM/dd/yyyy')
        if (this.isValid) expectedFormat = 'MM/DD/YYYY'
      }

      if (!this.isValid) {
        this.isValid = this.validateDateFormat(value, this.possibleDateFormats, 'yyyy/MM/dd')
        if (this.isValid) expectedFormat = 'YYYY/MM/DD'
      }

      if (!this.isValid) {
        const warningMessage = `Vasion Warning: The provided date format does not match the expected format: ${expectedFormat}`
        console.warn(warningMessage)
        return
      }

      const momentFormat = this.computedMomentFormat
      
      const dateFormat = lists.systemDateFormats.find(format => format.momentFormat === momentFormat)
      const materialFormat = dateFormat ? dateFormat.value : 'yyyy-MM-dd'

      const dateObject = moment(value, expectedFormat)
      const dateString = dateObject.format(momentFormat)

      this.$material.locale.dateFormat = materialFormat
      this.displayValue = dateString !== 'Invalid date' ? dateString : null
    },
    updateValue(event) {
      let newValue = (this.type === 'date') ? event : event.target.value
      
      if (!newValue) {
        this.$emit('input', newValue)
        return
      }
      
      if (this.type === 'date') {
        const momentObject = moment(newValue, this.computedMomentFormat)
        const formattedDate = momentObject.format(this.expectedFormat)
        newValue = formattedDate
      }

      if (newValue === this.value) return
      
      this.$emit('input', newValue)
    },
    validateDateFormat(value, listFormats, expectedFormat) {
      // Empty value is considered valid
      if (value === '') return true

      if (!value || !Array.isArray(listFormats) || listFormats.length === 0 || !expectedFormat) {
        throw new Error("Invalid date input parameters")
      }

      const detectedFormat = this.detectDateFormat(value, listFormats)

      return detectedFormat === expectedFormat
    },
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
@import '@/assets/css/light-theme-system-palette.scss';

.vasion-date-time-field {
  display: flex;
  flex-direction: column;
}

label {
  font-family: "Archivo Medium", "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: $grey-400;
  margin-bottom: 6px;
  position: relative;
}

input,
.md-datepicker {
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.error {
  color: red;
  margin-top: 5px;
}

.md-field {
  width: 100%;
  margin: 21px 0;
  min-height: 0 !important;
  display: flex;
  position: relative;
  font-family: inherit;
  padding: 4px 12px;
  border: solid 1px $grey-200;
  border-radius: 6px;
  margin-top: 0;
  margin-bottom: 10px;

}

.md-field::v-deep .md-input-action {
  top: 4px;
}

.md-field::v-deep .md-icon {
  z-index: 1;
}

.md-field:after {
  display: none;
}

.success {
  background-color: $success-bg !important;
  border: 1px solid $success-border !important;
}

.warning {
  background-color: $warning-bg !important;
  border: 1px solid $warning-border !important;
}

.error {
  background-color: $error-bg !important;
  border: 1px solid $error-border !important;
}

.disabled {
  color: $grey-400;
  background-color: $grey-75;
  border-radius: 6px;
  border: solid 1px $grey-200;
}

input.disabled {
  border: none;
}

input {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0);
  border-style: solid;
  border: none;
  font-family: "Archivo";
  font-size: 16px;
  z-index: 1;
  padding-left: 8px;
  color: #172027;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

input:focus {
  outline: none;
}

.input-time-container {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 6px;
  border: solid 1px $grey-200;
}

.input-time-container:hover {
  border: solid 1px $grey-300;
}

.input-time-container:focus-within {
  border: solid 1px $primary;
}
</style>
