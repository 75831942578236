import {
  burnTimestampToImage,
  convertTextToSignatureImage,
  getDateAsImage,
  getInboxSignatureDocuments,
  getSentSignatureDocuments,
  getSignatureDocumentsCompleted,
  getSignatureDocumentsRecalled,
  getSignatureDocumentTemplate,
  getSignatureDocumentTemplates,
  getUserSignature,
  getUserInfo,
  getUserInitials,
  saveInitials,
  saveShouldApplySignatureTimestamp,
  saveSignature,
  shouldApplySignatureTimestamp,
  saveSignatureDocumentTemplate,
  startSignatureDocument,
  UploadSignatureTemplateFile,
  verifyAndSendBulkSigTemplate,
} from '@/store/apiModules/digitalSignature.api'
import {
  buildTableData,
 } from '@/store/helperModules/digitalSignature.module';

function initialState() {
  return {
    activeDocument: {},
    addedUsersList: [],
    associateUserConfig: {
      showSetUserPanel: false,
      subType: '',
      uniqueId: '',
    },
    base64DataPrefix: 'data:image/png;base64,',
    bulkSendFailureDetails: { },
    completedData: [],
    currentPage: 1,
    deletingOnInputs: false,
    drawSignature: false,
    dsSideNav: '',
    filePath: '',
    inboxType: '',
    propertiesAnnotationsArray: [],
    isLoading: false,
    keepDataOnReturn: false,
    recalledData: [],
    saveAnnotationsToggle: false,
    selectedAnnotation: {},
    separateCopyForSigners: false,
    sentSignatureDocuments: [],
    shouldApplySignatureTimestampForUser: false,
    signatureData: [],
    signatureDataIsReadOnly: false,
    signaturePageSize: '',
    signatureTempData: {},
    signatureTotalPages: '',
    templateData: [],
    twoFactorAuth: false,
    userInitials: '',
    userSignature: '',
  }
}

const digitalSignature = {
  namespaced: true,
  state: initialState,
  mutations: {
    clearActiveDocument(state) { state.activeDocument = {} },
    changeDSSideNav(state, value) { state.dsSideNav = value },
    resetStoreData(state) {
      const localState = initialState()
      Object.keys(state).forEach((prop) => {
        state[prop] = localState[prop]
      })
    },
    setActiveDocument(state, value) { state.activeDocument = value },
    setAddedUsersList(state, list) { state.addedUsersList = list },
    setAssociateUserConfig(state, value) { state.associateUserConfig = value },
    setBulkSendFailureDetails(state, value) { state.bulkSendFailureDetails = value },
    setDeleteOnInput(state, value) { state.deletingOnInputs = value },
    setDrawSignature(state, value) { state.drawSignature = value },
    setFilePath(state, value) { state.filePath = value },
    setInboxType(state, value) { state.inboxType = value },
    setPropertiesAnnotationsArray(state, value) { state.propertiesAnnotationsArray = value; },
    setIsLoading(state, value) { state.isLoading = value },
    setKeepDataOnReturn(state, value) { state.keepDataOnReturn = value },
    setSaveAnnotationsToggle(state, value) {state.saveAnnotationsToggle = value},
    setSelectedAnnotation(state, value) { state.selectedAnnotation = value },
    setSeparateCopyForSigners(state, value) { state.separateCopyForSigners = value },
    setShouldApplySignatureTimestamp(state, value) { state.shouldApplySignatureTimestampForUser = value },
    setSignatureData(state, value) { state.signatureData = value },
    setSignatureDocuments(state, value) { state.signatureData = value },
    setSignatureDocumentsCompleted(state, value) { state.completedData = value },
    setSignatureDocumentsRecalled(state, value) { state.recalledData = value },
    setSignatureDocumentTemplates(state, value) { state.templateData = value },
    setSignaturePageSize(state, value) { state.signaturePageSize = value },
    setSignatureTempData(state, value) { state.signatureTempData = value },
    setTwoFactorAuth(state, value) { state.twoFactorAuth = value },
    setUserInitialsLocally(state, value) { state.userInitials = value },
    setUserSignatureLocally(state, value) { state.userSignature = value },
    updateElementInPropertiesAnnotationsArray(state, value) {
      if (!value || !value.UniqueID) { return }

      if (!state.propertiesAnnotationsArray) { state.propertiesAnnotationsArray = [] }

      const foundElement = state.propertiesAnnotationsArray.find(e => e.UniqueID === value.UniqueID)
      if (foundElement) {
        const shouldToggleSave = value.saveIfDifferent && 
          (foundElement.includeTime != value.includeTime || foundElement.bottomAlign != value.bottomAlign || foundElement.isRequired != value.isRequired)
        foundElement.includeTime = value.includeTime
        foundElement.bottomAlign = value.bottomAlign
        foundElement.isRequired = value.isRequired
        if (shouldToggleSave) { state.saveAnnotationsToggle = !state.saveAnnotationsToggle }
      } else {
        state.propertiesAnnotationsArray.push(value)
      }
    },
  },
  actions: {
    async burnTimestampToImage({ rootState }, payload) {
      const response = await burnTimestampToImage(rootState.common.apiToken, payload)
      return response
    },
    async burnTimestampToImageBase64({ dispatch, rootState }, signature) {
      const stampedSignature = await dispatch('burnTimestampToImage', { Value: signature.substr(22) })
      return `${rootState.digitalSignature.base64DataPrefix}${stampedSignature}`
    },
    changeDSSideNav({ commit }, value) { commit('changeDSSideNav', value) },
    async clearActiveDocument({ commit }) { commit('clearActiveDocument') },
    convertTextToSignatureImage({ rootState }, payload) {
      return convertTextToSignatureImage(rootState.common.apiToken, payload)
    },
    getDateAsImage({ rootState }, includeTime) {
      return getDateAsImage(rootState.common.apiToken, includeTime)
    },
    async getInboxSignatureDocuments({ commit, rootState }) {
      const response = await getInboxSignatureDocuments(rootState.common.apiToken)
      if (response) {
        commit('setSignatureDocuments', response)
      }
    },
    async getSentSignatureDocuments({ commit, rootState }) {
      const response = await getSentSignatureDocuments(rootState.common.apiToken)
      if (response) {
        commit('setSignatureDocuments', response)
      }
    },
    async getSignatureDocumentsCompleted({ commit, rootState }) {
      const response = await getSignatureDocumentsCompleted(rootState.common.apiToken)
      if (response) {
        commit('setSignatureDocumentsCompleted', response)
      }
    },
    async getSignatureDocumentsRecalled({ commit, rootState }) {
      const response = await getSignatureDocumentsRecalled(rootState.common.apiToken)
      if (response) {
        commit('setSignatureDocumentsRecalled', response)
      }
    },
    async getSignatureDocumentTemplate({ rootState }, payload) {
      const response = await getSignatureDocumentTemplate(rootState.common.apiToken, payload)
      return response
    },
    async getSignatureDocumentTemplates({ commit, rootState }) {
      const response = await getSignatureDocumentTemplates(rootState.common.apiToken)
      if (response) {
        commit('setSignatureDocumentTemplates', response)
      }
    },
    async getUserInfo({ rootState }) { return getUserInfo(rootState.common.apiToken) },
    async getUserInitialsForStore({ commit, rootState }) {
      const response = await getUserInitials(rootState.common.apiToken)
      if (response) {
        commit('setUserInitialsLocally', response)
      }
    },
    async getUserSignatureForStore({ commit, rootState }) {
      const response = await getUserSignature(rootState.common.apiToken)
      if (response) {
        commit('setUserSignatureLocally', response)
      }
    },
    async saveInitialsToUser({ dispatch, rootState }, payload) {
      await saveInitials(rootState.common.apiToken, payload)
      dispatch('getUserInitialsForStore', { root: true })
      dispatch('vault/showSnackbar', true, { root: true })
    },
    async saveShouldApplySignatureTimestamp({ rootState, commit }, payload) {
      const result = await saveShouldApplySignatureTimestamp(rootState.common.apiToken, payload)
      if (result) {
        commit('setShouldApplySignatureTimestamp', payload.Value === 'True')
      }
    },
    async saveSignatureDocumentTemplate({ rootState }, payload) {
      const result = await saveSignatureDocumentTemplate(rootState.common.apiToken, payload)
      return result
    },
    async saveSignatureToUser({ dispatch, rootState }, payload) {
      await saveSignature(rootState.common.apiToken, payload)
      dispatch('getUserSignatureForStore', { root: true })
      dispatch('vault/showSnackbar', true, { root: true })
    },
    async saveUserPassword({ rootState }, payload) { return saveSignature(rootState.common.apiToken, payload.signature, payload.password) },
    async setActiveDocument({ commit }, value) { commit('setActiveDocument', value) },
    async setAddedUsersList({ commit }, list) { commit('setAddedUsersList', list) },
    async setAssociateUserConfig({ commit }, value) { await commit('setAssociateUserConfig', value) },
    async setBulkSendFailureDetails({ commit }, value) { commit('setBulkSendFailureDetails', value) },
    async setDeleteOnInput({ commit }, value) { commit('setDeleteOnInput', value) },
    setDrawSignature({ commit }, value) { commit('setDrawSignature', value) },
    setInboxType({ commit }, value) { commit('setInboxType', value) },
    async setPropertiesAnnotationsArray({ commit }, value) { await commit('setPropertiesAnnotationsArray', value) },
    setIsLoading({ commit }, value) { commit('setIsLoading', value) },
    setKeepDataOnReturn({ commit }, value) { commit('setKeepDataOnReturn', value) },
    setSelectedAnnotation({ commit }, value) { commit('setSelectedAnnotation', value) },
    setSeparateCopyForSigners({ commit }, value) { commit('setSeparateCopyForSigners', value) },
    setSignaturePageSize({ commit }, pageSize) { commit('setSignaturePageSize', pageSize) },
    setSignatureTempData({ commit }, tempData) { commit('setSignatureTempData', tempData) },
    // eslint-disable-next-line no-empty-pattern
    async setTableData({}, data) { return buildTableData(data) },
    setTwoFactorAuth({ commit }, value) { commit('setTwoFactorAuth', value) },
    async shouldApplySignatureTimestamp({ rootState, commit }, payload) {
      const result = await shouldApplySignatureTimestamp(rootState.common.apiToken, payload)
      commit('setShouldApplySignatureTimestamp', result)
      return result
    },
    async startSignatureDocument({ rootState }, payload) {
      const result = await startSignatureDocument(rootState.common.apiToken, payload)
      return result
    },
    async UploadSignatureTemplateFile({ commit, rootState }, payload) {
      const result = await UploadSignatureTemplateFile(rootState.common.apiToken, payload)
      commit('setFilePath', result)
      return result
    },
    async updateElementInPropertiesAnnotationsArray({ commit }, updateElement) { await commit('updateElementInPropertiesAnnotationsArray', updateElement) },
    async verifyAndSendBulkSigTemplate({ commit, rootState }, payload) {
      const result = await verifyAndSendBulkSigTemplate(rootState.common.apiToken, payload)
      if (result.failureDetails !== null) {
          const headers = result.failureDetails.Columns.Values
          const values = result.failureDetails.Rows.Values
          commit('setBulkSendFailureDetails', { tableHeaders: headers, tableRows: values })
      }
      return result
    },
  },
}

export default digitalSignature;
