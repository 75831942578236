<template>
  <VasionGeneralModal
    :rejectButtonText="'CANCEL'"
    :confirmButtonText="'I Agree'"
    modalTitle="Almost Done"
    :modalType="'slot'"
    :sync="sync"
    @confirmButtonClick="confirmButtonClick()"
    @noButtonClick="noButtonClick"
  >
    <div id="confirmCompleteSignature">
      I agree to be legally bound by this document and the <a href="https://www.vasion.com/vasion-consumer-disclosure" target="_blank" class="vasion-terms-of-service link">Vasion Consumer Disclosure.</a> Click on "I Agree" to finish signing this document.
    </div>
  </VasionGeneralModal>
</template>

<script>

export default {
  name: 'VasionLegallyBindingConsentModal',
  components: {
  },
  props: {
    documentID: {
      type: Number,
      required: true,
    },
    geolocation: {
      type: String,
      required: false,
      default: '',
    },
    ipAddress: {
      type: String,
      required: false,
      default: '',
    },
    sync: {
      type: Boolean,
      required: true,
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
  },
  async created() {
  },
  methods: {
    async confirmButtonClick() {
      const payload = {
        documentID: this.documentID,
        UserType: 'Signer',
        geolocation: this.geolocation ? this.geolocation : '',
        ipAddress: this.ipAddress ? this.ipAddress : '',
        actionTaken: 'Consent',
        comment: '',
      }

      await this.$store.dispatch('document/addSignatureDocumentHistory', payload)
      this.$emit('confirmButtonClick')
    },
    noButtonClick() { this.$emit('noButtonClick') },
  },
}
</script>