/* eslint-disable linebreak-style */
/* eslint-disable default-case */
/* eslint-disable consistent-return */

import { createAxiosInstance } from '@/services/axiosService'

const attributeFieldLookUp = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const { data: response } = await axios.post('v1/Document/IndexFieldsDefaultLookup', payload)
    return response
  } catch (err) {
    console.warn(err)
  }
}

const createNewForm = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/admin/CreateAttributeForm', payload)
    if (response?.data && response.data.Value) {
      return response
    }
  } catch (error) {
    console.warn(error)
  }
}

const deleteAttributeForm = async (apiToken, formId) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('/v1/Admin/DeleteAttributeForm', {
      data: {
        Value: formId.toString(),
      },
    })

    if (response?.data && (response.data.Value || response.data.Value === '')) {
      return response.data.Value
    }    
  } catch (error) {
    console.warn(error)
  }
}

const getFormDetails = async (apiToken, payload) => {
  if (payload.Value == 0) {
    return
  }
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('/v1/Admin/GetAttributeFormDetails', {
      params: payload,
    })

    return response
  } catch (error) {
    console.warn(error)
  }
}

const getForms = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Document/GetAllIndexForms', null)
    if (response?.data) {
      return response
    }
  } catch (error) {
    console.warn(error)
  }
}

const getFormsForUser = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('/v1/Document/GetIndexFormsForUser', null)
    if (response?.data) {
      return response
    }
  } catch (error) {
    console.warn(error)
  }
}

const getMyForms = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Document/LoadAllEFormsForUser', payload)
    if (response?.data) {
      return response
    }
  } catch (error) {
    console.warn(error)
  }
}

const getObjectIDsWhereFieldUsed = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('/v1/Document/GetObjectIDsWhereFieldUsed', {
      params: payload,
    })

    if (response?.data) {
      return response
    }
  } catch (error) {
    console.warn(error)
  }
}

const isDocumentAssociatedWithObject = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('/v1/Document/IsDocumentAssociatedWithObject', {
      params: payload,
    })

    if (response?.data) {
      return response
    }
  } catch (error) {
    console.warn(error)
  }
}

const renameForm = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    await axios.post('v1/admin/RenameAttributeForm', payload, {
      responseType: 'blob',
    })
  } catch (error) {
    console.warn(error)
  }
}

const saveAttributeForm = async (apiToken, form) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/admin/UpdateAttributeFormDetails', form)
    if (response && response.data) {
      return response
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveAttributeFormFields = async (apiToken, fields) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/UpdateAttributeFormFields', fields)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveAttributeFormHeader = async (apiToken, form) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/UpdateAttributeFormHeader', form)
    if (response && response.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

export {
  attributeFieldLookUp,
  createNewForm,
  deleteAttributeForm,
  getFormDetails,
  getForms,
  getFormsForUser,
  getMyForms,
  getObjectIDsWhereFieldUsed,
  isDocumentAssociatedWithObject,
  renameForm,
  saveAttributeForm,
  saveAttributeFormFields,
  saveAttributeFormHeader,
}
