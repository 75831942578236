<template>
  <div>
    <h2 class="table-title">
      {{ localDataTable.Tablename }}
    </h2>
    <table class="line-item-table">
      <thead>
        <tr>
          <th class="num-cell" />
          <th v-for="(header, index1) in localDataTable.Headers" :key="`T-${tableIndex}-H${index1}`">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index2) in localDataTable.Rows" :key="`T-${tableIndex}-R${index2}`">
          <td class="num-cell">
            {{ index2 + 1 }}
          </td>
          <td v-for="(field, index3) in row.Columns" :key="field.CellId === 0 ? `${tableIndex}-${index2}-${index3}` : field.CellId">
            <VasionInput
              :ref="field.CellId === 0 ? `NF-${tableIndex}-${index2}-${index3}` : `F-${field.CellId}`"
              v-model="localDataTable.Rows[index2].Columns[index3].Value"
              class="input-style"
              inputType="top-white"
              :cusutomClass="(includeValidationColors && getMeanConfidenceClass(field)) || ''"
              :data-table="tableIndex"
              :data-row="index2"
              :data-column="index3"
              :data-cellid="field.CellId === 0 ? `NF-${tableIndex}-${index2}-${index3}` : `F-${field.CellId}`"
              :isDisabled="!isEditable"
              @click="handleFieldClick"
              @input="markDirty(); fieldChanged();"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <hr class="tabDivider">
    <VasionGeneralModal
      :id="`line-item-field-modal-${tableIndex}`"
      rejectButtonText="Cancel"
      confirmButtonText="Done"
      :showConfirmButton="true"
      :modalType="'slot'"
      :sync="showLineItemFieldModal"
      @noButtonClick="cancelFieldModification"
      @confirmButtonClick="confirmFieldModification"
    >
      <textarea
        v-model="textareaValue"
        class="textarea-01"
        rows="4"
        cols="50"
      />
    </VasionGeneralModal>
    <div id="textWidthChecker" />
  </div>
</template>

<script>
import { getMeanConfidenceColor } from '@/enums/objectFieldMeanConfidenceLevels.js'

export default {
  name: 'FieldsTable',
  props: {
    dataTable: {
      type: Object,
      required: true,
    },
    documentId: {
      type: Number,
      required: true
    },
    tableIndex: {
      type: Number,
      required: true,
    },
    includeValidationColors: {
      type: Boolean,
      required: false,
      default: true,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      localDataTable: { ...this.dataTable },
      currentFocusedField: null,
      showLineItemFieldModal: false,
      textareaValue: '',
    };
  },
  methods: {
    cancelFieldModification() {
      this.showLineItemFieldModal = false
    },
    async confirmFieldModification() {
      const element = this.$refs[`${this.currentFocusedField}`][0]
      const tableIndex = element.$el.querySelector('input').getAttribute('data-table')
      const rowIndex = element.$el.querySelector('input').getAttribute('data-row')
      const columnIndex = element.$el.querySelector('input').getAttribute('data-column')

      this.localDataTable.Rows[rowIndex].Columns[columnIndex].Value = this.textareaValue

      const cellId = this.currentFocusedField.includes('NF-') ? this.currentFocusedField : Number(this.currentFocusedField.replace('F-', ''))

      this.$emit('updateField', this.documentId, tableIndex, rowIndex, columnIndex, cellId, this.textareaValue)
      
      this.currentFocusedField = null
      this.textareaValue = ''
      this.markDirty()
      this.showLineItemFieldModal = false
    },
    fieldChanged() {
      const focusedElement = this.$refs[this.currentFocusedField]?.[0]
      if (!focusedElement) {
        console.error('Focused element not found')
        return
      }

      const inputElement = focusedElement.$el.querySelector('input')
      if (!inputElement) {
        console.error('Input element not found')
        return
      }

      const { value } = inputElement
      const tableIndex = inputElement.getAttribute('data-table')
      const rowIndex = inputElement.getAttribute('data-row')
      const columnIndex = inputElement.getAttribute('data-column')

      let cellId = this.currentFocusedField
      if (cellId.startsWith('NF-')) {
        const cellIdParts = cellId.split('-')
        cellIdParts[1] = this.localDataTable?.TableId || cellIdParts[1]
        cellId = cellIdParts.join('-')
      } else {
        cellId = Number(cellId.replace('F-', ''))
      }

      this.$emit('updateField', this.documentId, tableIndex, rowIndex, columnIndex, cellId, value);
    },
    getMeanConfidenceClass(field) {
      if (!field || !field.Confidence || !field.Confidence.MeanConfidence) return ''
      const meanConfidence = field.Confidence.MeanConfidence
      if (meanConfidence === -1) return ''

      const color = getMeanConfidenceColor(meanConfidence)
      let customInputClass = ''

      if (color === 'LawnGreen') {
        customInputClass = 'success'
      } else if (color === 'Yellow') {
        customInputClass = 'warning'
      } else if (color === 'Red') {
        customInputClass = 'error'
      }

      return customInputClass
    },
    async handleFieldClick(event) {
      if (this.isTextOverflowing(event.target)) {
        this.textareaValue = event.target.value
        this.showLineItemFieldModal = true
      }
      
      this.currentFocusedField = event.target.getAttribute('data-cellid')

      const selectedField = this.localDataTable.Rows
        .map(row => row.Columns.find(field => 
          this.currentFocusedField.startsWith('F-') && Number(field.CellId) === Number(this.currentFocusedField.replace('F-', ''))
        )).find(field => field !== undefined)

      if (selectedField) await this.$store.dispatch('document/setSelectedLineItemField', {...selectedField, documentId: this.documentId})
    },
    isTextOverflowing(input) {
      const text = input.value
      const textWidthChecker = document.getElementById('textWidthChecker')

      const inputStyles = window.getComputedStyle(input)
      textWidthChecker.style.fontSize = inputStyles.fontSize
      textWidthChecker.style.fontFamily = inputStyles.fontFamily
      textWidthChecker.style.fontWeight = inputStyles.fontWeight
      textWidthChecker.style.letterSpacing = inputStyles.letterSpacing
      textWidthChecker.style.textTransform = inputStyles.textTransform

      textWidthChecker.textContent = text
      const textWidth = textWidthChecker.offsetWidth
      const inputWidth = input.clientWidth
      return textWidth > inputWidth
    },
    markDirty() {
      this.$emit('markDirty')
    },
  }
};
</script>

<style lang="scss" scoped>
.table-title {
  color: #000000;
  font-family: Archivo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.line-item-table {
  width: 100%;
}

.line-item-table th {
  color: #000000;
  font-family: Archivo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 8px;
}

.line-item-table td {
  padding: 8px;
}

.line-item-table .num-cell {
  width: 40px;
}

.textarea-01 {
  min-width: 300px;
  min-height: 300px;
  padding: 10px 16px;
}

#textWidthChecker {
  visibility: hidden;
  white-space: nowrap;
  position: fixed;
}
.tabDivider {
  border-top: 1px solid #CBC4CF;
  border-style: solid;
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>