/* eslint-disable linebreak-style */
/* eslint-disable default-case */
/* eslint-disable consistent-return */

import { createAxiosInstance } from '@/services/axiosService'

const addDepartment = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const res = await axios.post('v1/Auth/AddDepartment', payload)
    if (res?.data) {
      return res.data
    }
    return res.response?.data
  } catch (error) {
    console.warn(error)
    return error
  }
}

const authenticateWebSearchLogin = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Auth/AuthenticateWebSearchLogin', payload)
    if (response && response.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const deleteDepartment = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const res = await axios.delete('v1/Auth/DeleteDepartment', { data: { ...payload } })
    if (res?.data) {
      return res.data
    }
    return res.response?.data
  } catch (error) {
    console.warn(error)
    return error
  }
}

const deleteGroup = async (apiToken, groupID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('v1/admin/DeleteGroup', {
      data: {
        Value: groupID.toString(),
      },
    })

    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const downloadUsersList = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/DownloadUserGroupReport', payload, { responseType: 'blob' })
    
    if (response?.data) {
      return response
    }
    
    return 0
  } catch (error) {
    console.warn(error)
  }
}

const forceLogOff = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/admin/ForceLogOff', payload)

    return response?.data?.Value == 'True'
  } catch (error) {
    console.warn(error)
  }
}

const generateSigDocLinkGUIDAuthCode = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    await axios.post('v1/Auth/GenerateSigDocLinkGUIDAuthCode', payload)
    return true
  } catch (error) {
    console.warn(error)
  }
}


const getActiveUsers = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Admin/GetActiveUsers')
    if (response?.data && response.data.Values) {
      return response.data.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const getAllApps = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Auth/GetAllAppAccess')
    if (response?.data && response.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getConfigurableTokens = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Admin/GetFieldsConfiguredForTokenSecurity`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getDepartmentList = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Auth/GetDepartments')
    if (response?.data && response.data.Values) {
      return response.data.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const getExternalUserList = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Admin/GetUsersInSignatureExternalUserGroup')
    if (response?.data) {
      return response.data.UserList.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const getGroupApps = async (apiToken, groupID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Auth/GetAppAccessAssignedToGroup?Value=${groupID}`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getGroupChildren = async (apiToken, groupID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Auth/GetChildrenOfGroup?Value=${groupID}`)
    if (response?.data && response.data.children) {
      return response.data.children
    }
  } catch (error) {
    console.warn(error)
  }
}

const getGroupList = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Auth/GetAllGroups')
    if (response?.data && response.data.Values) {
      return response.data.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const getGroupListEnabledOnly = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Auth/GetGroupList')
    if (response?.data && response.data.Values) {
      return response.data.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const getGroupRoles = async (apiToken, groupID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Auth/GetAssignedGroupRoles?Value=${groupID}`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getGroupTokens = async (apiToken, groupID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Admin/GetTokensForGroup?Value=${groupID}`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getLdapUserList = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/LoadLdapUsers', payload)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getLdapServerIp = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Admin/GetLdapServerIp')
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const importLdapUsers = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/SaveLdapUsers', payload)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getLoginTypes = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get('v1/Auth/GetLoginTypes')
    if (response?.data && response.data.Values) {
      return response.data.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const getSignatureLinkGUIDDetails = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    let getString = `v1/Auth/GetSignatureLinkGUIDDetails?Value=${payload.signatureDocLinkGUID}`

    if (payload.twoFactorAuthCode) {
      getString += `&twoFactorAuthCode=${payload.twoFactorAuthCode}`
    }
    if (payload.username) {
      getString += `&username=${payload.username}`
    }
    if (payload.password) {
      getString += `&password=${payload.password}`
    }

    const response = await axios.get(getString)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getUserApps = async (apiToken, userID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Auth/GetAppAccessAssignedToUser?Value=${userID}`)
    if (response?.data && response.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getUserDetails = async (apiToken, userID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Auth/GetUserDetails?Value=${userID}`)
    if (response?.data && response.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getUserList = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Auth/GetUserList', { includeDisabled: true, includeExternalAPIUsers: true })
    if (response?.data && response.data.Values) {
      return response.data.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const getUserListEnabledOnly = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Auth/GetUserList', {})
    if (response?.data && response.data.Values) {
      return response.data.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const getUserListIncludeExternal = async (apiToken) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Auth/GetUserList', { includeExternalSignatureUsers: true })
    if (response?.data && response.data.Values) {
      return response.data.Values
    }
  } catch (error) {
    console.warn(error)
  }
}

const getUserRoles = async (apiToken, userID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Auth/GetAssignedUserRoles?Value=${userID}`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const getUserTokens = async (apiToken, userID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.get(`v1/Admin/GetTokensForUser?Value=${userID}`)
    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveGroupApps = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Auth/SaveAppAccessForGroup', payload)
    if (response?.data && response.data.Value) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveGroupChildren = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Auth/SaveChildrenToGroup', payload)

    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveGroupDetails = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Auth/SaveGroup', payload)

    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveGroupRoles = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Auth/SaveGroupRoles', payload)
    if (response?.data && response.data.Value) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveGroupTokens = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/SaveGroupTokens', payload)

    if (response?.data && response.data.Value) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveUserApps = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Auth/SaveAppAccessForUser', payload)
    if (response?.data && response.data.Value) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveUserDetails = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Auth/SaveUserDetails', payload)

    if (response?.data) {
      return response.data
    }
  } catch (error) {
    if (error?.response?.data?.ResultMessage.startsWith('Cannot')){
      return error.response.data
    }
    console.warn(error)
  }
}

const saveUserRoles = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Auth/SaveUserRoles', payload)
    if (response?.data && response.data.Value) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const saveUserTokens = async (apiToken, payload) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/Admin/SaveUserTokens', payload)

    if (response?.data && response.data.Value) {
      return response.data.Value
    }
  } catch (error) {
    console.warn(error)
  }
}

const deleteUser = async (apiToken, userID) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.delete('v1/admin/DeleteUser', {
      data: {
        Value: userID.toString(),
      },
    })

    if (response?.data) {
      return response.data
    }
  } catch (error) {
    console.warn(error)
  }
}

const sendLogOffEmail = async (apiToken, email) => {
  const axios = createAxiosInstance(apiToken)
  try {
    const response = await axios.post('v1/admin/SendLogOffEmail', {
      Value: email,
    })

    return response?.data?.Value == 'True'
  } catch (error) {
    console.warn(error)
  }
}

export {
  addDepartment,
  authenticateWebSearchLogin,
  deleteDepartment,
  deleteGroup,
  deleteUser,
  downloadUsersList,
  forceLogOff,
  generateSigDocLinkGUIDAuthCode,
  getActiveUsers,
  getAllApps,
  getConfigurableTokens,
  getDepartmentList,
  getExternalUserList,
  getGroupApps,
  getGroupChildren,
  getGroupList,
  getGroupListEnabledOnly,
  getGroupRoles,
  getGroupTokens,
  getLdapUserList,
  getLdapServerIp,
  getLoginTypes,
  getSignatureLinkGUIDDetails,
  getUserApps,
  getUserDetails,
  getUserList,
  getUserListEnabledOnly,
  getUserListIncludeExternal,
  getUserRoles,
  getUserTokens,
  importLdapUsers,
  saveGroupApps,
  saveGroupChildren,
  saveGroupDetails,
  saveGroupRoles,
  saveGroupTokens,
  saveUserApps,
  saveUserDetails,
  saveUserRoles,
  saveUserTokens,
  sendLogOffEmail,
}
