import * as amplitude from '@amplitude/analytics-browser'
import store from '@/store/store'

let amplitudeInitialized = false
let amplitudeServiceAvailable = false

export default {
  async checkAmplitudeAvailability(externalUser = false) {
    amplitudeServiceAvailable = false
    // Feature flag
    const amplitudeDisabled = store.state.systemSettings.featureFlags.amplitudeDisabled
    // User config
    await store.dispatch('admin/getUserSetting', 'IsAnalyticsOptOut')
    let amplitudeUserOptedIn = !(store.state.admin.originalIsAnalyticsOptOut === "true")
    amplitudeUserOptedIn = externalUser ? true : amplitudeUserOptedIn
    // Check if all the necessary data is available, if not return, else enable amplitude
    if (amplitudeDisabled || !amplitudeUserOptedIn) return
    amplitudeServiceAvailable = true
    
    const appVersions = await store.dispatch('admin/getAppVersions')
    if (appVersions?.Apps) {
      const apiVersion = appVersions.Apps.find(app => app.AppName.includes('API')).Version
      this.setProperty('apiVersion', apiVersion)
    }
    this.setProperty('instance', window.location.host)
  },
  identifyUser(userId) {
    if (amplitudeServiceAvailable) {
      amplitude.setUserId(userId)
    }
  },
  initialize() {
    const isAmplitudeKeyPresent = process.env.VUE_APP_AMP_KEY ? true : false
    if (!isAmplitudeKeyPresent) return
    if (amplitudeInitialized) return

    const apiKey = process.env.VUE_APP_AMP_KEY
    const options = {
      defaultTracking: true,
    }
    amplitude.init(apiKey, options)
    amplitudeInitialized = true
  },
  isAvailable() {
    return amplitudeInitialized && amplitudeServiceAvailable
  },
  reset() {
    amplitudeInitialized = false
    amplitudeServiceAvailable = false
    amplitude.reset()
  },
  setProperty(property, value) {
    if (amplitudeServiceAvailable) {
      const identifyEvent = new amplitude.Identify()
      identifyEvent.set(property, value)
      amplitude.identify(identifyEvent)
    }
  },
  trackEvent(eventType, eventProperties) {
    if (amplitudeServiceAvailable) {
      amplitude.track(eventType, eventProperties)
    }
  },
}