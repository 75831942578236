import { defaultIgnoreItems } from '@/assets/js/formIOConfig'
import { flattenComponents } from 'formiojs/utils/formUtils.js'

// eslint-disable-next-line consistent-return
const addTotalLogicDropList = (currentConfig, form) => {
  const dataGridComponents = []
  const flattenedComponents = flattenComponents(form.components, true)
  for (const key in flattenedComponents) {
    if (flattenedComponents[key].type === 'datagrid') {
      dataGridComponents.push(flattenedComponents[key])
    }
  }

  if (dataGridComponents.length < 1) {
    return {changed: false}
  }
  
  const numericalColumList = []
  dataGridComponents.forEach(item => {
    item.components.forEach(column => {
      if (column.type === 'number' || column.type === 'currency') {
        numericalColumList.push({
          label: `[${item.label}] - ${column.label}`,
          value: `grid:${item.key}value:${column.key}`,
        })
      }
    })
  })
  
  const newNumberIgnoreItems = [
    ...defaultIgnoreItems.slice(0, 6),
    {
      key: 'data',
      ignore: false,
      components: [
        { key: 'multiple', ignore: true },
        { key: 'persistent', ignore: true },
        { key: 'protected', ignore: true },
        { key: 'dbIndex', ignore: true },
        { key: 'encrypted', ignore: true },
        { key: 'redrawOn', ignore: true },
        { key: 'clearOnHide', ignore: true },
        { key: 'customDefaultValuePanel', ignore: true },
        { key: 'calculateValuePanel', ignore: false },
        { key: 'allowCalculateOverride', ignore: false },
        {
          label: 'Total Column',
          data: {
              values: numericalColumList,
          },
          key: 'selectTotal',
          type: 'select',
          input: true,
        },
      ],
    },
  ]
  return {changed: true, number: [...newNumberIgnoreItems], currency: [...newNumberIgnoreItems]}
}

const setCalculateValueForTotalColumn = (component) => {
  if (component.selectTotal && (!component.calculateValue || component.calculateValue.startsWith('var GRID_FIELD'))) {
    const rawString = component.selectTotal
    const grid = rawString.substring(
      rawString.indexOf(':') + 1,
      rawString.indexOf('value:'),
    )
    const filterValue = rawString.substring(rawString.lastIndexOf(':') + 1)
    // eslint-disable-next-line quotes
    component.calculateValue = `var GRID_FIELD = '${grid}';\r\nvar dataGrid = data[GRID_FIELD] || [];\r\nvar FIELD = '${filterValue}';\r\nvar sum = 0;\r\nfor (var i = 0; i <= dataGrid.length; i++)\r\n{\r\nif ((dataGrid[i]) && dataGrid[i].hasOwnProperty(FIELD))\r\n{\r\nsum = sum + Number(dataGrid[i][FIELD]);\r\n}\r\n}\r\nvalue = sum;`
    component.disabled = true
  }
}

// Recursively go through the form.io JSON in order to find all the Vasion compoenents, and build a list of them
const findVasionComponents = (currentNode) => {
  let currentList = []
  //TODO: I think this method would be improved if it used formiojs.flattencomponents
  
  // This node may have a list of subcomponents, so loop through them all
  currentNode.components?.forEach(component => {
    // If the current component is a Vasion compoenent, add it to the list
    if (component.vasionUniqueFieldName !== '' && component.vasionUniqueFieldName !== undefined) {
      currentList.push(component)
    }
    // If this component has subcomponents, pass the current component recursively so it can loop through the subcomponents
    if (component.components?.length > 0) {
      currentList.push(...findVasionComponents(component))
    }
    // If this is a column component, it will have a list of components inside each column object, so go through them recursively
    component.columns?.forEach(col => {
      currentList.push(...findVasionComponents(col))
    })
  })
  return currentList
}

export {
  addTotalLogicDropList,
  findVasionComponents,
  setCalculateValueForTotalColumn,
}
