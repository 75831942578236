<template>
  <div
    v-if="sync === true"
    :id="modalId"
    :key="modalKey"
    class="overlay"
  >
    <div
      :class="{
        'modal-container': true,
        'md-dialog': modalMdContainer
      }"
    >
      <div class="vasion-general-modal" :class="modalType !== 'slot' ? 'dialog-div' : ''">
        <div v-if="(modalType === 'slot' || showTitleAndMessage) && (modalTitle || showTopRightCloseButton)" class="modal-title">
          {{ modalTitle }}
          <VasionClear v-if="showTopRightCloseButton" id="clear-icon" @click="noButtonClick(true)" />
        </div>
        <span
          v-if="modalType === 'message' || showTitleAndMessage"
          id="dialog-message"
          ref="dialogMessage"
          class="message"
          :class="{
            'message-margin-top': showTitleAndMessage,
            'left-align-text': leftAlignMessageText
          }"
        >
          {{ message }}
        </span>
        <div v-if="modalType === 'slot'" :class="[noYScroll ? 'slot-no-y-scroll' : 'slot']" @slotComponentEmit="yesButtonClick()">
          <slot ref="slottedComponent" />
        </div>
        <div
          v-if="!hideButtons"
          class="general-modal-footer"
          :class="{
            'buttons-start': buttonGroup === 'start',
            'buttons-center': buttonGroup === 'center',
            'buttons-end': buttonGroup === 'end',
            'buttons-single': buttonGroup === 'single',
          }"
        >
          <VasionButton
            v-if="!hideButtons && showCustomButton"
            id="custom-button"
            :isDisabled="customButtonDisabled"
            :classProp="'secondary'"
            @vasionButtonClicked="customButtonClick()"
          >
            {{ customButtonText }}
          </VasionButton>
          <VasionButton
            v-if="!hideButtons && showRejectButton"
            id="reject-button"
            :classProp="buttonGroup === 'single' ? 'primary' : 'secondary'"
            @vasionButtonClicked="noButtonClick(false)"
          >
            {{ rejectButtonText }}
          </VasionButton>
          <VasionButton
            v-if="!hideButtons && showConfirmButton"
            id="confirm-button"
            :isDisabled="confirmButtonDisabled"
            :classProp="'primary'"
            @vasionButtonClicked="yesButtonClick()"
          >
            {{ confirmButtonText }}
          </VasionButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VasionGeneralModal',
  props: {
    buttonGroup: {
      type: String,
      default: 'end',
      required: false,
    },
    confirmButtonCallback: {
      type: Function,
      default: null,
      required: false,
    },
    confirmButtonDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    confirmButtonText: {
      type: String,
      default: 'confirm',
      required: false,
    },
    customButtonDisabled: {
      type: Boolean,
      default: true,
      required: false,
    },
    customButtonText: {
      type: String,
      default: 'custom',
      required: false,
    },
    hideButtons: {
      type: Boolean,
      default: false,
      required: false,
    },
    leftAlignMessageText: {
      type: Boolean,
      default: false,
      required: false,
    },
    message: {
      type: String,
      default: "",
      required: false,
    },
    modalId: {
      type: String,
      default: "",
      required: false,
    },
    modalKey: {
      type: String,
      default: 'general-modal',
      required: false,
    },
    modalMdContainer: {
      type: Boolean,
      default: false,
      required: false,
    },
    modalTitle: {
      type: String,
      default: "",
      required: false,
    },
    modalType: {
      type: String,
      default: 'message',
      required: true,
    },
    noYScroll: {
      type: Boolean,
      default: false,
      required: false,
    },
    rejectButtonText: {
      type: String,
      default: 'reject',
      required: false,
    },
    showConfirmButton: {
      type: Boolean,
      default: true,
      required: false,
    },
    showCustomButton: {
      type: Boolean,
      default: false,
      required: false,
    },
    showRejectButton: {
      type: Boolean,
      default: true,
      required: false,
    },
    showTitleAndMessage: {
      type: Boolean,
      default: false,
      required: false,
    },
    showTopRightCloseButton: {
      type: Boolean,
      default: false,
      required: false,
    },
    sync: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: function () {
    return {
      buttonPosition: this.buttonGroup,
      processing: false,
    }
  },
  created: function () {
    return this.sync === true ? document.body.classList.add('modal-open') : document.body.classList.remove('modal-open')
  },
  mounted() {
    document.body.appendChild(this.$el)
  },
  beforeDestroy() {
    document.body.removeChild(this.$el)
  },
  methods: {
    customButtonClick() {
      this.$emit('customButtonClick')
    },
    noButtonClick(clickX) {
      this.$emit('noButtonClick', clickX)
    },
    yesButtonClick() {
      if (this.processing === true) {
        return;
      }
      this.processing = true
      // will need to be slightly refactored for multiple nested slots
      if (this.$slots.default !== undefined) {
        Object.values(this.$slots.default).forEach(vNode => {
          if (vNode.componentInstance?.slotToParentValue !== '' && vNode.componentInstance?.slotToParentValue !== undefined) {
            this.$emit('confirmButtonClick', vNode.componentInstance?.slotToParentValue, this.confirmButtonCallback)
          } else {
            this.$emit('confirmButtonClick', '', this.confirmButtonCallback)
          }
        })
      } else {
        this.$emit('confirmButtonClick', '', this.confirmButtonCallback)
      }
      setTimeout(() => {
        this.processing = false;
      }, 3000);
    },
  },
}
</script>

<style lang="scss">
  @import '@/assets/css/variables.scss';
  .modal-title {
    @include SubtitleActive;
    font-size: 1.75em;
    white-space: pre-wrap;
  }
  .overlay {
    visibility: initial;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 200;
    cursor: auto;
    .modal-container {
      z-index: 1000;
      border-radius: 8px;
      background: #FFFFFF;
      padding: 25px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      animation: grow .30s;
      max-width: 100%;
      max-height: 100%;
      overflow-y: auto;
      .vasion-general-modal {
        height: 100%;
        display: flex;
        flex-direction: column;
        margin: auto;
        .message {
          @include Text
        }
      }
      .general-modal-footer {
        position: relative;
        bottom: 0;
        right: 0;
      }
    }
  }
  #clear-icon {
    background: $grey-75;
    border-radius: 8px;
    width: 32px;
    height: 32px;
    padding: 4px;
    float: right;
    margin-top: -4px;
  }
  #clear-icon:hover {
    background: $plumb-50;
    cursor: pointer;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    &-end {
      @extend .buttons;
      justify-content: flex-end;
    }
    &-start {
      @extend .buttons;
      justify-content: flex-start;
    }
    &-center {
      @extend .buttons;
      justify-content: center;
      padding: 0;
    }
    &-single {
      @extend .buttons;
      justify-content: center;
      padding: 0;
      #custom-button {
        display: none;
      }
      #confirm-button {
        display: none;
      }
    }
  }

  .slot{
    max-height: 80vh;
    overflow: auto;
    margin: 8px 0;
  }

  @media (max-width: $phone) {
    .slot-no-y-scroll {
      max-height: 500px;
    }
  }

  .dialog-div {
    height: 10em;
    max-width: 450px;
    width: 100%;
    span {
      @include Subtitle;
      text-align: center;
      padding-bottom: 15px;
    }
    .left-align-text {
      text-align: left;
    }
  }

  @media (max-width: $breakpoint-sm) {
    .dialog-div {
      width: unset !important
    }
  }
  .message-margin-top{
    margin-top: 2rem;
  }

  @keyframes grow {
    from {
      transform: scale(.5);
    }
    to {
      transform: scale(1);
    }
  }
</style>
