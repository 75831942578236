function initialState() {
  return {
    integrationSideNav: '',
  }
}

const integration = {
  namespaced: true,
  state: initialState,
  mutations: {
    changeIntegrationSideNav(state, value) { state.integrationSideNav = value },
    resetStoreData(state) {
      const localState = initialState()
      Object.keys(state).forEach((prop) => {
        state[prop] = localState[prop]
      })
    },
  },
  actions: {
    changeIntegrationSideNav({ commit }, value) { commit('changeIntegrationSideNav', value) },
  },
}

export default integration;
