<template>
  <div v-if="isTitleInput" :class="[inputType === 'title-input' ? 'vasion-title-input' : '', cusutomClass]">
    <!-- Title Input -->
    <input
      v-bind="$attrs"
      ref="mainInput"
      autocomplete="off"
      class="text-input"
      :class="calculatedClasses().input"
      :maxlength="maxLengthProp"
      :disabled="isDisabled"
      :readonly="localReadonly"
      :style="[backgroundColor ? { 'background-color': backgroundColor } : null ]"
      @focus="checkRemoveReadonly()"
      v-on="listeners"
    >
  </div>
  <div v-else class="vasion-input possition-relative no-padding" :class="[inputType === 'left-white' ? 'vasion-flex-vertical-center' : '', cusutomClass]"
  >
    <div>
      <label
        v-if="title && (inputType === 'top-white' || inputType === 'search-white-top')"
        class="vasion-input-label-top"
        :class="{'error-state-label':isInErrorState}"
      >
        {{ title }}
      </label>
      <span v-if="title && (inputType === 'top-white' || inputType === 'search-white-top') && required" class="vasion-required-indicator">*</span>
    </div>
    <div>
      <label
        v-if="title && inputType === 'left-white'"
        class="input-label-left"
        :class="{'error-state-label':isInErrorState}"
      >
        {{ title }}
      </label>
      <span v-if="title && inputType === 'left-white' && required" class="vasion-required-indicator">*</span>
    </div>
    <div v-if="!title && showTitlePlaceholder" class="title-placeholder-div" />
    <div
      class="input-group"
      :class="calculatedClasses().container"
      :style="[backgroundColor ? { 'background-color': backgroundColor, width: inputGroupWidth } : { width: inputGroupWidth } ]"
    >
      <i v-if="inputType === 'search' || inputType === 'search-on-color' || inputType === 'search-white' || inputType === 'search-gray' || inputType === 'search-white-top'" class="search-icon"><VasionSearchIcon /></i>
      <!-- Number Input -->
      <input
        v-if="isNumber && specialValue.length > 0"
        v-model="specialValue"
        :id="currencyID"
        v-bind="$attrs"
        ref="mainInput"
        autocomplete="off"
        class="text-input"
        :class="calculatedClasses().input"
        :maxlength="maxLengthProp"
        :disabled="isDisabled"
        :readonly="localReadonly"
        :title="hoverTitle"
        @focus="checkRemoveReadonly()"
        @keyup="updateTitle"
        v-on="listeners"
      >
      <input
        v-else-if="isNumber && specialValue.length === 0"
        :id="currencyID"
        v-bind="$attrs"
        ref="mainInput"
        autocomplete="off"
        class="text-input"
        :class="calculatedClasses().input"
        :maxlength="maxLengthProp"
        :disabled="isDisabled"
        :readonly="localReadonly"
        :title="hoverTitle"
        @focus="checkRemoveReadonly()"
        @keyup="updateTitle"
        v-on="listeners"
      >
      <!-- URL Input -->
      <input
        v-else-if="isURL && !showURLLink"
        v-bind="$attrs"
        ref="mainInput"
        autocomplete="off"
        class="text-input"
        type="url"
        name="url"
        placeholder="https://example.com"
        pattern="https://.*"
        :class="calculatedClasses().input"
        :maxlength="maxLengthProp"
        :disabled="isDisabled"
        :readonly="localReadonly"
        :title="hoverTitle"
        @focus="checkRemoveReadonly()"
        @input="isValid"
        @keyup="updateTitle"
        v-on="listeners"
      >
      <!-- URL Link -->
      <input
        v-else-if="isURL && showURLLink"
        v-bind="$attrs"
        ref="mainInput"
        autocomplete="off"
        class="text-input"
        type="urlLink"
        name="urlLink"
        placeholder="https://example.com"
        pattern="https://.*"
        :class="calculatedClasses().input"
        :maxlength="maxLengthProp"
        :readonly="true"
        :title="hoverTitle"
        @click="openUrlInNewTab"
        @keyup="updateTitle"
        v-on="listeners"
      >
      <!-- Email Input -->
      <input
        v-else-if="isEmail"
        v-bind="$attrs"
        ref="mainInput"
        autocomplete="off"
        class="text-input"
        type="email"
        name="email"
        placeholder="jon_doe@example.com"
        :class="calculatedClasses().input"
        :maxlength="maxLengthProp"
        :disabled="isDisabled"
        :readonly="localReadonly"
        :title="hoverTitle"
        @focus="checkRemoveReadonly()"
        @input="isValid"
        @keyup="updateTitle"
        v-on="listeners"
      >
      <!-- Currency Input -->
      <input
        v-else-if="isCurrency"
        :id="currencyID"
        v-bind="$attrs"
        ref="mainInput"
        autocomplete="off"
        class="text-input currency-vasion-input"
        lang="en-150"
        :class="calculatedClasses().input"
        :maxlength="maxLengthProp"
        :disabled="isDisabled"
        :readonly="localReadonly"
        :title="hoverTitle"
        @focus="checkRemoveReadonly()"
        @keyup="[currencyHandler($event), updateTitle($event)]"
        @blur="finalizeCurrency"
        v-on="listeners"
      >
      <!-- Masked SSN Input -->
      <input
        v-else-if="isMaskedSSN"
        v-bind="$attrs"
        ref="mainInput"
        autocomplete="off"
        class="text-input"
        lang="en-150"
        :class="calculatedClasses().input"
        :maxlength="maxLengthProp"
        :disabled="isDisabled"
        :readonly="localReadonly"
        :title="hoverTitle"
        @focus="checkRemoveReadonly()"
        @keypress="preventAlphaCharacters"
        @keyup="[maskHandler($event), updateTitle($event)]"
        @blur="maskHandler"
        v-on="listeners"
      >
      <!-- Text Area Input -->
      <textarea
        v-else-if="isTextArea"
        v-bind="$attrs"
        ref="mainInput"
        autocomplete="off"
        class="text-area"
        :class="calculatedClasses().input"
        :maxlength="maxLengthProp"
        :disabled="isDisabled"
        :readonly="localReadonly"
        :title="hoverTitle"
        @focus="checkRemoveReadonly()"
        @keyup="updateTitle"
        v-on="listeners"
      />
      <!-- Password/Default Input -->
      <input
        v-else
        v-bind="$attrs"
        ref="mainInput"
        :autocomplete="preventPasswordAutofill ? 'new-password' : 'off'"
        class="text-input"
        :class="calculatedClasses().input"
        :maxlength="maxLengthProp"
        :disabled="isDisabled"
        :readonly="localReadonly"
        :type="fieldType"
        :title="hoverTitle"
        @focus="checkRemoveReadonly()"
        @blur="$emit('blur', true)"
        @keydown="checkForListNavigation"
        @keyup="updateTitle"
        v-on="listeners"
      >
      <i v-if="!isDisabled && arrowValue" class="drop-arrow" @click="arrowClickCallback"><VasionArrowDropUpIcon /></i>
      <i v-if="!isDisabled && arrowValue === false" class="drop-arrow" @click="arrowClickCallback"><VasionArrowDropDownIcon /></i>

      <i
        v-if="isPassword && !showPassword && canToggleShowPassword"
        class="show-hide-password-icon"
        :class="{ 'black-theme-icon': isBlackTheme }"
        @click="showPassword = !showPassword"
      >
        <VasionEyeHidePasswordIcon />
      </i>
      <i
        v-if="isPassword && showPassword && canToggleShowPassword"
        class="show-hide-password-icon"
        :class="{ 'black-theme-icon': isBlackTheme }"
        @click="showPassword = !showPassword"
      >
        <VasionEyeShowPasswordIcon />
      </i>
    </div>
    <i v-if="isURL && showURLLink" class="edit-icons pencil-icon" @click="showURLLink = !showURLLink"><VasionEditIcon /></i>
    <i v-if="isURL && !showURLLink" class="edit-icons" @click="showURLLink = !showURLLink"><VasionTextFieldIcon /></i>
  </div>
</template>
<script>
import AutoNumeric from 'autonumeric';
import { uuid } from 'vue-uuid';
import { formatCurrency, unformatCurrency } from '@/store/helperModules/common.module.js'

export default {
  name: 'VasionInput',
  components: {
  },
  inheritAttrs: false,
  props: {
    arrowClickCallback: {
      type: Function,
      required: false,
      default: null,
    },
    autoFocus: {
      type: Boolean,
      default: false,
      required: false,
    },
    arrowValue: {
      type: Boolean,
      default: null,
      required: false,
    },
    backgroundColor: {
      type: String,
      default: '',
      required: false,
    },
    canToggleShowPassword: {
      type: Boolean,
      default: true,
      required: false,
    },
    currencyFormat: {
      type: String,
      default: '',
      required: false,
    },
    cusutomClass: {
      type: String,
      default: '',
      required: false,
    },
    decimalPlaces: {
      type: Number,
      default: 0,
      required: false,
    },
    extraTitleText: {
      type: String,
      default: '',
      required: false,
    },
    hoverText: {
      type: String,
      default: '',
      required: false,
    },
    initialValue: {
      type: String,
      default: '',
      required: false,
    },
    inputType: {
      type: String,
      default: 'blank-white',
      required: true,
      validator: function (value) {
        return ['top-white', 'blank-white', 'search-gray', 'search-white', 'search-white-top', 'search-on-color', 'left-white', 'title-input', 'top-black'].indexOf(value) !== -1
      },
    },
    isDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    isInErrorState: {
      type: Boolean,
      default: false,
      required: false,
    },
    isTitleInput: {
      type: Boolean,
      default: false,
      required: false,
    },
    maxLengthProp: {
      type: Number,
      required: false,
      default: -1,
    },
    minValue:{
      type:Number,
      default:null,
      required: false
    },
    preventPasswordAutofill: {
      type: Boolean,
      default: null,
      required: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCommas: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTitlePlaceholder: {
      type: Boolean,
      required: false,
      default: false,
    },
    showURLLinkDefault: {
      type: Boolean,
      required: false,
      default: null,
    },
    specialType: {
      type: String,
      required: false,
      default: null,
    },
    temporaryReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    type: {
      type: String,
      default: undefined,
      required: false,
    },
    width: {
      type: String,
      default: '100%',
      required: false,
    },
    widthUnit: {
      type: String,
      default: 'px',
      required: false,
    },
  },
  data() {
    return {
      currencyID: '',
      hoverTitle: !this.hoverText ? '' : this.hoverText + (!this.extraTitleText ? '' : ` (${this.extraTitleText})`),
      isInputValid: true,
      isFormattingCurrency: false,
      lastFormattedValue: '',
      localReadonly: this.readonly,
      numberField: {},
      showURLLink: (this.showURLLinkDefault == null) ? false : this.showURLLinkDefault,
      showPassword: false,
      specialValue: this.initialValue,
    }
  },
  computed: {
    inputGroupWidth() {
      if (this.$options.propsData.width) {
        return this.width.endsWith('%') ? this.width : `${this.width}${this.widthUnit}`
      } else {
        return this.width
      }
    },
    isBlackTheme() { return this.inputType.includes('black') },
    isCheckbox() { return this.specialType && this.specialType.toLowerCase() === 'checkbox' },
    isCurrency() { return this.specialType && this.specialType.toLowerCase() === 'currency' },
    isEmail() { return this.specialType && this.specialType.toLowerCase() === 'email' },
    isNumber() { return this.specialType && this.specialType.toLowerCase() === 'number' },
    isRadioButton() { return this.specialType && this.specialType.toLowerCase() === 'radiobutton' },
    isURL() { return this.specialType && this.specialType.toLowerCase() === 'url' },
    isTextArea() { return this.specialType && this.specialType.toLowerCase() === 'textarea' },
    isPassword() { return this.specialType && this.specialType.toLowerCase() === 'password' },
    isMaskedSSN() { return this.specialType?.toLowerCase() === 'ssn' },
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => this.$emit('input', this.isNumber ? this.numberField.getNumericString() : event.target.value),
      }
    },
    fieldType() {
      if (this.type) {
        return this.type;
      }
      if(this.isPassword) {
        return this.showPassword ? 'text' : 'password';
      }
      return 'text';
    },
  },
  watch: {
    readonly: function (newVal) {
      this.localReadonly = newVal
    },
    value: function (val) {
      this.isInputValid = this.isValid(val)
    },
  },
  mounted() {
    if (this.isCurrency) {
      this.$refs.mainInput.value = formatCurrency(this.$refs.mainInput.value, this.currencyFormat)
    }
    if (this.isNumber) {
      // eslint-disable-next-line
      this.numberField = new AutoNumeric(`#${this.currencyID}`, {
        "digitGroupSeparator": this.showCommas ? ",": "",
        "decimalPlaces": this.decimalPlaces,
        ...(typeof this.minValue==='number' && { minimumValue: this.minValue}),
        watchExternalChanges: true,
        selectOnFocus: false,
      })
    }
    if (this.$refs.mainInput.value && this.isURL && this.showURLLinkDefault == null) { this.showURLLink = true }
    if (this.autoFocus) {
      this.setFocus()
    }
  },
  created() {
    // this is used for an DOM ID and a querySelector.  The querySelector requires that the ID does NOT start with a number
    this.currencyID = `v${uuid.v1().toString()}`
  },
  methods: {
    calculatedClasses: function () {
      const classes = {
        input: [],
        container: [],
      }
      if (this.isDisabled && this.isInErrorState) {
        classes.input.push('expand-height-for-error')
      } else if (this.isInErrorState || !this.isInputValid) {
        classes.container.push('error-state-container')
      }
      if (this.inputType.includes('search')) {
        classes.container.push('search')
      }
      if (this.inputType === 'search-on-color') {
        classes.container.push('search-on-color-container')
        classes.input.push('search-on-color-input')
      }
      if (this.inputType.includes('white')) {
        classes.container.push('white-theme')
      }
      if (this.inputType.includes('gray')) {
        classes.container.push('gray-theme')
      }
      if (this.isBlackTheme) {
        classes.container.push('black-theme')
      }
      if (this.isDisabled) {
        classes.input.push('disabled')
      }
      if (this.isURL && this.showURLLink) {
        classes.input.push('clickable-link')
      }
      return classes
    },
    checkForListNavigation(e) {
      switch (e.code) {
        case 'ArrowUp':
          this.$emit('listNavigation', 'ArrowUp')
          break
        case 'ArrowDown':
          this.$emit('listNavigation', 'ArrowDown')
          break
        default:
          break
      }
    },
    checkRemoveReadonly() {
      this.$emit('focus', true)
      if (this.temporaryReadonly) {
        this.localReadonly = false
      }
    },
    currencyHandler(event) {
      if (this.isFormattingCurrency) return
      
      // Don't reformat if the character is not a numeric value
      const charValue = Number(event.key)
      if (event.key !== '-' && (isNaN(charValue) || event.key === null || event.key === ' ')) {
        event.preventDefault()
        this.isValid(event)
        return
      }

      // Get what the user has typed and also the cursor position
      let typed = event.target.value
      if (typed == this.lastFormattedValue) return
      this.isFormattingCurrency = true
      const pointerPosition = event.target.selectionStart

      // Special logic for handling the minus character for negative currencies
      if (event.key == '-') {
        const noNegatives = typed.replaceAll('-', '')
        const lengthDiff = typed.length - noNegatives.length
        if (lengthDiff === 1) {
          typed = "-" + noNegatives
        } else {
          typed = noNegatives
        }
      }

      // Find the unformatted value of the field, and reformat it with the field format
      // This will allow us to "redisplay" what the user has typed using the format
      let val = unformatCurrency(typed, this.currencyFormat)
      event.target.value = formatCurrency(val, this.currencyFormat)

      // All this whole section does is adjust the cursor and selected remainder as the user types.
      // It does this by comparing what the user has typed with the formatted value, in case
      // the cursor needs to be "bumped" forward due to inserted formatting characters
      let formatCharsTyped = 0
      let numericCharsTyped = 0
      for (var i = 0; i < typed.length; i++) {
        const c = typed[i]
        if (c >= '0' && c <= '9') numericCharsTyped++
        else formatCharsTyped++
      }
      let formatCharsFormatted = 0
      let numericCharsFormatted = 0
      for (i = 0; i < event.target.value.length; i++)
      {
        const c = event.target.value[i]
        if (c >= '0' && c <= '9') numericCharsFormatted++
        else formatCharsFormatted++
        if (numericCharsFormatted == numericCharsTyped) break
      }

      // Bump the cursor based on the number of format charcters inserted
      event.target.selectionStart = pointerPosition + formatCharsFormatted - formatCharsTyped + numericCharsFormatted - numericCharsTyped

      this.isValid(event)
      this.isFormattingCurrency = false
      this.lastFormattedValue = event.target.value
    },
    finalizeCurrency(event) {
      // When the user leaves a currency field, make sure the last thing they typed is reformatted
      let val = unformatCurrency(event.target.value, this.currencyFormat)
      event.target.value = formatCurrency(val, this.currencyFormat)
    },
    maskHandler(event) {
      const charValue = Number(event.key)
      if (isNaN(charValue) || event.key === null || event.key === ' ') {
        event.preventDefault()
        this.isValid(event)
        return
      }

      const pointerPosition = event.target.selectionStart

      let val = event.target.value.replace(/\D/g, '')
      val = val.replace(/^(\d{3})/, '$1-')
      val = val.replace(/-(\d{2})/, '-$1-')
      val = val.replace(/(\d)-(\d{4}).*/, '$1-$2')
      event.target.value = val

      event.target.selectionStart = val.substr(val.length - 1) === "-" ? pointerPosition + 1 : pointerPosition

      this.isValid(event)
    },
    preventAlphaCharacters(event) {
      if (event.key !== "Backspace") {
        const charValue = Number(event.key)
        if (isNaN(charValue) || event.key === null || event.key === ' ') event.preventDefault()
      }
      this.isValid(event)
    },
    isValid(event) {
      if (this.isURL) {
        this.isInputValid = this.validateURL(event.target.value)
      } else if (this.isEmail) {
        this.isInputValid = event.target.value === '' ? true : /\S+@\S+\.\S+/.test(event.target.value)
      } else if (this.isMaskedSSN) {
        this.isInputValid = event.target.value === '' ? true : /^\d{3}-?\d{2}-?\d{4}$/.test(event.target.value)
      }
      if (!this.isInputValid) {
        this.$emit('notValid')
      } else {
        this.$emit('isValid')
      }
    },
    openUrlInNewTab(event) {
      if (event.target.value) {
        window.open(event.target.value)
      }
    },
    setFocus() {
        this.$refs.mainInput.focus()
    },
    updateTitle(event) {
      const currentValue = event.target.value
      const extraTitleText = this.extraTitleText ? ` (${this.extraTitleText})` : ''
      const newTitle = `${currentValue}${extraTitleText}`
      event.target.title = newTitle
    },
    validateURL(value) {
      // From Form.io's implementation so that we match the Eform Entry ..\node_modules\formiojs\validator\rules\Url.js
      /* eslint-disable max-len */
      let re = /^(?:(?:(?:https?|ftps?|sftp):)?\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

      let emailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      /* eslint-enable max-len */
      // Allow urls to be valid if the component is pristine and no value is provided.

      return !value || re.test(value) && !emailRe.test(value);
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
@import '@/assets/css/light-theme-system-palette.scss';

@mixin placeholder($selector, $color) {
  #{$selector}::-webkit-input-placeholder {color: $color}
  #{$selector}::-moz-placeholder          {color: $color}
  #{$selector}:-ms-input-placeholder      {color: $color}
}

@include placeholder('.search-on-color-input', $white);

// ----- Base styles -----
  #currency-dollar-sign {
    position: relative;
    top: 10px;
    left: 6px;
  }
  .full-width {
    width: 100%
  }
  .input-group {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    border-radius: 8px;
    border: solid 1px $grey-100;
    background-color: $grey-50;
    z-index: 1;
  }

  .input-group:hover {
    border: solid 1px $grey-300;
  }

  .input-group:focus-within {
    border: solid 1px $outline-variant;
  }

  .text-input {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0);
    border-style: solid;
    border: none;
    font-family: $font-family-book;
    font-size: 16px;
    z-index: 12;
    padding-left: 8px;
    color: $input-text;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-area {
    resize: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0);
    border-style: solid;
    border: none;
    outline: none;
    font-family: $font-family-book;
    font-size: 16px;
    z-index: 12;
    padding-left: 8px;
    color: $input-text;
  }

  input:focus{
    outline: none;
  }
  .possition-relative {
    position: relative;
  }
  .no-padding {
    padding: 0px;
  }

  .vasion-title-input input {
    font-family: $font-family-bold;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $grey-500;
    margin: auto;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
// ---- Label ----
  .edit-icons {
    position: absolute;
    right: 0px;
    cursor: pointer;
  }
  .pencil-icon {
    z-index: 1;
    bottom: 35px;
  }
  .input-label-left{
    height: 12px;
    font-family: $font-family-medium 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: $grey-400;
    margin-right: 22px;
    float: left;
    padding-top: 12px;
  }

// ---- Arrow ----

  .drop-arrow {
    position: relative;
    right: 8px;
    top: 1px;
    cursor: pointer;
  }

  @media screen and (min--moz-device-pixel-ratio:0) {
  .drop-arrow {
    z-index: 12;
  }
 }

// ---- Search ----

  .search {
    & input {
      padding-left: 45px;
    }
    & > .search-icon {
      position: absolute;
      padding: 6px 0px 0px 5px;
      fill: $grey-300;
    }
  }

// Input/Search/OnColor
  .clickable-link {
    color: blue;
    text-decoration: underline;
    cursor: pointer !important;
  }
  .error-state-label {
    color: $error !important;
  }
  .error-state-container {
    border: 1px solid $error !important;
  }

  .input-group {
    &.search-on-color-container {
      border: solid 1px rgba(255, 255, 255, 0.24);
      background-color: rgba(255, 255, 255, 0.12);
      & > .search-icon {
        fill: $white;
      }
      & > .drop-arrow {
        fill: $white;
      }
    }
  }
  .search-on-color-input{
    color: $white;
  }

  .white-theme {
    border: solid 1px $grey-100;
    background-color: $white;

    ::placeholder {
      color: $grey-300;
    }
  }

  .black-theme {
    border: solid 1px $grey-300;
    background-color: rgba(0, 0, 0, 0.2);
    .text-input {
      color: $grey-300;
    }
  }

  .black-theme-icon {
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }

  .gray-theme {
    border: solid 1px $grey-100;
    background-color: $white !important;
  }

  .title-placeholder-div {
    height: 20px;
  }

  .isLow {
    .white-theme {
      background-color: $grey-100;
    }
    .drop-arrow {
      fill: $grey-500;
    }
    .text-input {
      color: $grey-500;
      font-size: 14px;
    }
  }

  .isNormal {
    .white-theme {
      background-color: $blue-50;
    }
    .drop-arrow {
      fill: $blue-300;
    }
    .text-input {
      color: $blue-300;
      font-size: 14px;

    }
  }

  .isHigh {
    .white-theme {
       background-color: $red-50;
    }
    .drop-arrow {
      fill: $red-300;
    }
    .text-input {
       color: $red-300;
       font-size: 14px;
    }
  }

  .disabled {
    color: $grey-400;
    background-color: $grey-75;
  }
  .expand-height-for-error {
    border: 1px solid $error-red !important;
    height: 41px;
  }
  .show-hide-password-icon {
    margin-right: 1rem;
    cursor: pointer;
  }
  
  .success input {
    background-color: $success-bg !important;
  }
  .success .input-group {
    border: 1px solid $success-border !important;
  }
  .success input {
    border-radius: 10px !important;
  }
  .warning input {
    background-color: $warning-bg !important;
  }
  .warning .input-group {
    border: 1px solid $warning-border !important;
  }
  .warning input {
    border-radius: 10px !important;
  }
  .error input {
    background-color: $error-bg !important;
  }
  .error .input-group {
    border: 1px solid $error-border !important;
  }
  .error input {
    border-radius: 10px !important;
  }
</style>
