<template>
  <div :key="refreshKey" v-observe-visibility="visibilityChanged" class="fillWidth">
    <div class="documentWrapper">
      <div
        id="canvasWrapper24"
        :style="{ width: containerWidth, height: containerHeight, transform: `scale(${zoomScale})`, 'transform-origin': transformOrigin}"
        :class="{ rotateMeImage: largeDefaultLoadSize, documentWrapperInnerNewSignature: isInNewSignatureMode , documentWrapperInner: isNotInNewSignatureMode }"
      >
        <span v-show="!dataLoaded && !noImage">Loading Image...</span>
        <span v-show="noImage" id="photo-empty"><VasionPhotoEmpty /></span>
        <!-- <img class="documentImage" :style="{ width: width+'px', height: height+'px' }" v-show="dataLoaded" :src="imageSource"> -->

        <!--
          // TODO: Create a new 'DocumentImage' component called 'ThumbnailImage' and move the below code into it
        <ThumbnailCanvas
          v-if="isThumbnail && dataLoaded"
          class="documentAnnotations"
          :key="refreshKey"
          :canvasReference="'thumbnails-canvas-context'"
          :id="annotationsID"
          :canvasWidth="width"
          :canvasHeight="height">
            // TODO: Create a new 'AnnotationsCanvasContext' component called 'DocumentCanvasContext' and move the below code into it
            <AnnotationsCanvasContext
              :key="refreshKey"
              :largeDefaultLoadSize="largeDefaultLoadSize"
              :annotations="annotations"
              :image="image"
              :pageNumber="pageNumber">
            </AnnotationsCanvasContext>
        </ThumbnailCanvas>
        -->
        <AnnotationsCanvas
          v-if="!isThumbnail && dataLoaded"
          :id="annotationsID"
          ref="annoCanvas"
          :key="refreshKey"
          class="documentAnnotations"
          :class="{'smallImage': smallImage}"
          :canvasReference="'annotations-canvas-context'"
          :canvasWidth="image.Width"
          :canvasHeight="image.Height"
          :canDelete="canDeleteAnnotations"
        >
          <AnnotationsCanvasContext
            v-if="context === 'Annotations' && annotations !== null"
            :key="refreshKey"
            :largeDefaultLoadSize="largeDefaultLoadSize"
            :annotations="annotations"
            :image="image"
            :isSignatureDocument="isSignatureDocument"
            :canDelete="canDeleteAnnotations"
            :pageNumber="pageNumber"
            @backgroundImageLoaded="$emit('backgroundImageLoaded', width)"
            @emptyTextAnnotations="setEmptyTextAnnotations"
            @checkRightSidePanelMobile="checkRightSidePanelMobile"

          />
          <AutoSignConfigContext
            v-if="context === 'AutoSignConfig'"
            :key="refreshKey"
            :largeDefaultLoadSize="largeDefaultLoadSize"
            :image="image"
            :pageNumber="pageNumber"
          />
          <SignatureAppContext
            v-if="context === 'SignatureApp'"
            :key="pageNumber"
            :disableSaveAnnotations="disableMoveAndSaveAnnotations"
            :largeDefaultLoadSize="largeDefaultLoadSize"
            :image="image"
            :pageNumber="pageNumber"
            @annotationDeleted="$emit('annotationDeleted', $event)"
            @annotationUpdate="$emit('annotationUpdate')"
            @annotationCreated="$emit('annotationCreated')"
          />
          <AIPZonesContext
            v-if="context === 'AIPZones'"
            :key="refreshKey"
            :largeDefaultLoadSize="largeDefaultLoadSize"
            :image="image"
            :pageNumber="pageNumber"
          />
          <DocTemplateZonesContext
            v-if="context === 'DocTemplateZones'"
            :key="refreshKey"
            :largeDefaultLoadSize="largeDefaultLoadSize"
            :image="image"
            :pageNumber="pageNumber"
          />
          <AnnotationsAndAIPZones
            v-if="context === 'AnnotationsAndAIPZones' && annotations !== null"
            :key="refreshKey"
            :largeDefaultLoadSize="largeDefaultLoadSize"
            :annotations="annotations"
            :image="image"
            :isTextractAIP="isTextractAIP"
            :canDelete="canDeleteAnnotations"
            :pageNumber="pageNumber"
            :aipZones="aipZonesConverted"
            :disableMoveAndSaveAnnotations="disableMoveAndSaveAnnotations"
            :selectedAIP="selectedAIP"
            @backgroundImageLoaded="$emit('backgroundImageLoaded', width)"
          />
        </AnnotationsCanvas>
      </div>
    </div>
    <VasionAsynchronousConfirmDialog
      ref="documentPasswordConfirm"
      needsPassword="Enter Document Password"
      confirmText="Enter"
      cancelText="Back To Previous View"
    />
  </div>
</template>

<script>
import AIPZonesContext from '@/components/annotations/AIPZonesContext.vue';
import AnnotationsAndAIPZones from '@/components/annotations/AnnotationsAndAIPZones.vue';
import AnnotationsCanvas from '@/components/annotations/AnnotationsCanvas.vue';
import AnnotationsCanvasContext from '@/components/annotations/AnnotationsCanvasContext.vue';
import AutoSignConfigContext from '@/components/annotations/AutoSignConfigContext.vue';
import DocTemplateZonesContext from '@/components/annotations/DocTemplateZonesContext.vue';
import SignatureAppContext from '@/components/annotations/SignatureAppContext.vue';
// import ThumbnailCanvas from '@/components/annotations/ThumbnailCanvas.vue';
import VasionAsynchronousConfirmDialog from '@/components/shared/VasionAsynchronousConfirmDialog.vue'

export default {
  name: 'DocumentImage',
  components: {
    AIPZonesContext,
    AnnotationsAndAIPZones,
    AnnotationsCanvas,
    AnnotationsCanvasContext,
    AutoSignConfigContext,
    DocTemplateZonesContext,
    SignatureAppContext,
    // ThumbnailCanvas,
    VasionAsynchronousConfirmDialog,
  },
  props: {
    aipZones: {
      type: Object,
      required: false,
      default: () => {},
    },
    burnAnnotations: {
      type: Boolean,
      required: false,
      default: false,
    },
    canDeleteAnnotations: {
      type: Boolean,
      required: false,
      default: true,
    },
    context: {
      type: String,
      required: false,
      default: 'Annotations',
    },
    disableMoveAndSaveAnnotations: {
      required: false,
      type: Boolean,
      default: false,
    },
    docPassword: {
      required: false,
      type: String,
      default: '',
    },
    documentID: {
      required: true,
      type: Number,
    },
    documentRequiresPassword: {
      required: false,
      type: Boolean,
      default: false,
    },
    fileDetail: {
      required: false,
      type: Object,
      default: null,
    },
    filePath: {
      type: String,
      required: false,
      default: '',
    },
    height: {
      required: true,
      type: Number,
    },
    includeProcessedAIPZones: {
      type: Boolean,
      required: false,
      default: false,
    },
    imageEndpoint: {
      type: String,
      required: false,
      default: 'document/getDocumentImageURL',
    },
    isSignatureDocument: {
      required: false,
      type: Boolean,
      default: false,
    },
    isThumbnail: {
      type: Boolean,
      required: false,
      default: false,
    },
    largeDefaultLoadSize: {
      required: false,
      type: Boolean,
      default: false,
    },
    loadOnVisible: {
      required: false,
      type: Boolean,
      default: false,
    },
    pageNumber: {
      required: true,
      type: Number,
    },
    selectedAIP: {
      required: false,
      type: String,
      default: '',
    },
    smallImage: {
      required: false,
      type: Boolean,
      default: false,
    },
    transformOrigin: {
      required: false,
      type: String,
      default: 'center'
    },
    width: {
      required: true,
      type: Number,
    },
    zoomScale: {
      required: false,
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      annotations: null,
      archiveData: [],
      dataLoaded: false,
      image: {},
      imageSource: '',
      isVisible: false,
      noImage: false,
      leftPercent: '50%',
      refreshOnVisible: false,
    };
  },
  computed: {
    aipZonesConverted() {
      let aipZones = JSON.parse(JSON.stringify(this.aipZones))
      if (!aipZones.values) return {}
      aipZones.values.forEach(value => {
        value.ZoneHeight = this.image.Height * (value.ZoneHeight / (this.image.OriginalHeight / this.image.OriginalDPI))
        value.ZoneWidth = this.image.Width * (value.ZoneWidth / (this.image.OriginalWidth / this.image.OriginalDPI))
        value.ZoneX = this.image.Width * (value.ZoneX / (this.image.OriginalWidth / this.image.OriginalDPI))
        value.ZoneY = this.image.Height * (value.ZoneY / (this.image.OriginalHeight / this.image.OriginalDPI))
      })
      return aipZones
    },
    annotationsID() {
      if (this.largeDefaultLoadSize) {
        return 'annotationsCanvas_main'
      } else {
        return `annotationsCanvas_${this.pageNumber}`
      }
    },
    containerHeight() {
      // to make sure the sure can scroll far enough to see the whole document without a lot of extra space we
      // multipy the zoomValue by the images natural height and add 20 as a buffer
      return (this.zoomValue * this.image.Height + 20) + 'px'
    },
    containerWidth() {
      // to make sure the sure can scroll far enough to see the whole document without a lot of extra space we
      // multipy the zoomValue by the images natural width and add 20 as a buffer
      return (this.zoomValue * this.image.Width + 20)+'px'
    },
    isInNewSignatureMode() { return this.$route.name === 'NewDocument' },
    isInSignatureMode() { return this.$route.name !== 'SignatureEditDocument' && this.$store.state.mainViews.activeSubApp === 'signatures' },
    isNotInNewSignatureMode() { return this.$route.name !== 'NewDocument' },
    refreshKey() { return this.$store.state.document.refreshKey },
    zoomValue() { return this.$store.state.document.zoomValue },
    isTextractAIP() { return this.imageEndpoint === 'document/getTextractDocumentImageURL'}
  },
  watch: {
    pageNumber: function () {
      this.updateImageSource()
      this.scrollToTop()
    },
    fileDetail: function () {
      if (this.fileDetail) {
        this.updateImageSource()
      }
    },
  },
  async mounted() {
    this.$root.documentPasswordConfirm = this.$refs.documentPasswordConfirm;
  },
  async created() {
    await this.updateImageSource()
  },
  methods: {
    scrollToTop() {
      const elements = document.getElementsByClassName("mainImageDiv")
      if (elements.length) elements[0].scrollTop = 0
    },
    checkRightSidePanelMobile() {
      this.$emit('checkRightSidePanelMobile')
    },
    setEmptyTextAnnotations(count) {
      this.$emit('emptyTextAnnotations', count)
    },
    async updateImageSource() {
      let documentImageLoaded = false
      while (!documentImageLoaded) {
        if (this.imageEndpoint === "document/getDocumentImageURL" && (this.fileDetail === undefined || this.fileDetail === null)){
          return
        }
        if (this.pageNumber <= 0) {
          return
        }

        if (this.isVisible === false && this.loadOnVisible === true) {
          this.refreshOnVisible = true
          return
        }

        this.dataLoaded = false
        this.noImage = false

        const existingPage = this.archiveData.find((page) => {
          return page.documentID === this.documentID && page.pageNumber === this.pageNumber
        })

        if (existingPage && existingPage.imageSource) {
          if (this.imageSource !== existingPage.imageSource) {
            this.imageSource = existingPage.imageSource
            this.$emit('imageLoaded', existingPage)
          }

          this.dataLoaded = true
          this.annotations = this.burnAnnotations ? [] : existingPage.annotations
          this.image = existingPage
          return
        }

        let payload = {}
        let password = this.docPassword
        if (this.imageEndpoint === 'document/getDocumentImageURL') {
          if (this.documentRequiresPassword && !password) {
            let res = await this.$root.documentPasswordConfirm.open({})
            if (!res) {
              this.$emit('backToPreviousView')
            } else {
              this.$emit('passwordEntered', this.$refs.documentPasswordConfirm.password)
              return
            }
          }
          if (!this.fileDetail) return
          payload = {
            DocumentId: this.documentID,
            VersionNumber: this.$route.params.version ? this.$route.params.version : '',
            ImageWidth: this.largeDefaultLoadSize === true ? 1632 : this.width,
            ImageHeight: this.largeDefaultLoadSize === true ? 2112 : this.height,
            IncludeAnno: this.burnAnnotations ? this.burnAnnotations : false,
            PageNum: this.pageNumber,
            RenderMode: 0,
            ThumbWidth: 100,
            ThumbHeight: 150,
            ZoomMode: 1,
            RotationDegrees: -2, // This makes it refresh the image instead of loading from cache
            FileDetail: this.fileDetail,
            Password: password,
          }
        } else if (this.imageEndpoint === 'document/getFileImageURL' || this.imageEndpoint === 'document/getTextractDocumentImageURL') {
          if (!this.filePath || this.filePath === '') {
            console.warn(`The filePath Prop is required for the "${this.imageEndpoint}" endpoint`)
          }

          payload = {
            FilePath: this.filePath,
            ImageWidth: this.largeDefaultLoadSize === true ? 2112 : this.width,
            ImageHeight: this.largeDefaultLoadSize === true ? 1632 : this.height,
            IncludeAnno: this.burnAnnotations ? this.burnAnnotations : false,
            PageNum: this.pageNumber,
            RenderMode: 0,
            ThumbWidth: 100,
            ThumbHeight: 150,
            ZoomMode: 1,
            RotateDegrees: -2, // This makes it refresh the image instead of loading from cache
          }
        }
        let data = await this.$store.dispatch(this.imageEndpoint, payload)
        if (data === undefined) {
          this.noImage = true
          this.$emit('noImage')
          return
        }
        if (data !== -1) {
          this.imageSource = data.url
          this.dataLoaded = true
          const newImage = {
            DocumentId: this.documentID,
            PageNumber: this.pageNumber,
            ImageSource: this.imageSource,
            Width: data.width,
            Height: data.height,
            Annotations: { Values: data.annotations },
            PageDPI: data.PageDPI,
            OriginalDPI: data.OriginalDPI,
            OriginalHeight: data.OriginalHeight,
            OriginalWidth: data.OriginalWidth,
            FilePath: this.filePath,
          }

          this.archiveData.push(newImage)
          const annArr = []

          this.annotations = { Values: data.annotations }
          data.annotations.forEach(e => {
            const obj = {
                AnnType: e.Properties.subType === 'checkbox' ? 'checkbox' : 'Rectangle',
                height: e.Height,
                width: e.Width,
                top: e.PosY,
                left: e.PosX,
                UniqueID: e.UniqueID,
                subType: e.Properties.subType,
                includeTime: e.Properties.includeTime === undefined ? false : e.Properties.includeTime,
                bottomAlign: e.Properties.bottomAlign === undefined ? true : e.Properties.bottomAlign,
                isRequired: e.Properties.isRequired === undefined ? true : e.Properties.isRequired,
                ...(e.Properties.subType === 'checkbox' && { PICTURE_BASE64: e.Properties.PICTURE_BASE64 }),
              }
            annArr.push(obj)
          })
          this.image = newImage
          await this.$store.dispatch('workflow/storeSignatureZoneAnnotations', annArr)
          documentImageLoaded = true
          this.$emit('fileImageLoaded', newImage)
          this.noImage = false
        } else {
          this.$emit('password-failed')
        }
      }
    },
    visibilityChanged(isVisible) {
      this.isVisible = isVisible
      if (this.isVisible === true && this.refreshOnVisible === true) {
        const me = this
        setTimeout(() => { me.updateImageSource() }, 250)
      }
    },
  },

}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  #photo-empty {
    position: absolute;
    margin-left: 100px;
    margin-top: 160px;
  }

  .fillWidth {
    width: 100%;
    justify-content: center;
  }

  .documentWrapper {
    width: 100%;
    height: 100%;
  }

  .documentWrapperInner {
    margin: 0 auto;
  }

  .documentAnnotations {
    background-color: rgba(255, 255, 255, .1);
    margin-bottom: 20px;
  }
</style>
