<template>
  <div id="toolbar-pagination" class="flex-display">
    <div class="toolbar-height">
      <VasionButton
        id="btnFirstPage"
        class="toolbar-icon"
        name="btnFirstPage"
        title="First Page"
        :icon="'VasionFirstPageIcon'"
        :isDisabled="disablePreviousButtons"
        @vasionButtonClicked="goToFirstPage()"
      />
      <VasionButton
        id="btnPreviousPage"
        class="toolbar-icon"
        name="btnPreviousPage"
        title="Previous Page"
        :icon="'VasionPreviousPageIcon'"
        :isDisabled="disablePreviousButtons"
        @vasionButtonClicked="goToPreviousPage()"
      />
    </div>
    <div class="w60">
      <VasionInput
        id="current-page"
        v-model="currentPageInput"
        name="currentPage"
        placeholder="Page #"
        inputType="top-white"
        @focus="pageInputFocus"
        @blur="pageInputBlur"
        @keydown.enter="pageInputEnter"
      />
    </div>
    <div class="toolbar-height">
      <VasionButton
        id="btnNextPage"
        class="toolbar-icon"
        name="btnNextPage"
        title="Next Page"
        :icon="'VasionNextPageIcon'"
        :isDisabled="disableNextButtons"
        @vasionButtonClicked="goToNextPage()"
      />
      <VasionButton
        id="btnLastPage"
        class="toolbar-icon"
        name="btnLastPage"
        title="Last Page"
        :icon="'VasionLastPageIcon'"
        :isDisabled="disableNextButtons"
        @vasionButtonClicked="goToLastPage()"
      />
    </div>
    <div v-if="showPageSize" class="margin-right page-size-div">
      <VasionDropList
        v-slot="slotItem"
        v-model="selectedPageSize"
        :dataArray="localPageSizeOptions"
        :showSearchField="false"
        width="100%"
        type="plain-list"
        displayName="name"
        valueName="name"
        placeholder=""
        searchPlaceholder=""
      >
        {{ slotItem.item.name }}
      </VasionDropList>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VasionPager',
  props: {
    currentPageNumber: {
      type: Number,
      required: true,
    },
    showPageSize: {
      type: Boolean,
      default: true,
      required: false,
    },
    totalPages: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data: function() {
    return {
      currentPageInput: '',
      localCurrentPageNumber: -1,
      localPageSizeOptions: [],
      selectedPageSize: {
        name: '100',
        value: 100,
      },
    }
  },
  computed: {
    disableNextButtons() { return this.localCurrentPageNumber >= this.totalPages },
    disablePreviousButtons() { return this.localCurrentPageNumber <= 1 },
    pageSize: {
      get: function () {
        return this.$store.state.common.pageSize
      },
      set: function (newSize) {
        this.$store.dispatch('common/setPageSize', newSize)
      },
    },
    pageSizeOptions() { return this.$store.state.common.pageSizeOptions },
  },
  watch: {
    currentPageNumber: function () {
      this.localCurrentPageNumber = this.currentPageNumber
      this.updatePageDisplay()
    },
    pageSize: function () {
      this.goToFirstPage()
    },
    selectedPageSize: {
      handler: function () {
        if (this.selectedPageSize && this.selectedPageSize.name) {
          this.pageSize = this.selectedPageSize.value
        }
      },
      deep: true, // if .name changes on pages size, then we'll pick it up
    },
    totalPages: function () {
      this.updatePageDisplay()
    },
  },
  created() {
    this.localCurrentPageNumber = this.currentPageNumber

    setTimeout(() => {
      this.localPageSizeOptions = this.pageSizeOptions.map((element) => {
        return {
          name: element.toString(),
          value: element,
        }
      })

      const localPageSize = this.pageSize && this.pageSize > 0 ? this.pageSize : 100

      this.selectedPageSize = this.localPageSizeOptions.find((option) => {
        return option.value === localPageSize
      })
    }, 100) // Loads too fast without this.

    this.updatePageDisplay()
  },
  methods: {
    goToFirstPage() {
      this.goToPage(1)
    },
    goToLastPage() {
      this.goToPage(this.totalPages)
    },
    goToNextPage() {
      if ((this.localCurrentPageNumber + 1) <= this.totalPages) {
        this.goToPage(this.localCurrentPageNumber + 1)
      }
    },
    goToPage(pageNumber) {
      if (typeof pageNumber !== 'undefined' && pageNumber > 0 && pageNumber <= this.totalPages) {
        this.localCurrentPageNumber = pageNumber
        this.updatePageDisplay()
        this.$emit("pageChanged", this.localCurrentPageNumber)
      }
    },
    goToPreviousPage() {
      if (this.localCurrentPageNumber > 1) {
        this.goToPage(this.localCurrentPageNumber - 1)
      }
    },
    pageInputBlur() {
      let pageInput = parseInt(this.currentPageInput)
      if (isNaN(pageInput) || pageInput <= 0 || pageInput > this.totalPages) {
        this.updatePageDisplay()
      }
      else {
        this.goToPage(pageInput)
      }
    },
    pageInputEnter(event) {
      event.target.blur()
    },
    pageInputFocus() {
      this.currentPageInput = ''
    },
    updatePageDisplay() {
      this.currentPageInput = `${this.localCurrentPageNumber}/${this.totalPages}`
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  #btnLastPage {
    margin-right: 10px;
  }

  .flex-display {
    display: flex;
  }

  .moveUp {
    position: relative;
    top: -3px;
  }

  .page-size-div {
    width: 94px;
  }

  .toolbar-icon {
    margin-left: 0px;
  }

  .w60 {
    width: 86px;
    height: 30px;
  }
</style>
