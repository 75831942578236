@ -0,0 +1,313 @@
<template>
  <div>
    <div class="vasion-container-flex vasion-white-background">
      <div :class="{'z-index-fix': !isDisplayingNavBarDialog && !isMobile}" v-show="isSignedIn  && activeSubAppSet && isLoginRouteNotActive && !isSignatureDocLinkView && !doHide">
        <div class="mobile-menu-nav">
          <VasionCloseIcon v-if="isMobileSideRailActive && isMobile" class="mobile-menu-close-icon" @click="isMobileSideRailActive = !isMobileSideRailActive" />
          <VasionArrowLeftIcon v-if="isMobileSideBarActive && isMobile" class="mobile-menu-close-icon" @click="backSelectedFromSideBar" />
          <span v-if="(isMobileSideBarActive) && isMobile" :class="{'product-name': !isMobile, 'product-name-mobile': isMobile}">{{ isMobileSideRailActive ? 'VASION' : activeSubApp }}</span>
        </div>
        <SideRail v-if="apiToken && (isMobileSideRailActive || !isMobile) && !automateBridgeEnabled" :isMobile.sync="isMobile" @appSelected="appSelectedFromSideRail" />
        <div id="product-bridge" :style="{ display: displayRail }" />
        <TheMainSideNav v-if="apiToken && !hideSideNav && !isVasionHome && isMobile && !isFullPage" class="side-bar" :isMobile.sync="isMobile" :class="{'small-screen-collapse': !isMobileSideBarActive, 'side-bar-mobile': isMobile}" @itemSelected="itemSelectedFromSideBar" />
        <!-- <div v-if="isMobileSideBarActive && !isVasionHome && !isMobile" class="side-bar-close-button" @click="isMobileSideBarActive = !isMobileSideBarActive">
          <VasionCloseIcon class="side-bar-close-icon" />
        </div> -->
     </div>

      <!-- SIGNATURE DOCUMENT LINK PAGES -->
      <div v-if="isSignatureDocLinkView">
        <NavBar @showDialog="navBarDialog" :isMobile.sync="isMobile" />
        <transition name="component-fade" mode="out-in">
          <router-view v-if="activeSubAppSet" :key="$route.fullPath" to="/" />
        </transition>
      </div>

      <!-- VALID PAGES -->
      <div v-else-if="isSignedIn && isLoginRouteNotActive" class="main-class-view" :class="{'vasion-main-nav-mini-mode': !mainNavExpanded, 'vasion-main-nav-full-mode': mainNavExpanded, 'z-index-fix': isDisplayingNavBarDialog, 'full-width': mainContainerWithFullWidth}"> 
        <div v-if="!doHide" class="top-bar" :class="{'top-bar-mobile': isMobile}">
          <div v-if="isSignedIn && isMobile" class="mobile-side-bar-toggle" @click="isMobileSideRailActive = true">
            <VasionMenu v-if="isMobile && !isMobileSideBarActive && !isMobileSideRailActive" class="hamburger-icon" />
          </div>
          <NavBar @showDialog="navBarDialog" class="nav" :isMobile.sync="isMobile" />
        </div>
        <div class="vap-height-100-less-header w-100 d-flex">
          <TheMainSideNav v-if="apiToken && !hideSideNav && !isMobile && !isFullPage && !doHide" class="side-bar" :isMobile.sync="isMobile" :class="{'small-screen-collapse': !isMobileSideBarActive, 'side-bar-mobile': isMobile}" @itemSelected="itemSelectedFromSideBar" />
          <main :class="{ 'full-page': isFullPage }">
            <transition name="component-fade" mode="out-in">
              <router-view v-if="activeSubAppSet" :key="$route.fullPath" to="/" />
            </transition>
          </main>
        </div>
      </div>

      <!-- INVALID PAGE -->
      <div v-else>
        <NavBar />
        <transition name="component-fade" mode="out-in">
          <router-view to="/" />
        </transition>
      </div>
    </div>
    <md-dialog id="timeout-error" :md-active.sync="showNetworkTimeoutModal" :md-click-outside-to-close="false">
      <div class="dialog-div">
        <h1>Are you still there?</h1>
        <div id="dialog-message" ref="dialogMessage">
          It seems that you have left. Would you like to stay logged in?<br />
          You will be automatically logged out in {{ userLogoutIn }} seconds.
        </div>
        <div class="button-div">
          <VasionButton id="btnSignout" :classProp="'secondary'" @vasionButtonClicked="signOut">
            LOGOUT
          </VasionButton>
          <VasionButton id="btnStayLoggedIn" :classProp="'primary'" @vasionButtonClicked="stayLoggedIn">
            I'M STILL HERE
          </VasionButton>
        </div>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import NavBar from '@/components/mainViews/NavBar.vue';
import TheMainSideNav from '@/components/mainViews/TheMainSideNav.vue';
import SideRail from '@/components/mainViews/SideRail.vue';
import { isMobileSize } from '@/store/helperModules/common.module';

export default {
  name: 'TheMainView',
  components: {
    NavBar,
    SideRail,
    TheMainSideNav,
  },
  data: function () {
    return {
      doHide: false,
      isDisplayingNavBarDialog: false,
      isMobile : false,
      isMobileSideRailActive: false,
      isMobileSideBarActive: false,
      menuVisible: false,
      userLogoutCountdown: null,
      userLogoutIn: 0,
      userTimeoutCheck: null,
    }
  },
  computed: {
    activeSubApp() { return this.$store.state.mainViews.activeSubApp.toUpperCase() },
    activeSubAppSet() { return this.$store.state.mainViews.activeSubApp !== null },
    activateSideNav() { return this.$store.state.mainViews.activateSideNav },
    apiToken() { 
      const apiToken = (this.$store.state.common.isMSAApproved === null || this.$store.state.common.isMSAApproved === true || this.$store.state.common.userID == 1) ? this.$store.state.common.apiToken : ''
      return apiToken 
    },
    isFullPage() {
      return this.$route.name === 'TheNewFormView' ||
      this.$route.name === 'NewDocument' ||
      this.$route.name === 'SignatureEditDocument' ||
      this.$route.name === 'ConfigureEmailTemplate' ||
      this.$route.name === 'EditDocumentTemplate' ||
      this.$route.name === 'TheVasionHome'
    },
    automateBridgeEnabled() { return this.$store.state.systemSettings.featureFlags.vasionAutomateBridge },
    displayRail() { return this.automateBridgeEnabled ? 'block' : 'none' },
    hideNavbarAndHeader() { return this.$store.state.common.hideNavbarAndHeader },
    hideSideNav() { return this.$route.name == 'UserSettings' },
    isLoginRouteNotActive() { return this.$route.name !== 'TheLogin' },
    isSignatureDocLinkView() { return this.$store.state.common.isSignatureDocLinkView },
    isSignedIn() { return this.$store.state.common.isSignedIn },
    isVasionHome() { return this.$store.state.mainViews.vasionHomeToggle },
    mainContainerWithFullWidth() { return this.$store.state.common.mainContainerWithFullWidth },
    mainNavExpanded() { return this.$store.state.mainViews.mainNavExpanded },
    needsPasswordReset() { return this.$store.state.common.needsPasswordReset },
    showNetworkTimeoutModal() { return this.$store.state.mainViews.showNetworkTimeoutModal },
    userSessionExpiry() { return this.$store.state.common.userSessionExpiry },
  },
  watch: {
    activateSideNav() { 
      if (this.activateSideNav) this.isMobileSideBarActive = true
    },
    hideNavbarAndHeader() { this.doHide = this.hideNavbarAndHeader },
    isMobileSideBarActive() {
      if (!this.isMobileSideBarActive) this.$store.dispatch('mainViews/activateSideNav', false)
    },
    isSignedIn: function (value) {
      if (value) {
        this.$store.dispatch('admin/getUserSetting', 'defaultApp')
        this.createUserSessionTimer()
        this.setAmplitudeUserProperties()
        // eslint-disable-next-line
        System.import(process.env.VUE_APP_BRIDGE_JS)
      } else {
        clearInterval(this.userTimeoutCheck)
        clearInterval(this.userLogoutCountdown)
      }
    },
  },
  created() {
    if (this.isSignedIn) {
      this.$store.dispatch('admin/getUserSetting', 'defaultApp')
    }
  },
  mounted() {
    this.createUserSessionTimer()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
      this.onResize()
    })
  },
  beforeDestroy () {
    clearInterval(this.userTimeoutCheck)
    clearInterval(this.userLogoutCountdown)
    window.removeEventListener('resize', this.onResize); 
  },
  methods: {
    appSelectedFromSideRail()
    {
      this.isMobileSideRailActive = false
      this.isMobileSideBarActive = true
    },
    backSelectedFromSideBar()
    {
      this.isMobileSideRailActive = true
      this.isMobileSideBarActive = false
    },
    createUserSessionTimer() {
      clearInterval(this.userLogoutCountdown)
      if (this.isSignedIn && this.userSessionExpiry) {
        this.userTimeoutCheck = setInterval(() => {
          const now = new Date().getTime()
          if (this.userSessionExpiry - now <= 75000) {
            this.$store.dispatch('mainViews/setNetworkTimeoutModal', true)
            clearInterval(this.userTimeoutCheck)
            this.userLogoutIn = Math.round((this.userSessionExpiry - now)/1000 - 5)
            this.userLogoutCountdown = setInterval( () => {
              this.userLogoutIn = Math.round((this.userSessionExpiry - new Date().getTime())/1000 - 5)
              if (this.isSignedIn && this.userLogoutIn <= 0) {
                this.signOut(true)
              }
            }, 1000)
          }
        }, 10000)
      }
    },
    itemSelectedFromSideBar() {
      this.isMobileSideBarActive = false
    },
    navBarDialog(showing) { this.isDisplayingNavBarDialog = showing },
    async setAmplitudeUserProperties() {
      await this.$amplitude.checkAmplitudeAvailability()
    },
    onResize() {
      this.isMobile = isMobileSize()   
    },
    async signOut(isTokenExpired = false) {
      clearInterval(this.userTimeoutCheck)
      clearInterval(this.userLogoutCountdown)
      this.$router.push({ name: 'Logout', query: { isTokenExpired: isTokenExpired } })
    },
    async stayLoggedIn() { 
      await this.$store.dispatch('common/checkIsAuthenticated')
      this.toggleNetworkTimeoutModal()
      this.createUserSessionTimer()
    },
    toggleMenu() { this.menuVisible = !this.menuVisible },
    toggleNetworkTimeoutModal() { this.$store.dispatch('mainViews/setNetworkTimeoutModal', !this.showNetworkTimeoutModal) },
  },
}
</script>

<style lang="scss" scoped>
    @import '@/assets/css/variables.scss';
    @import '@/assets/css/light-theme-system-palette.scss';

    .top-bar{
      display: flex;
      width:100%;
      padding: 24px 24px 12px 24px;
      background-color: $surface;
    }
    
    .top-bar-mobile{
      width:100vw;
    }

    .nav{
      width: 100%;
    }

    .mobile-side-bar-toggle{
      display: none;
    }

    hamburger-icon{
      display: none;
    }

    side-bar-close-button{
      display: none;
    }

    @media (max-width: $breakpoint-lg) {
      .side-bar{
        visibility: visible;
        transition: .275s;
        width: 256px;
      }

      .side-bar-mobile {
        width: 100vw;
      }

      .small-screen-collapse{
        width: 0px;
        visibility: hidden;
        transition: .275s;
        left: 0;
        padding: 0px;
      }

      .mobile-side-bar-toggle{
        width: 56px;
        height: 56px;
        background-color: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .hamburger-icon{
        padding: 0px;
        margin: 0px;
        fill: $white;
      }

      .side-bar-close-button{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0;
        left: 338px;
        margin-top: 12px;
        z-index: 6;

      }

      .side-bar-close-icon path{
        fill: white !important;
      }

      .overlay-effect{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 1;
        transition: .5s ease;
        background-color: rgba(0,0,0, 0.4);
        overflow:hidden;
        z-index: 6;
      }
    }

    .vasion-container-flex{
        display: flex;
    }

    #timeout-error {
      z-index: 5000;
    }

    .dialog-div {
      text-align: center;
      padding: 15px 10px 0px 10px;
      height: 190px;
      width: 450px;
    }

    .button-div {
      position: relative;
      top: 10px;
      margin: 0 auto;
    }

    .z-index-fix {
      display: flex;
      // z-index: 6;
    }
    .full-width {
      width: 100vw;
    }

    .mobile-menu-nav {
      height: 56px;
      background-color: $primary;
      display: flex;
      align-items: center;
    }

    .mobile-menu-close-icon {
      margin: 16px;
      fill: $white;
    }

    .product-name {
      color: $primary;
      font-family: $font-family-accent;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .product-name-mobile {
      color: $on-primary;
      font-family: $font-family-accent;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

main {
  background-color: $surface;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
  padding: 0px;
  width: calc(100vw - 328px);
  z-index: 1;
}

::v-deep main > div {
  height: 100%;
  width: 100%;
}

@media (max-width: $breakpoint-md) {
  .top-bar {
    padding: 0px 26px 0px 8px;
    background-color: $primary;
  }
}

@media (max-width: $breakpoint-lg) {
  main {
    width: calc(100vw - 72px);
  }
}

#product-bridge {
  width: 72px;
  height: 100%;
  position: block;
  // z-index: 5;
}

@media (max-width: $breakpoint-vuetify-xs) {
  #product-bridge {
    width: 0px;
  }
}

.full-page {
  padding: 0px;
}

@media (min-width: $breakpoint-md) {
  main {
    padding: 24px;
  }
  .full-page {
    padding: 24px;
  }
}

@media (min-width: $breakpoint-lg) {
  main {
    padding: 0px 24px 24px 0px;
  }
  .full-page {
    padding: 0px 24px 24px 24px;
  }
}
</style>
