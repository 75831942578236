/* eslint-disable linebreak-style */
/* eslint-disable no-console */
import {
  deleteDocumentTemplate,
  copyDocumentTemplate,
  deleteTextractAIP,
  deleteXmlConfiguration,
  documentTemplateReplace,
  findAndReplaceIndexData,
  generateSamlSSOLogin,
  getAppVersions,
  getCaptureProfile,
  getComprehendTypes,
  getAllUserSettings,
  getUserSetting,
  getDocumentTemplateTypeList,
  getCustomFolderViewDetailsForFolder,
  getCustomFolderViewDetailsForID,
  getEmailSettings,
  getEmailTemplateDetails,
  getFieldHasMultipleObjects,
  getLicenseAvailabilityForUserUpsert,
  getLookupBindings,
  getSamlConfig,
  getSamlSSOUrl,
  getTextractAIP,
  getTransactionLogAggregateData,
  getUiVersion,
  getXmlConfigurationList,
  getXmlSchemaList,
  listTextractS3Buckets,
  loadDashboardStatistics,
  loadImportXmlSchemaFields,
  reloadSAMLConfiguration,
  saveBarcodeIndex,
  saveCaptureProfile,
  saveEmailSettings,
  saveEmailTemplate,
  saveOcrTemplate,
  savePasswordSettings,
  saveSamlConfig,
  sendTestEmail,
  setThirdPartyCode,
  setUserSetting,
  saveTextractAIP,
  saveXmlConfiguration,
  signatureDocumentFileReplace,
  signatureDocumentTemplateReplace,
  testComprehendConnection,
  testTextractConnection,
  validateTextractConnection,
  getAwsRegions,
 } from '@/store/apiModules/admin.api'

 import {
  buildTableData,
 } from '@/store/helperModules/adminStorageSetup.module';

function initialState() {
  return {
    defaultApp: null,
    defaultAppLocalSelection: null,
    originalIsAnalyticsOptOut: false,
    editXmlConfig: null,    
    isAnalyticsOptOut: null,
    licenseAvailable: false,
    licenseAvailableMessage: '',
    parentFolderPermissions: {},
    samlConfig: null,
    samlSSOLogin: '',
    samlSSOUrl: '',
    saveWorkflowID: 0,
    selectedEmailTemplate: null,
    uiVersion: '',
    xmlConfigurationList: [],
  }
}

const admin = {
  namespaced: true,
  state: initialState,
  mutations: {
    defaultAppSelection(state, value) { state.defaultAppLocalSelection = value },
    isAnalyticsOptOutSelection(state, value){state.isAnalyticsOptOut = value},
    resetStoreData(state) {
      const localState = initialState()
      Object.keys(state).forEach((prop) => {
        state[prop] = localState[prop]
      })
    },
    setDefaultApp(state, value) { state.defaultApp = value },
    setIsAnalyticsOptOut(state, value) {state.originalIsAnalyticsOptOut = value},
    setEditXmlConfig(state, value) { state.editXmlConfig = value },
    setLicenseAvailable(state, value) { state.licenseAvailable = value },
    setLicenseAvailableMessage(state, value) {state.licenseAvailableMessage = value },
    setParentFolderPermissions(state, value) { state.parentFolderPermissions = value },
    setSamlConfig(state, value) { state.samlConfig = value },
    setSamlSSOLogin(state, value) { state.samlSSOLogin = value },
    setSamlSSOUrl(state, value) { state.samlSSOUrl = value },
    setSaveWorkflowID(state, value) { state.saveWorkflowID = value },
    setSelectedEmailTemplate(state, value) { state.selectedEmailTemplate = value },
    setUiVersion(state, value) { state.uiVersion = value },
    setXmlConfigurationList(state, value) { state.xmlConfigurationList = value },
  },
  actions: {
    defaultAppSelection({ commit }, appName) { commit('defaultAppSelection', appName) },
    async deleteDocumentTemplate({ rootState }, documentTemplateId) {
      const result = await deleteDocumentTemplate(rootState.common.apiToken, documentTemplateId)
      return result
    },
    async copyDocumentTemplate({ rootState }, documentTemplateId) {
      const result = await copyDocumentTemplate(rootState.common.apiToken, documentTemplateId)
      return result
    },
    async deleteTextractAIP({ rootState }, textractAIPId) { return deleteTextractAIP(rootState.common.apiToken, textractAIPId) },
    async deleteXmlConfiguration({ rootState }, xmlConfigId) { return deleteXmlConfiguration(rootState.common.apiToken, xmlConfigId) },
    async documentTemplateReplace({rootState}, payload) {
      return await documentTemplateReplace(rootState.common.apiToken, payload)
    },
    async signatureDocumentFileReplace({rootState}, payload) {
      return await signatureDocumentFileReplace(rootState.common.apiToken, payload)
    },
    async signatureDocumentTemplateReplace({rootState}, payload) {
      return await signatureDocumentTemplateReplace(rootState.common.apiToken, payload)
    },
    async findAndReplaceIndexData({ rootState }, payload) {
      const result = await findAndReplaceIndexData(rootState.common.apiToken, payload)
      return result
    },
    async generateSamlSSOLogin({ rootState, commit }) {
      const result = await generateSamlSSOLogin(rootState.common.apiToken)
      await commit('setSamlSSOLogin', result)
    },
    async getAppVersions({ rootState }) {
      return getAppVersions(rootState.common.apiToken)
    },
    async getCaptureProfile({ rootState }, profileId) {
      return getCaptureProfile(rootState.common.apiToken, profileId)
    },
    async getComprehendTypes({ rootState }) { return getComprehendTypes(rootState.common.apiToken) },
    async getCustomFolderViewDetailsForFolder({ rootState }, folderId) {
      return getCustomFolderViewDetailsForFolder(rootState.common.apiToken, folderId)
    },
    async getCustomFolderViewDetailsForID({ rootState }, custViewId) {
      return getCustomFolderViewDetailsForID(rootState.common.apiToken, custViewId)
    },
    async getAllUserSettings({ rootState, dispatch, commit }) {
      const result = await getAllUserSettings(rootState.common.apiToken)
      if (result?.Values.defaultApp || result?.Values.defaultApp === '') {
        await commit('setDefaultApp', result?.Values.defaultApp)
      }
      if (result?.Values.defaultView) {
        dispatch('workflow/setCustomFolderViewSaved', result?.Values.defaultView, { root: true })
      }
      return result
    },
    async getUserSetting({ rootState, dispatch, commit }, name) {
      const result = await getUserSetting(rootState.common.apiToken, name)
      if (name == 'defaultApp') {
        await commit('setDefaultApp', result?.Value)
      }
      else if (name == 'defaultView') {
        dispatch('workflow/setCustomFolderViewSaved', result?.Value, { root: true })
      } else if(name == 'IsAnalyticsOptOut'){
        await commit('setIsAnalyticsOptOut', result?.Value)
      }
      return result
    },
    async getDocumentTemplateTypeList({ rootState }) { return getDocumentTemplateTypeList(rootState.common.apiToken) },
    async getEmailSettings({ rootState }) {
      return getEmailSettings(rootState.common.apiToken)
    },
    async getFieldHasMultipleObjects({ rootState }, fieldName) {
      return getFieldHasMultipleObjects(rootState.common.apiToken, fieldName)
    },
    async getLicenseAvailabilityForUserUpsert({ rootState, commit }) {
      const result = await getLicenseAvailabilityForUserUpsert(rootState.common.apiToken)
      await commit('setLicenseAvailable', result?.UserLicenseIsAvailable)
      await commit('setLicenseAvailableMessage', result?.Message)
      return result
    },
    async getLookupBindings({ rootState }) {
      return getLookupBindings(rootState.common.apiToken)
    },
    async getSamlConfig({ rootState, commit }) {
      const result = await getSamlConfig(rootState.common.apiToken)
      commit('setSamlConfig', result)
    },
    async getSamlSSOUrl({ rootState, commit }) {
      const result = await getSamlSSOUrl(rootState.common.apiToken)
      await commit('setSamlSSOUrl', result)
    },
    async getTextractAIP({ rootState }, textractAIPId) { return getTextractAIP(rootState.common.apiToken, textractAIPId) },
    async getTransactionData({ rootState }, payload) { return getTransactionLogAggregateData(rootState.common.apiToken, payload) },
    async getUiVersion({commit}) {
      const result = await getUiVersion()
      commit('setUiVersion', result)
    },
    async getXmlConfigurationList({ rootState }) { return getXmlConfigurationList(rootState.common.apiToken) },
    async getXmlSchemaList({ rootState }) {
      return getXmlSchemaList(rootState.common.apiToken)
    },
    async listTextractS3Buckets({ rootState }, payload) { return listTextractS3Buckets(rootState.common.apiToken, payload) },
    async loadDashboardStatistics({ rootState }) { return loadDashboardStatistics(rootState.common.apiToken) },
    async loadImportXmlSchemaFields({ rootState }, payload) { return loadImportXmlSchemaFields(rootState.common.apiToken, payload) },
    async loadSamlConfig({ rootState, commit }) {
      const result = await getSamlConfig(rootState.common.apiToken)
      commit('setSamlConfig', result)
    },
    isAnalyticsOptOutSelection({ commit }, isOptedIn) { commit('isAnalyticsOptOutSelection', isOptedIn) },
    async reloadSAMLConfiguration({ rootState, commit }) {
      const result = await reloadSAMLConfiguration(rootState.common.apiToken)
      await commit('reloadSAMLConfiguration', result)
    },
    async saveBarcodeIndex({ rootState }, payload) {
      const response = await saveBarcodeIndex(rootState.common.apiToken, payload)
      return response
    },
    async saveCaptureProfile({ rootState }, payload) {
      const response = await saveCaptureProfile(rootState.common.apiToken, payload)
      return response
    },
    async setUserSetting({ rootState, dispatch }, payload) {
      const response = await setUserSetting(rootState.common.apiToken, payload)
      // if (payload.settingName == 'defaultApp') {
      //   dispatch('getDefaultApp')
      // }
      if (payload.settingName == 'defaultView') {
        dispatch('workflow/setCustomFolderViewSaved', payload.settingValue, { root: true })
      }
      return response
    },
    async saveEmailTemplate({ rootState }, payload) {
      const result = await saveEmailTemplate(rootState.common.apiToken, payload)
      return result
    },
    async saveEmailSettings({ rootState }, payload) {
      const result = await saveEmailSettings(rootState.common.apiToken, payload)
      return result
    },
    async saveOcrTemplate({ rootState }, payload) {
      const response = await saveOcrTemplate(rootState.common.apiToken, payload)
      return response
    },
    async savePasswordSettings({ rootState }, payload) {
      const response = await savePasswordSettings(rootState.common.apiToken, payload)
      return response.Value
    },
    async saveSamlConfig({ rootState, commit }, payload) {
      const result = await saveSamlConfig(rootState.common.apiToken, payload)
      if (result) {
        commit('setSamlConfig', result)
        return true
      }

      return false
    },
    async sendTestEmail({ rootState }) {
      const response = await sendTestEmail(rootState.common.apiToken)
      return response
    },
    async setEmailSelectedEmailTemplate({ commit, rootState }, emailTemplateID) {
      let template = null
      if (emailTemplateID !== undefined && emailTemplateID > 0) {
        template = await getEmailTemplateDetails(rootState.common.apiToken, emailTemplateID)
      } else {
        template = {
          templateID: 0,
          templateName: '',
          subject: '',
          body: '',
          docLinkMaxViews: 0,
          docLinkExpirationHours: 0,
          docLinkMaxDownloads: 0,
          docLinkUserID: 0,
        }
      }

      try {
        commit('setSelectedEmailTemplate', template)
      } catch (error) {
        console.warn(error)
      }
    },
    setParentFolderPermissions({ commit }, payload) { commit('setParentFolderPermissions', payload) },
    async setSaveWorkflowID({ commit }, workflowID) { await commit('setSaveWorkflowID', workflowID) },
    // eslint-disable-next-line no-empty-pattern
    async setTableData({}, data) {
      const response = await buildTableData(data)
      return response
    },
    async setThirdPartyCode({ rootState }, payload) {
      const result = await setThirdPartyCode(rootState.common.apiToken, payload)
      return result
    },
    async saveTextractAIP({ rootState }, payload) { return saveTextractAIP(rootState.common.apiToken, payload) },
    async saveXmlConfiguration({ rootState }, payload) { return saveXmlConfiguration(rootState.common.apiToken, payload) },
    async testComprehendConnection({ rootState }, payload) { return testComprehendConnection(rootState.common.apiToken, payload) },
    async testTextractConnection({ rootState }, payload) { return testTextractConnection(rootState.common.apiToken, payload) },
    async validateTextractConnection({ rootState }, payload) { return validateTextractConnection(rootState.common.apiToken, payload) },
    async getAwsRegions({rootState}) {return getAwsRegions(rootState.common.apiToken)},
  },
}

export default admin
